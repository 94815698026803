import styled from '@emotion/styled';
import { css } from '@emotion/core';

const stickyHeaderStyles = () => css`
  header {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .dashboard-wrapper {
    margin: 0px auto 40px auto;
  }
`;

const SCPartnerLayout = styled.div`
  width: 100%;
  height: 100vh;
  font-family: var(--font2);

  .page-content {
    display: flex;
    height: 100%;

    .partner-menu {
      max-width: 280px;
      width: 100%;
      padding: 25px 15px;
      background-color: #1c2536;
      display: flex;
      flex-direction: column;
      gap: 40px;

      .logo-container {
        position: relative;
        width: fit-content;
        margin-left: 10px;

        .cyberGuardian-simple-logo {
          display: none;
          width: 50px;
          aspect-ratio: 1;
        }

        .cyberGuardian-logo {
          width: 125px;
          height: 70px;
        }

        > h3 {
          position: absolute;
          color: var(--white);
          bottom: 4px;
          right: 0px;
          font-size: 14px;
          font-style: italic;
        }
      }

      .navigation-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 5px;

        .partner-menu-button {
          width: 100%;
          display: flex;
          gap: 16px;
          align-items: center;
          padding: 6px 16px;
          border-radius: 8px;
          text-decoration: none;
          outline: none;
          color: var(--light-grey-6);
          background-color: transparent;
          font-size: 16px;
          transition: background-color 0.2s;

          :hover {
            background-color: rgba(255, 255, 255, 0.04);
          }
        }

        .selected {
          background-color: rgba(255, 255, 255, 0.04);
          color: white;

          :hover {
            background-color: rgba(255, 255, 255, 0.04);
          }
        }
      }

      .sign-out-button {
        display: flex;
        gap: 10px;
        padding: 8px 20px;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        background-color: var(--partner-primary);
        color: var(--white);
        border-radius: 12px;

        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        :hover {
          background-color: #5d697c;
        }
      }
    }

    .partner-dashboard {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;
      background-color: var(--application-background-color);

      header {
        background-color: var(--application-background-color);

        .header-container {
          width: 100%;
          max-width: calc(var(--section-width-xl) + 40px);
          margin: 0 auto;
          padding: 30px 20px;

          h3 {
            font-family: var(--font1);
            font-size: 30px;
            color: #111927;
            font-weight: 600;
          }
        }
      }

      .dashboard-wrapper {
        display: flex;
        flex: 1;
        margin: 40px auto;
        padding: 0px 20px;
        width: 100%;
        max-width: calc(var(--section-width-xl) + 40px);

        .dashboard-content {
          width: 100%;
        }
      }

      ${({ isStickyHeader }) => isStickyHeader && stickyHeaderStyles};
    }
  }

  @media (max-width: 1200px) {
    .page-content {
      .partner-menu {
        max-width: 68px;
        padding: 25px 10px;

        .navigation-container {
          .partner-menu-button {
            padding: 6px 10px;
            justify-content: center;
            > span {
              display: none;
            }
          }
        }
        .logo-container {
          margin: 0 auto;

          .cyberGuardian-logo,
          h3 {
            display: none;
          }
          .cyberGuardian-simple-logo {
            display: block;
          }
        }

        .sign-out-button {
          > span {
            display: none;
          }
        }
      }
    }
  }
`;

export default SCPartnerLayout;
