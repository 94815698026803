import axios from 'axios';

export const saveAnswer = async (answer) => {
  return axios.put('/forms/qualification/answers', answer);
};

export const saveOtherTester = async (testerData) => {
  return axios.put('/forms/qualification/admin', testerData);
};

export const sendRemind = async () => {
  return axios.post('/forms/qualification/remind');
};
