import { faList } from '@fortawesome/pro-regular-svg-icons';
import {
  faCircle1,
  faCircle2,
  faCircleCheck,
} from '@fortawesome/pro-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import useBreachNotificationType from '../../../hooks/breachNotificationType/useBreachNotificationType';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCProgressMeasuresTakenCard from './ProgressMeasuresTakenCard.style';
import { ErrorKey } from '../../../track';
import { getBreachStatus } from '../../../utils/functions/dataBreaches';

const ProgressMeasuresTakenCard = () => {
  const i18n = useTranslation();
  const { breaches, activeBreach } = useBreachNotificationType();
  const client = useSelector((redux) => redux.client);
  const [takeActionForTheBreach, setTakeActionForTheBreach] = useState(false);
  const dispatch = useDispatch();

  const isBreachWithPasswords = () => {
    if (breaches[activeBreach]?.classes?.indexOf('passwords') > -1) return true;
    return false;
  };

  const resetBreachNotifications = () => {
    if (activeBreach) {
      const clientId = client.id;
      const emails = Object.keys(breaches[activeBreach].emails).map(
        (email) => email
      );
      const breachDate = breaches[activeBreach].date.toDate();

      axios
        .post('/api/breaches/dismiss', {
          clientId,
          emails,
          breach: activeBreach,
          date: breachDate,
        })
        .then(() => {
          dispatch(showPopUp(null));
        })
        .catch(() => {
          dispatch(showPopUp(null));
          ErrorKey('common.errorTryLater');
        });
    }
  };

  useEffect(() => {
    setTakeActionForTheBreach(false);
  }, [activeBreach]);

  return (
    <SCProgressMeasuresTakenCard>
      {/* ALERT MÁS CRÍTICA  - Se trata de una filtración crítica porque incluye contraseñas */}
      {breaches[activeBreach]?.dangerousLevel === 'critical' &&
        !takeActionForTheBreach &&
        getBreachStatus(breaches[activeBreach]) === 'actionRequired' && (
          <div className="section-organism">
            <InfoBanner
              type="warn"
              title={i18n.t('breachNotification.criticalBreachCard.title')}
              text={[
                i18n.t('breachNotification.criticalBreachCard.p1'),
                i18n.t('breachNotification.criticalBreachCard.p2'),
              ]}
              onClickButton={() => setTakeActionForTheBreach(true)}
              buttonText={i18n.t(
                'breachNotification.criticalBreachCard.takeAction'
              )}
            />
          </div>
        )}

      {/* ALERT MENOS CRÍTICA - Se trata de una filtración menos crítica porque no incluye contraseñas */}
      {breaches[activeBreach]?.dangerousLevel !== 'critical' &&
        !isBreachWithPasswords() && (
          <div className="section-organism">
            <InfoBanner
              type="warn"
              title={i18n.t('breachNotification.lessCriticalBreachCard.title')}
              text={[
                i18n.t('breachNotification.lessCriticalBreachCard.p1'),
                i18n.t('breachNotification.lessCriticalBreachCard.p2'),
              ]}
              onClickButton={() =>
                dispatch(
                  showPopUp('SentEmailTo', {
                    title: i18n.t(
                      'breachNotification.notifyAffectedBox.modalTitle'
                    ),
                    type: 'breaches',
                  })
                )
              }
              buttonText={i18n.t(
                'breachNotification.lessCriticalBreachCard.openEmailTemplate'
              )}
            />
          </div>
        )}

      {/* ALERT MENOS CRÍTICA FILTRACIÓN HACE MÁS DE 6 MESES - Se trata de una filtración menos crítica porque se trata de una filtración de hace más de 6 meses */}
      {breaches[activeBreach]?.dangerousLevel !== 'critical' &&
        isBreachWithPasswords() && (
          <div className="section-organism">
            <InfoBanner
              type="warn"
              title={i18n.t('breachNotification.oldCriticalBreachCard.title')}
              text={[
                i18n.t('breachNotification.oldCriticalBreachCard.p1'),
                i18n.t('breachNotification.oldCriticalBreachCard.p2'),
              ]}
              onClickButton={() =>
                dispatch(
                  showPopUp('SentEmailTo', {
                    title: i18n.t(
                      'breachNotification.notifyAffectedBox.modalTitle'
                    ),
                    type: 'breaches',
                  })
                )
              }
              buttonText={i18n.t(
                'breachNotification.oldCriticalBreachCard.openEmailTemplate'
              )}
            />
          </div>
        )}

      {/* ALERT STEP 1 - Notifica a los empleados afectados y pídeles que confirmen que han cambiado las contraseñas */}
      {breaches[activeBreach]?.dangerousLevel === 'critical' &&
        takeActionForTheBreach &&
        getBreachStatus(breaches[activeBreach]) === 'actionRequired' && (
          <div className="section-organism">
            <SectionCard
              headerIcon={faList}
              headerTitle={i18n.t(
                'breachNotification.resolvingBreachCard.title'
              )}>
              <div className="progress-actions-container">
                <div className="progress-actions-first-step">
                  <div className="steps-icon">
                    <Icon
                      iconName={faCircle1}
                      color="var(--bluish-grey)"
                      size="large"
                    />
                  </div>
                  <div className="steps-information-title">
                    <h3 className="dark-grey">
                      {i18n.t('breachNotification.resolvingBreachCard.step1')}
                    </h3>
                    <p>
                      {i18n.t(
                        'breachNotification.resolvingBreachCard.step1sub'
                      )}
                    </p>
                    <Button
                      text={i18n.t(
                        'breachNotification.resolvingBreachCard.sendNotificationEmail'
                      )}
                      size="mid"
                      color="white"
                      onClick={() =>
                        dispatch(showPopUp('breachesNotifyAffected'))
                      }
                    />
                  </div>
                </div>
                <div className="progress-actions-second-step">
                  <div className="steps-icon">
                    <Icon iconName={faCircle2} color="#D9D9D9" size="large" />
                  </div>
                  <div className="steps-information-title">
                    <h3 className="light-grey">
                      {i18n.t('breachNotification.resolvingBreachCard.step2')}
                    </h3>
                  </div>
                </div>
              </div>
            </SectionCard>
          </div>
        )}

      {/* ALERT STEP 2 - Espera la confirmación de tus empleados */}
      {breaches[activeBreach]?.dangerousLevel === 'critical' &&
        getBreachStatus(breaches[activeBreach]) === 'notified' && (
          <div className="section-organism">
            <SectionCard
              headerIcon={faList}
              headerTitle={i18n.t(
                'breachNotification.resolvingBreachCard.title'
              )}>
              <div className="progress-actions-container">
                <div className="progress-actions-first-step">
                  <div className="steps-icon">
                    <Icon
                      iconName={faCircleCheck}
                      color="#EBF5DB"
                      size="large"
                    />
                  </div>
                  <div className="steps-information-title">
                    <h3 className="light-green">
                      {i18n.t('breachNotification.resolvingBreachCard.step1')}
                    </h3>
                  </div>
                </div>
                <div className="progress-actions-second-step">
                  <div className="steps-icon">
                    <Icon
                      iconName={faCircle2}
                      color="var(--bluish-grey)"
                      size="large"
                    />
                  </div>
                  <div className="steps-information-title">
                    <h3 className="dark-grey">
                      {i18n.t('breachNotification.resolvingBreachCard.step2')}
                    </h3>
                    <p>
                      {i18n.t(
                        'breachNotification.resolvingBreachCard.step2sub'
                      )}
                    </p>
                    <Button
                      text={i18n.t(
                        'breachNotification.resolvingBreachCard.sendAReminder'
                      )}
                      size="mid"
                      color="white"
                      onClick={() =>
                        dispatch(showPopUp('breachesNotifyAffected'))
                      }
                    />
                  </div>
                </div>
              </div>
            </SectionCard>
          </div>
        )}

      {/* ALERT STEP RESUELTO -  Has revisado esta filtración de datos */}
      {breaches[activeBreach]?.dangerousLevel === 'critical' &&
        getBreachStatus(breaches[activeBreach]) === 'resolved' && (
          <div className="section-organism">
            <InfoBanner
              type="info"
              title={i18n.t('breachNotification.resolvedBreachCard.title')}
              text={[i18n.t('breachNotification.resolvedBreachCard.p1')]}
              onClickButton={() => resetBreachNotifications()}
              buttonText={i18n.t(
                'breachNotification.resolvedBreachCard.resetBreach'
              )}
            />
          </div>
        )}
    </SCProgressMeasuresTakenCard>
  );
};

export default ProgressMeasuresTakenCard;
