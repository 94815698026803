import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import hackingLaptop from '../../img/hacking-laptop.png';
import calmMan from '../../img/calm-man.png';
import highFive from '../../img/high-five.png';
import readyToStart from '../../img/office-man.jpeg';
import addEmployees from '../../img/InitialTutorial/addEmployees.png';

import ImageTitleText from '../../components/atoms/ImageTitleText/ImageTitleText';
import TextImage from '../../components/atoms/TextImage/TextImage';
import SCInitialTutorialPage from './InitialTutorialPage.styles';
import { finishTutorial } from '../../redux/actions/client.actions';
import { useTranslation } from '../../i18n';
import { getInternationalizedPNGImage } from '../../utils/internationalizationModules/internationalizedImage/getInternationalizedImage';
import CarouselHandler from '../../components/molecules/CarouselHandler/CarouselHandler';
import Button from '../../components/atoms/Button/Button';
import InitialTutorialScoring from '../../components/molecules/InitialTutorialScoring/InitialTutorialScoring';

const InitialTutorialPage = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();

  const closeModal = () => {
    dispatch(finishTutorial('initialTutorial'));
    navigate('/client');
  };

  const tutorialStepsContent = {
    whatOffers: (
      <WhatOffersContainer>
        <WhatOffersSection>
          <ImageTitleText
            textKeys={[
              i18n.t('tutorials.initialTutorial.maxProtection'),
              i18n.t('tutorials.initialTutorial.maxProtection1'),
            ]}
            img={hackingLaptop}
            imgWidth="150px"
            titleHeight="55px"
          />
        </WhatOffersSection>
        <WhatOffersSection>
          <ImageTitleText
            textKeys={[
              i18n.t('tutorials.initialTutorial.monitoring'),
              i18n.t('tutorials.initialTutorial.monitoring1'),
            ]}
            img={calmMan}
            imgWidth="150px"
            titleHeight="55px"
          />
        </WhatOffersSection>
        <WhatOffersSection>
          <ImageTitleText
            textKeys={[
              i18n.t('tutorials.initialTutorial.understand'),
              i18n.t('tutorials.initialTutorial.understand1'),
            ]}
            img={highFive}
            imgWidth="150px"
            titleHeight="55px"
          />
        </WhatOffersSection>
      </WhatOffersContainer>
    ),
    homePage: (
      <ImageTitleText
        textKeys={[
          i18n.t('tutorials.initialTutorial.homePage1'),
          i18n.t('tutorials.initialTutorial.homePage2'),
        ]}
        img={getInternationalizedPNGImage('InitialTutorial', 'homePage')}
        imgWidth="374px"
      />
    ),
    score: <InitialTutorialScoring />,
    controlPanel: (
      <ImageTitleText
        textKeys={[
          i18n.t('tutorials.initialTutorial.controlPanel1'),
          i18n.t('tutorials.initialTutorial.controlPanel2'),
        ]}
        img={getInternationalizedPNGImage('InitialTutorial', 'controlPanel')}
        imgWidth="374px"
      />
    ),
    actions: (
      <ImageTitleText
        textKeys={[
          i18n.t('tutorials.initialTutorial.actions1'),
          i18n.t('tutorials.initialTutorial.actions2'),
        ]}
        img={getInternationalizedPNGImage('InitialTutorial', 'actionsList')}
        imgWidth="374px"
      />
    ),
    startUsing: (
      <ImageTitleText
        textKeys={[
          i18n.t('tutorials.initialTutorial.startUsing1'),
          i18n.t('tutorials.initialTutorial.startUsing2'),
        ]}
        img={addEmployees}
        imgWidth="100%"
        hasImgTranslation
      />
    ),
    countOnUs: (
      <TextImage
        subtitle={i18n.t('tutorials.initialTutorial.countOnUs1')}
        text={i18n.t('tutorials.initialTutorial.countOnUs2')}
        img={getInternationalizedPNGImage('InitialTutorial', 'contactSupport')}
        imgHeight="270px"
      />
    ),
    readyToBegin: (
      <TextImage
        img={readyToStart}
        imgHeight="270px"
        backgroundPosition="0% 30%"
        text={i18n.t('tutorials.initialTutorial.readyToBegin1')}
      />
    ),
  };

  const tutorialStep = (id) => {
    return (
      <StepCardContent>
        <h1>{i18n.t(`tutorials.initialTutorial.${id}`)}</h1>
        {tutorialStepsContent[id] || ''}
      </StepCardContent>
    );
  };

  const genericContent = [
    tutorialStep('whatOffers'),
    tutorialStep('homePage'),
    tutorialStep('score'),
    tutorialStep('controlPanel'),
    tutorialStep('actions'),
    tutorialStep('startUsing'),
    tutorialStep('countOnUs'),
    tutorialStep('readyToBegin'),
  ];

  return (
    <SCInitialTutorialPage className="wings-section-background">
      <div className="it-step-wrapper">
        <>
          {genericContent[activeStep - 1]}
          <CarouselHandler
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            totalSteps={genericContent.length}
            firstPageButton={
              <Button
                onClick={() => {
                  closeModal();
                }}
                text={i18n.t('tutorials.skipTutorial')}
                size="large"
                color="white"
                width="200px"
              />
            }
            lastPageButton={
              <Button
                onClick={() => {
                  closeModal();
                }}
                text={i18n.t('tutorials.start')}
                size="large"
                color="red"
                width="200px"
              />
            }
          />
        </>
      </div>
    </SCInitialTutorialPage>
  );
};

const StepCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 45px 30px 15px 30px; */
  padding-bottom: 30px;
  text-align: center;
  height: 500px;
  max-height: 550px;
  overflow: hidden;

  & h1 {
    font-size: 25px;
    line-height: 37px;
    margin-bottom: 45px;
  }

  & p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0;
    text-wrap: balance;
  }
  @media (width < 769px) {
    height: 100%;
    max-height: 100%;
    padding-bottom: 0;

    & h1 {
      padding: 0px 30px;
    }
  }
`;

const WhatOffersContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media (width < 769px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
    overflow-y: auto;
  }
`;

const WhatOffersSection = styled.div`
  flex: 0 0 33%;

  &:not(:last-of-type) {
    padding-right: 25px;
  }

  @media (width < 769px) {
    padding: 0 30px;
  }
`;

export default InitialTutorialPage;
