import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from '../../../i18n';
import ChartSpinner from '../../atoms/ChartSpinner/ChartSpinner';
import { getRisk, getRiskColor } from '../../../utils/helper';

const RadialChart = ({ score, label }) => {
  const i18n = useTranslation();

  const CHART_OPTIONS = {
    series: [score * 10],
    options: {
      chart: {
        // height: 350,
        type: 'radialBar',
      },
      fill: {
        type: 'solid',
        colors: getRiskColor(score),
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -360,
          endAngle: 0,
          hollow: {
            size: '66.5%',
          },
          dataLabels: {
            showOn: 'always',
            name: {
              show: true,
              offsetY: 25,
              fontFamily: 'var(--font1)',
              color: 'var(--bluish-grey)',
              fontSize: '16px',
              fontWeight: 500,
            },
            value: {
              show: true,
              offsetY: -10,
              fontFamily: 'var(--font1)',
              color: getRiskColor(score),
              fontSize: '38px',
              fontWeight: 500,
              formatter(val) {
                return `${val / 10}`;
              },
            },
          },
        },
      },
      // labels: [getChartLabel()],
      labels: [label ?? i18n.t(`websiteSecurity.${getRisk(score)}`)],
    },
  };

  return (
    <>
      {score !== null && score !== undefined && score >= 0 ? (
        <ReactApexChart
          type="radialBar"
          options={CHART_OPTIONS.options}
          series={CHART_OPTIONS.series}
          width="250px"
          height="250px"
        />
      ) : (
        <ChartSpinner />
      )}
    </>
  );
};

export default RadialChart;
