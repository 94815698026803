import React from 'react';
import SCLastUpdateText from './LastUpdateText.style';
import { getDateFormat } from '../../../utils/format/dataFormat';
import { useTranslation } from '../../../i18n';

const LastUpdateText = ({ date }) => {
  const i18n = useTranslation();
  const dateText = getDateFormat(date, { hour: '2-digit', minute: '2-digit' });

  return (
    <SCLastUpdateText>
      {`${i18n.t('common.lastUpdate')}: ${dateText}`}
    </SCLastUpdateText>
  );
};

export default LastUpdateText;
