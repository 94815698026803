import {
  faChevronRight,
  faSyncAlt,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { useTranslation } from '../../../i18n';
import SCEmailSecurityTableCard from './EmailSecurityTableCard.style';
import Label from '../../atoms/Label/Label';
import { getEmailSecurityColor } from '../../../utils/functions/securitySeverity';
import Icon from '../../atoms/Icon/Icon';
import { getDateFormat } from '../../../utils/format/dataFormat';
import {
  getDMARCStatus,
  getSPFStatus,
} from '../../../utils/emailSecurity/emailSecurity';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import useRunScan from '../../../hooks/useRunScan/useRunScan';

const EmailSecurityTableCard = ({ isClickable = false }) => {
  const i18n = useTranslation();
  const client = useSelector((redux) => redux.client);
  const navigate = useNavigate();
  const { loading, isAbleToRescan, isLoadingNewScan, handleRunScan } =
    useRunScan({
      domain: client.monitoredDomains[0],
      atEmail: client.atEmail,
    });

  const statusSPF = getSPFStatus(client?.status?.emailSecurity?.spf);
  const statusDMARC = getDMARCStatus(client?.status?.emailSecurity?.dmarc);
  const lastDateAnalyzed = client?.status?.emailSecurity?.updatedAt;

  return (
    <SCEmailSecurityTableCard isClickable={isClickable}>
      <SectionCard
        headerIcon={faWrench}
        headerTitle={`${i18n.t('controlPanel.emailSecurity.title')} @${
          client.atEmail
        }`}
        headerButtonIcon={!isLoadingNewScan && faSyncAlt}
        headerButtonText={
          isLoadingNewScan ? (
            <SpinnerText text={i18n.t('common.analyzing')} />
          ) : (
            i18n.t('websiteSecurity.runScan')
          )
        }
        headerButtonDisabled={loading || isLoadingNewScan || !isAbleToRescan}
        headerButtonOnClick={handleRunScan}>
        <div className="table-domain-info-container">
          <div
            className="emailSecurityRow"
            onClick={
              isClickable
                ? () => navigate('/client/configure-email-spf')
                : undefined
            }>
            <div className="emailSecurrityRowName">
              <p>{i18n.t('controlPanel.emailSecurity.SPF')}</p>
            </div>
            <div className="emailSecurrityRowLabel">
              <Label
                value={i18n.t(`configureEmailSecurity.SPF.${statusSPF}`)}
                color={getEmailSecurityColor(statusSPF)}
                width="120px"
              />
            </div>

            <div className="emailSecurrityRowStatus">
              {i18n.t(`configureEmailSecurity.SPF.${statusSPF}Long`)}
            </div>
            {isClickable && (
              <Icon size="medium" iconName={faChevronRight} color="#798897" />
            )}
          </div>
          <div
            className="emailSecurityRow"
            onClick={() => {
              isClickable && navigate('/client/configure-email-dmarc');
            }}>
            <div className="emailSecurrityRowName">
              <p>{i18n.t('controlPanel.emailSecurity.DMARC')}</p>
            </div>
            <div className="emailSecurrityRowLabel">
              <Label
                value={i18n.t(`configureEmailSecurity.DMARC.${statusDMARC}`)}
                color={getEmailSecurityColor(statusDMARC)}
                width="120px"
              />
            </div>

            <div className="emailSecurrityRowStatus">
              {i18n.t(`configureEmailSecurity.DMARC.${statusDMARC}Long`)}
            </div>
            {isClickable && (
              <Icon size="medium" iconName={faChevronRight} color="#798897" />
            )}
          </div>
          <p className="domain-info-container">
            {i18n.t('websiteSecurity.lastScanned')}{' '}
            {getDateFormat(lastDateAnalyzed?.toDate())}
          </p>
        </div>
      </SectionCard>
    </SCEmailSecurityTableCard>
  );
};

export default EmailSecurityTableCard;
