import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { firestore } from '../../firebase';

const useQualificationForm = () => {
  const [qualificationForm, setQualificationForm] = useState();

  const client = useSelector((redux) => redux.client);

  const getQualificationForm = () => {
    firestore
      .collection('qualification_forms')
      .doc(client.id)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setQualificationForm(doc.data());
        }
      });
  };

  useEffect(() => {
    if (!qualificationForm) {
      getQualificationForm();
    }
  }, []);

  return {
    qualificationForm,
  };
};

export default useQualificationForm;
