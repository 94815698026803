import { useEffect, useState } from 'react';

const useKeyPress = (key) => {
  const [isKeyPressed, setIsKeyPressed] = useState(false);

  useEffect(() => {
    function downHandler(event) {
      if (event.key === key) {
        setIsKeyPressed(true);
      }
    }

    function upHandler(event) {
      if (event.key === key) {
        setIsKeyPressed(false);
      }
    }

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return {
    isKeyPressed,
  };
};

export default useKeyPress;
