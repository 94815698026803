import {
  GET_USER, UPDATE_USER,
} from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case GET_USER:
      return action.payload || null;
    case UPDATE_USER:
      return action.payload || null;
    default:
      return state;
  }
};
