import styled from '@emotion/styled';

const SCModal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: ${({ tawkToAvailable }) =>
    tawkToAvailable ? 1999999999 : 2000000004};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 40px 30px;
  /* transition: background-color 0.1s; */
  -webkit-overflow-scrolling: touch;
  background: rgba(0, 0, 0, 0.7);

  animation-name: onetrust-fade-in;
  /* animation-duration: 400ms;
  animation-timing-function: ease-in-out; */

  .modal-card {
    max-height: 90vh;
    background-color: var(--white);
    width: ${({ width }) => width || '420px'};
    border-radius: 5px;
    padding: 40px;
  }

  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  @media (max-width: 440px) {
    .modal-content {
      width: calc(100% - 20px);
      max-height: calc(100% - 20px);
      height: 100%;
    }
  }
`;

export default SCModal;
