import styled from '@emotion/styled';

const borderColors = {
  default: 'var(--default-label-bluish-grey)',
  white: 'var(--bluish-grey)',
};

const fontColors = {
  default: 'var(--bluish-grey)',
  white: 'var(--bluish-grey)',
  lightRed: 'var(--white)',
  red: 'var(--white)',
  orange: 'var(--white)',
  green: 'var(--white)',
  redGradient: 'var(--white)',
  lightRedGradient1: 'var(--white)',
  lightRedGradient2: 'var(--white)',
  lightRedGradient3: 'var(--white)',
};

const backgroundColors = {
  default: 'var(--default-label-bluish-grey)',
  white: 'var(--white)',
  red: 'var(--red-gradient)',
  lightRed: 'var(--light-red-gradient-1)',
  orange: 'var(--orange-gradient)',
  green: 'var(--green-gradient)',
  redGradient: 'var(--red-gradient)',
  lightRedGradient1: 'var(--light-red-gradient-1)',
  lightRedGradient2: 'var(--light-red-gradient-2)',
  lightRedGradient3: 'var(--light-red-gradient-3)',
};

const SCLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: var(--font2);
  height: 25px;
  min-width: ${({ width }) => width || '100px'};
  width: ${({ width }) => width || '100px'};
  font-weight: 500;
  white-space: nowrap;
  color: ${({ color }) => (color ? fontColors[color] : fontColors.default)};
  background: ${({ color }) =>
    color ? backgroundColors[color] : backgroundColors.default};
  border-radius: 5px;
  border: 1px solid
    ${({ color }) => (color ? borderColors[color] : borderColors.default)};
`;

export default SCLabel;
