import ReactGA from 'react-ga';
import { auth, googleProvider } from '../../firebase';
import { AUTH_USER, SIGN_OUT } from './types';
import { getUser } from './user.actions';
import { SESSION_STORAGE_KEYS } from '../../utils/constants/browserStorage';

export const authUser = () => (dispatch) => {
  const wasLogged = auth.currentUser && true;
  auth.onAuthStateChanged((user) => {
    if (user) {
      localStorage.removeItem('sessionTimeoutLogout');

      dispatch(getUser(user.uid));
      ReactGA.set({ userId: user.uid });
      dispatch({
        type: AUTH_USER,
        payload: auth,
      });
    } else {
      dispatch({
        type: AUTH_USER,
        payload: auth,
      });
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.CLIENT_ID);
      // he logged-out, move him to sign-in
      if (wasLogged) {
        window.location.href = `/sign-in?redirect=${window.location.href}`;
      }
    }
  });
};

export const signOut = () => (dispatch) => {
  auth.signOut().then(() => {
    dispatch({
      type: SIGN_OUT,
      payload: auth,
    });
    window.location.href = '/sign-in';
  });
};

export const signInWithEmailAndPassword =
  (email, pass, redirect) => (dispatch) => {
    ReactGA.event({
      category: 'Authentication',
      action: 'Sign in',
      label: 'Email/Password',
    });
    // Log in is called directly from sign-in page
    // auth.signInWithEmailAndPassword(
    //   email,
    //   pass,
    // )
    //   .then(() => {
    //     if (redirect) {
    //       window.location.href = redirect;
    //     } else {
    //       window.location.href = '/';
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     if (e.code === 'auth/wrong-password' || e.code === 'auth/user-not-found') {
    //       ErrorKey('authentication.wrongPasswordOrAccount');
    //     } else if (e.code === 'auth/multi-factor-auth-required') {
    //       mfaKey('authentication.multiFactorAuthRequired', e.resolver, firebase);
    //     } else {
    //       ErrorKey('authentication.errorWithAuthentication');
    //     }
    //   });
  };

// Unused
export const signInWithGoogle = (redirect) => (dispatch) => {
  ReactGA.event({
    category: 'Authentication',
    action: 'Sign in',
    label: 'Gmail',
  });
  auth
    .signInWithPopup(googleProvider)
    .then((result) => {
      if (redirect) {
        window.location.href = redirect;
      } else {
        window.location.href = '/';
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const signUpWithGoogle = async () => {
  ReactGA.event({
    category: 'Authentication',
    action: 'Sign in',
    label: 'Gmail',
  });
  const result = await auth
    .signInWithPopup(googleProvider)
    .then((result) => result)
    .catch((error) => {
      console.log(error);
    });
  if (result) {
    const { isNewUser } = result.additionalUserInfo;

    const {
      email,
      name,
      family_name: familyName,
      given_name: givenName,
    } = result.additionalUserInfo.profile;
    const nameFirst = name ? name.split(' ')[0] : '';
    const nameLast = name ? name.split(' ')[name.split(' ').length] : '';
    const firstName = givenName || nameFirst;
    const lastName = familyName || nameLast;
    return { email, firstName, lastName };
  }
  return false;
};
