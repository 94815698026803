import { faEye, faEyeSlash, faXmark } from '@fortawesome/pro-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import SCInput from './Input.style';

const Input = ({
  inputType,
  inputPlaceholder,
  size,
  onChangeValue,
  center,
  defaultValue = '',
  icon,
  Internalref,
  inputHidden,
  maxLenght,
  design,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const getInputType = () => {
    if (inputType === 'password') {
      return passwordVisibility ? 'text' : 'password';
    }
    if (inputType === 'erasableText') {
      return 'text';
    }
    return inputType;
  };

  useEffect(() => {
    if (onChangeValue) {
      onChangeValue(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  const handleChangedValue = (newValue) => {
    let auxValue = newValue;

    if (inputType === 'tel') {
      auxValue = newValue.replace(/[^0-9+]/g, '');

      if (auxValue[0] !== '+' && auxValue.length > 0) {
        auxValue = `+${auxValue}`;
      }

      if (auxValue[auxValue.length - 1] === '+' && auxValue.length > 1) {
        auxValue = auxValue.slice(0, -1);
      }
    }

    if (
      !maxLenght ||
      (maxLenght && auxValue.toString().length - 1 < maxLenght)
    ) {
      setInputValue(auxValue);
    }
  };

  const handleOnKeyDown = (e) => {
    if (
      inputType === 'number' &&
      ['e', 'E', '+', '-', ',', '.'].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  return (
    <SCInput
      size={size}
      icon={icon}
      center={center}
      inputHidden={inputHidden}
      design={design}>
      {icon && (
        <div className="iconContainer">
          <Icon iconName={icon} />
        </div>
      )}

      <input
        type={getInputType()}
        placeholder={inputPlaceholder}
        value={inputValue}
        onKeyDown={handleOnKeyDown}
        onChange={(e) => {
          handleChangedValue(e.target.value);
        }}
        ref={Internalref}
        {...props}
      />
      {inputType === 'password' && (
        <span
          role="button"
          className="passwordVisibilityToggle"
          onClick={() => togglePasswordVisibility()}>
          <Icon
            iconName={passwordVisibility ? faEyeSlash : faEye}
            color="var(--bluish-grey-2)"
          />
        </span>
      )}
      {inputType === 'erasableText' && (
        <span
          role="button"
          className="erasableToggle"
          onClick={() => setInputValue('')}>
          <Icon iconName={faXmark} color="var(--bluish-grey-2)" />
        </span>
      )}
    </SCInput>
  );
};

export default Input;
