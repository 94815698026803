import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as suppliersService from '../../services/suppliers/suppliers.service';
import { showPopUp } from '../../redux/actions/popUp.actions';

const useSuppliers = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteSupplier = async (domain) => {
    setLoading(true);

    return suppliersService
      .deleteSupplier(domain)
      .then(() => navigate('/client/supplier-security'))
      .finally(() => {
        setLoading(false);
        dispatch(showPopUp(null));
      });
  };

  return {
    loading,
    error,
    deleteSupplier,
  };
};

export default useSuppliers;
