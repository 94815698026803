import { faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import React, { useRef, useState } from 'react';
import useClickOutside from '../../../hooks/handleClickOutside/useClickOutside';
import Icon from '../Icon/Icon';
import SCDropdown from './Dropdown.style';

const Dropdown = ({ className, selectedItem, items }) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const dropDownRef = useRef();
  useClickOutside(dropDownRef, () => setDropdownOpened(false));

  return (
    <>
      {selectedItem && (
        <SCDropdown
          className={className}
          dropdownOpened={dropdownOpened}
          ref={dropDownRef}>
          <div
            tabIndex={0}
            className="clickable-element"
            role="button"
            onClick={() => setDropdownOpened(!dropdownOpened)}>
            {/* {selectedItem.icon && (
          <Icon
            iconName={selectedItem.icon}
            size="medium"
            color="var(--bluish-grey)"
          />
        )} */}
            <h3>{selectedItem.text}</h3>

            <Icon
              iconName={faAngleUp}
              iconClassName={`dropdown-arrow${dropdownOpened ? ' down' : ''}`}
              color="var(--bluish-grey)"
            />
          </div>
          <div className="dropdown-container">
            {items?.map((item) => (
              <div
                className={`dropdown-element${
                  item.text === selectedItem.text ? ' active' : ''
                }`}>
                {item.icon && <Icon iconName={item.icon} size="medium" />}
                <button
                  type="button"
                  onClick={() => {
                    setDropdownOpened(false);
                    item.onClick();
                  }}>
                  {item.text}
                </button>
              </div>
            ))}
          </div>
        </SCDropdown>
      )}
    </>
  );
};

export default Dropdown;
