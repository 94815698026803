import styled from '@emotion/styled';

const SCBillingHistory = styled.div`
  .billing-history-container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .concept-container {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  thead > tr > th > .TableHeader_Container {
    white-space: normal;
  }
`;

export default SCBillingHistory;
