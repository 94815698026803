import React from 'react';
import ToggleButton from '../../atoms/ToggleButton/ToggleButton';
import {
  SCProfileEmailItem,
  EmailNotificationType,
} from './ProfileEmailItem.style';

const ProfileEmailItem = ({ checked, tittle, text, textToggle, onClick }) => {
  return (
    <SCProfileEmailItem>
      <EmailNotificationType>
        <h3>{tittle}</h3>
        <p>{text}</p>
      </EmailNotificationType>
      <ToggleButton checked={checked} text={textToggle} onClick={onClick} />
    </SCProfileEmailItem>
  );
};

export default ProfileEmailItem;
