import isValidCNPJ from './companyIdValidation/brCNPJValidation';
import isValidCif from './companyIdValidation/cifValidation';
import isValidRFC from './companyIdValidation/rfcValidation';
import isValidCPF from './personIDValidation/brCPFValidation';
import isValidDNI from './personIDValidation/dniValidation';

export const isValidCompanyID = (companyID, domain) => {
  const companyIDValidatorsByCountry = {
    es: isValidCif,
    mx: isValidRFC,
    br: isValidCNPJ,
  };

  return companyIDValidatorsByCountry[domain](companyID) || false;
};

export const isValidPersonID = (nif, locale) => {
  const NIFValidatorsByCountry = {
    es: isValidDNI,
    br: isValidCPF,
  };

  return NIFValidatorsByCountry[locale]?.(nif) || false;
};
