import styled from '@emotion/styled';

const SCActionInfoCard = styled.div`
  p {
    margin: 0;
  }

  .description,
  .question {
    font-size: 16px;
  }

  .question {
    margin-top: 30px;

    font-weight: 700;
  }

  .questionActions {
    display: flex;
    Button {
      margin-top: 40px;
      margin-right: 15px;
    }
  }

  .banner {
    margin-top: 30px;
  }
`;

export default SCActionInfoCard;
