import React from 'react';
import { useTranslation } from '../../../i18n';
import RadialChart from '../RadialChart/RadialChart';
import SCInitialTutorialScoring from './InitialTutorialScoring.style';

const InitialTutorialScoring = () => {
  const i18n = useTranslation();
  return (
    <SCInitialTutorialScoring>
      <p>{i18n.t('tutorials.initialTutorial.score1')}</p>
      <div>
        <div className="InitialTutorialScoring_ScoreDetails">
          <div className="InitialTutorialScoring_SideContainer">
            <div className="InitialTutorialScoring_InfoContainer">
              <div className="InitialTutorialScoring_InfoHeader">
                <h3>
                  {i18n.t('tutorials.initialTutorial.yourInternalProtection')}
                </h3>
              </div>
              <div
                className="InitialTutorialScoring_InfoContent"
                style={{ alignItems: 'flex-start' }}>
                <p>
                  {i18n.t(
                    'tutorials.initialTutorial.yourInternalProtectionText'
                  )}
                </p>
              </div>
            </div>
            <hr />
          </div>
          <div className="InitialTutorialScoring_ChartContainer">
            <RadialChart score={7.6} />
          </div>
          <div className="InitialTutorialScoring_SideContainer">
            <hr />
            <div className="InitialTutorialScoring_InfoContainer">
              <div className="InitialTutorialScoring_InfoHeader">
                <h3>
                  {i18n.t('tutorials.initialTutorial.yourInternetExposure')}
                </h3>
              </div>
              <div
                className="InitialTutorialScoring_InfoContent"
                style={{ alignItems: 'flex-end' }}>
                <p>
                  {i18n.t('tutorials.initialTutorial.yourInternetExposureText')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="InitialTutorialScoring_BottomInfoHeader">
          <hr />
          <h3>{i18n.t('tutorials.initialTutorial.employeeAwareness')}</h3>
          <p>{i18n.t('tutorials.initialTutorial.employeeAwarenessText')}</p>
        </div>
      </div>
    </SCInitialTutorialScoring>
  );
};

export default InitialTutorialScoring;
