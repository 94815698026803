import React from 'react';
import {
  faCircleMinus,
  faCirclePlus,
  faGlobe,
} from '@fortawesome/pro-regular-svg-icons';
import SCFilter from './Filter.style';
import Button from '../../atoms/Button/Button';
import Select from '../../atoms/Select/Select';

const Filter = ({
  filterValues,
  selectedValue,
  onChangeFilter,
  addButtonText,
  removeButtonText,
  addButtonOnClick,
  removeButtonOnClick,
  removeButtonDisabled = false,
}) => {
  return (
    <SCFilter>
      <div className="filter-select-container">
        <Select
          onChange={onChangeFilter}
          icon={faGlobe}
          values={filterValues}
          selectedValue={selectedValue}
          design="round"
        />
      </div>

      <Button
        color="white"
        size="mid"
        onClick={() => {
          addButtonOnClick();
        }}
        icon={faCirclePlus}
        text={addButtonText}
      />

      {removeButtonText && removeButtonOnClick && (
        <Button
          color="white"
          size="mid"
          onClick={() => {
            removeButtonOnClick();
          }}
          icon={faCircleMinus}
          text={removeButtonText}
          disabled={removeButtonDisabled}
        />
      )}
    </SCFilter>
  );
};

export default Filter;
