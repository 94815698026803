import styled from '@emotion/styled';

const SCActionFAQCard = styled.div`
  .FAQ {
    margin-top: 20px;
  }

  .FAQ:first-child {
    margin-top: 0px;
  }
`;

export default SCActionFAQCard;
