import React from 'react';
import ContactLegalInformation from '../../molecules/ContactLegalInformation/ContactLegalInformation';
import Navbar from '../../Partners/Navbar/Navbar';
import SCOnboardLayout from './OnboardLayout.style';

const OnboardLayout = ({
  children,
  centerChildren,
  displayNavbar = true,
  displayFooter = true,
}) => {
  return (
    <SCOnboardLayout className="wings-section-background">
      {displayNavbar && <Navbar />}

      <div className={centerChildren && 'onboard-elements'}>{children}</div>

      {displayFooter && (
        <ContactLegalInformation color="transparent" size="section" separator />
      )}
    </SCOnboardLayout>
  );
};
export default OnboardLayout;
