import styled from '@emotion/styled';

const SCPartnerClientControlPanelSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .section-header-container {
    position: sticky;
    top: 97.5px;
    z-index: 10;
    background-color: var(--white);
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0px 5px 15px -2px rgba(121, 136, 151, 0.2);
  }

  .control-panel-summary-widgets {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .control-panel-summary-widgets > div {
    min-height: 300px;
  }
`;

export default SCPartnerClientControlPanelSummary;
