export const getSuspiciousText = (score) => {
  let securityText = 'pending';

  if (score === undefined || score === null || score > 10 || score < 0) {
    return 'pending';
  }

  if (score >= 0 && score < 2.5) {
    securityText = 'lowSuspicious';
  }

  if (score >= 2.5 && score < 5) {
    securityText = 'mediumSuspicious';
  }

  if (score >= 5 && score < 7.5) {
    securityText = 'highSuspicious';
  }

  if (score >= 7.5 && score <= 10) {
    securityText = 'criticalSuspicious';
  }

  return securityText;
};

export const getSuspiciousColor = (score) => {
  let securityColor = 'default';

  if (score === undefined || score === null || score > 10 || score < 0) {
    return 'default';
  }

  if (score >= 0 && score < 2.5) {
    securityColor = 'green';
  }

  if (score >= 2.5 && score < 5) {
    securityColor = 'orange';
  }

  if (score >= 5 && score < 7.5) {
    securityColor = 'lightRed';
  }

  if (score >= 7.5 && score <= 10) {
    securityColor = 'red';
  }

  return securityColor;
};
