import styled from '@emotion/styled';

const SCConfigureDmarc = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  .configureDmarc_Actions {
    display: flex;
    justify-content: space-between;
  }
`;

export default SCConfigureDmarc;
