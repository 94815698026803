import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showPopUp } from '../../redux/actions/popUp.actions';
import { useTranslation } from '../../i18n';
import { ERRORS } from '../../utils/constants/errors';
import emailProtectionService from '../../services/emailProtection/emailProtection.service';

const useInitEmailProtection = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();

  const [loading, setLoading] = useState(false);

  const [areMXRecordsInitialized, setAreMXRecordsInitialized] = useState(false);

  const init = async () => {
    setLoading(true);

    emailProtectionService
      .init()
      .then(() => {
        setAreMXRecordsInitialized(true);
      })
      .catch((e) => {
        const { data } = e.response;
        dispatch(
          showPopUp('notification', {
            notificationType: 'error',
            title: i18n.t('common.error'),
            closeButtonText: i18n.t('common.close'),
            text:
              data === ERRORS.DOMAIN_ALREADY_EXISTS
                ? i18n.t('mailbox-security.unableActivateProtection')
                : i18n.t('common.errorTryLater'),
            ...(data === ERRORS.DOMAIN_ALREADY_EXISTS && {
              additionalButtonText: i18n.t('contactUs.contactSupport'),
              onButtonClick: () => Tawk_API.toggle(),
            }),
          })
        );
        throw e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    init,
    loading,
    areMXRecordsInitialized,
  };
};

export default useInitEmailProtection;
