/**
 * Method that checks if the DNI is valid
 * @param {string} dni
 * @return {boolean}
 */
const isValidDNI = (dni) => {
  const regDNI = new RegExp(/^\d{8}[a-zA-Z]$/);
  const controlLetters = 'TRWAGMYFPDXBNJZSQVHLCKET';

  if (regDNI.test(dni)) {
    let number = dni.slice(0, -1);
    const letter = dni.slice(-1);

    number %= 23;

    const controlLetter = controlLetters.slice(number, number + 1);

    return letter.toUpperCase() === controlLetter;
  }

  return false;
};

export default isValidDNI;
