import axios from 'axios';
import {
  filtersToString,
  sortToString,
} from '../../utils/functions/queryParameters';

const init = () => {
  return axios.post('/email-protection/init');
};

const release = (emailId) => {
  return axios.post(`/email-protection/emails/${emailId}/release`);
};

const remove = (emailId) => {
  return axios.delete(`/email-protection/emails/${emailId}`);
};

const fetchQuarantineEmail = (id) => {
  return axios.get(`/email-protection/quarantine/${id}`);
};

const fetchQuarantineEmails = ({ sorter, pagination, filters }) => {
  return axios.get('/email-protection/quarantine', {
    params: {
      order: sortToString(sorter),
      page: pagination.current,
      limit: pagination.pageSize,
      filter: filtersToString(filters),
    },
  });
};

export default {
  init,
  release,
  remove,
  fetchQuarantineEmail,
  fetchQuarantineEmails,
};
