import styled from '@emotion/styled';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Button from '../../atoms/Button/Button';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const SCWebsiteSecurityPopup = styled.div`
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;

    p {
      font-size: 14px;
      line-height: 20px;
    }

    p:last-child {
      margin-bottom: 0px;
    }

    h1 {
      font-size: 25px;
      margin-bottom: 20px;
      text-align: left;
    }
  }
`;

const WebsiteSecurityPopup = ({ faqData }) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();

  return (
    <ModalLayout>
      <SCWebsiteSecurityPopup>
        <h1>{faqData.title}</h1>
        <div className="modal-card-content">{faqData.answer}</div>
        <div className="modal-card-buttons">
          <Button
            text={i18n.t('faqText.acknowledged')}
            onClick={() => dispatch(showPopUp(null))}
            size="full"
            color="bluishGrey"
          />
        </div>
      </SCWebsiteSecurityPopup>
    </ModalLayout>
  );
};

const mapStateToProps = (state) => ({
  popUp: state.popUp,
});

export default connect(mapStateToProps)(WebsiteSecurityPopup);
