import styled from '@emotion/styled';

const SCButtonHandler = styled.button`
  background: ${({ checked }) =>
    checked ? 'var(--red)' : 'var(--light-grey)'};
  height: 35px;
  min-width: 75px;
  border-radius: 50vh;
  border: none;
  /* border: 1px solid
    ${({ checked }) => (checked ? 'var(--red)' : 'var(--light-grey-3)')}; */
  position: relative;
  /* padding: 4px 10px; */
  color: ${({ checked }) => (checked ? 'var(--white)' : 'var(--bluish-grey)')};
  font-weight: 500;
  font-size: 14px;
  text-align: ${({ checked }) => (checked ? 'left' : 'right')};

  .InnerToggleButton {
    position: absolute;
    top: 0px;
    left: ${({ checked }) => (checked ? '42px' : '0px')};
    width: 35px;
    height: 35px;
    border-radius: 50vh;
    transition: left 0.25s;
    padding: 0 !important;
    background: ${({ checked }) =>
      checked ? 'var(--white)' : 'var(--light-grey-4)'};
    border: 3px solid
      ${({ checked }) => (checked ? 'var(--red)' : 'var(--light-grey)')};
  }

  :hover {
    color: ${({ checked }) =>
      checked ? 'var(--light-grey-4)' : 'var(--bluish-grey)'};
    background: ${({ checked }) =>
      checked ? 'var(--red)' : 'var(--light-grey)'};
  }

  .text {
    position: absolute;
    top: 7px;
    left: ${({ checked }) => (checked ? '16px' : '42px')};
  }
`;

export default SCButtonHandler;
