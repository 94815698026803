import useInternationalization from '../../../hooks/Internationalization/useInternationalization';

const COMPANY_ID_EXAMPLES = {
  es: { name: 'CIF', example: 'A1234567A' },
  mx: { name: 'RFC', example: 'XXXX000000XXX' },
  br: { name: 'CNPJ', example: '00000000000000' },
};

const getCompanyIDExample = () => {
  const { currentLocale } = useInternationalization();

  const defaultCompanyIDExample = { name: 'ID', example: '' };

  return COMPANY_ID_EXAMPLES[currentLocale] || defaultCompanyIDExample;
};

export default getCompanyIDExample;
