import React from 'react';
import SCLabel from './Label.style';

const Label = ({ value, color, width }) => {
  return (
    <SCLabel color={color ?? 'default'} width={width}>
      {value || '-'}
    </SCLabel>
  );
};

export default Label;
