import styled from '@emotion/styled';

const SCConfigureSpf = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  .configureSpf_Actions {
    display: flex;
    justify-content: space-between;

    span {
      width: 100%;
      background: #dde3e9;
      margin-right: 10px;
    }
  }

  .configureSpfRecord {
    display: flex;
    flex-direction: column;

    p {
      background: #dde3e9;
    }
  }
`;

export default SCConfigureSpf;
