import styled from '@emotion/styled';

const SCSectionCard = styled.div`
  background-color: white;
  border-radius: var(--section-radius);
  height: 100%;
  width: 100%;
  position: relative;
  -webkit-box-shadow: 2px 2px 20px 0px rgba(121, 136, 151, 0.08);
  box-shadow: 2px 2px 20px 0px rgba(121, 136, 151, 0.08);
  overflow: ${({ hasOverflow }) => (hasOverflow ? '' : 'hidden')};
  display: flex;
  flex-direction: column;

  .content {
    padding: ${({ hasPadding }) => hasPadding && '30px'};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    place-content: baseline;
    height: 100%;
  }

  @media (max-width: 576px) {
    .content {
      padding: ${({ hasPadding }) => hasPadding && '30px 20px'};
    }
  }
`;

export default SCSectionCard;
