import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { auth } from '../../firebase';
import LICENSEES from '../../utils/constants/licensees';
import { PAYMENT_METHODS } from '../../utils/constants/payments';

function AuthRouteGuard({ children, isAccessingPartner, isAccessingAdmin }) {
  const client = useSelector((redux) => redux.client);
  const user = useSelector((redux) => redux.user);

  const redirectAuthorizedUser = () => {
    if (!auth.currentUser.emailVerified) {
      return <Navigate to="/email-verification" />;
    }

    if (
      client?.licensee === LICENSEES.santander &&
      client?.status.paymentMethod === PAYMENT_METHODS.NONE
    ) {
      return (
        <Navigate to="/activacion-prueba-gratuita-cyber-guardian-cliente-santander" />
      );
    }

    if (client.partner && !client.licensee && !isAccessingPartner) {
      return <Navigate to="/partner" />;
    }

    if (user?.superAdmin && !isAccessingAdmin) {
      return <Navigate to="/admin/leads" />;
    }

    return children;
  };

  if (!auth.currentUser) {
    window.location.href = '/sign-in';
  }

  return redirectAuthorizedUser();
}

export default AuthRouteGuard;
