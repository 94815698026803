import React from 'react';
import { faEarthAmericas } from '@fortawesome/pro-regular-svg-icons';
import SCRegionBanner from './RegionBanner.style';
import Icon from '../../atoms/Icon/Icon';
import { useTranslation } from '../../../i18n';
import RegionSelector from '../../atoms/RegionSelector/RegionSelector';
import useInternationalization from '../../../hooks/Internationalization/useInternationalization';
import {
  getSantanderEnvironmentRegionText,
  isSantanderEnvironment,
} from '../../../utils/locale';

const RegionBanner = () => {
  const i18n = useTranslation();
  const { currentLocale } = useInternationalization();

  return (
    <SCRegionBanner>
      <Icon
        iconName={faEarthAmericas}
        size="medium"
        color="var(--bluish-grey)"
        style={{ paddingTop: '3px' }}
      />
      {isSantanderEnvironment ? (
        <p style={{ paddingBottom: '10px' }}>
          {i18n.t('regionSelector.registerIn')}&nbsp;
          <b>{getSantanderEnvironmentRegionText(currentLocale)}</b>
        </p>
      ) : (
        <div className="region-text-container">
          <p>
            <span>{i18n.t('regionSelector.boldText')}</span>
            {i18n.t('regionSelector.text')}
          </p>
          <RegionSelector text={i18n.t('regionSelector.registerIn')} />
        </div>
      )}
    </SCRegionBanner>
  );
};

export default RegionBanner;
