import { useTranslation } from '../../i18n';
import {
  getInternationalizedImgLang,
  getLocaleBySubdomain,
} from '../../utils/locale';

function useInternationalization() {
  const { lang } = useTranslation();
  const currentLocale = getLocaleBySubdomain();
  return {
    currentLocale,
    internationalizedImgLang: getInternationalizedImgLang(lang),
  };
}

export default useInternationalization;
