import axios from 'axios';
import { toBase64 } from '../../../../utils/functions/base64';

export const dismissDomainImpersonation = (
  clientId: string,
  domain: string,
  impersonation: string
) =>
  axios.get(
    `/api/domain-impersonation/clients/${clientId}/domains/${toBase64(
      domain
    )}/impersonations/${toBase64(impersonation)}/dismiss`
  );

export const reportDomainImpersonation = (
  clientId: string,
  domain: string,
  impersonation: string
) =>
  axios.get(
    `/api/domain-impersonation/clients/${clientId}/domains/${toBase64(
      domain
    )}/impersonations/${toBase64(impersonation)}/report`
  );

export const resolveDomainImpersonation = (
  clientId: string,
  domain: string,
  impersonation: string
) =>
  axios.get(
    `/api/domain-impersonation/clients/${clientId}/domains/${toBase64(
      domain
    )}/impersonations/${toBase64(impersonation)}/resolve`
  );

export const toReportDomainImpersonation = (
  clientId: string,
  domain: string,
  impersonation: string
) =>
  axios.get(
    `/api/domain-impersonation/clients/${clientId}/domains/${toBase64(
      domain
    )}/impersonations/${toBase64(impersonation)}/toreport`
  );
