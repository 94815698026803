import styled from '@emotion/styled';
import { Table } from 'antd';

const AntDTable = styled(Table)`
  border: 1px solid var(--light-grey-3) !important;
  border-radius: 10px !important;

  .ant-table-column-sort {
    background-color: transparent !important;
  }

  .selected-row:hover {
    background-color: var(--red);
    color: black;
  }

  .ant-pagination .ant-pagination-item-active {
    border-color: var(--red);
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: var(--red);
  }

  .ant-spin .ant-spin-dot-holder {
    color: var(--red);
  }

  a,
  a:not([href]):not([tabindex]) {
    cursor: pointer;
    color: inherit;
  }

  .ant-btn-primary {
    background: var(--red);
  }

  .ant-pagination-item-active {
    > a {
      color: var(--red) !important;
    }
  }

  ${({ onRow }) => {
    const onRowImplementation = onRow && onRow({});
    return onRowImplementation?.onClick
      ? `
        tbody tr:hover {
          cursor: pointer;
        }
        `
      : '';
  }}

  .ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--red);
  }
`;

export default AntDTable;
