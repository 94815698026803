import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import sha1 from 'sha1';

import qs from 'query-string';
import { faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons';

import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Partners/Navbar/Navbar';
import { showPasswordIcon } from '../../components/Misc/Icons';
import { useTranslation } from '../../i18n';
import { TrackButton, InfoKey } from '../../track';
import SCAccountCreated from './AccountCreated.style';
import Icon from '../../components/atoms/Icon/Icon';
import Button from '../../components/atoms/Button/Button';

const AccountCreatedWrapper = (props) => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  return <AccountCreated {...props} i18n={i18n} navigate={navigate} />;
};

class AccountCreated extends Component {
  static generatePassword() {
    const length = 10;
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; i += 1) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  constructor(props) {
    super(props);
    const queries = qs.parse(window.location.search);
    this.state = {
      showDialog: false,
      oobCode: queries.oobCode,
      continueUrl: queries.continueUrl,
      mode: queries.mode,
      password: AccountCreated.generatePassword(),
      showPassword: true,
    };
  }

  componentDidMount() {
    const { firebase } = this.props;
    const auth = firebase.auth();
    const { oobCode, mode, continueUrl } = this.state;
    if (oobCode && mode === 'verifyEmail') {
      auth.applyActionCode(oobCode).then(() => {
        if (continueUrl) {
          window.location.href = continueUrl;
        }
      });
    }
    if (auth && auth.currentUser && auth.currentUser.emailVerified) {
      if (continueUrl) {
        window.location.href = continueUrl;
      } else {
        window.location.href = '/client';
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { auth, navigate } = this.props;
    if (
      !auth.isLoaded &&
      nextProps.auth.isLoaded &&
      this.state.mode === 'verifyEmail'
    ) {
      if (nextProps.auth.isEmpty) {
        // its not signed in redirect to signed-in
        navigate('/sign-in');
      }
      if (nextProps.auth.currentUser.emailVerified) {
        // Email is verified redirect to dashboard
        window.location.href = '/client';
      }
    }
  }

  componentDidUpdate() {
    const { auth } = this.props;
    if (auth.currentUser?.emailVerified) {
      window.location.href = '/client';
    }
  }

  checkPwnedPassword() {
    const { password } = this.state;
    if (!password) return;
    const digit5 = sha1(password).substring(0, 5);
    const checkRest = sha1(password).replace(digit5, '');
    this.setState({ hasPwned: 0 });
    axios
      .get(`https://api.pwnedpasswords.com/range/${digit5}`)
      .then((res) => {
        const pass = res.data.split('\r\n');
        // check each row of array with hash passwords and see if user's password exists
        for (let i = 0; i < pass.length; i += 1) {
          if (pass[i].indexOf(checkRest.toUpperCase()) > -1) {
            // oops password has been pwned!
            this.setState({
              hasPwned: pass[i].substring(
                pass[i].indexOf(':') + 1,
                pass[i].length
              ),
              allowAutoPassword: true,
            });
            break;
          }
        }
      })
      .catch((error) => this.setState({ hasError: error }));
  }

  changeHandlerText(e) {
    const { name, value } = e.target;
    if (name) {
      this.setState({ [name]: value });
    }
  }

  togglePassword() {
    const { showPassword } = this.state;
    if (showPassword) {
      this.setState({ showPassword: false });
    } else {
      this.setState({ showPassword: true });
    }
  }

  randomPassword() {
    this.setState({ password: AccountCreated.generatePassword() }, () =>
      this.checkPwnedPassword()
    );
  }

  // Submit email to begin Passwordless transaction
  sendMagicLink() {
    const { firebase } = this.props;
    firebase
      .auth()
      .currentUser.sendEmailVerification({ url: window.location.origin })
      .then(this.setState({ showDialog: true }))
      .catch((e) => console.log(e.message));
  }

  hideDialog() {
    this.setState({ showDialog: false });
  }

  changePassword() {
    const { firebase } = this.props;
    const auth = firebase.auth();
    const { password, password2, hasPwned, oobCode } = this.state;
    if (!password) {
      InfoKey('emailVerification.errorFillForm');
      return;
    }
    if (password.length < 10) {
      InfoKey('emailVerification.error10Digit');
      return;
    }
    if (password !== password2) {
      InfoKey('emailVerification.errorPasswordsNotMatch');
      return;
    }
    if (hasPwned > 0) {
      InfoKey('emailVerification.errorPasswordBreached');
      return;
    }
    auth
      .verifyPasswordResetCode(oobCode)
      .then((email) => {
        const accountEmail = email;

        // TODO: Show the reset screen with the user's email and ask the user for
        // the new password.

        // Save the new password.
        auth
          .confirmPasswordReset(oobCode, password)
          .then((resp) => {
            this.setState({ showDialog: true });
          })
          .catch((e) => console.log(e));
      })
      .catch((error) => console.log(error));
  }

  render() {
    const { showDialog, oobCode, mode } = this.state;
    const { i18n } = this.props;

    if (mode === 'resetPassword') {
      return (
        <>
          <Navbar />
          <div
            className="authComponent container-fluid px-sm-5 py-sm-5"
            style={{
              backgroundColor: '#eff9fe',
              minHeight: 'calc(100vh - 395px)',
            }}>
            <div className="row px-sm-5 py-sm-5">
              <div>
                {showDialog && (
                  <div className="fixbreach-dialog" id="fixbreachdialog">
                    <div className="fixbreach-text">
                      {i18n.t('emailVerification.passwordChanged')}
                    </div>
                    <div className="fixbreach-buttons">
                      <button
                        type="button"
                        className="button large red"
                        style={{
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          display: 'block',
                        }}
                        onClick={() => {
                          this.hideDialog();
                          TrackButton('emailVerification.ok');
                        }}>
                        {i18n.t('emailVerification.ok')}
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-12 col-lg-12 col-xl-12 sign-header">
                {i18n.t('emailVerification.passwordUpdate')}
              </div>
              <div style={{ width: '100%' }}>
                <div className="form-group" style={{ marginBottom: 0 }}>
                  <div className="input-group">
                    <input
                      name="password"
                      id="password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      className="form-control"
                      value={this.state.password || ''}
                      onChange={(e) => this.changeHandlerText(e)}
                      onBlur={() => this.checkPwnedPassword()}
                      placeholder={i18n.t('emailVerification.typeYourPassword')}
                    />
                    <div className="input-group-append">
                      <span
                        role="button"
                        tabIndex="0"
                        className={`${
                          this.state.showPassword
                            ? 'showPasswordIcon '
                            : 'hidePasswordIcon '
                        }input-group-text`}
                        onClick={() => this.togglePassword()}
                        onKeyDown={() => this.togglePassword()}
                        id="showPasswordIcon">
                        {showPasswordIcon}
                      </span>
                    </div>
                    {this.state.allowAutoPassword && (
                      <div
                        className="has_hidden_note"
                        style={{
                          display: 'inline-block',
                          color: '#1e88e5',
                          textDecoration: 'underline',
                          fontSize: '14px',
                        }}>
                        <button
                          type="button"
                          id="company_address_button"
                          className="btn btn-default"
                          style={{
                            background: 'white',
                            border: '1px solid #ced4da',
                            color: '#1e88e5',
                            marginLeft: '20px',
                          }}
                          onClick={() => {
                            this.randomPassword();
                            TrackButton('emailVerification.generatePassword');
                          }}>
                          {i18n.t('emailVerification.generatePassword')}
                        </button>
                        <p style={{ marginTop: '50px', marginLeft: '-20px' }}>
                          {i18n.t('emailVerification.createUnbreachedPassword')}
                        </p>
                      </div>
                    )}
                  </div>
                  <label style={{ fontSize: '12px' }}>
                    {this.state.hasPwned > 0 && (
                      <span>
                        {i18n.t('emailVerification.yourPasswordHasAppeared')}
                        <strong style={{ color: 'red' }}>
                          {' '}
                          {Number(this.state.hasPwned).toLocaleString()}{' '}
                        </strong>{' '}
                        {i18n.t('emailVerification.xTimesInBreaches')}
                      </span>
                    )}
                  </label>
                </div>
                <div className="form-group">
                  <input
                    name="password2"
                    type="password"
                    className="form-control"
                    value={this.state.password2 || ''}
                    onChange={(e) => this.changeHandlerText(e)}
                    placeholder={i18n.t('emailVerification.typeAgain')}
                  />
                </div>
                <button
                  style={{ marginLeft: 0 }}
                  id="emailButton"
                  disabled={showDialog}
                  type="button"
                  title="Send link"
                  className="btn btn-primary btn-fix btn-login"
                  onClick={() => {
                    this.changePassword();
                    TrackButton('emailVerification.changePassword');
                  }}>
                  {i18n.t('emailVerification.changePassword')}
                </button>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <SCAccountCreated>
        <div className="account-created-container">
          <div className="icon-container">
            <Icon
              iconName={faEnvelopeOpen}
              size="extraLarge"
              color="var(--red)"
            />
          </div>

          {!oobCode && (
            <div className="text-button-container">
              <h1>{i18n.t('emailVerification.oneStep')}</h1>
              <p>{i18n.t('emailVerification.accountCreated')}</p>
              <p>{i18n.t('emailVerification.noEmailReceived')}</p>
              {!showDialog ? (
                <Button
                  text={i18n.t('emailVerification.resendLink')}
                  onClick={() => {
                    this.sendMagicLink();
                    TrackButton('emailVerification.resendLink');
                  }}
                  size="full"
                  color="red"
                />
              ) : (
                <p className="email-link-sent">
                  {i18n.t('emailVerification.emailLinkSent')}
                </p>
              )}
            </div>
          )}

          {oobCode && (
            <p className="verifying-email">
              {i18n.t('emailVerification.emailVerifying')}
            </p>
          )}
        </div>
      </SCAccountCreated>
    );
  }
}

export function mapStateToProps(state) {
  return {
    auth: state.auth,
    firebase: state.firebase,
  };
}

AccountCreated.propTypes = {
  firebase: PropTypes.object.isRequired, // eslint-disable-line
  auth: PropTypes.object.isRequired, //eslint-disable-line
  location: PropTypes.object.isRequired, //eslint-disable-line
};

export default connect(mapStateToProps)(AccountCreatedWrapper);
