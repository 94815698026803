import React from 'react';
import { faMemoCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCBreachNotificationCard from './BreachNotificationCard.style.';

import { useTranslation } from '../../../i18n';
import { getDateFormat, parseDate } from '../../../utils/format/dataFormat';
import LabelDetailGroup from '../LabelDetailGroup/LabelDetailGroup';

const BreachNotificationCard = ({ data = {}, isHeaderClickable, loading }) => {
  const i18n = useTranslation();
  const navigate = useNavigate();

  const {
    monitoredEmails,
    compromisedEmails,
    totalLeaks,
    lastLeakAt,
    updatedAt,
  } = data;

  const parsedLastLeakAt = parseDate(lastLeakAt);
  const parsedUpdatedAt = parseDate(updatedAt);

  const LABEL_DETAILS = [
    {
      name: i18n.t('controlPanel.breachNotification.monitoredEmail'),
      value: monitoredEmails || '0',
    },
    {
      name: i18n.t('controlPanel.breachNotification.compromisedEmail'),
      value: compromisedEmails || '0',
      color: compromisedEmails ? 'red' : 'default',
    },
    {
      name: i18n.t('controlPanel.breachNotification.totalLeaks'),
      value: totalLeaks || '0',
      color: totalLeaks ? 'red' : 'default',
    },
    {
      name: i18n.t('controlPanel.breachNotification.lastDateLeaks'),
      value: lastLeakAt ? getDateFormat(parsedLastLeakAt) : '-',
    },
  ];

  const handleHeaderButtonOnClick = isHeaderClickable
    ? () => {
        navigate('/client/data-alert');
      }
    : undefined;

  return (
    <SCBreachNotificationCard>
      <SectionCard
        headerIcon={faMemoCircleInfo}
        headerTitle={i18n.t('controlPanel.breachNotification.title')}
        headerButtonOnClick={handleHeaderButtonOnClick}>
        <LabelDetailGroup
          labelDetails={LABEL_DETAILS}
          lastUpdateDate={parsedUpdatedAt}
          loading={loading}
        />
      </SectionCard>
    </SCBreachNotificationCard>
  );
};

export default BreachNotificationCard;
