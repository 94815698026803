const useTawkToScript = (language) => {
  const TAWK_TO_LANGUAGES_IDS = {
    es: process.env.REACT_APP_TAWKTO_WIDGET_ID_ES,
    en: process.env.REACT_APP_TAWKTO_WIDGET_ID_EN,
    'pt-BR': process.env.REACT_APP_TAWKTO_WIDGET_ID_PT_BR,
  };

  /**
   * Minimize widget if tawk is loaded
   */
  if (window.Tawk_API) {
    window.Tawk_API.minimize();
  }

  /**
   * Delete TawkTo scripts
   */
  const { scripts } = document;
  for (let i = 0; i < scripts.length; i += 1) {
    const script = scripts[i];

    if (script.src?.startsWith('https://embed.tawk.to/')) {
      script.parentNode.removeChild(script);
    }
  }

  /**
   * Delete anything related to tawk, otherwise new widget would not be loaded
   */
  for (const name in window) {
    if (
      window.hasOwnProperty(name) &&
      (name.includes('tawk') || name.includes('Tawk'))
    ) {
      delete window[name];
    }
  }

  /**
   * Remove old TawkTo created divs with class "widget-visible"
   */
  Array.from(document.getElementsByClassName('widget-visible')).forEach(
    (widgetVisibleElement) => {
      widgetVisibleElement.remove();
    }
  );

  /**
   * Almost the same code as original
   */
  window.Tawk_API = window.Tawk_API || {};
  window.Tawk_LoadStart = new Date();

  (function () {
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = `https://embed.tawk.to/6220c8521ffac05b1d7cc627/${
      TAWK_TO_LANGUAGES_IDS[language] ?? TAWK_TO_LANGUAGES_IDS.en
    }`; // Here we use correct url for locale
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s1.setAttribute('tawk', 'yes'); // This line is used to mark tawk script
    s0.parentNode.insertBefore(s1, s0);
  })();

  window.Tawk_API = window.Tawk_API || {};
  window.Tawk_API.onLoad = function () {
    // That displays "CallMeWidget.js" when TawkTo is loaded so there's no blink between both of them.
    const callMeWidgetElement = document.getElementById('call-me-widget');
    callMeWidgetElement.style.visibility = 'visible';

    const iconElement = callMeWidgetElement.querySelector('button > svg');
    iconElement.classList.add('call-me-widget-icon-animation');
  };
};

export default useTawkToScript;
