const currencyValueByLocale = {
  es: 'EUR',
  mx: 'MXN',
  br: 'BRL',
};

const getCurrencyByLocale = (locale) => {
  return currencyValueByLocale[locale] ?? currencyValueByLocale.es;
};

export default getCurrencyByLocale;
