import React from 'react';
import { faUserSecret } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCDomainImpersonationCard from './DomainImpersonationCard.style';
import LabelDetailGroup from '../LabelDetailGroup/LabelDetailGroup';
import { parseDate } from '../../../utils/format/dataFormat';

const DomainImpersonationCard = ({
  monitoredDomainsLength,
  data = {},
  isHeaderClickable,
  loading,
}) => {
  const navigate = useNavigate();
  const i18n = useTranslation();

  const { suspiciousDomains, updatedAt } = data;

  const LABEL_DETAILS = [
    {
      name: i18n.t('controlPanel.domainImpersonation.monitoredDomain'),
      value: monitoredDomainsLength,
    },

    {
      name: i18n.t('controlPanel.domainImpersonation.suspiciousDomains'),
      value: suspiciousDomains,
      color: suspiciousDomains && 'red',
    },
  ];

  const handleHeaderButtonOnClick = isHeaderClickable
    ? () => {
        navigate('/client/domainimpersonation');
      }
    : undefined;

  return (
    <SCDomainImpersonationCard>
      <SectionCard
        headerIcon={faUserSecret}
        headerTitle={i18n.t('controlPanel.widgetDomainImpersonation.title')}
        headerButtonOnClick={handleHeaderButtonOnClick}>
        <LabelDetailGroup
          labelDetails={LABEL_DETAILS}
          lastUpdateDate={parseDate(updatedAt)}
          loading={loading}
        />
      </SectionCard>
    </SCDomainImpersonationCard>
  );
};
export default DomainImpersonationCard;
