const isBreachForEmailResolved = (breach, email, clientId) => {
  if (email?.resolutions?.[clientId]?.[breach]?.resolved) {
    const now = new Date();
    return now >= email?.resolutions[clientId][breach].resolved.toDate();
  }
  return false;
};

const isBreachForEmailNotified = (breach, email, clientId) => {
  if (email?.resolutions?.[clientId]?.[breach]?.notified) {
    const now = new Date();
    return now >= email?.resolutions[clientId][breach].notified.toDate();
  }
  return false;
};

export const getBreachStatus = (breach) => {
  let isResolved = true;
  let isNotified = true;
  if (!breach?.emails) return 'noActionRequired';
  Object.keys(breach.emails).forEach((email) => {
    if (!breach.emails[email].resolved) isResolved = false;
    if (!breach.emails[email].notified) isNotified = false;
  });
  if (breach.dangerousLevel === 'critical') {
    if (isResolved) return 'resolved';
    if (isNotified) return 'notified';
    return 'actionRequired';
  }
  return 'noActionRequired';
};

export const getDangerousLevel = (breach) => {
  const oneYearBeforeNow = new Date();
  oneYearBeforeNow.setFullYear(oneYearBeforeNow.getFullYear() - 1);
  if (!breach?.date || !breach?.classes) return 'lessCritical';
  if (
    breach.classes.indexOf('passwords') === -1 &&
    breach.classes.indexOf('credit-cards') === -1
  )
    return 'lessCritical';
  if (breach.date.toDate() > oneYearBeforeNow) {
    return 'critical';
  }
  return 'lessCritical';
};

export const getBreachesV2 = (emails, clientId) => {
  const breaches = {};

  Object.keys(emails).forEach((key) => {
    if (emails[key].breaches) {
      const tmp = emails[key].breaches;
      Object.keys(tmp).forEach((subkey) => {
        if (breaches[subkey]) {
          breaches[subkey].emails[key] = {
            email: key,
            resolved: isBreachForEmailResolved(subkey, emails[key], clientId),
            notified: isBreachForEmailNotified(subkey, emails[key], clientId),
          };
        } else {
          breaches[subkey] = {
            ...tmp[subkey],
            name: subkey,
            dangerousLevel: getDangerousLevel(tmp[subkey]),
            emails: {},
          };
          breaches[subkey].emails[key] = {
            email: key,
            resolved: isBreachForEmailResolved(subkey, emails[key], clientId),
            notified: isBreachForEmailNotified(subkey, emails[key], clientId),
          };
        }
      });
    }
  });

  return breaches;
};

export const getLatestBreachV2 = (breaches) => {
  const breachesAr = Object.keys(breaches).map((key) => breaches[key]);
  const sortedBreaches = breachesAr.sort(
    (a, b) => b.date.toDate().getTime() - a.date.toDate().getTime()
  );
  return sortedBreaches[0];
};
