const sectors = [
  { id: 1, label: 'ManagementServices' },
  { id: 2, label: 'ArtsEntertainmentRecreation' },
  { id: 3, label: 'HomeWork' },
  { id: 4, label: 'GovernmentOrganization' },
  { id: 5, label: 'FinancialServices' },
  { id: 6, label: 'RealEstateServices' },
  { id: 7, label: 'ProfessionalScientificTechnicalActivities' },
  { id: 8, label: 'HealthCareServices' },
  { id: 9, label: 'AgricultureForestryFishing' },
  { id: 10, label: 'RetailWholesale' },
  { id: 11, label: 'Construction' },
  { id: 12, label: 'Education' },
  { id: 13, label: 'HotelAccommodation' },
  { id: 14, label: 'Manufacturing' },
  { id: 15, label: 'MiningQuarrying' },
  { id: 16, label: 'InformationTelecommunicationsTechnology' },
  { id: 17, label: 'WaterWasteSupply' },
  { id: 18, label: 'EnergyGasAirSupply' },
  { id: 19, label: 'TransportStorage' },
  { id: 20, label: 'OtherServices' },
];

export default sectors;
