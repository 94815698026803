import React from 'react';
import { useTranslation } from '../../../i18n';
import SCSingleActionProgress from './SingleActionProgress.style';
import ProgressBar from '../../atoms/ProgressBar/ProgressBar';

const SingleActionProgress = ({ action }) => {
  const i18n = useTranslation();

  return (
    <SCSingleActionProgress>
      <div>
        <div className="progressData">
          <h4>{i18n.t('action-center.yourProgress')}</h4>
          <h3>
            {i18n.t(`action-center.actions.${action.type}.progress`, {
              number: action.passCount,
              total: action.totalCount,
            })}
          </h3>
        </div>
        <div>
          <ProgressBar
            height="15px"
            backgroundColor="var(--light-grey)"
            completed={action.passCount}
            total={action.totalCount}
            displayCircle
          />
        </div>
      </div>
    </SCSingleActionProgress>
  );
};

export default SingleActionProgress;
