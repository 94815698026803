import {
  faArrowUpRightFromSquare,
  faList,
} from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCImpersonationInfoCard from './ImpersonationInfoCard.style';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { firestore } from '../../../firebase';
import {
  toReportDomainImpersonation,
  dismissDomainImpersonation,
  reportDomainImpersonation,
} from '../../Dashboard/Sections/DomainImpersonation/helper';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import ScoreExplanation from '../../molecules/ScoreExplanation/ScoreExplanation';
import { getSuspiciousText } from '../../../utils/functions/suspiciousSeverity';
import Icon from '../../atoms/Icon/Icon';
import { getDateFormat } from '../../../utils/format/dataFormat';

const ImpersonationInfoCard = ({ domain, impersonation }) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useSelector((redux) => redux.client);

  const [screenshot, setScreenshot] = useState('');

  const getScreenShotByDomain = async () => {
    const doc = await firestore
      .collection('screenshots')
      .doc(impersonation.name)
      .get();
    if (!doc.exists || !doc.data) return null;
    const data = doc.data();
    if (!data) return null;
    return data.data;
  };

  const preprocessScreenshot = (img) => {
    if (!img || !img.toBase64) return '';
    const data = img.toBase64();
    const clean = data.replaceAll('AooooAKKKKACiiig', ''); // high concentration of this indicates full white img
    if (clean.length < 1000 && clean.length / data.length < 0.1) return '';
    return data;
  };

  useEffect(() => {
    getScreenShotByDomain().then((domainScreenshot) => {
      const sch = preprocessScreenshot(domainScreenshot);
      setScreenshot(sch);
    });
  }, []);

  return (
    <SCImpersonationInfoCard>
      <SectionCard
        headerIcon={faList}
        headerTitle={i18n.t('domainImpersonation.domainDetails.primaryTitle')}>
        <h1>{impersonation.name}</h1>
        <div className="ImpersonationInfoCard_MainContainer">
          <div className="ImpersonationInfoCard_ColumnContainer">
            <div className="ImpersonationInfoCard_ImageContainer">
              {!screenshot && (
                <div
                  style={{
                    width: '100%',
                    maxWidth: '500px',
                    height: '300px',
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    padding: '20px',
                    border: '2px solid #b3b3b3',
                  }}>
                  {i18n.t('domainImpersonation.noScreenshot')}{' '}
                  {impersonation.name}
                </div>
              )}
              {screenshot && (
                <img
                  src={`data:image/jpeg;base64,${screenshot}`}
                  style={{ width: '100%', maxWidth: '500px', height: '310px' }}
                  alt={`${i18n.t('domainImpersonation.noScreenshot')} ${
                    impersonation.name
                  }`}
                />
              )}
            </div>

            <div className="ImpersonationInfoCard_ActionContainer">
              <p>
                {i18n.t(
                  'domainImpersonation.resolvePossibleImpersonation.isImitating'
                )}
              </p>
              <div className="ImpersonationInfoCard_Actions">
                <Button
                  color={
                    impersonation.status === 'not_reported' ||
                    impersonation.status === 'reported'
                      ? 'bluishGrey'
                      : 'white'
                  }
                  text={i18n.t(
                    'domainImpersonation.resolvePossibleImpersonation.yes'
                  )}
                  size="mid"
                  onClick={() => {
                    toReportDomainImpersonation(
                      client.id,
                      domain,
                      impersonation.name
                    );
                  }}
                />
                <Button
                  color={
                    impersonation.status === 'dismissed' ||
                    impersonation.status === 'resolved'
                      ? 'bluishGrey'
                      : 'white'
                  }
                  text={i18n.t(
                    'domainImpersonation.resolvePossibleImpersonation.no'
                  )}
                  size="mid"
                  onClick={() => {
                    dismissDomainImpersonation(
                      client.id,
                      domain,
                      impersonation.name
                    );
                  }}
                />
                <Button
                  color="white"
                  text={i18n.t(
                    'domainImpersonation.resolvePossibleImpersonation.notSure'
                  )}
                  size="mid"
                  onClick={() => {
                    dispatch(
                      showPopUp('SentEmailTo', {
                        type: 'impersonation',
                        title: i18n.t(
                          'domainImpersonation.requestManualReview.modalTitle'
                        ),
                        data: {
                          domainName: impersonation.name,
                        },
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="ImpersonationInfoCard_ColumnContainer">
            <div className="ImpersonationInfoCard_Info">
              <span>
                {i18n.t('domainImpersonation.domainDetails.dateCreated')}
              </span>
              <p>
                {(impersonation.date &&
                  getDateFormat(impersonation.date.toDate())) ??
                  i18n.t('websiteSecurity.unknown')}
              </p>
            </div>
            <div className="ImpersonationInfoCard_Info">
              <span>
                {i18n.t('domainImpersonation.domainDetails.expirationDate')}
              </span>
              <p>
                {(impersonation.expirationDate &&
                  getDateFormat(impersonation.expirationDate.toDate())) ??
                  i18n.t('websiteSecurity.unknown')}
              </p>
            </div>
            <div className="ImpersonationInfoCard_Info">
              <span>
                {i18n.t('domainImpersonation.domainDetails.updateDate')}
              </span>
              <p>
                {(impersonation.updateDate &&
                  getDateFormat(impersonation.updateDate.toDate())) ??
                  i18n.t('websiteSecurity.unknown')}
              </p>
            </div>
            <div className="ImpersonationInfoCard_Info">
              <span>
                {i18n.t('domainImpersonation.domainDetails.emailServer')}
              </span>
              <p>{impersonation.emails}</p>
            </div>
            <div className="ImpersonationInfoCard_Info">
              <span>
                {i18n.t('domainImpersonation.domainDetails.domainRegistrar')}
              </span>

              <p>
                {impersonation.registrar
                  ? impersonation.registrar.split(',')[0]
                  : ''}
              </p>
            </div>
          </div>
        </div>
        <div className="ImpersonationInfoCard_BannerContainer">
          {(impersonation.status === 'dismissed' ||
            impersonation.status === 'resolved') && (
            <InfoBanner
              type="info"
              text={[
                i18n.t(
                  'domainImpersonation.resolvePossibleImpersonation.3Resolvedbody'
                ),
              ]}
              onClickButton={() => {
                navigate('/client/domainimpersonation');
              }}
              buttonText={i18n.t(
                'domainImpersonation.resolvePossibleImpersonation.goBack'
              )}
            />
          )}
          {impersonation.status === 'reported' && (
            <InfoBanner
              type="info"
              text={[
                i18n.t(
                  'domainImpersonation.resolvePossibleImpersonation.3ActionTakenbody'
                ),
              ]}
              onClickButton={() => {
                navigate('/client/domainimpersonation');
              }}
              buttonText={i18n.t(
                'domainImpersonation.resolvePossibleImpersonation.goBack'
              )}
            />
          )}
          {impersonation.status === 'not_reported' && (
            <InfoBanner
              type="warn"
              text={[
                i18n.t(
                  'domainImpersonation.resolvePossibleImpersonation.3subheaderImpersonating_first'
                ),
                i18n.t(
                  'domainImpersonation.resolvePossibleImpersonation.3subheaderImpersonating_seconnd'
                ),
                i18n.t(
                  'domainImpersonation.resolvePossibleImpersonation.3subheaderImpersonating_third'
                ),
                <a
                  href="https://www.icann.org/resources/pages/customer-support-2015-06-22-en"
                  target="_blank"
                  rel="noreferrer">
                  <div className="icann-icon-text-container">
                    <Icon size="medium" iconName={faArrowUpRightFromSquare} />
                    {i18n.t(
                      'domainImpersonation.resolvePossibleImpersonation.3ActionTakenReadMore'
                    )}
                  </div>
                </a>,
              ]}
              onClickButton={() => {
                reportDomainImpersonation(
                  client.id,
                  domain,
                  impersonation.name
                );
              }}
              buttonText={i18n.t(
                'domainImpersonation.resolvePossibleImpersonation.takeAction'
              )}
              width="fit-content"
            />
          )}
        </div>
        <div className="ImpersonationInfoCard_Score">
          <p
            className="ImpersonationInfoCard_ScoreResume"
            // style={{
            //   color: `var(--${
            //     impersonation.score !== 'N/A'
            //       ? getSecurityColor(10 - impersonation.score)
            //       : 'light-grey-3'
            //   })`,
            // }}
          >
            {i18n.t('domainImpersonation.suspect')}{' '}
            {i18n
              .t(
                `domainImpersonation.suspiciusLevels.${
                  impersonation.score !== 'N/A'
                    ? getSuspiciousText(impersonation.score)
                    : 'unknown'
                }`
              )
              .toLowerCase()}{' '}
            {impersonation.score !== 'N/A' && (
              <>
                ({impersonation.score}{' '}
                {i18n.t(
                  'domainImpersonation.domainDetails.suspiciousDetails.points'
                )}
                )
              </>
            )}
          </p>
          {impersonation && (
            <ScoreExplanation observations={impersonation.observations} />
          )}
        </div>
      </SectionCard>
    </SCImpersonationInfoCard>
  );
};

export default ImpersonationInfoCard;
