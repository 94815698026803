import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../i18n';
import { getClientSecret } from '../../../services/subscriptions/subscription.service';
import StripeEditCardForm from '../StripeEditCardForm/StripeEditCardForm';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import SCStripeEditCard from './StripeEditCard.style';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeEditCard = () => {
  const i18n = useTranslation();
  const [clientSecret, setClientSecret] = useState();
  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      const { data } = await getClientSecret();
      setClientSecret(data?.data?.clientSecret);
    } catch (e) {
      console.log('Error', e);
    }
  }, []);

  const ELEMENTS_OPTIONS = {
    clientSecret,
    locale: i18n.lang,
  };

  return (
    <ModalLayout>
      <SCStripeEditCard>
        <h1>{i18n.t('profile.paymentMethod.editPopUp.title')}</h1>

        <div className="StripeEditCard_ContentContainer">
          {clientSecret ? (
            <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
              <StripeEditCardForm />
            </Elements>
          ) : (
            <div className="StripeEditCard_Spinner">
              <SpinnerText text={i18n.t('common.loading')} />
            </div>
          )}

          <Button
            size="full"
            color="white"
            text={i18n.t('common.cancel')}
            onClick={() => dispatch(showPopUp(null))}
          />
        </div>
      </SCStripeEditCard>
    </ModalLayout>
  );
};

export default StripeEditCard;
