import styled from '@emotion/styled';

const SCPossibleImpersonationFilterCard = styled.div`
  .filter-table-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;

export default SCPossibleImpersonationFilterCard;
