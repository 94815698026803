/* eslint-disable import/prefer-default-export */
const getObjectKeyName = (obj) => {
  if (!obj) return '';
  return Object.keys(obj)[0];
};

export const getMappedDnsRecords = (dnsRecord) => {
  return {
    provider: getObjectKeyName(dnsRecord?.phishing?.email_provider),
    dnsProvider: getObjectKeyName(dnsRecord?.ddos),
    spf: dnsRecord?.phishing?.spf?.record || '',
    dmarc: dnsRecord?.phishing?.dmarc?.record || '',
  };
};
