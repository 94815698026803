import styled from '@emotion/styled';

const SCMiniCard = styled.div`
  width: 100px;
  height: 100px;
  border: 1px solid #e0e5ea;
  border-radius: 5px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-family: var(--font2);
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--bluish-grey);
  padding: 10px;

  svg {
    font-size: 30px;
    color: var(--bluish-grey);
  }

  &:hover {
    cursor: pointer;
    color: var(--bluish-grey);
    border: 1px solid var(--bluish-grey);
    svg {
      color: var(--bluish-grey);
    }
  }
`;

export default SCMiniCard;
