import styled from '@emotion/styled';
import { css } from '@emotion/core';

const leftPosition = `
text-align: start;
`;

const rightPosition = `
text-align: end;
`;

const centerPosition = `
text-align: center;
justify-content: center;
`;

const tableElementPosition = {
  left: leftPosition,
  right: rightPosition,
  center: centerPosition,
};

const IconStyle = (color = '#212529', fontSize = '16px') => ({
  fontSize,
  marginLeft: 0,
  verticalAlign: 'bottom',
  color,
});

const CellStyle = (color, fontWeight) => ({
  color: color || 'var(--black)',
  fontWeight: fontWeight || 400,
});

const calculateIndex = (hasRowSelect, index) => {
  return !hasRowSelect ? index + 1 : index + 2;
};

const setTableStyles = (
  headers,
  sortable,
  activeSortedColumnName,
  hasRowSelect,
  hasRowIcon,
  activeSortedColumnDirection,
  autoWidth
) => {
  let columnStyles = '';

  if (headers) {
    headers.forEach((tableHeader, index) => {
      const realIndex = calculateIndex(hasRowSelect, index);

      if (tableHeader.options?.hide) {
        columnStyles += `
        thead > tr > th:nth-child(${realIndex}),
        tbody > tr > td:nth-child(${realIndex}) { 
          display: none;
        }
        `;
      }

      columnStyles += `
      thead > tr > th:nth-child(${realIndex}) { 
        cursor: ${
          sortable && !tableHeader.options?.avoidSort ? 'pointer' : 'inherit'
        };
        
        width: ${
          autoWidth && !hasRowSelect
            ? `${100 / headers.length}%;`
            : 'fit-content;'
        };

        .TableHeader_Icon {
          margin-left: 10px;
          display: ${
            sortable && !tableHeader.options?.avoidSort ? 'inherit' : 'none'
          };
          width: fit-content;
          opacity: ${
            activeSortedColumnName === tableHeader.name &&
            !!activeSortedColumnDirection
              ? '1 !important'
              : '0'
          };
  
        }
  
        .TableHeader_Container {
          &:hover {
            .TableHeader_Icon {
              opacity: 0.5
            }
          }
        }
      };
      `;
      if (tableHeader.options?.position) {
        columnStyles += `
            thead > tr > th:nth-child(${realIndex}) .TableHeader_Container,
            tbody > tr > td:nth-child(${realIndex}) { 
            ${tableElementPosition[tableHeader.options.position]};
          }
        `;
      }

      if (tableHeader.options?.stickyOnScroll) {
        columnStyles += `
        thead > tr > th:nth-child(${realIndex}),
        tbody > tr > td:nth-child(${realIndex}) { 
            position: sticky; 
            left: ${tableHeader.options?.stickyOffset}px;
            z-index: 2;
          }
        `;
      }

      if (tableHeader.options?.width) {
        columnStyles += `
        thead > tr > th:nth-child(${realIndex}),
        tbody > tr > td:nth-child(${realIndex}) { 
            min-width: ${tableHeader.options?.width}px;
            max-width: ${tableHeader.options?.width}px;
          }

        thead > tr > th:nth-child(${realIndex}) .TableHeader_Container {
          white-space: wrap;
        }
        `;
      }

      if (tableHeader.options?.ellipsisContent) {
        columnStyles += `
        tbody > tr > td:nth-child(${realIndex}),
        tbody > tr > td:nth-child(${realIndex}) p { 
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        `;
      }
    });
  }

  return css`
    ${columnStyles}
  `;
};

const SCTable = styled.table`
  width: 100%;
  color: var(--black);
  ${({ isShiftPressed }) => isShiftPressed && 'user-select: none'};

  tr > td,
  th,
  tr {
    background: #ffffff 0% 0% no-repeat padding-box;
  }

  p {
    font-size: 14px;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  thead > tr > th {
    background-color: var(--light-grey-4);
    /* border-bottom: 1px solid var(--black); */
    box-shadow: var(--light-grey-3) 0px -1px 0px 0px inset;
    user-select: none; /* Standard syntax */
  }

  font-size: 14px;
  border-radius: 0px 0px 5px 5px;
  overflow-x: auto;
  opacity: 1;
  ${({
    headers,
    sortable,
    activeSortedColumnName,
    hasRowSelect,
    hasRowIcon,
    activeSortedColumnDirection,
    autoWidth,
  }) =>
    setTableStyles(
      headers,
      sortable,
      activeSortedColumnName,
      hasRowSelect,
      hasRowIcon,
      activeSortedColumnDirection,
      autoWidth
    )};

  th,
  td {
    padding-left: 30px;
  }

  th:nth-child(1),
  td:nth-child(1) {
    ${({ hasRowSelect }) =>
      hasRowSelect &&
      `
      position: sticky;
      left: 0;
      z-index: 2;
      background-color: inherit;
    `};
  }

  th:nth-child(2),
  td:nth-child(2) {
    padding-left: ${({ hasRowSelect }) => hasRowSelect && '10px'};
  }
  th:last-child,
  td:last-child {
    padding-right: 30px;
  }

  th:first-child,
  td:first-child {
    padding-left: 20px;
  }

  .TableHeader_Container {
    display: flex;
    white-space: nowrap;

    > button {
      background: transparent;
      padding-inline: 5px;
    }
  }

  @media (max-width: 576px) {
    transform: translate(-20px, 0px);
    width: calc(100% + 40px);

    th,
    td {
      padding-left: 20px;
    }
  }
`;

const TableRow = styled.tr`
  height: 50px;
  cursor: ${({ onClick }) => onClick && 'pointer'};
  :hover > td {
    background: var(--table-row-hover-color) !important;
  }
`;

const TableColumn = styled.tr`
  height: 50px;
`;

const TdIcon = styled.td`
  text-align: center;
`;

const TdArrowIcon = styled.td`
  text-align: end;
`;

const LoadingTable = styled.div`
  height: 100px;
  width: 100%;
  font-family: var(--font1);
  font-weight: 500;
  color: var(--bluish-grey);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  SCTable,
  TableRow,
  TableColumn,
  TdIcon,
  TdArrowIcon,
  LoadingTable,
  CellStyle,
  IconStyle,
};
