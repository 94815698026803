import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { auth } from '../../firebase';

function RootRouteAuthGuard() {
  const client = useSelector((redux) => redux.client);

  let route = '/sign-in';

  if (auth.currentUser) {
    route = '/client';

    if (client.partner) {
      route = '/partner';
    }
  }

  return <Navigate to={route} />;
}
export default RootRouteAuthGuard;
