import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSubscriptionType, {
  SUBSCRIPTIONS_PRODUCTS,
  SUBSCRIPTION_STATUS,
} from '../../../hooks/subscriptionType/useSubscriptionType';
import { useTranslation } from '../../../i18n';
import { finishTutorial } from '../../../redux/actions/client.actions';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import SCSubscriptionBanner from './SubscriptionBanner.style';
import usePaymentAccess from '../../../hooks/paymentAccess/usePaymentAccess';
import usePromoCode from '../../../hooks/usePromoCode/usePromoCode';
import { hasPaymentAccess } from '../../../utils/functions/subscriptions';

const SubscriptionBanner = () => {
  const client = useSelector((redux) => redux.client);

  const {
    currentSubscription,
    currentSubscriptionDaysLeft,
    subscriptionHasBeenUpdatedOneDayAgo,
  } = useSubscriptionType();

  const { handlePaymentAccess } = usePaymentAccess();
  const { promoCode } = usePromoCode(client.setupCode);

  const i18n = useTranslation();
  const dispatch = useDispatch();

  const getSubscriptionBanner = () => {
    let banner = <></>;

    const isPendingPayment =
      currentSubscription?.status === SUBSCRIPTION_STATUS.ACTIVE &&
      currentSubscriptionDaysLeft <= 0;

    const isTrialPendingPayment = isPendingPayment && promoCode?.isCardRequired;

    switch (currentSubscription.product) {
      case SUBSCRIPTIONS_PRODUCTS.TRIAL:
      case SUBSCRIPTIONS_PRODUCTS.TRIAL_PROTECTION:
        if (isTrialPendingPayment) {
          banner = (
            <div className="section-organism">
              <InfoBanner
                text={[i18n.t('homeTrialSantanderPendingPayment.text')]}
              />
            </div>
          );
        } else {
          banner = (
            <>
              {currentSubscriptionDaysLeft > 0 && (
                <div className="section-organism">
                  <InfoBanner
                    type={
                      currentSubscriptionDaysLeft <= 3
                        ? 'error'
                        : 'defaultStyle'
                    }
                    text={[
                      i18n.t('homeTrialBanner.text', {
                        trialDaysLeft: currentSubscriptionDaysLeft,
                      }),
                    ]}
                    {...(hasPaymentAccess() && {
                      onClickButton: handlePaymentAccess,
                      buttonText: i18n.t(
                        'contractSubscription.payment.actions.signContract'
                      ),
                    })}
                  />
                </div>
              )}

              {!client?.finishedTutorials?.homeBannerAnalysisInformation &&
                subscriptionHasBeenUpdatedOneDayAgo() && (
                  <div className="section-organism">
                    <InfoBanner
                      text={[i18n.t('homeBannerAnalysisInformation.text')]}
                      onClickButton={() =>
                        dispatch(
                          finishTutorial('homeBannerAnalysisInformation')
                        )
                      }
                      buttonText={i18n.t(
                        'homeBannerAnalysisInformation.buttonText'
                      )}
                    />
                  </div>
                )}
            </>
          );
        }
        break;
      case SUBSCRIPTIONS_PRODUCTS.DEFAULT:
        banner = (
          <div className="section-organism">
            <InfoBanner
              text={[i18n.t('homeDefaultBanner.text')]}
              {...(hasPaymentAccess() && {
                onClickButton: handlePaymentAccess,
                buttonText: i18n.t(
                  'contractSubscription.payment.actions.signContract'
                ),
              })}
            />
          </div>
        );
        break;
      case SUBSCRIPTIONS_PRODUCTS.PAYING:
        if (isPendingPayment) {
          banner = (
            <div className="section-organism">
              <InfoBanner
                text={[i18n.t('homePayingSantanderPendingPayment.text')]}
              />
            </div>
          );
        } else {
          banner = !client?.finishedTutorials?.homeBannerAnalysisInformation &&
            subscriptionHasBeenUpdatedOneDayAgo() && (
              <div className="section-organism">
                <InfoBanner
                  text={[i18n.t('homeBannerAnalysisInformation.text')]}
                  onClickButton={() =>
                    dispatch(finishTutorial('homeBannerAnalysisInformation'))
                  }
                  buttonText={i18n.t(
                    'homeBannerAnalysisInformation.buttonText'
                  )}
                />
              </div>
            );
        }
        break;
      default:
        break;
    }

    return banner;
  };

  return (
    <SCSubscriptionBanner>
      {currentSubscription && getSubscriptionBanner()}
    </SCSubscriptionBanner>
  );
};

export default SubscriptionBanner;
