import styled from '@emotion/styled';
import { css } from '@emotion/core';

const selectedStyles = () => css`
  color: var(--white);
  background: var(--red-gradient);
`;

const unselectedStyles = () => css`
  color: var(--bluish-grey);
  background-color: var(--white);
`;

const hoverUnselectedStyles = () => css`
  color: var(--red);
  background-color: var(--bluish-grey-4);
`;

const SCTopMenuSubItem = styled.button`
  position: relative;
  width: 100%;
  height: 40px;
  padding: 0px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  ${({ selected }) => (selected ? selectedStyles : unselectedStyles)};

  &:hover {
    ${({ selected }) => (selected ? selectedStyles : hoverUnselectedStyles)};
  }

  .description-container {
    display: none;
    position: absolute;
    left: -290px;
    width: 275px;
    background-color: var(--light-grey-4);
    border-radius: 5px;

    -webkit-box-shadow: 2px 2px 20px 0px rgba(121, 136, 151, 0.2);
    box-shadow: 2px 2px 20px 0px rgba(121, 136, 151, 0.2);

    ::after {
      content: ' ';
      display: block;
      height: 10px;
      aspect-ratio: 1/1;
      position: absolute;
      right: -5px;
      top: calc(50% - 3px);
      transform: rotate(45deg);
      background-color: var(--light-grey-4);
    }

    > div {
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 10px;

      > p {
        color: var(--bluish-grey);
        text-align: left;
        font-family: var(--font1);
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
      }
    }
  }

  &:hover > div.description-container {
    display: block;
  }

  > span {
    margin-left: 10px;
    font-family: var(--font1);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: inherit;
  }
`;

export default SCTopMenuSubItem;
