import styled from '@emotion/styled';

const SCEmailSecuritySection = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  .emailSecurityOrganism {
    margin-bottom: 60px;
  }
`;

export default SCEmailSecuritySection;
