function useBillingAddressValidator() {
  const isBillingAddressComplete = (address) => {
    return (
      !!address?.line1 &&
      !!address?.city &&
      !!address?.postalCode &&
      address?.postalCode.toString().length === 5 &&
      !!address?.state &&
      !!address?.country &&
      !!address?.corporateName
    );
  };

  return {
    isBillingAddressComplete,
  };
}

export default useBillingAddressValidator;
