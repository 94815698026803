import styled from '@emotion/styled';

export const SCNotificationAlerts = styled.div`
  border-radius: 50%;
  background-color: ${({ selected }) => (selected ? 'white' : '#bd1728')};
  width: 15px;
  height: 15px;
  color: ${({ selected }) => (selected ? '#bd1728' : 'white')};
  font-size: 13px;
  font-weight: 400;
  position: absolute;
  top: 9px;
  right: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SCSettingsMenuButtons = styled.div`
  width: 202px;
  height: 54px;
  border-radius: 50vh;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  button {
    border-radius: 5px;
    :hover {
      background-color: var(--bluish-grey-4);
    }
  }

  .top-menu-notifications-icon {
    align-items: center;
    text-align: center;
    position: relative;
    cursor: pointer;
  }

  .division-section {
    margin: 5px 20px;
  }

  #settings-button {
    @media screen and (max-width: 1320px) {
      display: none;
    }
  }

  #top-menu-my-account-icon {
    @media screen and (min-width: 1320px) {
      display: none;
    }
  }

  #mobile-menu-switcher-button {
    @media screen and (min-width: 1320px) {
      display: none;
    }
  }
`;

export default SCSettingsMenuButtons;
