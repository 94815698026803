import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getParameterByName } from '../../utils/helper';
import {
  getBreachesV2,
  getDangerousLevel,
  getLatestBreachV2,
} from '../../utils/functions/dataBreaches';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import { ErrorKey } from '../../track';

function useBreachNotificationType() {
  const i18n = useTranslation();
  const monitoredEmails = useSelector((redux) => redux.monitoredEmails);
  const client = useSelector((redux) => redux.client);
  const teamMembers = useSelector((redux) => redux.teamMembers || []);
  const activeBreach = getParameterByName('breach', window.location.href);
  const activeEmail = getParameterByName('email', window.location.href);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getBreachNotification = () => {
    const totalMonitoredEmail = teamMembers.length;
    let compromisedEmail = 0;
    let totalLeaks = 0;

    Object.keys(breaches).forEach((key) => {
      if (breaches[key].emails) {
        totalLeaks += Object.keys(breaches[key].emails).length;
      }
    });

    Object.keys(monitoredEmails).forEach((key) => {
      if (
        monitoredEmails[key]?.breaches &&
        Object.keys(monitoredEmails[key]?.breaches).length > 0
      ) {
        compromisedEmail += 1;
      }
    });

    const lastDateLeaks = getLatestBreachV2(breaches)?.date;

    return {
      totalLeaks,
      compromisedEmail,
      totalMonitoredEmail,
      lastDateLeaks,
    };
  };

  const onClickEmployees = () => {
    navigate('/client/settings/employees');
  };

  const getNameFromTeamMemberEmail = (email) => {
    let name = '';
    const teamMember = teamMembers?.find((item) => item.email === email);
    if (teamMember) {
      name = `${teamMember.firstName} ${teamMember.lastName}`;
    }
    return name;
  };

  const getAffectedMonitoredEmails = () => {
    let auxAffectedMonitoredEmails = [];

    if (breaches?.[activeBreach]?.emails) {
      if (getDangerousLevel(breaches[activeBreach]) === 'critical') {
        auxAffectedMonitoredEmails = Object.keys(
          breaches[activeBreach]?.emails
        ).map((email) => ({
          ...breaches[activeBreach]?.emails[email],
          level: 'critical',
          name: getNameFromTeamMemberEmail(email),
        }));
      } else {
        auxAffectedMonitoredEmails = Object.keys(
          breaches[activeBreach]?.emails
        ).map((email) => ({
          ...breaches[activeBreach]?.emails[email],
          level: 'lessCritical',
          classes: breaches[activeBreach].classes
            .map((item) => i18n.t(`breachNotification.breachClasses.${item}`))
            .join(', '),
          breachName: activeBreach,
          name: getNameFromTeamMemberEmail(email),
        }));
      }
    }

    return auxAffectedMonitoredEmails;
  };

  // Función para notificar o recordar mediante correo electrónico a los usuarios con filtraciones críticas
  const emailBreachNotifications = () => {
    if (activeBreach) {
      const clientId = client.id;
      const emails = Object.keys(breaches[activeBreach].emails).filter(
        (email) => {
          if (!breaches[activeBreach].emails[email].resolved) {
            return email;
          }
        }
      );

      axios
        .post('/api/breaches/email', {
          clientId,
          emails,
          breach: activeBreach,
          language: i18n.getLanguage(),
        })
        .then(() => {
          dispatch(
            showPopUp('notification', {
              notificationType: 'success',
              title: '',
              text: i18n.t('phishingSimulator.campaigns.emailsSent'),
            })
          );
        })
        .catch(() => {
          ErrorKey('common.errorTryLater');
        });
    }
  };

  const getConcatClasses = (classes) => {
    let cla = '';

    if (classes) {
      const translated = classes.map((item) =>
        i18n.t(`breachNotification.breachClasses.${item}`)
      );
      cla = translated.join(', ');
    }

    return cla;
  };

  // Método que devuelve un array con los correos electrónicos de las filtraciones de un empleado dado
  const getBreachesByEmployee = () => {
    const activeEmailBreaches = monitoredEmails[activeEmail]?.breaches || {};

    return Object.keys(activeEmailBreaches).map((breachName) => {
      const breach = activeEmailBreaches[breachName];

      return {
        name: breachName,
        date: breach.date,
        classes: getConcatClasses(breach.classes),
      };
    });
  };

  // Método para obtener las filtraciones totales por email
  const getTotalBreachesPerEmail = (monitoredEmailsBreaches) => {
    let breachesPerEmail = 0;

    Object.keys(monitoredEmailsBreaches).forEach((key) => {
      if (monitoredEmailsBreaches[key].classes) {
        breachesPerEmail += 1;
      }
    });

    return breachesPerEmail;
  };

  // Método para obtener la fecha de la última filtración
  const latestBreachDatePerEmail = (monitoredEmailsBreaches) => {
    const breachesArray = Object.values(monitoredEmailsBreaches);

    const sortedBreaches = breachesArray.sort(
      (a, b) => b.date.toDate().getTime() - a.date.toDate().getTime()
    );

    return sortedBreaches[0].date;
  };

  // Array con los correos electrónicos de los empleados, las filtraciones totales por email y la fecha de la última filtración
  const savedEmails = teamMembers.map((teamMember) => {
    const teamMemberBreaches = monitoredEmails[teamMember.email]?.breaches;

    return {
      ...teamMember,
      breachesPerEmail: teamMemberBreaches
        ? getTotalBreachesPerEmail(teamMemberBreaches)
        : 0,
      lastBreachAt: teamMemberBreaches
        ? latestBreachDatePerEmail(teamMemberBreaches)
        : undefined,
    };
  });

  const breaches = getBreachesV2(monitoredEmails, client.id);
  const breachesByEmployee = getBreachesByEmployee();
  const breachNotification = getBreachNotification();
  const affectedMonitoredEmails = getAffectedMonitoredEmails();

  return {
    breachNotification,
    onClickEmployees,
    breaches,
    savedEmails,
    affectedMonitoredEmails,
    activeBreach,
    emailBreachNotifications,
    breachesByEmployee,
  };
}

export default useBreachNotificationType;
