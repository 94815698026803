import styled from '@emotion/styled';

const SCProfileEmail = styled.div`
  .subtitle-container {
    margin-bottom: 0px;
    font-family: var(--font2);
    color: var(--grey);
    font-size: 16px;
  }
`;

export default SCProfileEmail;
