import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LicenseeGuard = ({ licensee, children }) => {
  const client = useSelector((redux) => redux.client);

  return client.licensee === licensee ? children : <Navigate to="/" />;
};

export default LicenseeGuard;
