import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from '../../i18n';
import Tool4StepsTutorial from '../../components/organisms/Tool4StepsTutorial/Tool4StepsTutorial';
import DevicesSummary from '../../components/organisms/DevicesSummary/DevicesSummary';
import { showPopUp } from '../../redux/actions/popUp.actions';
import InfoBanner from '../../components/molecules/InfoBanner/InfoBanner';
import SCDeviceSecuritySection from './DeviceSecuritySection.style';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import InstallProtections from '../../components/organisms/InstallProtections/InstallProtections';
import BackButton from '../../components/molecules/BackButton/BackButton';
import { finishTutorial } from '../../redux/actions/client.actions';
import LicensesOverviewCard from '../../components/organisms/LicensesOverviewCard/LicensesOverviewCard';
import AnyDoubts from '../../components/molecules/AnyDoubts/AnyDoubts';
import LicensesSentByEmployeeTableCard from '../../components/organisms/LicensesSentByEmployeeTableCard/LicensesSentByEmployeeTableCard';
import SectionHeader from '../../components/molecules/SectionHeader/SectionHeader';
import DeviceSecurityCard from '../../components/organisms/DeviceSecurityCard/DeviceSecurityCard';

const DeviceSecuritySection = () => {
  const client = useSelector((redux) => redux.client);

  const i18n = useTranslation();

  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (client && !client?.finishedTutorials?.deviceSecurity) {
      dispatch(showPopUp('tutorial', 'deviceSecurity'));
    }
  }, []);

  return (
    <SectionLayout>
      <SCDeviceSecuritySection>
        {!id && ( // Main page
          <>
            <div className="section-organism">
              <SectionHeader />
            </div>

            <div className="section-organism">
              <Tool4StepsTutorial />
            </div>

            <div className="section-organism multi-column">
              <DeviceSecurityCard
                data={client?.status?.deviceSecurity}
                showEmptyCardButton
              />
              <LicensesOverviewCard />
            </div>

            <div className="section-organism">
              <DevicesSummary />
            </div>

            <AnyDoubts />
          </>
        )}
        {/* Install Protections page  */}
        {id === 'install-protections' && (
          <>
            <div className="section-back-button">
              <BackButton />
            </div>

            {!client?.finishedTutorials?.installProtectionsInfo && (
              <div className="section-organism">
                <InfoBanner
                  type="warn"
                  title={i18n.t(
                    'deviceSecurity.installProtectionsInfoBanner.title'
                  )}
                  text={[
                    i18n.t(
                      'deviceSecurity.installProtectionsInfoBanner.paragraph1'
                    ),
                    i18n.t(
                      'deviceSecurity.installProtectionsInfoBanner.paragraph2'
                    ),
                  ]}
                  onClickButton={() =>
                    dispatch(finishTutorial('installProtectionsInfo'))
                  }
                  buttonText={i18n.t(
                    'deviceSecurity.installProtectionsInfoBanner.button'
                  )}
                />
              </div>
            )}

            <div className="section-organism">
              <InstallProtections />
            </div>

            <div className="section-organism">
              <LicensesSentByEmployeeTableCard />
            </div>
          </>
        )}
      </SCDeviceSecuritySection>
    </SectionLayout>
  );
};

export default DeviceSecuritySection;
