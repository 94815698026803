import en_GB from 'antd/locale/en_US';
import es_ES from 'antd/locale/es_ES';
import pt_BR from 'antd/locale/pt_BR';
import { isUserInTeamMembers } from './functions/teamMembers';
import { auth } from '../firebase';

export const LOCALES = {
  es: 'es',
  mx: 'mx',
  br: 'br',
  seu: 'seu',
  sam: 'sam',
  am: 'am',
};

const SANTANDER_LOCALES = [LOCALES.sam, LOCALES.seu];

const NON_COUNTRY_LOCALES = [...SANTANDER_LOCALES, LOCALES.am];

const AVAILABLE_INTERNATIONALIZED_IMG_LANGUAGES = ['es', 'en'];

export const getInternationalizedImgLang = (desiredLang) =>
  AVAILABLE_INTERNATIONALIZED_IMG_LANGUAGES.includes(desiredLang)
    ? desiredLang
    : 'en';

export const getLocaleBySubdomain = () => {
  const DEFAULT_LOCALE = LOCALES.es;

  const SUBDOMAIN_LOCALE = {
    es: LOCALES.es,
    'es-staging': LOCALES.es,
    'es-testing': LOCALES.es,
    'es-prod': LOCALES.es,

    mx: LOCALES.mx,
    'mx-staging': LOCALES.mx,
    'mx-testing': LOCALES.mx,
    'mx-prod': LOCALES.mx,

    br: LOCALES.br,
    'br-staging': LOCALES.br,
    'br-testing': LOCALES.br,
    'br-prod': LOCALES.br,

    'seu-prod': LOCALES.seu,

    'sam-prod': LOCALES.sam,

    'am-prod': LOCALES.am,
  };

  return SUBDOMAIN_LOCALE[process.env.REACT_APP_ENVIRONMENT] ?? DEFAULT_LOCALE;
};

export const isSantanderEnvironment = SANTANDER_LOCALES.includes(
  getLocaleBySubdomain()
);

export const isNonCountryEnvironment = NON_COUNTRY_LOCALES.includes(
  getLocaleBySubdomain()
);

export const getSantanderEnvironmentRegionText = () => {
  const currentLocale = getLocaleBySubdomain();

  const santanderLocalesTexts = {
    [LOCALES.seu]: 'EU',
    [LOCALES.sam]: 'AM',
  };

  return `Cyber Guardian Santander ${
    santanderLocalesTexts[currentLocale] ?? ''
  }`;
};

export const getCurrentAntDLocale = (lang) => {
  const DEFAULT_LOCALE = es_ES;
  const ANTD_LOCALES = {
    es: es_ES,
    en: en_GB,
    'pt-BR': pt_BR,
  };

  return ANTD_LOCALES[lang] || DEFAULT_LOCALE;
};

export const getDateFormatByLanguage = (lang) => {
  const DateFormatsByLanguage = {
    en: 'MM/DD/YYYY',
  };

  return DateFormatsByLanguage[lang] ?? 'DD/MM/YYYY';
};

export const isCallMeAvailable = (pathname) => {
  const ROUTES_WITHOUT_CALL_ME_WIDGET = ['/partner', '/admin', '/gerencia'];

  const isNotRestrictedRoute = !ROUTES_WITHOUT_CALL_ME_WIDGET.some((route) =>
    pathname.startsWith(route)
  );

  return (
    isNotRestrictedRoute &&
    !isNonCountryEnvironment &&
    (isUserInTeamMembers() || !auth?.currentUser)
  );
};

export const isTawkToAvailable = (pathname) => {
  const ROUTES_WITHOUT_TAWKTO_WIDGET = ['/admin', '/gerencia'];

  const isNotRestrictedRoute = !ROUTES_WITHOUT_TAWKTO_WIDGET.some((route) =>
    pathname.startsWith(route)
  );

  return isNotRestrictedRoute;
};
