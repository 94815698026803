import React from 'react';

import { useSelector } from 'react-redux';
import SCProfileBilling from './ProfileBilling.style';
import SectionLayout from '../../layouts/SectionLayout/SectionLayout';
import SectionHeader from '../../molecules/SectionHeader/SectionHeader';
import MySubscriptionCard from '../PartnerClientMySubscriptionCard/MySubscriptionCard';
import YourSubscriptionCardInfoBanner from '../../molecules/YourSubscriptionCardInfoBanner/YourSubscriptionCardInfoBanner';
import LICENSEES from '../../../utils/constants/licensees';
import useSubscriptionType from '../../../hooks/subscriptionType/useSubscriptionType';
import ManageSubscription from '../ManageSubscription/ManageSubscription';
import BillingHistory from '../BillingHistory/BillingHistory';
import { isNonCountryEnvironment } from '../../../utils/locale';
import { isPartnerType } from '../../../utils/functions/partners';
import { PARTNER_TYPES } from '../../../utils/constants/partners';
import { hasPaymentAccess } from '../../../utils/functions/subscriptions';

const ProfileBilling = () => {
  const client = useSelector((redux) => redux.client);
  const { hasPayingSubscription } = useSubscriptionType();

  return (
    <SectionLayout>
      <SCProfileBilling>
        <div className="section-organism">
          <SectionHeader />
        </div>

        <div className="section-organism">
          <MySubscriptionCard
            totalLicenses={
              client?.status?.serviceLevel?.limits?.nbLicences ?? 0
            }
            installedLicenses={client?.status?.nbLicensesUsed ?? 0}
            sentLicenses={client?.status?.nbLicensesSent ?? 0}
            currentSubscriptionProduct={client?.status?.serviceLevelIDs[0]}
            currentSubscriptionExpirationDate={client?.status?.active.toDate()}
            nextRenewalAt={client?.status?.nextRenewalAt?.toDate()}
            showLinkToPlans={client.licensee === LICENSEES.santander}
            {...(hasPaymentAccess() && {
              infoBanner: <YourSubscriptionCardInfoBanner />,
            })}
          />
        </div>

        {hasPayingSubscription() &&
          !isNonCountryEnvironment &&
          !isPartnerType(PARTNER_TYPES.MSSP) && (
            <div className="section-organism">
              <ManageSubscription />
            </div>
          )}

        {!isNonCountryEnvironment && !isPartnerType(PARTNER_TYPES.MSSP) && (
          <BillingHistory />
        )}
      </SCProfileBilling>
    </SectionLayout>
  );
};

export default ProfileBilling;
