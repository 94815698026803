import axios from 'axios';
import { firestore } from '../../firebase';
import { GET_USER, UPDATE_USER } from './types';
import { i18n } from '../../i18n';
import { ErrorKey } from '../../track';

export const getUser = (uid: string) => async (dispatch: Function) => {
  const user = await firestore
    .collection('users')
    .doc(uid)
    .get()
    .then((doc: any) => doc.data());
  dispatch({
    type: GET_USER,
    payload: { uid, ...user },
  });
};

type UserData = {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
};

const validateForm = (payload: UserData, user: any): boolean => {
  if (!user || !user.uid) {
    ErrorKey('errors.thereWasAnError', 'validateForm');
    return false;
  }
  const { firstName, lastName, phoneNumber } = payload;
  const invalid = [];
  if (!firstName && !user.firstName)
    invalid.push(i18n.t('profile.name').toLowerCase());
  if (!lastName && !user.lastName)
    invalid.push(i18n.t('profile.lastName').toLowerCase());
  if (phoneNumber && phoneNumber[0] !== '+')
    invalid.push(
      `${i18n.t('profile.phone').toLowerCase()} (${i18n.t('profile.phoneEx')})`
    );
  if (!firstName && !user.firstName) invalid.push('Name'.toLowerCase());
  if (!lastName && !user.lastName) invalid.push('Last name'.toLowerCase());
  if (phoneNumber && phoneNumber[0] !== '+')
    invalid.push(`${'Phone number'.toLowerCase()} (${'e.g. +4465412343'})`);
  if (invalid.length > 0) {
    const msg = invalid.map((item, index) => {
      if (index === 0) {
        return item;
      }
      if (index !== 0 && index < invalid.length - 1) {
        return `, ${item}`;
      }
      return `, ${item}`; // Change for translation from: return ` and ${item}`;
    });
    ErrorKey('errors.thereWasAnErrorWith', 'validateForm', {
      errormessage: msg.join(''),
    });
    return false;
  }
  return true;
};

export const updateUser =
  (payload: UserData, user: any) => async (dispatch: any) => {
    const { firstName, lastName } = user;
    if (validateForm(payload, user)) {
      const success = await axios
        .patch(`/users/${user.uid}`, { firstName, lastName, ...payload })
        .then(() => {
          dispatch({
            type: UPDATE_USER,
            payload: { ...user, ...payload },
          });
          return true;
        })
        .catch((error) => {
          ErrorKey(`errors.${error.response.data.code}`, 'updateUser');
          throw error.response.data.code;
        });
      return success;
    }
    return false;
  };

export const update2fa =
  ({
    requires2fa,
    verified2fa,
  }: {
    requires2fa: boolean;
    verified2fa: boolean;
  }) =>
  (dispatch: Function, getState: Function) => {
    const { user } = getState();
    const newUser = { ...user, requires2fa, verified2fa };
    dispatch({
      type: UPDATE_USER,
      payload: newUser,
    });
  };

export default 1;
