import styled from '@emotion/styled';

const SCTagList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  white-space: nowrap;
  flex-wrap: ${({ cell }) => (cell ? 'nowrap' : 'wrap')};

  ::-webkit-scrollbar {
    height: 5px;
  }
`;

export default SCTagList;
