import styled from '@emotion/styled';

const SCStripeEditCardForm = styled.form`
  /* ToDo - Mejorar estilos label e input para que sea lo más similar el <PaymentElement /> de Stripe */
  width: 70%;
  display: flex;
  flex-direction: column;

  .label-input-container {
    display: flex;
    margin-bottom: 12px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    > label {
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.15;
      color: #30313d;
      transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    }

    input {
      width: 100%;
      padding: 12px;
      background-color: var(--white);
      border-radius: 5px;
      transition: background 0.15s ease, border 0.15s ease,
        box-shadow 0.15s ease, color 0.15s ease;
      border: 1px solid #e6e6e6;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03),
        0px 3px 6px rgba(0, 0, 0, 0.02);

      :focus {
        outline: 0;
        border-color: hsla(210, 96%, 45%, 50%);
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03),
          0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%),
          0 1px 1px 0 rgba(0, 0, 0, 0.08);
      }

      :disabled {
        background-color: #e9ecef;
      }
    }
    .label-inputs {
      display: flex;
      flex-direction: row;
      gap: 0.75rem;
      width: 100%;
    }
  }

  button {
    margin-top: 20px;
  }

  .name-surname-container {
    display: flex;
    gap: 10px;
  }
`;

export default SCStripeEditCardForm;
