import axios from 'axios';
import { SET_PARTNER_CONFIG } from './types';

const setPartnerConfig = (licensee, dispatch) => {
  axios
    .get('/clients/partners/data', {
      params: {
        licensee,
      },
    })
    .then((resp) => {
      const partnerConfig = resp.data.data;

      dispatch({
        type: SET_PARTNER_CONFIG,
        payload: partnerConfig,
      });
    });
};

export default setPartnerConfig;
