import React from 'react';
import ButtonBubble from '../ButtonBubble/ButtonBubble';
import SCTagsPalette from './TagsPalette.style';

export const tagStyles = [
  {
    id: '#000001',
    backgroundColor: '#78bc12',
    color: '#ffffff',
    borderColor: '#78bc12',
  },
  {
    id: '#000002',
    backgroundColor: '#efc210',
    color: '#ffffff',
    borderColor: '#efc210',
  },
  {
    id: '#000003',
    backgroundColor: '#ef8e10',
    color: '#ffffff',
    borderColor: '#ef8e10',
  },
  {
    id: '#000004',
    backgroundColor: '#bc1224',
    color: '#ffffff',
    borderColor: '#bc1224',
  },
  {
    id: '#000005',
    backgroundColor: '#1278bc',
    color: '#ffffff',
    borderColor: '#1278bc',
  },
  {
    id: '#000006',
    backgroundColor: '#7812bc',
    color: '#ffffff',
    borderColor: '#7812bc',
  },
  {
    id: '#000007',
    backgroundColor: '#d804bf',
    color: '#ffffff',
    borderColor: '#d804bf',
  },
  {
    id: '#000008',
    backgroundColor: '#798897',
    color: '#ffffff',
    borderColor: '#798897',
  },
  {
    id: '#000009',
    backgroundColor: '#d2e9b1',
    color: '#78bc12',
    borderColor: '#d2e9b1',
  },
  {
    id: '#000010',
    backgroundColor: '#faeab0',
    color: '#efc210',
    borderColor: '#faeab0',
  },
  {
    id: '#000011',
    backgroundColor: '#fadab0',
    color: '#ef8e10',
    borderColor: '#fadab0',
  },
  {
    id: '#000012',
    backgroundColor: '#e9b0b6',
    color: '#bc1224',
    borderColor: '#e9b0b6',
  },
  {
    id: '#000013',
    backgroundColor: '#b0d2e9',
    color: '#1278bc',
    borderColor: '#b0d2e9',
  },
  {
    id: '#000014',
    backgroundColor: '#d3b0e9',
    color: '#7812bc',
    borderColor: '#d3b0e9',
  },
  {
    id: '#000015',
    backgroundColor: '#f2acea',
    color: '#d804bf',
    borderColor: '#f2acea',
  },
  {
    id: '#000016',
    backgroundColor: 'var(--default-label-bluish-grey)',
    color: '#798897',
    borderColor: 'var(--default-label-bluish-grey)',
  },
  {
    id: '#000017',
    backgroundColor: 'transparent',
    color: '#78bc12',
    borderColor: '#78bc12',
  },
  {
    id: '#000018',
    backgroundColor: 'transparent',
    color: '#efc210',
    borderColor: '#efc210',
  },
  {
    id: '#000019',
    backgroundColor: 'transparent',
    color: '#ef8e10',
    borderColor: '#ef8e10',
  },
  {
    id: '#000020',
    backgroundColor: 'transparent',
    color: '#bc1224',
    borderColor: '#bc1224',
  },
  {
    id: '#000021',
    backgroundColor: 'transparent',
    color: '#1278bc',
    borderColor: '#1278bc',
  },
  {
    id: '#000022',
    backgroundColor: 'transparent',
    color: '#7812bc',
    borderColor: '#7812bc',
  },
  {
    id: '#000023',
    backgroundColor: 'transparent',
    color: '#d804bf',
    borderColor: '#d804bf',
  },
  {
    id: '#000024',
    backgroundColor: 'transparent',
    color: '#798897',
    borderColor: '#798897',
  },
];

const TagsPalette = ({ handleTagColor, selectedColor }) => {
  return (
    <SCTagsPalette>
      {tagStyles.map((tagStyle) => (
        <ButtonBubble
          isSelected={selectedColor === tagStyle.id}
          key={tagStyle.id}
          tagStyle={tagStyle}
          onClick={() => handleTagColor(tagStyle.id)}
        />
      ))}
    </SCTagsPalette>
  );
};

export default TagsPalette;
