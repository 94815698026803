import styled from '@emotion/styled';
import { css } from '@emotion/core';

const full = () => css`
  max-width: 1600px;

  @media (min-width: 1280px) {
    .footer-content-container {
      padding: 0px 20px;
    }
  }
`;

const section = () => css`
  max-width: var(--section-width);
`;

const sectionXL = () => css`
  max-width: var(--section-width-xl);
`;

const sizes = {
  full,
  section,
  sectionXL,
};

const black = () => css`
  background-color: var(--black);

  .footer-container {
    color: var(--white);
  }

  svg {
    filter: brightness(100);
  }
`;

const transparent = () => css`
  background-color: var(--application-background-color);

  .footer-container {
    color: var(--bluish-grey);
  }
`;

const colors = {
  black,
  transparent,
};

const SCContactLegalInformation = styled.footer`
  display: flex;
  justify-content: center;

  ${({ color }) => colors[color] || black};

  .footer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0px 20px;

    ${({ size }) => sizes[size] || full};

    .separator-container {
      width: 100%;
    }

    .footer-content-container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      padding-bottom: 30px;
      padding-top: 40px;

      h3 {
        font-family: var(--font1);
        font-size: 14px;
        color: inherit;
        font-weight: 500;
      }

      a {
        font-family: var(--font1);
        font-size: 14px;
        color: inherit;
        font-weight: 400;

        :hover {
          text-decoration: underline;
        }
      }

      .copyright-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        > svg {
          width: 125px;
          color: orange;
          cursor: pointer;
        }

        > p {
          font-family: var(--font1);
          font-size: 14px;
          color: inherit;
          font-weight: 400;
        }
      }

      .support-container,
      .contact-container,
      .legal-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  @media (max-width: 769px) {
    .footer-container {
      .footer-content-container {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .copyright-container {
          align-items: center;
        }

        .support-container,
        .contact-container,
        .legal-container {
          align-items: center;
        }
      }
    }
  }
`;

export default SCContactLegalInformation;
