import { firestore } from '../../firebase';

const getInstallationTokenByValue = async ({ clientId, value }) => {
  const installationTokenDoc = await firestore
    .collection('clients')
    .doc(clientId)
    .collection('installation_tokens')
    .where('value', '==', value)
    .where('isRevoked', '==', false)
    .limit(1)
    .get();

  return installationTokenDoc.docs?.[0]?.data();
};

export default { getInstallationTokenByValue };
