import React, { useState, useEffect } from 'react';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import { Skeleton } from 'antd';
import SCListOfPaymentMethods from './ListOfPaymentMethods.style';
import Icon from '../../atoms/Icon/Icon';
import { i18n } from '../../../i18n';
import usePaymentMethods from '../../../hooks/paymentMethods/usePaymentMethods';
import EmptyMessage from '../EmptyMessage/EmptyMessage';

const ListOfPaymentMethods = () => {
  const [loading, setLoading] = useState(false);
  const { getClientCards, paymentMethods } = usePaymentMethods();

  useEffect(() => {
    let isCancel = false;
    setLoading(true);

    getClientCards().finally(() => {
      if (!isCancel) {
        setLoading(false);
      }
    });

    return () => {
      isCancel = true;
    };
  }, []);

  return (
    <SCListOfPaymentMethods>
      {!loading ? (
        <>
          {paymentMethods.length > 0 ? (
            paymentMethods.map((paymentMethod) => (
              <div className="PaymentMethod_Container">
                <div className="PaymentMethod_Icon">
                  <Icon iconName={faCreditCard} color="var(--bluish-grey)" />
                </div>

                <div className="PaymentMethod_Info">
                  <p>
                    {paymentMethod.brand.toUpperCase()} ••••{' '}
                    {paymentMethod.last4}
                  </p>

                  <p>
                    {i18n.t(
                      'profile.paymentMethod.listOfPaymentMethods.expirationLabel',
                      {
                        month: paymentMethod.expMonth,
                        year: paymentMethod.expYear,
                      }
                    )}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <EmptyMessage>
              {i18n.t(
                'profile.paymentMethod.listOfPaymentMethods.paymentMethodsEmpty'
              )}
            </EmptyMessage>
          )}
        </>
      ) : (
        <Skeleton active paragraph={{ rows: 1 }} />
      )}
    </SCListOfPaymentMethods>
  );
};
export default ListOfPaymentMethods;
