import { faCircleExclamationCheck } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useBreachNotificationType from '../../../hooks/breachNotificationType/useBreachNotificationType';
import { useTranslation } from '../../../i18n';
import { getBreachStatus } from '../../../utils/functions/dataBreaches';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCResolvedBreachesCard from './ResolvedBreachesCard.style';
import Label from '../../atoms/Label/Label';
import { getDateFormat, getDateInMs } from '../../../utils/format/dataFormat';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import TableContainer from '../../molecules/TableContainer/TableContainer';

const ResolvedBreachesCard = () => {
  const i18n = useTranslation();
  const { breaches } = useBreachNotificationType();
  const breachesArray = Object.values(breaches);
  const navigate = useNavigate();
  // Constante con información para pintar los headers de la tabla
  const columns = [
    {
      name: i18n.t('breachNotification.breachTable.headers.breaches.name'),
    },
    {
      name: i18n.t('breachNotification.breachTable.headers.breaches.type'),
    },
    {
      name: i18n.t('breachNotification.breachTable.headers.breaches.status'),
    },
    {
      name: i18n.t('breachNotification.breachTable.headers.breaches.date'),
    },
  ];

  const mapperFn = (item) => {
    return {
      name: { type: 'string', value: item.name, sortValue: item.name },
      breachType: {
        type: 'component',
        value: (
          <Label
            value={i18n.t(
              `breachNotification.breachTable.${item.dangerousLevel}`
            )}
            width="150px"
          />
        ),
        sortValue: i18n.t(
          `breachNotification.breachTable.${item.dangerousLevel}`
        ),
      },
      state: {
        type: 'string',
        value: i18n.t(
          `breachNotification.breachTable.${getBreachStatus(item)}`
        ),
        sortValue: i18n.t(
          `breachNotification.breachTable.${getBreachStatus(item)}`
        ),
      },
      date: {
        type: 'string',
        value: getDateFormat(item.date.toDate()),
        sortValue: getDateInMs(item.date.toDate()),
      },
      onClick: () => navigate(`/client/data-alert?breach=${item.name}`),
    };
  };

  const PAGE_SIZE = 10;
  const defaultSortDirection = 'desc';
  const sortOptions = {
    activeSortedColumnName: i18n.t(
      'breachNotification.breachTable.headers.breaches.date'
    ),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 3,
    onSort: (data) => {
      setSort(data);
    },
  };
  const { currentPage, setSort, setSearch, setPage, rows, length } =
    useFilterTable(
      PAGE_SIZE,
      mapperFn,
      breachesArray.filter((item) => getBreachStatus(item) === 'resolved'),
      'name',
      sortOptions
    );

  return (
    <SCResolvedBreachesCard>
      <SectionCard
        headerIcon={faCircleExclamationCheck}
        headerTitle={`${i18n.t(
          'breachNotification.sectionResolvedBreaches'
        )} (${length})`}>
        <TableContainer
          emptyMessage={i18n.t(
            'breachNotification.breachTable.emptyMessage.noResolvedBreaches'
          )}
          columns={columns}
          rows={rows}
          onPageChange={(page) => setPage(page)}
          hasHorizontalScroll
          totalCount={length}
          currentPage={currentPage}
          onChangeValue={setSearch}
          sortOptions={sortOptions}
          pageSize={PAGE_SIZE}
        />
      </SectionCard>
    </SCResolvedBreachesCard>
  );
};

export default ResolvedBreachesCard;
