import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SCConfigureDmarc from './configureDmarc.style';
import Button from '../../components/atoms/Button/Button';
import ConfigureDmarcSteps from '../../components/organisms/ConfigureDmarcSteps/ConfigureDmarcSteps';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import BackButton from '../../components/molecules/BackButton/BackButton';

const ConfigureDmarc = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const [stepDMARC, setStepDMARC] = useState(1);
  const navigate = useNavigate();

  const [DMARC, setDMARC] = useState({
    action: 'reject',
    emailReceiver: undefined,
  });

  const handleButtonNext = (e) => {
    if (stepDMARC > 0) {
      if (DMARC && (!DMARC.areYouResponsible || !DMARC.areYouAbleToSetDNS)) {
        dispatch(
          showPopUp('SentEmailTo', {
            type: 'emailSecurity',
          })
        );
      } else {
        setStepDMARC(stepDMARC + 1);
      }
    }
  };

  const handleButtonPrevious = (e) => {
    if (stepDMARC > 1) {
      setStepDMARC(stepDMARC - 1);
    }
  };

  return (
    <SectionLayout>
      <SCConfigureDmarc>
        <div className="section-back-button">
          <BackButton />
        </div>

        <div className="section-organism">
          <ConfigureDmarcSteps
            dmarc={DMARC}
            step={stepDMARC}
            onChangeConfig={setDMARC}
          />
        </div>

        <div className="section-organism configureDmarc_Actions">
          {stepDMARC > 1 && (
            <Button
              width="340px"
              onClick={handleButtonPrevious}
              color="white"
              text={i18n.t('configureEmailSecurity.back')}
            />
          )}
          {stepDMARC > 0 && stepDMARC < 3 && (
            <Button
              width="340px"
              onClick={handleButtonNext}
              color="bluishGrey"
              text={i18n.t('configureEmailSecurity.next')}
            />
          )}
          {stepDMARC === 3 && (
            <Button
              width="340px"
              onClick={() => navigate('/client/configure-email-security')}
              color="bluishGrey"
              text={i18n.t('configureEmailSecurity.done')}
            />
          )}
        </div>
      </SCConfigureDmarc>
    </SectionLayout>
  );
};

export default ConfigureDmarc;
