import { firestore } from '../../firebase';
import { GET_EMAIL_PROTECTION_TUTORIAL_DATA } from './types';

export default (dispatch) => {
  firestore
    .collection('config')
    .doc('url')
    .get()
    .then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        const emailProtectionTutorialDownload = data.emailProtectionTutorialDownload;

        dispatch({
          type: GET_EMAIL_PROTECTION_TUTORIAL_DATA,
          payload: emailProtectionTutorialDownload,
        });
      } else {
        console.log("No such document");
      }
    })
    .catch((error) => {
      console.error("Error getting document:", error);
    });
};
