import { firestore } from '../../firebase';
import { GET_PAYMENTS } from './types';

const setPaymentsListener = (clientId, dispatch) => {
  firestore
    .collection('clients')
    .doc(clientId)
    .collection('payments')
    .orderBy('createdAt', 'desc')
    .onSnapshot((snapshot) => {
      const payments = [];

      if (!snapshot.empty) {
        snapshot.forEach((element) => {
          const payment = element.data();
          payments.push(payment);
        });
      }

      dispatch({
        type: GET_PAYMENTS,
        payload: payments,
      });
    });
};

export default setPaymentsListener;
