import styled from '@emotion/styled';

const SCMailContentCard = styled.div`
  .MailContentCard_MainContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    textarea {
      width: 100%;
      min-height: 420px;
      resize: none;

      border: 1px solid var(--light-grey-3);
      border-radius: 5px;
      padding: 15px;
      color: var(--black);
      font-family: monospace;
      outline: none;
    }
  }
`;

export default SCMailContentCard;
