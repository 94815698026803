import {
  faFilterList,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import Table from '../../atoms/Table/Table';
import TagFilterContainer from '../TagFilterContainer/TagFilterContainer';
import SCTableContainer from './TableContainer.style';

const TableContainer = ({
  onChangeValue,
  columns,
  rows,
  onChangeFilterTag,
  avoidFilterRolesAsTag,
  hasHorizontalScroll,
  emptyMessage,
  rowHeight,
  hasRowIcon,
  hasRowSelect,
  rowIdentifier,
  rowHasBeenSelected,
  sortOptions,
  isLoading,
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  customSideElements,
  customBottomElements,
  size,
  autoWidth,
}) => {
  const i18n = useTranslation();
  const [tagFilterOpened, setTagFilterOpened] = useState(false);

  return (
    <SCTableContainer
      size={size}
      rowHasBeenSelected={rowHasBeenSelected}
      rowsLength={rows.length}
      totalCount={totalCount}
      pageSize={pageSize}
      onChangeFilterTag={onChangeFilterTag}
      hasHorizontalScroll={hasHorizontalScroll}
      hasHeaders={columns}
      rowHeight={rowHeight}>
      {(onChangeValue || onChangeFilterTag || customSideElements) && (
        <div className="table-filter">
          {onChangeValue && (
            <Input
              inputType="text"
              inputPlaceholder={`${i18n.t('common.search')}...`}
              onChangeValue={onChangeValue}
              icon={faMagnifyingGlass}
              size={size || 'short'}
              design="round"
            />
          )}

          {onChangeFilterTag && (
            <Button
              text={i18n.t('tagFiltering.actions.filterByEmployee')}
              onClick={() => {
                setTagFilterOpened(!tagFilterOpened);
              }}
              color={tagFilterOpened ? 'bluishGrey' : 'white'}
              type="button"
              size={size || 'mid'}
              icon={faFilterList}
            />
          )}
          {onChangeFilterTag && customSideElements && (
            <>
              <span>|</span>{' '}
            </>
          )}
          {customSideElements && <>{customSideElements}</>}
        </div>
      )}

      <div className="table-filterInfo">
        {onChangeFilterTag && (
          <TagFilterContainer
            avoidFilterRolesAsTag={avoidFilterRolesAsTag}
            onChangeFilterTagCallback={onChangeFilterTag}
            opened={tagFilterOpened}
            hasCustomButtonElements={customBottomElements}
            hasPreviousFilter={false}
          />
        )}

        {tagFilterOpened && rowHasBeenSelected && <hr className="solid" />}

        {customBottomElements}
      </div>

      <div className="table-container">
        <Table
          columns={columns}
          rows={rows}
          emptyMessage={emptyMessage}
          hasRowIcon={hasRowIcon}
          onChangeFilterTag={onChangeFilterTag}
          rowIdentifier={rowIdentifier}
          rowHasBeenSelected={rowHasBeenSelected}
          hasRowSelect={hasRowSelect}
          sortOptions={sortOptions}
          isLoading={isLoading}
          autoWidth={autoWidth}
        />
      </div>

      {/* {totalCount > pageSize && (
        <div className="pagination-container">
          <Pagination
            onPageChange={onPageChange}
            totalCount={totalCount}
            siblingCount={siblingCount}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        </div>
      )} */}
    </SCTableContainer>
  );
};

export default TableContainer;
