import React from 'react';
import SCMobileMenuSubItem from './MobileMenuSubItem.style';

const ApplicationMenuSubItem = ({ onClick, text, selected, ...props }) => {
  return (
    <SCMobileMenuSubItem onClick={onClick} selected={selected} {...props}>
      <span>{text}</span>
    </SCMobileMenuSubItem>
  );
};

export default ApplicationMenuSubItem;
