import styled from '@emotion/styled';

const SCEmptyMessage = styled.div`
  height: 100px;
  width: 100%;
  background-color: var(--light-grey-4);
  border: 1px solid var(--light-grey-3);
  border-radius: 10px;
  font-family: var(--font1);
  font-weight: 500;
  color: var(--bluish-grey);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default SCEmptyMessage;
