import React from 'react';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import RadioButton from '../../atoms/RadioButton/RadioButton';
import SCCarouselHandler from './CarouselHandler.style';

const CarouselHandler = ({
  activeStep,
  setActiveStep,
  firstPageButton,
  lastPageButton,
  totalSteps,
}) => {
  const i18n = useTranslation();
  return (
    <SCCarouselHandler>
      {activeStep > 1 ? (
        <Button
          onClick={() => {
            setActiveStep(activeStep - 1);
          }}
          text={i18n.t('tutorials.previous')}
          size="large"
          color="white"
          width="200px"
        />
      ) : (
        firstPageButton
      )}
      <div className="carousel-handler-steps">
        {[...Array(totalSteps)].map((_, index) => (
          <RadioButton
            isChecked={activeStep === index + 1}
            onClick={() => setActiveStep(index + 1)}
          />
        ))}
      </div>
      {activeStep < totalSteps ? (
        <Button
          onClick={() => {
            setActiveStep(activeStep + 1);
          }}
          text={i18n.t('tutorials.next')}
          isHidden={activeStep === totalSteps}
          size="large"
          color="red"
          width="200px"
        />
      ) : (
        lastPageButton ?? <div style={{ width: '200px' }} />
      )}
    </SCCarouselHandler>
  );
};

export default CarouselHandler;
