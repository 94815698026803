import { faPalette, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useRef, useState } from 'react';
import useTag from '../../../hooks/tags/useTag';

import Icon from '../Icon/Icon';
import IndeterminateCheckbox, {
  CHECKBOX_STATES,
} from '../IndeterminateCheckbox/IndeterminateCheckbox';
import RemoveTag from '../RemoveTag/RemoveTag';
import TagLabel from '../TagLabel/TagLabel';
import TagsBox from '../TagsBox/TagsBox';
import TagsPalette from '../TagsPalette/TagsPalette';
import SCTag from './Tag.style';

const Tag = ({ tag, onChangeTagCheckbox, tagAppearance }) => {
  const { updateTag, deleteTag } = useTag();
  const [isOpenPaletteBox, setIsOpenPaletteBox] = useState(false);
  const [isOpenRemoveTagBox, setIsOpenRemoveTagBox] = useState(false);
  const [checkboxState, setCheckboxState] = useState(tagAppearance);
  const refIconPalette = useRef(null);

  useEffect(() => {
    setCheckboxState(tagAppearance);
  }, [tag.tagAppearance, tagAppearance]);

  const handleCheckboxChange = () => {
    let newCheckboxState;
    if (checkboxState === CHECKBOX_STATES.checked) {
      newCheckboxState = CHECKBOX_STATES.empty;
    } else if (checkboxState === CHECKBOX_STATES.indeterminate) {
      newCheckboxState = CHECKBOX_STATES.empty;
    } else {
      newCheckboxState = CHECKBOX_STATES.checked;
    }

    setCheckboxState(newCheckboxState);
    onChangeTagCheckbox(tag.id, newCheckboxState);
  };

  const handleTagColor = async (tagColor) => {
    try {
      await updateTag(tag.id, tagColor);
      setIsOpenPaletteBox(false);
    } catch (e) {
      console.error('Tag - handleTagColor():', e);
    }
  };

  const handleRemoveTag = async () => {
    try {
      await deleteTag(tag.id);
      setIsOpenRemoveTagBox(false);
    } catch (e) {
      console.error('Tag - handleRemoveTag():', e);
    }
  };

  const handlePaletteBox = () => {
    setIsOpenRemoveTagBox(false);
    setIsOpenPaletteBox(!isOpenPaletteBox);

    refIconPalette.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const handleRemoveTagBox = () => {
    setIsOpenPaletteBox(false);
    setIsOpenRemoveTagBox(!isOpenRemoveTagBox);

    refIconPalette.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <SCTag key={tag.id}>
      <div className="Tag_Container">
        <div className="Tag_Info">
          <IndeterminateCheckbox
            state={checkboxState}
            onChange={handleCheckboxChange}
          />
          <TagLabel
            value={tag.name}
            color={tag.color}
            height="30px"
            fontSize="16px"
          />
        </div>
        <div className="Tag_Actions" ref={refIconPalette}>
          <Icon
            iconName={faPalette}
            height="15px"
            color="var(--bluish-grey)"
            onClick={() => handlePaletteBox()}
          />
          <Icon
            iconName={faTrashCan}
            height="15px"
            color="var(--bluish-grey)"
            onClick={() => handleRemoveTagBox()}
          />
        </div>
      </div>

      <div className="Tag_Palette_Container">
        <TagsBox isOpenBox={isOpenPaletteBox}>
          <TagsPalette
            handleTagColor={handleTagColor}
            selectedColor={tag.color}
          />
        </TagsBox>
      </div>

      <div className="Tag_Palette_Container">
        <TagsBox isOpenBox={isOpenRemoveTagBox}>
          <RemoveTag
            handleRemoveTag={handleRemoveTag}
            setIsOpenRemoveTagBox={setIsOpenRemoveTagBox}
          />
        </TagsBox>
      </div>
    </SCTag>
  );
};

export default Tag;
