import styled from '@emotion/styled';

const SCSupplierNameCard = styled.div`
  .ss-supplier-summary {
    > h2 {
      color: var(--black);
      font-family: var(--font2);
      font-weight: 500;
      font-size: 40px;
      margin-bottom: 10px;
    }

    .ss-supplier-summary-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
      gap: 50px;

      > div {
        & h3 {
          font-family: var(--font2);
          font-size: 20px;
          font-weight: 200;
          margin-bottom: 5px;
        }

        & span {
          font-weight: 500;
          font-size: 20px;
        }
      }

      .ss-supplier-summary-info {
        width: 100%;
        min-width: 460px;
        display: flex;
        flex-wrap: wrap;

        .ss-supplier-summary-info-content {
          padding: 25px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          > div {
            display: flex;
            align-items: center;
            gap: 20px;
          }
        }
      }
    }

    > div:last-of-type {
      display: flex;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;
    }

    @media (width < 769px) {
      > h2 {
        text-align: center;
      }
      .ss-supplier-summary-content {
        flex-direction: column;
        gap: 20px;

        .ss-supplier-summary-info {
          justify-content: center;

          .ss-supplier-summary-info-content:nth-child(2) {
            order: 3;
          }
        }
      }
    }

    /* > div:last-of-type {
        flex-direction: column;
      } */
  }
`;

export default SCSupplierNameCard;
