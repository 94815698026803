import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import {
  faDolly,
  faEnvelope,
  faGlobe,
  faIndustry,
} from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import {
  addSupplierList,
  editSupplierList,
} from '../../../redux/actions/monitoredSuppliers.actions';
import { ErrorKey, TrackButton } from '../../../track';

import { useTranslation } from '../../../i18n';
import { simpleIndustries } from '../../../utils/helper';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Icon from '../../atoms/Icon/Icon';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const AddSupplierPopUpContainer = styled.div`
  text-align: center;
  display: flex;
  overflow: auto;
  flex-direction: column;

  h1,
  p {
    text-align: center;
  }

  p {
    font-size: 14px;
    margin: 0;
    line-height: 16px;
  }

  h1 {
    font-size: 25px;
  }

  .AddSupplierPopUpContent {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .AddSupplier-select-container {
    position: relative;
    display: inline-block;
    width: 100%;

    .select-icon-container {
      position: absolute;
      height: 100%;
      width: 30px;
      display: flex;
      justify-content: center;
      color: var(--bluish-grey);
    }

    select {
      height: 32.5px;
      cursor: pointer;
      color: var(--bluish-grey);
      font-family: var(--font2);
      font-size: 16px;
      font-weight: 400;
      border-bottom: 1px solid #c4c4c4;
      border-top: none;
      border-right: none;
      border-left: none;
      padding-left: 35px;
      padding-right: 35px;
      width: 100%;

      /* for Firefox */
      -moz-appearance: none;

      /* for Chrome */
      -webkit-appearance: none;

      background: url('data:image/svg+xml;utf8,<svg style="filter: invert(63%) sepia(8%) saturate(749%) hue-rotate(169deg) brightness(83%) contrast(85%)" xmlns="http://www.w3.org/2000/svg" width="22.5" height="22.5" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"/></svg>')
        no-repeat;
      background-position-x: calc(100% - 5px);
      background-position-y: 5px;
      background-color: transparent;

      outline: 0px;
    }

    /* For IE10 */
    select::-ms-expand {
      display: none;
    }
  }
  .add-button {
    margin-top: 40px;
    margin-bottom: 10px;
  }
`;

const AddSupplier = (props) => {
  const dispatch = useDispatch();
  const client = useSelector((redux) => redux.client);
  const i18n = useTranslation();
  const navigate = useNavigate();
  const [domain, setDomain] = useState('');
  const [name, setName] = useState('');
  const [atEmail, setAtEmail] = useState();
  const [domainToDelete, setDomainToDelete] = useState();
  const [sector, setSector] = useState();
  const { isAdd, user } = props;
  const isAddI18n = isAdd ? 'add' : 'update';

  const reg =
    /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{1,11}?$/im;
  const validate = (e) => {
    e.target.disabled = true;
    if (!domain || !name || atEmail === undefined || !sector) {
      ErrorKey('common.errorFillForm');
      e.target.disabled = false;
      return;
    }
    if (
      (domain && domain.indexOf('/') > -1) ||
      (atEmail && atEmail.indexOf('/') > -1)
    ) {
      ErrorKey(`suppliersSecurity.${isAddI18n}.errorContainsSlash`);
      e.target.disabled = false;
      return;
    }
    const strippedAtEmail = atEmail.replace(/@/g, '');
    if (!reg.test(domain) || !reg.test(strippedAtEmail)) {
      ErrorKey(`suppliersSecurity.${isAddI18n}.errorDomainFormat`);
      e.target.disabled = false;
      return;
    }
    if (isAdd) {
      props.addSupplierList({
        domain,
        name,
        atEmail: strippedAtEmail,
        sector,
      });
    } else {
      const strippedDomain = domain
        .replace('https://', '')
        .replace('http://', '');
      const strippedDomainToDelete = domainToDelete
        .replace('https://', '')
        .replace('http://', '');
      const diff = strippedDomain !== strippedDomainToDelete;
      props.editSupplierList({
        domain: strippedDomain,
        name,
        atEmail: strippedAtEmail,
        domainToDelete: diff && strippedDomainToDelete,
        sector,
      });
      navigate('/client/supplier-security');
    }
  };

  const getSupplierSector = (website, monitoredSuppliers) => {
    if (monitoredSuppliers[website]) {
      return monitoredSuppliers[website].sector;
    }
    return false;
  };

  useEffect(() => {
    if (!isAdd && user) {
      setDomain(user.domain);
      setName(user.name);
      setAtEmail(user.atEmail);
      setDomainToDelete(user.domain);
      setSector(getSupplierSector(user.domain, client.monitoredSuppliers));
    }
  }, []);

  const changeHandlerDomain = (value) => {
    const normalizedValue = `${value
      .replace(/https:/g, '')
      .replace(/\//g, '')
      .replace(/http:/g, '')}`
      .trim()
      .toLowerCase();
    setDomain(normalizedValue);
    setAtEmail(`@${normalizedValue.replace(/www\./g, '')}`);
  };

  const changeHandlerAtEmail = (value) => {
    const normalizedValue = `@${value
      .replace(/@www./g, '')
      .replace(/@/g, '')
      .replace(/https:\/\//g, '')
      .replace(/http:\/\//g, '')}`
      .trim()
      .toLowerCase();
    setAtEmail(normalizedValue);
  };

  return (
    <ModalLayout>
      <AddSupplierPopUpContainer>
        <h1>{i18n.t(`suppliersSecurity.${isAddI18n}.title`)}</h1>
        <div className="modal-card-content AddSupplierPopUpContent">
          <Input
            name="name"
            id="name"
            inputType="text"
            icon={faDolly}
            defaultValue={name || ''}
            onChangeValue={(val) => setName(val.trim())}
            inputPlaceholder={i18n.t(`suppliersSecurity.${isAddI18n}.name`)}
          />
          <div className="AddSupplier-select-container">
            <div className="select-icon-container">
              <Icon iconName={faIndustry} />
            </div>

            <select
              value={sector || '0'}
              onChange={(e) => setSector(e.target.value)}
              style={{ cursor: 'pointer' }}>
              <option value="0">
                {i18n.t('setup.company.industry.placeholder')}
              </option>
              {simpleIndustries.map((simpleIndustrie) => (
                <option value={simpleIndustrie.value}>
                  {/* {simpleIndustrie.label} */}
                  {i18n.t(`setup.company.industries.${simpleIndustrie.label}`)}
                </option>
              ))}
            </select>
          </div>

          <Input
            name="domain"
            id="domain"
            inputType="text"
            icon={faGlobe}
            defaultValue={domain || ''}
            onChangeValue={(val) => changeHandlerDomain(val.trim())}
            inputPlaceholder={i18n.t(`suppliersSecurity.${isAddI18n}.website`)}
          />
          <Input
            name="atEmail"
            id="atEmail"
            inputType="text"
            icon={faEnvelope}
            defaultValue={atEmail && atEmail !== true ? atEmail : ''}
            onChangeValue={(val) => changeHandlerAtEmail(val.trim())}
            inputPlaceholder={i18n.t(`suppliersSecurity.${isAddI18n}.email`)}
          />
        </div>
        <div className="modal-card-buttons">
          <Button
            text={i18n.t(`suppliersSecurity.${isAddI18n}.validateButton`)}
            onClick={(e) => {
              TrackButton(`suppliersSecurity.${isAddI18n}.validateButton`);
              validate(e);
            }}
            size="full"
            color="bluishGrey"
          />
          <Button
            text={i18n.t('websiteSecurity.add.cancel')}
            onClick={() => dispatch(showPopUp(null))}
            size="full"
            color="white"
          />
        </div>
      </AddSupplierPopUpContainer>
    </ModalLayout>
  );
};

export default connect(undefined, { addSupplierList, editSupplierList })(
  AddSupplier
);
