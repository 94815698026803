import { SHOW_POP_UP } from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case SHOW_POP_UP:
      return action.payload || null;
    default:
      return state;
  }
};
