import { faPlus, faTags, faXmark } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import Select from '../../atoms/Select/Select';
import SCTagFilter from './TagFilter.style';

const TagFilter = ({
  filter,
  index,
  onModify,
  onRemove,
  avoidFilterRolesAsTag,
}) => {
  const i18n = useTranslation();
  const tags =
    useSelector((redux) => redux.client).tags?.map((tag) => tag.name) ?? [];

  const [selectedTags, setSelectedTags] = useState([]);

  const [unselectedTags, setUnselectedTags] = useState(
    avoidFilterRolesAsTag
      ? [...tags]
      : [
          i18n.t(`manageEmailList.administrator`),
          i18n.t(`manageEmailList.teamMember`),
          i18n.t(`manageEmailList.noTags`),
          ...tags,
        ]
  );

  useEffect(() => {
    if (filter && selectedTags !== []) {
      setUnselectedTags(
        (avoidFilterRolesAsTag
          ? [...tags]
          : [
              i18n.t(`manageEmailList.administrator`),
              i18n.t(`manageEmailList.teamMember`),
              i18n.t(`manageEmailList.noTags`),
              ...tags,
            ]
        ).filter((tag) => !selectedTags.includes(tag))
      );

      onModify(selectedTags);
    }
  }, [selectedTags]);

  useEffect(() => {
    if (filter && JSON.stringify(filter) !== JSON.stringify(selectedTags)) {
      setSelectedTags(filter);
    }
  }, [filter]);

  useEffect(() => {
    if (
      !tags.every((tag) => {
        return selectedTags.includes(tag) || unselectedTags.includes(tag);
      })
    ) {
      setUnselectedTags(
        (avoidFilterRolesAsTag
          ? [...tags]
          : [
              i18n.t(`manageEmailList.administrator`),
              i18n.t(`manageEmailList.teamMember`),
              i18n.t(`manageEmailList.noTags`),
              ...tags,
            ]
        ).filter((tag) => !selectedTags.includes(tag))
      );
    }
  }, [tags]);

  const addNewFilterTag = (tag) => {
    setSelectedTags([...selectedTags, tag]);
  };

  const replaceFilterTag = (newTag, tagToReplace) => {
    const selectedTagsAux = [...selectedTags];
    const tagToReplaceIndex = selectedTagsAux.findIndex((selectedTag) => {
      return selectedTag === tagToReplace;
    });
    selectedTagsAux[tagToReplaceIndex] = newTag;
    setSelectedTags(selectedTagsAux);
  };

  return (
    <SCTagFilter>
      <div className="TagFilter_MainContainer">
        {filter && (
          <>
            <div className="TagFilter_ContentContainer">
              <div className="TagFilter_InfoContainer">
                <div className="TagFilter_IndexContainer">{index + 1}.</div>
                <Icon iconName={faTags} />
                <div className="TagFilter_LabelContainer">
                  {i18n.t('tagFiltering.tagFilter.tagSelectionLabel')}
                </div>
              </div>
              <div className="TagFilter_Selections">
                {selectedTags.map((selectedTag) => (
                  <div className="TagFilter_Selection">
                    <Select
                      selectedValue={selectedTag}
                      onChange={(newTag) => {
                        replaceFilterTag(newTag, selectedTag);
                      }}
                      values={[selectedTag, ...unselectedTags]}
                      design="round"
                    />
                    <p>{i18n.t('tagFiltering.tagFilter.and')}</p>
                  </div>
                ))}
                <Select
                  disabled={unselectedTags.length === 0}
                  selectPlaceholder={i18n.t(
                    'tagFiltering.tagFilter.select.placeholder'
                  )}
                  onChange={(tag) => {
                    addNewFilterTag(tag);
                  }}
                  values={unselectedTags}
                  selectedValue=""
                  design="round"
                />
              </div>
            </div>
            <div className="TagFilter_CloseActionContainer">
              <Button
                onClick={() => {
                  onRemove();
                }}
                color="icon"
                type="button"
                icon={faXmark}
              />
            </div>
          </>
        )}
        {!filter && (
          <div className="TagFilter_NewFilter">
            <Button
              text={i18n.t('tagFiltering.actions.addNewFilter')}
              onClick={() => {
                onModify();
              }}
              color="icon"
              width="100%"
              type="button"
              size="fit"
              icon={faPlus}
            />
          </div>
        )}
      </div>
    </SCTagFilter>
  );
};

export default TagFilter;
