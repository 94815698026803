const baseLineChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    events: {
      click: undefined,
    },
  },
  xaxis: {
    categories: [],
    tickPlacement: 'between',
    tickAmount: 1,
    labels: {
      formatter() {},
      hideOverlappingLabels: false,
      showDuplicates: false,
    },
  },
  yaxis: {
    min: 0,
    tickAmount: 5,
    max: 10,
    show: true,
  },
  markers: {
    size: 3,
    discrete: [],
  },
  stroke: {
    width: 3,
    curve: 'smooth',
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    show: true,
    borderColor: '#F6F6F6',
    xaxis: {
      lines: {
        show: false,
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      shadeIntensity: 1,
      opacityFrom: 1,
      opacityTo: 1,
      colorStops: [],
    },
  },
  tooltip: {
    marker: {
      show: false,
    },
  },
  toolbar: {
    show: false,
  },
};

export default baseLineChartOptions;
