import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  faCheck,
  faCopy,
  faTrashCanUndo,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCircleExclamation,
  faDesktop,
  faServer,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { Skeleton } from 'antd';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import installationTokensService from '../../../services/installationTokens/installationTokens.service';
import { getDateFormat } from '../../../utils/format/dataFormat';
import deviceProtectionService from '../../../services/deviceProtection/deviceProtection.service';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import { ErrorKey } from '../../../track';

const SCLicensesSentByEmployeePopUp = styled.div`
  h2 {
    font-weight: 500;
    font-family: var(--font1);
    font-size: 25px;
  }

  p {
    font-size: 14px;
    color: inherit;
  }

  .licenses-cards-container {
    --cards-gap: 10px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--cards-gap);

    .license-card {
      color: var(--bluish-grey);
      width: calc(50% - (var(--cards-gap) / 2));
      background-color: var(--bluish-grey-3);
      padding: 15px;
      border-radius: 10px;

      display: flex;
      flex-direction: column;
      gap: 30px;

      .license-type {
        display: flex;
        flex-direction: column;
        gap: 10px;

        > p {
          font-weight: 600;
        }
      }

      .installed-pending-counters {
        display: flex;
        justify-content: center;
        gap: 50px;

        .counter {
          > p {
            font-weight: 600;
            white-space: pre-line;

            > span {
              font-size: 22px;
            }
          }
        }
      }

      .actions-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        > button {
          width: 100%;
        }
      }

      .expiration-date-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        > p {
          > span {
            font-weight: 600;
          }
        }
      }
    }
  }

  @media (width < 768px) {
    .licenses-cards-container .license-card {
      width: 100%;
    }
  }
`;

const IS_COPIED_DEFAULT_STATE = {
  desktop: false,
  server: false,
};

const IS_LOADING_REVOKE_TOKEN_DEFAULT_STATE = {
  desktop: false,
  server: false,
};

const DEVICE_TYPES = {
  DESKTOP: 'desktop',
  SERVER: 'server',
  ANDROID: 'android',
  IOS: 'ios',
};

const LicensesSentByEmployeePopUp = ({ email }) => {
  const { id: clientId } = useSelector((redux) => redux.client);
  const teamMember = useSelector((redux) => redux.teamMembers)?.find(
    (member) => member.email === email
  );
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const [expirationDates, setExpirationDates] = useState({});
  const [isCopied, setIsCopied] = useState(IS_COPIED_DEFAULT_STATE);
  const [isLoadingRevokeToken, setIsLoadingRevokeToken] = useState(
    IS_LOADING_REVOKE_TOKEN_DEFAULT_STATE
  );
  const [isLoadingExpirationDate, setIsLoadingExpirationDate] = useState(true);

  const {
    licenses: { sent: sentLicenses = {}, used: usedLicenses = {} } = {},
    setupTokens = {},
  } = teamMember;

  const LICENSES_INFO = [
    {
      title: i18n.t('deviceSecurity.licensesSentByEmployeeModal.desktop'),
      icon: faDesktop,
      deviceType: DEVICE_TYPES.DESKTOP,
    },
    {
      title: i18n.t('deviceSecurity.licensesSentByEmployeeModal.server'),
      icon: faServer,
      deviceType: DEVICE_TYPES.SERVER,
    },
    {
      title: 'Android',
      icon: faAndroid,
      deviceType: DEVICE_TYPES.ANDROID,
    },
    {
      title: 'iOS',
      icon: faApple,
      deviceType: DEVICE_TYPES.IOS,
    },
  ];

  const availableLicensesByDevice = {
    [DEVICE_TYPES.DESKTOP]:
      sentLicenses[DEVICE_TYPES.DESKTOP] - usedLicenses[DEVICE_TYPES.DESKTOP],
    [DEVICE_TYPES.SERVER]:
      sentLicenses[DEVICE_TYPES.SERVER] - usedLicenses[DEVICE_TYPES.SERVER],
    [DEVICE_TYPES.ANDROID]:
      sentLicenses[DEVICE_TYPES.ANDROID] - usedLicenses[DEVICE_TYPES.ANDROID],
    [DEVICE_TYPES.IOS]:
      sentLicenses[DEVICE_TYPES.IOS] - usedLicenses[DEVICE_TYPES.IOS],
  };

  useEffect(() => {
    const fetchExpirationDates = async () => {
      try {
        if (availableLicensesByDevice[DEVICE_TYPES.DESKTOP]) {
          const desktopInstallationToken =
            await installationTokensService.getInstallationTokenByValue({
              clientId,
              value: setupTokens.desktop,
            });
          setExpirationDates({
            desktop: desktopInstallationToken?.expirationDate,
          });
        }

        if (availableLicensesByDevice[DEVICE_TYPES.SERVER]) {
          const serverInstallationToken =
            await installationTokensService.getInstallationTokenByValue({
              clientId,
              value: setupTokens.server,
            });
          setExpirationDates((prev) => ({
            ...prev,
            server: serverInstallationToken?.expirationDate,
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingExpirationDate(false);
      }
    };

    fetchExpirationDates();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCopied(IS_COPIED_DEFAULT_STATE);
    }, 2000);

    return () => clearTimeout(timer);
  }, [isCopied.desktop, isCopied.server]);

  const getSetupToken = (token) => {
    if (!token) {
      return null;
    }

    return `${clientId}${token}`;
  };

  const handleCopy = (token, deviceType) => {
    setIsCopied((prevState) => ({
      ...prevState,
      [deviceType]: true,
    }));

    const tokenWithClientId = getSetupToken(token);
    navigator.clipboard.writeText(tokenWithClientId);
  };

  const getExpirationDate = (expirationDate) => {
    const date = getDateFormat(expirationDate);
    const hours = String(expirationDate?.getHours()).padStart(2, '0');
    const minutes = String(expirationDate?.getMinutes()).padStart(2, '0');

    return i18n.t('deviceSecurity.licensesSentByEmployeeModal.expirationDate', {
      date,
      hours,
      minutes,
    });
  };

  const closeModal = () => {
    dispatch(showPopUp(null));
  };

  const handleRevokeToken = (token, deviceType) => {
    setIsLoadingRevokeToken((prevState) => ({
      ...prevState,
      [deviceType]: true,
    }));

    deviceProtectionService
      .revokeToken({ tokenValue: token })
      .then(closeModal)
      .catch(() => {
        ErrorKey('errors.not_found');
      })
      .finally(() => {
        setIsLoadingRevokeToken((prevState) => ({
          ...prevState,
          [deviceType]: false,
        }));
      });
  };

  return (
    <ModalLayout>
      <SCLicensesSentByEmployeePopUp>
        <h2>
          {i18n.t(
            'deviceSecurity.licensesSentByEmployeeModal.licensesAssignedTo',
            {
              email,
            }
          )}
        </h2>

        {!isLoadingExpirationDate ? (
          <>
            <div className="modal-card-content licenses-cards-container">
              {LICENSES_INFO.map(({ title, icon, deviceType }) => {
                const nbSentLicenses = sentLicenses[deviceType];
                const nbInstalledLicenses = usedLicenses[deviceType];
                const availableLicenses = nbSentLicenses - nbInstalledLicenses;
                const token = setupTokens[deviceType];
                const isTokenCopied = isCopied[deviceType];
                const isRevokeLoading = isLoadingRevokeToken[deviceType];
                const expirationDate = expirationDates[deviceType]?.toDate();

                const isDesktopOrServer = [
                  DEVICE_TYPES.DESKTOP,
                  DEVICE_TYPES.SERVER,
                ].includes(deviceType);

                if (!nbSentLicenses) {
                  return null;
                }

                return (
                  <div className="license-card">
                    <div className="license-type">
                      <FontAwesomeIcon icon={icon} size="xl" />
                      <p>{title}</p>
                    </div>

                    <div className="installed-pending-counters">
                      <div className="counter">
                        <p>
                          <span>{nbInstalledLicenses}</span>
                          <br />
                          {i18n
                            .t(
                              'deviceSecurity.licensesSentByEmployeeModal.installedLicenses'
                            )
                            .replace(' ', '\n')}
                        </p>
                      </div>

                      <div className="counter">
                        <p
                          style={{
                            color:
                              availableLicenses > 0
                                ? 'var(--red)'
                                : 'var(--bluish-grey-2)',
                          }}>
                          <span>{availableLicenses}</span>
                          <br />
                          {i18n
                            .t(
                              'deviceSecurity.licensesSentByEmployeeModal.availableLicenses'
                            )
                            .replace(' ', '\n')}
                        </p>
                      </div>
                    </div>

                    {isDesktopOrServer && availableLicenses > 0 && (
                      <>
                        <div className="actions-container">
                          <Button
                            icon={isTokenCopied ? faCheck : faCopy}
                            text={
                              isTokenCopied
                                ? i18n.t('common.copied')
                                : i18n.t(
                                    'deviceSecurity.licensesSentByEmployeeModal.copyToken'
                                  )
                            }
                            size="mid"
                            color="white"
                            onClick={() => {
                              handleCopy(token, deviceType);
                            }}
                            disabled={isTokenCopied}
                          />
                          <Button
                            icon={!isRevokeLoading && faTrashCanUndo}
                            text={
                              isRevokeLoading ? (
                                <SpinnerText text={i18n.t('common.loading')} />
                              ) : (
                                i18n.t(
                                  'deviceSecurity.licensesSentByEmployeeModal.revokePendings'
                                )
                              )
                            }
                            size="mid"
                            color="white"
                            onClick={() => handleRevokeToken(token, deviceType)}
                            disabled={isRevokeLoading}
                          />
                        </div>

                        <div className="expiration-date-container">
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            size="lg"
                            color="var(--red)"
                          />

                          <p>
                            {i18n.t(
                              'deviceSecurity.licensesSentByEmployeeModal.notInstalledLicensesExpiredAt'
                            )}{' '}
                            <br />
                            {expirationDate && (
                              <span>{getExpirationDate(expirationDate)}</span>
                            )}
                          </p>
                        </div>
                      </>
                    )}

                    {deviceType === DEVICE_TYPES.ANDROID && (
                      <p>
                        {i18n.t(
                          'deviceSecurity.selectEmailsModal.androidMobileInfo'
                        )}
                      </p>
                    )}

                    {deviceType === DEVICE_TYPES.IOS && (
                      <p>
                        {i18n.t(
                          'deviceSecurity.selectEmailsModal.iosMobileInfo'
                        )}
                      </p>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="modal-card-buttons">
              <Button
                size="full"
                color="white"
                text={i18n.t('common.back')}
                onClick={closeModal}
              />
            </div>
          </>
        ) : (
          <Skeleton active />
        )}
      </SCLicensesSentByEmployeePopUp>
    </ModalLayout>
  );
};

export default LicensesSentByEmployeePopUp;
