import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';

const buttonMenuHeight = 64; // px

const fadeIn = keyframes`
  0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const selectedStyles = () => css`
  color: var(--white);
  background: var(--red-gradient);
`;

const unselectedStyles = () => css`
  color: var(--bluish-grey);
  background-color: var(--white);
`;

const hoverUnselectedStyles = () => css`
  color: var(--red);
  background-color: var(--bluish-grey-4);
`;

const rightAlign = () => css`
  right: 0px;
`;

const leftAlign = () => css`
  left: 0px;
`;

const aligns = {
  left: leftAlign,
  right: rightAlign,
};

const SCTopMenuItem = styled.button`
  position: relative;
  min-width: 64px;
  width: 100%;
  height: ${buttonMenuHeight}px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  ${({ selected }) => (selected ? selectedStyles : unselectedStyles)};

  &:hover {
    ${({ selected }) => (selected ? selectedStyles : hoverUnselectedStyles)};
  }

  /* &:hover > div.dropdown-container {
    display: block;
  } */

  > span {
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
    font-family: var(--font1);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: inherit;
  }

  .dropdown-wrapper {
    display: ${({ isDroppedDown }) => (isDroppedDown ? 'block' : 'none')};
    background: transparent;
    position: absolute;
    cursor: auto;
    height: auto;
    width: max-content;
    //top: Altura del botón menos margen de seguridad.
    top: ${buttonMenuHeight - 2}px;
    ${({ align }) => aligns[align]};
    animation: ${fadeIn} 0.25s;

    .dropdown-container {
      background-color: var(--white);
      border-radius: 5px;
      height: auto;
      margin-top: 15px;

      -webkit-box-shadow: 2px 2px 20px 0px rgba(121, 136, 151, 0.15);
      box-shadow: 2px 2px 20px 0px rgba(121, 136, 151, 0.15);

      > p {
        color: var(--bluish-grey);
        font-family: var(--font1);
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        padding: 5px 10px;
      }

      .dropdown-items {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 10px 0px;
      }
    }
  }
`;

export default SCTopMenuItem;
