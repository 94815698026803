import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../../firebase';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import { TrackInfo } from '../../track';

const useDashboard = () => {
  const client = useSelector((redux) => redux.client);
  const firebase = useSelector((redux) => redux.firebase);
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const saveSession = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const stripeSessionId = urlParams.get('sSId');

    if (!stripeSessionId) return false;
    setLoading(true);
    // if (client.santanderP) await createdUtagData(client.id);
    TrackInfo('welcome.paidSubscription', 'payments');
    await axios
      .post('/stripe-session', {
        sessionId: stripeSessionId,
        clientId: client.id,
      })
      .then(() => {
        dispatch(
          showPopUp('notification', {
            notificationType: 'success',
            title: i18n.t('welcome.yourPaymentHasBeenConfirmed'),
            text: i18n.t('welcome.subscriptionHasStarted'),
            subtext: i18n.t('welcome.thanksforJoining'),
          })
        );
        setLoading(false);
        navigate('/client');
      })
      .catch(() => {
        setLoading(false);
      });
    return false;
  };

  const updateLastVisit = () => {
    firestore.collection('clients').doc(client.id).update({
      lastVisit: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  return {
    loading,
    saveSession,
    updateLastVisit,
  };
};

export default useDashboard;
