import styled from '@emotion/styled/macro';

const SCSubNavBar = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  border-top: 1px solid var(--application-background-color);

  h3 {
    font-family: var(--font1);
    font-size: 16px;
    color: var(--bluish-grey);
  }

  .section-info {
    display: flex;
    align-items: center;
    gap: 10px;

    svg,
    h3 {
      color: var(--bluish-grey-2);
    }
  }
`;

export default SCSubNavBar;
