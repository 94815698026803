import { faEye, faRotate } from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import axios from 'axios';
import { faFileInvoice } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { ErrorKey } from '../../../track';
import FaqWebsite from '../../Dashboard/Sections/Faq/FaqWebsite';
import SCWebsiteSecurityVisionFilterCard from './WebsiteSecurityVisionFilterCard.style';
import web from '../../../img/clientDashboard/web.svg';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import Button from '../../atoms/Button/Button';
import ScanResultBox from '../../molecules/ScanResultBox/ScanResultBox';
import Filter from '../../molecules/Filter/Filter';
import useDomains from '../../../hooks/domains/useDomains';
import { toBase64 } from '../../../utils/functions/base64';
import { getDateFormat } from '../../../utils/format/dataFormat';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import RadarSpinner from '../../atoms/RadarSpinner/RadarSpinner';
import useRunScan from '../../../hooks/useRunScan/useRunScan';

const WebsiteSecurityVisionFilterCard = () => {
  const i18n = useTranslation();
  const monitoredDomains = useSelector((redux) => redux.monitoredDomains);
  const client = useSelector((redux) => redux.client);
  const { addDomain, removeDomain } = useDomains();
  const dispatch = useDispatch();
  const FaqContent = FaqWebsite();
  const [currentDomain, setCurrentDomain] = useState(monitoredDomains[0]);
  const [didMount, setDidMount] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const {
    scanResult,
    loading,
    isAbleToRescan,
    isLoadingNewScan,
    handleRunScan,
  } = useRunScan({ domain: currentDomain.domain, atEmail: client.atEmail });

  useEffect(() => {
    if (didMount && monitoredDomains && monitoredDomains.length > 0) {
      setCurrentDomain(monitoredDomains.at(-1));
    }

    setDidMount(true);
  }, [monitoredDomains.length]);

  // Every time monitoredDomains changes and also have information inside website object, we set the current domain to the updated domain received from redux.
  useEffect(() => {
    const newDomain = monitoredDomains.find((monitoredDomain) => {
      return monitoredDomain.domain === currentDomain.domain;
    });

    if (newDomain && newDomain.website) {
      setCurrentDomain(newDomain);
    }
  }, [monitoredDomains]);

  const changeCurrentDomain = (targetDomain) => {
    const newDomain = monitoredDomains.find((monitoredDomain) => {
      return monitoredDomain.domain === targetDomain;
    });

    setCurrentDomain(newDomain);
  };

  const threatDescriptionPopup = (attackName) => {
    dispatch(
      showPopUp('websiteSecurityPopup', {
        title: FaqContent[attackName].faqTitle,
        answer: FaqContent[attackName].faqAnswer,
      })
    );
  };

  const getReportPDF = (domain) => {
    ReactGA.event({
      category: 'Risk Portal',
      action: 'Download Report',
      label: `${domain}`,
    });
    setIsDownloadingReport(true);
    axios
      .get(
        `/get_webreport_pdf/${toBase64(domain)}?companyName=${
          client.company.name || ''
        }&atEmail=${toBase64(client.atEmail || '')}`,
        {
          responseType: 'blob',
          headers: { 'Accept-Language': i18n.getLanguage() },
        }
      )
      .then((response) => {
        if (window.navigator && window.navigator.msSaveBlob) {
          // IE11 and the legacy version Edge support
          const blob = new Blob([response.data], { type: 'application/pdf' });
          window.navigator.msSaveBlob(blob, `${domain}.pdf`);
        } else {
          // other browsers
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${domain}.pdf`); // or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {
        axios
          .get(
            `/get_webreport_pdf/${toBase64(domain)}?companyName=${
              client.company.name || ''
            }`,
            {
              responseType: 'blob',
              headers: { 'Accept-Language': i18n.getLanguage() },
            }
          )
          .then((response) => {
            if (window.navigator && window.navigator.msSaveBlob) {
              // IE11 and the legacy version Edge support
              const blob = new Blob([response.data], {
                type: 'application/pdf',
              });
              window.navigator.msSaveBlob(blob, `${domain}.pdf`);
            } else {
              // other browsers
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' })
              );
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${domain}.pdf`); // or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(() => ErrorKey('errors.thereWasAnError', 'getReportPDF'));
      })
      .finally(() => {
        setIsDownloadingReport(false);
      });
  };

  return (
    <SCWebsiteSecurityVisionFilterCard>
      <Filter
        selectedValue={currentDomain.domain}
        filterValues={monitoredDomains.map(
          (monitoredDomain) => monitoredDomain.domain
        )}
        onChangeFilter={changeCurrentDomain}
        addButtonText={i18n.t('websiteSecurity.addMore')}
        removeButtonText={i18n.t('websiteSecurity.removeDomain')}
        addButtonOnClick={() => addDomain()}
        removeButtonDisabled={monitoredDomains.length === 1}
        removeButtonOnClick={() => {
          removeDomain(currentDomain.domain);
        }}
      />

      {monitoredDomains && (
        <SectionCard
          headerIcon={faEye}
          headerTitle={`${i18n.t('websiteSecurity.summaryFor')} ${
            currentDomain.domain
          }`}
          headerButtonIcon={!isLoadingNewScan && faRotate}
          headerButtonText={
            isLoadingNewScan ? (
              <SpinnerText text={i18n.t('common.analyzing')} />
            ) : (
              i18n.t('websiteSecurity.runScan')
            )
          }
          headerButtonOnClick={handleRunScan}
          headerButtonDisabled={loading || isLoadingNewScan || !isAbleToRescan}>
          <div className="scan-container">
            {!loading &&
            !isLoadingNewScan &&
            currentDomain?.timestamp?.toDate() >=
              scanResult.timestamp.toDate() ? (
              <div className="scan-result-box-wrapper">
                <div className="scan-result-box-container">
                  <div className="scan-result-row">
                    <div className="mitm-result-wrap">
                      <ScanResultBox
                        title={i18n.t(
                          'websiteSecurity.results.man_in_the_middle.title'
                        )}
                        scanScore={currentDomain.website.mitm.score}
                        learnMoreOnClick={() =>
                          threatDescriptionPopup('man_in_the_middle')
                        }
                        lineHeight="87.5px"
                      />
                    </div>
                    <div className="clickjacking-result-wrap">
                      <ScanResultBox
                        title={i18n.t(
                          'websiteSecurity.results.clickjacking.title'
                        )}
                        scanScore={currentDomain.website.clickjacking.score}
                        learnMoreOnClick={() =>
                          threatDescriptionPopup('clickjacking')
                        }
                        lineHeight="185px"
                      />
                    </div>
                    <div className="xss-result-wrap">
                      <ScanResultBox
                        title={i18n.t('websiteSecurity.results.xss.title')}
                        scanScore={currentDomain.website.xss.score}
                        learnMoreOnClick={() => threatDescriptionPopup('xss')}
                        lineHeight="105px"
                      />
                    </div>
                    <div className="ddos-result-wrap">
                      <ScanResultBox
                        title={i18n.t('websiteSecurity.results.ddos.title')}
                        scanScore={currentDomain.website.ddos.score}
                        learnMoreOnClick={() => threatDescriptionPopup('ddos')}
                        lineHeight="70px"
                      />
                    </div>
                  </div>

                  <div className="scan-images-row">
                    <img src={web} alt="hacker-svg" />
                  </div>
                </div>

                <div className="scan-download-container">
                  <Button
                    onClick={() => {
                      getReportPDF(currentDomain.domain);
                    }}
                    icon={!isDownloadingReport && faFileInvoice}
                    text={
                      isDownloadingReport ? (
                        <SpinnerText text={i18n.t('misc.preparing')} />
                      ) : (
                        i18n.t('websiteSecurity.downloadReport')
                      )
                    }
                    size="mid"
                    color="white"
                  />
                  <div className="last-scanned-date">
                    {i18n.t('websiteSecurity.lastScanned')}{' '}
                    {/* {currentDomain?.date} */}
                    {getDateFormat(currentDomain?.timestamp.toDate())}
                  </div>
                </div>
              </div>
            ) : (
              <div className="scan-running">
                <RadarSpinner />
                <div className="scan-running-content">
                  <h3>{i18n.t('websiteSecurity.scanIsRunning')}</h3>
                  <p>{i18n.t('websiteSecurity.scanDuration')}</p>
                  <p>{i18n.t('websiteSecurity.scanDuration2')}</p>
                </div>
              </div>
            )}
          </div>
        </SectionCard>
      )}
    </SCWebsiteSecurityVisionFilterCard>
  );
};

export default WebsiteSecurityVisionFilterCard;
