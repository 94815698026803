import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import SCNavMenuMobile from './NavMenuMobile.style';

const NavMenuMobile = ({ showMobileMenu }) => {
  const navigate = useNavigate();
  const i18n = useTranslation();

  return (
    <SCNavMenuMobile open={showMobileMenu}>
      <a id="logo" href="/">
        <img
          src="https://storage.googleapis.com/cyberguardian-assets/logo-vertical-centrado-rojoynegro.svg"
          alt="Cyber Guardian logo"
        />
      </a>

      <div className="button-container">
        <Button
          onClick={() => navigate('/sign-in')}
          color="white"
          text={i18n.t('home.logIn')}
          size="full"
        />
        <Button
          onClick={() => navigate('/setup-account')}
          color="red"
          text={i18n.t('home.register')}
          size="full"
        />
      </div>
    </SCNavMenuMobile>
  );
};

export default NavMenuMobile;
