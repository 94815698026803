import styled from '@emotion/styled';

const SCCarouselHandler = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .carousel-handler-steps {
    width: 35%;
    display: flex;
    justify-content: space-between;
    border-radius: var(--section-radius);
    align-items: center;
  }

  @media (width < 769px) {
    padding: 0 30px;
    flex-direction: column-reverse;
    gap: 10px;
    align-items: center;

    > div {
      order: 1;
    }
    > button {
      width: 100%;
    }
    .carousel-handler-steps {
      margin-bottom: 5px;
      margin-top: 15px;
      width: 60%;
      max-width: 200px;
      touch-action: none;

      > span {
        height: 7px;
        width: 7px;
        pointer-events: none;
      }
    }
  }
`;

export default SCCarouselHandler;
