import styled from '@emotion/styled';

const SCLastUpdateText = styled.p`
  font-size: 12px;
  font-style: italic;
  color: var(--bluish-grey);
  text-align: right;
`;

export default SCLastUpdateText;
