import styled from '@emotion/styled';

const SCSupplierSecurityExplanationCard = styled.div`
  .ss-evaluation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: row;

      > div {
        width: 390px;
      }
    }

    & svg {
      color: var(--bluish-grey);
      margin: 0 10px 0 0;
    }

    & h3 {
      font-size: 16px;
      font-family: var(--font2);
      font-weight: 600;
      margin-bottom: 0;
      height: 58px;
    }

    & p {
      font-size: 16px;
      font-family: var(--font2);
      font-weight: 300;
      margin-bottom: 0;
    }
  }
`;

export default SCSupplierSecurityExplanationCard;
