import styled from '@emotion/styled';

/* Función que devuelve la altura del TableContainer en función de la altura del header de la tabla, si tiene, + el tamaño de cada celda x pageSize */
const getTableContainerHeight = (
  totalCount,
  pageSize,
  hasHeaders,
  rowHeight
) => {
  if (totalCount > pageSize) {
    return hasHeaders
      ? `${50 + 2 + (rowHeight || 50) * pageSize}px`
      : `${(rowHeight || 50) * pageSize}px`;
  }

  return 'auto';
};

const SCTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* transform: translate(-30px, 0px);  TODO para omitir el padding del contenedor TODO
  width: calc(100% + 60px); */

  .table-container {
    overflow-x: ${({ hasHorizontalScroll }) =>
      hasHorizontalScroll ? 'auto' : 'hidden'};
    overflow-y: overlay;
    height: ${({ totalCount, pageSize, hasHeaders, rowHeight }) =>
      getTableContainerHeight(totalCount, pageSize, hasHeaders, rowHeight)};
    border: ${({ rowsLength }) =>
      rowsLength !== 0 && '1px solid var(--light-grey-3)'};
    border-radius: ${({ rowsLength }) => rowsLength !== 0 && '10px'};
    /* border: 1px solid var(--light-grey-3); */

    /* border-radius: 10px; */

    ::-webkit-scrollbar {
      height: 20px;
      width: 20px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      margin-top: 50px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--bluish-grey-3);

      border-radius: 90px;
      border: 5px solid white;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: var(--bluish-grey);
    }
  }

  .table-filterInfo {
    margin: ${({ rowHasBeenSelected }) =>
      rowHasBeenSelected ? '0px 0px 30px 0px' : '0'};
    hr {
      margin: 15px 50px;
    }
  }

  .table-filter {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 30px;

    > span {
      font-size: 30px;
      font-weight: 300;
      color: var(--light-grey-2);
      margin: 0 5px;
      line-height: 1;
    }
  }

  .pagination-container {
    display: flex;
    justify-content: center;
  }
`;

export default SCTableContainer;
