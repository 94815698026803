import styled from '@emotion/styled';

const SCActionFAQ = styled.div`
  display: flex;
  flex-direction: row;
  place-content: center;
  align-items: center;
  width: fit-content;

  svg {
    margin-right: 10px;
  }
  & a {
    margin: 0;
    color: var(--bluish-grey);
    font-size: 16px;
  }
`;

export default SCActionFAQ;
