import React, { useState } from 'react';
import SCTooltip from './Tooltip.style';

function Tooltip({ children, tooltip }) {
  const [display, setDisplay] = useState({
    show: false,
    mouseX: NaN,
    mouseY: NaN,
  });

  return (
    <SCTooltip
      forceDisplay={display.show}
      onMouseLeave={() => {
        setDisplay({
          ...display,
          show: false,
        });
      }}
      onMouseMove={(e) => {
        setDisplay({
          show: e.target.offsetWidth < e.target.scrollWidth,
          mouseX: e.clientX,
          mouseY: e.clientY,
        });
      }}>
      {children}
      <div
        className="show-tooltip"
        style={{
          top: `calc(${display.mouseY}px - 15px)`,
          left: `calc(${display.mouseX}px + 15px)`,
        }}>
        {tooltip}
      </div>
    </SCTooltip>
  );
}

export default Tooltip;
