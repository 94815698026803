import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import {
  showPopUp,
  removePhishingCampaign,
} from '../../../redux/actions/popUp.actions';
import { TrackButton } from '../../../track';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const SCRemoveCampaign = styled.div`
  h1 {
    text-align: center;
    font-family: var(--font1);
    font-size: 25px;
    font-weight: 600;
  }
`;

const RemoveCampaign = (props) => {
  const i18n = useTranslation();

  const removeCampaign = () => {
    props.removePhishingCampaign();
  };
  const closeModal = () => {
    props.showPopUp(null);
  };

  return (
    <ModalLayout>
      <SCRemoveCampaign>
        <h1>{i18n.t('phishingSimulator.campaigns.delete.question')}</h1>

        <div className="modal-card-buttons">
          <Button
            text={i18n.t('phishingSimulator.campaigns.delete.yes')}
            onClick={() => {
              removeCampaign();
              TrackButton('phishingSimulator.campaigns.delete.yes');
            }}
            size="full"
            color="bluishGrey"
          />
          <Button
            text={i18n.t('phishingSimulator.campaigns.delete.no')}
            onClick={() => {
              closeModal();
              TrackButton('phishingSimulator.campaigns.delete.yes');
            }}
            size="full"
            color="white"
          />
        </div>
      </SCRemoveCampaign>
    </ModalLayout>
  );
};

export default connect(undefined, { showPopUp, removePhishingCampaign })(
  RemoveCampaign
);
