import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import ReactGA from 'react-ga';

import { useSelector } from 'react-redux';
import {
  faDownload,
  faShieldCheck,
  faSyncAlt,
} from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import { useTranslation } from '../../../i18n';
import RadialChart from '../../molecules/RadialChart/RadialChart';
import {
  getLineChartOptions,
  getLineChartSeries,
} from '../../../utils/chartFactory/chartFactory';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCSecurityOverview from './SecurityOverview.style';
import { getRiskColor } from '../../../utils/helper';
import { getDateFormat } from '../../../utils/format/dataFormat';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import { ErrorKey } from '../../../track';
import useRunScan from '../../../hooks/useRunScan/useRunScan';
import useSubscriptionType, {
  SUBSCRIPTIONS_PRODUCTS,
} from '../../../hooks/subscriptionType/useSubscriptionType';

const SecurityOverview = () => {
  const client = useSelector((state) => state.client);
  const { currentSubscription } = useSubscriptionType();
  const i18n = useTranslation();
  const { loading, isAbleToRescan, isLoadingNewScan, handleRunScan } =
    useRunScan({
      domain: client.monitoredDomains?.[0],
      atEmail: client.atEmail,
    });

  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  const getScoreChartData = () => {
    const monitoredDomain = client.monitoredDomains?.[0];
    const data = client?.scoring?.history?.[monitoredDomain];

    if (!data || data?.length === 0) return undefined;

    const sortedData = data?.sort((a, b) => a.date.toDate() - b.date.toDate());

    const parsedData = {
      scores: [],
      dates: [],
    };

    sortedData?.forEach((item) => {
      parsedData.scores.push(item.value);
      parsedData.dates.push(item.date.toDate());
    });

    return parsedData;
  };

  const rawChartData = getScoreChartData();

  const chartxAxisLabelFormat = (value) => {
    return getDateFormat(value, {
      month: 'short',
      year: undefined,
    });
  };

  const getChartOptions = () => {
    let chartOptionsAux = getLineChartOptions({
      xAxisCategory: [],
      fillColors: [],
    });

    if (rawChartData) {
      chartOptionsAux = getLineChartOptions({
        xAxisCategory: rawChartData?.dates,
        xAxisLabelFormatter: chartxAxisLabelFormat,
        discreteMarkerColors: rawChartData.scores.map(
          (singleFilteredData, index) => {
            return {
              seriesIndex: 0,
              dataPointIndex: index,
              fillColor: getRiskColor(singleFilteredData),
              strokeColor: '#FFF',
              size: 3,
            };
          }
        ),
        fillColors: rawChartData.scores.map((singleFilteredData, index) => {
          return {
            offset: Math.abs(
              (1 / rawChartData.scores.length) * (index + 1) * 100
            ),
            color: getRiskColor(singleFilteredData),
            opacity: 1,
          };
        }),
      });
    }

    return chartOptionsAux;
  };

  const getChartSeries = () => {
    let chartSeriesAux = getLineChartSeries({
      name: i18n.t('score.securityChartLabel'),
    });

    if (rawChartData) {
      chartSeriesAux = getLineChartSeries({
        name: i18n.t('score.securityChartLabel'),
        data: rawChartData.scores,
      });
    }

    return chartSeriesAux;
  };

  const downloadReport = (domain) => {
    setIsDownloadingReport(true);

    ReactGA.event({
      category: 'Risk Portal',
      action: 'Download Report',
      label: `${domain}`,
    });
    const pdfUrl = `/get_report_pdf/last`;
    axios
      .get(pdfUrl, {
        responseType: 'blob',
        headers: { 'Accept-Language': i18n.getLanguage() },
      })
      .then((response) => {
        if (window.navigator && window.navigator.msSaveBlob) {
          // IE11 and the legacy version Edge support
          const blob = new Blob([response.data], { type: 'application/pdf' });
          window.navigator.msSaveBlob(blob, `${domain}.pdf`);
        } else {
          // other browsers
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${domain}.pdf`); // or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => ErrorKey('errors.thereWasAnError', 'getReportPDF'))
      .finally(() => setIsDownloadingReport(false));
  };

  return (
    <SCSecurityOverview>
      <SectionCard
        headerIcon={faShieldCheck}
        headerTitle={i18n.t('score.yourCybersecurityLevel')}
        {...(rawChartData &&
          currentSubscription?.product !== SUBSCRIPTIONS_PRODUCTS.DEFAULT && {
            headerButtonIcon: !isLoadingNewScan && faSyncAlt,
            headerButtonText: isLoadingNewScan ? (
              <SpinnerText text={i18n.t('common.analyzing')} />
            ) : (
              i18n.t('websiteSecurity.runScan')
            ),
            headerButtonDisabled:
              loading || isLoadingNewScan || !isAbleToRescan,
            headerButtonOnClick: handleRunScan,
          })}>
        <div className="security-overview-container">
          <div className="boxesHolder">
            <div className="boxWrapperRadialChart">
              <RadialChart score={client?.scoring?.lastValue} />
            </div>

            <div className="boxWrapper">
              <Chart
                type="line"
                options={getChartOptions()}
                series={getChartSeries()}
                height={247}
              />
            </div>
          </div>

          {rawChartData?.scores.length > 0 && (
            <InfoBanner
              text={[i18n.t('score.downloadInfoBannerText')]}
              buttonIcon={!isDownloadingReport && faDownload}
              buttonText={
                isDownloadingReport ? (
                  <SpinnerText text={i18n.t('misc.preparing')} />
                ) : (
                  i18n.t('score.downloadReport')
                )
              }
              onClickButton={() => downloadReport(client.monitoredDomains?.[0])}
              buttonDisabled={isDownloadingReport}
              width="mid"
            />
          )}
        </div>
      </SectionCard>
    </SCSecurityOverview>
  );
};

export default SecurityOverview;
