import ReactGA from 'react-ga';
import Swal from 'sweetalert2';
import { i18n } from './i18n';

export const TrackButton = (action, label) => {
  ReactGA.event({
    category: 'Button',
    action,
    label,
  });
};

export const TrackError = (action, label) => {
  ReactGA.event({
    category: 'Error',
    action,
    label,
  });
};

export const TrackInfo = (action, label) => {
  ReactGA.event({
    category: 'Info',
    action,
    label,
  });
};

// export const ErrorMessage = (message, label) => {
//   Swal.fire(i18n.t('common.error'), message, 'error');
//   TrackError(message, label);
// };

export const ErrorKey = (i18nKey, label, i18nOptions) => {
  // const dispatch = useDispatch();

  // dispatch(
  //   showPopUp('notification', {
  //     notificationType: 'error',
  //     title: i18n.t('common.error'),
  //     text: i18n.t(i18nKey, i18nOptions),
  //   })
  // );
  Swal.fire({
    title: i18n.t('common.wow'),
    html: i18n.t(i18nKey, i18nOptions),
    icon: 'error',
    confirmButtonText: i18n.t('common.close'),
  });
  TrackError(i18nKey, label);
  // return Swal.fire(i18n.t('common.wow'), i18n.t(i18nKey, i18nOptions), 'error');
};

export const WarningKey = (i18nKey, label, i18nOptions) => {
  // const dispatch = useDispatch();

  // dispatch(
  //   showPopUp('notification', {
  //     notificationType: 'warning',
  //     title: i18n.t('common.warning'),
  //     text: i18n.t(i18nKey, i18nOptions),
  //   })
  // );
  Swal.fire(i18n.t('common.warning'), i18n.t(i18nKey, i18nOptions), 'warning');
  TrackError(i18nKey, label);
};

export const InfoKey = (i18nKey, label, i18nOptions) => {
  // const dispatch = useDispatch();

  // dispatch(
  //   showPopUp('notification', {
  //     notificationType: 'success',
  //     title: '',
  //     text: i18n.t(i18nKey, i18nOptions),
  //   })
  // );
  // Swal.fire('', i18n.t(i18nKey, i18nOptions), 'info');
  Swal.fire({
    title: i18n.t('common.info'),
    html: i18n.t(i18nKey, i18nOptions),
    icon: 'info',
    confirmButtonText: i18n.t('common.close'),
  });
  TrackInfo(i18nKey, label);
};

export const SuccessKey = (i18nKey, label, i18nOptions) => {
  // const dispatch = useDispatch();

  // dispatch(
  //   showPopUp('notification', {
  //     notificationType: 'success',
  //     title: '',
  //     text: i18n.t(i18nKey, i18nOptions),
  //   })
  // );
  TrackInfo(i18nKey, label);
  return Swal.fire('', i18n.t(i18nKey, i18nOptions), 'success');
};

export const ErrorKeyCustomized = ({
  i18nKey,
  i18nOptions,
  label,
  swalOptions,
  onClickConfirm,
}) => {
  const defaultSwalOptions = {
    icon: 'error',
    // iconHtml: '<i class="fa fa-times"></i>',
    confirmButtonColor: 'var(--red)',
    confirmButtonText: i18n.t('common.close'),
  };

  // finalSwalOptions takes the defaultSwalOptions and overwrites any common properties with incoming params (swalOptions).
  const finalSwalOptions = { ...defaultSwalOptions, ...swalOptions };

  Swal.fire({
    title: i18n.t('common.error'),
    html: i18n.t(i18nKey, i18nOptions),
    ...finalSwalOptions,
  }).then((result) => {
    if (result.isConfirmed && onClickConfirm) {
      onClickConfirm();
    }
  });
  TrackError(i18nKey, label);
};

export const SuccessKeyCustomized = ({
  i18nKey,
  i18nOptions,
  label,
  swalOptions,
}) => {
  const defaultSwalOptions = {
    icon: 'success',
    iconHtml: '<i class="fa fa-check"></i>',
  };

  // finalSwalOptions takes the defaultSwalOptions and overwrites any common properties with incoming params (swalOptions).
  const finalSwalOptions = { ...defaultSwalOptions, ...swalOptions };

  Swal.fire({
    title: i18n.t('common.greeting'),
    text: i18n.t(i18nKey, i18nOptions),
    ...finalSwalOptions,
  });
  TrackError(i18nKey, label);
};
