import React from 'react';
import { faCommentQuestion } from '@fortawesome/pro-regular-svg-icons';
import Icon from '../../atoms/Icon/Icon';
import SCActionFAQ from './ActionFAQ.style';

const ActionFAQ = ({ FAQName, FAQResource }) => {
  return (
    <SCActionFAQ>
      <Icon iconName={faCommentQuestion} color="var(--bluish-grey);" />
      <a href={FAQResource} target="_blank" rel="noreferrer">
        {FAQName}
      </a>
    </SCActionFAQ>
  );
};

export default ActionFAQ;
