import { firestore } from '../../firebase';
import { GET_MANAGED_THREAT_COUNTERS } from './types';

export default (clientId, dispatch) => {
  firestore
    .collection('clients')
    .doc(clientId)
    .collection('threat_counters')
    .onSnapshot((snapshot) => {
      const managedThreatCounters = [];

      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          if (doc.exists) {
            const counter = doc.data();
            managedThreatCounters.push(counter);
          }
        });
      }

      dispatch({
        type: GET_MANAGED_THREAT_COUNTERS,
        payload: managedThreatCounters,
      });
    });
};
