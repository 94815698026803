import { firestore } from '../../firebase';

import { GET_MONITORED_DOMAINS } from './types';

const timestampToDateString = (timestamp) => {
  const date = `${timestamp.toDate().getUTCDate()}/${
    timestamp.toDate().getUTCMonth() + 1
  }/${timestamp.toDate().getUTCFullYear()}`;
  return date;
};

export const setDomainListener = async (client, dispatch) => {
  if (client.monitoredDomains) {
    const monitoredDomains = client.monitoredDomains.map((item) => ({
      domain: item,
    }));

    client.monitoredDomains.forEach((domain, index) => {
      firestore
        .collection('reports')
        .where('domain', '==', domain)
        .where('client', '==', client.id)
        .where('emailAt', '==', client.atEmail)
        .where('inProgress', '==', false)
        .orderBy('timestamp', 'desc')
        .limit(1)
        .onSnapshot((domainSnapshot) => {
          domainSnapshot.forEach((doc) => {
            if (doc.data()) {
              const domainObject = doc.data();

              monitoredDomains[index] = {
                ...monitoredDomains[index],
                ...domainObject,
                date: timestampToDateString(domainObject.timestamp),
              };
            }
          });
          dispatch({
            type: GET_MONITORED_DOMAINS,
            payload: [...monitoredDomains],
          });
        });
    });
  }
};

export default true;
