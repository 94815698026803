import React from 'react';
import SCMiniCard from './MiniCard.style';

const MiniCard = ({ children, onClick, ...props }) => (
  <SCMiniCard {...props} onClick={onClick}>
    {children}
  </SCMiniCard>
);

export default MiniCard;
