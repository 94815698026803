import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { firestore } from '../../firebase';
import { useTranslation } from '../../i18n';

const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const client = useSelector((redux) => redux.client);

  const i18n = useTranslation();

  function FilterEmptyChanges(ch) {
    if (!ch) return {};
    const filtered = Object.keys(ch)
      .filter((k) => ch[k])
      .reduce((acc, k) => ({ [k]: ch[k], ...acc }), {});
    return filtered;
  }

  const getNotificationInfo = (notification) => {
    if (!notification?.type || !notification?.changes) return '';
    // changes is a map (key-value) similar to actions.Progress
    // where the keys are the affected resource (domain, user_uuid, email hash for breaches)
    // and the values are structs/objects that include any relevant information
    const changes = FilterEmptyChanges(notification.changes);
    const numChanges = Object.keys(changes).length;
    if (numChanges < 1) {
      return '';
    }
    // TODO: use more info of what's in changes for each notification type
    //       https://github.com/Bewica/gofire/blob/staging/notifications/data.go#L103
    const typeKey = notification.type;
    if (notification.type === 'phishing') {
      // TODO: different kinds of phishing notification
    }
    let tKey = `notifications.messages.${typeKey}`;
    if (notification.level === 'info') {
      tKey = `notifications.messages.info.${typeKey}`;
    }
    return (
      i18n.t(tKey, { count: numChanges }) || `notification of type ${typeKey}`
    );
  };

  const getAllNotifications = async () => {
    let tmpNotifications = [];

    const notificationsSnap = await firestore
      .collection('notifications')
      .where('client', '==', client.id)
      .where('dismissed', '==', null)
      .orderBy('sent', 'desc')
      .limit(200)
      .get();

    notificationsSnap.forEach((doc) => {
      const data = { ...doc.data(), notificationId: doc.id };
      tmpNotifications.push(data);
    });

    tmpNotifications = tmpNotifications.filter(
      (n) => getNotificationInfo(n).length > 0
    );

    setNotifications(tmpNotifications);
  };

  useEffect(async () => {
    await getAllNotifications();
    setIsLoading(false);
  }, []);

  return {
    notifications,
    isLoading,
  };
};

export default useNotifications;
