import { useSelector } from 'react-redux';
import useSubscriptionType, {
  SUBSCRIPTIONS_PRODUCTS,
} from '../../../hooks/subscriptionType/useSubscriptionType';
import handleUnauthorizedAccess from '../../../utils/restrictedAccessBehaviour/restrictedAccessBehaviour';

function SupplierRouteGuard({ children }) {
  const { currentSubscription } = useSubscriptionType();
  const client = useSelector((redux) => redux.client);

  return currentSubscription?.product !== SUBSCRIPTIONS_PRODUCTS.DEFAULT &&
    client?.status?.serviceLevel.periodicitiesInDays.scanSuppliers > 0
    ? children
    : handleUnauthorizedAccess();
}
export default SupplierRouteGuard;
