import React from 'react';

const ScoreExampleES = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="780"
    height="217.5"
    viewBox="0 0 780 217.5">
    <g
      id="Group_1121"
      data-name="Group 1121"
      transform="translate(11171 -19034)">
      <g
        id="Group_1116"
        data-name="Group 1116"
        transform="translate(-11741 18496.5)">
        <text
          id="_40_"
          data-name="40%"
          transform="translate(570 618)"
          fill="#798897"
          fontSize="30"
          fontFamily="Poppins-SemiBold, Poppins"
          fontWeight="600">
          <tspan x="0" y="0">
            40%
          </tspan>
        </text>
        <g
          id="Component_98_2"
          data-name="Component 98 – 2"
          transform="translate(570 639)">
          <text
            id="Seguridad_interna:_Seguridad_de_dispositivos_Seguridad_de_buzones_Amenazas_gestionadas"
            data-name="Seguridad interna:

Seguridad de dispositivos
Seguridad de buzones
Amenazas gestionadas"
            fill="#2e2e2e"
            fontSize="14"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600">
            <tspan x="0" y="15">
              Seguridad interna:
            </tspan>
            <tspan fontFamily="Karla-Regular, Karla" fontWeight="400">
              <tspan x="0" y="34" />
              <tspan x="0" y="51">
                Seguridad de dispositivos
              </tspan>
              <tspan x="0" y="68">
                Seguridad de buzones
              </tspan>
              <tspan x="0" y="85">
                Amenazas gestionadas
              </tspan>
            </tspan>
          </text>
        </g>
        <line
          id="Line_33"
          data-name="Line 33"
          x2="184"
          transform="translate(649.5 607.5)"
          fill="none"
          stroke="#798897"
          strokeWidth="2"
        />
        <text
          id="_60_"
          data-name="60%"
          transform="translate(1285 618)"
          fill="#798897"
          fontSize="30"
          fontFamily="Poppins-SemiBold, Poppins"
          fontWeight="600">
          <tspan x="0" y="0">
            60%
          </tspan>
        </text>
        <line
          id="Line_34"
          data-name="Line 34"
          x2="184"
          transform="translate(1086.5 607)"
          fill="none"
          stroke="#798897"
          strokeWidth="2"
        />
        <g
          id="Component_99_2"
          data-name="Component 99 – 2"
          transform="translate(1086 639)">
          <text
            id="Exposición_en_internet:_Seguridad_del_correo_corporativo_Filtraciones_de_datos_Página_web_Posibles_suplantaciones"
            data-name="Exposición en internet:

Seguridad del correo corporativo
Filtraciones de datos
Página web
Posibles suplantaciones"
            fill="#2e2e2e"
            fontSize="14"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600">
            <tspan x="104.106" y="15">
              Exposición en internet:
            </tspan>
            <tspan fontFamily="Karla-Regular, Karla" fontWeight="400">
              <tspan x="264" y="34" />
              <tspan x="49.303" y="51">
                Seguridad del correo corporativo
              </tspan>
              <tspan x="125.82" y="68">
                Filtraciones de datos
              </tspan>
              <tspan x="188.855" y="85">
                Página web
              </tspan>
              <tspan x="103.469" y="102">
                Posibles suplantaciones
              </tspan>
            </tspan>
          </text>
        </g>
      </g>
      <g
        id="Group_1118"
        data-name="Group 1118"
        transform="translate(-11458.9 18401.1)">
        <path
          id="Subtraction_1"
          data-name="Subtraction 1"
          d="M72,144a71.662,71.662,0,0,1-40.256-12.3A72.216,72.216,0,0,1,5.658,100.025,71.9,71.9,0,0,1,12.3,31.744,72.212,72.212,0,0,1,43.974,5.658,71.9,71.9,0,0,1,112.256,12.3a72.218,72.218,0,0,1,26.086,31.678,71.9,71.9,0,0,1-6.639,68.282,72.208,72.208,0,0,1-31.677,26.086A71.549,71.549,0,0,1,72,144ZM71.28,10.08A61.92,61.92,0,1,0,133.2,72,61.99,61.99,0,0,0,71.28,10.08Z"
          transform="translate(605.9 632.9)"
          fill="#e7e7e7"
        />
        <path
          id="Subtraction_3"
          data-name="Subtraction 3"
          d="M72,144a71.666,71.666,0,0,1-40.257-12.3A72.228,72.228,0,0,1,5.658,100.026,71.9,71.9,0,0,1,12.3,31.744,72.206,72.206,0,0,1,43.975,5.658,71.555,71.555,0,0,1,72,0V10.084c-.225,0-.467,0-.72,0A61.92,61.92,0,1,0,133.2,72H144a71.662,71.662,0,0,1-12.3,40.256,72.208,72.208,0,0,1-31.677,26.087A71.56,71.56,0,0,1,72,144Z"
          transform="translate(605.9 632.9)"
          fill="#78bc12"
        />
        <text
          id="_7.5_Alta"
          data-name="7.5
Alta"
          transform="translate(676.9 708.9)"
          fill="#78bc12"
          fontSize="29"
          fontFamily="Poppins-Medium, Poppins"
          fontWeight="500">
          <tspan x="-20.88" y="0">
            7.5
          </tspan>
          <tspan fill="#798897" fontSize="12">
            <tspan x="-12.066" y="15">
              Alta
            </tspan>
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default ScoreExampleES;
