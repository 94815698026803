import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import axios from 'axios';
import { ConfigProvider } from 'antd';
import { authUser } from './redux/actions/auth.actions';
import { useTranslation } from './i18n';
import { update2fa } from './redux/actions/user.actions';
import { initiateFirebase } from './redux/actions/firebase.actions';
import { watchFirestore } from './redux/actions/firestore.actions';
import Spinner from './components/Misc/Spinner';
import { ErrorKey } from './track';
import useTawkToScript from './hooks/useTawkToScript/useTawkToScript';
import withRouter from './components/organisms/withRouterHOC/withRouterHOC';
import { getCurrentAntDLocale } from './utils/locale';
import { auth } from './firebase';
import { SESSION_STORAGE_KEYS } from './utils/constants/browserStorage';
import { setTeamMemberLanguage } from './services/teamMembers/teamMembers.service';

const StartUpWrapper = (props) => {
  const i18n = useTranslation();
  const { lang } = i18n;
  const locale = getCurrentAntDLocale(lang);
  const myUserPreferences = useSelector((redux) => redux.myUserPreferences);
  const client = useSelector((redux) => redux.client);
  const user = useSelector((redux) => redux.user);

  useEffect(() => {
    useTawkToScript(lang);

    if (myUserPreferences) {
      setTeamMemberLanguage(lang, client?.id, user?.hashedEmail);
    }
  }, [lang]);

  return (
    // AntD locale.
    <ConfigProvider locale={locale}>
      <Startup {...props} i18n={i18n} />
    </ConfigProvider>
  );
};

class Startup extends Component {
  static propTypes = {
    auth: PropTypes.object,
    children: PropTypes.object.isRequired,
    user: PropTypes.object,
    client: PropTypes.object,
  };

  constructor() {
    super();
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    const { authUser, initiateFirebase } = this.props;
    authUser();
    initiateFirebase();
    this.axiosIntercepts();
  }

  componentDidUpdate(prevProps) {
    const { i18n, auth } = this.props;
    const { firestore } = this.state;

    // Change document/tab title
    const splittedURL = window.location.pathname.split('/');
    const baseURL = splittedURL.slice(0, 3).join('/');

    let tabTitle = i18n.t(`urlToTitles.${baseURL}`);

    if (baseURL === '/client/settings' && splittedURL[3] === 'notifications') {
      tabTitle = i18n.t(`urlToTitles.${baseURL}/notifications`);
    }

    if (baseURL === '/email-verification/') {
      const urlParams = new URLSearchParams(window.location.search);
      const queryMode = urlParams.get('mode');
      if (queryMode === 'resetPassword')
        tabTitle = i18n.t(`urlToTitles.${baseURL}?mode=resetPassword`);
    }

    if (tabTitle)
      document.title = `${tabTitle} - ${i18n.t('common.cyberGuardian')}`;

    // ToDo - Es la única parte del código donde se sigue utilizando el this.props.auth, habría que ver cómo eliminarlo y no hacer uso del auth de redux.
    if (!prevProps.auth && auth) {
      this.check2fa();
    }

    if (auth?.currentUser?.uid && !firestore) {
      this.props.watchFirestore(auth.currentUser.uid);
      this.setState({ firestore: true });
    }
  }

  getAccessToken = async () => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      return token;
    }
    return null;
  };

  axiosIntercepts = () => {
    const { i18n } = this.props;

    axios.interceptors.request.use(async (config) => {
      const auxConfig = { ...config };
      // if its a request to back-end(contain origin url or a path that stats with /) add auth token
      if (
        config.url.indexOf(window.location.origin) > -1 ||
        config.url[0] === '/'
      ) {
        const token = await this.getAccessToken();
        const clientIdFromSessionStorage = sessionStorage.getItem(
          SESSION_STORAGE_KEYS.CLIENT_ID
        );

        auxConfig.headers.Authorization = token;

        if (clientIdFromSessionStorage) {
          auxConfig.headers['Overwrite-clientId'] = clientIdFromSessionStorage;
        }

        try {
          const lang = i18n.getLanguage();
          auxConfig.headers['App-Language'] = lang;
        } catch (e) {
          console.log(e);
        }
      }
      return auxConfig;
    });
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const auxError = { ...error };
        // Reject promise if usual error
        if (error.response.status !== 401) {
          return Promise.reject(error);
        }

        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        axios.interceptors.response.eject(interceptor);
        return auth.currentUser
          .getIdToken(true)
          .then((idToken) => {
            error.response.config.headers.Authorization = `${idToken}`;
            return axios(error.response.config);
          })
          .catch((er) => {
            if (window.location.pathname !== '/two-factor-authentication')
              window.location.href = '/sign-in';

            return Promise.reject(er);
          })
          .finally(this.axiosIntercepts);
      }
    );
  };

  check2fa = async () => {
    this.setState({ verifying: true });
    const { update2fa } = this.props;
    const { pathname } = window.location;
    if (auth?.currentUser) {
      await auth.currentUser
        .getIdTokenResult(true) // 1
        .then(async (idTokenResult) => {
          this.setState({ loaded: true });
          // 2 Confirm the user is an Admin
          if (idTokenResult.claims) {
            // 3
            update2fa({
              requires2fa: idTokenResult.claims.requires2fa || false,
              verified2fa: idTokenResult.claims.verified2fa || false,
            });
            // Show moderator UI.
            if (idTokenResult.claims.requires2fa) {
              const bedeid = localStorage.getItem('bedeid');
              if (!bedeid && pathname !== '/two-factor-authentication')
                window.location.href = '/two-factor-authentication';
              await axios
                .post('/2fa/verify-user', {
                  bedeid,
                })
                .then((res) => {
                  auth.signInWithCustomToken(res.data).then(() => {
                    update2fa({
                      requires2fa: idTokenResult.claims.requires2fa || false,
                      verified2fa: true,
                    });
                  });
                })
                .catch((err) => {
                  if (
                    err.response.status === 403 &&
                    pathname !== '/two-factor-authentication'
                  )
                    window.location.href = '/two-factor-authentication';
                });
              this.setState({ verifying: false });
            } else {
              this.setState({ verifying: false });
            }
          } else {
            // Show regular user UI.
            ErrorKey('authentication.errorWithAuthentication');
            auth.signOut();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (!auth?.currentUser) {
      this.setState({ loaded: true, verifying: false });
    }
  };

  render() {
    const { children, user, client, teamMembers } = this.props;
    const { loaded, verifying } = this.state;

    const isSetupAccountPage =
      window.location.pathname.split('/')[1] === 'setup-account';

    if (
      // Está autenticado, tenemos su user y hemos cargado el cliente
      (auth?.currentUser && user && client && teamMembers) ||
      // No está autenticado
      !auth?.currentUser ||
      // xd
      isSetupAccountPage
    ) {
      if (loaded && !verifying) {
        return children;
      }
    }
    return <Spinner loading />;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user,
    client: state.client,
    teamMembers: state.teamMembers,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    authUser,
    initiateFirebase,
    update2fa,
    watchFirestore,
  })(StartUpWrapper)
);
