import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeSaveCardForm from '../StripeSaveCardForm/StripeSaveCardForm';
import { useTranslation } from '../../../i18n';
import { getClientSecret } from '../../../services/subscriptions/subscription.service';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeSaveCard = ({ onConfirm }) => {
  const i18n = useTranslation();
  const [clientSecret, setClientSecret] = useState();

  useEffect(async () => {
    try {
      const { data } = await getClientSecret();
      setClientSecret(data?.data?.clientSecret);
    } catch (e) {
      console.log('Error', e);
    }
  }, []);

  const ELEMENTS_OPTIONS = {
    clientSecret,
    locale: i18n.lang,
  };

  return (
    <>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <StripeSaveCardForm onConfirm={onConfirm} />
        </Elements>
      ) : (
        <div>
          <SpinnerText text={i18n.t('common.loading')} />
        </div>
      )}
    </>
  );
};

export default StripeSaveCard;
