import styled from '@emotion/styled';

const SCVisionByEmployeePhishingTableCard = styled.div`
  .input-container {
    margin-bottom: 25px;
  }
  .pagination-container {
    margin: auto;
  }
`;

export default SCVisionByEmployeePhishingTableCard;
