import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import SCNavMenu from './NavMenu.style';

const NavMenu = () => {
  const navigate = useNavigate();
  const i18n = useTranslation();

  return (
    <SCNavMenu>
      <div className="button-container">
        <Button
          className="button-login"
          onClick={() => navigate('/sign-in')}
          color="white"
          text={i18n.t('home.logIn')}
          size="landing"
        />
        <Button
          onClick={() => navigate('/setup-account')}
          className="button-register"
          color="red"
          text={i18n.t('home.register')}
          size="landing"
        />
      </div>
    </SCNavMenu>
  );
};

export default NavMenu;
