import React, { useEffect, useState } from 'react';
import {
  faBadgeCheck,
  faBarsStaggered,
  faBell,
  faEnvelopeOpenText,
  faGear,
  faList,
  faPowerOff,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import SCSettingsMenuButtons, {
  SCNotificationAlerts,
} from './SettingsMenuButtons.style';
import TopMenuItem from '../../atoms/TopMenuItem/TopMenuItem';
import TopMenuSubItem from '../../atoms/TopMenuSubItem/TopMenuSubItem';
import { useTranslation } from '../../../i18n';
import Separator from '../../atoms/Separator/Separator';
import { signOut } from '../../../redux/actions/auth.actions';
import useSection from '../../../hooks/useSection/useSection';
import { firestore } from '../../../firebase';
import { isUserInTeamMembers } from '../../../utils/functions/teamMembers';

const SettingsMenuButtons = ({ setMobileMenu }) => {
  const [numberOfNotifications, setNumberOfNotifications] = useState(0);

  const client = useSelector((redux) => redux.client);

  const i18n = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { section, subSection } = useSection();

  const getBellNotificationsNumber = () => {
    firestore
      .collection('notifications')
      .where('client', '==', client.id)
      .where('dismissed', '==', null)
      .orderBy('sent', 'desc')
      .limit(10)
      .onSnapshot((snap) => {
        setNumberOfNotifications(snap.size);
      });
  };

  useEffect(getBellNotificationsNumber, []);

  const settingsRoute = isUserInTeamMembers() ? 'my-account' : 'employees';

  return (
    <SCSettingsMenuButtons>
      <div className="top-menu-notifications-icon">
        <TopMenuItem
          onClick={() => {
            navigate('/client/notifications');
          }}
          icon={faBell}
          selected={section === 'notifications'}
        />
        {numberOfNotifications > 0 && (
          <SCNotificationAlerts selected={subSection === 'notifications'}>
            {numberOfNotifications <= 9 ? numberOfNotifications : '+'}
          </SCNotificationAlerts>
        )}
      </div>

      <div id="top-menu-my-account-icon">
        <TopMenuItem
          onClick={() => {
            navigate(`/client/settings/${settingsRoute}`);
          }}
          icon={faGear}
          selected={section === 'settings'}
        />
      </div>

      <div id="settings-button">
        <TopMenuItem
          icon={faGear}
          selected={section === 'settings'}
          align="right">
          {isUserInTeamMembers() && (
            <TopMenuSubItem
              icon={faUser}
              text={i18n.t('profile.menu.myAccount')}
              onClick={() => navigate('/client/settings/my-account')}
            />
          )}
          <TopMenuSubItem
            icon={faList}
            text={i18n.t('profile.menu.employeesLong')}
            onClick={() => navigate('/client/settings/employees')}
          />
          {isUserInTeamMembers() && (
            <TopMenuSubItem
              icon={faEnvelopeOpenText}
              text={i18n.t('profile.menu.emailPreferences')}
              onClick={() => navigate('/client/settings/email-preferences')}
            />
          )}
          <TopMenuSubItem
            icon={faBadgeCheck}
            text={i18n.t('profile.menu.mySubscription')}
            onClick={() => navigate('/client/settings/billing')}
          />

          <div className="division-section">
            <Separator />
          </div>

          <TopMenuSubItem
            icon={faPowerOff}
            text={i18n.t('common.signOut')}
            onClick={() => {
              dispatch(signOut());
            }}
          />
        </TopMenuItem>
      </div>
      <Button
        id="mobile-menu-switcher-button"
        color="icon"
        text={
          <Icon
            iconName={faBarsStaggered}
            size="semiLarge"
            color="var(--bluish-grey)"
          />
        }
        onClick={() => {
          setMobileMenu(true);
        }}
      />
    </SCSettingsMenuButtons>
  );
};

export default SettingsMenuButtons;
