import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import useSuppliers from '../../../hooks/suppliers/useSuppliers';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';

const SCRemoveSupplier = styled.div`
  h1 {
    text-align: center;
    font-family: var(--font1);
    font-size: 25px;
    font-weight: 600;
  }
`;

const RemoveSupplier = ({ domain }) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const { loading, deleteSupplier } = useSuppliers();

  const closeModal = () => {
    dispatch(showPopUp(null));
  };

  return (
    <ModalLayout>
      <SCRemoveSupplier>
        <h1>{i18n.t('suppliersSecurity.delete.question')}</h1>
        <div className="modal-card-buttons">
          <Button
            text={
              loading ? (
                <SpinnerText text={i18n.t('common.loading')} />
              ) : (
                i18n.t('suppliersSecurity.delete.yes')
              )
            }
            onClick={() => {
              deleteSupplier(domain);
            }}
            disabled={loading}
            size="full"
            color="bluishGrey"
          />
          <Button
            text={i18n.t('suppliersSecurity.delete.no')}
            onClick={closeModal}
            size="full"
            color="white"
          />
        </div>
      </SCRemoveSupplier>
    </ModalLayout>
  );
};

export default RemoveSupplier;
