import styled from '@emotion/styled/macro';

const SCFullPageModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #2e2e2ef2;
  z-index: 2000000004;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  backdrop-filter: blur(30px);

  h3,
  p {
    color: var(--bluish-grey-2);
  }

  h3 {
    width: 300px;
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }
`;

export default SCFullPageModal;
