import React from 'react';
import {
  faCalendarCircleExclamation,
  faCloudExclamation,
  faList,
  faMemoCircleInfo,
} from '@fortawesome/pro-regular-svg-icons';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCBreachInformationCard from './BreachInformationCard.style';
import InfoSummary from '../../molecules/InfoSummary/InfoSummary';
import { getParameterByName } from '../../../utils/helper';
import useBreachNotificationType from '../../../hooks/breachNotificationType/useBreachNotificationType';
import { getDateFormat } from '../../../utils/format/dataFormat';
import { useTranslation } from '../../../i18n';

const BreachInformationCard = () => {
  const i18n = useTranslation();
  const { breaches } = useBreachNotificationType();
  const activeBreach = getParameterByName('breach', window.location.href);

  const infoSummaryData = {
    icons: [faCloudExclamation, faMemoCircleInfo, faCalendarCircleExclamation],
    titles: [
      i18n.t('breachNotification.breachDetails.serviceBreached'),
      i18n.t('breachNotification.breachDetails.typesOfDatacompromised'),
      i18n.t('breachNotification.breachDetails.dateOfBreach'),
    ],
    subtitles: [
      breaches[activeBreach]?.name,
      breaches[activeBreach]?.classes &&
        breaches[activeBreach]?.classes
          .map((item) => i18n.t(`breachNotification.breachClasses.${item}`))
          .join(', '),
      breaches[activeBreach]?.date &&
        getDateFormat(breaches[activeBreach].date.toDate()),
    ],
  };

  return (
    <SCBreachInformationCard>
      <SectionCard
        headerIcon={faList}
        headerTitle={i18n.t('breachNotification.breachDetails.title')}>
        <InfoSummary
          icons={infoSummaryData.icons}
          titles={infoSummaryData.titles}
          subtitles={infoSummaryData.subtitles}
        />
      </SectionCard>
    </SCBreachInformationCard>
  );
};

export default BreachInformationCard;
