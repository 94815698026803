import React from 'react';
import SCFullPageModal from './FullPageModal.style';

const FullPageModal = ({ mainText, secondaryText }) => {
  return (
    <SCFullPageModal>
      <h3>{mainText}</h3>
      {secondaryText && <p>{secondaryText}</p>}
    </SCFullPageModal>
  );
};

export default FullPageModal;
