import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import SCInvalidPaymentMethodBanner from './InvalidPaymentMethodBanner.style';
import { useTranslation } from '../../../i18n';

const InvalidPaymentMethodBanner = () => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const teamMembers = useSelector((redux) => redux.teamMembers);
  const user = useSelector((redux) => redux.user);

  const myTeamMember = teamMembers?.find(
    (teamMember) => teamMember.hashedEmail === user.hashedEmail
  );

  const handleInfoBannerOnClick = () => {
    const { pathname } = window.location;
    const targetRoute = '/client/settings/my-account';
    const targetId = 'payment-method-card';

    if (pathname === targetRoute) {
      document
        .getElementById(targetId)
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      navigate(targetRoute, {
        state: { targetId },
      });
    }
  };

  if (!myTeamMember) return null;

  return (
    <SCInvalidPaymentMethodBanner>
      {myTeamMember?.role?.owner ? (
        <InfoBanner
          type="error"
          text={[i18n.t('invalidPaymentBanner.owner.text')]}
          buttonText={i18n.t('invalidPaymentBanner.owner.buttonText')}
          onClickButton={handleInfoBannerOnClick}
        />
      ) : (
        <InfoBanner
          type="error"
          text={[i18n.t('invalidPaymentBanner.teamMember.text')]}
        />
      )}
    </SCInvalidPaymentMethodBanner>
  );
};

export default InvalidPaymentMethodBanner;
