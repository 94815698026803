import React, { useEffect, useState } from 'react';
import {
  faCircleQuestion,
  faReceipt,
  faShieldPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';
import SkeletonButton from 'antd/es/skeleton/Button';
import InfoBanner from '../../components/molecules/InfoBanner/InfoBanner';
import SCContractSubscription from './ContractSubscription.style';
import { ReactComponent as SantanderLogo } from '../../img/cyberguardian/santander-logo.svg';
import Icon from '../../components/atoms/Icon/Icon';
import { useTranslation } from '../../i18n';
import {
  getCurrencyFormat,
  getDateFormat,
} from '../../utils/format/dataFormat';
import Button from '../../components/atoms/Button/Button';
import { showPopUp } from '../../redux/actions/popUp.actions';
import Input from '../../components/atoms/Input/Input';
import LICENSEES from '../../utils/constants/licensees';
import {
  getAvailablePaymentFrequencies,
  getSubscriptionPrices,
} from '../../services/subscriptions/subscription.service';
import SpinnerText from '../../components/atoms/SpinnerText/SpinnerText';
import useStripePayment from '../../hooks/stripePayment/useStripePayment';
import getLegalDocumentsUrls from '../../utils/internationalizationModules/legalDocuments';

const PAYMENT_FREQUENCIES = {
  YEARLY: 'yearly',
  MONTHLY: 'monthly',
};

const ContractSubscription = () => {
  const client = useSelector((redux) => redux.client);
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const { createStripeSession, isLoading: isPaymentLinkLoading } =
    useStripePayment();
  const urlParams = new URLSearchParams(window.location.search);
  const totalLicensesURL = urlParams.get('totalLicenses');
  const paymentFrequencyURL = urlParams.get('paymentFrequency');
  const [isMonthlySubAllowed, setIsMonthlySubAllowed] = useState(false);
  const [paymentFrequency, setPaymentFrequency] = useState(
    paymentFrequencyURL === PAYMENT_FREQUENCIES.MONTHLY && isMonthlySubAllowed
      ? PAYMENT_FREQUENCIES.MONTHLY
      : PAYMENT_FREQUENCIES.YEARLY
  );
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [subscriptionPrices, setSubscriptionPrices] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [numLicenses, setNumLicenses] = useState({
    totalLicenses: Number(totalLicensesURL) || 0,
    computersLicenses: 0,
    mobilesLicenses: 0,
    serversLicenses: 0,
  });

  useEffect(() => {
    getAvailablePaymentFrequencies()
      .then(({ availableFrequencies }) => {
        const isMonthlySubAllowedResp = availableFrequencies.includes(
          PAYMENT_FREQUENCIES.MONTHLY
        );
        setIsMonthlySubAllowed(isMonthlySubAllowedResp);
      })
      .catch((e) => console.error(e));

    getSubscriptionPrices()
      .then((resp) => {
        setSubscriptionPrices(resp?.data?.data);
        setIsLoading(false);
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    const { computersLicenses, mobilesLicenses, serversLicenses } = numLicenses;

    if (computersLicenses || mobilesLicenses || serversLicenses) {
      setNumLicenses({
        ...numLicenses,
        totalLicenses: computersLicenses + mobilesLicenses + serversLicenses,
      });
    }
  }, [
    numLicenses.computersLicenses,
    numLicenses.mobilesLicenses,
    numLicenses.serversLicenses,
  ]);

  const yearlyMarketingPrice = subscriptionPrices.yearly?.marketingPrice;
  const monthlyMarketingPrice = subscriptionPrices.monthly?.marketingPrice;

  const marketingPrice = subscriptionPrices[paymentFrequency]?.marketingPrice;
  const licensePrice = subscriptionPrices[paymentFrequency]?.price;
  const discountValue = marketingPrice - licensePrice;

  const taxValue = subscriptionPrices.taxes?.value;

  const priceWithoutDiscount = numLicenses.totalLicenses * marketingPrice;
  const discountAmount = numLicenses.totalLicenses * discountValue * -1;
  const taxAmount = (priceWithoutDiscount + discountAmount) * (taxValue / 100);
  const subtotal = priceWithoutDiscount + discountAmount + taxAmount;
  const totalAmount =
    paymentFrequency === PAYMENT_FREQUENCIES.YEARLY ? subtotal : subtotal * 12;
  const anualSavingsPercentage = Math.trunc(
    (1 - yearlyMarketingPrice / (monthlyMarketingPrice * 12)) * 100
  );

  const fromDate = getDateFormat(new Date());
  const nextDate = new Date();
  nextDate.setFullYear(nextDate.getFullYear() + 1);
  const toDate = getDateFormat(nextDate);

  const shouldShowLinkToPlans = client.licensee === LICENSEES.santander;

  const handlePayment = async () => {
    if (!numLicenses.totalLicenses) {
      dispatch(
        showPopUp('notification', {
          notificationType: 'error',
          title: i18n.t('common.error'),
          text: i18n.t('contractSubscription.noLicensesModal.msg'),
        })
      );
    } else {
      await createStripeSession(
        numLicenses.totalLicenses,
        subscriptionPrices[paymentFrequency]?.id,
        subscriptionPrices.taxes?.id,
        subscriptionPrices.currency,
        paymentFrequency
      );
    }
  };

  const INSTALLATION_REQUIREMENTS_URLS = {
    es: 'https://cyberguardian.tawk.help/article/que-software-de-proteccion-se-va-a-instalar-en-los-dispositivos',
    en: 'https://cyberguardian.tawk.help/en-us/article/what-protection-software-is-going-to-be-installed-on-the-devices',
  };

  const { generalConditions } = getLegalDocumentsUrls(i18n.getLanguage());

  return (
    <SCContractSubscription isMonthlySubscriptionAllowed={isMonthlySubAllowed}>
      <InfoBanner
        type="error"
        text={[i18n.t('contractSubscription.content.discountText')]}
        customIcon={<SantanderLogo />}
      />

      <div className="subscription-container">
        <div className="configure-subscription-container">
          <div className="title">
            <Icon iconName={faShieldPlus} size="medium" />
            <h3>
              {i18n
                .t('contractSubscription.content.configureYourSubscription')
                .toUpperCase()}
            </h3>
          </div>

          <div className="configure-texts-container">
            <p>{i18n.t('contractSubscription.content.description-one')}</p>
            <p>{i18n.t('contractSubscription.content.description-two')}</p>
          </div>

          <div className="configure-contract-container">
            <div className="configure-contract-quantity-inputs">
              <p>
                <strong>
                  {i18n.t('contractSubscription.content.iWantToHire')}:
                </strong>
              </p>
              <div className="configure-contract-quantity-input">
                <Input
                  inputType="number"
                  size="semiShort"
                  design="round"
                  center
                  placeholder={0}
                  value={numLicenses.totalLicenses || ''}
                  onChange={(e) =>
                    setNumLicenses({
                      totalLicenses: e.target.valueAsNumber || 0,
                      computersLicenses: 0,
                      mobilesLicenses: 0,
                      serversLicenses: 0,
                    })
                  }
                />

                <p>{i18n.t('contractSubscription.content.licenses')}</p>
              </div>
            </div>

            <div className="configure-contract-quantity-inputs">
              <p>
                <strong>
                  {i18n.t('contractSubscription.content.orWeHelpYou')}:
                </strong>
              </p>

              <div className="configure-contract-quantity-input-container">
                <div className="configure-contract-quantity-input">
                  <Input
                    inputType="number"
                    size="semiShort"
                    design="round"
                    center
                    placeholder={0}
                    value={numLicenses.computersLicenses || ''}
                    onChange={(e) =>
                      setNumLicenses({
                        ...numLicenses,
                        computersLicenses: e.target.valueAsNumber || 0,
                      })
                    }
                  />

                  <p>{i18n.t('contractSubscription.content.computers')}</p>
                </div>

                <div className="configure-contract-quantity-input">
                  <Input
                    inputType="number"
                    size="semiShort"
                    design="round"
                    center
                    placeholder={0}
                    value={numLicenses.mobilesLicenses || ''}
                    onChange={(e) =>
                      setNumLicenses({
                        ...numLicenses,
                        mobilesLicenses: e.target.valueAsNumber || 0,
                      })
                    }
                  />

                  <p>{i18n.t('contractSubscription.content.mobiles')}</p>
                </div>

                <div className="configure-contract-quantity-input">
                  <Input
                    inputType="number"
                    size="semiShort"
                    design="round"
                    center
                    placeholder={0}
                    value={numLicenses.serversLicenses || ''}
                    onChange={(e) => {
                      setNumLicenses({
                        ...numLicenses,
                        serversLicenses: e.target.valueAsNumber || 0,
                      });
                    }}
                  />

                  <p>{i18n.t('contractSubscription.content.servers')}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="questions-wrapper">
            {shouldShowLinkToPlans && (
              <Link to="/license-plan-features">
                {i18n.t('licensePlanFeatures.linkText')}
              </Link>
            )}
            <div className="questions-container">
              <button
                type="button"
                onClick={() => dispatch(showPopUp('whatIncludesMyPlan'))}>
                <Icon iconName={faCircleQuestion} size="medium" />

                <p>{i18n.t('contractSubscription.content.whatIsIncluded')}</p>
              </button>

              <button
                type="button"
                onClick={() =>
                  window.open(
                    INSTALLATION_REQUIREMENTS_URLS[i18n.getLanguage()],
                    '_blank'
                  )
                }>
                <Icon iconName={faCircleQuestion} size="medium" />

                <p>
                  {i18n.t(
                    'contractSubscription.content.installationRequirements'
                  )}
                </p>
              </button>
            </div>
          </div>
        </div>

        <div className="confirm-subscription-container">
          <div className="confirm-subscription-prices">
            <div className="title">
              <Icon iconName={faReceipt} size="medium" />

              <h3>
                {i18n
                  .t('contractSubscription.content.confirmSubscription')
                  .toUpperCase()}
              </h3>
            </div>

            <div className="confirm-subscription-content">
              {!isLoading ? (
                <>
                  {isMonthlySubAllowed && (
                    <div className="confirm-subscription-frequency-wrapper">
                      <div className="confirm-subscription-hardcoded-discount">
                        {i18n.t(
                          'contractSubscription.content.annualSavingsPercentage',
                          {
                            percentage: anualSavingsPercentage,
                          }
                        )}
                      </div>
                      <div className="confirm-subscription-intervals">
                        <div className="confirm-subscription-interval">
                          <Button
                            text={
                              <>
                                {i18n.t(
                                  'contractSubscription.interval.monthly'
                                )}
                                <br />
                                {getCurrencyFormat(monthlyMarketingPrice, {
                                  minimumFractionDigits: 0,
                                })}
                              </>
                            }
                            color={
                              paymentFrequency === PAYMENT_FREQUENCIES.MONTHLY
                                ? 'redInfoBanner'
                                : 'white'
                            }
                            onClick={() =>
                              setPaymentFrequency(PAYMENT_FREQUENCIES.MONTHLY)
                            }
                            size="full"
                          />
                        </div>
                        <div className="confirm-subscription-interval">
                          <Button
                            text={
                              <>
                                {i18n.t('contractSubscription.interval.yearly')}
                                <br />
                                {getCurrencyFormat(yearlyMarketingPrice, {
                                  minimumFractionDigits: 0,
                                })}
                              </>
                            }
                            color={
                              paymentFrequency === PAYMENT_FREQUENCIES.YEARLY
                                ? 'redInfoBanner'
                                : 'white'
                            }
                            onClick={() =>
                              setPaymentFrequency(PAYMENT_FREQUENCIES.YEARLY)
                            }
                            size="full"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="confirm-subscription-total-price-detail">
                    <div className="confirm-subscription-title-description-price">
                      <div className="confirm-subscription-title-description">
                        <p>
                          <strong>
                            {i18n.t(
                              isMonthlySubAllowed
                                ? 'contractSubscription.payment.totalPrice'
                                : 'contractSubscription.payment.totalPriceByYear'
                            )}
                          </strong>
                        </p>

                        <p className="confirm-subscription-description">
                          {i18n.t('contractSubscription.payment.subtext', {
                            amount: getCurrencyFormat(marketingPrice),
                          })}
                        </p>
                      </div>

                      <p>
                        <strong>
                          {getCurrencyFormat(priceWithoutDiscount)}
                        </strong>
                      </p>
                    </div>

                    <div className="confirm-subscription-title-description-price">
                      <div className="confirm-subscription-title-description">
                        <p className="confirm-subscription-discount">
                          <strong>
                            {i18n.t(
                              isMonthlySubAllowed
                                ? 'contractSubscription.payment.discount'
                                : 'contractSubscription.payment.discountByYear',
                              {
                                amount: 50,
                              }
                            )}
                          </strong>
                        </p>

                        <p className="confirm-subscription-description">
                          {i18n.t('contractSubscription.payment.subtext', {
                            amount: getCurrencyFormat(-discountValue),
                          })}
                        </p>
                      </div>

                      <p className="confirm-subscription-discount">
                        <strong>{getCurrencyFormat(discountAmount)}</strong>
                      </p>
                    </div>

                    <div className="confirm-subscription-title-description-price">
                      <div className="confirm-subscription-title-description">
                        <p>
                          <strong>
                            {i18n.t('contractSubscription.payment.taxes', {
                              percentage: taxValue,
                            })}
                          </strong>
                        </p>
                      </div>

                      <p>
                        <strong>{getCurrencyFormat(taxAmount)}</strong>
                      </p>
                    </div>

                    <hr />

                    {paymentFrequency === PAYMENT_FREQUENCIES.MONTHLY && (
                      <div className="confirm-subscription-title-description-price">
                        <div className="confirm-subscription-title-description">
                          <p>
                            <strong>
                              {i18n.t(
                                'contractSubscription.payment.monthlyAmount'
                              )}
                            </strong>
                          </p>

                          <p className="confirm-subscription-description">
                            {i18n.t(
                              'contractSubscription.payment.twelveMonthlyPayments'
                            )}
                          </p>
                        </div>

                        <p>
                          <strong>{getCurrencyFormat(subtotal)}</strong>
                        </p>
                      </div>
                    )}

                    <div className="confirm-subscription-title-description-price">
                      <div className="confirm-subscription-title-description">
                        <p>
                          <strong>
                            {i18n.t(
                              isMonthlySubAllowed
                                ? 'contractSubscription.payment.price'
                                : 'contractSubscription.payment.priceByYear'
                            )}
                          </strong>
                        </p>

                        <p className="confirm-subscription-description">
                          {i18n.t('contractSubscription.payment.fromToDate', {
                            fromDate,
                            toDate,
                          })}
                        </p>
                      </div>

                      <p>
                        <strong>{getCurrencyFormat(totalAmount)}</strong>
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {isMonthlySubAllowed && (
                    <div className="skeleton-buttons-container">
                      <SkeletonButton active shape="round" size="large" block />
                      <SkeletonButton active shape="round" size="large" block />
                    </div>
                  )}

                  <Skeleton active paragraph={{ rows: 7 }} />
                </>
              )}
            </div>
          </div>

          <div className="checkboxes-button-container">
            <div className="checkboxes-text-container">
              <div className="checkbox-text">
                <input
                  type="checkbox"
                  onChange={() => setTermsAndConditions(!termsAndConditions)}
                />

                <p>
                  <strong>
                    {i18n.t('contractSubscription.payment.paymentLabel')}{' '}
                    <a
                      href={generalConditions}
                      target="_blank"
                      rel="noreferrer">
                      {i18n.t('contractSubscription.payment.paymentCheckbox')}
                    </a>
                  </strong>
                </p>
              </div>
            </div>

            <div className="buttons-container">
              <Button
                text={
                  isPaymentLinkLoading ? (
                    <SpinnerText text={i18n.t('common.loading')} />
                  ) : (
                    i18n.t('contractSubscription.payment.actions.signContract')
                  )
                }
                onClick={handlePayment}
                disabled={
                  !termsAndConditions || isLoading || isPaymentLinkLoading
                }
                size="full"
                color="red"
              />

              <button
                className="call-us-button"
                type="button"
                onClick={() => dispatch(showPopUp('callUs'))}>
                <p>{i18n.t('contractSubscription.content.needHelp')}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </SCContractSubscription>
  );
};

export default ContractSubscription;
