import { faLanguage } from '@fortawesome/pro-regular-svg-icons';
import React, { useContext } from 'react';
import useApp from '../../../hooks/app/useApp';
import { I18nContext } from '../../../i18n';
import imageFlags from '../../../utils/internationalizationModules/ImageFlags/ImageFlags';
import Icon from '../../atoms/Icon/Icon';
import Select from '../../atoms/Select/Select';
import SCLanguageSelector from './LanguageSelector.style';

const LanguageSelector = ({ displayLanguageIcon = true, color }) => {
  const { setLanguageCustom, lang } = useContext(I18nContext);
  const { getLanguageKey, getLanguagename, possibleLanguageNames } = useApp();

  return (
    <SCLanguageSelector color={color}>
      {displayLanguageIcon && (
        <div className="language-icon-container">
          <Icon
            iconName={faLanguage}
            size="semiLarge"
            color="var(--bluish-grey)"
          />
        </div>
      )}

      <img src={imageFlags[lang]} alt="region-logo" />

      <Select
        className="language-selector"
        onChange={(e) => setLanguageCustom(getLanguageKey(e))}
        values={possibleLanguageNames}
        selectedValue={getLanguagename(lang)}
      />
    </SCLanguageSelector>
  );
};

export default LanguageSelector;
