import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/Misc/Spinner';
import { auth } from '../../firebase';

const SingleSignOnPage = () => {
  const navigate = useNavigate();

  const sendSantanderAuthCode = async (code) => {
    try {
      const res = await axios.get(`/santander-login/${code}`);
      window.location.href = res.data.url;
    } catch (err) {
      console.log(err);
    }
  };

  const signInWithSantanderToken = (token) => {
    auth
      .signInWithCustomToken(token)
      .then((res) => {
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const signInSantander = async (code) => {
    try {
      const res = await axios.post('/santander-login', { code });
      signInWithSantanderToken(res.data.token);
    } catch (err) {
      navigate(`/sign-in?error=${err.response.data}`);
      console.log(err.response);
    }
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const santaderFirstCode = sessionStorage.getItem('santaderFirstCode');
    if (urlParams.has('code')) {
      if (santaderFirstCode) {
        sessionStorage.removeItem('santaderFirstCode');
        signInSantander(code);
      } else {
        sessionStorage.setItem('santaderFirstCode', true);
        sendSantanderAuthCode(code);
      }
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryToken = urlParams.get('token');

    if (queryToken) {
      signInWithSantanderToken(queryToken);
    }
  }, []);
  return (
    <>
      <Spinner loading />
    </>
  );
};

export default SingleSignOnPage;
