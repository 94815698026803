import styled from '@emotion/styled';

const SCYourInterfaceInformationCard = styled.div`
  .InterfaceInformation_MainContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .InterfaceInformation_Action {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    & p {
      margin-bottom: 0px;
      font-family: var(--font2);
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

export default SCYourInterfaceInformationCard;
