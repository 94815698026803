import CryptoJS from 'crypto-js';
import { firestore } from '../../firebase';
import {
  ADD_EMAIL_RESULTS,
  GET_MY_USER_PREFERENCES,
  GET_TEAM_MEMBERS,
} from './types';

export default (clientId, dispatch, getState) => {
  let debounceTimeout = null;
  const DEBOUNCE_DELAY = 600;

  firestore
    .collection('clients')
    .doc(clientId)
    .collection('team_members')
    .orderBy('email')
    .onSnapshot((snapshot) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      debounceTimeout = setTimeout(() => {
        const teamMembers = [];

        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            if (doc.exists) {
              const member = {
                ...doc.data(),
                email: doc?.data()?.email,
                hashedEmail: doc.id,
              };

              if (member.email) teamMembers.push(member);

              const hash = CryptoJS.SHA224(member.email).toString(
                CryptoJS.enc.Hex
              );

              firestore
                .collection('breaches')
                .doc(hash)
                .onSnapshot((snap) => {
                  const breachDoc = snap.data();

                  if (breachDoc?.breaches) {
                    const emailResults = { [member.email]: snap?.data() || {} };

                    dispatch({
                      type: ADD_EMAIL_RESULTS,
                      payload: { ...emailResults },
                    });
                  }
                });
            }
          });
        }

        const user = getState()?.user;

        const myTeamMember = teamMembers?.find(
          (teamMember) => teamMember.hashedEmail === user.hashedEmail
        );

        if (myTeamMember) {
          dispatch({
            type: GET_MY_USER_PREFERENCES,
            payload: {
              language: myTeamMember.language,
            },
          });
        }

        dispatch({
          type: GET_TEAM_MEMBERS,
          payload: [...teamMembers],
        });
      }, DEBOUNCE_DELAY);
    });
};
