import {
  faPen,
  faPowerOff,
  faShieldKeyhole,
} from '@fortawesome/pro-regular-svg-icons';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ReactGA from 'react-ga';
import useFetchApiAddress from '../../../hooks/useFetchApiAddress/useFetchApiAddress';
import { signOut } from '../../../redux/actions/auth.actions';
import Button from '../../atoms/Button/Button';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCMySessionCard from './MySessionCard.style';
import { ErrorKey } from '../../../track';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import ToggleButton from '../../atoms/ToggleButton/ToggleButton';
import TwoFactorAuthenticationSetup from '../TwoFactorAuthenticationSetup/TwoFactorAuthenticationSetup';
import { useTranslation } from '../../../i18n';

const platform = require('platform');

const MySessionCard = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const firebase = useSelector((state) => state.firebase);
  const { currentUser } = firebase.auth();

  const [setup2faConfirmation, setSetup2faConfirmation] = useState();
  const [setup2fa, setSetup2fa] = useState();
  const [recaptchaReady, setRecaptchaReady] = useState();
  const [isFetching, setIsFetching] = useState();
  const [shouldVerifyCode, setShouldVerifyCode] = useState();
  const [recaptcha, setRecaptcha] = useState();

  const sendCodeButton = React.createRef();
  const { ipAddress, loading } = useFetchApiAddress();

  useEffect(() => {
    if (recaptcha) {
      sendRecaptchaToken();
    }
  }, [recaptcha]);

  const sendRecaptchaToken = async () => {
    firebase
      .auth()
      .currentUser.multiFactor.getSession()
      .then(
        async (multiFactorSession) => {
          console.log('OK: ', multiFactorSession);
          const phoneInfoOptions = {
            phoneNumber: user.phoneNumber,
            session: multiFactorSession,
          };
          const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          // localStorage.setItem('bedeid', res.data);
          setIsFetching(false);
          setShouldVerifyCode(true);
          try {
            const verificationId = await phoneAuthProvider.verifyPhoneNumber(
              phoneInfoOptions,
              window.recaptchaVerifier
            );
            setSetup2faConfirmation(verificationId);
          } catch {
            await ErrorKey('authentication.errorWithAuthentication');
            setIsFetching(false);
            setSetup2fa(false);
            setShouldVerifyCode(false);
          }
        },
        (err) => {
          console.error('Error: ', err);
          if (
            err &&
            err.response &&
            err.response.data === 'TOO_MANY_ATTEMPTS_TRY_LATER'
          ) {
            ErrorKey('authentication.tooManyAttemps');
          }
          window.recaptchaVerifier.reset();
          setIsFetching(false);
        }
      );
  };

  useEffect(() => {
    addRecaptcha();
    if (window.recaptchaVerifier) {
      try {
        window.recaptchaVerifier
          .render()
          .then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
            setRecaptchaReady(true);
          })
          .catch((e) => console.log(1, e));
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const addRecaptcha = () => {
    try {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'setupSendCode',
        {
          size: 'invisible',
          callback(recaptchaToken) {
            try {
              setRecaptcha(recaptchaToken);
              // a REST call to your backend
            } catch (e) {
              // window.recaptchaVerifier.reset();
              setIsFetching(false);
              // window.recaptchaVerifier.clear();
            }
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const disable2fa = () => {
    axios
      .post(`/2fa/remove-2fa`, {})
      .then(async (res) => {
        // SuccessKey('profile.tfa.disabled').then(() => {
        //   dispatch(signOut());
        // });
        dispatch(
          showPopUp('notification', {
            notificationType: 'success',
            title: '',
            text: i18n.t('profile.tfa.disabled'),
            onButtonClick: () => dispatch(signOut()),
          })
        );
      })
      .catch((e) => {
        console.log('Error');
      });
  };

  const verifyCode = async (code) => {
    let cred;

    try {
      cred = firebase.auth.PhoneAuthProvider.credential(
        setup2faConfirmation,
        code
      );
    } catch {
      await ErrorKey('authentication.errorWithAuthentication');
      setIsFetching(false);
      setSetup2fa(false);
      setShouldVerifyCode(false);
    }

    const multiFactorAssertion =
      firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    // Complete enrollment.
    const enroll = firebase
      .auth()
      .currentUser.multiFactor.enroll(multiFactorAssertion, 'mfaDisplayName');

    enroll.then(
      (res) => {
        setIsFetching(false);
        // SuccessKey('profile.tfa.enabled');
        dispatch(
          showPopUp('notification', {
            notificationType: 'success',
            title: '',
            text: i18n.t('profile.tfa.enabled'),
          })
        );
        setSetup2fa(false);
        setShouldVerifyCode(false);
      },
      (err) => {
        if (err && err.code && err.code === 'auth/invalid-verification-code') {
          ErrorKey('authentication.auth/invalid-verification-code');
        } else {
          ErrorKey('profile.tfa.error');
        }
        setIsFetching(false);
        setSetup2fa(false);
        setShouldVerifyCode(false);
      }
    );
  };

  const launch2faSetup = () => {
    if (user && user.phoneNumber) {
      ReactGA.event({
        category: 'Risk Portal',
        action: 'Enable 2FA',
        label: 'Enable 2FA',
      });
      setSetup2fa(true);
    } else {
      ErrorKey('profile.tfa.addPhoneNumber');
    }
  };

  const clickSendCodeButton = () => {
    if (sendCodeButton && sendCodeButton.current) {
      setIsFetching(true);
      sendCodeButton.current.click();
    }
  };

  const clickToClose = () => {
    setSetup2fa(false);
    // setShouldVerifyCode(false);
  };

  const handleToggleButton = () => {
    if (
      currentUser.multiFactor.enrolledFactors &&
      currentUser.multiFactor.enrolledFactors.length > 0
    ) {
      disable2fa();
    } else {
      launch2faSetup();
    }
  };

  return (
    <SCMySessionCard>
      <SectionCard
        headerIcon={faShieldKeyhole}
        headerTitle={i18n.t('profile.mySession.title')}>
        <div className="my-2fa">
          <div className="two-factor-autentication-container">
            <div className="two-factor-autentication-desc">
              <p className="device-information">{i18n.t('profile.tfa.desc')}</p>
              <p className="location-information">
                {i18n.t('profile.tfa.text')}
              </p>
            </div>

            <ToggleButton
              checked={
                currentUser.multiFactor.enrolledFactors &&
                currentUser.multiFactor.enrolledFactors.length > 0
              }
              text={
                currentUser.multiFactor.enrolledFactors &&
                currentUser.multiFactor.enrolledFactors.length > 0
                  ? i18n.t('notifications.settings.on')
                  : i18n.t('notifications.settings.off')
              }
              onClick={() => handleToggleButton()}
            />
          </div>

          {setup2fa && (
            <TwoFactorAuthenticationSetup
              phoneNumber={user.phoneNumber}
              recaptchaReady={recaptchaReady}
              clickSendCodeButton={clickSendCodeButton}
              isFetching={isFetching}
              shouldVerifyCode={shouldVerifyCode}
              verifyCode={verifyCode}
              clickToClose={clickToClose}
              firebase={firebase}
            />
          )}

          <button
            type="button"
            id="setupSendCode"
            aria-label="Send2fa"
            ref={sendCodeButton}
            style={{ display: 'none' }}
          />
        </div>
        <div className="my-session-container">
          <p className="device-information">
            {i18n.t('profile.mySession.changePassword')}
          </p>
          <Button
            text={i18n.t('profile.mySession.change')}
            icon={faPen}
            color="white"
            size="mid"
            onClick={() => dispatch(showPopUp('changePassword'))}
          />
        </div>
        <div className="my-session-container">
          <div>
            <p className="device-information">
              {`${platform.name} ${platform.version} ` +
                ` - ${platform.os.toString()}`}
            </p>
            <p className="location-information">
              IP:{' '}
              {loading && (
                <SpinnerText text={i18n.t('profile.mySession.loading')} />
              )}
              {ipAddress}
            </p>
          </div>
          <Button
            text={i18n.t('profile.mySession.closeSession')}
            icon={faPowerOff}
            color="white"
            size="mid"
            onClick={() => dispatch(signOut())}
          />
        </div>
      </SectionCard>
    </SCMySessionCard>
  );
};
export default MySessionCard;
