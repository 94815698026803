import styled from '@emotion/styled/macro';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import firebase, { auth } from '../../../firebase';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import {
  passwordPwned,
  validatePasswordRegex,
} from '../../../utils/validators/password';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';

const SCChangePassword = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  p {
    font-weight: 500;
  }

  h1 {
    font-family: var(--font1);
  }

  p {
    font-family: var(--font2);
  }

  > button:first-of-type {
    margin-bottom: 10px;
  }
`;

const ChangePassword = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [error, setError] = useState('');

  const errorTexts = {
    'auth/wrong-password': i18n.t('profile.mySession.wrongPassword'),
    differentPasswords: i18n.t('profile.mySession.differentPasswords'),
    wrongPasswordFormat: i18n.t('errors.wrongPasswordFormat'),
    pwnedPassword: i18n.t('errors.pwnedPassword'),
    default: i18n.t('errors.thereWasAnError'),
  };

  const reauthenticate = () => {
    const user = auth.currentUser;
    // El error mostrado por eslint no se corresponde con nuestra configuración
    // eslint-disable-next-line import/no-named-as-default-member
    const cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );

    return user.reauthenticateWithCredential(cred);
  };

  const changeCurrentPassword = async () => {
    const user = auth.currentUser;
    try {
      setLoading(true);
      // reauthenticating
      await reauthenticate();
      // updating password
      await user.updatePassword(newPassword);

      setFinished(true);
    } catch (err) {
      console.log(err);
      setError(err.code);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (newPassword !== newPassword2) {
      setError('differentPasswords');
      return;
    }

    if (!validatePasswordRegex(newPassword)) {
      setError('wrongPasswordFormat');
      return;
    }

    if (await passwordPwned(newPassword)) {
      setError('pwnedPassword');
      return;
    }

    changeCurrentPassword();
  };

  return (
    <ModalLayout>
      <SCChangePassword>
        <h1>{i18n.t('profile.mySession.changePassword')}</h1>
        <form onSubmit={handlePasswordChange}>
          {!finished ? (
            <>
              <div className="form-container">
                <Input
                  inputType="password"
                  size="large"
                  inputPlaceholder={i18n.t('profile.mySession.currentPassword')}
                  onChangeValue={setCurrentPassword}
                />
                <Input
                  inputType="password"
                  size="large"
                  inputPlaceholder={i18n.t('profile.mySession.newPassword')}
                  onChangeValue={setNewPassword}
                />
                <Input
                  inputType="password"
                  size="large"
                  inputPlaceholder={i18n.t(
                    'profile.mySession.newPasswordAgain'
                  )}
                  onChangeValue={setNewPassword2}
                />
                {error && (
                  <InfoBanner
                    type="error"
                    text={[errorTexts[error] || errorTexts.default]}
                    minified
                  />
                )}
              </div>
              <div className="modal-card-buttons">
                <Button
                  type="submit"
                  size="full"
                  color="bluishGrey"
                  text={
                    loading ? (
                      <SpinnerText text={i18n.t('misc.saving')} />
                    ) : (
                      i18n.t('profile.mySession.change')
                    )
                  }
                  disabled={
                    loading || !currentPassword || !newPassword || !newPassword2
                  }
                />
                <Button
                  size="full"
                  color="white"
                  text={i18n.t('common.cancel')}
                  onClick={() => dispatch(showPopUp(null))}
                />
              </div>
            </>
          ) : (
            <>
              <InfoBanner
                type="check"
                text={[i18n.t('profile.mySession.passwordChanged')]}
              />
              <Button
                size="full"
                color="white"
                text={i18n.t('common.close')}
                onClick={() => dispatch(showPopUp(null))}
              />
            </>
          )}
        </form>
      </SCChangePassword>
    </ModalLayout>
  );
};

export default ChangePassword;
