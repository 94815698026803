import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import {
  Route,
  Routes,
  BrowserRouter,
  Outlet,
  Navigate,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { I18nContextProvider } from './i18n';
import history from './history';
import configureStore from './redux/store.ts';

// root components (HOC's)
import Startup from './Startup';
import SessionTimeout from './components/atoms/SessionTimeout/SessionTimeout';

// layout
import OnboardLayout from './components/layouts/OnboardLayout/OnboardLayout';
import PartnerLayout from './components/layouts/PartnerLayout/PartnerLayout';

// guards
import AuthRouteGuard from './guards/authGuard/auth.guard';
import RootRouteAuthGuard from './guards/rootRouteAuthGuard/rootRouteAuthGuard';
import PhisingRouteGuard from './guards/sectionGuards/phisingGuard/phising.guard';
import SupplierRouteGuard from './guards/sectionGuards/supplierGuard/supplier.guard';
import WebsiteRouteGuard from './guards/sectionGuards/websiteGuard/website.guard';
import PossibleImpersonationRouteGuard from './guards/sectionGuards/possibleImpersonationGuard/possibleImpersonation.guard';
import ManagedThreatsRouteGuard from './guards/sectionGuards/managedThreatsGuard/managedThreats.guard';
import MailboxSecurityRouteGuard from './guards/sectionGuards/mailboxSecurityGuard/mailboxSecurity.guard';
import EmailSecurityRouteGuard from './guards/sectionGuards/emailSecurityGuard/emailSecurity.guard';
import DeviceSecurityRouteGuard from './guards/sectionGuards/deviceSecurityGuard/deviceSecurity.guard';
import BreachesRouteGuard from './guards/sectionGuards/breachesGuard/breaches.guard';
import ScrollToTop from './utils/ScrollToTop/ScrollToTop';
import PopUp from './components/organisms/PopUp/Wrapper';
import AuthAccessRouteGuard from './guards/auth/auth.guard';
import CallMeWidget from './components/atoms/CallMeWidget/CallMeWidget';
import SignOutPage from './pages/sign-out/SignOut';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import EmailVerificationWrapper from './pages/EmailVerification/EmailVerification';
import PartnerSignIn from './pages/sign-in/SignIn';
import NotFound from './pages/NotFound/NotFound';
import Dashboard from './components/Dashboard/Dashboard';
import PhisingSimilator from './components/Dashboard/Sections/PhishingSimulator';
import SingleSignOnPage from './pages/singleSignOn/singleSignOn';
import AccountCreatedWrapper from './pages/AccountCreated/AccountCreated';
import ControlPanelSection from './pages/ControlPanelSection/ControlPanelSection';
import ActionSection from './pages/actionSection/actionSection';
import LandingSection from './pages/landingSection/landingSection';
import AcionView from './pages/action/actionView';
import DeviceSecuritySection from './pages/DeviceSecuritySection/DeviceSecuritySection';
import ManagedThreatsSection from './pages/ManagedThreatsSection/ManagedThreatsSection';
import MailboxSecuritySection from './pages/MailboxSecuritySection/MailboxSecuritySection';
import EmailSecuritySection from './pages/emailSecuritySection/emailSecuritySection';
import ConfigureSpf from './pages/configureSpf/configureSpf';
import ConfigureDmarc from './pages/configureDmarc/configureDmarc';
import BreachesSection from './pages/BreachesSection/BreachesSection';
import WebsiteSecuritySection from './pages/WebsiteSecuritySection/WebsiteSecuritySection';
import PossibleImpersonationSection from './pages/PossibleImpersonations/possibleImpersonationsSection';
import ImpersonationView from './pages/impersonation/impersonation';
import SupplierSecuritySection from './pages/SupplierSecuritySection/SupplierSecuritySection';
import NewPhisingSimulator from './pages/NewPhisingSimulator/NewPhisingSimulator';
import ProfileNotifications from './components/organisms/ProfileNotifications/ProfileNotifications';
import ProfileInfo from './components/organisms/ProfileInfo/ProfileInfo';
import ProfileEmployees from './components/organisms/ProfileEmployees/ProfileEmployees';
import ProfileEmail from './components/organisms/ProfileEmail/ProfileEmail';
import QuarantineEmailDetail from './pages/QuarantineEmailDetail/QuarantineEmailDetail';
import ProfileBilling from './components/organisms/ProfileBilling/ProfileBilling';
import SetupAccount from './pages/sign-up/signUp';
import SupportGerenciaView from './pages/SupportGerencia/supportGerencia';
import TwoFactorAuthentication from './pages/TwoFactorAuthentication/TwoFactorAuthentication';
import Whitelisting from './pages/Whitelisting/Whitelisting';
import InitialTutorialPage from './pages/InitialTutorial/InitialTutorialPage';
import Spinner from './components/Misc/Spinner';
import QualificationFormPage from './pages/QualificationFormPage/QualificationFormPage';
import LicensePlanFeaturesPage from './pages/LicensePlanFeatures/LicensePlanFeaturesPage';
import LicenseeGuard from './guards/licensee/licensee.guard';
import LICENSEES from './utils/constants/licensees';
import ContractSubscriptionGuard from './guards/sectionGuards/ContractSubscriptionGuard/contractSubscription.guard';
import ContractSubscription from './pages/ContractSubscription/ContractSubscription';
import LeadsPage from './LandingPages/LeadsPage/LeadsPage';
import PartnerClients from './pages/PartnerClients/PartnerClients';
import PartnerGuard from './guards/partner/partner.guard';
import PartnerClientControlPanel from './pages/PartnerClientControlPanel/PartnerClientControlPanel';
import PartnerDashboard from './pages/PartnerDashboard/PartnerDashboard';
import RedirectPage from './components/atoms/RedirectPage/RedirectPage';
import { PUBLIC_HOME } from './utils/constants/urls';
import SettingsGuard from './guards/sectionGuards/settingsGuard/settingsGuard.guard';
import AdminLayout from './components/layouts/AdminLayout/AdminLayout';
import LeadsAdminPage from './pages/admin/LeadsAdminPage/LeadsAdminPage';
import AdminGuard from './guards/admin/admin.guard';

ReactGA.initialize();
ReactGA.ga('create', process.env.REACT_APP_GA_API_KEY);
// anonymize IP
ReactGA.ga('set', 'anonymizeIp', true);

// hotjar
hotjar.initialize(process.env.REACT_APP_HOTJAR_API_KEY, 6);

const makeMainRoutes = () => {
  const initialState = {};
  const store = configureStore(initialState, history);

  return (
    <Provider store={store}>
      <I18nContextProvider>
        <BrowserRouter history={history}>
          <PopUp />
          <ScrollToTop />
          <SessionTimeout />
          <CallMeWidget />
          <Startup>
            <Suspense
              fallback={
                <div className="Partners HOC">
                  <Spinner loading />
                </div>
              }>
              <Routes>
                <Route exact path="/" element={<RootRouteAuthGuard />} />
                <Route
                  sensitive
                  path="/sso"
                  element={
                    <OnboardLayout centerChildren>
                      <SingleSignOnPage />
                    </OnboardLayout>
                  }
                />
                <Route
                  sensitive
                  path="/sign-in"
                  element={
                    <OnboardLayout centerChildren>
                      <PartnerSignIn />
                    </OnboardLayout>
                  }
                />
                <Route sensitive path="/sign-out" element={<SignOutPage />} />
                <Route
                  sensitive
                  path="/forgot-password"
                  element={
                    <OnboardLayout centerChildren>
                      <ForgotPassword />
                    </OnboardLayout>
                  }
                />
                <Route path="/email-verification/oobCode">
                  <Route
                    path=":oobCode"
                    element={
                      <OnboardLayout centerChildren>
                        <EmailVerificationWrapper />
                      </OnboardLayout>
                    }
                  />
                </Route>
                <Route
                  sensitive
                  path="/email-verification"
                  element={
                    <OnboardLayout centerChildren>
                      <EmailVerificationWrapper />
                    </OnboardLayout>
                  }
                />
                <Route
                  sensitive
                  path="/account-created"
                  element={
                    <OnboardLayout centerChildren>
                      <AccountCreatedWrapper />
                    </OnboardLayout>
                  }
                />
                <Route
                  sensitive
                  path="/admin/leads"
                  element={
                    <AuthRouteGuard isAccessingAdmin>
                      <AdminGuard>
                        <AdminLayout />
                      </AdminGuard>
                    </AuthRouteGuard>
                  }>
                  <Route index element={<LeadsAdminPage />} />
                </Route>
                <Route
                  sensitive
                  path="/partner"
                  element={
                    <AuthRouteGuard isAccessingPartner>
                      <PartnerGuard>
                        <PartnerLayout />
                      </PartnerGuard>
                    </AuthRouteGuard>
                  }>
                  <Route index element={<PartnerDashboard />} />
                  <Route path="clients">
                    <Route index element={<PartnerClients />} />
                    <Route
                      path="control-panel/:clientId"
                      element={<PartnerClientControlPanel />}
                    />
                  </Route>
                </Route>
                <Route
                  sensitive
                  exact
                  path="/client"
                  element={
                    <AuthRouteGuard>
                      <Dashboard />
                    </AuthRouteGuard>
                  }>
                  <Route path="" element={<LandingSection />} />
                  <Route
                    path="control-panel"
                    element={<ControlPanelSection />}
                  />
                  <Route
                    path="contract-subscription"
                    element={
                      <ContractSubscriptionGuard>
                        <ContractSubscription />
                      </ContractSubscriptionGuard>
                    }
                  />
                  <Route path="actions" element={<ActionSection />} />
                  <Route path="action" element={<AcionView />} />
                  <Route
                    path="device-security/:id?"
                    element={
                      <DeviceSecurityRouteGuard>
                        <DeviceSecuritySection />
                      </DeviceSecurityRouteGuard>
                    }
                  />
                  <Route
                    path="managed-threats"
                    element={
                      <ManagedThreatsRouteGuard>
                        <ManagedThreatsSection />
                      </ManagedThreatsRouteGuard>
                    }
                  />
                  <Route
                    path="mailbox-security"
                    element={
                      <MailboxSecurityRouteGuard>
                        <Outlet />
                      </MailboxSecurityRouteGuard>
                    }>
                    <Route index element={<MailboxSecuritySection />} />
                    <Route path=":id" element={<QuarantineEmailDetail />} />
                  </Route>

                  <Route
                    path="configure-email-security"
                    element={
                      <EmailSecurityRouteGuard>
                        <EmailSecuritySection />
                      </EmailSecurityRouteGuard>
                    }
                  />
                  <Route
                    path="configure-email-spf"
                    element={
                      <EmailSecurityRouteGuard>
                        <ConfigureSpf />
                      </EmailSecurityRouteGuard>
                    }
                  />
                  <Route
                    path="configure-email-dmarc"
                    element={
                      <EmailSecurityRouteGuard>
                        <ConfigureDmarc />
                      </EmailSecurityRouteGuard>
                    }
                  />
                  <Route
                    path="data-alert"
                    element={
                      <BreachesRouteGuard>
                        <BreachesSection />
                      </BreachesRouteGuard>
                    }
                  />
                  <Route
                    path="websitesecurity"
                    element={
                      <WebsiteRouteGuard>
                        <WebsiteSecuritySection />
                      </WebsiteRouteGuard>
                    }
                  />

                  <Route
                    path="domainimpersonation"
                    element={
                      <PossibleImpersonationRouteGuard>
                        <PossibleImpersonationSection />
                      </PossibleImpersonationRouteGuard>
                    }
                  />
                  <Route
                    path="impersonation"
                    element={
                      <PossibleImpersonationRouteGuard>
                        <ImpersonationView />
                      </PossibleImpersonationRouteGuard>
                    }
                  />
                  <Route
                    path="supplier-security/:id?"
                    element={
                      <SupplierRouteGuard>
                        <SupplierSecuritySection />
                      </SupplierRouteGuard>
                    }
                  />
                  <Route
                    path="phishingsimulator/:id?"
                    element={
                      <PhisingRouteGuard>
                        <PhisingSimilator />
                      </PhisingRouteGuard>
                    }
                  />
                  <Route
                    path="new-phising-simulator"
                    element={
                      <PhisingRouteGuard>
                        <NewPhisingSimulator />
                      </PhisingRouteGuard>
                    }
                  />
                  <Route
                    path="notifications"
                    element={<ProfileNotifications />}
                  />
                  <Route
                    path="settings"
                    element={
                      <SettingsGuard>
                        <ProfileInfo />
                      </SettingsGuard>
                    }
                  />
                  <Route
                    path="settings/my-account"
                    index
                    element={
                      <SettingsGuard>
                        <ProfileInfo />
                      </SettingsGuard>
                    }
                  />
                  <Route
                    path="settings/employees"
                    element={<ProfileEmployees />}
                  />
                  <Route
                    path="settings/email-preferences"
                    element={
                      <SettingsGuard>
                        <ProfileEmail />
                      </SettingsGuard>
                    }
                  />
                  <Route path="settings/billing" element={<ProfileBilling />} />
                </Route>
                <Route
                  sensitive
                  path="/setup-account/:sessionId?/:code?"
                  element={
                    <OnboardLayout centerChildren>
                      <SetupAccount />
                    </OnboardLayout>
                  }
                />
                <Route
                  sensitive
                  path="/gerencia"
                  element={<SupportGerenciaView />}
                />
                {/* <Route
                  path="/guia-clientes-zurich-santander"
                  element={
                    <ZurichGuidePageGuard>
                      <ZurichGuidePage />
                    </ZurichGuidePageGuard>
                  }
                /> */}
                <Route
                  sensitive
                  path="/activacion-prueba-gratuita-cyber-guardian-cliente-santander"
                  element={
                    <AuthAccessRouteGuard>
                      <QualificationFormPage />
                    </AuthAccessRouteGuard>
                  }
                />
                <Route
                  sensitive
                  path="/two-factor-authentication"
                  element={
                    <OnboardLayout>
                      <TwoFactorAuthentication />
                    </OnboardLayout>
                  }
                />
                <Route
                  sensitive
                  exact
                  path="/whitelisting"
                  element={
                    <AuthRouteGuard>
                      <Whitelisting />
                    </AuthRouteGuard>
                  }
                />
                <Route
                  sensitive
                  exact
                  path="/home"
                  element={<RedirectPage url={PUBLIC_HOME} />}
                />
                <Route
                  sensitive
                  path="/initial-tutorial"
                  element={
                    <AuthRouteGuard>
                      <InitialTutorialPage />
                    </AuthRouteGuard>
                  }
                />
                <Route
                  sensitive
                  exact
                  path="/des/tu-informe-de-seguridad-online"
                  element={<Navigate to="/tu-informe-de-seguridad-online" />}
                />
                <Route
                  sensitive
                  exact
                  path="/tu-informe-de-seguridad-online"
                  element={<LeadsPage />}
                />
                <Route
                  sensitive
                  exact
                  path="/license-plan-features"
                  element={
                    <AuthRouteGuard>
                      <LicenseeGuard licensee={LICENSEES.santander}>
                        <OnboardLayout centerChildren>
                          <LicensePlanFeaturesPage />
                        </OnboardLayout>
                      </LicenseeGuard>
                    </AuthRouteGuard>
                  }
                />
                <Route
                  path="*"
                  element={
                    <OnboardLayout centerChildren>
                      <NotFound />
                    </OnboardLayout>
                  }
                />
              </Routes>
            </Suspense>
          </Startup>
        </BrowserRouter>
      </I18nContextProvider>
    </Provider>
  );
};

export default makeMainRoutes;
