import { useState, useEffect } from 'react';
import promoCodeService from '../../services/promoCode/promoCode.service';

const usePromoCode = (setupCode) => {
  const [promoCode, setPromoCode] = useState();

  useEffect(async () => {
    const promoCodeInfo = await promoCodeService.getPromoCode(setupCode);

    setPromoCode(promoCodeInfo);
  }, []);

  return {
    promoCode,
  };
};

export default usePromoCode;
