import React, { useEffect } from 'react';

const RedirectPage = ({ url }) => {
  useEffect(() => {
    window.location.replace(url);
  }, []);

  return <></>;
};

export default RedirectPage;
