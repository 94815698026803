import React from 'react';
import SCTextImage from './TextImage.style';

const TextImage = ({
  title = '',
  subtitle = '',
  text,
  img,
  imgWidth,
  imgHeight,
  imgPadding,
  backgroundPosition,
  imgSided,
}) => {
  return (
    <SCTextImage
      imgWidth={imgWidth}
      imgHeight={imgHeight}
      imgSided={imgSided}
      imgPadding={imgPadding}
      backgroundPosition={backgroundPosition}
      img={img}>
      <div className="texts-container">
        {title && (
          <h2>
            <strong>{title}</strong>
          </h2>
        )}
        {subtitle && (
          <div>
            <h3>{subtitle}</h3>
          </div>
        )}
        <p>{text}</p>
      </div>
      <div className="img-container" />
    </SCTextImage>
  );
};

export default TextImage;
