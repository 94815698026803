import styled from '@emotion/styled/macro';
import { css } from '@emotion/core';

const mobileOpenedStyles = () => css`
  transform: translateX(-420px);
  background-color: var(--white);

  @media (max-width: 420px) {
    transform: translateX(-100%);
  }
`;

const SCMobileMenu = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  transition: all 0.5s ease 0s;
  height: 100%;
  width: 420px;
  right: -420px;
  z-index: 2000000001;

  @media (max-width: 420px) {
    width: 100%;
    right: -100%;
  }

  ${({ mobileOpened }) => (mobileOpened ? mobileOpenedStyles : '')};

  .ApplicationMenuContent {
    padding-top: 20px;
    height: auto;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    color: var(--grey);
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    box-shadow: 2px 2px 20px 0px rgb(121 136 151 / 8%);

    .top-app-menu-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo-settings-container {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 70px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    .application-menu-items {
      display: flex;
      gap: 5px;
      width: 100%;
      padding: 0 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 1320px) {
    display: none;
  }
`;

export default SCMobileMenu;
