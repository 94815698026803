import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import {
  faCircleExclamation,
  faCircleInfo,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import SCInfoBanner from './InfoBanner.style';

const InfoBanner = ({
  type = 'defaultStyle',
  title,
  text,
  onClickButton,
  buttonText,
  buttonIcon,
  buttonDisabled,
  minified,
  width,
  customIcon,
}) => {
  const icons = {
    defaultStyle: faCircleInfo,
    info: faCircleInfo,
    check: faCircleCheck,
    warn: faCircleExclamation,
    error: faTriangleExclamation,
  };

  const colors = {
    defaultStyle: 'defaultInfoBanner',
    info: 'greenInfoBanner',
    check: 'greenInfoBanner',
    warn: 'orangeInfoBanner',
    error: 'redInfoBanner',
  };

  return (
    <SCInfoBanner type={type} hasButton={!!onClickButton} width={width}>
      <div className="icon-content-container">
        {!minified &&
          (customIcon ? (
            <div className="custom-icon-container">{customIcon}</div>
          ) : (
            <Icon size="navIcon" iconName={icons[type]} />
          ))}

        <div className="content-container">
          {title && <h3>{title}</h3>}
          {text.map((content, index) => {
            if (typeof content === 'string') {
              return <p dangerouslySetInnerHTML={{ __html: content }} />;
            }
            if (React.isValidElement(content)) {
              return <div>{content}</div>;
            }
            return null;
          })}
        </div>
      </div>

      {onClickButton && (
        <Button
          onClick={onClickButton}
          icon={buttonIcon}
          text={buttonText}
          size="mid"
          color={colors[type]}
          disabled={buttonDisabled}
        />
      )}
    </SCInfoBanner>
  );
};

export default InfoBanner;
