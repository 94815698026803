/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from 'react';
import {
  faEarthAmericas,
  faEnvelope,
  faKey,
} from '@fortawesome/pro-regular-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import Button from '../../components/atoms/Button/Button';
import firebase, { auth } from '../../firebase';
import { ErrorKey, ErrorKeyCustomized, TrackButton } from '../../track';
import SCSignIn from './SignIn.style';
import Input from '../../components/atoms/Input/Input';
import { googleIcon } from '../../components/Misc/Icons';
import {
  signInWithEmailAndPassword,
  signUpWithGoogle,
} from '../../redux/actions/auth.actions';
import { useTranslation } from '../../i18n';
import RegionSelector from '../../components/atoms/RegionSelector/RegionSelector';
import { showPopUp } from '../../redux/actions/popUp.actions';
import InfoBanner from '../../components/molecules/InfoBanner/InfoBanner';
import useInternationalization from '../../hooks/Internationalization/useInternationalization';
import {
  getSantanderEnvironmentRegionText,
  isNonCountryEnvironment,
  isSantanderEnvironment,
} from '../../utils/locale';
import Icon from '../../components/atoms/Icon/Icon';

const SignIn = () => {
  const i18n = useTranslation();
  const { redirect } = useParams();
  const [email, setEmail] = useState(undefined);
  const [pass, setPass] = useState(undefined);
  const [show2FACaptcha, setShow2FACaptcha] = useState(false);
  const [showSessionTimeoutBanner, setShowSessionTimeoutBanner] =
    useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentLocale } = useInternationalization();

  const saveNewClient = async (account, idToken, code, personalCode) => {
    await axios
      .post('/save-new-client', {
        account,
        language: i18n.getLanguage(),
        idToken,
        code,
        personalCode,
      })
      .then((res) => {
        if (auth.currentUser) {
          navigate(redirect ?? '/client');
        } else {
          ErrorKeyCustomized({
            i18nKey: 'errors.thereWasAnError',
            label: 'saveNewClient3',
          });
        }
      })
      .catch((err) => {
        if (err.response?.status === 409) {
          navigate(redirect ?? '/client');
        } else {
          ErrorKeyCustomized({
            i18nKey: 'errors.thereWasAnError',
            label: 'saveNewClient3',
          });
        }
      });
  };

  const handleSignInWithGoogle = async () => {
    const googlePayload = await signUpWithGoogle();
    if (!googlePayload) {
      ErrorKeyCustomized({
        i18nKey: 'errors.thereWasAnError',
        label: 'saveNewClient3',
      });
      return false;
    }

    // not logged -in (create account)
    if (!auth || (auth && !auth.currentUser)) {
      await saveNewClient(googlePayload, undefined, undefined, undefined);
    } else {
      auth.currentUser.getIdToken(true).then(async (idToken) => {
        await saveNewClient(googlePayload, idToken, undefined, undefined);
      });
    }
  };

  // const sendSantanderPreAuth = async () => {
  //   try {
  //     const res = await axios.get('/santander-login');
  //     window.location.href = res.data.url;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // Submit email to begin Passwordless transaction
  const sendMagicLink = () => {
    // Event track
    signInWithEmailAndPassword(email, pass, redirect);

    // Login
    auth
      .signInWithEmailAndPassword(email, pass)
      .then(() => {
        // Auto redirect
      })
      .catch(async (e) => {
        if (
          e.code === 'auth/wrong-password' ||
          e.code === 'auth/user-not-found'
        ) {
          ErrorKey('authentication.wrongPasswordOrAccount');
        } else if (e.code === 'auth/too-many-requests') {
          ErrorKey('authentication.tooManyAttemps');
        } else if (e.code === 'auth/multi-factor-auth-required') {
          // In case 2fa is enabled
          setShow2FACaptcha(true);

          const phoneInfoOptions = {
            multiFactorHint: e.resolver.hints[0],
            session: e.resolver.session,
          };
          const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            'setupSendCode'
          );

          // Ask if is a human and send SMS
          const verificationId = await phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            recaptchaVerifier
          );

          // Modal where sms code is entered
          const { value: code } = await Swal.fire({
            title: 'Introduce el código',
            text: 'Te hemos enviado un SMS con tu código de autenticación de doble factor (2FA)',
            inputLabel: 'Código',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off',
            },
            showCancelButton: false,
            confirmButtonText: 'Log in',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
          });

          const cred = firebase.auth.PhoneAuthProvider.credential(
            verificationId,
            code
          );
          const multiFactorAssertion =
            firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

          // Continues log in
          try {
            await e.resolver.resolveSignIn(multiFactorAssertion);
          } catch (error) {
            if (error.code === 'auth/invalid-verification-code') {
              await ErrorKey('authentication.auth/invalid-verification-code');
            } else {
              await ErrorKey('authentication.errorWithAuthentication');
            }
            window.location.reload();
          }
        } else {
          ErrorKey('authentication.errorWithAuthentication');
        }
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMagicLink();
    }
  };

  const checkIfWasLoggedOutForTimeout = () => {
    const sessionTimeoutLogoutFlag = localStorage.getItem(
      'sessionTimeoutLogout'
    );
    if (sessionTimeoutLogoutFlag) {
      setShowSessionTimeoutBanner(true);
    }
  };

  useEffect(() => {
    checkIfWasLoggedOutForTimeout();
  }, []);

  useEffect(() => {
    if (auth?.currentUser) {
      navigate('/');
    }
  }, [auth]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get('error');
    if (error) {
      dispatch(
        showPopUp('notification', {
          notificationType: 'error',
          title: i18n.t('common.error'),
          text: i18n.t(`errors.${error}`),
          subtext: i18n.t('errors.messageError', {
            errormessage: error,
          }),
          customSubText: true,
          closeButtonColor: 'red',
          closeButtonText: i18n.t('common.tryAgain'),
          additionalButtonText: i18n.t('common.contactSupport'),
          additionalButtonClosable: true,
          onButtonClick: () => {
            // eslint-disable-next-line no-undef
            Tawk_API.toggle();
          },
        })
      );
    }
  }, []);

  return (
    <SCSignIn>
      <h1>{i18n.t('signIn.hiAgain')}</h1>

      {showSessionTimeoutBanner && (
        <div className="sessionTimeoutBanner">
          <InfoBanner
            type="error"
            text={[i18n.t('errors.sessionTimeoutWarningBanner')]}
          />
        </div>
      )}
      {isSantanderEnvironment ? (
        <div className="seu-text">
          <Icon
            iconName={faEarthAmericas}
            size="medium"
            color="var(--bluish-grey)"
            style={{ paddingTop: '3px' }}
          />
          <p style={{ paddingBottom: '10px' }}>
            {i18n.t('regionSelector.logIn')}&nbsp;
            <b>{getSantanderEnvironmentRegionText(currentLocale)}</b>
          </p>
        </div>
      ) : (
        <RegionSelector icon text={i18n.t('regionSelector.logIn')} />
      )}

      <Input
        className="fs-exclude"
        inputType="text"
        id="businessEmail"
        name="businessEmail"
        size="large"
        icon={faEnvelope}
        inputPlaceholder={i18n.t('signIn.email')}
        defaultValue={email || ''}
        onChangeValue={(val) => {
          setEmail(val.trim());
        }}
        onKeyPress={(e) => handleKeyPress(e)}
      />

      <Input
        className="fs-exclude"
        inputType="password"
        id="password"
        name="password"
        size="large"
        icon={faKey}
        inputPlaceholder={i18n.t('signIn.password')}
        defaultValue={pass || ''}
        onChangeValue={(val) => {
          setPass(val.trim());
        }}
        onKeyPress={(e) => handleKeyPress(e)}
      />

      {show2FACaptcha && <div id="setupSendCode" />}

      <Button
        id="emailButton"
        type="button"
        title="Send link"
        onClick={() => {
          sendMagicLink();
          TrackButton('signIn.signIn');
        }}
        text={i18n.t('signIn.signIn')}
        size="full"
        color="red"
      />

      <div className="links-container">
        <Link to="/forgot-password">{i18n.t('signIn.forgotPassword')}</Link>
        <Link to="/setup-account">{i18n.t('signIn.noAccount')}</Link>
      </div>

      {/** Google and Santander buttons */}
      {!isNonCountryEnvironment && (
        <div className="SignIn_Buttons">
          <div className="SignIn_GoogleButton">
            <Button
              text={
                <>
                  {googleIcon}
                  {i18n.t('setup.account.signUpWithGoogle')}
                </>
              }
              onClick={() => handleSignInWithGoogle()}
              size="full"
              color="white"
            />
          </div>
        </div>
      )}
    </SCSignIn>
  );
};

export default SignIn;
