import styled from '@emotion/styled';

const SCNewPhisingCampaignType = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  .NewPhisingCampaignType_TypesContainer {
    display: flex;
    flex-direction: row;

    gap: 20px;
  }

  .NewPhisingCampaignType_ActionsContainer {
    margin-top: 30px;
  }

  .NewPhisingCampaignType_TypeSelected {
    background: transparent linear-gradient(180deg, #78bc12bf 0%, #78bc12 100%)
      0% 0% no-repeat padding-box !important;
    color: white !important;
    border-color: inherit !important;

    svg {
      color: white !important;
    }
  }
`;

export default SCNewPhisingCampaignType;
