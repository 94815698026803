import React from 'react';
import useInternationalization from '../../../hooks/Internationalization/useInternationalization';

import { useTranslation } from '../../../i18n';
import WrapperImage from '../../organisms/PopUp/WrapperImage';
import whitelistingImages from '../../../utils/internationalizationModules/whitelistingImages';
import SCGSuiteGuite from './GSuiteGuide.style';

const GSuiteGuide = () => {
  const i18n = useTranslation();
  const { internationalizedImgLang } = useInternationalization();
  const whitelistingImagesI18n = whitelistingImages[internationalizedImgLang];

  return (
    <SCGSuiteGuite>
      <h3>{i18n.t('whitelisting.guideGSuite.stepAllowEmails.stepTitle')}</h3>
      <p>
        <div>
          {i18n.t('whitelisting.guideGSuite.stepAllowEmails.logon')}{' '}
          <a href="https://admin.google.com" target="_blank" rel="noreferrer">
            https://admin.google.com
          </a>
        </div>

        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                'whitelisting.guideGSuite.stepAllowEmails.navigate'
              ),
            }}
          />
          <WrapperImage src={whitelistingImagesI18n.whitelistingGSuite1} />
          <WrapperImage src={whitelistingImagesI18n.whitelistingGSuite2} />
          <WrapperImage src={whitelistingImagesI18n.whitelistingGSuite3} />
          <WrapperImage src={whitelistingImagesI18n.whitelistingGSuite4} />
        </div>

        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.t('whitelisting.guideGSuite.stepAllowEmails.addIP'),
            }}
          />
          <WrapperImage src={whitelistingImagesI18n.whitelistingGSuite5} />
        </div>
      </p>

      <h3>
        {i18n.t('whitelisting.guideGSuite.stepPreventWarnings.stepTitle')}
      </h3>
      <p>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                'whitelisting.guideGSuite.stepPreventWarnings.navigate'
              ),
            }}
          />
          <WrapperImage src={whitelistingImagesI18n.whitelistingGSuite6} />
        </div>
        <div>
          {i18n.t('whitelisting.guideGSuite.stepPreventWarnings.createSetting')}
          <WrapperImage src={whitelistingImagesI18n.whitelistingGSuite9} />
          <ul>
            <li
              dangerouslySetInnerHTML={{
                __html: i18n.t(
                  'whitelisting.guideGSuite.stepPreventWarnings.createSettingDescription'
                ),
              }}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: i18n.t(
                  'whitelisting.guideGSuite.stepPreventWarnings.createSettingAffects'
                ),
              }}
            />
            <li>
              {i18n.t(
                'whitelisting.guideGSuite.stepPreventWarnings.createSettingAddExpression'
              )}
              <WrapperImage src={whitelistingImagesI18n.whitelistingGSuite8} />
              <b>
                <ul>
                  <li>
                    {i18n.t(
                      'whitelisting.guideGSuite.stepPreventWarnings.createSettingExpressionParam1'
                    )}
                  </li>
                  <li>
                    {i18n.t(
                      'whitelisting.guideGSuite.stepPreventWarnings.createSettingExpressionParam2'
                    )}
                  </li>
                  <li>
                    {i18n.t(
                      'whitelisting.guideGSuite.stepPreventWarnings.createSettingExpressionParam3'
                    )}
                  </li>
                  <li>
                    {i18n.t(
                      'whitelisting.guideGSuite.stepPreventWarnings.createSettingExpressionParam4'
                    )}
                  </li>
                </ul>
              </b>
            </li>
            <li>
              <span
                dangerouslySetInnerHTML={{
                  __html: i18n.t(
                    'whitelisting.guideGSuite.stepPreventWarnings.createSettingExpressionMathes'
                  ),
                }}
              />
              <WrapperImage src={whitelistingImagesI18n.whitelistingGSuite10} />
            </li>
            <li
              dangerouslySetInnerHTML={{
                __html: i18n.t(
                  'whitelisting.guideGSuite.stepPreventWarnings.createSettingValidate'
                ),
              }}
            />
          </ul>
        </div>
      </p>
    </SCGSuiteGuite>
  );
};

export default GSuiteGuide;
