import styled from '@emotion/styled';
import { Button } from 'antd';

const AntDButton = styled(Button)`
  &.ant-btn-primary {
    background-color: var(--red);

    :hover {
      background-color: var(--red-hover) !important;
    }
  }
`;

export default AntDButton;
