import styled from '@emotion/styled';

const SCMySubscriptionCard = styled.div`
  .subscription-card-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .subscription-card {
    display: flex;
    flex-direction: ${({ isTwoColumnLayout }) =>
      isTwoColumnLayout ? 'row' : 'column'};
    justify-content: space-between;
    align-items: center;
    gap: ${({ isTwoColumnLayout }) => (isTwoColumnLayout ? '65px' : '30px')};
  }

  .apex-chart-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .your-subscription-text-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    b,
    span {
      font-family: var(--font2);
    }
  }

  @media (max-width: 900px) {
    .subscription-card {
      flex-direction: column;
    }
  }

  @media (width < 500px) {
    .apexcharts-legend {
      justify-content: flex-start !important;
      top: auto !important;
    }
  }
`;

export default SCMySubscriptionCard;
