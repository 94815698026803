import React from 'react';
import SectionCardHeader from '../SectionCardHeader/SectionCardHeader';
import SCSectionCard from './SectionCard.style';

const SectionCard = ({
  children,
  headerIcon = undefined,
  headerTitle = undefined,
  headerSubtitle = undefined,
  headerButtonIcon = undefined,
  headerButtonText = undefined,
  headerButtonOnClick = undefined,
  headerButtonDisabled = undefined,
  headerCollapsableButtonOnClick = undefined,
  isHeaderSelected = false,
  isCollapsed = undefined,
  hasPadding = true,
  hasOverflow = false,
  className,
}) => {
  return (
    <SCSectionCard
      hasPadding={hasPadding}
      hasOverflow={hasOverflow}
      className={className}>
      {(headerIcon || headerTitle) && (
        <SectionCardHeader
          iconName={headerIcon}
          title={headerTitle}
          subtitle={headerSubtitle}
          buttonIcon={headerButtonIcon}
          buttonText={headerButtonText}
          buttonOnClick={headerButtonOnClick}
          buttonDisabled={headerButtonDisabled}
          collapseOnClick={headerCollapsableButtonOnClick}
          isHeaderSelected={isHeaderSelected}
          isCollapsed={isCollapsed}
        />
      )}
      {!isCollapsed && <div className="content">{children}</div>}
    </SCSectionCard>
  );
};

export default SectionCard;
