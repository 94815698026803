import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import emailProtectionService from '../../services/emailProtection/emailProtection.service';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';

const useFetchQuarantineEmail = (id) => {
  const [isLoading, setIsLoading] = useState(true);
  const [quarantineEmail, setQuarantineEmail] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const i18n = useTranslation();

  useEffect(() => {
    emailProtectionService
      .fetchQuarantineEmail(id)
      .then((resp) => {
        setQuarantineEmail(resp?.data.data);
      })
      .catch(() => {
        navigate('/client/mailbox-security');
        dispatch(
          showPopUp('notification', {
            notificationType: 'error',
            title: i18n.t('common.error'),
            text: i18n.t('errors.not_found'),
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return {
    isLoading,
    quarantineEmail,
  };
};

export default useFetchQuarantineEmail;
