import styled from '@emotion/styled';

const SCEmbeddedTawkTo = styled.div`
  overflow: hidden;
  padding-top: 6%;

  .chat-container{
    display: flex;
    flex-direction: column;
  
  .title {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    background-color: #f7f7f7;
    padding: 1rem;
  }

  .frame {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

   @media (max-width: 769px) {
     margin-top: 10vh;
    }
`;

export default SCEmbeddedTawkTo;
