import { useEffect, useState } from 'react';
import partnerClientsService from '../../services/partnerClients/partnerClients.service';

const usePartnerClient = ({ clientId }) => {
  const [partnerClient, setPartnerClient] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchPartnerClient = async () => {
    if (clientId) {
      try {
        const resp = await partnerClientsService.getPartnerClient(clientId);
        setPartnerClient(resp.data?.data);
      } catch (e) {
        setError(e);
      }
    }
  };

  useEffect(() => {
    const getPartnerClient = async () => {
      setLoading(true);
      setError(null);

      await fetchPartnerClient(clientId);

      setLoading(false);
    };

    if (clientId) {
      getPartnerClient();
    }
  }, [clientId]);

  return {
    loading,
    error,
    partnerClient,
    fetchPartnerClient,
  };
};

export default usePartnerClient;
