import { faClockFive } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useTranslation } from '../../../i18n';
import Icon from '../../atoms/Icon/Icon';
import SCActionsEmptyMessage from './ActionsEmptyMessage.style';

const ActionsEmptyMessage = ({ title }) => {
  const i18n = useTranslation();
  return (
    <SCActionsEmptyMessage>
      <div className="actions-empty-message-container">
        <div className="left-container">
          <Icon
            iconName={faClockFive}
            size="semiLarge"
            color="var(--bluish-grey)"
          />
        </div>

        <div className="right-container">
          <p className="title">{title}</p>

          <p className="subtitle">
            {i18n.t('action-center.subtitleEmptyMessage')}
          </p>
        </div>
      </div>
    </SCActionsEmptyMessage>
  );
};

export default ActionsEmptyMessage;
