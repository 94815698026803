import styled from '@emotion/styled/macro';

export const SCProgressTutorial = styled.div`
  font-family: var(--font2);

  & .tuturial-step-text {
    color: var(--bluish-grey);
    font-size: 16px;
    text-align: center;
    padding-top: 15px;
    width: 165px;
    font-weight: 600;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color || 'var(--bluish-grey)'};
  background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 30px;
  line-height: 30px;
  margin: 0 5px;
`;

export const Horizontal = styled.div`
  height: 1px;
  border: 1px solid;
  width: 120rem;
  color: var(--grey-2);
  margin-top: 20px;
  border-radius: 10px;

  @media screen and (max-width: 1320px) {
    display: none;
  }
`;

export const Steps = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 1304px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 1504px) {
    &.progressSteps {
      flex-wrap: wrap;
    }
  }
  & ${Card}:first-of-type {
    ${Horizontal}:first-of-type {
      display: none;
    }
  }

  & ${Card}:last-child {
    ${Horizontal}:last-child {
      display: none;
    }
  }

  @media screen and (max-width: 532px) {
    display: flex;
    justify-content: center;
    gap: 20px;
    & ${Card} {
      flex-grow: 1 !important;
    }
    & ${Horizontal} {
      visibility: hidden;
    }
  }
`;
