export const PAYMENT_METHODS = {
  NONE: 'NONE',
  ACTIVE: 'ACTIVE',
  INVALID: 'INVALID',
};

export const PAYMENT_REASONS = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  SUBSCRIPTION_RENEWAL: 'SUBSCRIPTION_RENEWAL',
  SUBSCRIPTION_UPDATE: 'SUBSCRIPTION_UPDATE',
};

export const PAYMENT_PERIODICITY = {
  YEARLY: 'YEARLY',
  MONTHLY: 'MONTHLY',
  NONE: 'NONE',
};

export const PAYMENT_STATUS = {
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED',
};
