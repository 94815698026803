import React from 'react';
import Label from '../../atoms/Label/Label';
import SCLabelDetail from './LabelDetail.style';

const LabelDetail = ({ name, value, color, labelWidth }) => {
  return (
    <SCLabelDetail>
      <b>{name}</b>
      <Label value={value} color={color} width={labelWidth} />
    </SCLabelDetail>
  );
};

export default LabelDetail;
