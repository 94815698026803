import React from 'react';
import styled from '@emotion/styled';
import { Table } from 'antd';

const StyledTable = styled(Table)`
  .custom-row:hover {
    cursor: pointer;
  }
  .selected-row {
    background-color: #bc1224;
    color: white;
  }

  .ant-table-column-sort {
    background-color: transparent !important;
  }

  .selected-row:hover {
    background-color: #bc1224;
    color: black;
  }
`;

const CustomTable = ({ setSelectedRow, selectedRowId, ...props }) => {
  return (
    <StyledTable
      onRow={(record) => ({
        onClick: () => setSelectedRow(record.id),
      })}
      rowClassName={(record) =>
        record.id === selectedRowId ? 'custom-row selected-row' : 'custom-row'
      }
      {...props}
    />
  );
};

export default CustomTable;
