import { useSelector } from 'react-redux';
import handleUnauthorizedAccess from '../../../utils/restrictedAccessBehaviour/restrictedAccessBehaviour';

function EmailSecurityRouteGuard({ children }) {
  const client = useSelector((redux) => redux.client);

  return client?.status?.serviceLevel.features.configureEmailSecurity
    ? children
    : handleUnauthorizedAccess();
}
export default EmailSecurityRouteGuard;
