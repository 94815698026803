import styled from '@emotion/styled';

const SCAccountCreated = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  .account-created-container {
    background-color: var(--white);
    border-radius: var(--section-radius);
    padding: 40px;
    width: 420px;
    position: relative;
    box-shadow: 2px 2px 20px #79889714;

    .icon-container {
      margin-bottom: 40px;
    }

    p {
      font-family: var(--font2);
      font-weight: 600;
    }

    .verifying-email {
      margin-top: 30px;
    }

    .email-link-sent {
      color: var(--red);
    }

    .text-button-container {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
`;

export default SCAccountCreated;
