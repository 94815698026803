import styled from '@emotion/styled';

const SCProfileEmployeesFilterCard = styled.div`
  .employees-list-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  p {
    font-weight: 400;
  }

  .ProfileEmployeesFilterCard_SelectedRowActions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 65px;
    padding: 15px;

    background-color: var(--light-grey);
    border-radius: 5px;

    > p {
      color: var(--bluish-grey);
      text-align: end;
    }

    .ProfileEmployeesFilterCard_SelectedRowActionsInfo {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;

      p {
        color: var(--bluish-grey);
        font-weight: 500;
      }
    }
    .ProfileEmployeesFilterCard_SelectedRowActionsAction {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }

  .ProfileEmployeesFilterCard_Actions {
    display: flex;
    align-items: center;
    gap: 20px;

    > p {
      color: var(--bluish-grey-2);
      width: 100%;
      text-align: end;
    }
  }

  .ProfileEmployeesFilterCard_Pagination {
    display: flex;
    justify-content: center;
  }

  .ProfileEmployeesFilterCard_Filter {
    margin-bottom: 25px;
  }
`;

export default SCProfileEmployeesFilterCard;
