import React from 'react';
import { useTranslation } from '../../../i18n';
import SCRemoveTag from './RemoveTag.style';

const RemoveTag = ({ handleRemoveTag, setIsOpenRemoveTagBox }) => {
  const i18n = useTranslation();

  return (
    <SCRemoveTag>
      <p>{i18n.t('tagFiltering.removeTagsText')}</p>

      <div className="remove-palette-buttons-container">
        <button
          className="confirm-button"
          type="button"
          onClick={() => handleRemoveTag()}>
          {i18n.t('common.confirm')}
        </button>

        <button
          className="cancel-button"
          type="button"
          onClick={() => setIsOpenRemoveTagBox(false)}>
          {i18n.t('common.cancel')}
        </button>
      </div>
    </SCRemoveTag>
  );
};

export default RemoveTag;
