import { useSelector } from 'react-redux';
import usePhishingTrainings from '../phishingTrainings/usePhishingTrainings';

function usePhishingTrainingType() {
  const phishingCampaigns = useSelector((redux) => redux.campaigns);
  const { phishingTrainings } = usePhishingTrainings();

  const getPhishingCampaignsData = () => {
    const totalCampaigns = phishingCampaigns.length;
    let currentCampaigns = 0;
    const lastCampaignNA = '';
    let lastDateCampaign = lastCampaignNA;

    phishingCampaigns.forEach((item) => {
      const finish = item.launch_date.toDate();
      finish.setDate(finish.getDate() + 2);
      const today = new Date();

      if (finish > today) currentCampaigns += 1;

      if (
        lastDateCampaign === lastCampaignNA ||
        lastDateCampaign < item.launch_date
      ) {
        lastDateCampaign = item.launch_date;
      }
    });

    return {
      currentCampaigns,
      completedCampaigns: totalCampaigns - currentCampaigns,
      lastDateCampaign,
    };
  };

  const getPhishingTrainingsData = () => {
    let completedTrainings = 0;

    if (Object.keys(phishingTrainings).length > 0) {
      Object.keys(phishingTrainings).forEach((key) => {
        if (phishingTrainings[key].completed) {
          completedTrainings += 1;
        }
      });
    }

    return { completedTrainings };
  };

  const getPhishingTraining = () => {
    const { currentCampaigns, completedCampaigns, lastDateCampaign } =
      getPhishingCampaignsData();
    const { completedTrainings } = getPhishingTrainingsData();

    return {
      currentCampaigns,
      completedCampaigns,
      lastDateCampaign,
      completedTrainings,
    };
  };

  return { phishingTraining: getPhishingTraining() };
}

export default usePhishingTrainingType;
