import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as tagService from '../../services/tags/tag.service';

const useTag = () => {
  const { id: clientId } = useSelector((redux) => redux.client);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const createTag = async (tagName, tagColor, teamMembersEmails) => {
    const serviceResp = await tagService.createTag(
      clientId,
      tagName,
      tagColor,
      teamMembersEmails
    );

    return serviceResp;
  };

  const updateTag = async (tagId, tagColor) => {
    const serviceResp = await tagService.updateTag(clientId, tagId, tagColor);

    return serviceResp;
  };

  const deleteTag = async (tagId) => {
    const serviceResp = await tagService.deleteTag(clientId, tagId);

    return serviceResp;
  };

  return {
    loading,
    error,

    createTag,
    updateTag,
    deleteTag,
  };
};

export default useTag;
