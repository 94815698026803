import { useState, useEffect } from 'react';
import * as configService from '../../services/config/config.service';

const useMaintainMode = () => {
  const [isMaintainMode, setIsMaintainMode] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getMaintainModeState();
  }, []);

  const getMaintainModeState = () => {
    setLoading(true);

    configService.isMaintainMode().then((response) => {
      setIsMaintainMode(response?.isMaintainMode);
    }).finally(() => {
      setLoading(false);
    }); 
  };

  return {
    loading,
    isMaintainMode,
  };
};
export default useMaintainMode;
