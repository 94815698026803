import styled from '@emotion/styled';

const SCAdminLayout = styled.div`
  height: 100dvh;
  display: flex;
  flex-direction: column;

  .content-container {
    padding-top: calc(var(--navbar-height) + 40px);
    padding-inline: 40px;
    background-color: var(--light-grey);
    flex: 1;
  }
`;

export default SCAdminLayout;
