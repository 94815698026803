import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showPopUp } from '../../../redux/actions/popUp.actions';

const WrapperImage = ({ src, showPopUp }) => {
  const showImage = () =>
    showPopUp('textWithoutButtons', <img src={src} alt="Screenshot Popup" />);

  return (
    <FontAwesomeIcon
      style={{ cursor: 'pointer', marginLeft: 5 }}
      icon="expand"
      onClick={showImage}
      color="blue"
    />
  );
};

export default connect(undefined, { showPopUp })(WrapperImage);
