import React, { useEffect, useState } from 'react';

import {
  faCircleExclamation,
  faMessageCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import SCPossibleImpersonationFilterCard from './PossibleImpersonationFilterCard.style';
import { useTranslation } from '../../../i18n';
import Filter from '../../molecules/Filter/Filter';
import PossibleImpersonationTableCard from '../PossibleImpersionationTableCard/PossibleImpersionationTableCard';
import useImpersonations from '../../../hooks/impersonations/useImpersonations';
import useDomains from '../../../hooks/domains/useDomains';

const PossibleImpersonationFilterCard = () => {
  const client = useSelector((redux) => redux.client);
  const [selectedDomain, setSelectedDomain] = useState('');

  const { impersonations } = useImpersonations(selectedDomain);
  const { addDomain, removeDomain } = useDomains();
  const i18n = useTranslation();

  const { monitoredDomains } = client;

  const handleDomainChange = (targetDomain) => {
    setSelectedDomain(targetDomain);
  };

  const IMPERSONATIONS_STATUS = [
    'dismissed',
    'resolved',
    'not_reported',
    'reported',
  ];

  const reviewedImpersonations = impersonations.filter((impersonation) =>
    IMPERSONATIONS_STATUS.includes(impersonation.status)
  );

  const notReviewedImpersonations = impersonations.filter(
    (impersonation) => !IMPERSONATIONS_STATUS.includes(impersonation.status)
  );

  useEffect(() => {
    if (monitoredDomains && monitoredDomains.length > 0) {
      setSelectedDomain(monitoredDomains[0]);
    }
  }, [monitoredDomains.length]);

  return (
    <SCPossibleImpersonationFilterCard>
      <div className="section-organism">
        <div className="filter-table-container">
          <Filter
            selectedValue={selectedDomain}
            filterValues={monitoredDomains}
            onChangeFilter={handleDomainChange}
            addButtonText={i18n.t(
              'components.monitoredDomainsBox.addNewDomain'
            )}
            removeButtonText={i18n.t(
              'components.monitoredDomainsBox.removeDomain'
            )}
            addButtonOnClick={() => addDomain()}
            removeButtonDisabled={monitoredDomains.length === 1}
            removeButtonOnClick={() => {
              removeDomain(selectedDomain);
            }}
          />
          <PossibleImpersonationTableCard
            title={i18n.t('suppliersSecurity.table.notReviewedTitle')}
            icon={faCircleExclamation}
            impersonations={notReviewedImpersonations}
            domain={selectedDomain}
          />
        </div>
      </div>

      <PossibleImpersonationTableCard
        title={i18n.t('suppliersSecurity.table.reviewedTitle')}
        icon={faMessageCheck}
        impersonations={reviewedImpersonations}
        domain={selectedDomain}
      />
    </SCPossibleImpersonationFilterCard>
  );
};

export default PossibleImpersonationFilterCard;
