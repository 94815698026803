/**
 * Algoritmo de https://es.stackoverflow.com/questions/31713/c%C3%B3mo-validar-un-rfc-de-m%C3%A9xico-y-su-digito-verificador
 * @param {string} rfc
 * @returns wether the RFC is valid or not.
 */
const isValidRFC = (rfc) => {
  const reg2 =
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;

  const validFormat = rfc.match(reg2);

  if (!validFormat) return false;

  // Separar el dígito verificador del resto del RFC
  const digitoVerificador = parseInt(validFormat.pop());
  const rfcSinDigito = validFormat.slice(1).join('');
  const len = rfcSinDigito.length;

  // Obtener el digito esperado
  const diccionario = '0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ';
  const indice = len + 1;
  let suma;
  let digitoEsperado;

  if (len === 12) {
    suma = 0;
  } else {
    // Ajuste para persona moral
    suma = 481;
  }

  for (let i = 0; i < len; i += 1) {
    suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
  }

  digitoEsperado = 11 - (suma % 11);

  if (digitoEsperado === 11) {
    digitoEsperado = 0;
  } else if (digitoEsperado === 10) {
    digitoEsperado = 'A';
  }

  // El dígito verificador coincide con el esperado?
  if (digitoVerificador !== digitoEsperado) {
    return false;
  }

  return true;
};

export default isValidRFC;
