import esFlag from '../../../img/flags/es.svg';
import enFlag from '../../../img/flags/gb.svg';
import brFlag from '../../../img/flags/br.svg';

const imageFlags = {
  es: esFlag,
  en: enFlag,
  'pt-BR': brFlag,
};

export default imageFlags;
