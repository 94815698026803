import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowsSpin } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import useTableSort from '../../../hooks/tableSort/useTableSort';
import {
  SCTable,
  TableRow,
  IconStyle,
  TdIcon,
  TdArrowIcon,
  CellStyle,
  TableColumn,
  LoadingTable,
} from './Table.style';
import Icon from '../Icon/Icon';
import SpinnerText from '../SpinnerText/SpinnerText';
import EmptyMessage from '../../molecules/EmptyMessage/EmptyMessage';
import { useTranslation } from '../../../i18n';
import IndeterminateCheckbox, {
  CHECKBOX_STATES,
} from '../IndeterminateCheckbox/IndeterminateCheckbox';
import useKeyPress from '../../../hooks/useKeyPress/useKeyPress';

const TYPES = {
  STRING: 'string',
  ICON: 'icon',
  COMPONENT: 'component',
};

const Table = ({
  columns,
  rows,
  emptyMessage,
  hasRowIcon,
  hasRowSelect,
  onChangeFilterTag,
  rowIdentifier,
  rowHasBeenSelected,
  sortOptions,
  isLoading,
  autoWidth = true,
  // activeSortedColumnDirection,
  // activeSortedColumnName,
  // onSort,
}) => {
  const i18n = useTranslation();
  const { sortColumn, getColumnIcon, sortingOptions, hasBeenSorted } =
    useTableSort({
      activeSortedColumnDirection: sortOptions?.activeSortedColumnDirection,
      activeSortedColumnName: sortOptions?.activeSortedColumnName,
    });
  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [lastSelectedIndex, setLastSelectedIndex] = useState();

  /**
   * Si el shift está presionado, entonces, eliminamos estilos de selección para evitar
   * seleccionar todo el contenido de la tabla al utilizar la selección múltiple por checkbox.
   */
  const { isKeyPressed } = useKeyPress('Shift');

  useEffect(() => {
    if (rowHasBeenSelected) {
      updateTableSelectedRows([]);
    }
  }, [rows]);

  const updateTableSelectedRows = (selectedRows) => {
    setTableSelectedRows(selectedRows);
    rowHasBeenSelected(selectedRows);
  };

  const selectTableRow = (selectedRowIndex, event) => {
    const selectedRowKey = rows[selectedRowIndex][rowIdentifier].value;
    const tableSelectedRowsMap = {};
    tableSelectedRows.forEach((row) => {
      tableSelectedRowsMap[row[rowIdentifier].value] = row;
    });
    const isShiftPressed = event.nativeEvent.shiftKey;

    const isNotAlreadySelected = !tableSelectedRowsMap[selectedRowKey];

    if (isShiftPressed && lastSelectedIndex !== undefined) {
      const selectedRows = rows.slice(
        Math.min(selectedRowIndex, lastSelectedIndex),
        Math.max(selectedRowIndex, lastSelectedIndex) + 1
      );

      selectedRows.forEach((row) => {
        if (isNotAlreadySelected) {
          tableSelectedRowsMap[row[rowIdentifier].value] = row;
        } else {
          delete tableSelectedRowsMap[row[rowIdentifier].value];
        }
      });
    } else if (isNotAlreadySelected) {
      tableSelectedRowsMap[selectedRowKey] = rows[selectedRowIndex];
    } else {
      delete tableSelectedRowsMap[selectedRowKey];
    }

    setLastSelectedIndex(selectedRowIndex);
    updateTableSelectedRows(Object.values(tableSelectedRowsMap));
  };

  const selectAllTableRow = (e) => {
    const tableSelectedRowsAux = e.target.checked ? [...rows] : [];

    setLastSelectedIndex(undefined);
    updateTableSelectedRows(tableSelectedRowsAux);
  };

  const renderHeaders = () => {
    const auxColumns = columns.map((column, index) => (
      <th
        key={hasRowSelect ? index + 1 : index}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(sortOptions?.onSort &&
          !column.options?.avoidSort && {
            onClick: () => {
              sortColumn(column.name, index);
            },
          })}>
        <div className="TableHeader_Container">
          {column.name}
          {column.infoOnClick && (
            <button
              type="button"
              aria-label="Link to FAQ information"
              onClick={(e) => {
                e.stopPropagation();
                column.infoOnClick();
              }}>
              <FontAwesomeIcon icon={faCircleInfo} color="var(--bluish-grey)" />
            </button>
          )}
          <div className="TableHeader_Icon">
            <Icon iconName={getColumnIcon(column.name)} />
          </div>
        </div>
      </th>
    ));
    if (hasRowIcon) {
      auxColumns.push(<th key={columns.length + 1}> </th>);
    }
    if (hasRowSelect) {
      auxColumns.unshift(
        <th key={0}>
          <IndeterminateCheckbox
            state={
              rows.length === tableSelectedRows.length
                ? CHECKBOX_STATES.checked
                : CHECKBOX_STATES.empty
            }
            onChange={(e) => {
              selectAllTableRow(e);
            }}
          />
        </th>
      );
    }
    return auxColumns;
  };

  const renderIcon = (field) => {
    return (
      <FontAwesomeIcon
        icon={field.value}
        style={IconStyle(field.color, field.fontSize)}
      />
    );
  };

  const renderLoadingIcon = () => {
    return (
      <Icon
        iconName={faArrowsSpin}
        size="medium"
        color="var(--bluish-grey)"
        iconClassName="spin-animation"
        style={{
          width: 'fit-content',
          marginLeft: 'auto',
        }}
      />
    );
  };

  const renderCells = (cells, rowIndex) => {
    const ROW_OBJECT_INDEX = 1;
    const data = Object.entries(cells);

    const auxCells = data.map((cellData, index) => {
      if (cellData[ROW_OBJECT_INDEX]) {
        const key = `${index}-${cellData[ROW_OBJECT_INDEX].value}`;
        const ofuscationRequired =
          columns && columns[index]?.options?.ofuscateFS && 'fs-exclude';

        if (cellData[ROW_OBJECT_INDEX].type === TYPES.STRING) {
          return (
            <td
              className={ofuscationRequired}
              key={key}
              style={CellStyle(
                cellData[ROW_OBJECT_INDEX].color,
                cellData[ROW_OBJECT_INDEX].fontWeight
              )}>
              {cellData[ROW_OBJECT_INDEX].value}
            </td>
          );
        }

        if (cellData[ROW_OBJECT_INDEX].type === TYPES.ICON) {
          return (
            <TdIcon key={key}>{renderIcon(cellData[ROW_OBJECT_INDEX])}</TdIcon>
          );
        }

        if (cellData[ROW_OBJECT_INDEX].type === TYPES.COMPONENT) {
          return (
            <td className={ofuscationRequired} key={key}>
              {cellData[ROW_OBJECT_INDEX].value}
            </td>
          );
        }
      }
    });

    if (hasRowIcon) {
      if (cells.isLoading) {
        auxCells.push(<TdArrowIcon>{renderLoadingIcon()}</TdArrowIcon>);
      } else if (cells.onClick) {
        auxCells.push(
          <TdArrowIcon>
            {renderIcon({
              value: faAngleRight,
              color: 'var(--bluish-grey)',
            })}
          </TdArrowIcon>
        );
      } else {
        auxCells.push(<TdArrowIcon />);
      }
    }

    if (hasRowSelect) {
      auxCells.unshift(
        <td>
          <IndeterminateCheckbox
            state={
              tableSelectedRows.find(
                (tableSelectedIndex) =>
                  tableSelectedIndex.email.value === rows[rowIndex].email.value
              )
                ? CHECKBOX_STATES.checked
                : CHECKBOX_STATES.empty
            }
            onChange={(event) => {
              selectTableRow(rowIndex, event);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </td>
      );
    }
    return auxCells;
  };

  const renderRows = () => {
    return rows.map((row, index) => (
      <TableRow key={index} onClick={row.onClick}>
        {renderCells(row, index)}
      </TableRow>
    ));
  };

  useEffect(() => {
    if (hasBeenSorted && sortOptions?.onSort) {
      sortOptions.onSort(sortingOptions);
    }
  }, [sortingOptions]);

  const renderComponent = () => {
    if (isLoading) {
      return (
        <LoadingTable>
          <SpinnerText text={i18n.t('common.loading')} />
        </LoadingTable>
      );
    }

    if (rows?.length) {
      return (
        <SCTable
          headers={columns}
          onChangeFilterTag={onChangeFilterTag}
          hasRowSelect={hasRowSelect}
          sortable={!!sortOptions?.onSort}
          activeSortedColumnName={sortingOptions?.activeSortedColumnName}
          activeSortedColumnDirection={
            sortingOptions?.activeSortedColumnDirection
          }
          hasRowIcon={hasRowIcon}
          autoWidth={autoWidth}
          isShiftPressed={isKeyPressed}>
          {columns && (
            <thead>
              <TableColumn>{renderHeaders()}</TableColumn>
            </thead>
          )}
          <tbody>{renderRows()}</tbody>
        </SCTable>
      );
    }

    return <EmptyMessage>{emptyMessage}</EmptyMessage>;
  };

  return renderComponent();
};

export default Table;
