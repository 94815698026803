import { useSelector } from 'react-redux';

export const isUserInTeamMembers = () => {
  const teamMembers = useSelector((redux) => redux.teamMembers);
  const user = useSelector((redux) => redux.user);

  return teamMembers?.some(
    (teamMember) => teamMember.hashedEmail === user.hashedEmail
  );
};
