import React from 'react';
import { faSealQuestion } from '@fortawesome/pro-regular-svg-icons';
import SCActionFAQCard from './ActionFAQCard.style';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import actionTemplates from '../../Dashboard/Sections/Actions/ActionTemplates.json';
import { useTranslation } from '../../../i18n';
import ActionFAQ from '../../molecules/ActionFAQ/ActionFAQ';

const ActionFAQCard = ({ action }) => {
  const i18n = useTranslation();
  return (
    <SCActionFAQCard>
      <SectionCard
        headerTitle={i18n.t('action-center.faq')}
        headerIcon={faSealQuestion}>
        {actionTemplates[action.type].faq.map((item) => (
          <div className="FAQ">
            <ActionFAQ
              FAQName={i18n.t(`action-center.faqArticles.${item.slug}`)}
              FAQResource={item[i18n.getLanguage()]}
            />
          </div>
        ))}
      </SectionCard>
    </SCActionFAQCard>
  );
};

export default ActionFAQCard;
