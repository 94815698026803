/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export const createStripeSession = (
  clientId,
  numberOfLicenses,
  language,
  priceId,
  taxId,
  currency,
  paymentFrequency,
  stripeCustomerId
) => {
  return axios.post('/create-stripe-session', {
    clientId,
    numberOfLicenses,
    language,
    priceId,
    taxId,
    currency,
    paymentFrequency,
    stripeCustomerId,
  });
};
