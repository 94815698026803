import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { faCartShopping, faPen } from '@fortawesome/pro-regular-svg-icons';
import SCYourPaymentMethodInformationCard from './YourPaymentMethodInformationCard.style';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import ListOfPaymentMethods from '../../molecules/ListOfPaymentMethods/ListOfPaymentMethods';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import { PAYMENT_METHODS } from '../../../utils/constants/payments';

const YourPaymentMethodInformationCard = () => {
  const client = useSelector((redux) => redux.client);
  const teamMembers = useSelector((redux) => redux.teamMembers);
  const user = useSelector((redux) => redux.user);
  const i18n = useTranslation();
  const dispatch = useDispatch();

  const myTeamMember = teamMembers?.find(
    (teamMember) => teamMember.hashedEmail === user.hashedEmail
  );

  const invalidPaymentMethod =
    client?.status.paymentMethod === PAYMENT_METHODS.INVALID;

  return (
    <SCYourPaymentMethodInformationCard>
      <SectionCard
        headerIcon={faCartShopping}
        headerTitle={i18n.t('profile.paymentMethod.title')}
        id="payment-method-card">
        <div className="info-content">
          {invalidPaymentMethod && (
            <InfoBanner
              text={[
                i18n.t(
                  myTeamMember.role.owner
                    ? 'invalidPaymentBanner.owner.text'
                    : 'invalidPaymentBanner.teamMember.text'
                ),
              ]}
              type="error"
            />
          )}

          <ListOfPaymentMethods />

          <div className="buttons-form-container">
            <Button
              text={i18n.t('profile.edit')}
              icon={faPen}
              size="mid"
              color="white"
              onClick={() => {
                dispatch(showPopUp('editCreditCard'));
              }}
            />
          </div>
        </div>
      </SectionCard>
    </SCYourPaymentMethodInformationCard>
  );
};

export default YourPaymentMethodInformationCard;
