import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PartnerGuard = ({ children }) => {
  const client = useSelector((redux) => redux.client);

  // eslint-disable-next-line react/react-in-jsx-scope
  return client.partner ? children : <Navigate to="/" />;
};

export default PartnerGuard;
