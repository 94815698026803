import styled from '@emotion/styled';

const SCManagedThreatsSection = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  .managedThreatsOrganism,
  .managedThreatsChartsOrganism {
    margin-bottom: 60px;
  }

  .managedThreatsChartsOrganism {
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    div {
      max-width: 470px;
    }
  }
`;

export default SCManagedThreatsSection;
