import styled from '@emotion/styled';

const fontColors = {
  default: 'var(--bluish-grey)',
  red: 'var(--white)',
  lightRed: 'var(--white)',
  orange: 'var(--white)',
  yellow: 'var(--white)',
  green: 'var(--white)',
};

const riskTagFontColors = {
  default: 'var(--bluish-grey)',
  lightRed: 'var(--red-hover)',
  red: 'var(--red)',
  orange: 'var(--orange)',
  green: 'var(--green)',
};

const backgroundColors = {
  default: 'var(--light-grey)',
  lightRed: 'var(--light-red-gradient-1)',
  red: 'var(--red-gradient)',
  orange: 'var(--orange-gradient)',
  green: 'var(--green-gradient)',
};

const borderColors = {
  default: backgroundColors.default,
  red: backgroundColors.red,
  orange: backgroundColors.orange,
  yellow: backgroundColors.yellow,
  green: backgroundColors.green,
};

const SCScanResultBox = styled.div`
  .scan-result-box {
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    width: 125px;
    height: 100%;

    > p {
      color: ${({ color }) => (color ? fontColors[color] : fontColors.default)};
      font-family: var(--font2);
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      line-height: 16px;
    }

    background: ${({ color }) =>
      color ? backgroundColors[color] : backgroundColors.default};
  }

  .risk-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 95px;
    height: 26px;
    border-radius: 9999px;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0;
    margin-top: 10px;
    margin-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
    color: ${({ color }) =>
      color ? riskTagFontColors[color] : riskTagFontColors.default};
    /* border: ${({ color }) => (!color || color === 'default') && '1px solid'};
    border-color: ${({ color }) =>
      (!color || color === 'default') && borderColors.default}; */
  }

  .learn-more {
    cursor: pointer;
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;

    > p {
      font-size: 14px;
      color: ${({ color }) => (color ? fontColors[color] : fontColors.default)};
      font-family: var(--font2);
      font-weight: 600;
    }

    > div {
      color: ${({ color }) => (color ? fontColors[color] : fontColors.default)};
    }

    &:hover {
      text-decoration: underline;
      text-decoration-color: ${({ color }) =>
        color ? fontColors[color] : fontColors.default};
    }
  }

  .scan-result-line {
    width: 3px;
    margin-left: auto;
    margin-right: auto;
    background: ${({ color }) =>
      color ? riskTagFontColors[color] : riskTagFontColors.default};
    height: ${({ lineHeight }) => lineHeight || '100px'};
  }

  .scan-result-circle {
    border-radius: 50%;
    width: 21px;
    height: 21px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    background: ${({ color }) =>
      color ? riskTagFontColors[color] : riskTagFontColors.default};
  }
`;

export default SCScanResultBox;
