import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import Icon from '../Icon/Icon';
import SCMobileMenuItem from './MobileMenuItem.style';

const MobileMenuItem = ({
  onClick,
  icon,
  text,
  selected,
  droppedDown,
  ...props
}) => {
  return (
    <SCMobileMenuItem onClick={onClick} selected={selected} {...props}>
      <Icon iconName={icon} size="medium" />
      <span className="menu-item-text">{text}</span>
      {droppedDown !== undefined && (
        <div className={`menu-dropdown-arrow ${droppedDown ? 'down' : ''}`}>
          <Icon iconName={faAngleRight} size="medium" />
        </div>
      )}
    </SCMobileMenuItem>
  );
};

export default MobileMenuItem;
