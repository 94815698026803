import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';

const useTableSort = (initialSortingOptions) => {
  const [hasBeenSorted, setHasBeenSorted] = useState(false);
  const [sortingOptions, setSortingOptions] = useState(initialSortingOptions);

  const getActiveColumnIcon = () => {
    let activeColumnIcon;
    switch (sortingOptions.activeSortedColumnDirection) {
      case 'asc':
        activeColumnIcon = faAngleUp;
        break;
      case 'desc':
        activeColumnIcon = faAngleDown;
        break;
      default:
        // to-do
        activeColumnIcon = faAngleUp;
        break;
    }
    return activeColumnIcon;
  };

  const getColumnIcon = (columnName) => {
    if (columnName === sortingOptions.activeSortedColumnName) {
      return getActiveColumnIcon();
    }
    return faAngleUp;
  };

  const getNextSortedDirec = (currentDirection) => {
    let nextSortDirec;
    switch (currentDirection) {
      case 'asc':
        nextSortDirec = 'desc';
        break;
      case 'desc':
        nextSortDirec = 'asc';
        break;
      default:
        nextSortDirec = 'asc';
        break;
    }
    return nextSortDirec;
  };

  const sortColumn = (sortedColumnName, sortedColumnIndex) => {
    if (sortingOptions?.activeSortedColumnName === sortedColumnName) {
      setSortingOptions({
        ...sortingOptions,
        activeSortedColumnDirection: getNextSortedDirec(
          sortingOptions.activeSortedColumnDirection
        ),
        activeSortedColumnIndex: sortedColumnIndex,
      });
    } else {
      setSortingOptions({
        activeSortedColumnName: sortedColumnName,
        activeSortedColumnDirection: getNextSortedDirec(undefined),
        activeSortedColumnIndex: sortedColumnIndex,
      });
    }
    setHasBeenSorted(true);
  };

  return {
    getNextSortedDirec,
    sortColumn,
    getColumnIcon,
    hasBeenSorted,
    sortingOptions,
  };
};

export default useTableSort;
