
import React from 'react';
import { connect } from 'react-redux';


const TextWithoutButtons = ({ popUp }) => (
  <div className="popUpTextWithoutButton">
    {popUp.item}
  </div>
);

const mapStateToProps = state => ({
  popUp: state.popUp,
});

export default connect(mapStateToProps)(TextWithoutButtons);
