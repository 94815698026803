import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AdminGuard = ({ children }) => {
  const user = useSelector((redux) => redux.user);

  return user?.superAdmin ? children : <Navigate to="/" />;
};

export default AdminGuard;
