import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../i18n';

function useFilterTable(
  pageSize,
  callback,
  initialData,
  searchedFields = undefined,
  initialSort = undefined
) {
  const i18n = useTranslation();
  const [rows, setRows] = useState([]);
  const [nOfResults, setNOfResults] = useState(0);

  const [search, setSearch] = useState('');
  const [searchTags, setSearchTags] = useState([]);

  const [sort, setSort] = useState(initialSort);

  const [page, setPage] = useState(1);

  const { tags } = useSelector((redux) => redux.client);

  useEffect(() => {
    if (initialData) {
      createRows(getFilteredData());
    }
  }, [initialData, callback, page]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      createRows(getFilteredData());
    }
  }, [search, searchTags, sort]);

  const checkIfMatch = (field) => {
    return Array.isArray(field)
      ? field?.join().toLowerCase()?.includes(search.toLowerCase()) ?? true
      : field?.toLowerCase()?.includes(search.toLowerCase()) ?? true;
  };

  const filterByMultipleFields = (objField) => {
    return searchedFields.some((searchedField) => {
      return checkIfMatch(objField[searchedField]);
    });
  };

  const getFilteredData = () => {
    if (!initialData) {
      return [];
    }
    let filteredData = initialData.filter((item) => {
      return Array.isArray(searchedFields)
        ? filterByMultipleFields(item)
        : checkIfMatch(item[searchedFields]);
    });

    if (searchTags.length > 0) {
      let tagMatches = [];
      searchTags.forEach((searchTag) => {
        if (searchTag[0] === i18n.t(`manageEmailList.noTags`)) {
          tagMatches = tagMatches.concat(
            filteredData.filter((item) => {
              const hasNoTags = !item.tags || item.tags.length === 0;
              const isRelevantRole = item.role?.member || item.role?.admin;
              return hasNoTags && isRelevantRole;
            })
          );
        } else {
          tagMatches = tagMatches.concat(
            filteredData.filter((item) => {
              return searchTag.every((currentSearchedTag) => {
                const itemTagNames = item.tags
                  ? item.tags.map((itemTag) => {
                      return tags.find((tag) => {
                        return tag.id === itemTag;
                      }).name;
                    })
                  : [];
                return [
                  item.role?.admin && i18n.t(`manageEmailList.administrator`),
                  item.role?.member &&
                    !item.role?.admin &&
                    i18n.t(`manageEmailList.teamMember`),
                  ...itemTagNames,
                ].includes(currentSearchedTag);
              });
            })
          );
        }
      });
      filteredData = [...new Set(tagMatches)];
    }

    setNOfResults(filteredData.length);
    return filteredData;
  };

  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  const normalizateData = (data) => {
    return typeof data === 'string' || data instanceof String
      ? String(data).toLowerCase()
      : data;
  };

  const createRows = (data) => {
    if (data) {
      const auxRows = data
        // .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
        .map(callback)
        .sort((a, b) => {
          if (sort) {
            if (sort.activeSortedColumnDirection) {
              const auxA =
                Object.entries(a)[sort.activeSortedColumnIndex][1].sortValue;
              const auxB =
                Object.entries(b)[sort.activeSortedColumnIndex][1].sortValue;

              if (normalizateData(auxA) < normalizateData(auxB)) {
                return sort.activeSortedColumnDirection === 'asc' ? -1 : 1;
              }
              if (normalizateData(auxA) > normalizateData(auxB)) {
                return sort.activeSortedColumnDirection === 'asc' ? 1 : -1;
              }
              return 0;
            }
          }
          return 0;
        });
      if (
        JSON.stringify(auxRows, getCircularReplacer()) !==
        JSON.stringify(rows, getCircularReplacer())
      ) {
        setRows(auxRows);
      }
    }
  };
  return {
    rows,
    length: nOfResults,
    currentSearch: search,
    setSearch,
    setSort,
    setSearchTags,
    currentPage: page,
    setPage,
    loading: initialData?.length > 0 && rows?.length === 0 && !search,
  };
}

export default useFilterTable;
