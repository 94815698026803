import styled from '@emotion/styled';
import { css } from '@emotion/core';

const semiShort = () => css`
  width: 100px;
`;

const short = () => css`
  width: 210px;
`;

const customMid = () => css`
  width: 200px;
`;

const large = () => css`
  width: 100%;
`;

const defaultValue = () => css`
  & input {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid var(--light-grey-3);
    :focus {
      border-bottom: 1px solid var(--grey);
    }
  }
`;

const round = () => css`
  svg {
    padding-left: 15px;
  }
  & input {
    border: 1px solid var(--light-grey-3);
    border-radius: 50vh;
    :focus {
      border: 1px solid var(--grey);
    }
  }
`;

const inputStyles = {
  semiShort,
  short,
  customMid,
  large,
};

const designStyles = {
  defaultValue,
  round,
};

const SCInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  ${({ size }) => inputStyles[size]};
  ${({ design }) =>
    design ? designStyles[design] : designStyles.defaultValue};

  :focus {
    box-shadow: none;
  }

  .iconContainer {
    width: 30px;
    height: 100%;
    position: absolute;
    color: var(--bluish-grey);
  }

  .passwordVisibilityToggle {
    width: 30px;
    height: 100%;
    position: absolute;
    right: 0;
    color: var(--bluish-grey);
    cursor: pointer;
  }

  .erasableToggle {
    width: 30px;
    height: 100%;
    position: absolute;
    right: 10px;
    color: var(--bluish-grey);
    cursor: pointer;
  }

  & input {
    padding: ${({ icon }) => (icon ? '5px 10px 5px 35px' : '5px 10px')};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-family: var(--font2);
    font-size: 16px;
    width: 100%;
    color: var(--black);

    :focus {
      box-shadow: none;
      color: var(--black);
      outline: 0;

      ::placeholder {
        color: transparent;
      }
    }

    ::placeholder,
    ::-webkit-input-placeholder {
      color: var(--bluish-grey);
    }

    :-ms-input-placeholder {
      color: var(--bluish-grey);
    }

    :disabled {
      border-bottom-color: ${({ inputHidden }) => inputHidden && 'transparent'};
      background-color: ${({ inputHidden }) => inputHidden && 'transparent'};
    }
  }
`;

export default SCInput;
