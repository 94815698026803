import styled from '@emotion/styled';

const SCImageTitleText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: flex-start;

  .img-container {
    & img {
      width: ${({ imgWidth }) => imgWidth || ''};
    }
    margin-bottom: 30px;
  }

  & h3 {
    margin: 0;
    font-family: var(--font1);
    font-size: 14px;
    height: ${({ titleHeight }) => titleHeight || 'auto'};
  }

  @media (width < 769px) {
    padding: 0px 30px;
    overflow: auto;
    padding-bottom: 60px;

    .img-container {
      width: ${({ hasImgTranslation }) =>
        hasImgTranslation ? 'calc(100% + 60px)' : '100%'};
      transform: ${({ hasImgTranslation }) =>
        hasImgTranslation ? 'translateX(-30px)' : 'none'};
    }
  }
`;

export default SCImageTitleText;
