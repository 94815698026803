import React from 'react';
import YourBillingInformationCardSpain from '../../../components/organisms/YourBillingInformationCardSpain/YourBillingInformationCardSpain';
import YourBillingInformationCardMexico from '../../../components/organisms/YourBillingInformationCardMexico/YourBillingInformationCardMexico';
import YourBillingInformationCardBrasil from '../../../components/organisms/YourBillingInformationCardBrasil/YourBillingInformationCardBrasil';
import useInternationalization from '../../../hooks/Internationalization/useInternationalization';

const YourBillingInformationCardTemplate = ({ isShownInPopUp }) => {
  const { currentLocale } = useInternationalization();

  const yourBillingInformationCardTemplates = {
    es: <YourBillingInformationCardSpain isShownInPopUp={isShownInPopUp} />,
    mx: <YourBillingInformationCardMexico isShownInPopUp={isShownInPopUp} />,
    br: <YourBillingInformationCardBrasil isShownInPopUp={isShownInPopUp} />,
  };

  return yourBillingInformationCardTemplates[currentLocale] || <></>;
};

export default YourBillingInformationCardTemplate;
