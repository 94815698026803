import { faEye } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useCampaign from '../../../hooks/useCampaign/useCampaign';
import { useTranslation } from '../../../i18n';
import { TrackButton } from '../../../track';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCResultCampaignsTableCard from './ResultCampaignsTableCard.style';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import Label from '../../atoms/Label/Label';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import { getDateFormat, getDateInMs } from '../../../utils/format/dataFormat';

const ResultCampaignsTableCard = ({ phishingCampaigns }) => {
  const { getCampaignSummary } = useCampaign();
  const i18n = useTranslation();
  const navigate = useNavigate();

  const campaignSummaries = phishingCampaigns.map((campaign) =>
    getCampaignSummary(campaign)
  );

  const mapperFn = (campaign) => {
    const row = {
      campaignName: {
        type: 'component',
        value: (
          <Tooltip
            tooltip={
              <p>
                {i18n.t(
                  `phishingSimulator.campaigns.campaingTypes.${
                    campaign?.type?.split('-')[0]
                  }`
                )}
              </p>
            }>
            <p>
              {i18n.t(
                `phishingSimulator.campaigns.campaingTypes.${
                  campaign?.type?.split('-')[0]
                }`
              )}
            </p>
          </Tooltip>
        ),
        sortValue: i18n.t(
          `phishingSimulator.campaigns.campaingTypes.${
            campaign?.type?.split('-')[0]
          }`
        ),
      },
      sendMails: {
        type: 'component',
        value: <Label value={campaign.emailsSent || '0'} width="70px" />,
        sortValue: campaign.emailsSent || '0',
      },
      clicks: {
        type: 'component',
        value: (
          <Label
            value={campaign.emailsClicked || '0'}
            color={campaign.emailsClicked && 'red'}
            width="70px"
          />
        ),
        sortValue: campaign.emailsClicked || '0',
      },
      ratio: {
        type: 'component',
        value: (
          <Label
            value={`${(campaign.clickRatio.toFixed(2) * 100).toFixed(0)}%`}
            color={campaign.emailsClicked && 'red'}
            width="70px"
          />
        ),
        sortValue: campaign.clickRatio,
      },
      issued: {
        type: 'string',
        value:
          campaign.launchDate && getDateFormat(campaign.launchDate.toDate()),
        sortValue: getDateInMs(campaign.launchDate.toDate()),
      },
      onClick: () => {
        navigate(`/client/phishingsimulator/${campaign.id}`);
        TrackButton('phishingSimulator.campaigns.viewCampaign', campaign.type);
      },
    };
    return row;
  };

  const PAGE_SIZE = 10;

  const defaultSortDirection = 'desc';
  const sortOptions = {
    activeSortedColumnName: i18n.t('phishingSimulator.campaigns.issued'),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 4,
    onSort: (data) => {
      setSort(data);
    },
  };

  const { currentPage, setSearch, setSort, setPage, rows, length } =
    useFilterTable(PAGE_SIZE, mapperFn, campaignSummaries, 'name', sortOptions);

  const columns = [
    {
      name: i18n.t('phishingSimulator.campaigns.campaignName'),
      options: {
        stickyOnScroll: true,
        width: 200,
        stickyOffset: 0,
        ellipsisContent: true,
      },
    },
    {
      name: i18n.t('phishingSimulator.campaigns.emailsSent'),
    },
    {
      name: i18n.t('phishingSimulator.campaigns.emailsClicked'),
    },
    {
      name: i18n.t('phishingSimulator.campaigns.clickRatio'),
    },
    {
      name: i18n.t('phishingSimulator.campaigns.issued'),
    },
  ];

  return (
    <SCResultCampaignsTableCard>
      <SectionCard
        headerIcon={faEye}
        headerTitle={`${i18n.t(
          'phishingSimulator.resultCampaigns'
        )} (${length})`}>
        <TableContainer
          onChangeValue={setSearch}
          columns={columns}
          rows={rows}
          emptyMessage={i18n.t('phishingSimulator.campaigns.emptyMessage')}
          hasRowIcon
          hasHorizontalScroll
          sortOptions={sortOptions}
          onPageChange={(page) => setPage(page)}
          totalCount={length}
          currentPage={currentPage}
          pageSize={PAGE_SIZE}
        />
      </SectionCard>
    </SCResultCampaignsTableCard>
  );
};

export default ResultCampaignsTableCard;
