import { useSelector } from 'react-redux';
import handleUnauthorizedAccess from '../../../utils/restrictedAccessBehaviour/restrictedAccessBehaviour';

function PossibleImpersonationRouteGuard({ children }) {
  const client = useSelector((redux) => redux.client);

  return client?.status?.serviceLevel.periodicitiesInDays
    .scanDomainImpersonations
    ? children
    : handleUnauthorizedAccess();
}
export default PossibleImpersonationRouteGuard;
