import { faEarthAmericas } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import useApp from '../../../hooks/app/useApp';
import useInternationalization from '../../../hooks/Internationalization/useInternationalization';
import domainsUrls from '../../../utils/internationalizationModules/domainsUrls/domainsUrls';
import Icon from '../Icon/Icon';
import Select from '../Select/Select';
import SCRegionSelector from './RegionSelector.style';

const RegionSelector = ({ icon, text }) => {
  const { currentLocale } = useInternationalization();
  const { getRegionKey, getRegionName, possibleRegionNames } = useApp();

  const handleRegion = (e) => {
    if (
      ['es-testing', 'es-staging'].includes(
        window.location.hostname.split('.')[0]
      )
    ) {
      window.location.reload();
    } else {
      window.location.href = domainsUrls[e ?? 'es'] + window.location.pathname;
    }
  };

  return (
    <SCRegionSelector>
      <div className="region-select-container">
        {icon && (
          <Icon
            iconName={faEarthAmericas}
            size="medium"
            color="var(--bluish-grey)"
          />
        )}
        <p>{text}</p>
        <Select
          className="region-select"
          onChange={(e) => handleRegion(getRegionKey(e))}
          values={possibleRegionNames}
          selectedValue={getRegionName(currentLocale)}
          color="var(--black)"
        />
      </div>
    </SCRegionSelector>
  );
};

export default RegionSelector;
