import styled from '@emotion/styled';

const SCModalLayout = styled.div`
  height: 100%;

  > div {
    //80px = padding top + padding bottom
    max-height: calc(90vh - 80px);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 40px;

    > form {
      // Sorry for what I'm about to do
      //150px = padding top + padding bottom + title height + gap
      max-height: calc(90vh - 150px);
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 40px;

      .form-container {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
    }
  }

  .modal-card-content {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
  }

  .modal-card-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export default SCModalLayout;
