import { useLocation } from 'react-router-dom';

const useSection = () => {
  const location = useLocation();

  return {
    section: location.pathname.split('/')[2],
    subSection: location.pathname.split('/')[3],
  };
};

export default useSection;
