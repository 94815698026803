import styled from '@emotion/styled';

const SCMySessionCard = styled.div`
  .two-factor-autentication-desc {
    display: flex;
    flex-direction: column;
  }
  .my-session-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
  }
  .device-information {
    font-family: var(--font2);
    font-size: 16px;
    font-weight: 500;
  }

  .location-information {
    font-family: var(--font2);
    font-size: 16px;
    font-weight: 400;
    color: var(--grey-2);
    margin-bottom: 0px;
  }
`;

export default SCMySessionCard;
