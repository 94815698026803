const isValidCPF = (cpf) => {
  const onlyDigitsReg = /^[0-9]+$/;

  // Verificar la longitud del CNPJ y que sólo contenga dígitos numéricos
  if (cpf.length !== 11 || !onlyDigitsReg.test(cpf)) {
    return false;
  }

  // Variables to check cpf valid (sum and rest)
  let sum = 0;
  let rest = 0;

  // Checking for "null" CPF
  if (cpf === '00000000000') {
    return false;
  }

  // Checking first control digit.
  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;

  if (rest !== parseInt(cpf.substring(9, 10))) {
    return false;
  }

  // Checking second control digit
  sum = 0;

  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;

  if (rest !== parseInt(cpf.substring(10, 11))) {
    return false;
  }

  return true;
};

export default isValidCPF;
