import styled from '@emotion/styled';
import { css } from '@emotion/core';

const small = () => css`
  width: 10px;
  height: 10px;
`;

const medium = () => css`
  width: 17px;
  height: 17px;
`;

const semiLarge = () => css`
  width: 22px;
  height: 22px;
`;

const navIcon = () => css`
  width: 30px;
  height: 30px;
`;

const large = () => css`
  width: 50px;
  height: 50px;
`;

const extraLarge = () => css`
  width: 75px;
  height: 75px;
`;

const topMenuIcon = () => css`
  width: 20px;
  height: 20px;
`;

const sectionHeaderIcon = () => css`
  width: 25px;
  height: 25px;
`;

const inputStyles = {
  small,
  medium,
  semiLarge,
  large,
  extraLarge,
  navIcon,
  topMenuIcon,
  sectionHeaderIcon,
};

const SCIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: ${({ height }) => height || '100%'};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  color: ${({ color }) => color || 'inherit'};
  border: ${({ border }) => border || 'none'};
  border-radius: ${({ borderRadius }) => borderRadius || 'none'};
  padding: ${({ padding }) => padding || '0'};
  border-color: ${({ color }) => color || 'inherit'};

  & svg {
    ${({ size }) => inputStyles[size]};
  }
`;

export default SCIcon;
