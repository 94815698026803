import styled from '@emotion/styled';

const SCContractSubscription = styled.div`
  /* NUEVO DISEÑO*/
  display: flex;
  flex-direction: column;
  gap: 40px;

  width: 100%;

  p,
  h3 {
    line-height: 1;
  }

  .subscription-container {
    display: flex;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;

    .configure-subscription-container,
    .confirm-subscription-container {
      display: flex;
      flex-direction: column;
      min-height: 725px;
      gap: 40px;
      padding: 30px;

      .title {
        display: flex;
        align-items: center;
        gap: 8px;

        color: var(--bluish-grey);

        > h3 {
          color: inherit;
          font-weight: 400;
        }
      }
    }

    .configure-subscription-container {
      flex: 60%;
      background-color: var(--white);

      .configure-texts-container {
        display: flex;
        flex-direction: column;
        gap: 30px;

        > p {
          line-height: 1.25;
        }
      }

      .configure-contract-container {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .configure-contract-quantity-inputs {
          display: flex;
          flex-direction: column;
          gap: 30px;

          .configure-contract-quantity-input-container {
            display: flex;
            flex-wrap: wrap;
            column-gap: 60px;
            row-gap: 30px;
          }

          .configure-contract-quantity-input {
            display: flex;
            align-items: center;
            gap: 12px;

            > p {
              color: var(--bluish-grey);
              font-weight: 500;
            }
          }
        }
      }

      .questions-wrapper {
        margin-top: auto;

        p,
        a {
          font-weight: 500;
          color: var(--bluish-grey);
        }

        .questions-container {
          margin-top: 10px;
          display: flex;
          gap: 15px;
          flex-wrap: wrap;

          button {
            display: flex;
            align-items: center;
            gap: 5px;

            color: var(--bluish-grey);
            background-color: transparent;
          }
        }
      }
    }

    .confirm-subscription-container {
      flex: 40%;
      background-color: var(--bluish-grey-4);
      justify-content: space-between;

      .confirm-subscription-prices {
        display: flex;
        flex-direction: column;
        gap: ${({ isMonthlySubscriptionAllowed }) =>
          isMonthlySubscriptionAllowed ? '20px' : '40px'};

        .confirm-subscription-content {
          display: flex;
          flex-direction: column;
          gap: 40px;

          .confirm-subscription-frequency-wrapper {
            display: flex;
            flex-direction: column;

            .confirm-subscription-hardcoded-discount {
              text-align: right;
              color: var(--red);
            }

            .confirm-subscription-intervals {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-evenly;
              gap: 20px;
              position: relative;

              .confirm-subscription-interval {
                display: flex;
                flex-direction: column;
                width: 100%;
              }
            }
          }

          .confirm-subscription-total-price-detail {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .confirm-subscription-title-description-price {
              display: flex;
              gap: 10px;
              justify-content: space-between;

              .confirm-subscription-discount {
                color: var(--red);
              }

              .confirm-subscription-title-description {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .confirm-subscription-description {
                  font-size: 13px;
                  color: var(--bluish-grey);
                }
              }
            }

            > hr {
              margin: 0;
              border: 0;
              border-top: 1px solid var(--bluish-grey);
            }
          }

          .skeleton-buttons-container {
            display: flex;
            gap: 20px;
            margin-top: 20px;
          }
        }
      }

      .checkboxes-button-container {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .checkboxes-text-container {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .checkbox-text {
            display: flex;
            gap: 10px;
            align-items: center;

            input[type='checkbox'] {
              border: 1px solid var(--grey);
              width: 30px;
              height: 30px;
              accent-color: var(--red);
            }
          }
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .call-us-button {
      background-color: transparent;

      > p {
        color: var(--red);
      }
    }
  }

  @media (width < 769px) {
    .subscription-container {
      flex-direction: column;

      .configure-subscription-container,
      .confirm-subscription-container {
        min-height: 100%;
      }
    }
  }
`;

export default SCContractSubscription;
