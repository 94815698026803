import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from '../../../i18n';
import SCStripeSaveCardForm from './StripeEditCardForm.style';
import Button from '../../atoms/Button/Button';

import { showPopUp } from '../../../redux/actions/popUp.actions';

import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import usePaymentMethods from '../../../hooks/paymentMethods/usePaymentMethods';

const StripeEditCardForm = () => {
  const i18n = useTranslation();
  const { getClientCards } = usePaymentMethods();

  const user = useSelector((redux) => redux.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState();
  const [surname, setSurname] = useState();

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const PAYMENT_ELEMENT_OPTIONS = {
    terms: {
      card: 'never',
    },
    fields: {
      billingDetails: {
        address: {
          country: 'never',
        },
      },
    },
  };

  const stripeConfirmSetup = async () => {
    const { error } = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/client/poc-stripe-save-card',
        payment_method_data: {
          billing_details: {
            name: `${name.trim()} ${surname.trim()}`,
            email: user.email,
            address: {
              country: 'ES',
            },
          },
        },
      },
      redirect: 'if_required',
    });

    if (error) {
      setErrorMessage(error.message);
      // setLoading(false);
    } else {
      // to-do
      // await setNewPaymentMethodAsDefault
      await getClientCards();
      dispatch(showPopUp(null));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    // 1. Validar state companyID
    if (name && surname) {
      await stripeConfirmSetup();
    }

    setLoading(false);
  };

  return (
    <SCStripeSaveCardForm onSubmit={handleSubmit}>
      <div className="name-surname-container">
        <div className="label-input-container">
          <label htmlFor="name">
            {i18n.t('addCreditCard.form.cardholder')}
          </label>
          <div className="label-inputs">
            <input
              type="text"
              placeholder={i18n.t('addCreditCard.form.name')}
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              placeholder={i18n.t('addCreditCard.form.surname')}
              id="surname"
              name="surname"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
        </div>
      </div>

      <PaymentElement options={PAYMENT_ELEMENT_OPTIONS} />

      <Button
        type="submit"
        color="red"
        size="full"
        text={
          loading ? (
            <SpinnerText text={i18n.t('common.loading')} />
          ) : (
            i18n.t('addCreditCard.form.editCompanyCard')
          )
        }
        disabled={!stripe || !name || !surname || loading}
      />

      {errorMessage && <div>{errorMessage}</div>}
    </SCStripeSaveCardForm>
  );
};

export default StripeEditCardForm;
