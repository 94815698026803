import styled from '@emotion/styled';

const SCRegionBanner = styled.div`
  display: flex;
  gap: 5px;
  background: var(--light-grey);
  padding: 18px 15px 5px 15px;
  border-radius: 5px;
  p {
    font-family: var(--font1);
    font-size: 14px;
    color: var(--bluish-grey);
    font-weight: 400;
    text-align: left;
    span {
      font-weight: 500;
    }
    b {
      font-weight: 600;
    }
  }

  .region-text-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 5px;
  }
`;

export default SCRegionBanner;
