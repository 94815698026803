import styled from '@emotion/styled';

const SCStripeEditCard = styled.div`
  .StripeEditCard_ContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 10px;

    form {
      width: 100%;
    }

    .StripeEditCard_Spinner {
      margin-bottom: 30px;
    }
  }
`;

export default SCStripeEditCard;
