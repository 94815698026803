export const getSPFStatus = (SPFStatus) => {
  const SPF_STATUS = {
    ACTIVE: 'weak',
    STRONG: 'strong',
  };

  return SPF_STATUS[SPFStatus] ?? 'notSet';
};

export const getDMARCStatus = (DMARCStatus) => {
  const DMARC_STATUS = {
    ACTIVE: 'weak',
    STRONG: 'compliant',
    NEED_SPF: 'needSPF',
  };

  return DMARC_STATUS[DMARCStatus] ?? 'notSet';
};
