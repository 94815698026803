import { firestore } from '../../firebase';
import { PROTECTED_DEVICES_STATUS } from '../../utils/constants/protectedDevices';
import { GET_PROTECTED_DEVICES } from './types';

const setProtectedDevicesListener = (clientId, dispatch) => {
  const { PARTIALLY_INSTALLED, INSTALLED } = PROTECTED_DEVICES_STATUS;

  firestore
    .collection('clients')
    .doc(clientId)
    .collection('protection_tools')
    .where('status', 'in', [PARTIALLY_INSTALLED, INSTALLED])
    .onSnapshot((snapshot) => {
      const clientDevices = [];

      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          const device = { id: doc.id, ...doc.data() };
          clientDevices.push(device);
        });
      }

      dispatch({
        type: GET_PROTECTED_DEVICES,
        payload: [...clientDevices],
      });
    });
};

export default setProtectedDevicesListener;
