import { faMobile } from '@fortawesome/pro-regular-svg-icons';
import React, { useContext } from 'react';
import { I18nContext, useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCYourInterfaceInformationCard from './YourInterfaceInformationCard.style';
import useApp from '../../../hooks/app/useApp';
import Select from '../../atoms/Select/Select';
import imageFlags from '../../../utils/internationalizationModules/ImageFlags/ImageFlags';

const YourInterfaceInformationCard = () => {
  const { getLanguageKey, possibleLanguageNames, getLanguagename } = useApp();
  const i18n = useTranslation();
  const { setLanguageCustom } = useContext(I18nContext);

  return (
    <SCYourInterfaceInformationCard>
      <SectionCard
        headerIcon={faMobile}
        headerTitle={i18n.t('profile.yourInterface')}>
        <div className="InterfaceInformation_MainContainer">
          <div className="InterfaceInformation_Action">
            <div>
              <p>{i18n.t('profile.interface.language')}</p>
            </div>
            <div>
              <Select
                icon
                img={imageFlags[i18n.getLanguage()]}
                onChange={(e) => setLanguageCustom(getLanguageKey(e))}
                values={possibleLanguageNames}
                selectedValue={getLanguagename(i18n.getLanguage())}
                design="round"
              />
            </div>
          </div>
        </div>
      </SectionCard>
    </SCYourInterfaceInformationCard>
  );
};
export default YourInterfaceInformationCard;
