import styled from '@emotion/styled';

const SCRadarSpinner = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;
  .radar-container {
    display: flex;
    justify-content: center;
    height: 105px;
  }
  .pin {
    position: relative;
    top: 30px;
    width: 45px;
    height: 45px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    left: 30px;
    border: solid 1px #e0e0e0;
    transform: translateX(-50%);
  }
  @-webkit-keyframes radar {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @-moz-keyframes radar {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @-o-keyframes radar {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @keyframes radar {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @-webkit-keyframes pulse {
    0% {
      opacity: 1;
      width: 100%;
      height: 100%;
    }
    100% {
      opacity: 0;
      width: 500%;
      height: 500%;
    }
  }
  @-moz-keyframes pulse {
    0% {
      opacity: 1;
      width: 100%;
      height: 100%;
    }
    100% {
      opacity: 0;
      width: 500%;
      height: 500%;
    }
  }
  @-o-keyframes pulse {
    0% {
      opacity: 1;
      width: 100%;
      height: 100%;
    }
    100% {
      opacity: 0;
      width: 500%;
      height: 500%;
    }
  }
  @keyframes pulse {
    0% {
      opacity: 1;
      width: 100%;
      height: 100%;
    }
    100% {
      opacity: 0;
      width: 200%;
      height: 220%;
    }
  }
  .ring-2 {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    border: solid 1px #e0e0e0;
    overflow: hidden;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: radar 3.25s linear infinite;
  }
  .ring-2::before {
    content: '';
    position: absolute;
    width: 37.5px;
    height: 37.5px;
    top: 50%;
    left: 50%;
    background-image: linear-gradient(
      230deg,
      rgba(188, 18, 36, 1),
      rgba(188, 18, 36, 0) 60%
    );
    transform-origin: top left;
    transform: scale(-1, 1.7) rotate(45deg);
    border-top: solid 1px #bc1224;
    overflow: hidden;
    background-repeat: no-repeat;
  }
  .ring-2::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #e0e0e0;
    border-radius: 50%;
  }
  .pin::before,
  .ring::before,
  .ring::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: solid 1px #e0e0e0;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    opacity: 0;
  }
  .ring::after {
    animation: pulse 2.5s ease infinite;
  }
`;

export default SCRadarSpinner;
