import styled from '@emotion/styled';

const SCSupplierAnalysisCard = styled.div`
  .ss-analysis {
    .analysis-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      margin-bottom: 40px;

      > h3 {
        margin-bottom: 0;
        font-family: var(--font2);
        font-size: 20px;
        font-weight: 500;
      }
    }

    .analysis-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: row;

        > div {
          width: 390px;
        }
      }
    }

    .column-content {
      margin-top: 25px;

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 25px;

        > span:first-of-type {
          margin-right: 20px;
          font-family: var(--font2);
          font-size: 14px;
          font-weight: 300;
          line-height: normal;
        }

        > span:last-of-type {
          margin-left: auto;
        }
      }
    }

    & h4 {
      margin: 2px 0 0 0;
      font-size: 14px;
      font-weight: 500;
      font-family: var(--font2);
      height: 35px;
    }

    & svg {
      margin: 0 10px 0 0;
      font-size: 20px;
      color: var(--bluish-grey);
    }
  }

  .analysis-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    > h3 {
      color: var(--bluish-grey);
    }
  }
`;

export default SCSupplierAnalysisCard;
