import { GET_PAYMENTMETHODS } from './types';

export const setPaymentMethodsAction = (paymentMethods) => (dispatch) => {
  dispatch({
    type: GET_PAYMENTMETHODS,
    payload: paymentMethods,
  });
};

export default true;
