import React from 'react';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import SCSpfAndDmarcCard from './SpfAndDmarcCard.style';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SpfDmarcPhoto from '../../../img/SPFDMARC.png';
import useCollapsableCardStatus from '../../../hooks/collapsableCardStatus/useCollapsableCardStatus';
import { useTranslation } from '../../../i18n';
import useSection from '../../../hooks/useSection/useSection';

const SpfAndDmarcCard = () => {
  const i18n = useTranslation();
  const { section } = useSection();

  const { collapsableCardOpened, switchCollapsableCardStatus } =
    useCollapsableCardStatus(`${section}.what-is-dmarc-and-spf-card.hide`);

  return (
    <SCSpfAndDmarcCard>
      <SectionCard
        headerTitle={i18n.t('configureEmailSecurity.explainTitle')}
        headerIcon={faCircleInfo}
        isCollapsed={collapsableCardOpened}
        headerCollapsableButtonOnClick={() => switchCollapsableCardStatus()}>
        <div className="SPfAndDmarc_Container">
          <div className="SPfAndDmarc_TextContainer">
            <div className="SPfAndDmarc_Text">
              <b>{i18n.t('configureEmailSecurity.explainSPFTitle')}</b>
              <p>{i18n.t('configureEmailSecurity.explainSPF')}</p>
            </div>
            <div className="SPfAndDmarc_Text">
              <b>{i18n.t('configureEmailSecurity.explainDKIMTitle')}</b>
              <p>{i18n.t('configureEmailSecurity.explainDKIM')}</p>
            </div>
            <div className="SPfAndDmarc_Text">
              <b>{i18n.t('configureEmailSecurity.explainDMARCTitle')}</b>
              <p>{i18n.t('configureEmailSecurity.explainDMARC')}</p>
            </div>
          </div>
          <div className="SPfAndDmarc_ImageContainer">
            <img src={SpfDmarcPhoto} alt="Email prtections" />
          </div>
        </div>
      </SectionCard>
    </SCSpfAndDmarcCard>
  );
};

export default SpfAndDmarcCard;
