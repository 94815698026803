import styled from '@emotion/styled';

export const SCSummaryCard = styled.div`
  .summary-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
  }

  .summary-text {
    line-height: 1.2rem;
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
  }

  .summary-buttons {
    display: flex;
    column-gap: 16px;
  }
`;

export const Stat = styled.div`
  color: black;
  font-family: 'Karla', Arial, sans-serif;
  font-weight: 400;
  font-size: 36px;
  display: flex;
  flex: 0 1 25%;
  gap: 16px;

  p {
    font-family: var(--font2);
    font-weight: 500;
    color: var(--bluish-grey);
    margin-bottom: 0;
    font-size: 30px;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    color: var(--bluish-grey);
    line-height: 1.15;
    white-space: pre-line;
  }

  svg {
    font-size: 30px;
    color: var(--bluish-grey);
  }
`;

export const TextBold = styled.div`
  display: flex;
  align-items: center;
  font-family: var(--font2);
  font-size: 16px;
  color: var(--bluish-grey);

  b {
    margin-left: 4px;
  }
`;
