import axios from 'axios';
import { firestore } from '../../firebase';

export const getPaidSubscription = (clientId) => {
  return axios.get(`/api/subscriptions?client=${clientId}&product=paying`);
};

export const getClientSecret = () => {
  return axios.get('/api/subscriptions/client-secret');
};

export const startTrialSubscription = () => {
  return axios.post('/api/subscriptions/trial');
};

export const getClientCards = () => {
  return axios.get('/api/subscriptions/cards');
};

export const getSubscriptionPrices = () => {
  return axios.get('/api/subscriptions/prices');
};

export const getAvailablePaymentFrequencies = async () => {
  const doc = await firestore.collection('config').doc('payments').get();

  return doc.data();
};

export const addLicenses = ({ clientId, nbLicenses }) => {
  return axios.post('/api/subscriptions/partners/extend', {
    clientId,
    nbLicenses,
  });
};

export const extendedSubscription = ({ clientId, nbLicenses }) => {
  return axios.post('/api/subscriptions/partners/renewal', {
    clientId,
    nbLicenses,
  });
};
