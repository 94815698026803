import styled from '@emotion/styled';

const SCNotFound = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  .not-found-card {
    background-color: var(--white);
    border-radius: var(--section-radius);
    padding: 40px;
    width: 420px;
    position: relative;
    box-shadow: 2px 2px 20px #79889714;
  }

  .card-content-container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    h1 {
      font-size: 80px;
      color: var(--red);
    }

    h2 {
      font-size: 25px;
      font-weight: 600;
    }

    p {
      font-family: var(--font1);
      font-weight: 600;
    }
  }
`;

export default SCNotFound;
