import styled from '@emotion/styled';

const SCDeleteAccountCard = styled.div`
  & p {
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 0;
  }

  button {
    margin-top: 30px;
    width: 200px;
  }
`;

export default SCDeleteAccountCard;
