import styled from '@emotion/styled';

const SCPartnerDashboard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    font-family: var(--font1);
    font-size: 30px;
    color: #111927;
    font-weight: 600;
  }

  h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.3;
    margin-left: 0;
    margin-bottom: 20px;
    color: #111927;
  }

  p {
    font-size: 16px;
    color: var(--bluish-grey);
  }

  .filter-container {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 16px;
    padding: 10px 30px;
    background-color: var(--white);
    color: #111927;
    border-radius: var(--section-radius);
    min-height: 55px;

    .filter-info {
      display: flex;
      align-items: center;
      gap: 8px;

      > p {
        font-size: 16px;
        color: var(--bluish-grey);
      }
    }

    .filter-operator-tag {
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: var(--partner-primary-light);
      color: var(--partner-primary);
      padding: 2px 15px;
      border-radius: 50vh;
      text-align: center;

      .close-button {
        color: var(--partner-primary-light);
        background-color: transparent;
      }
    }

    .icon-wrapper {
      background-color: var(--partner-primary);
      height: 16px;
      aspect-ratio: 1/1;
      display: grid;
      place-content: center;
      border-radius: 5000px;
    }
  }

  .widgets-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    .widget-item {
      padding: 30px;
      background-color: var(--white);
      border-radius: var(--section-radius);
      display: flex;
      gap: 20px;
      align-items: center;

      .rounded-icon {
        padding: 10px;
        background-color: var(--partner-primary-light);
        border-radius: 20px;
      }

      .widget-texts {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        span {
          font-family: var(--font1);
          font-size: 16px;
          font-weight: 400;
          color: var(--bluish-grey);
        }

        .ant-skeleton-title {
          height: 30px;
        }

        .ant-skeleton-paragraph > li {
          height: 20px;
        }
      }
    }
  }

  .charts-table-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    > div {
      height: auto;
    }

    .bar-chart-skeleton,
    .donut-chart-skeleton {
      align-items: center;
      display: flex;

      .ant-skeleton-paragraph > li {
        height: 30px;
      }
    }

    .bar-chart-skeleton {
      height: 215px;
    }

    .donut-chart-skeleton {
      height: 170px;
    }

    .clients-table {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 3;
      grid-column-end: 4;

      .ant-table-thead {
        .ant-table-cell {
          color: var(--bluish-grey);
          font-weight: 500;
          font-family: var(--font1);
          font-size: 16px;
        }

        th.ant-table-column-sort {
          background-color: var(--light-grey);
        }
      }

      .ant-table-row {
        color: #111927;
      }

      .ant-table-column-sorter-down.active,
      .ant-table-column-sorter-up.active {
        color: var(--partner-primary);
      }
    }
  }

  @media (max-width: 1550px) {
    .charts-table-container {
      grid-template-columns: 1fr 1fr;

      .clients-table {
        grid-row-start: 3;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }

  @media (max-width: 950px) {
    .charts-table-container {
      grid-template-columns: none;

      .clients-table {
        grid-row-start: auto;
        grid-row-end: auto;
        grid-column-start: auto;
        grid-column-end: auto;
      }
    }
  }

  @media (max-width: 768px) {
    .filter-container {
      .filtering-by {
        display: none;
      }
    }

    .widgets-container {
      grid-template-columns: none;
    }
  }

  @media (width < 550px) {
    .apexcharts-legend {
      justify-content: flex-start !important;
      top: auto !important;
    }
  }
`;

export default SCPartnerDashboard;
