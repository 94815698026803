import styled from '@emotion/styled';

const SCQualificationFormPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--application-background-color);
  overflow-x: hidden;

  .santander-logo {
    height: 25px;
    position: fixed;
    top: 30px;
    left: 30px;
  }

  .Poc_MainContainer {
    display: flex;
    max-width: var(--section-width);
    width: var(--section-width);
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    justify-content: space-evenly;
    margin: 13vh 0;

    .Poc_Header {
      width: 100%;
      text-align: center;

      h1 {
        color: #ea1d25;
      }
    }
    .Poc_Content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .Poc_InitialPage {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 30px;
        padding: 50px;
        border-radius: 10px;
        background-color: var(--white);
        box-shadow: 2px 2px 20px 0px rgba(121, 136, 151, 0.15);

        h1 {
          font-weight: 600 !important;
          color: #ea1d25;
        }

        h2 {
          font-weight: 600 !important;
        }

        .Poc_InitialContent_Paragraphs {
          display: flex;
          flex-direction: column;
          gap: 15px;

          p {
            color: var(--bluish-grey);
            font-size: 18px;
          }
        }

        > form {
          .name-surname-container {
            display: flex;
            gap: 10px;
            width: 100%;

            .ant-form-item {
              width: 100%;
            }
          }
        }
      }

      .Poc_StripeSaveCard {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 18px;
        text-align: center;

        h2 {
          font-weight: 600 !important;
          color: var(--bluish-grey);
        }

        p {
          color: var(--bluish-grey);
        }
      }
    }

    .Poc_Footer {
      min-height: 100px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;

      .call-us-button {
        background-color: transparent;
        font-size: 20px;
        color: var(--red);
        outline: none;
        width: fit-content;
        padding: 0 20px;
      }

      .Poc_Footer_buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;

        .callMeButton {
          color: var(--red);

          :hover {
            color: var(--red);
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    .Poc_MainContainer {
      width: 100%;
      padding: 20px;

      .Poc_Footer {
        .Poc_Footer_buttons {
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }
`;

export default SCQualificationFormPage;
