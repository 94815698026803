import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from '../../../i18n';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const SCWhatIncludesMyPlan = styled.div`
  h1 {
    text-align: center;
  }

  .functionalities-included-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .functionality-container {
      display: flex;
      align-items: center;
      text-align: left;
      gap: 10px;

      > p {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
`;

const WhatIncludesMyPlan = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();

  const FUNCTIONALITIES_KEYS = [
    'continuousCybersecurityMonitoring',
    'protectionForYourDevices',
    'protectionForNavigation',
    'protectionForEmail',
    'proactiveThreatProtection',
    'updatesAndSupport',
    'awarenessOfYourEmployees',
    'corporateEmailMonitoring',
    'websiteMonitoring',
    'dataBreackMonitoring',
    'websiteCloneDetection',
    'providerSecurityCheck',
  ];

  return (
    <ModalLayout>
      <SCWhatIncludesMyPlan>
        <h1>{i18n.t('contractSubscription.content.whatIsIncluded')}</h1>

        <div className="modal-card-content functionalities-included-container">
          {FUNCTIONALITIES_KEYS.map((functionalityKey) => (
            <div className="functionality-container">
              <Icon iconName={faCheckCircle} color="var(--green)" />

              <p>
                {i18n.t(
                  `contractSubscription.content.whatIsIncludedItems.${functionalityKey}`
                )}
              </p>
            </div>
          ))}
        </div>
        <div className="modal-card-buttons">
          <Button
            text={i18n.t('common.back')}
            onClick={() => dispatch(showPopUp(null))}
            size="full"
            color="white"
          />
        </div>
      </SCWhatIncludesMyPlan>
    </ModalLayout>
  );
};

export default WhatIncludesMyPlan;
