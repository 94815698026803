import styled from '@emotion/styled';

const SCQuantityInput = styled.div`
  padding: 2px 5px;
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
  background-color: ${({ disabled }) =>
    disabled ? 'var(--light-grey)' : 'var(--green)'};
  color: ${({ disabled }) =>
    disabled ? 'var(--bluish-grey)' : 'var(--white)'};
  border-radius: 5px;

  button {
    padding: 0px 7.5px;
    background: transparent;
    text-align: center;
    color: ${({ disabled }) =>
      disabled ? 'var(--bluish-grey)' : 'var(--white)'};
    font-size: 16px;
    font-weight: 700;
    user-select: none;
    outline: none;

    :disabled {
      cursor: default;
    }
  }

  input {
    width: 40px;
    height: 20px;
    background-color: transparent;
    text-align: center;
    font-size: 16px;

    :not([disabled]) {
      border-bottom: ${({ disabled }) =>
        disabled ? '1px solid var(--grey-2)' : '1px solid var(--white)'};
    }

    :focus {
      outline: none;
    }
  }
`;

export default SCQuantityInput;
