import styled from '@emotion/styled';

const SCConfigureDmarcSteps = styled.div`
  p {
    margin: 0;
    font-size: 16px;
  }

  .ConfigureDmarcStep_Header {
    font-size: 20px;
  }

  .question {
    display: flex;
    margin-top: 40px;
    font-weight: 700;

    svg {
      margin-right: 8px;
    }
  }

  .questionActions {
    margin-top: 40px;

    Button {
      margin-right: 15px;
    }

    select,
    input {
      max-width: 175px;
    }
  }

  .dnsProviders_Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 30px;
    width: 100%;
    max-width: 400px;

    a {
      margin: 0;
    }

    img {
      width: 150px;
      margin: 10px 0px;
    }
  }
`;

export default SCConfigureDmarcSteps;
