import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import { useNavigate, useParams } from 'react-router-dom';
import NewSignUp from './NewSignUp';
import Spinner from '../../components/Misc/Spinner';
import { I18nContext } from '../../i18n';
import { ErrorKey, ErrorKeyCustomized } from '../../track';
import SCSignUp from './signUp.style';

const SetupAccount = ({ firebase, user, auth, client }) => {
  const { i18n } = useContext(I18nContext);
  const navigate = useNavigate();
  // Call to server in order to create the new client.

  const saveNewClient = (account, idToken, code, personalCode) => {
    axios
      .post('/save-new-client', {
        account,
        language: i18n.getLanguage(),
        idToken,
        code,
        personalCode,
      })
      .then(async () => {
        if (firebase.auth().currentUser) {
          await axios.post('/auth/created');

          if (!firebase.auth().currentUser.emailVerified) {
            firebase.auth().languageCode = i18n.getLanguage();
            firebase
              .auth()
              .currentUser.sendEmailVerification({
                url: window.location.origin,
              })
              .then(() => {
                setCreating(false);
                navigate('/account-created');
              })
              .catch((e) => {
                setCreating(false);
                console.log(e, e.message);
                ErrorKeyCustomized({
                  i18nKey: 'errors.thereWasAnErrorWith',
                  i18nOptions: { errormessage: e.message },
                  label: 'saveNewClient1',
                });
                // ErrorKey('errors.thereWasAnErrorWith', 'saveNewClient1', { errormessage: e.message });
              });
          } else {
            setCreating(false);
            window.location.href = '/client';
          }
        } else {
          firebase
            .auth()
            .signInWithEmailAndPassword(account.email, account.password)
            .then(async () => {
              // if (code) {
              await axios.post('/auth/created');
              // }

              firebase.auth().languageCode = i18n.getLanguage();
              firebase
                .auth()
                .currentUser.sendEmailVerification({
                  url: window.location.origin,
                })
                .then(() => {
                  setCreating(false);
                  navigate('/account-created');
                })
                .catch((e) => {
                  console.log(e, e.message);
                  ErrorKeyCustomized({
                    i18nKey: 'errors.thereWasAnErrorWith',
                    i18nOptions: { errormessage: e.message },
                    label: 'saveNewClient2',
                  });
                  // ErrorKey('errors.thereWasAnErrorWith', 'saveNewClient2', { errormessage: e.message });
                });
            });
        }
      })
      .catch((err) => {
        setCreating(false);
        // Si ya existía esa cuenta de Google, no mostrar error. La aplicación directamente le hace log-in.
        if (account.signUpWithGoogle && err.response.status === 409) {
          console.log(err.response?.data);
          // Si es un error 409, el usuario ya está registrado. Mostrar mensaje de alreadyRegistered.
        } else if (err.response.status === 409) {
          console.log(err.response?.data);
          ErrorKeyCustomized({
            i18nKey: 'errors.alreadyRegistered',
            label: 'saveNewClient3',
          });
        } else {
          console.log(err);
          ErrorKeyCustomized({
            i18nKey: 'errors.thereWasAnError',
            label: 'saveNewClient3',
          });
        }
      });
  };

  const [activeStep, setActiveStep] = useState(1);
  const [account, setAccount] = useState({});
  const [company, setCompany] = useState({ address: {} });
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState();
  const [creating, setCreating] = useState(false);
  const [clientLicensee, setClientLicensee] = useState();
  const [promoCode, setPromoCode] = useState();
  const [personalCode, setPersonalCode] = useState();
  const { sessionId, code } = useParams();

  useEffect(() => {
    const userEmail = auth && auth.currentUser && auth.currentUser.email;

    if (sessionId) {
      fetchSession(sessionId, code);
    }

    if (auth && userEmail) {
      setAccount({ ...account, email: userEmail });
    }

    if (user && client && auth && auth.currentUser) {
      window.location.href = '/';
    }
  }, []);

  // if client already exists and is logged-in redirect him
  const fetchSession = async (session, code) => {
    setLoading(true);
    if (
      session !== 'broker' &&
      session !== 'code' &&
      session !== 'personal-code'
    ) {
      // allow signup without code
    } else {
      if (session === 'broker') {
        await axios
          .get('/check_partner')
          .then((res) => {
            const { partner } = res.data;
            setAccount({
              firstName: partner.name,
              lastName: partner.lastName,
              email: partner.email,
            });
          })
          .catch((err) => {
            console.log(err);
            navigate('/get-access');
          });
      }

      if (session === 'code') {
        axios
          .get(`/promotional-codes/${code}`)
          .then((res) => {
            setPromoCode(code);

            // I think that this is not used right now but it could be used in a future - DO NOT DELETE THIS COMMENT
            // setClientLicensee(res.data.licensee);
          })
          .catch(() => {
            ErrorKey('setup.getAccess.errorPromotionalCode');
            navigate('/setup-account');
          });
      }

      // TODO - THAT LOGIC NEEDS TO BE MOVED TO THE BACK-END (ETHICAL HACKING)
      if (session === 'personal-code') {
        axios
          .get(`/check-personal-code/${code}`)
          .then((res) => {
            setCompany(res.data.company);
            setDomains(res.data.monitoredDomains);
            setClientLicensee(res.data.licensee);
            setPersonalCode(code);
          })
          .catch(() => {
            if (document.referrer.indexOf('success=false') < 0) {
              ErrorKey('setup.getAccess.errorPromotionalCode');
              window.location.href = `${document.referrer}?success=false`;
            }
          });
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (client && firebase.auth().currentUser.emailVerified) {
      window.location.href = '/client';
    }
  }, [client]);

  // Process to create the new account.
  const createAccount = () => {
    setCreating(true);
    // not logged -in (create account)
    if (!auth || (auth && !auth.currentUser)) {
      saveNewClient(account, undefined, promoCode, personalCode);
    } else {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          saveNewClient(account, idToken, promoCode, personalCode);
        });
    }
  };

  const setCurrentStep = (step) => {
    setActiveStep(step);
  };

  let currentStep = (
    <NewSignUp
      setAccount={setAccount}
      currentUser={auth.currentUser}
      userEmail={account.email}
      setCurrentStep={setCurrentStep}
      account={account}
      createAccount={createAccount}
      hideRegionBanner={code === 'wealthmanagement'}
    />
  );
  switch (activeStep) {
    case 1:
      currentStep = (
        <NewSignUp
          setAccount={setAccount}
          currentUser={auth.currentUser}
          userEmail={account.email}
          setCurrentStep={setCurrentStep}
          account={account}
          createAccount={createAccount}
          hideRegionBanner={code === 'wealthmanagement'}
        />
      );
      break;
    /**
     * ***********************************
     */

    default:
      currentStep = (
        <NewSignUp
          setAccount={setAccount}
          currentUser={auth.currentStep}
          userEmail={account.email}
          setCurrentStep={setCurrentStep}
          account={account}
          hideRegionBanner={code === 'wealthmanagement'}
        />
      );
  }

  // When account has createNewAccount and an email,
  useEffect(() => {
    if (account?.createNewAccount && account?.email) {
      // Set the signUpWithGoogle and createNewAccount to false.
      setAccount({ ...account, createNewAccount: false });
      // Start the process to create new account.
      createAccount();
    }
  }, [account]);

  return (
    <SCSignUp>
      <Spinner loading={loading || creating} />
      {currentStep}
    </SCSignUp>
  );
};

const mapStateToProps = (state) => ({
  firebase: state.firebase,
  auth: state.auth,
  user: state.user,
  client: state.client,
});

export default connect(mapStateToProps, {})(SetupAccount);
