import { faCheck, faHyphen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import SCIndeterminateCheckbox from './IndeterminateCheckbox.style';

export const CHECKBOX_STATES = {
  checked: 'checked',
  indeterminate: 'indeterminate',
  empty: 'empty',
};

/**
 * @param {*} state //One of CHECKBOX_STATES
 * @returns
 */
const IndeterminateCheckbox = ({ state, onChange, onClick }) => {
  const inputIcons = {
    checked: faCheck,
    indeterminate: faHyphen,
  };

  return (
    <SCIndeterminateCheckbox
      onClick={onClick}
      empty={state === CHECKBOX_STATES.empty}>
      <input type="checkbox" onChange={onChange} />
      {state !== CHECKBOX_STATES.empty && (
        <span className="checkbox-indicator">
          <FontAwesomeIcon
            icon={inputIcons[state]}
            color="var(--bluish-grey)"
          />
        </span>
      )}
    </SCIndeterminateCheckbox>
  );
};

export default IndeterminateCheckbox;
