import styled from '@emotion/styled';

const SCEmptyCardContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  > p {
    color: var(--bluish-grey);
    text-align: center;
  }
`;

export default SCEmptyCardContent;
