import React from 'react';
import { faEnvelopeDot } from '@fortawesome/pro-regular-svg-icons';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCAffectedMonitoredEmailsCard from './AffectedMonitoredEmailsCard.style';
import useBreachNotificationType from '../../../hooks/breachNotificationType/useBreachNotificationType';
import Label from '../../atoms/Label/Label';
import { useTranslation } from '../../../i18n';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import { getParameterByName } from '../../../utils/helper';

const AffectedMonitoredEmailsCard = () => {
  const i18n = useTranslation();
  const { affectedMonitoredEmails, breaches } = useBreachNotificationType();
  const activeBreach = getParameterByName('breach', window.location.href);

  const breachIsCritical =
    breaches[activeBreach]?.dangerousLevel === 'critical';

  const PAGE_SIZE = 10;

  // Constante con información para pintar los headers de la tabla
  const columns = [
    {
      name: i18n.t('breachNotification.breachTable.headers.breach.name'),
    },
    {
      name: i18n.t('breachNotification.breachTable.headers.breach.email'),
    },
    {
      name: breachIsCritical
        ? i18n.t(
            'breachNotification.breachTable.headers.breach.sentNotification'
          )
        : i18n.t('breachNotification.breachDetails.serviceBreached'),
    },
    {
      name: breachIsCritical
        ? i18n.t(
            'breachNotification.breachTable.headers.breach.confirmedSecurity'
          )
        : i18n.t('breachNotification.breachTable.headers.member.classes'),
    },
  ];

  const mapperFn = (item) => {
    return {
      name: { type: 'string', value: item.name, sortValue: item.name },
      email: { type: 'string', value: item.email, sortValue: item.email },
      sentNotification: breachIsCritical
        ? {
            type: 'component',
            value: (
              <Label
                value={
                  item.notified ? i18n.t('common.yes') : i18n.t('common.no')
                }
                color={!item.notified && 'red'}
                width="70px"
              />
            ),
            sortValue: item.notified
              ? i18n.t('common.yes')
              : i18n.t('common.no'),
          }
        : {
            type: 'string',
            value: item.breachName,
            sortValue: item.breachName,
          },
      confirmedSecurity: breachIsCritical
        ? {
            type: 'component',
            value: (
              <Label
                value={
                  item.resolved ? i18n.t('common.yes') : i18n.t('common.no')
                }
                color={!item.resolved && 'red'}
                width="70px"
              />
            ),
            sortValue: item.resolved
              ? i18n.t('common.yes')
              : i18n.t('common.no'),
          }
        : { type: 'string', value: item.classes, sortValue: item.classes },
    };
  };

  const defaultSortDirection = 'asc';
  const sortOptions = {
    activeSortedColumnName: i18n.t(
      'breachNotification.breachTable.headers.breach.name'
    ),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 0,
    onSort: (data) => {
      setSort(data);
    },
  };

  const {
    currentPage,
    setSearch,
    setSort,
    setSearchTags,
    setPage,
    rows,
    length,
  } = useFilterTable(
    PAGE_SIZE,
    mapperFn,
    affectedMonitoredEmails,
    breachIsCritical
      ? ['name', 'email']
      : ['name', 'email', 'breachName', 'classes'],
    sortOptions
  );

  return (
    <SCAffectedMonitoredEmailsCard>
      <SectionCard
        headerIcon={faEnvelopeDot}
        headerTitle={`${i18n.t(
          'breachNotification.monitoredEmailsAffected'
        )} (${length})`}>
        <TableContainer
          columns={columns}
          onChangeValue={setSearch}
          rows={rows}
          onPageChange={(page) => setPage(page)}
          hasHorizontalScroll
          totalCount={length}
          currentPage={currentPage}
          sortOptions={sortOptions}
          pageSize={PAGE_SIZE}
        />
      </SectionCard>
    </SCAffectedMonitoredEmailsCard>
  );
};

export default AffectedMonitoredEmailsCard;
