import React from 'react';
import {
  faArrowUpRightFromSquare,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SCActionInfoCard from './ActionInfoCard.style';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { useTranslation } from '../../../i18n';
import actionTemplates from '../../Dashboard/Sections/Actions/ActionTemplates.json';
import Button from '../../atoms/Button/Button';
import SingleActionProgress from '../SingleActionProgress/SingleActionProgress';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';

const ActionInfoCard = ({ action }) => {
  const i18n = useTranslation();
  const client = useSelector((state) => state.client);
  const navigate = useNavigate();

  const isComplete = action.pass;

  const updateAction = (operation) => {
    axios.post(`/actions/${client.id}/${action.id}`, { operation });
  };

  const handleYesAction = () => {
    if (!isComplete) {
      updateAction('pass');
    } else {
      updateAction('reset');
    }
  };
  const handleNoAction = () => {
    if (isComplete) {
      updateAction('overridefail');
    } else {
      updateAction('reset');
    }
  };

  return (
    <SCActionInfoCard>
      <SectionCard
        headerTitle={i18n.t(`action-center.actions.${action.type}.popUpTitle`)}
        headerIcon={faWrench}>
        <p className="description">
          {i18n.t(`action-center.actions.${action.type}.text`)}
        </p>
        {action.passCount > 0 && <SingleActionProgress action={action} />}
        {actionTemplates[action.type].actionWithQuestion && (
          <>
            <p className="question">
              {i18n.t(`action-center.actions.${action.type}.question`)}
            </p>
            <div className="questionActions">
              <Button
                text={i18n.t('action-center.yes')}
                color={isComplete ? 'bluishGrey' : 'white'}
                onClick={() => handleYesAction()}
                size="small"
              />
              <Button
                text={i18n.t('action-center.no')}
                color={!isComplete ? 'bluishGrey' : 'white'}
                onClick={() => {
                  handleNoAction();
                }}
                size="small"
              />
            </div>
            <div className="banner">
              <InfoBanner
                type={isComplete ? 'info' : 'warn'}
                text={[
                  isComplete
                    ? i18n.t(`action-center.completedActionBanner`)
                    : i18n.t(`action-center.incompletedActionBanner`),
                ]}
              />
            </div>
          </>
        )}
        <div className="questionActions">
          {actionTemplates[action.type].tool && (
            <Button
              onClick={() => {
                navigate(actionTemplates[action.type].tool);
              }}
              size="mid"
              color="white"
              icon={faArrowUpRightFromSquare}
              text={i18n.t(
                `action-center.actions.${action.type}.toolButtonProgress`
              )}
            />
          )}
          {actionTemplates[action.type].avoidable && !isComplete && (
            <Button
              color="white"
              size="mid"
              onClick={() => updateAction('pass')}
              text={i18n.t('action-center.alreadyDone')}
            />
          )}
        </div>
      </SectionCard>
    </SCActionInfoCard>
  );
};

export default ActionInfoCard;
