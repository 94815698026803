import styled from '@emotion/styled';

const percentageColors = {
  default: 'var(--light-grey)',
  red: 'var(--red)',
  bluishGreyHover: 'var(--bluish-grey-hover)',
  lightRed: 'var(--light-red3)',
  orange: 'var(--orange)',
  green: 'var(--green)',
};

const SCActionsProgress = styled.div`
  .completed-actions-container {
    display: flex;
    justify-content: flex-start;
    gap: 70px;

    .left-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 10px;
      width: 30%;
      margin-left: 10px;

      .completed-total-text-container {
        > h1 {
          display: inline-block;
          font-size: 38px;
          line-height: 1;
          color: var(--green);
        }

        > span {
          font-family: var(--font1);
          font-size: 20px;
          color: var(--bluish-grey-2);
        }
      }

      .completed-actions-text-container {
        > p {
          font-weight: 500;
          color: var(--bluish-grey-2);
          line-height: 19px;
        }
      }
    }

    .right-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      p {
        color: var(--bluish-grey);
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 55ch;
      }

      .actions-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .action-container {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .completed-actions-container {
      flex-direction: column;
      gap: 35px;

      .left-container {
        margin-left: 0;
      }

      .right-container {
        .actions-fade-container {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            background-image: linear-gradient(
              to right,
              rgba(255, 255, 255, 0),
              white 85%
            );
            width: 5%;
          }
        }

        .actions-scroll-container {
          overflow: auto;

          ::-webkit-scrollbar {
            display: none;
          }

          .actions-container {
            width: fit-content;
            margin-right: 20px;

            .action-container {
              gap: 0px;

              > div {
                position: sticky;
                left: 0;
                padding-right: 10px;
                background-color: white;
                height: 20px;
              }

              > p {
                overflow: visible;
              }
            }
          }
        }
      }
    }
  }
`;

export default SCActionsProgress;
