import React from 'react';
import {
  faFolderMagnifyingGlass,
  faHeartPulse,
  faShieldCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { faRadiation } from '@fortawesome/pro-solid-svg-icons';

import ThreatsNeutralized from '../../molecules/ThreatsNeutralized/ThreatsNeutralized';
import { COUNTER_TYPES } from '../../../hooks/useManagedThreatCounters/useManagedThreatCounters';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { getNumberFormat } from '../../../utils/format/dataFormat';

const ThreatsNeutralizedDevices = ({ counters }) => {
  const i18n = useTranslation();
  const THREATS_NEUTRALIZED_DEVICES = [
    {
      iconName: faHeartPulse,
      amount: counters[COUNTER_TYPES.MALWARE_PROTECTION_TOTAL_EVENTS],
      description: i18n.t(
        'managed-threats.widgets.device-threats-neutralized.labels.eventsAnalyzed'
      ),
    },
    {
      iconName: faFolderMagnifyingGlass,
      amount: counters[COUNTER_TYPES.MALWARE_PROTECTION_FILES_ANALYZED],
      description: i18n.t(
        'managed-threats.widgets.device-threats-neutralized.labels.filesAnalyzed'
      ),
    },
    {
      iconName: faRadiation,
      amount: counters[COUNTER_TYPES.MALWARE_PROTECTION_LOW_ALERTS],
      description: i18n.t(
        'managed-threats.widgets.device-threats-neutralized.labels.lowSeverityThreats'
      ),
    },
    {
      iconName: faRadiation,
      amount: counters[COUNTER_TYPES.MALWARE_PROTECTION_HIGH_ALERTS],
      description: i18n.t(
        'managed-threats.widgets.device-threats-neutralized.labels.highSeverityThreats'
      ),
    },
  ];

  return (
    <SectionCard
      headerTitle={i18n.t(
        'managed-threats.widgets.device-threats-neutralized.title'
      )}
      headerIcon={faShieldCheck}>
      <ThreatsNeutralized
        subtitle={i18n.t(
          'managed-threats.widgets.device-threats-neutralized.subtitle',
          {
            amount: getNumberFormat(
              (counters[COUNTER_TYPES.MALWARE_PROTECTION_LOW_ALERTS] ?? 0) +
                (counters[COUNTER_TYPES.MALWARE_PROTECTION_HIGH_ALERTS] ?? 0)
            ),
          }
        )}
        threatsCounters={THREATS_NEUTRALIZED_DEVICES}
      />
    </SectionCard>
  );
};

export default ThreatsNeutralizedDevices;
