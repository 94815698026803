import styled from '@emotion/styled';

const TRANSITION_DURATION = '0.1s';

const SCTagsBox = styled.div`
  background: var(--light-grey);
  width: 296px;
  height: ${({ isOpenBox }) => (isOpenBox ? '123px' : '0px')};
  padding: ${({ isOpenBox }) => (isOpenBox ? '15px' : '0px 15px')};
  margin-top: ${({ isOpenBox }) => (isOpenBox ? '15px' : '0px')};
  border-radius: 10px;
  transition: height ${TRANSITION_DURATION} ease-in,
    padding ${TRANSITION_DURATION} ease-in,
    margin-top ${TRANSITION_DURATION} ease-in;
  overflow: hidden;

  .tags-box-children-container {
    opacity: ${({ isOpenBox }) => (isOpenBox ? '1' : '0')};
    transition: opacity ${TRANSITION_DURATION} ease-in;
  }
`;

export default SCTagsBox;
