import React from 'react';
import useInternationalization from '../../../hooks/Internationalization/useInternationalization';

export const getInternationalizedPNGImage = (
  imgLocalesFolderName,
  imgFileName
) => {
  const { internationalizedImgLang } = useInternationalization();

  // eslint-disable-next-line
    return require(`../../../img/${imgLocalesFolderName}/${internationalizedImgLang}/${imgFileName}.png`)
    .default;
};

export const getInternationalizedSVGImage = (
  imgLocalesFolderName,
  imgFileName
) => {
  const { internationalizedImgLang } = useInternationalization();

  /* eslint-disable */
    const ImgComponent =
      require(`../../../img/${imgLocalesFolderName}/${internationalizedImgLang}/${imgFileName}.js`).default;
    /* eslint-enable */

  return <ImgComponent />;
};
