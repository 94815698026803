import React from 'react';
import SCSelect from './Select.style';
import Icon from '../Icon/Icon';

const Select = ({
  onChange,
  values,
  selectedValue,
  icon,
  img,
  selectPlaceholder,
  design,
  ...props
}) => {
  return (
    <SCSelect hasIcon={!!icon} design={design}>
      {icon && (
        <div className="Select_Icon">
          {img ? (
            <img src={img} alt={`${img}-logo`} />
          ) : (
            <Icon iconName={icon} />
          )}
        </div>
      )}

      {/* {img && (
        <div className="Select_Icon">
          <img src={img} alt={`${img}-logo`} />
        </div>
      )} */}

      <select
        onChange={(e) => onChange(e.target.value)}
        {...props}
        value={selectedValue}>
        {selectPlaceholder && (
          <option disabled selected={!selectedValue} value="">
            {selectPlaceholder}
          </option>
        )}
        {values.map((filterValue) => (
          <option selected={filterValue === selectedValue} value={filterValue}>
            {filterValue}
          </option>
        ))}
      </select>
    </SCSelect>
  );
};

export default Select;
