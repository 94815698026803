import styled from '@emotion/styled';

const SCNavMenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: white;
  top: 0;
  right: ${({ open }) => (open ? '0' : '-100%')};
  width: 100%;
  height: 100vh;
  transition: 1s all ease;
  > a {
    > img {
      max-width: 115px;
      margin-bottom: 40px;
    }
  }
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding: 0 15px;
    max-width: 500px;
    margin-top: 40px;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

export default SCNavMenuMobile;
