import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  SCDatePicker,
  SCCustomDatePicker,
  SCCustomDatePickerRange,
} from './DatePicker.style';
import { useTranslation } from '../../../i18n';
import { getDateFormatByLanguage } from '../../../utils/locale';
/* eslint-disable */
import { es } from 'dayjs/locale/es';
import { pt } from 'dayjs/locale/pt-br';
/* eslint-enable */

dayjs.extend(customParseFormat);

const DatePickerApp = ({
  isRangePicker,
  onChange,
  defaultValue,
  disabledDatesBefore,
  disabledDatesAfter,
}) => {
  // TO DO: Format dependiente del idioma.
  const { lang } = useTranslation();

  const disabledDate = (current) => {
    // Can not select days before today and today
    if (disabledDatesBefore && !disabledDatesAfter) {
      return current && current < dayjs(disabledDatesBefore).endOf('day');
    }
    if (!disabledDatesBefore && disabledDatesAfter) {
      return current && current > dayjs(disabledDatesAfter).endOf('day');
    }
    if (disabledDatesBefore && disabledDatesAfter) {
      return (
        (current && current < dayjs(disabledDatesBefore).endOf('day')) ||
        current > dayjs(disabledDatesAfter).endOf('day')
      );
    }
  };

  return (
    <SCDatePicker>
      {isRangePicker ? (
        <SCCustomDatePickerRange
          disabledDate={disabledDate}
          onChange={onChange}
          value={
            defaultValue?.length > 0
              ? [dayjs(defaultValue[0]), dayjs(defaultValue[1])]
              : undefined
          }
          format={getDateFormatByLanguage(lang)}
        />
      ) : (
        <SCCustomDatePicker
          onChange={onChange}
          value={defaultValue && dayjs(defaultValue)}
          format={getDateFormatByLanguage(lang)}
        />
      )}
    </SCDatePicker>
  );
};

export default DatePickerApp;
