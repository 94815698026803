import React from 'react';
import { useTranslation } from '../../../i18n';
import SCScoreExplanation, { Observation } from './ScoreExplanation.style';
import Label from '../../atoms/Label/Label';

const ScoreExplanation = ({ observations }) => {
  const i18n = useTranslation();

  const getObservationText = (observationName, observationEffect) => {
    let text;
    switch (observationName) {
      case 'active_ips':
        text = i18n.t(
          'domainImpersonation.domainDetails.suspiciousDetails.activeIP'
        );
        break;
      case 'no_content':
        text = i18n.t(
          'domainImpersonation.domainDetails.suspiciousDetails.noContent'
        );
        break;
      case 'active_mxs':
        text = i18n.t(
          'domainImpersonation.domainDetails.suspiciousDetails.activeMX'
        );
        break;
      case 'active_web':
        text = i18n.t(
          'domainImpersonation.domainDetails.suspiciousDetails.activeWebsite'
        );
        break;
      case 'updated_after':
        text = i18n.t(
          'domainImpersonation.domainDetails.suspiciousDetails.updatedAfter'
        );
        break;
      case 'parked':
        text = i18n.t(
          'domainImpersonation.domainDetails.suspiciousDetails.parked'
        );
        break;
      case 'unknown_registrar':
        text = i18n.t(
          'domainImpersonation.domainDetails.suspiciousDetails.unknownRegistrar'
        );
        break;
      case 'created_after':
        text = i18n.t(
          'domainImpersonation.domainDetails.suspiciousDetails.createdAfter'
        );
        break;
      case 'similar_img_src':
        text = i18n.t(
          'domainImpersonation.domainDetails.suspiciousDetails.similarImg'
        );
        break;
      case 'similar_words':
        text = i18n.t(
          observationEffect > 0
            ? 'domainImpersonation.domainDetails.suspiciousDetails.similarText'
            : 'domainImpersonation.domainDetails.suspiciousDetails.noSimilarText'
        );
        break;
      case 'similar_img_alt':
        text = i18n.t(
          'domainImpersonation.domainDetails.suspiciousDetails.similarAltImgs'
        );
        break;
      case 'similar_links':
        text = i18n.t(
          'domainImpersonation.domainDetails.suspiciousDetails.similarLinks'
        );
        break;
      case 'phishtank_match':
        text = i18n.t(
          'domainImpersonation.domainDetails.suspiciousDetails.phishtankMatch'
        );
        break;
      default:
        text = '';
        break;
    }
    return text;
  };

  return (
    <SCScoreExplanation>
      {observations
        .sort((a, b) => {
          return b.Effect - a.Effect;
        })
        .map((observation) => (
          <Observation observationEffect={observation.Effect}>
            <div className="scoreExplanation_score">
              <Label
                value={(observation.Effect > 0 && '+') + observation.Effect}
                color={observation.Effect > 0 ? 'red' : 'green'}
                width="50px"
              />
            </div>
            <div className="scoreExplanation_name">
              <b>{getObservationText(observation.Name, observation.Effect)}</b>
            </div>
          </Observation>
        ))}
    </SCScoreExplanation>
  );
};

export default ScoreExplanation;
