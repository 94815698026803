import { useSelector } from 'react-redux';
import { useTranslation } from '../../i18n';
import useBreachNotificationType from '../breachNotificationType/useBreachNotificationType';

function useConfigureEmailLinks(domainName) {
  const client = useSelector((redux) => redux.client);
  const user = useSelector((redux) => redux.user);
  const { breaches, activeBreach } = useBreachNotificationType();
  const emailProtectionTutorial = useSelector((redux) => redux.emailProtectionTutorial);
  const i18n = useTranslation();

  const CYBERGUARDIAN_SUPPORT = 'support@cyberguardian.tech';

  // SUPLANTACIONES
  const gmailImpersonationLink = `https://mail.google.com/mail/u/${user.email.replace(
    /[+]/g,
    '%2B'
  )}/?view=cm&fs=1&to=${CYBERGUARDIAN_SUPPORT}&su=${i18n.t(
    'domainImpersonation.requestManualReview.emailSubject'
  )}&body=${i18n.t('domainImpersonation.requestManualReview.emailBody', {
    domain: domainName,
  })}&tf=1`;

  const nativeImpersonationLink = `mailto:?to=${CYBERGUARDIAN_SUPPORT}&subject=${i18n.t(
    'domainImpersonation.requestManualReview.emailSubject'
  )}&body=${i18n.t('domainImpersonation.requestManualReview.emailBody', {
    domain: domainName,
  })}`;

  // SEGURIDAD DE CORREO
  const gmailEmailSecurityLink = `https://mail.google.com/mail/u/${user.email.replace(
    /[+]/g,
    '%2B'
  )}/?view=cm&fs=1&su=${i18n.t(
    'configureEmailSecurity.sendITEmailSubject'
  )}&body=${i18n.t('configureEmailSecurity.sendITEmailBody', {
    domain: client.atEmail,
  })}&tf=1`;
  // const outlookLink = `https://outlook.live.com/mail/${user.email.replace(
  //   /[+]/g,
  //   '%2B'
  // )}/deeplink/compose?version=2020060802.13&popoutv2=1&?view=cm&fs=1&su=${i18n.t(
  //   'configureEmailSecurity.sendITEmailSubject'
  // )}&body=${i18n.t('configureEmailSecurity.sendITEmailBody', {
  //   domain: client.atEmail,
  // })}&tf=1`;
  const nativeEmailSecurityLink = `mailto:?subject=${i18n.t(
    'configureEmailSecurity.sendITEmailSubject'
  )}&body=${i18n.t('configureEmailSecurity.sendITEmailBody', {
    domain: client.atEmail,
  })}`;

  // FILTRACIONES DE DATOS
  let gmailBreachesLink = '';
  let nativeBreachesLink = '';
  if (breaches && activeBreach && breaches[activeBreach]?.emails) {
    const breachesSubject = i18n.t(
      'breachNotification.notifyAffectedBox.emailSubject'
    );
    const breachesRecipients = Object.keys(breaches[activeBreach]?.emails)
      .map((key) => key)
      .join(',');
    const breachesBody = i18n.t(
      'breachNotification.notifyAffectedBox.emailBody',
      {
        breachedServices: activeBreach,
        adminName: user.firstName,
      }
    );
    gmailBreachesLink = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${user.email}&bcc=${breachesRecipients}&su=${breachesSubject}&body=${breachesBody}&tf=1`;
    nativeBreachesLink = `mailto:?bcc=${breachesRecipients}&to=${user.email}&subject=${breachesSubject}&body=${breachesBody}`;
  }

  // SEGURIDAD DE BUZÓN DE CORREO ELECTRÓNICO
  const gmailMailboxSecurityLink = `https://mail.google.com/mail/u/${user.email.replace(
    /[+]/g,
    '%2B'
  )}/?view=cm&fs=1&su=${i18n.t(
    'mailbox-security.configureEmailMxRecords.sendITEmailSubject'
  )}&body=${i18n.t('mailbox-security.configureEmailMxRecords.sendITEmailBody', {
    domain: client.atEmail,
    domainVerificationToken: client.domainVerificationToken,
    url: emailProtectionTutorial?.[i18n.lang],
  })}&tf=1`;
  const nativeMailboxSecurityLink = `mailto:?subject=${i18n.t(
    'mailbox-security.configureEmailMxRecords.sendITEmailSubject'
  )}&body=${i18n.t('mailbox-security.configureEmailMxRecords.sendITEmailBody', {
    domain: client.atEmail,
    domainVerificationToken: client.domainVerificationToken,
    url: emailProtectionTutorial?.[i18n.lang],
  })}`;

  // SEGURIDAD DE PROOVEDORES
  const gmailProvidersLink = `https://mail.google.com/mail/u/${user.email.replace(
    /[+]/g,
    '%2B'
  )}/?view=cm&fs=1&su=${i18n.t(
    'suppliersSecurity.notifySupplier.mailSubject'
  )}&body=${i18n.t('suppliersSecurity.notifySupplier.mailText')}&tf=1`;

  const nativeProvidersLink = `mailto:?subject=${i18n.t(
    'suppliersSecurity.notifySupplier.mailSubject'
  )}&body=${i18n.t('suppliersSecurity.notifySupplier.mailText')}`;

  const links = {
    gmailImpersonationLink,
    nativeImpersonationLink,
    gmailEmailSecurityLink,
    // outlookLink,
    nativeEmailSecurityLink,
    gmailBreachesLink,
    nativeBreachesLink,
    gmailMailboxSecurityLink,
    nativeMailboxSecurityLink,
    gmailProvidersLink,
    nativeProvidersLink,
  };

  return {
    links,
  };
}

export default useConfigureEmailLinks;
