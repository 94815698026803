import styled from '@emotion/styled';

const SCChartSpinner = styled.div`
  margin: auto;
  max-width: 100%;
  line-height: 1.4;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;

  .loadWords {
    font-weight: normal;
    font-size: 0.9rem;
    color: #bc1224;
    margin: 0.6rem 0 2rem 0;
    display: block;
  }

  .loading__anim {
    width: 180px;
    height: 180px;
    border: 14px solid rgba(189, 189, 189, 0.25);
    border-left-color: #78bc12;
    border-top-color: #78bc12;
    border-radius: 50%;
    display: inline-block;
    animation: rotate 3000ms infinite linear;
  }

  @keyframes rotate {
    to {
      transform: rotate(1turn);
    }
  }

  .tag {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: 500;
    color: var(--bluish-grey);
    font-family: var(--font1);
  }
`;

export default SCChartSpinner;
