import React from 'react';
import { Skeleton } from 'antd';
import SCLabelDetailGroup from './LabelDetailGroup.style';
import LabelDetail from '../../molecules/LabelDetail/LabelDetail';
import LastUpdateText from '../../atoms/LastUpdateText/LastUpdateText';

const LabelDetailGroup = ({
  labelDetails,
  lastUpdateDate,
  loading = false,
}) => {
  return (
    <SCLabelDetailGroup>
      {!loading ? (
        <>
          <div className="label-details">
            {labelDetails.map(({ name, value, color }) => (
              <LabelDetail
                name={name}
                value={value}
                color={color}
                labelWidth="120px"
              />
            ))}
          </div>
          {lastUpdateDate && <LastUpdateText date={lastUpdateDate} />}
        </>
      ) : (
        <Skeleton active paragraph={{ rows: 5 }} />
      )}
    </SCLabelDetailGroup>
  );
};

export default LabelDetailGroup;
