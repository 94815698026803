import * as formsService from '../../services/forms/forms.service';

const useSantanderInitForm = () => {
  const saveAnswer = (questionId, answers) => {
    const answer = {
      answers: [
        {
          questionId,
          optionsId: answers,
        },
      ],
    };

    return formsService.saveAnswer(answer);
  };

  const saveOtherTesterData = (name, lastName, phoneNumber, email) => {
    const testerData = {
      name,
      lastName,
      phoneNumber,
      email,
    };

    return formsService.saveOtherTester(testerData);
  };

  const sendRemind = () => {
    return formsService.sendRemind();
  };

  return {
    saveAnswer,
    saveOtherTesterData,
    sendRemind,
  };
};

export default useSantanderInitForm;
