import React from 'react';
import { faMemoCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCLicensesOverviewCard from './LicensesOverviewCard.style';
import useLicenses from '../../../hooks/licenses/useLicenses';
import { useTranslation } from '../../../i18n';
import LabelDetailGroup from '../LabelDetailGroup/LabelDetailGroup';

const LicensesOverviewCard = () => {
  const {
    totalLicenses,
    sentNotInstalledLicenses,
    installedLicenses,
    availableLicensesToSend,
  } = useLicenses();
  const i18n = useTranslation();

  const LABEL_DETAILS = [
    {
      name: i18n.t('deviceSecurity.licensesOverview.purchasedLicenses'),
      value: totalLicenses,
    },

    {
      name: i18n.t('deviceSecurity.licensesOverview.sentNotInstalled'),
      value: sentNotInstalledLicenses,
      color: sentNotInstalledLicenses > 0 && 'red',
    },
    {
      name: i18n.t('deviceSecurity.licensesOverview.sentInstalled'),
      value: installedLicenses,
    },
    {
      name: i18n.t('deviceSecurity.licensesOverview.availableToSend'),
      value: availableLicensesToSend,
    },
  ];

  return (
    <SCLicensesOverviewCard>
      <SectionCard
        headerIcon={faMemoCircleInfo}
        headerTitle={i18n.t('deviceSecurity.licensesOverview.title')}>
        <LabelDetailGroup labelDetails={LABEL_DETAILS} />
      </SectionCard>
    </SCLicensesOverviewCard>
  );
};

export default LicensesOverviewCard;
