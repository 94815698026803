import styled from '@emotion/styled';

const SCTwoFactorAuthenticationSetup = styled.div`
  h1 {
    text-align: center;
    font-family: var(--font1);
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .phone-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .next-button {
    margin-top: 30px;
  }

  .cancel-button {
    margin-top: 10px;
  }
`;

export default SCTwoFactorAuthenticationSetup;
