import React from 'react';
import {
  faHeartPulse,
  faLaptop,
  faRectangleTerminal,
} from '@fortawesome/pro-regular-svg-icons';
import { faRadiation } from '@fortawesome/pro-solid-svg-icons';

import ThreatsNeutralized from '../../molecules/ThreatsNeutralized/ThreatsNeutralized';
import { COUNTER_TYPES } from '../../../hooks/useManagedThreatCounters/useManagedThreatCounters';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { getNumberFormat } from '../../../utils/format/dataFormat';

const ThreatsNeutralizedNavigation = ({ counters }) => {
  const i18n = useTranslation();

  const THREATS_NEUTRALIZED_NAVIGATION = [
    {
      iconName: faRectangleTerminal,
      amount: counters[COUNTER_TYPES.WEB_PROTECTION_CONNECTIONS_ANALYZED],
      description: i18n.t(
        'managed-threats.widgets.navegation-threats-neutralized.labels.webAnalyzed'
      ),
    },
    {
      iconName: faHeartPulse,
      amount: counters[COUNTER_TYPES.WEB_PROTECTION_TOTAL_EVENTS],
      description: i18n.t(
        'managed-threats.widgets.navegation-threats-neutralized.labels.webEventsAnalyzed'
      ),
    },
    {
      iconName: faRadiation,
      amount: counters[COUNTER_TYPES.WEB_PROTECTION_CONNECTIONS_BLOCKED],
      description: i18n.t(
        'managed-threats.widgets.navegation-threats-neutralized.labels.webThreatsAnalyzed'
      ),
    },
  ];

  return (
    <SectionCard
      headerTitle={i18n.t(
        'managed-threats.widgets.navegation-threats-neutralized.title'
      )}
      headerIcon={faLaptop}>
      <ThreatsNeutralized
        subtitle={i18n.t(
          'managed-threats.widgets.navegation-threats-neutralized.subtitle',
          {
            amount: getNumberFormat(
              counters[COUNTER_TYPES.WEB_PROTECTION_CONNECTIONS_BLOCKED] ?? 0
            ),
          }
        )}
        threatsCounters={THREATS_NEUTRALIZED_NAVIGATION}
      />
    </SectionCard>
  );
};

export default ThreatsNeutralizedNavigation;
