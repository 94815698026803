import React from 'react';
import SCImageWithText from './ImageTitleText.style';

const ImageTitleText = ({
  textKeys,
  img,
  imgWidth,
  hasImgTranslation = false,
  titleHeight,
}) => {
  const [title, ...rest] = textKeys;

  return (
    <SCImageWithText
      imgWidth={imgWidth}
      hasImgTranslation={hasImgTranslation}
      titleHeight={titleHeight}>
      <div>
        <div className="img-container">
          {typeof img === 'string' ? <img src={img} alt={title} /> : img}
        </div>
        <h3>{title}</h3>
        <br />
      </div>
      {rest.map((paragraph) => (
        <p key={paragraph?.substring(0, 10)}>{paragraph}</p>
      ))}
    </SCImageWithText>
  );
};

export default ImageTitleText;
