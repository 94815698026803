import styled from '@emotion/styled';

const SCThreatCounter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  padding: 10px;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? 'var(--red-active)' : 'transparent'};
  color: ${({ isHighlighted }) =>
    isHighlighted ? 'var(--red)' : 'var(--bluish-grey)'};
  border-radius: 10px;

  .ManagedThreat_Icon {
    margin-top: 15px;
    height: fit-content;
  }

  .ManagedThreat_Info {
    display: flex;
    flex-direction: column;
    gap: 5px;

    p {
      color: inherit;
    }

    .ManagedThreat_InfoData {
      font-weight: 600;
      font-size: 20px;
    }
  }
`;

export default SCThreatCounter;
