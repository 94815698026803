import { useState } from 'react';
import * as domainRecognitionService from '../../services/domainRecognition/domainRecognition.service';

const useDomainRecognition = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const isBlacklistedDomain = async (domain) => {
    setLoading(true);
    setError(false);
    const serviceResp = domainRecognitionService
      .isBlacklistedDomain(domain)
      .then((res) => {
        return res.data.code === 'auth/domain-blacklisted';
      })
      .catch((err) => {
        setError(true);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });

    return serviceResp;
  };

  const isWarnDomain = async (domain) => {
    setLoading(true);
    setError(false);
    const serviceResp = domainRecognitionService
      .isWarnDomain(domain)
      .then((res) => {
        return res.data.code === 'auth/domain-warnlisted';
      })
      .catch((err) => {
        setError(true);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });

    return serviceResp;
  };
  return { loading, error, isBlacklistedDomain, isWarnDomain };
};

export default useDomainRecognition;
