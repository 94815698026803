import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import emailProtectionService from '../../services/emailProtection/emailProtection.service';
import {
  isEmptyObject,
  tryParseJSONObject,
} from '../../utils/functions/objects';
import { FILTER_OPERATORS } from '../../utils/functions/queryParameters';
import { dateToISOString } from '../../utils/functions/date';

const ORDERS = {
  ascend: 'ascend',
  descend: 'descend',
};

const QUARANTINE_OPERATORS = {
  subject: FILTER_OPERATORS.LIKE,
  from: FILTER_OPERATORS.LIKE,
  rcpt: FILTER_OPERATORS.LIKE,
  date: [FILTER_OPERATORS.GREATER_THAN, FILTER_OPERATORS.LOWER_THAN],
};

const getFilters = (filters, operators) => {
  if (!filters || isEmptyObject(filters)) {
    return null;
  }

  const mappedFilters = Object.entries(filters).flatMap(([key, value]) => {
    // Si el valor recibido y el operator para el campo son un array, entonces, damos por hecho que es una fecha.
    if (Array.isArray(value) && Array.isArray(operators[key])) {
      return [
        {
          field: key,
          operator: operators[key][0],
          value: dateToISOString(value[0]),
        },
        {
          field: key,
          operator: operators[key][1],
          value: dateToISOString(value[1]),
        },
      ];
    }

    return {
      field: key,
      operator: operators[key],
      value: `'${value}'`,
    };
  });

  return mappedFilters;
};

const getFiltersFromURL = (urlFilters) => {
  if (!urlFilters) {
    return null;
  }

  let parsedFilters = tryParseJSONObject(urlFilters);

  if (parsedFilters?.hasOwnProperty('date')) {
    parsedFilters = {
      date: [new Date(parsedFilters.date[0]), new Date(parsedFilters.date[1])],
    };
  }

  return parsedFilters;
};

const useFetchQuarantineEmails = ({ enableSearchParams } = {}) => {
  const [quarantineEmails, setQuarantineEmails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tableParams, setTableParams] = useState({
    sorter: {
      field: 'date',
      order: ORDERS[searchParams.get('order')] ?? ORDERS.descend,
    },
    pagination: {
      current: Number(searchParams.get('page')) || 1,
      pageSize: Number(searchParams.get('pageSize')) || 20,
    },
    filters: getFiltersFromURL(searchParams.get('filters')),
  });

  const fetchQuarantineEmails = async () => {
    setIsLoading(true);

    try {
      const quarantineEmailsResp =
        await emailProtectionService.fetchQuarantineEmails({
          sorter: tableParams?.sorter,
          pagination: tableParams?.pagination,
          filters: getFilters(tableParams?.filters, QUARANTINE_OPERATORS),
        });

      const { data, pagination } = quarantineEmailsResp?.data ?? {};

      setQuarantineEmails(data);
      setTableParams((prevTableParams) => ({
        ...prevTableParams,
        pagination: {
          current: pagination?.currentPage,
          pageSize: pagination?.itemsPerPage,
          total: pagination?.totalItems,
        },
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchQuarantineEmails();

    if (enableSearchParams) {
      setSearchParams({
        order: tableParams?.sorter?.order,
        pageSize: tableParams?.pagination?.pageSize,
        page: tableParams?.pagination?.current,
        ...(tableParams?.filters && {
          filters: JSON.stringify(tableParams?.filters),
        }),
      });
    }
  }, [
    JSON.stringify(tableParams?.filters),
    tableParams?.sorter?.order,
    tableParams?.pagination?.current,
    tableParams?.pagination?.pageSize,
  ]);

  return {
    quarantineEmails,
    isLoading,
    tableParams,
    setTableParams,
  };
};

export default useFetchQuarantineEmails;
