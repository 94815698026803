import styled from '@emotion/styled';

const SCLeadsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;

  p {
    font-family: var(--font1);
    color: var(--bluish-grey);
    font-size: 16px;
    font-weight: 400;
  }

  .form-title-container {
    display: flex;
    gap: 8px;
  }

  .email-domain-container {
    display: flex;
    align-items: center;
    gap: 10px;

    > p {
      font-weight: 600;
    }
  }

  input {
    border-radius: 5px;
    padding: 11px 22px;
    color: var(--bluish-grey);
    border: 1px solid var(--bluish-grey);
    width: 100%;
    font-family: var(--font1);
    ::placeholder {
      color: var(--bluish-grey);
    }
    :focus-visible {
      outline: 0px;
    }
    ::-webkit-calendar-picker-indicator {
      filter: opacity(50%);
    }
  }

  > select {
    border-radius: 5px;
    padding: 11px 22px;
    padding-right: 37px;
    border: 1px solid var(--bluish-grey);
    color: var(--bluish-grey);
    font-size: 16px;
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="grey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"/></svg>');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 9px;
    cursor: pointer;

    :focus-visible {
      outline: 0px;
    }
  }

  .accept-terms-and-privacy-container {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;

    input[type='checkbox'] {
      border: 1px solid var(--grey);
      width: 30px;
      height: 30px;
      accent-color: var(--red);
    }

    > p {
      width: 100%;
    }
  }

  > button {
    border-radius: 50px;
    font-family: var(--font1);
  }

  .privacy-policy-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    > p {
      font-size: 14px;
    }
  }
`;

export default SCLeadsForm;
