import styled from '@emotion/styled';

const SCInitialTutorialScoring = styled.div`
  height: 100%;
  h3 {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 700;
  }

  > div {
    height: max-content;

    .InitialTutorialScoring_ScoreDetails {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .InitialTutorialScoring_SideContainer {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;

        > hr {
          width: 100px;
          border: 1px solid var(--bluish-grey);
        }

        .InitialTutorialScoring_InfoContainer {
          display: flex;
          flex-direction: column;
          width: 100%;

          .InitialTutorialScoring_InfoContent {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .InitialTutorialScoring_BottomInfoHeader {
      width: 700px;
      margin: auto;

      > hr {
        border: 1px solid var(--bluish-grey);
        height: 25px;
        width: 0px;
        margin: 0px auto 10px auto;
      }
    }
  }

  @media (width < 769px) {
    overflow: auto;
    padding-bottom: 60px;
    height: fit-content;

    p {
      padding: 0 30px;
    }

    hr {
      display: none;
    }

    > div {
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .InitialTutorialScoring_BottomInfoHeader {
        width: auto;
      }
    }

    .InitialTutorialScoring_ScoreDetails {
      flex-direction: column;
      gap: 30px;
      margin-bottom: 30px;

      .InitialTutorialScoring_ChartContainer {
        order: -1;
      }
    }
  }
`;

export default SCInitialTutorialScoring;
