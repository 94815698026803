import React from 'react';
import { faWrench } from '@fortawesome/pro-regular-svg-icons';
import { faCircle1, faCircle2 } from '@fortawesome/pro-solid-svg-icons';
import SCConfigureDmarcSteps from './ConfigureDmarcSteps.style';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import Button from '../../atoms/Button/Button';
import dnsProviders from '../../../utils/dnsProviders/dnsProvidersTemplate';
import Icon from '../../atoms/Icon/Icon';
import { getMappedDnsRecords } from '../../../utils/functions/dnsRecords';
import useDnsRecord from '../../../hooks/dnsRecord/useDnsRecord';

const ConfigureDmarcSteps = ({ dmarc, step, onChangeConfig }) => {
  const { dnsRecord } = useDnsRecord();
  const i18n = useTranslation();

  const records = getMappedDnsRecords(dnsRecord);

  return (
    <SCConfigureDmarcSteps>
      <SectionCard
        headerIcon={faWrench}
        headerTitle={i18n.t('configureEmailSecurity.DMARC.title')}>
        <b className="ConfigureDmarcStep_Header">
          {i18n.t(`configureEmailSecurity.DMARC.step${step}`)}
        </b>
        {step === 1 && (
          <>
            <p className="question">
              <Icon iconName={faCircle1} size="medium" />
              {i18n.t('configureEmailSecurity.SPF.areYouResponsible')}
            </p>
            <div className="questionActions">
              <Button
                text={i18n.t('configureEmailSecurity.SPF.yes')}
                onClick={() => {
                  onChangeConfig({ ...dmarc, areYouResponsible: true });
                }}
                color={
                  dmarc.areYouResponsible === true ? 'bluishGrey' : 'white'
                }
                size="small"
              />
              <Button
                text={i18n.t('configureEmailSecurity.SPF.no')}
                onClick={() => {
                  onChangeConfig({ ...dmarc, areYouResponsible: false });
                }}
                color={
                  dmarc.areYouResponsible === false ? 'bluishGrey' : 'white'
                }
                size="small"
              />
            </div>

            <p className="question">
              <Icon iconName={faCircle2} />
              {i18n.t('configureEmailSecurity.SPF.areYouAbleToSetDNS')}
            </p>
            <div className="questionActions">
              <Button
                text={i18n.t('configureEmailSecurity.SPF.yes')}
                onClick={() => {
                  onChangeConfig({ ...dmarc, areYouAbleToSetDNS: true });
                }}
                color={
                  dmarc.areYouAbleToSetDNS === true ? 'bluishGrey' : 'white'
                }
                size="small"
              />
              <Button
                text={i18n.t('configureEmailSecurity.SPF.no')}
                onClick={() => {
                  onChangeConfig({ ...dmarc, areYouAbleToSetDNS: false });
                }}
                color={
                  dmarc.areYouAbleToSetDNS === false ? 'bluishGrey' : 'white'
                }
                size="small"
              />
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <p className="question">
              <Icon iconName={faCircle1} />
              {i18n.t('configureEmailSecurity.DMARC.whatToDoWithFails')}
            </p>
            <div className="questionActions">
              <select
                className="form-control"
                value={dmarc.action || 'none'}
                onChange={(e) =>
                  onChangeConfig({ ...dmarc, action: e.target.value })
                }>
                <option value="none">
                  {i18n.t('configureEmailSecurity.DMARC.none')}
                </option>
                <option value="quarantine">
                  {i18n.t('configureEmailSecurity.DMARC.quarantine')}
                </option>
                <option value="reject">
                  {i18n.t('configureEmailSecurity.DMARC.reject')}
                </option>
              </select>
            </div>

            <p className="question">
              <Icon iconName={faCircle2} />
              {i18n.t('configureEmailSecurity.DMARC.reveicerEmail')}
            </p>
            <div className="questionActions">
              <input
                className="generatePassword form-control"
                type="text"
                value={dmarc.emailReceiver || ''}
                onChange={(e) =>
                  onChangeConfig({ ...dmarc, emailReceiver: e.target.value })
                }
                placeholder="email@email.com"
              />
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <div className="questionActions">
              <p>
                {i18n.t('configureEmailSecurity.dnsProviderDetected')}{' '}
                <strong>{records.dnsProvider}</strong>
              </p>

              {i18n.t('configureEmailSecurity.chooseHostingProvider', {
                mechanism: 'DMARC',
              })}
              <div className="dnsProviders_Container">
                {dnsProviders.map((item) => (
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <img src={item.img} alt="DNS provider" />
                  </a>
                ))}
              </div>
            </div>
          </>
        )}
      </SectionCard>
    </SCConfigureDmarcSteps>
  );
};

export default ConfigureDmarcSteps;
