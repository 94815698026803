import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SCQuarantineEmailDetail from './QuarantineEmailDetail.style';
import { useTranslation } from '../../i18n';
import Button from '../../components/atoms/Button/Button';
import { showPopUp } from '../../redux/actions/popUp.actions';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import MailContentCard from '../../components/organisms/MailContentCard/MailContentCard';
import MailDetailsCard from '../../components/organisms/mailDetailsCard/mailDetailsCard';
import BackButton from '../../components/molecules/BackButton/BackButton';
import useFetchQuarantineEmail from '../../hooks/emailProtection/useFetchQuarantineEmail';

const QuarantineEmailDetail = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { isLoading, quarantineEmail } = useFetchQuarantineEmail(id);

  return (
    <SectionLayout>
      <SCQuarantineEmailDetail>
        <div className="section-back-button">
          <BackButton />
        </div>

        <div className="section-organism">
          <MailDetailsCard mailInfo={quarantineEmail} isLoading={isLoading} />
        </div>

        <div className="section-organism">
          <MailContentCard mailInfo={quarantineEmail} isLoading={isLoading} />
        </div>

        <div className="section-organism Mail_Actions">
          <Button
            color="bluishGrey"
            size="large"
            type="button"
            onClick={() => {
              dispatch(showPopUp('removeQuarantineEmail', quarantineEmail?.id));
            }}
            text={i18n.t('mailbox-security.mail.actions.delete')}
          />
          <Button
            color="white"
            size="large"
            type="button"
            onClick={() => {
              dispatch(
                showPopUp('releaseQuarantineEmail', quarantineEmail?.id)
              );
            }}
            text={i18n.t('mailbox-security.mail.actions.release')}
          />
        </div>
      </SCQuarantineEmailDetail>
    </SectionLayout>
  );
};

export default QuarantineEmailDetail;
