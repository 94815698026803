import {
  faCircleLocationArrow,
  faCircleQuestion,
  faMessagesQuestion,
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSection from '../../../hooks/useSection/useSection';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Button from '../../atoms/Button/Button';

import SCAnyDoubts from './AnyDoubts.style';

const AnyDoubts = () => {
  const { section } = useSection();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const i18n = useTranslation();
  const { lang } = i18n;

  const TUTORIALS_CALLBACKS = {
    'initial-tutorial': () => navigate('/initial-tutorial'),
    actions: () => dispatch(showPopUp('tutorial', 'actions')),
    'device-security': () => dispatch(showPopUp('tutorial', 'deviceSecurity')),
    'managed-threats': () => dispatch(showPopUp('tutorial', 'managedThreats')),
    'mailbox-security': () =>
      dispatch(showPopUp('tutorial', 'mailboxSecurity')),
    'configure-email-security': () =>
      dispatch(showPopUp('tutorial', 'emailSecurity')),
    'data-alert': () => dispatch(showPopUp('tutorial', 'breach')),
    websitesecurity: () => dispatch(showPopUp('tutorial', 'website')),
    domainimpersonation: () => dispatch(showPopUp('tutorial', 'impersonation')),
    'supplier-security': () => dispatch(showPopUp('tutorial', 'supplier')),
    phishingsimulator: () => dispatch(showPopUp('tutorial', 'phishing')),
  };

  const FAQ_URLS = {
    es: 'https://cyberguardian.tawk.help/',
    en: 'https://cyberguardian.tawk.help/en-us/',
    'pt-BR': 'https://cyberguardian.tawk.help/pt-br/',
  };

  return (
    <SCAnyDoubts>
      <h3>{i18n.t('anyDoubts.title')}</h3>
      <div className="buttons-container">
        <Button
          color="white"
          size="mid"
          onClick={TUTORIALS_CALLBACKS[section || 'initial-tutorial']}
          icon={faCircleLocationArrow}
          text={i18n.t('anyDoubts.backToWatchTutorial')}
        />

        <Button
          color="white"
          size="mid"
          onClick={() => window.open(FAQ_URLS[lang], '_blank')}
          icon={faCircleQuestion}
          text={i18n.t('anyDoubts.faq')}
        />

        <Button
          color="white"
          size="mid"
          onClick={() => Tawk_API.toggle()}
          icon={faMessagesQuestion}
          text={i18n.t('anyDoubts.contactSupport')}
        />
      </div>
    </SCAnyDoubts>
  );
};

export default AnyDoubts;
