import { useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * MALWARE_PROTECTION: CrowdStrike
 * - MALWARE_PROTECTION_TOTAL_EVENTS - Eventos analizados
 * - MALWARE_PROTECTION_FILES_ANALYZED - Ficheros analizados
 * - MALWARE_PROTECTION_LOW_ALERTS - Amenazas leves o medias neutralizadas en dispositivos
 * - MALWARE_PROTECTION_HIGH_ALERTS - Amenazas altas o críticas neutralizadas en dispositivos
 * EMAIL_PROTECTION: Mimecast
 * - EMAIL_PROTECTION_EMAILS_ANALYZED - Emails analizados
 * - EMAIL_PROTECTION_FILES_ANALYZED - Adjuntos analizados
 * - EMAIL_PROTECTION_MALICIOUS_EMAILS_DELETED - Emails maliciosos eliminados
 * - EMAIL_PROTECTION_EMAILS_IN_QUARANTINE - Emails sospechosos en cuarentena
 * WEB_PROTECTION: Netskope
 * - WEB_PROTECTION_CONNECTIONS_ANALYZED - Conexiones web analizadas
 * - WEB_PROTECTION_TOTAL_EVENTS - Eventos web analizados
 * - WEB_PROTECTION_CONNECTIONS_BLOCKED - Amenazas web neutralizadas
 */
/* eslint-disable prettier/prettier */
export const COUNTER_TYPES = {
  MALWARE_PROTECTION_TOTAL_EVENTS: 'MALWARE_PROTECTION_TOTAL_EVENTS',
  MALWARE_PROTECTION_FILES_ANALYZED: 'MALWARE_PROTECTION_FILES_ANALYZED',
  MALWARE_PROTECTION_LOW_ALERTS: 'MALWARE_PROTECTION_LOW_ALERTS',
  MALWARE_PROTECTION_HIGH_ALERTS: 'MALWARE_PROTECTION_HIGH_ALERTS',
  // EMAIL_PROTECTION_EMAILS_ANALYZED: 'EMAIL_PROTECTION_EMAILS_ANALYZED',
  EMAIL_PROTECTION_FILES_ANALYZED: 'EMAIL_PROTECTION_FILES_ANALYZED',
  EMAIL_PROTECTION_MALICIOUS_EMAILS_DELETED: 'EMAIL_PROTECTION_MALICIOUS_EMAILS_DELETED',
  EMAIL_PROTECTION_EMAILS_IN_QUARANTINE: 'EMAIL_PROTECTION_EMAILS_IN_QUARANTINE',
  WEB_PROTECTION_CONNECTIONS_ANALYZED: 'WEB_PROTECTION_CONNECTIONS_ANALYZED',
  WEB_PROTECTION_TOTAL_EVENTS: 'WEB_PROTECTION_TOTAL_EVENTS',
  WEB_PROTECTION_CONNECTIONS_BLOCKED: 'WEB_PROTECTION_CONNECTIONS_BLOCKED',
};
/* eslint-enable prettier/prettier */

const getInitialDates = () => {
  const fromDate = new Date();
  fromDate.setMonth(fromDate.getMonth() - 1);
  fromDate.setHours(0, 0, 0, 0);

  const toDate = new Date();
  toDate.setHours(23, 59, 59, 999);

  return [fromDate, toDate];
};

const useManagedThreatCounters = () => {
  const managedThreatCounters = useSelector(
    (redux) => redux.managedThreatCounters
  );

  const [filterBetweeenDates, setFilterBetweeenDates] =
    useState(getInitialDates);

  const isBetweenDates = (counterDate) => {
    return (
      counterDate.toDate() >= filterBetweeenDates[0] &&
      counterDate.toDate() <= filterBetweeenDates[1]
    );
  };

  const counters = managedThreatCounters.reduce(
    (accumulator, { date, counts }) => {
      if (isBetweenDates(date)) {
        Object.keys(counts).forEach((counterType) => {
          accumulator[counterType] =
            (accumulator[counterType] || 0) + counts[counterType];
        });
      }

      return accumulator;
    },
    {}
  );

  return {
    filterBetweeenDates,
    setFilterBetweeenDates,
    counters,
  };
};

export default useManagedThreatCounters;
