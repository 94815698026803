const isValidCNPJ = (cnpj) => {
  const onlyDigitsReg = /^[0-9]+$/;

  // Verificar la longitud del CNPJ y que sólo contenga dígitos numéricos
  if (cnpj.length !== 14 || !onlyDigitsReg.test(cnpj)) {
    return false;
  }

  // calcula el primer dígito verificador
  let sum = 0;
  let peso = 5;
  for (let i = 0; i < 12; i += 1) {
    sum += parseInt(cnpj[i]) * peso;
    peso -= 1;
    if (peso < 2) peso = 9;
  }
  let resto = sum % 11;
  const dv1 = resto < 2 ? 0 : 11 - resto;

  if (dv1 !== parseInt(cnpj.substring(12, 13))) {
    return false;
  }

  // calcula el segundo dígito verificador
  sum = 0;
  peso = 6;
  for (let i = 0; i < 13; i += 1) {
    sum += parseInt(cnpj[i]) * peso;
    peso -= 1;
    if (peso < 2) peso = 9;
  }
  resto = sum % 11;
  const dv2 = resto < 2 ? 0 : 11 - resto;

  if (dv2 !== parseInt(cnpj.substring(13, 14))) {
    return false;
  }

  // El CNPJ es correcto.
  return true;
};

export default isValidCNPJ;
