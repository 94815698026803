import styled from '@emotion/styled';

const SCSectionLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  > div {
    width: 100%;
  }

  .section-back-button {
    margin-bottom: 40px;
    width: 100%;
  }

  .section-organism {
    margin-bottom: 40px;
    width: 100%;

    &.half-wide > div {
      width: calc(50% - 10px);
    }

    &.multi-column {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;

      > div {
        width: 100%;
        max-width: calc(50% - 10px);
        height: auto;
      }
    }
  }

  @media (max-width: 900px) {
    .section-organism {
      margin-bottom: 30px;

      &.half-wide > div {
        width: 100%;
      }

      &.multi-column {
        flex-direction: column;
        gap: 30px;

        > div {
          max-width: 100%;
        }
      }
    }
  }
`;

export default SCSectionLayout;
