import styled from '@emotion/styled';

const SCWelcomePopup = styled.div`
  /* text-align: center; */
  display: flex;
  overflow: auto;
  flex-direction: column;
  :root {
    .ant-select-dropdown {
      z-index: 999999999999 !important;
    }
  }

  :root {
    .ant-select-dropdown {
      z-index: 999999999999 !important;
    }
  }

  p,
  h1 {
    text-align: center;
  }

  p {
    font-family: var(--font1);
    font-weight: 400;
    font-size: 14px;
  }

  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  /* select {
    width: 100%;
    color: var(--grey);
    font-size: 16px;
    border: none;
    border-bottom: 1px solid;
    border-radius: 4px;
    padding: 5px 2px;
    width: 100%;
  } */
`;

export default SCWelcomePopup;
