import React from 'react';
import { Form, Input, DatePicker } from 'antd';
import SCCustomCard from './CustomCard.style';

const formItems = [
  { label: 'Nombre', name: ['name'], type: 'input', disabled: true },
  {
    label: 'Cargo',
    name: ['position'],
    type: 'input',
  },
  { label: 'Email', name: ['email'], type: 'input', disabled: true },
  {
    label: 'Teléfono',
    name: ['phone'],
    type: 'input',
    disabled: true,
  },
  {
    label: 'Fecha creación',
    name: ['createdAt'],
    type: 'datepicker',
    disabled: true,
  },
  {
    label: 'Partner',
    name: ['eventName'],
    type: 'input',
    disabled: true,
  },
  {
    label: 'Cláusula contacto',
    name: ['agreements', 'contactAllowed'],
    type: 'input',
    disabled: true,
  },
];

const ContactCard = () => (
  <SCCustomCard title="Contacto" size="small" bordered>
    <div className="content-grid">
      {formItems.map((item) => (
        <Form.Item
          label={
            <span>
              <strong>{item.label}</strong>
            </span>
          }
          name={item.name}>
          {item.type === 'input' && (
            <Input disabled={item.disabled} size="small" />
          )}
          {item.type === 'datepicker' && (
            <DatePicker
              showTime
              format="DD/MM/YYYY"
              disabled={item.disabled}
              size="small"
            />
          )}
        </Form.Item>
      ))}
    </div>
  </SCCustomCard>
);

export default ContactCard;
