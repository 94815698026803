import styled from '@emotion/styled';
import React from 'react';
import {
  faCheck,
  faExclamation,
  faInfo,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const SCNotificationPopUp = styled.div`
  align-items: center;

  .Notification_IconCircle {
    border-radius: 50vh;
    padding: 10px;
    border: 5px solid ${({ color }) => color || 'inherit'};
  }

  h1,
  p {
    font-weight: 500;
  }

  h1 {
    font-family: var(--font1);
  }

  p {
    font-family: var(--font2);
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .custom-sub-text {
    font-size: 14px;
    color: var(--grey);
  }
`;

const NotificationsPopUp = (props) => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const { notificationData } = props;
  const {
    notificationType,
    title,
    text,
    subtext,
    customSubText,
    closeButtonText,
    additionalButtonText,
    onButtonClick,
    closeButtonColor,
    additionalButtonClosable,
  } = notificationData;

  // TO-DO: cases should be enums instead of hardcoded string.
  // Also, instead of two switchs we could use one object of objects where
  // each notificationType is the key and icon and color are the values.
  const getIconByNotificationType = () => {
    let outputIcon;
    switch (notificationType) {
      case 'info':
        outputIcon = faInfo;
        break;
      case 'success':
        outputIcon = faCheck;
        break;
      case 'warning':
        outputIcon = faExclamation;
        break;
      case 'error':
        outputIcon = faXmark;
        break;
      default:
        outputIcon = faXmark;
        break;
    }
    return outputIcon;
  };

  const getColorByNotificationType = () => {
    let outputColor;
    switch (notificationType) {
      case 'info':
        outputColor = 'var(--bluish-grey)';
        break;
      case 'success':
        outputColor = 'var(--green)';
        break;
      case 'warning':
        outputColor = 'var(--orange)';
        break;
      case 'error':
        outputColor = 'var(--red)';
        break;
      default:
        outputColor = 'var(--red)';
        break;
    }
    return outputColor;
  };

  return (
    <ModalLayout>
      <SCNotificationPopUp color={getColorByNotificationType()}>
        <div className="Notification_IconCircle">
          <Icon
            iconName={getIconByNotificationType()}
            size="large"
            color={getColorByNotificationType()}
          />
        </div>
        <h1>{title}</h1>
        <div className="modal-card-content text-container">
          {text && <p>{text}</p>}
          {subtext && (
            <p className={`${customSubText && 'custom-sub-text'}`}>{subtext}</p>
          )}
        </div>

        <div className="modal-card-buttons">
          {additionalButtonText && (
            <Button
              size="full"
              color="white"
              text={additionalButtonText}
              onClick={() => {
                onButtonClick();
                if (!additionalButtonClosable) {
                  dispatch(showPopUp(null));
                }
              }}
            />
          )}
          <Button
            size="full"
            color={closeButtonColor ?? 'bluishGrey'}
            text={closeButtonText ?? i18n.t('welcome.continue')}
            onClick={() => {
              if (!additionalButtonText && onButtonClick) {
                onButtonClick();
              }
              dispatch(showPopUp(null));
            }}
          />
        </div>
      </SCNotificationPopUp>
    </ModalLayout>
  );
};

export default NotificationsPopUp;
