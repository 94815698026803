import { firestore } from '../../firebase';
import { GET_CAMPAIGNS } from './types';

export default (clientId, dispatch) => {
  firestore
    .collection('clients')
    .doc(clientId)
    .collection('phishing_campaigns')
    .onSnapshot((snapshot) => {
      let campaigns = [];

      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          if (doc.exists) {
            const campaign = { ...doc.data() };
            campaigns.push(campaign);
          }
        });

        campaigns = campaigns.filter((item) => !item.anonymised);
      }

      dispatch({
        type: GET_CAMPAIGNS,
        payload: [...campaigns],
      });
    });
};
