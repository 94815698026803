import styled from '@emotion/styled';

const SCRemoveTag = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;

  > p {
    font-size: 14px;
    color: var(--bluish-grey);
    font-weight: 400;
  }

  .remove-palette-buttons-container {
    display: flex;
    gap: 20px;

    .confirm-button,
    .cancel-button {
      font-size: 14px;
      background-color: transparent;
      outline: 0;
    }

    .confirm-button {
      color: var(--red);
    }

    .cancel-button {
      color: var(--bluish-grey);
    }
  }
`;

export default SCRemoveTag;
