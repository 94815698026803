import React from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from '../../i18n';
import {
  getSecurityColor,
  getSecurityText,
} from '../../utils/functions/securitySeverity';
import Label from '../../components/atoms/Label/Label';

function useMonitoredSuppliers() {
  const i18n = useTranslation();
  const monitoredSuppliers = useSelector((redux) => redux.monitoredSuppliers);

  const getRiskLabel = (score) => (
    <Label
      value={`${i18n.t(`suppliersSecurity.${getSecurityText(score)}`)} ${
        score ? `(${score})` : ''
      }`}
      color={getSecurityColor(score)}
    />
  );

  const sortByScore = (a, b) => {
    if (a.overall?.score === b.overall?.score) return 0;
    if (a.overall?.score > b.overall?.score) return 1;
    if (a.overall?.score < b.overall?.score) return -1;
  };

  // Se ha eliminado el widget "Resumen de seguridad de proveedores", sin embargo, esta función puede ser útil en un futuro
  const getSummaryStats = () => {
    const checkedSuppliers = monitoredSuppliers.length;
    let leastSecured = i18n.t('common.N/A');
    let mostSecured = i18n.t('common.N/A');

    if (!monitoredSuppliers || monitoredSuppliers.length === 0) {
      return [{ value: 0 }, { value: leastSecured }, { value: mostSecured }];
    }

    const sortedByScoreSuppliers = monitoredSuppliers.sort((a, b) =>
      sortByScore(a, b)
    );
    leastSecured = sortedByScoreSuppliers[0].name;
    mostSecured =
      sortedByScoreSuppliers[sortedByScoreSuppliers.length - 1].name;
    return [
      { value: checkedSuppliers },
      { value: mostSecured },
      { value: leastSecured },
    ];
  };

  return {
    monitoredSuppliers,
    getRiskLabel,
    getSummaryStats,
  };
}

export default useMonitoredSuppliers;
