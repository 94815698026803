import React, { useState } from 'react';
import { faMarker } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { ErrorKey } from '../../../track';
import useLeads from '../../../hooks/useLeads/useLeads';
import Button from '../../atoms/Button/Button';
import SCLeadsForm from './LandingForm.style';
import Icon from '../../atoms/Icon/Icon';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { useTranslation } from '../../../i18n';
import useRecaptcha from '../../../hooks/recaptcha/useRecaptcha';
import sectors from '../../../utils/constants/sectors';
import useDomainRecognition from '../../../hooks/domainRecognition/useDomainRecognition';
import getLegalDocumentsUrls from '../../../utils/internationalizationModules/legalDocuments';

const getFormattedWebpage = (webpage) => {
  if (webpage.startsWith('www.')) {
    return webpage.substring(4);
  }

  return webpage;
};

const LandingForm = ({ landingPageConfig }) => {
  const i18n = useTranslation();
  const { isBlacklistedDomain, isWarnDomain } = useDomainRecognition();

  const initialState = {
    user: '',
    email: '',
    phone: '',
    company: '',
    webpage: '',
    atEmail: '',
    companySector: '',
    numberEmployees: '',
    acceptTermsAndPrivacy: false,
  };

  const [
    {
      user,
      email,
      phone,
      company,
      webpage,
      atEmail,
      companySector,
      numberEmployees,
      acceptTermsAndPrivacy,
    },
    setInfoForm,
  ] = useState(initialState);

  const { getRecaptchaToken } = useRecaptcha();
  const { createLead, loading } = useLeads();
  const dispatch = useDispatch();
  const { privacyPolicy } = getLegalDocumentsUrls(i18n.getLanguage());

  const regEmail = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const regWebpage =
    /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{1,11}?$/im;

  const regAtEmail =
    /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const regPhone = /^[6,7,9]{1}[0-9]{8}/gm;

  const isFormFilled = () => {
    const filled =
      user &&
      email &&
      phone &&
      company &&
      webpage &&
      atEmail &&
      companySector &&
      numberEmployees &&
      acceptTermsAndPrivacy;

    return filled;
  };

  const isValid = async () => {
    const formattedEmail = email.split('@')[1];
    const formattedWebpage = getFormattedWebpage(webpage);
    const formattedAtEmail = atEmail.trim().toLowerCase();

    const isWarnEmail = await isWarnDomain(formattedEmail);
    const isWarnWebpage = await isWarnDomain(formattedWebpage);
    const isWarnAtEmail = await isWarnDomain(formattedAtEmail);

    if (
      !regEmail.test(email) ||
      (!isWarnEmail && (await isBlacklistedDomain(formattedEmail)))
    ) {
      ErrorKey('setup.company.website.errorEmailFormat');
      return false;
    }

    if (!regPhone.test(phone)) {
      ErrorKey('setup.company.website.errorPhoneFormat');
      return false;
    }

    if (
      !regWebpage.test(webpage) ||
      (!isWarnWebpage && (await isBlacklistedDomain(formattedWebpage)))
    ) {
      ErrorKey('setup.company.website.errorWebpageFormat');
      return false;
    }

    if (
      !regAtEmail.test(atEmail) ||
      (!isWarnAtEmail && (await isBlacklistedDomain(formattedAtEmail)))
    ) {
      ErrorKey('setup.company.website.errorEmailDomainFormatWithoutExample');
      return false;
    }

    return true;
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    getRecaptchaToken().then(async (token) => {
      // Add your logic to submit to your backend server here.
      if (await isValid()) {
        const data = {
          user,
          email: email.trim().toLowerCase(),
          phone,
          company,
          webpage: webpage.trim().toLowerCase(),
          atEmail: atEmail.trim().toLowerCase(),
          companySector: i18n.t(`setup.company.newIndustries.${companySector}`),
          numberEmployees,
          language: i18n.lang,
          token,
        };

        try {
          await createLead({
            ...data,
            eventName: landingPageConfig.eventName,
            isSantanderEvent: landingPageConfig.isSantanderEvent,
          });

          dispatch(
            showPopUp('notification', {
              notificationType: 'success',
              title: i18n.t('common.success'),
              text: i18n.t('setup.company.website.successForm'),
            })
          );
        } catch (err) {
          console.log({ err });
          ErrorKey('common.errorTryLater');
        } finally {
          setInfoForm(initialState);
        }
      }
    });
  };

  return (
    <SCLeadsForm onSubmit={handleOnSubmit}>
      <div className="form-title-container">
        <Icon iconName={faMarker} size="medium" color="var(--bluish-grey)" />
        <p>{i18n.t('landing.fillInAllFields')}</p>
      </div>
      <input
        type="text"
        name="usuario"
        id="usuario"
        placeholder={i18n.t('landing.nameAndSurname')}
        value={user}
        onChange={(e) =>
          setInfoForm((prevState) => ({ ...prevState, user: e.target.value }))
        }
      />
      <input
        type="email"
        name="email"
        id="email"
        placeholder={i18n.t('landing.email')}
        value={email}
        onChange={(e) =>
          setInfoForm((prevState) => ({ ...prevState, email: e.target.value }))
        }
      />
      <input
        type="text"
        name="phone"
        id="phone"
        maxLength="9"
        placeholder={i18n.t('landing.telephone')}
        value={phone}
        onChange={(e) =>
          setInfoForm((prevState) => ({ ...prevState, phone: e.target.value }))
        }
      />
      <input
        type="text"
        name="company"
        id="company"
        placeholder={i18n.t('landing.companyName')}
        value={company}
        onChange={(e) =>
          setInfoForm((prevState) => ({
            ...prevState,
            company: e.target.value,
          }))
        }
      />
      <input
        type="text"
        name="webpage"
        id="webpage"
        placeholder={i18n.t('landing.webPage')}
        value={webpage}
        onChange={(e) =>
          setInfoForm((prevState) => ({
            ...prevState,
            webpage: e.target.value,
          }))
        }
      />
      <div className="email-domain-container">
        <p>@</p>
        <input
          type="text"
          name="atEmail"
          id="atEmail"
          placeholder={i18n.t('landing.atEmail')}
          value={atEmail}
          onChange={(e) =>
            setInfoForm((prevState) => ({
              ...prevState,
              atEmail: e.target.value,
            }))
          }
        />
      </div>

      <select
        name="companySector"
        id="companySector"
        value={companySector}
        className="dropdown"
        onChange={(e) =>
          setInfoForm((prevState) => ({
            ...prevState,
            companySector: e.target.value,
          }))
        }>
        <option value="" disabled>
          {i18n.t('landing.sector')}
        </option>
        {sectors.map((e) => (
          <option value={e.label} key={e.id}>
            {i18n.t(`setup.company.newIndustries.${e.label}`)}
          </option>
        ))}
      </select>

      <select
        name="numberEmployees"
        id="numberEmployees"
        value={numberEmployees}
        className="dropdown"
        onChange={(e) =>
          setInfoForm((prevState) => ({
            ...prevState,
            numberEmployees: e.target.value,
          }))
        }>
        <option value="" disabled>
          {i18n.t('landing.employees')}
        </option>
        <option value="0-2">{i18n.t('landing.0to2Employees')}</option>
        <option value="3-9">{i18n.t('landing.3to9Employees')}</option>
        <option value="10-49">{i18n.t('landing.10to49Employees')}</option>
        <option value="50+">{i18n.t('landing.50MoreEmployees')}</option>
      </select>

      <div className="accept-terms-and-privacy-container">
        <input
          type="checkbox"
          id="termsAndPrivacyCheckbox"
          checked={acceptTermsAndPrivacy}
          onChange={() =>
            setInfoForm((prevState) => ({
              ...prevState,
              acceptTermsAndPrivacy: !acceptTermsAndPrivacy,
            }))
          }
        />

        <p>
          <strong>{i18n.t('landing.acceptReceiveNotifications')}</strong>{' '}
          {i18n.t('landing.aboutCyberGuardianServices')}
        </p>
      </div>

      <Button
        type="submit"
        color="red"
        text={`${i18n.t('landing.getYourFreeReport')}*`}
        size="landing"
        disabled={!isFormFilled() || loading}
      />

      <div className="privacy-policy-container">
        <p
          dangerouslySetInnerHTML={{
            __html: i18n.t('landing.acceptTermsAndConditions1', {
              privacyPolicy: `<a href=${privacyPolicy} target="_blank" rel="noopener noreferrer">${i18n.t(
                'landing.privacyPolicy'
              )}</a>`,
            }),
          }}
        />

        <p>{i18n.t('landing.cyberGuardianWillUseData')}</p>

        <p
          dangerouslySetInnerHTML={{
            __html: i18n.t('landing.acceptTermsAndConditions2', {
              privacyPolicy: `<a href=${privacyPolicy} target="_blank" rel="noopener noreferrer">${i18n.t(
                'landing.privacyPolicy'
              )}</a>`,
            }),
          }}
        />
      </div>
    </SCLeadsForm>
  );
};
export default LandingForm;
