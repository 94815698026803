const dnsProviders = [
  {
    img: 'https://storage.googleapis.com/cyberguardian-assets/platform-images/providers/godaddy.png',
    link: 'https://godaddy.com/',
  },
  {
    img: 'https://storage.googleapis.com/cyberguardian-assets/platform-images/providers/bluehost.png',
    link: 'https://bluehost.com',
  },
  {
    img: 'https://storage.googleapis.com/cyberguardian-assets/platform-images/providers/cloudflare.png',
    link: 'https://www.cloudflare.com/',
  },
  {
    img: 'https://storage.googleapis.com/cyberguardian-assets/platform-images/providers/123-reg-2.png',
    link: 'https://www.123-reg.co.uk/',
  },
];

export default dnsProviders;
