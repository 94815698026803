import {
  faEraser,
  faMessagesQuestion,
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCDeleteAccountCard from './DeleteAccountCard.style';

const DeleteAccountCard = () => {
  const i18n = useTranslation();
  return (
    <SCDeleteAccountCard>
      <SectionCard
        headerIcon={faEraser}
        headerTitle={i18n.t('profile.deleteAccount')}>
        <p>{i18n.t('profile.deleteAccountParagraph')}</p>
        <Button
          icon={faMessagesQuestion}
          size="mid"
          color="white"
          text={i18n.t('contactUs.contactSupport')}
          onClick={() => {
            Tawk_API.toggle();
          }}
        />
      </SectionCard>
    </SCDeleteAccountCard>
  );
};

export default DeleteAccountCard;
