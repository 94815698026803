import React from 'react';
import SCRadarSpinner from './RadarSpinner.style';

const RadarSpinner = () => {
  return (
    <SCRadarSpinner>
      <div className="radar-container">
        <div className="pin">
          <span className="ring-2" />
          <span className="ring" />
        </div>
      </div>
    </SCRadarSpinner>
  );
};

export default RadarSpinner;
