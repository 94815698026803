import styled from '@emotion/styled';
import { css } from '@emotion/core';

const selectedStyles = () => css`
  color: var(--red);
  background-color: var(--red-active);
`;

const unselectedStyles = () => css`
  color: var(--bluish-grey);
  background-color: var(--white);
`;

const hoverUnselectedStyles = () => css`
  color: var(--bluish-grey);
  background-color: var(--light-grey-3);
`;

const SCMobileMenuItem = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  font-family: var(--font1);
  font-size: 16px;
  font-weight: 200;
  padding: 0 20px 0 20px;
  border-radius: 50px;
  transition: all 225ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  ${({ selected }) => (selected ? selectedStyles : unselectedStyles)};

  &:hover {
    ${({ selected }) => (selected ? selectedStyles : hoverUnselectedStyles)};
    opacity: 1;
  }

  > span {
    font-family: var(--font1);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: inherit;
  }

  .menu-item-text {
    margin-left: 10px;
  }

  .menu-dropdown-arrow {
    margin-left: auto;
    transition: transform 225ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

    &.down {
      transform: rotate(90deg);
    }
  }
`;

export default SCMobileMenuItem;
