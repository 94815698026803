import { getDomain } from 'tldjs';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../i18n';

function isObservation(obs) {
  return obs.Name !== undefined;
}

function useImpersonations(domain) {
  const impersonations = useSelector((redux) => redux.impersonations);
  const i18n = useTranslation();

  const domainObject = impersonations.find(
    (monitoredDomain) => monitoredDomain.domain === domain
  );

  const getImpersonations = () => {
    if (!domainObject || !domainObject.domain_impersonation) {
      return [];
    }

    const items = domainObject.domain_impersonation.map((item) => {
      let domainSimilarities = (item.DomainSimilarities || {})[
        getDomain(domainObject.domain) || ''
      ];
      if (
        !domainSimilarities &&
        item.OriginalDomain === getDomain(domainObject.domain)
      ) {
        domainSimilarities = {
          Observations: (item.Observations || []).map((obs) =>
            isObservation(obs) ? obs : { Name: obs, Effect: 0 }
          ),
          Score: i18n.t('common.N/A'),
          Reviewed: false,
        };
      }
      if (!domainSimilarities) {
        return null;
      }
      return {
        name: item.Domain,
        date: (item.Whois || {}).CreatedDate,
        updateDate: (item.Whois || {}).UpdatedDate,
        expirationDate: (item.Whois || {}).ExpirationDate,
        emails:
          item.MXs && item.MXs.length > 0
            ? i18n.t('common.yes')
            : i18n.t('common.no'),
        registrar:
          item.Whois && item.Whois.Registrar && item.Whois.Registrar.Name
            ? item.Whois.Registrar.Name
            : i18n.t('common.N/A'),
        status: item.Status,
        observations: Array.from(new Set(domainSimilarities.Observations)),
        score: domainSimilarities.Score,
        reviewed: domainSimilarities.Reviewed,
      };
    });

    const filteredItems = items.filter((item) => item !== null);

    return filteredItems;
  };

  return {
    impersonations: domainObject ? getImpersonations() : [],
  };
}

export default useImpersonations;
