import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SCCallMeWidget from './CallMeWidget.style';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { useTranslation } from '../../../i18n';
import { isCallMeAvailable, isTawkToAvailable } from '../../../utils/locale';

const CallMeWidget = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const { pathname } = useLocation();

  const showCallMe = isCallMeAvailable(pathname);
  const showTawkTo = isTawkToAvailable(pathname);

  if (!showCallMe && !showTawkTo) {
    return null;
  }

  return (
    <SCCallMeWidget id="call-me-widget">
      {showCallMe && (
        <div className="text-button-container">
          <span>{i18n.t('callMe.weCallYou')}</span>

          <button
            aria-label="call us button"
            type="button"
            onClick={() => dispatch(showPopUp('callUs'))}>
            <FontAwesomeIcon
              icon={faPhoneVolume}
              color="var(--light-grey-4)"
              size="xl"
            />
          </button>
        </div>
      )}

      {showTawkTo && (
        <div id="tawk-to-container" className="tawk-to-text-button-container">
          <span>{i18n.t('callMe.chat')}</span>

          <div className="tawk-to-space" />
        </div>
      )}
    </SCCallMeWidget>
  );
};

export default CallMeWidget;
