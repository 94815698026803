import React from 'react';
import SCToggleButton from './ToggleButton.style';

const ToggleButton = ({ checked, text, onClick, isHidden }) => {
  return (
    <SCToggleButton checked={checked} onClick={onClick} isHidden={isHidden}>
      <div className="InnerToggleButton" />
      <div className="text">{text}</div>
    </SCToggleButton>
  );
};

export default ToggleButton;
