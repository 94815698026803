import React, { useState } from 'react';
import { connect } from 'react-redux';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from '../../i18n';
import OnboardLayout from '../../components/layouts/OnboardLayout/OnboardLayout';
import SectionCard from '../../components/molecules/SectionCard/SectionCard';
import GSuiteGuide from '../../components/atoms/GSuiteGuide/GSuiteGuide';
import SCWhitelisting from './Whitelisting.style';
import ExchangeGuide from '../../components/atoms/ExchangeGuide/ExchangeGuide';
import BackButton from '../../components/molecules/BackButton/BackButton';

const Whitelisting = () => {
  const i18n = useTranslation();

  const [isCollapsedGSuite, setIsCollapsedGSuite] = useState(true);
  const [isCollapsedExchange, setIsCollapsedExchange] = useState(true);

  return (
    <OnboardLayout>
      <SCWhitelisting>
        <div>
          <BackButton />
        </div>

        <SectionCard
          headerIcon={faCircleQuestion}
          headerTitle={i18n.t('whitelisting.guideGSuite.title')}
          isCollapsed={isCollapsedGSuite}
          headerCollapsableButtonOnClick={() =>
            setIsCollapsedGSuite(!isCollapsedGSuite)
          }>
          <GSuiteGuide />
        </SectionCard>
        <SectionCard
          headerIcon={faCircleQuestion}
          headerTitle={i18n.t('whitelisting.guideMicrosoftExchange.title')}
          isCollapsed={isCollapsedExchange}
          headerCollapsableButtonOnClick={() =>
            setIsCollapsedExchange(!isCollapsedExchange)
          }>
          <ExchangeGuide />
        </SectionCard>
      </SCWhitelisting>
    </OnboardLayout>
  );
};

export default connect(undefined, {})(Whitelisting);
