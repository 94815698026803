import styled from '@emotion/styled';

const SCNewPhisingCampaignType = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  .NewPhisingCampaignEmployeeSelectorCard_SelectorContainer {
    display: flex;
    flex-direction: row;

    gap: 20px;
  }

  .NewPhisingCampaignEmployeeSelectorCard_STypeSelected {
    background: transparent linear-gradient(180deg, #78bc12bf 0%, #78bc12 100%)
      0% 0% no-repeat padding-box !important;
    color: white !important;
    border-color: inherit !important;

    svg {
      color: white !important;
    }
  }

  .NewPhisingCampaignEmployeeSelectorCard_SelectorTable {
    margin-top: 30px;
  }

  .NewPhisingCampaignEmployeeSelectorCard_SelectedRowActions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 65px;
    padding: 15px;

    background-color: var(--light-grey);
    border-radius: 5px;

    > p {
      color: var(--bluish-grey);
      text-align: end;
    }

    .NewPhisingCampaignEmployeeSelectorCard_SelectedRowActionsInfo {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;

      p {
        color: var(--bluish-grey);
        font-weight: 500;
      }
    }
    .NewPhisingCampaignEmployeeSelectorCard_SelectedRowActionsAction {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }
`;

export default SCNewPhisingCampaignType;
