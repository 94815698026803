export const FILTER_OPERATORS = {
  EQUAL: '==',
  NOT_EQUAL: '!=',
  GREATER_THAN: '>',
  GREATER_THAN_OR_EQUAL_TO: '>=',
  LOWER_THAN: '<',
  LOWER_THAN_OR_EQUAL_TO: '<=',
  LIKE: '~',
  CONTAINS: '~=',
  CONTAINS_ANY: 'contains-any',
  IN: 'in',
  NOT_IN: 'not-in',
};

const SORT_ORDERS = {
  descend: 'desc',
  ascend: 'asc',
};

/**
   *
   * @param {*} filters
   * const FILTERS_EXAMPLE = [
      {
        field: 'createdAt',
        operator: FILTER_OPERATORS.GREATER_THAN,
        value: '2024-08-12T00:00:00.000Z',
      },
      {
        field: 'createdAt',
        operator: FILTER_OPERATORS.LOWER_THAN,
        value: '2024-08-22T00:00:00.000Z',
      },
      {
        field: 'company.cif',
        operator: FILTER_OPERATORS.EQUAL,
        value: 'A28805127',
      },
    ];
   *
   */
export const filtersToString = (filters) => {
  if (!filters) {
    return null;
  }

  const mappedFilters = filters
    .filter(({ value }) => value !== undefined && value !== null)
    .map(({ field, operator, value }) => `${field} ${operator} ${value}`)
    .join(', ');

  return mappedFilters;
};

export const sortToString = ({ field, order } = {}) => {
  const normalizedOrder = SORT_ORDERS[order] ?? SORT_ORDERS.descend;

  if (!order) {
    return null;
  }

  return `${field}:${normalizedOrder}`;
};
