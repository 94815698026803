import React from 'react';
import SCProfileEmployees from './ProfileEmployees.style';
import ProfileEmployeesFilterCard from '../ProfileEmployeesFilterCard/ProfileEmployeesFilterCard';
import SectionLayout from '../../layouts/SectionLayout/SectionLayout';
import SectionHeader from '../../molecules/SectionHeader/SectionHeader';

const ProfileEmployees = () => {
  return (
    <SectionLayout>
      <SCProfileEmployees>
        <div className="section-organism">
          <SectionHeader />
        </div>

        <ProfileEmployeesFilterCard />
      </SCProfileEmployees>
    </SectionLayout>
  );
};

export default ProfileEmployees;
