import React from 'react';
import SCThreatCounter from './ThreatCounter.style';
import Icon from '../../atoms/Icon/Icon';
import { getReduceNumber } from '../../../utils/format/dataFormat';

const ThreatCounter = ({ iconName, description, amount, isHighlighted }) => {
  return (
    <SCThreatCounter isHighlighted={isHighlighted}>
      <div className="ManagedThreat_Icon">
        <Icon
          iconName={iconName}
          size="navIcon"
          color={isHighlighted ? 'var(--red)' : 'var(--bluish-grey)'}
        />
      </div>
      <div className="ManagedThreat_Info">
        <p className="ManagedThreat_InfoData">
          {amount !== undefined && amount !== null
            ? getReduceNumber(amount)
            : '-'}
        </p>
        <p>{description}</p>
      </div>
    </SCThreatCounter>
  );
};
export default ThreatCounter;
