import { useState } from 'react';

function useCollapsableCardStatus(localStorageCardKey) {
  const isCollapsableCardOpened = () => {
    const isCollapsedStatus = localStorage.getItem(localStorageCardKey);

    if (isCollapsedStatus) {
      return isCollapsedStatus === 'true';
    }

    return false;
  };

  const [collapsableCardOpened, setCollapsableCardOpened] = useState(
    isCollapsableCardOpened()
  );

  const switchCollapsableCardStatus = () => {
    const newCollapsableCardOpenedStatus = !isCollapsableCardOpened();
    localStorage.setItem(localStorageCardKey, newCollapsableCardOpenedStatus);
    setCollapsableCardOpened(newCollapsableCardOpenedStatus);
  };

  return {
    collapsableCardOpened,
    switchCollapsableCardStatus,
  };
}

export default useCollapsableCardStatus;
