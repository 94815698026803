import axios from 'axios';
import sha1 from 'sha1';

export const validatePasswordRegex = (password) => {
  const reg = new RegExp(/^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/gm);

  return reg.test(password);
};

export const passwordPwned = async (password) => {
  let isPwned = false;
  const digit5 = sha1(password).substring(0, 5);
  const checkRest = sha1(password).replace(digit5, '');

  await axios
    .get(`https://api.pwnedpasswords.com/range/${digit5}`)
    .then((res) => {
      const pass = res.data.split('\r\n');

      // Check each row of array with hash passwords and see if user's password exists
      for (let i = 0; i < pass.length; i += 1) {
        if (pass[i].indexOf(checkRest.toUpperCase()) > -1) {
          // Password has been pwned!
          isPwned = true;
          break;
        }
      }
    });

  return isPwned;
};
