import React from 'react';
import { faArrowUpShortWide } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import SCLandingSection from './landingSection.style';
import { useTranslation } from '../../i18n';
import ActionTable from '../../components/organisms/ActionTable/ActionTable';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import ActionsProgress from '../../components/organisms/ActionsProgress/ActionsProgress';
import AnyDoubts from '../../components/molecules/AnyDoubts/AnyDoubts';
import SubscriptionBanner from '../../components/organisms/SubscriptionBanner/SubscriptionBanner';
import { getIncompletedActions } from '../../utils/functions/actions';
import SecurityOverview from '../../components/organisms/SecurityOverview/SecurityOverview';

const LandingSection = () => {
  const i18n = useTranslation();
  const client = useSelector((redux) => redux.client);

  const incompletedActions = getIncompletedActions(client.status.actions);

  return (
    <SectionLayout>
      <SCLandingSection>
        <SubscriptionBanner />

        {/* {!client?.finishedTutorials?.homeBannerImproveSecurity && (
          <div className="section-organism">
            <TryActionCard />
          </div>
        )} */}

        {/* <div className="section-organism">
          <TryPhishingCard />
        </div> */}

        <div className="section-organism">
          <SecurityOverview />
        </div>


        <div className="section-organism">
          <ActionTable
            actions={incompletedActions.slice(0, 3)}
            title={i18n.t(`action-center.pendingActions`)}
            headerIcon={faArrowUpShortWide}
            minified
          />
        </div>

        <div className="section-organism">
          <ActionsProgress title={i18n.t('action-center.completedActions')} />
        </div>

        <AnyDoubts />
      </SCLandingSection>
    </SectionLayout>
  );
};

export default LandingSection;
