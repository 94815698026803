import axios from 'axios';

export const createTag = async (
  clientId,
  tagName,
  tagColor,
  teamMembersEmails
) => {
  const response = await axios.post(`/clients/${clientId}/tags`, {
    tagName,
    tagColor,
    teamMembersEmails,
  });

  return response;
};

export const updateTag = async (clientId, tagId, tagColor) => {
  const response = await axios.put(`/clients/${clientId}/tags/${tagId}`, {
    tagColor,
  });

  return response;
};

export const deleteTag = async (clientId, tagId) => {
  const response = await axios.delete(`/clients/${clientId}/tags/${tagId}`);

  return response;
};

export const editTagsToTeamMembers = (
  clientId,
  teamMembersHashedEmails,
  teamMembersTagModifications,
  deferred
) => {
  return axios.put(`/clients/${clientId}/tags/members`, {
    teamMembersHashedEmails,
    teamMembersTagModifications,
    deferred,
  });
};
