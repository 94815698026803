import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getParameterByName } from '../../utils/helper';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import AnyDoubts from '../../components/molecules/AnyDoubts/AnyDoubts';
import BackButton from '../../components/molecules/BackButton/BackButton';
import AffectedMonitoredEmailsCard from '../../components/organisms/AffectedMonitoredEmailsCard/AffectedMonitoredEmailsCard';
import BreachesByEmployeeCard from '../../components/organisms/BreachesByEmployeeCard/BreachesByEmployeeCard';
import BreachInformationCard from '../../components/organisms/BreachInformationCard/BreachInformationCard';
import NewBreachesDetectedCard from '../../components/organisms/NewBreachesDetectedCard/NewBreachesDetectedCard';
import ProgressMeasuresTakenCard from '../../components/organisms/ProgressMeasuresTakenCard/ProgressMeasuresTakenCard';
import ResolvedBreachesCard from '../../components/organisms/ResolvedBreachesCard/ResolvedBreachesCard';
import SummaryCard from '../../components/organisms/SummaryCard/SummaryCard';
import VisionByEmployeeBreachesCard from '../../components/organisms/VisionByEmployeeBreachesCard/VisionByEmployeeBreachesCard';
import { showPopUp } from '../../redux/actions/popUp.actions';
import SCBreachesSection from './BreachesSection.style';
import SectionHeader from '../../components/molecules/SectionHeader/SectionHeader';

const BreachesSection = () => {
  const client = useSelector((redux) => redux.client);
  const dispatch = useDispatch();
  const activeEmail = getParameterByName('email', window.location.href);
  const activeBreach = getParameterByName('breach', window.location.href);
  const activeNotification = getParameterByName(
    'notification',
    window.location.href
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (
      client &&
      (!client.finishedTutorials ||
        (client.finishedTutorials && !client.finishedTutorials.breach))
    ) {
      dispatch(showPopUp('tutorial', 'breach'));
    }
  }, []);

  return (
    <SectionLayout>
      <SCBreachesSection>
        {/* VISTA PRINCIPAL */}
        {!activeEmail && !activeBreach && !activeNotification && (
          <>
            <div className="section-organism">
              <SectionHeader />
            </div>

            <div className="section-organism">
              <SummaryCard />
            </div>

            <div className="section-organism">
              <NewBreachesDetectedCard />
            </div>

            <div className="section-organism">
              <ResolvedBreachesCard />
            </div>

            <div className="section-organism">
              <VisionByEmployeeBreachesCard />
            </div>

            <AnyDoubts />
          </>
        )}

        {/* VISTA INFORMACIÓN DE LA FILTRACIÓN - Al hacer click sobre fila de las tablas "Nuevas filtraciones críticas" o "Filtraciones resueltas" */}
        {activeBreach && (
          <>
            <div className="section-back-button">
              <BackButton />
            </div>

            <div className="section-organism">
              <ProgressMeasuresTakenCard />
            </div>

            <div className="section-organism">
              <BreachInformationCard />
            </div>

            <div className="section-organism">
              <AffectedMonitoredEmailsCard />
            </div>
          </>
        )}

        {/* VISTA FILTRACIONES POR EMPLEADO */}
        {activeEmail && (
          <>
            <div className="section-back-button">
              <BackButton />
            </div>

            <div className="section-organism">
              <BreachesByEmployeeCard />
            </div>
          </>
        )}
      </SCBreachesSection>
    </SectionLayout>
  );
};

export default BreachesSection;
