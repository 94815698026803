import { firestore } from '../../firebase';
import { GET_DNS_RECORDS } from './types';

const timestampToDateString = (timestamp: any) => {
  const date = `${timestamp.toDate().getUTCDate()}/${
    timestamp.toDate().getUTCMonth() + 1
  }/${timestamp.toDate().getUTCFullYear()}`;
  return date;
};

export const setDNSListener = (clientAtEmail: any, dispatch: any) => {
  firestore
    .collection('scans')
    .doc(clientAtEmail)
    .get()
    .then(async (doc: any) => {
      let domainObject = null;

      if (doc.data() && doc.data().last_results) {
        domainObject = { clientAtEmail, ...doc.data().last_results };
        domainObject.date = timestampToDateString(domainObject.timestamp);
      }

      dispatch({
        type: GET_DNS_RECORDS,
        payload: domainObject,
      });
    });
};

export default true;
