import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const SCLicensePlanFeaturesPage = styled.div`
  .section-back-button {
    margin-bottom: 40px;
  }

  span {
    width: 100%;
    min-height: 40px;
    padding: 7px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bluish-grey);
    font-family: var(--font2);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  p {
    color: var(--bluish-grey);
    font-family: var(--font2);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }

  .disclaimer {
    font-size: 14px;
    margin-top: 10px;
    text-align: left;
  }

  .license-plan-cards-container {
    width: 1000px;
    height: 780px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    .license-plan-card {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 5px;
      background-color: var(--white);
      box-shadow: 10px 10px 40px -20px rgba(0, 0, 0, 0.18);

      .license-plan-section {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .button-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      :first-child {
        .highlighted {
          font-weight: 500;
        }
      }
    }

    .highlighted {
      background-color: var(--bluish-grey-4);
    }

    .top-detail {
      margin-top: 60px;
      color: var(--red);
      font-weight: 500;
    }

    p {
      width: 100%;
      height: 80px;
      padding: 7px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const whiteHeader = () => css`
  background-color: var(--white);
  border-color: var(--bluish-grey);
  p {
    color: var(--bluish-grey);
  }
`;

const redHeader = () => css`
  background-color: var(--white);
  border-color: var(--red);
  p {
    color: var(--red);
  }
`;

const fullRedHeader = () => css`
  border: none;
  background-color: var(--red);
  p {
    color: var(--white);
  }
`;

const headerColorStyles = {
  white: whiteHeader,
  red: redHeader,
  fullRed: fullRedHeader,
};

export const SCLicensePlanHeader = styled.div`
  position: absolute;
  width: 250px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 5px 5px 20px -8px rgba(0, 0, 0, 0.7);
  /* border: 1px solid; */
  border-radius: 1px;
  text-align: center;
  padding: 10px;
  border-radius: 5px 5px 5px 5px;

  p {
    font-family: var(--font1);
    font-size: 17px;
    font-weight: 500;
  }

  ${({ color }) => headerColorStyles[color]};

  /* ::after {
    content: '';
    position: absolute;
    top: 100%;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 35px 125px 0 125px;
    border-color: var(--red) transparent transparent transparent;
    transform: rotate(0deg);
  } */
`;
