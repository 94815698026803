import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import SCEmailSecurityFilterCard from './EmailSecurityFilterCard.style';
import { useTranslation } from '../../../i18n';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import { toBase64 } from '../../../utils/functions/base64';
import EmailSecurityTableCard from '../EmailSecurityTableCard/EmailSecurityTableCard';

const EmailSecurityFilterCard = () => {
  const client = useSelector((redux) => redux.client);
  const [isWarnEmail, setIsWarnEmail] = useState(false);
  const i18n = useTranslation();

  useEffect(() => {
    const encodedAtEmail = toBase64(client.atEmail);

    axios
      .get(`/warn-email-providers/${encodedAtEmail}`)
      .then((res) => {
        setIsWarnEmail(res.data.code === 'auth/domain-warnlisted');
      })
      .catch(() => setIsWarnEmail(true));
  }, [client.atEmail]);

  return (
    <SCEmailSecurityFilterCard>
      {isWarnEmail && (
        <div className="section-organism">
          <InfoBanner
            type="warn"
            text={[i18n.t('configureEmailSecurity.warnEmailText')]}
          />
        </div>
      )}

      <div>
        <EmailSecurityTableCard isClickable={!isWarnEmail} />
      </div>
    </SCEmailSecurityFilterCard>
  );
};

export default EmailSecurityFilterCard;
