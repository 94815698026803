import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import Icon from '../Icon/Icon';
import SCTopMenuSubItem from './TopMenuSubItem.style';

const TopMenuSubItem = ({ onClick, icon, text, selected, description }) => {
  return (
    <SCTopMenuSubItem onClick={onClick} selected={selected}>
      <Icon iconName={icon} size="medium" color="var(--red)" />

      <span>{text}</span>

      {description && (
        <div className="description-container">
          <div>
            <Icon
              color="var(--bluish-grey)"
              iconName={faCircleInfo}
              size="medium"
            />
            <p>{description}</p>
          </div>
        </div>
      )}
    </SCTopMenuSubItem>
  );
};

export default TopMenuSubItem;
