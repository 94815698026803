import React from 'react';
import { useTranslation } from '../../../../i18n';

import xssEN from '../../../../img/clientDashboard/xss.png';
import xssDE from '../../../../img/clientDashboard/xssDE.png';
import xssES from '../../../../img/clientDashboard/xssES.png';
import clickjackingEN from '../../../../img/clientDashboard/clickjacking.png';
import clickjackingDE from '../../../../img/clientDashboard/clickjackingDE.png';
import clickjackingES from '../../../../img/clientDashboard/clickjackingES.png';
import mitmEN from '../../../../img/clientDashboard/mitm.png';
import mitmDE from '../../../../img/clientDashboard/mitmDE.png';
import mitmES from '../../../../img/clientDashboard/mitmES.png';
import ddos from '../../../../img/clientDashboard/ddos.svg';
import breachedEN from '../../../../img/clientDashboard/breached.png';
import breachedDE from '../../../../img/clientDashboard/breachedDE.png';
import breachedES from '../../../../img/clientDashboard/breachedES.png';
import impersonationEN from '../../../../img/clientDashboard/impersonation.svg';
import impersonationDE from '../../../../img/clientDashboard/impersonationDE.png';
import impersonationES from '../../../../img/clientDashboard/impersonationES.png';

const images = {
  en: {
    xss: xssEN,
    clickjacking: clickjackingEN,
    mitm: mitmEN,
    ddos,
    breached: breachedEN,
    impersonation: impersonationEN,
  },
  de: {
    xss: xssDE,
    clickjacking: clickjackingDE,
    mitm: mitmDE,
    ddos,
    breached: breachedDE,
    impersonation: impersonationDE,
  },
  es: {
    xss: xssES,
    clickjacking: clickjackingES,
    mitm: mitmES,
    ddos,
    breached: breachedES,
    impersonation: impersonationES,
  },
};

const FaqWebsite = () => {
  const i18n = useTranslation();

  return {
    scoreCalculation: {
      faqTitle: i18n.t('faqText.scoreCalculation.faqTitle'),
      faqAnswer: (
        <div>
          {i18n.t('faqText.scoreCalculation.faqText')}
          <ul>
            <li>0 - 3.3 = {i18n.t('websiteSecurity.lowRisk')}</li>
            <li>3.4 - 6.7 = {i18n.t('websiteSecurity.mediumRisk')}</li>
            <li>6.8 - 10 = {i18n.t('websiteSecurity.highRisk')}</li>
          </ul>
        </div>
      ),
    },
    howToImprove: {
      faqTitle: i18n.t('faqText.howToImprove.faqTitle'),
      faqAnswer: i18n.t('faqText.howToImprove.faqText'),
    },
    xss: {
      faqTitle: i18n.t('faqText.xss.faqTitle'),
      faqAnswer: (
        <div className="content">
          <p>{i18n.t('faqText.xss.faqText')}</p>
          <img
            src={images[i18n.getLanguage()]?.xss ?? images.en.xss}
            alt={i18n.t('faqText.xss.faqTitle')}
            height="auto"
          />
          <h1>{i18n.t('faqText.xss.caseStudy')}</h1>
          <p>{i18n.t('faqText.xss.caseStudyText1')}</p>
          <p>{i18n.t('faqText.xss.caseStudyText2')}</p>
        </div>
      ),
    },
    clickjacking: {
      faqTitle: i18n.t('faqText.clickjacking.faqTitle'),
      faqAnswer: (
        <div className="content">
          <p>{i18n.t('faqText.clickjacking.faqText')}</p>
          <img
            style={{ marginBottom: '20px' }}
            src={
              images[i18n.getLanguage()]?.clickjacking ?? images.en.clickjacking
            }
            alt={i18n.t('faqText.clickjacking.faqTitle')}
            height="auto"
          />
          <h1>{i18n.t('faqText.clickjacking.caseStudy')}</h1>
          <p>{i18n.t('faqText.clickjacking.caseStudyText1')}</p>
          <p>{i18n.t('faqText.clickjacking.caseStudyText2')}</p>
        </div>
      ),
    },
    ddos: {
      faqTitle: i18n.t('faqText.ddos.faqTitle'),
      faqAnswer: (
        <div className="content">
          <p>{i18n.t('faqText.ddos.faqText')}</p>
          <img
            src={images[i18n.getLanguage()]?.ddos ?? images.en.ddos}
            alt={i18n.t('faqText.ddos.faqText')}
            height="auto"
          />
          <h1>{i18n.t('faqText.ddos.caseStudy')}</h1>
          <p>{i18n.t('faqText.ddos.caseStudyText1')}</p>
          <p>{i18n.t('faqText.ddos.caseStudyText2')}</p>
        </div>
      ),
    },
    man_in_the_middle: {
      faqTitle: i18n.t('faqText.man_in_the_middle.faqTitle'),
      faqAnswer: (
        <div className="content">
          <p>{i18n.t('faqText.man_in_the_middle.faqText')}</p>
          <img
            src={images[i18n.getLanguage()]?.mitm ?? images.en.mitm}
            alt={i18n.t('faqText.man_in_the_middle.faqTitle')}
            height="auto"
          />
          <h1>{i18n.t('faqText.man_in_the_middle.forExample')}</h1>
          <p>{i18n.t('faqText.man_in_the_middle.forExampleText')}</p>
        </div>
      ),
    },
    breached: {
      faqTitle: i18n.t('faqText.breached.faqTitle'),
      faqAnswer: (
        <div>
          <p>{i18n.t('faqText.breached.faqText')}</p>
          <img
            src={images[i18n.getLanguage()]?.breached ?? images.en.breached}
            alt={i18n.t('faqText.breached.faqTitle')}
            height="auto"
          />
        </div>
      ),
    },
    impersonation: {
      faqTitle: i18n.t('faqText.impersonation.faqTitle'),
      faqAnswer: (
        <div>
          <p>{i18n.t('faqText.impersonation.faqText')}</p>
          <img
            src={
              images[i18n.getLanguage()]?.impersonation ??
              images.en.impersonation
            }
            alt={i18n.t('faqText.impersonation.faqTitle')}
            height="auto"
          />
        </div>
      ),
    },
  };
};

export default FaqWebsite;
