import React from 'react';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import Icon from '../Icon/Icon';
import SCButtonBubble from './ButtonBubble.style';

const ButtonBubble = ({ tagStyle, onClick, isSelected }) => {
  return (
    <SCButtonBubble type="button" tagStyle={tagStyle} onClick={onClick}>
      {isSelected && <Icon iconName={faCheck} />}
    </SCButtonBubble>
  );
};

export default ButtonBubble;
