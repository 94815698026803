import styled from '@emotion/styled';
import { css } from '@emotion/core';

const selectedStyles = () => css`
  color: var(--red);
  background-color: var(--red-active);
`;

const unselectedStyles = () => css`
  color: var(--bluish-grey);
  background-color: var(--white);
`;

const hoverUnselectedStyles = () => css`
  color: var(--bluish-grey);
  background-color: var(--light-grey-3);
`;

const SCMobileMenuSubItem = styled.button`
  width: 100%;
  height: 35px;
  cursor: pointer;
  padding-left: 47px;
  border-radius: 50px;
  text-align: left;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  ${({ selected }) => (selected ? selectedStyles : unselectedStyles)};

  &:hover {
    ${({ selected }) => (selected ? selectedStyles : hoverUnselectedStyles)};
    opacity: 1;
  }

  > span {
    font-family: var(--font1);
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: inherit;
  }
`;

export default SCMobileMenuSubItem;
