import styled from '@emotion/styled';

const SCMailDetailsCard = styled.div`
  .MailDetailsCard_Details {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }

  .MailDetailsCard_InfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 15px;
    }
  }

  .MailDetailsCard_Info {
    display: flex;
    flex-direction: column;

    span,
    p {
      font-size: 14px;
      margin: 0;
      color: var(--black);
      line-height: 1;
      overflow-wrap: anywhere;
    }
    p {
      font-weight: 700;
    }
    span {
      line-height: 1.2;
    }
  }

  @media (width < 700px) {
    .MailDetailsCard_Details {
      grid-template-columns: auto;
    }
  }
`;

export default SCMailDetailsCard;
