const MEXICAN_STATES = [
  {
    name: 'Aguascalientes',
    ISOAbbreviation: 'AGS',
  },
  {
    name: 'Baja California',
    ISOAbbreviation: 'BCN',
  },
  {
    name: 'Baja California Sur',
    ISOAbbreviation: 'BCS',
  },
  {
    name: 'Campeche',
    ISOAbbreviation: 'CAM',
  },
  {
    name: 'Chiapas',
    ISOAbbreviation: 'CHP',
  },
  {
    name: 'Chihuahua',
    ISOAbbreviation: 'CHI',
  },
  {
    name: 'Ciudad de México',
    ISOAbbreviation: 'DIF',
  },
  {
    name: 'Coahuila',
    ISOAbbreviation: 'COA',
  },
  {
    name: 'Colima',
    ISOAbbreviation: 'COL',
  },
  {
    name: 'Durango',
    ISOAbbreviation: 'DUR',
  },
  {
    name: 'Guanajuato',
    ISOAbbreviation: 'GTO',
  },
  {
    name: 'Guerrero',
    ISOAbbreviation: 'GRO',
  },
  {
    name: 'Hidalgo',
    ISOAbbreviation: 'HGO',
  },
  {
    name: 'Jalisco',
    ISOAbbreviation: 'JAL',
  },
  {
    name: 'México',
    ISOAbbreviation: 'MEX',
  },
  {
    name: 'Michoacán',
    ISOAbbreviation: 'MIC',
  },
  {
    name: 'Morelos',
    ISOAbbreviation: 'MOR',
  },
  {
    name: 'Nayarit',
    ISOAbbreviation: 'NAY',
  },
  {
    name: 'Nuevo León',
    ISOAbbreviation: 'NLE',
  },
  {
    name: 'Oaxaca',
    ISOAbbreviation: 'OAX',
  },
  {
    name: 'Puebla',
    ISOAbbreviation: 'PUE',
  },
  {
    name: 'Querétaro',
    ISOAbbreviation: 'QRO',
  },
  {
    name: 'Quintana Roo',
    ISOAbbreviation: 'ROO',
  },
  {
    name: 'San Luis Potosí',
    ISOAbbreviation: 'SLP',
  },
  {
    name: 'Sinaloa',
    ISOAbbreviation: 'SIN',
  },
  {
    name: 'Sonora',
    ISOAbbreviation: 'SON',
  },
  {
    name: 'Tabasco',
    ISOAbbreviation: 'TAB',
  },
  {
    name: 'Tamaulipas',
    ISOAbbreviation: 'TAM',
  },
  {
    name: 'Tlaxcala',
    ISOAbbreviation: 'TLX',
  },
  {
    name: 'Veracruz',
    ISOAbbreviation: 'VER',
  },
  {
    name: 'Yucatán',
    ISOAbbreviation: 'YUC',
  },
  {
    name: 'Zacatecas',
    ISOAbbreviation: 'ZAC',
  },
];

export default MEXICAN_STATES;
