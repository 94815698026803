import React from 'react';

import {
  faBadgeCheck,
  faEye,
  faGlobe,
  faGraduationCap,
  faHourglassEnd,
  faPaperPlane,
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from '../../../i18n';

const TutorialContent = () => {
  const i18n = useTranslation();

  return {
    phishing: {
      title: i18n.t('tutorials.phishing.title'),
      steps: [
        {
          title: i18n.t('tutorials.phishing.subtitle1'),
          content: [
            <p>{i18n.t('tutorials.phishing.content1a')}</p>,
            <p>{i18n.t('tutorials.phishing.content1b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.phishing.subtitle2'),
          content: [
            <p>{i18n.t('tutorials.phishing.content2a')}</p>,
            <p>{i18n.t('tutorials.phishing.content2b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.phishing.subtitle3'),
          content: [
            {
              icon: faPaperPlane,
              title: i18n.t('tutorials.phishing.box3-1title'),
              p: i18n.t('tutorials.phishing.box3-1text'),
            },
            {
              icon: faHourglassEnd,
              title: i18n.t('tutorials.phishing.box3-2title'),
              p: i18n.t('tutorials.phishing.box3-2text'),
            },
            {
              icon: faGraduationCap,
              title: i18n.t('tutorials.phishing.box3-3title'),
              p: i18n.t('tutorials.phishing.box3-3text'),
            },
          ],
        },
      ],
    },
    deviceSecurity: {
      title: i18n.t('tutorials.deviceSecurity.title'),
      steps: [
        {
          title: i18n.t('tutorials.deviceSecurity.subtitle1'),
          content: [
            <p>{i18n.t('tutorials.deviceSecurity.content1a')}</p>,
            <p>{i18n.t('tutorials.deviceSecurity.content1b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.deviceSecurity.subtitle2'),
          content: [
            <p>{i18n.t('tutorials.deviceSecurity.content2a')}</p>,
            <p>{i18n.t('tutorials.deviceSecurity.content2b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.deviceSecurity.subtitle3'),
          content: [
            <p>{i18n.t('tutorials.deviceSecurity.content3a')}</p>,
            <p>{i18n.t('tutorials.deviceSecurity.content3b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.deviceSecurity.subtitle4'),
          content: [
            <p>{i18n.t('tutorials.deviceSecurity.content4a')}</p>,
            <p>{i18n.t('tutorials.deviceSecurity.content4b')}</p>,
          ],
        },
      ],
    },
    impersonation: {
      steps: [
        {
          title: i18n.t('tutorials.impersonation.subtitle1'),
          content: [
            <p>{i18n.t('tutorials.impersonation.content1a')}</p>,
            <p>{i18n.t('tutorials.impersonation.content1b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.impersonation.subtitle2'),
          content: [
            <p>{i18n.t('tutorials.impersonation.content2a')}</p>,
            <p>{i18n.t('tutorials.impersonation.content2b')}</p>,
            <p>{i18n.t('tutorials.impersonation.content2c')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.impersonation.subtitle3'),
          content: [
            {
              icon: faGlobe,
              p: i18n.t('tutorials.impersonation.box3-1text'),
            },
            {
              icon: faBadgeCheck,
              p: i18n.t('tutorials.impersonation.box3-2text'),
            },
            {
              icon: faEye,
              p: i18n.t('tutorials.impersonation.box3-3text'),
            },
          ],
        },
      ],
    },
    actions: {
      title: i18n.t('tutorials.actions.title'),
      steps: [
        {
          title: i18n.t('tutorials.actions.subtitle1'),
          content: [
            <p>{i18n.t('tutorials.actions.content1a')}</p>,
            <p>{i18n.t('tutorials.actions.content1b')}</p>,
            <p>{i18n.t('tutorials.actions.content1c')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.actions.subtitle2'),
          content: [
            <p>{i18n.t('tutorials.actions.content2a')}</p>,
            <p>{i18n.t('tutorials.actions.content2b')}</p>,
            <p>{i18n.t('tutorials.actions.content2c')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.actions.subtitle3'),
          content: [
            <p>{i18n.t('tutorials.actions.content3a')}</p>,
            <p>{i18n.t('tutorials.actions.content3b')}</p>,
          ],
        },
      ],
    },
    website: {
      title: i18n.t('tutorials.website.title'),
      steps: [
        {
          title: i18n.t('tutorials.website.subtitle1'),
          content: [
            <p>{i18n.t('tutorials.website.content1a')}</p>,
            <p>{i18n.t('tutorials.website.content1b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.website.subtitle2'),
          content: [<p>{i18n.t('tutorials.website.content2a')}</p>],
        },
        {
          title: i18n.t('tutorials.website.subtitle3'),
          content: [
            <p>{i18n.t('tutorials.website.content3a')}</p>,
            <p>{i18n.t('tutorials.website.content3b')}</p>,
            <p>{i18n.t('tutorials.website.content3c')}</p>,
          ],
        },
      ],
    },
    breach: {
      title: i18n.t('tutorials.breach.title'),
      steps: [
        {
          title: i18n.t('tutorials.breach.subtitle1'),
          content: [
            <p>{i18n.t('tutorials.breach.content1a')}</p>,
            <p>{i18n.t('tutorials.breach.content1b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.breach.subtitle2'),
          content: [
            <p>{i18n.t('tutorials.breach.content2a')}</p>,
            <p>{i18n.t('tutorials.breach.content2b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.breach.subtitle3'),
          content: [<p>{i18n.t('tutorials.breach.content3a')}</p>],
        },
        {
          title: i18n.t('tutorials.breach.subtitle4'),
          content: [
            <p>{i18n.t('tutorials.breach.content4a')}</p>,
            <p>{i18n.t('tutorials.breach.content4b')}</p>,
            <p>{i18n.t('tutorials.breach.content4c')}</p>,
          ],
        },
      ],
    },
    supplier: {
      steps: [
        {
          title: i18n.t('tutorials.supplier.subtitle1'),
          content: [
            <p>{i18n.t('tutorials.supplier.content1a')}</p>,
            <p>{i18n.t('tutorials.supplier.content1b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.supplier.subtitle2'),
          content: [
            <p>{i18n.t('tutorials.supplier.content2a')}</p>,
            <p>{i18n.t('tutorials.supplier.content2b')}</p>,
            <p>{i18n.t('tutorials.supplier.content2c')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.supplier.subtitle3'),
          content: [
            <p>{i18n.t('tutorials.supplier.content3a')}</p>,
            <p>{i18n.t('tutorials.supplier.content3b')}</p>,
            <p>{i18n.t('tutorials.supplier.content3c')}</p>,
            <p>{i18n.t('tutorials.supplier.content3d')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.supplier.subtitle4'),
          content: [
            <p>{i18n.t('tutorials.supplier.content4a')}</p>,
            <p>{i18n.t('tutorials.supplier.content4b')}</p>,
          ],
        },
      ],
    },
    emailSecurity: {
      steps: [
        {
          title: i18n.t('tutorials.emailSecurity.subtitle1'),
          content: [
            <p>{i18n.t('tutorials.emailSecurity.content1a')}</p>,
            <p>{i18n.t('tutorials.emailSecurity.content1b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.emailSecurity.subtitle2'),
          content: [
            <p>{i18n.t('tutorials.emailSecurity.content2a')}</p>,
            <p>{i18n.t('tutorials.emailSecurity.content2b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.emailSecurity.subtitle3'),
          content: [
            <p>{i18n.t('tutorials.emailSecurity.content3a')}</p>,
            <p>{i18n.t('tutorials.emailSecurity.content3b')}</p>,
            <p>{i18n.t('tutorials.emailSecurity.content3c')}</p>,
          ],
        },
      ],
    },
    managedThreats: {
      steps: [
        {
          title: i18n.t('tutorials.managedThreats.subtitle1'),
          content: [
            <p>{i18n.t('tutorials.managedThreats.content1a')}</p>,
            <p>{i18n.t('tutorials.managedThreats.content1b')}</p>,
            <p>{i18n.t('tutorials.managedThreats.content1c')}</p>,
          ],
        },
      ],
    },
    mailboxSecurity: {
      title: i18n.t('tutorials.mailboxSecurity.title'),
      steps: [
        {
          title: i18n.t('tutorials.mailboxSecurity.subtitle1'),
          content: [
            <p>{i18n.t('tutorials.mailboxSecurity.content1a')}</p>,
            <p>{i18n.t('tutorials.mailboxSecurity.content1b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.mailboxSecurity.subtitle2'),
          content: [
            <p>{i18n.t('tutorials.mailboxSecurity.content2a')}</p>,
            <p>{i18n.t('tutorials.mailboxSecurity.content2b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.mailboxSecurity.subtitle3'),
          content: [
            <p>{i18n.t('tutorials.mailboxSecurity.content3a')}</p>,
            <p>{i18n.t('tutorials.mailboxSecurity.content3b')}</p>,
          ],
        },
        {
          title: i18n.t('tutorials.mailboxSecurity.subtitle4'),
          content: [
            <p>{i18n.t('tutorials.mailboxSecurity.content4a')}</p>,
            <p>{i18n.t('tutorials.mailboxSecurity.content4b')}</p>,
            <p>{i18n.t('tutorials.mailboxSecurity.content4c')}</p>,
          ],
        },
      ],
    },
  };
};

export default TutorialContent;
