import React from 'react';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import SCMaintenanceBanner from './MaintenanceBanner.style';
import { useTranslation } from '../../../i18n';

const MaintenanceBanner = () => {
  const i18n = useTranslation();

  return (
    <SCMaintenanceBanner>
      <InfoBanner
        type="warn"
        text={[
          i18n.t('maintenance.globalBanner.mainText'),
          i18n.t('maintenance.globalBanner.subtext'),
        ]}
      />
    </SCMaintenanceBanner>
  );
};

export default MaintenanceBanner;
