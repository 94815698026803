import React from 'react';
import { useTranslation } from '../../../i18n';
import SCChartSpinner from './ChartSpinner.style';

const ChartSpinner = () => {
  const i18n = useTranslation();
  return (
    <SCChartSpinner>
      <span className="loading__anim" />
      <span className="tag">{i18n.t('score.analysingChartTag')}</span>
    </SCChartSpinner>
  );
};

export default ChartSpinner;
