import styled from '@emotion/styled';

const SCActionTable = styled.div`
  .inputContainer {
    margin-bottom: 30px;
  }

  .actionRow {
    display: flex;
    flex-direction: row;
    place-content: space-between;
    align-items: center;
    transform: translate(-30px, 0px);
    width: calc(100% + 60px);
    padding: 0 30px;
    height: 50px;
    cursor: pointer;
    &:hover {
      background: var(--light-grey);
    }
  }

  .actionRowLeft {
    display: flex;
    flex-direction: row;
    place-content: flex-start;
    align-items: center;
    gap: 25px;

    width: 90%;

    & p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      max-width: 60ch;
      text-align: left;
      margin: 0;
    }
  }

  .actionRowProgress {
    width: 80px;
    min-width: 80px;
  }

  .seeMoreAction {
    margin-top: 20px;
  }

  @media (max-width: 576px) {
    .actionRowLeft {
      gap: 10px;
    }

    .actionRowProgress {
      display: none;
    }
  }
`;

export default SCActionTable;
