import React, { useEffect, useState } from 'react';

import {
  faPaperPlane,
  faCheck,
  faAlarmClock,
  faBallotCheck,
  faUserGraduate,
} from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpShortWide } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import {
  SCProgressTutorial,
  Card,
  Horizontal,
  Steps,
  CardContainer,
} from './ProgressTutorial.style';
import Icon from '../../atoms/Icon/Icon';
import useSection from '../../../hooks/useSection/useSection';

const icons = {
  campaign: [
    faCheck,
    faAlarmClock,
    faBallotCheck,
    faPaperPlane,
    faUserGraduate,
  ],
};

const ProgressTutorial = ({ current, campaignName }) => {
  const i18n = useTranslation();
  const { section } = useSection();

  const [page, setPage] = useState(section || '');

  useEffect(() => {
    setPage(section);
  }, [section]);

  if (!section) return null;

  return (
    <SCProgressTutorial>
      <SectionCard
        headerIcon={faArrowUpShortWide}
        headerTitle={`${i18n.t(
          `tools4StepsTutorial.${page}.campaign.title`
        )} '${campaignName}'`}>
        <Steps>
          {icons.campaign.map((item, index) => (
            <>
              <Card
                key={
                  i18n.t(`tools4StepsTutorial.${page}.campaign.steps`)[
                    index + 1
                  ]
                }>
                <CardContainer
                  color={current > index && 'var(--green)'}
                  backgroundColor={current > index && 'var(--green-gradient)'}>
                  <Icon
                    size={current > index ? 'topMenuIcon' : undefined}
                    iconName={current > index ? faCheck : item}
                    color={current > index && 'var(--white)'}
                    backgroundColor={current > index && 'var(--light-green)'}
                  />
                </CardContainer>
                <div className="tuturial-step-text">
                  {i18n.t(`tools4StepsTutorial.${page}.campaign.steps`)[index]}
                </div>
              </Card>
              {index < icons.campaign.length - 1 ? <Horizontal /> : ''}
            </>
          ))}
        </Steps>
      </SectionCard>
    </SCProgressTutorial>
  );
};

export default ProgressTutorial;
