import styled from '@emotion/styled';

const SCTagFilter = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--light-grey);
  border-radius: 5px;

  .TagFilter_MainContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    padding: 15px 0px;
  }

  .TagFilter_ContentContainer {
    display: flex;
    align-items: flex-start;

    gap: 10px;
  }

  .TagFilter_CloseActionContainer {
    display: flex;
    align-items: center;
    height: 35px;
  }

  .TagFilter_NewFilter {
      width:100%;
      > button{
        width:100%;
      }
    .icon-text-container {
      justify-content: flex-start;
    }
  }

  .TagFilter_InfoContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
    color: var(--bluish-grey);
  }
  
  .TagFilter_IndexContainer {
    padding-left: 20px;
  }


  .TagFilter_Selections {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    gap: 8px;
  }

  .TagFilter_Selection {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 8px;

    > p {
      color: var(--bluish-grey);
    }
  }

  .TagFilter_LabelContainer {
    white-space: nowrap;

  }  
}
`;

export default SCTagFilter;
