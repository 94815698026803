import React from 'react';
import Icon from '../Icon/Icon';
import SCButton from './Button.style';

const Button = ({
  onClick,
  icon,
  text,
  isHidden,
  size,
  width,
  color,
  disabled,
  ...props
}) => {
  return (
    <SCButton
      type="button"
      onClick={onClick}
      isHidden={isHidden}
      size={size}
      color={color}
      width={width}
      disabled={disabled}
      {...props}>
      <div className="icon-text-container">
        {icon && <Icon iconName={icon} size="medium" />}
        {text}
      </div>
    </SCButton>
  );
};

export default Button;
