import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/atoms/Button/Button';
import { useTranslation } from '../../i18n';
import SCNotFound from './NotFound.style';

const NotFound = () => {
  const i18n = useTranslation();
  const navigate = useNavigate();

  return (
    <SCNotFound>
      <div className="not-found-card">
        <div className="card-content-container">
          <h1>404</h1>

          <h2>{i18n.t('notFound.header')}</h2>

          <p>{i18n.t('notFound.content')}</p>

          <Button
            text={i18n.t('notFound.buttonText')}
            onClick={() => {
              navigate('/');
            }}
            size="full"
            color="red"
          />
        </div>
      </div>
    </SCNotFound>
  );
};

export default NotFound;
