import React, { useEffect, useState } from 'react';
import {
  faBellExclamation,
  faBullhorn,
  faUserPlus,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faBellOn,
  faPaperPlane,
  faEnvelopeOpen,
  faSearch,
  faUser,
  faBadgeCheck,
  faFileCheck,
  faUserCrown,
  faListOl,
  faEye,
  faBoxOpen,
  faCircleQuestion,
  faShieldCheck,
  faCircleRadiation,
  faUserShield,
  faRocket,
  faUserClock,
  faBookReader,
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import {
  SCTool4StepsTutorial,
  Card,
  Horizontal,
  Steps,
} from './Tool4StepsTutorial.style';
import Icon from '../../atoms/Icon/Icon';
import useCollapsableCardStatus from '../../../hooks/collapsableCardStatus/useCollapsableCardStatus';
import useSection from '../../../hooks/useSection/useSection';

const icons = {
  'supplier-security': [faBoxOpen, faFileCheck, faListOl, faEye],
  websitesecurity: [faUserCrown, faPaperPlane, faFileCheck, faBellOn],
  'data-alert': [faUserPlus, faBellExclamation, faSearch, faBullhorn],
  domainimpersonation: [faUserCrown, faFileCheck, faListOl, faEye],
  phishingsimulator: [faUserShield, faRocket, faUserClock, faBookReader],
  'device-security': [faEnvelopeOpen, faBadgeCheck, faBellOn],
  'configure-email-security': [faUser, faBadgeCheck, faFileCheck, faBellOn],
  'mailbox-security': [faUser, faShieldCheck, faCircleRadiation],
};

const Tool4StepsTutorial = () => {
  const i18n = useTranslation();
  const { section } = useSection();

  const [page, setPage] = useState(section || '');
  const { collapsableCardOpened, switchCollapsableCardStatus } =
    useCollapsableCardStatus(`${section}.training.hide`);

  useEffect(() => {
    setPage(section);
  }, [section]);

  if (!section) return null;
  return (
    <SCTool4StepsTutorial>
      <SectionCard
        headerIcon={faCircleQuestion}
        headerTitle={i18n.t(`tools4StepsTutorial.${page}.title`)}
        isCollapsed={collapsableCardOpened}
        headerCollapsableButtonOnClick={() => switchCollapsableCardStatus()}>
        <Steps>
          {icons[page].map((item, index) => (
            <>
              <Card key={`t4st${index}`}>
                <div className="tutorial-icon-wrapper">
                  <Icon iconName={item} />
                </div>
                <div className="tuturial-step-text">
                  {i18n.t(`tools4StepsTutorial.${page}.step-titles`)[index]}
                </div>
              </Card>
              {index < icons[page].length - 1 ? <Horizontal /> : ''}
            </>
          ))}
        </Steps>
      </SectionCard>
    </SCTool4StepsTutorial>
  );
};

export default Tool4StepsTutorial;
