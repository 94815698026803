const useRecaptcha = () => {
  const getToken = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_RECAPTCHAV3_KEY, { action: 'verify' })
          .then((token) => {
            resolve(token);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  };

  return {
    getRecaptchaToken: getToken,
  };
};

export default useRecaptcha;
