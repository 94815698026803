import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';

const BackButton = () => {
  const navigate = useNavigate();
  const i18n = useTranslation();

  return (
    <Button
      onClick={() => {
        navigate(-1);
      }}
      icon={faArrowLeft}
      text={i18n.t('phishingSimulator.back')}
      size="small"
      color="white"
    />
  );
};

export default BackButton;
