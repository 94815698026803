import styled from '@emotion/styled';

const SCQualificationFormQuestion = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  p,
  label,
  span {
    color: var(--bluish-grey);
  }

  .StepQuestion_Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;

    .StepQuestion_Questions {
      width: 100%;

      h2 {
        color: var(--bluish-grey);
        font-weight: 500 !important;
      }
    }
    .StepQuestion_Answers {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 5px;

      input {
        accent-color: var(--red);
      }

      .StepQuestion_Answer {
        display: flex;
        gap: 10px;

        label {
          display: flex;
        }
        input {
          margin-right: 10px;
        }
        > p,
        a {
          line-height: 1;
        }
        a {
          font-family: var(--font2);
        }
      }

      .StepQuestion_MatricialRow {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 20px;
        width: 100%;

        > p {
          white-space: nowrap;
        }

        input {
          margin-right: 0px;
        }
      }

      .StepQuestion_SubQuestion {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .StepQuestion_SubAnswers {
          width: 100%;
          display: flex;
          align-items: flex-end;
          gap: 10px;
          min-height: 35px;
          justify-content: space-between;

          .StepQuestion_SubAnswersContainer {
            display: flex;
            gap: 15px;
            width: 200px;
            justify-content: space-between;
          }
        }
        .StepQuestion_SubAnswers:not(:first-of-type) {
          .StepQuestion_SubAnswersContainer {
            .StepQuestion_MatricialRow {
              p {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .StepQuestion_AnswersDesc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-content: flex-start;
    width: 100%;

    p {
      font-size: 14px;
    }
  }
  .StepQuestion_Actions {
    display: flex;
  }

  @media (max-width: 769px) {
    .StepQuestion_Wrapper {
      .StepQuestion_Answers {
        .StepQuestion_SubQuestion {
          .StepQuestion_SubAnswers {
            flex-direction: column;
            align-items: center;
            gap: 15px;
            margin-bottom: 20px;
            > .StepQuestion_Answer {
              width: 100%;
            }
            .StepQuestion_SubAnswersContainer {
              width: 100%;
              .StepQuestion_MatricialRow {
                p {
                  display: flex !important;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default SCQualificationFormQuestion;
