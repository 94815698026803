import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showPopUp } from '../../redux/actions/popUp.actions';
import monitoredDomainsService from '../../services/monitoredDomains/monitoredDomains.service';

const useMonitoredDomains = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const deleteMonitoredDomain = async (domain) => {
    setLoading(true);

    return monitoredDomainsService.deleteMonitoredDomain(domain).finally(() => {
      setLoading(false);
      dispatch(showPopUp(null));
    });
  };

  return {
    loading,
    error,
    deleteMonitoredDomain,
  };
};

export default useMonitoredDomains;
