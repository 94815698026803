import {} from '@fortawesome/pro-regular-svg-icons';
import {
  faCircleExclamation,
  faCircleInfo,
} from '@fortawesome/pro-solid-svg-icons';

export const getSecurityText = (score) => {
  let securityText = 'pending';

  if (score === undefined || score === null || score > 10 || score < 0) {
    return 'pending';
  }

  if (score >= 0 && score < 2.5) {
    securityText = 'criticalSecurity';
  }

  if (score >= 2.5 && score < 5) {
    securityText = 'lowSecurity';
  }

  if (score >= 5 && score < 7.5) {
    securityText = 'mediumSecurity';
  }

  if (score >= 7.5 && score <= 10) {
    securityText = 'highSecurity';
  }

  return securityText;
};

export const getSecurityIcon = (level) => {
  // switch (level) {
  //   case 'danger':
  //     return faCircleExclamation;
  //   case 'info':
  //     return faCircleInfo;
  //   case 'warning':
  //     return faCircleExclamation;
  //   default:
  //     return faCircleExclamation;
  // }

  return level === 'info' ? faCircleInfo : faCircleExclamation;
};

export const getSecurityColor = (score) => {
  let securityColor = 'default';

  if (score === undefined || score === null || score > 10 || score < 0) {
    return 'default';
  }

  if (score >= 0 && score < 2.5) {
    securityColor = 'red';
  }

  if (score >= 2.5 && score < 5) {
    securityColor = 'lightRed';
  }

  if (score >= 5 && score < 7.5) {
    securityColor = 'orange';
  }

  if (score >= 7.5 && score <= 10) {
    securityColor = 'green';
  }

  return securityColor;
};

export const getEmailSecurityColor = (level) => {
  if (level === 'strong' || level === 'compliant') {
    return 'green';
  }
  if (level === 'notSet') {
    return 'red';
  }
  return 'red';
};

/**
 * @param {*} dangerousLevel critical || lessCritical
 * @returns color for the dangerousLevel
 */
export const getBreachesSecurityColor = (dangerousLevel) => {
  return dangerousLevel === 'critical' ? 'red' : 'lightRed';
};
