import React, { useState } from 'react';
import styled from '@emotion/styled';

import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import { useTranslation } from '../../../i18n';
import { auth } from '../../../firebase';
import { showPopUp } from '../../../redux/actions/popUp.actions';

const SCEmailVerificationExpired = styled.div`
  align-items: center;

  .Notification_IconCircle {
    border-radius: 50vh;
    padding: 10px;
    border: 5px solid var(--red);
  }

  h1,
  p {
    font-weight: 500;
  }

  h1 {
    font-family: var(--font1);
  }

  p {
    font-family: var(--font2);
  }

  .disclaimer {
    color: var(--red);
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .custom-sub-text {
    font-size: 14px;
    color: var(--grey);
  }
`;

const EmailVerificationExpired = (props) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notificationData } = props;
  const { onButtonClick } = notificationData;

  const [emailSended, setEmailSended] = useState(undefined);

  const isSignedIn = auth?.currentUser;

  const handleSendEmailButton = () => {
    onButtonClick()
      .then((resp) => {
        setEmailSended(true);
      })
      .catch((error) => {
        setEmailSended(false);
      });
  };

  return (
    <ModalLayout>
      <SCEmailVerificationExpired>
        <div className="Notification_IconCircle">
          <Icon iconName={faXmark} size="large" color="var(--red)" />
        </div>
        <h1>{i18n.t('common.error')}</h1>
        <div className="modal-card-content text-container">
          <p>
            {i18n.t(
              isSignedIn
                ? 'emailVerification.expiredOobcode'
                : 'emailVerification.expiredOobcodeNotSignedIn'
            )}
          </p>
        </div>

        <div className="modal-card-buttons">
          {isSignedIn ? (
            <>
              {emailSended === true && (
                <p className="disclaimer">
                  {i18n.t('emailVerification.verificationSucess')}
                </p>
              )}
              {emailSended === false && (
                <p className="disclaimer">
                  {i18n.t('emailVerification.verificationFailed')}
                </p>
              )}
              {emailSended === undefined && (
                <Button
                  size="full"
                  color="bluishGrey"
                  text={i18n.t('emailVerification.sendVerification')}
                  onClick={handleSendEmailButton}
                />
              )}
            </>
          ) : (
            <Button
              size="full"
              color="bluishGrey"
              text={i18n.t('emailVerification.backToSignIn')}
              onClick={() => {
                navigate('/sign-in');
                dispatch(showPopUp(null));
              }}
            />
          )}
        </div>
      </SCEmailVerificationExpired>
    </ModalLayout>
  );
};

export default EmailVerificationExpired;
