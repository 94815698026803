import { firestore } from '../../firebase';
import { GET_SUBSCRIPTION } from './types';

const setSubscriptionListener = (clientId, dispatch) => {
  firestore
    .collection('clients')
    .doc(clientId)
    .collection('subscriptions')
    .where('product', 'in', [
      'default',
      'trial',
      'trialProtection',
      'monitoredBroker',
      'monitored',
      'paying',
    ])
    .orderBy('startTime', 'desc')
    .limit(10)
    .onSnapshot((snapshot) => {
      const subscriptions = [];

      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          if (doc.exists) {
            const subscription = {
              ...doc.data(),
              id: doc.id,
            };

            subscriptions.push(subscription);
          }
        });
      }

      dispatch({
        type: GET_SUBSCRIPTION,
        payload: [...subscriptions],
      });
    });
};

export default setSubscriptionListener;
