import { Navigate } from 'react-router-dom';
import React from 'react';
import { useDispatch } from 'react-redux';
import { showPopUp } from '../../redux/actions/popUp.actions';

const handleUnauthorizedAccess = () => {
  const dispatch = useDispatch();
  // const location = useLocation();

  dispatch(showPopUp('requirePayment'));

  // const shouldRedirectToRoot = () => {
  //   return location.key === 'default';
  // };

  return <Navigate to="/client" />;
};

export default handleUnauthorizedAccess;
