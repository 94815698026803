import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import SCTopMenuItem from './TopMenuItem.style';

const TopMenuItem = ({
  onClick,
  icon,
  text,
  selected,
  description,
  children,
  align,
}) => {
  const [isDroppedDown, setIsDroppedDown] = useState(false);

  return (
    <SCTopMenuItem
      onClick={onClick}
      onMouseOver={() => setIsDroppedDown(true)}
      onMouseOut={() => setIsDroppedDown(false)}
      selected={selected}
      isDroppedDown={isDroppedDown}
      align={align}>
      <Icon iconName={icon} size={text ? 'semiLarge' : 'navIcon'} />

      {text && (
        <span>
          {text}
          {children && <Icon iconName={faChevronDown} size="small" />}
        </span>
      )}

      {(description || children) && (
        <div className="dropdown-wrapper">
          <div className="dropdown-container">
            {description && (
              <p
                style={{
                  padding: children && '20px 20px 10px 20px',
                  fontWeight: children && '600',
                }}>
                {description}
              </p>
            )}
            {children && <div className="dropdown-items">{children}</div>}
          </div>
        </div>
      )}
    </SCTopMenuItem>
  );
};

export default TopMenuItem;
