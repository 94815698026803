import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { faDesktop, faServer } from '@fortawesome/pro-regular-svg-icons';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { Collapse } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import useLicenses from '../../../hooks/licenses/useLicenses';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Button from '../../atoms/Button/Button';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import ProgressBar from '../../atoms/ProgressBar/ProgressBar';
import QuantityInput from '../../atoms/QuantityInput/QuantityInput';
import Icon from '../../atoms/Icon/Icon';
import deviceProtectionService from '../../../services/deviceProtection/deviceProtection.service';
import getInstallerFAQLink from '../../../utils/internationalizationModules/installerFAQs';

const SCSendProtectionsEmails = styled.div`
  h2 {
    text-align: center;
    color: var(--black);
    font-family: var(--font1);
    font-size: 25px;
    font-weight: 500;
  }

  div {
    text-align: left;
    font-family: var(--font1);
    font-size: 14px;

    :last-of-type {
      margin-bottom: 0px;
    }

    > span {
      font: inherit;
    }
  }

  .progressBar-container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    > p {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .progress-bar-quantity-input-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    gap: 20px;
    justify-content: center;

    .icon-device-container {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .faq-link {
      margin-inline: 7px;
    }
  }

  .ant-collapse-header {
    align-items: center !important;
  }
`;

const LICENSES_TYPES = {
  DESKTOP_LICENSES: 'desktopLicenses',
  SERVER_LICENSES: 'serverLicenses',
  ANDROID_LICENSES: 'androidLicenses',
  IOS_LICENSES: 'iosLicenses',
};

const UPDATE_OPERATIONS = {
  ADD: 'ADD',
  REDUCE: 'REDUCE',
};

const DEFERRED_LIMIT = 100;

const ICON_PROPS = {
  color: 'var(--bluish-grey-2)',
  size: 'medium',
};

const SendProtectionsEmails = ({ selectedTeamMembersEmails }) => {
  const i18n = useTranslation();
  const [step, setStep] = useState(0);
  const [sendingEmails, setSendingEmails] = useState(false);
  const [nLicenses, setNLicenses] = useState({
    desktopLicenses: 0,
    serverLicenses: 0,
    androidLicenses: 0,
    iosLicenses: 0,
  });
  const { availableLicensesToSend, totalLicenses, sentLicenses } =
    useLicenses();
  const {
    genericInstallerFAQLink,
    androidInstallerFAQLink,
    iosInstallerFAQLink,
  } = getInstallerFAQLink(i18n.getLanguage());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [collapseActiveKeys, setCollapseActiveKeys] = useState(new Set());

  const { desktopLicenses, serverLicenses, androidLicenses, iosLicenses } =
    nLicenses;
  const numTeamMembers = selectedTeamMembersEmails.length;
  const selectedLicenses =
    desktopLicenses + serverLicenses + androidLicenses + iosLicenses;
  const totalLicensesToSend = selectedLicenses * numTeamMembers;
  const disableQuantityInputButton =
    (selectedLicenses + 1) * numTeamMembers + sentLicenses > totalLicenses;

  const deferred = totalLicensesToSend > DEFERRED_LIMIT;

  const updateLicensesQuantity = (licenseType, operation) => {
    setNLicenses((prevLicenses) => {
      const newLicenses = { ...prevLicenses };

      if (operation === UPDATE_OPERATIONS.ADD) {
        newLicenses[licenseType] += 1;

        // Abrir desplegable si pasa de 0 a 1
        if (newLicenses[licenseType] === 1) {
          collapseActiveKeys.add(licenseType);
        }
      } else if (operation === UPDATE_OPERATIONS.REDUCE) {
        newLicenses[licenseType] = Math.max(newLicenses[licenseType] - 1, 0);

        // Cerrar desplegable si pasa a 0
        if (newLicenses[licenseType] <= 0) {
          collapseActiveKeys.delete(licenseType);
        }
      }

      return newLicenses;
    });
  };

  const handleQuantityInput = (e, licenseType) => {
    const inputLicenses = e.target.valueAsNumber || 0;

    const numLicenses = Object.keys(nLicenses).reduce((accumulator, key) => {
      return key !== licenseType ? accumulator + nLicenses[key] : accumulator;
    }, 0);

    if (
      (inputLicenses + numLicenses) * numTeamMembers + sentLicenses >
      totalLicenses
    ) {
      return;
    }

    setNLicenses((prevState) => ({
      ...prevState,
      [licenseType]: inputLicenses,
    }));
  };

  const handleSendingEmails = async () => {
    setSendingEmails(true);

    const protectionsToSend = selectedTeamMembersEmails.map((email) => ({
      email,
      desktopLicenses,
      serverLicenses,
      androidLicenses,
      iosLicenses,
    }));

    await deviceProtectionService
      .sendLicenses({ recipients: protectionsToSend, deferred })
      .then(() => {
        // Todo correcto, step 2.
        setStep(2);
      })
      .catch((err) => {
        // Algún error, step 3.
        console.log(err);
        setStep(3);
      });

    setSendingEmails(false);
  };

  const STEPS_CONTENT = {
    0: (
      <>
        <h2>{i18n.t('deviceSecurity.selectEmailsModal.sendLicenses')}</h2>

        <div className="modal-card-content progress-bar-quantity-input-container">
          <div className="progressBar-container">
            <p className="install-protections-text">
              {i18n.t('deviceSecurity.selectEmailsModal.sentLicensesInfo', {
                sentLicenses,
                totalLicenses,
                licensesToSend: totalLicensesToSend,
              })}
            </p>
            <ProgressBar
              backgroundColor="#F8F9FA"
              completed={sentLicenses}
              toCompleted={totalLicensesToSend}
              total={totalLicenses}
              hideXAxis
            />
          </div>

          <Collapse
            activeKey={[...collapseActiveKeys]}
            expandIconPosition="start"
            ghost
            size="small"
            onChange={(activeKeys) => {
              setCollapseActiveKeys(new Set(activeKeys));
            }}
            items={[
              {
                key: LICENSES_TYPES.DESKTOP_LICENSES,
                label: (
                  <div className="icon-device-container">
                    <Icon iconName={faDesktop} {...ICON_PROPS} />
                    <h3>
                      {i18n.t('deviceSecurity.selectEmailsModal.desktop')}
                    </h3>
                  </div>
                ),
                children: (
                  <p>
                    {i18n.t(
                      'deviceSecurity.selectEmailsModal.desktopDisclaimer'
                    )}
                    <a
                      className="faq-link"
                      href={genericInstallerFAQLink}
                      aria-label="faq-info"
                      target="_blank"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        color="var(--bluish-grey)"
                      />
                    </a>
                  </p>
                ),
                extra: (
                  <QuantityInput
                    value={desktopLicenses}
                    onAdd={() => {
                      updateLicensesQuantity(
                        LICENSES_TYPES.DESKTOP_LICENSES,
                        UPDATE_OPERATIONS.ADD
                      );
                    }}
                    onInputChange={(event) => {
                      handleQuantityInput(
                        event,
                        LICENSES_TYPES.DESKTOP_LICENSES
                      );
                    }}
                    onReduce={() => {
                      updateLicensesQuantity(
                        LICENSES_TYPES.DESKTOP_LICENSES,
                        UPDATE_OPERATIONS.REDUCE
                      );
                    }}
                    disabled={disableQuantityInputButton}
                  />
                ),
              },
              {
                key: LICENSES_TYPES.SERVER_LICENSES,
                label: (
                  <div className="icon-device-container">
                    <Icon iconName={faServer} {...ICON_PROPS} />
                    <h3>{i18n.t('deviceSecurity.selectEmailsModal.server')}</h3>
                  </div>
                ),
                children: (
                  <p>
                    {i18n.t(
                      'deviceSecurity.selectEmailsModal.serverDisclaimer'
                    )}
                    <a
                      className="faq-link"
                      href={genericInstallerFAQLink}
                      aria-label="faq-info"
                      target="_blank"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        color="var(--bluish-grey)"
                      />
                    </a>
                  </p>
                ),
                extra: (
                  <QuantityInput
                    value={serverLicenses}
                    onAdd={() => {
                      updateLicensesQuantity(
                        LICENSES_TYPES.SERVER_LICENSES,
                        UPDATE_OPERATIONS.ADD
                      );
                    }}
                    onInputChange={(event) => {
                      handleQuantityInput(
                        event,
                        LICENSES_TYPES.SERVER_LICENSES
                      );
                    }}
                    onReduce={() => {
                      updateLicensesQuantity(
                        LICENSES_TYPES.SERVER_LICENSES,
                        UPDATE_OPERATIONS.REDUCE
                      );
                    }}
                    disabled={disableQuantityInputButton}
                  />
                ),
              },
              {
                key: LICENSES_TYPES.ANDROID_LICENSES,
                label: (
                  <div className="icon-device-container">
                    <Icon iconName={faAndroid} {...ICON_PROPS} />
                    <h3>
                      {i18n.t('deviceSecurity.selectEmailsModal.androidMobile')}
                    </h3>
                  </div>
                ),
                children: (
                  <p>
                    {i18n.t(
                      'deviceSecurity.selectEmailsModal.androidDisclaimer'
                    )}
                    <a
                      className="faq-link"
                      href={androidInstallerFAQLink}
                      aria-label="faq-info"
                      target="_blank"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        color="var(--bluish-grey)"
                      />
                    </a>
                  </p>
                ),
                extra: (
                  <QuantityInput
                    value={androidLicenses}
                    onAdd={() =>
                      updateLicensesQuantity(
                        LICENSES_TYPES.ANDROID_LICENSES,
                        UPDATE_OPERATIONS.ADD
                      )
                    }
                    onReduce={() =>
                      updateLicensesQuantity(
                        LICENSES_TYPES.ANDROID_LICENSES,
                        UPDATE_OPERATIONS.REDUCE
                      )
                    }
                    disabled={disableQuantityInputButton}
                  />
                ),
              },
              {
                key: LICENSES_TYPES.IOS_LICENSES,
                label: (
                  <div className="icon-device-container">
                    <Icon iconName={faApple} {...ICON_PROPS} />
                    <h3>
                      {i18n.t('deviceSecurity.selectEmailsModal.iosMobile')}
                    </h3>
                  </div>
                ),
                children: (
                  <p>
                    {i18n.t('deviceSecurity.selectEmailsModal.iosDisclaimer')}
                    <a
                      className="faq-link"
                      href={iosInstallerFAQLink}
                      aria-label="faq-info"
                      target="_blank"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        color="var(--bluish-grey)"
                      />
                    </a>
                  </p>
                ),
                extra: (
                  <QuantityInput
                    value={iosLicenses}
                    onAdd={() =>
                      updateLicensesQuantity(
                        LICENSES_TYPES.IOS_LICENSES,
                        UPDATE_OPERATIONS.ADD
                      )
                    }
                    onReduce={() =>
                      updateLicensesQuantity(
                        LICENSES_TYPES.IOS_LICENSES,
                        UPDATE_OPERATIONS.REDUCE
                      )
                    }
                    disabled={disableQuantityInputButton}
                  />
                ),
              },
            ]}
          />
        </div>

        <div className="modal-card-buttons">
          <Button
            onClick={() => {
              setStep(1);
            }}
            text={i18n.t('deviceSecurity.selectEmailsModal.sendLicenses')}
            size="full"
            color="bluishGrey"
            disabled={
              !desktopLicenses &&
              !serverLicenses &&
              !androidLicenses &&
              !iosLicenses
            }
          />

          <Button
            onClick={() => dispatch(showPopUp(null))}
            text={i18n.t('common.cancel')}
            size="full"
            color="white"
          />
        </div>
      </>
    ),
    1: (
      <>
        {/* No tiene suficientes licencias */}
        {totalLicensesToSend > availableLicensesToSend && !sendingEmails ? (
          <>
            <h2>
              {i18n.t('deviceSecurity.selectEmailsModal.notEnoughLicenses')}
            </h2>
            <div className="modal-card-content">
              <div>
                {i18n.t(
                  'deviceSecurity.selectEmailsModal.notEnoughLicensesSubtitle',
                  {
                    extraLicenses:
                      totalLicensesToSend - availableLicensesToSend,
                  }
                )}
              </div>
              <div>
                {i18n.t(
                  'deviceSecurity.selectEmailsModal.notEnoughLicensesParagraph1'
                )}
              </div>
            </div>

            <div className="modal-card-buttons">
              <Button
                onClick={() => {
                  dispatch(showPopUp(null));
                  navigate('/client/settings/billing');
                }}
                text={i18n.t('deviceSecurity.selectEmailsModal.getLicenses')}
                size="full"
                color="bluishGrey"
              />
              <Button
                onClick={() => dispatch(showPopUp(null))}
                text={i18n.t('common.cancel')}
                size="full"
                color="white"
              />
            </div>
          </>
        ) : (
          // Tiene suficientes licencias
          <>
            <h2>{i18n.t('deviceSecurity.selectEmailsModal.confirmSent')}</h2>
            <div className="modal-card-content">
              <div>
                {i18n.t(
                  'deviceSecurity.selectEmailsModal.confirmSentParagraph1'
                )}
              </div>
              <div>
                {i18n.t(
                  'deviceSecurity.selectEmailsModal.confirmSentParagraph2'
                )}
              </div>
            </div>

            <div className="modal-card-buttons">
              <Button
                onClick={() => handleSendingEmails()}
                disabled={sendingEmails}
                text={
                  sendingEmails
                    ? i18n.t('deviceSecurity.selectEmailsModal.sending')
                    : i18n.t('deviceSecurity.selectEmailsModal.send')
                }
                size="full"
                color="bluishGrey"
              />
              <Button
                onClick={() => {
                  setStep(0);
                }}
                text={i18n.t('common.goBack')}
                size="full"
                color="white"
              />
            </div>
          </>
        )}
      </>
    ),
    2: (
      <>
        <h2>
          {deferred
            ? i18n.t('common.processingRequest')
            : i18n.t('common.processedRequest')}
        </h2>

        <div className="modal-card-content">
          <div>
            {deferred
              ? i18n.t('deviceSecurity.selectEmailsModal.processingRequestText')
              : i18n.t('deviceSecurity.selectEmailsModal.processedRequestText')}
          </div>
        </div>

        <div className="modal-card-buttons">
          <Button
            onClick={() => {
              dispatch(showPopUp(null));
              navigate('/client/device-security');
            }}
            text={i18n.t('deviceSecurity.selectEmailsModal.confirm')}
            size="full"
            color="bluishGrey"
          />
        </div>
      </>
    ),
    3: (
      <>
        <h2>{i18n.t('deviceSecurity.selectEmailsModal.error')}</h2>
        <div className="modal-card-content">
          <div className="error-container">
            {i18n.t('deviceSecurity.selectEmailsModal.errorParagraph')}
          </div>
        </div>

        <div className="modal-card-buttons">
          <Button
            onClick={() => {
              dispatch(showPopUp(null));
              Tawk_API.toggle();
            }}
            text={i18n.t('deviceSecurity.selectEmailsModal.contactUs')}
            size="full"
            color="bluishGrey"
          />

          <Button
            onClick={() => {
              dispatch(showPopUp(null));
              navigate('/client/device-security');
            }}
            text={i18n.t('common.close')}
            size="full"
            color="white"
          />
        </div>
      </>
    ),
  };

  return (
    <ModalLayout>
      <SCSendProtectionsEmails>{STEPS_CONTENT[step]}</SCSendProtectionsEmails>
    </ModalLayout>
  );
};

export default SendProtectionsEmails;
