import {
  faBuilding,
  faBuildingCircleCheck,
  faEnvelopeOpen,
  faPen,
  faPhone,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useInternationalization from '../../../hooks/Internationalization/useInternationalization';
import { useTranslation } from '../../../i18n';
import { updateUser } from '../../../redux/actions/user.actions';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCYourInformationCard from './YourInformationCard.style';
import TELEPHONE_DATA from '../../../utils/internationalizationModules/telephones/telephonePrefix';
import { isNonCountryEnvironment } from '../../../utils/locale';

const YourInformationCard = () => {
  const i18n = useTranslation();
  const user = useSelector((state) => state.user);
  const client = useSelector((redux) => redux.client);
  const firebase = useSelector((state) => state.firebase);
  const { currentUser } = firebase.auth();
  const { currentLocale } = useInternationalization();

  const [mUser, setUser] = useState({});
  const [formEdit, setformEdit] = useState(false);
  const [isSaving, setIsSaving] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setUser({
      firstName: user.firstName,
      phoneNumber: user.phoneNumber,
      lastName: user.lastName,
    });
  }, [user]);

  const onClickSave = async (payload, userObj) => {
    setIsSaving(true);

    try {
      await dispatch(updateUser(payload, userObj));
    } catch (e) {
      if (e === 'auth/invalid-phone-number') {
        setUser({
          ...mUser,
          phoneNumber: user.phoneNumber,
        });
      }
    }

    setIsSaving(false);
  };

  const hasChange = () => {
    return !(
      mUser.firstName === user.firstName &&
      mUser.phoneNumber === user.phoneNumber &&
      mUser.lastName === user.lastName
    );
  };

  return (
    <SCYourInformationCard>
      <SectionCard
        headerIcon={faUser}
        headerTitle={i18n.t('profile.yourDetails')}>
        <form onSubmit={(e) => e.preventDefault()} style={{ margin: '0' }}>
          <div className="profile-info-grid">
            <div className="profile-info-grid-column">
              <Input
                className="fs-exclude"
                id="firstName"
                name="firstName"
                size="large"
                icon={faUser}
                defaultValue={mUser.firstName}
                onChangeValue={(val) =>
                  setUser({ ...mUser, firstName: val.trim() })
                }
                disabled={!formEdit}
                inputHidden={!formEdit}
              />

              <Input
                className="fs-exclude"
                id="phoneNumber"
                name="phoneNumber"
                inputType="tel"
                size="large"
                icon={faPhone}
                inputPlaceholder={i18n.t('profile.phoneEx')}
                defaultValue={mUser.phoneNumber}
                onChangeValue={(val) =>
                  setUser({
                    ...mUser,
                    phoneNumber: val.replace(/\s/g, ''),
                  })
                }
                disabled={
                  (currentUser.multiFactor.enrolledFactors &&
                    currentUser.multiFactor.enrolledFactors.length > 0) ||
                  !formEdit
                }
                inputHidden={
                  (currentUser.multiFactor.enrolledFactors &&
                    currentUser.multiFactor.enrolledFactors.length > 0) ||
                  !formEdit
                }
              />

              <Input
                id="companyName"
                name="companyName"
                size="large"
                icon={faBuilding}
                defaultValue={client.company.name}
                disabled
                inputHidden
              />
            </div>

            <div className="profile-info-grid-column">
              <Input
                className="fs-exclude"
                id="lastName"
                name="lastName"
                size="large"
                icon={faUser}
                defaultValue={mUser.lastName}
                onChangeValue={(val) =>
                  setUser({ ...mUser, lastName: val.trim() })
                }
                disabled={!formEdit}
                inputHidden={!formEdit}
              />

              <Input
                id="email"
                name="email"
                size="large"
                icon={faEnvelopeOpen}
                defaultValue={user.email}
                disabled
                inputHidden
              />

              {!isNonCountryEnvironment && (
                <Input
                  id="companyCif"
                  name="companyCif"
                  size="large"
                  icon={faBuildingCircleCheck}
                  defaultValue={client.company.cif}
                  disabled
                  inputHidden
                />
              )}
            </div>
          </div>

          <div className="buttons-form-container">
            {formEdit ? (
              <>
                <Button
                  text={
                    !isSaving ? (
                      i18n.t('profile.save')
                    ) : (
                      <SpinnerText text={i18n.t('misc.saving')} />
                    )
                  }
                  size="mid"
                  color="bluishGrey"
                  onClick={() => {
                    setformEdit(false);
                    onClickSave(mUser, user);
                  }}
                  disabled={!hasChange()}
                />

                <Button
                  text={i18n.t('common.cancel')}
                  size="mid"
                  color="white"
                  onClick={() => {
                    setUser({
                      firstName: user.firstName,
                      phoneNumber:
                        user.phoneNumber ||
                        TELEPHONE_DATA[currentLocale]?.phonePrefix,
                      lastName: user.lastName,
                    });
                    setformEdit(false);
                  }}
                />
              </>
            ) : (
              <Button
                text={
                  !isSaving ? (
                    i18n.t('profile.edit')
                  ) : (
                    <SpinnerText text={i18n.t('misc.saving')} />
                  )
                }
                icon={!isSaving && faPen}
                size="mid"
                color="white"
                onClick={() => {
                  if (!mUser.phoneNumber) {
                    setUser({
                      ...mUser,
                      phoneNumber: TELEPHONE_DATA[currentLocale]?.phonePrefix,
                    });
                  }
                  setformEdit(true);
                }}
                disabled={isSaving}
              />
            )}
          </div>
        </form>
      </SectionCard>
    </SCYourInformationCard>
  );
};
export default YourInformationCard;
