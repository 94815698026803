import React from 'react';
import { Form } from 'antd';

import styled from '@emotion/styled/macro';
import moment from 'moment';
import ContactCard from './ContactCard';
import CompanyCard from './CompanyCard';
import SalesProcessCard from './SalesProcessCard';
import SubscriptionCard from './SubscriptionCard';

const SCFormLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const DetailForm = ({ data, onChange, leadOwners, leadSteps }) => {
  const [form] = Form.useForm();

  const handleValuesChange = (changedValues) => {
    onChange((prevValues) => ({
      ...prevValues,
      ...changedValues,
    }));
  };

  const normalizedInitialValues = {
    ...data,
    createdAt: moment(data.createdAt),
    agreements: {
      ...data.agreements,
      contactAllowed: data.agreements.contactAllowed ? 'Sí ' : 'No',
    },
    subscription: {
      ...data.subscription,
      endTime: data.subscription?.endTime
        ? moment(data.subscription?.endTime)
        : null,
      startTime: data.subscription?.startTime
        ? moment(data.subscription?.startTime)
        : null,
    },
  };

  return (
    <Form
      form={form}
      initialValues={normalizedInitialValues}
      onValuesChange={handleValuesChange}>
      <SCFormLayout>
        <ContactCard />
        <CompanyCard
          isSantanderEvent={normalizedInitialValues.eventName === 'santander'}
        />
        <SalesProcessCard
          leadOwners={leadOwners}
          leadSteps={leadSteps}
          step={normalizedInitialValues.stepId}
        />
        <SubscriptionCard />
      </SCFormLayout>
    </Form>
  );
};

export default DetailForm;
