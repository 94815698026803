import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { auth } from '../../firebase';
import { useTranslation } from '../../i18n';
import useRecaptcha from '../recaptcha/useRecaptcha';
import { showPopUp } from '../../redux/actions/popUp.actions';
import * as leadsService from '../../services/leads/leads.service';
import * as clientService from '../../services/client/client.service';

const useLeads = () => {
  const i18n = useTranslation();
  const { getRecaptchaToken } = useRecaptcha();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const createLead = (dataLead) => {
    setLoading(true);
    return axios
      .post('/send_data_lead', { data: dataLead })
      .then((resp) => {
        setError(false);
        return resp.status === 204 ? undefined : resp.data;
      })
      .catch((e) => {
        setError(true);
        throw e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const requestContactSuccessPopUp = () =>
    dispatch(
      showPopUp('notification', {
        notificationType: 'success',
        title: i18n.t('callMe.successPopUp.title'),
        text: i18n.t('callMe.successPopUp.text'),
        closeButtonText: i18n.t('callMe.successPopUp.actions.close'),
      })
    );

  const requestContact = (phone, name) => {
    setLoading(true);

    if (auth?.currentUser) {
      clientService
        .requestContact(phone)
        .then(() => {
          requestContactSuccessPopUp();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getRecaptchaToken().then((token) => {
        leadsService
          .requestContact(phone, i18n.getLanguage(), name, token)
          .then(() => {
            requestContactSuccessPopUp();
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  };

  return { createLead, requestContact, loading, error };
};

export default useLeads;
