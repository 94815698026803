import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SCActionView from './actionView.style';
import { useTranslation } from '../../i18n';
import ActionInfoCard from '../../components/organisms/ActionInfoCard/ActionInfoCard';
import ActionFAQCard from '../../components/organisms/ActionFAQCard/ActionFAQCard';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import actionTemplates from '../../components/Dashboard/Sections/Actions/ActionTemplates.json';
import BackButton from '../../components/molecules/BackButton/BackButton';
import { getAction } from '../../utils/functions/actions';

const AcionView = () => {
  const client = useSelector((redux) => redux.client);

  const i18n = useTranslation();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const queryActionId = urlParams.get('actionType');

  const action = getAction(client.status.actions, queryActionId);

  useEffect(() => {
    if (!queryActionId) {
      navigate('/client');
    }
  }, []);

  return (
    <SectionLayout>
      <SCActionView>
        <div className="section-back-button">
          <BackButton />
        </div>

        {action && (
          <>
            <div className="section-organism">
              <ActionInfoCard action={action} />
            </div>

            {actionTemplates[action.type].faq && (
              <div className="section-organism">
                <ActionFAQCard action={action} />
              </div>
            )}
          </>
        )}
      </SCActionView>
    </SectionLayout>
  );
};

export default AcionView;
