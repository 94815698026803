import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { TrackButton } from '../../../track';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import { deleteTeamMember } from '../../../services/teamMembers/teamMembers.service';

const SCRemoveEmail = styled.div`
  h1 {
    text-align: center;
    font-family: var(--font1);
    font-size: 25px;
    font-weight: 500;
  }
`;

const RemoveEmail = ({ hashedEmail }) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(showPopUp(null));
  };

  return (
    <ModalLayout>
      <SCRemoveEmail>
        <h1>{i18n.t('manageEmailList.remove.title')}</h1>
        <div className="modal-card-buttons">
          <Button
            text={i18n.t('manageEmailList.remove.confirm')}
            onClick={() => {
              deleteTeamMember(hashedEmail);
              closeModal();
              TrackButton('manageEmailList.remove.confirm');
            }}
            size="full"
            color="bluishGrey"
          />
          <Button
            text={i18n.t('manageEmailList.remove.decline')}
            onClick={() => {
              closeModal();
              TrackButton('manageEmailList.remove.decline');
            }}
            size="full"
            color="white"
          />
        </div>
      </SCRemoveEmail>
    </ModalLayout>
  );
};

export default RemoveEmail;
