import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SCProfileInfo from './ProfileInfo.style';
import YourInformationCard from '../YourInformationCard/YourInformationCard';
import MySessionCard from '../MySessionCard/MySessionCard';
import YourInterfaceInformationCard from '../YourInterfaceInformationCard/YourInterfaceInformationCard';
import YourBillingInformationCardTemplate from '../../../utils/internationalizationModules/templates/YourBillingInformationCardTemplate';
import SectionLayout from '../../layouts/SectionLayout/SectionLayout';
import SectionHeader from '../../molecules/SectionHeader/SectionHeader';
import YourPaymentMethodInformationCard from '../YourPaymentMethodInformationCard/YourPaymentMethodInformationCard';
import DeleteAccountCard from '../DeleteAccountCard/DeleteAccountCard';
import { isNonCountryEnvironment } from '../../../utils/locale';
import { PARTNER_TYPES } from '../../../utils/constants/partners';
import { isPartnerType } from '../../../utils/functions/partners';

const ProfileInfo = () => {
  const client = useSelector((redux) => redux.client);

  const { state } = useLocation();
  const { targetId } = state || {};

  useEffect(() => {
    const el = document.getElementById(targetId);

    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <SectionLayout>
      <SCProfileInfo>
        <div className="section-organism">
          <SectionHeader />
        </div>

        <div className="section-organism">
          <YourInformationCard />
        </div>

        {!isPartnerType(PARTNER_TYPES.MSSP) && (
          <div className="section-organism">
            <YourBillingInformationCardTemplate />
          </div>
        )}

        {Boolean(client?.customerId) &&
          !isNonCountryEnvironment &&
          !isPartnerType(PARTNER_TYPES.MSSP) && (
            <div className="section-organism" id="payment-method-card">
              <YourPaymentMethodInformationCard />
            </div>
          )}

        <div className="section-organism">
          <YourInterfaceInformationCard />
        </div>

        <div className="section-organism">
          <MySessionCard />
        </div>

        {!isPartnerType(PARTNER_TYPES.MSSP) && <DeleteAccountCard />}
      </SCProfileInfo>
    </SectionLayout>
  );
};

export default ProfileInfo;
