import styled from '@emotion/styled';

export const SCProfileEmailItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EmailNotificationType = styled.div`
  margin-top: 24px;
  & h3 {
    font-family: var(--font2);
    font-size: 16px;
    color: var(--black);
    margin-bottom: 0;
  }

  & p {
    font-size: 14px;
    color: var(--grey);
    margin-top: 7px;
    margin-bottom: 20px;
    letter-spacing: -0.25px;
    padding-right: 15px;
    line-height: 20px;
    min-height: 40px;
  }
`;
