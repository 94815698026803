import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import { TrackButton } from '../../../track';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import useTeamMembers from '../../../hooks/useTeamMembers/useTeamMembers';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import { TEAM_MEMBERS_ROLES } from '../../../utils/constants/teamMembers';

const SCAddCSVUserInEmailList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  h2 {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
  }

  .AddSCVUserInEmailList_Content {
    display: flex;
    flex-direction: column;
    gap: 30px;

    p.valid-format {
      font-weight: 400;
    }

    a {
      font-family: var(--font2);
      font-size: 14px;
      color: var(--bluish-grey);
      text-align: center;
    }

    .AddCSVUserInEmailList_Message {
      display: flex;
      flex-direction: column;
      gap: 20px;

      p {
        font-weight: 400;
      }
    }

    .AddCSVUserInEmailList_CSV_EXCEL {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .AddCSVUserInEmailList_CSV_EXCEL_Header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 14px;
        }
      }

      .AddCSVUserInEmailList_CSV_Table_Header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 12px;
        height: 35px;
        border: 1px solid var(--light-grey-3);
        border-bottom: none;
        border-radius: 5px 5px 0px 0px;
        font-family: var(--font1);
        color: var(--bluish-grey);
        font-weight: 500;
        font-size: 14px;
        background-color: var(--light-grey-4);
      }

      .AddCSVUserInEmailList_CSV_Table {
        display: flex;
        align-items: center;
        padding-left: 12px;
        height: 35px;
        border: 1px solid var(--light-grey-3);
        border-radius: 0px 0px 5px 5px;
        font-family: var(--font1);
        color: var(--black);
        font-weight: 500;
        font-size: 14px;
      }

      .AddCSVUserInEmailList_Table_Header {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        height: 35px;

        div {
          display: flex;
          align-items: center;
          justify-content: center;

          border: 1px solid var(--light-grey-3);
          border-bottom: none;
          font-family: var(--font1);
          color: var(--bluish-grey);
          font-weight: 500;
          font-size: 14px;
          background-color: var(--light-grey-4);
        }

        > div:first-of-type {
          border-radius: 5px 0px 0px 0px;
        }

        > div:last-of-type {
          border-radius: 0px 5px 0px 0px;
        }
      }

      .AddCSVUserInEmailList_Table {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        height: 35px;

        div {
          display: flex;
          align-items: center;
          padding-left: 12px;

          border: 1px solid var(--light-grey-3);
          font-family: var(--font1);
          color: var(--black);
          font-weight: 500;
          font-size: 14px;
        }

        > div:first-of-type {
          border-radius: 0px 0px 0px 5px;
        }

        > div:last-of-type {
          border-radius: 0px 0px 5px 0px;
        }
      }
    }
  }
`;

const AddCSVUserInEmailList = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const lang = i18n.getLanguage();
  const client = useSelector((redux) => redux.client);
  const userAccount = useSelector((redux) => redux.user);
  const teamMembers = useSelector((redux) => redux.teamMembers);
  const { loading, addMultipleTeamMembers } = useTeamMembers();
  const [showMaxMembersBanner, setshowMaxMembersBanner] = useState(false);

  let differentDomainRestriction;

  const FILE_DOWNLOAD_XLSX = {
    es: 'https://static.cyberguardian.tech/es/Plantilla_lista_empleados_cyberguardian.xlsx',
    en: 'https://static.cyberguardian.tech/en/Employee_list_template_cyberguardian.xlsx',
    'pt-BR':
      'https://static.cyberguardian.tech/pt/Modelo_lista_colaboradores_cyberguardian.xlsx',
  };

  const FILE_DOWNLOAD_CSV = {
    es: 'https://static.cyberguardian.tech/es/Plantilla_lista_empleados_cyberguardian.csv',
    en: 'https://static.cyberguardian.tech/en/Employee_list_template_cyberguardian.csv',
    'pt-BR':
      'https://static.cyberguardian.tech/pt/Modelo_lista_colaboradores_cyberguardian.csv',
  };

  const FILE_EXTENSIONS = {
    CSV: 'csv',
    XLS: 'xls',
    XLSX: 'xlsx',
  };

  const getFileExtensions = () => {
    return Object.values(FILE_EXTENSIONS);
  };

  const handleDataMultipleUsers = (arrayMultipleUsers) => {
    let valid = 0;
    let firstName = 0;
    let lastName = 1;
    let emailAddress = 2;
    let data;
    arrayMultipleUsers[0].forEach((header, index) => {
      if (header === i18n.t('manageEmailList.firstNameHeader')) {
        valid += 1;
        firstName = index;
      } else if (header === i18n.t('manageEmailList.lastNameHeader')) {
        valid += 1;
        lastName = index;
      } else if (header === i18n.t('manageEmailList.emailHeader')) {
        emailAddress = index;
        valid += 1;
      }
    });

    if (valid > 0 && valid <= 3) {
      data = arrayMultipleUsers.slice(1, arrayMultipleUsers.length);
    } else {
      data = arrayMultipleUsers;
    }
    const teamMembersArray = [];
    data = data.filter((row) => row[emailAddress]);
    data.forEach((item) => {
      const teamMemberMapping = {
        firstName: item[firstName].trim(),
        lastName: item[lastName].trim(),
        email: item[emailAddress].trim().toLowerCase(),
        language: i18n.getLanguage(),
        role: TEAM_MEMBERS_ROLES.EMPLOYEE,
      };

      if (
        teamMemberMapping.email.split('@')[1] !== client.atEmail &&
        (!client ||
          !client?.status?.serviceLevel.features.allowDifferentEmailAt)
      ) {
        dispatch(
          showPopUp('notification', {
            notificationType: 'warning',
            title: i18n.t('common.warning'),
            text: i18n.t('manageEmailList.error.emailNotMatchWithDomain', {
              domain: `@${client.atEmail}`,
            }),
            closeButtonText: i18n.t('manageEmailList.error.cancel'),
            additionalButtonText: i18n.t('manageEmailList.error.request'),
            onButtonClick: () => {
              axios
                .post('/send_email_form', {
                  subject: 'Request adding third party domains',
                  companyEmail: userAccount.email,
                  message: `${userAccount.firstName} ${userAccount.lastName} from ${client.company.name} (with client id: ${client.id}) has requested adding third party domains.`,
                })
                .then(() => {
                  dispatch(showPopUp(null));
                  dispatch(
                    showPopUp('notification', {
                      notificationType: 'success',
                      title: '',
                      text: i18n.t('manageEmailList.error.successfulRequest'),
                    })
                  );
                })
                .catch((error) => {
                  console.error(error);
                  dispatch(
                    showPopUp('notification', {
                      notificationType: 'error',
                      title: i18n.t('common.error'),
                      text: i18n.t('common.errorTryLater'),
                    })
                  );
                });
            },
          })
        );
        differentDomainRestriction = true;
      }
      if (
        teamMemberMapping.firstName &&
        teamMemberMapping.lastName &&
        teamMemberMapping.email
      ) {
        teamMembersArray.push(teamMemberMapping);
      }
    });

    if (differentDomainRestriction) return;
    addMultipleTeamMembers(teamMembersArray);
  };

  const onChangeMultipleUsers = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    // e.target.value = null;
    const fileExtension = file.name.split('.').at(-1);

    if (!file) {
      return;
    }

    if (!getFileExtensions().includes(fileExtension)) {
      // TODO - Dar feedback al usuario sobre que el tipo de archivo introducido no es CSV, XLS o XLSX.
      console.log('El archivo no es CSV, XLS o XLSX');
      return;
    }

    if (fileExtension === FILE_EXTENSIONS.CSV) {
      console.log('El archivo es CSV');
      Papa.parse(file, {
        complete(results) {
          handleDataMultipleUsers(results.data);
        },
      });
    } else {
      console.log('El archivo es XLS o XLSX');

      const reader = new FileReader();
      reader.onload = (element) => {
        const data = element.target.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const dataMultipleUsers = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        });
        handleDataMultipleUsers(dataMultipleUsers);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const onClickAddUserWithFile = () => {
    if (showMaxMembersBanner) {
      setshowMaxMembersBanner(false);
      return;
    }
    document.getElementById('addUsersViaCSV').click();
    TrackButton('manageEmailList.uploadCSV');
  };

  const selectFileText = showMaxMembersBanner
    ? i18n.t('manageEmailList.uploadCSVAgain')
    : i18n.t('manageEmailList.uploadCSV');

  return (
    <ModalLayout>
      <SCAddCSVUserInEmailList>
        <h2>{i18n.t('manageEmailList.addTeamFromCSV')}</h2>
        <div className="modal-card-content AddSCVUserInEmailList_Content">
          {showMaxMembersBanner ? (
            <div className="AddCSVUserInEmailList_ErrorMessage">
              <InfoBanner
                type="error"
                minified
                text={[i18n.t('manageEmailList.error.maxTeamMembers')]}
              />
            </div>
          ) : (
            <>
              <div className="AddCSVUserInEmailList_Message">
                <p>{i18n.t('manageEmailList.addTeamFromCSVP1')}</p>

                <p>{i18n.t('manageEmailList.addTeamFromCSVP2')}</p>
              </div>

              <div className="AddCSVUserInEmailList_CSV_EXCEL">
                <div className="AddCSVUserInEmailList_CSV_EXCEL_Header">
                  <p className="valid-format">
                    {i18n.t('manageEmailList.firstLineCSV')}
                  </p>

                  <a href={FILE_DOWNLOAD_CSV[lang]} rel="noopener noreferrer">
                    {i18n.t('manageEmailList.download')}
                  </a>
                </div>
                <div>
                  <div className="AddCSVUserInEmailList_CSV_Table_Header">
                    A
                  </div>
                  <div className="AddCSVUserInEmailList_CSV_Table">
                    {i18n.t('manageEmailList.firstNameHeader')},
                    {i18n.t('manageEmailList.lastNameHeader')},
                    {i18n.t('manageEmailList.emailHeader')}
                  </div>
                </div>
              </div>

              <div className="AddCSVUserInEmailList_CSV_EXCEL">
                <div className="AddCSVUserInEmailList_CSV_EXCEL_Header">
                  <p className="valid-format">
                    {i18n.t('manageEmailList.firstLineEXCEL')}
                  </p>

                  <a href={FILE_DOWNLOAD_XLSX[lang]} rel="noopener noreferrer">
                    {i18n.t('manageEmailList.download')}
                  </a>
                </div>
                <div>
                  <div className="AddCSVUserInEmailList_Table_Header">
                    <div>A</div>
                    <div>B</div>
                    <div>C</div>
                  </div>

                  <div className="AddCSVUserInEmailList_Table">
                    <div>{i18n.t('manageEmailList.firstNameHeader')}</div>
                    <div>{i18n.t('manageEmailList.lastNameHeader')}</div>
                    <div>{i18n.t('manageEmailList.emailHeader')}</div>
                  </div>
                </div>
              </div>

              <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                id="addUsersViaCSV"
                onChange={onChangeMultipleUsers}
                style={{ display: 'none' }}
              />
            </>
          )}
        </div>

        <div className="modal-card-buttons">
          <Button
            text={
              loading ? (
                <SpinnerText text={i18n.t('misc.saving')} />
              ) : (
                selectFileText
              )
            }
            onClick={onClickAddUserWithFile}
            color="bluishGrey"
            disabled={loading}
          />

          <Button
            text={i18n.t('websiteSecurity.add.cancel')}
            onClick={() => dispatch(showPopUp(null))}
            size="full"
            color="white"
          />
        </div>
      </SCAddCSVUserInEmailList>
    </ModalLayout>
  );
};

export default AddCSVUserInEmailList;
