import { getDomain } from 'tldjs';
import { firestore } from '../../firebase';

import { GET_IMPERSONATIONS } from './types';

const getSimilarDomainsStatus = (clientId, dispatch, getState) => {
  firestore
    .collection('clients')
    .doc(clientId)
    .collection('domain_impersonations')
    .onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        const { impersonations } = getState();

        snapshot.forEach((doc) => {
          const clientImpersonationDoc = doc.data();

          if (clientImpersonationDoc) {
            const originalDomain = getDomain(
              clientImpersonationDoc.OriginalDomain
            );

            const impersonationItem = impersonations.find(
              (impersonation) =>
                impersonation.domain === originalDomain ||
                getDomain(impersonation.domain) === originalDomain
            );

            const domainImpersonation =
              impersonationItem?.domain_impersonation.find(
                (impersonation) =>
                  impersonation.Domain === clientImpersonationDoc.Domain
              );

            if (domainImpersonation) {
              domainImpersonation.Status = clientImpersonationDoc.Status;
            }
          }
        });

        dispatch({
          type: GET_IMPERSONATIONS,
          payload: [...impersonations],
        });
      }
    });
};

export const setImpersonationListener = (client, dispatch, getState) => {
  if (client.monitoredDomains) {
    const monitoredImpersonations = client.monitoredDomains.map((domain) => ({
      domain,
    }));

    client.monitoredDomains.forEach((domain, index) => {
      const baseDomain = getDomain(domain);

      firestore
        .collection('domain_records')
        .where('OriginalDomain', '==', baseDomain)
        .onSnapshot((snapshot) => {
          const impersonationsDocs = [];

          snapshot.forEach((doc) => {
            const impersonationDoc = doc.data();

            if (impersonationDoc) {
              const {
                Whois,
                OriginalDomain,
                DomainSimilarities,
                Observations,
                MXs,
                Domain,
              } = impersonationDoc;

              impersonationsDocs.push({
                Whois,
                OriginalDomain,
                DomainSimilarities,
                Observations,
                MXs,
                Domain,
              });
            }
          });

          monitoredImpersonations[index] = {
            ...monitoredImpersonations[index],
            domain_impersonation: impersonationsDocs,
          };

          dispatch({
            type: GET_IMPERSONATIONS,
            payload: [...monitoredImpersonations],
          });
        });
    });

    getSimilarDomainsStatus(client.id, dispatch, getState);
  }
};

export default true;
