import styled from '@emotion/styled';

const SCEmailSecurityFilterCard = styled.div`
  .emailSecurity_filterContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 38px;

    button {
      margin-right: 20px;
    }
  }
`;

export default SCEmailSecurityFilterCard;
