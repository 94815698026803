import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Input, Button as AntButton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faCalendar,
  faSearch,
} from '@fortawesome/pro-regular-svg-icons';
import {
  getLeads,
  getLeadsOwner,
  editLead,
  getLeadsSteps,
  getLeadsEvents,
} from '../../../services/leads/leads.service';
import { FILTER_OPERATORS } from '../../../utils/functions/queryParameters';
import Button from '../../../components/atoms/Button/Button';
import CustomTable from '../organisms/CustomTable';
import DetailView from '../organisms/DetailView';
import DetailForm from '../organisms/DetailForm';
import SpinnerText from '../../../components/atoms/SpinnerText/SpinnerText';
import DatePickerApp from '../../../components/atoms/DatePicker/DatePicker';
import {
  SCLeadsAdminPage,
  DetailPanel,
  TableContainer,
  Content,
  DetailHeader,
  DetailContainer,
  ButtonContainer,
} from './LeadsAdminPage.style';

const COLUMN_NAMES = {
  STEP_DESCRIPTION: 'stepDescription',
  EVENT_NAME: 'eventName',
  LEAD_OWNER: 'leadOwner',
};

const INPUTS_PLACEHOLDERS = {
  companyCif: 'CIF',
  companyName: 'nombre empresa',
};

// Remove empty values from object
const removeEmptyValues = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v != null)
  );
};

// Function to find the difference between two filter objects
const getNewEntries = (current, updated) => {
  return Object.keys(updated).reduce((acc, key) => {
    if (!current?.hasOwnProperty(key)) {
      acc[key] = updated[key];
    }
    return acc;
  }, {});
};

const isEmptyObject = (object) => {
  return Object.keys(object).length === 0;
};

const getFormattedDates = (dates) => {
  let finalDates;

  if (dates && dates.length === 2) {
    finalDates = dates.map((datePickerDate, index) => {
      const adjustedDate =
        index === 0
          ? datePickerDate.startOf('day').toDate()
          : datePickerDate.endOf('day').toDate();

      // Adjust the date to keep the time intact without changing timezone
      const dateInISOString = new Date(
        adjustedDate.getTime() - adjustedDate.getTimezoneOffset() * 60000
      ).toISOString();

      return dateInISOString;
    });
  }

  return finalDates;
};

const LeadsAdminPage = () => {
  const [leadsData, setLeadsData] = useState([]);
  const [leadOwners, setLeadOwners] = useState([]);
  const [leadSteps, setLeadSteps] = useState([]);
  const [leadEvents, setLeadEvents] = useState([]);
  const [loadingGetLeads, setLoadingGetLeads] = useState(false);
  const [loadingEditLead, setLoadingEditLead] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState();
  const [editMode, setEditMode] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [tableParams, setTableParams] = useState({
    filters: null,
    sorter: {
      field: 'createdAt',
      order: 'descend',
    },
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const getFiltersArray = () => {
    const {
      createdAt,
      companyCif,
      companyName,
      stepDescription,
      eventName,
      leadOwner,
    } = tableParams.filters ?? {};
    const mappedFilters = [];

    if (!tableParams.filters || isEmptyObject(tableParams.filters)) {
      return null;
    }

    if (createdAt) {
      mappedFilters.push(
        {
          field: 'createdAt',
          operator: FILTER_OPERATORS.GREATER_THAN,
          value: createdAt[0],
        },
        {
          field: 'createdAt',
          operator: FILTER_OPERATORS.LOWER_THAN,
          value: createdAt[1],
        }
      );
    }

    if (companyCif) {
      mappedFilters.push({
        field: 'company.cif',
        operator: FILTER_OPERATORS.LIKE,
        value: `'${companyCif}'`,
      });
    }

    if (companyName) {
      mappedFilters.push({
        field: 'company.name',
        operator: FILTER_OPERATORS.LIKE,
        value: `'${companyName}'`,
      });
    }

    if (stepDescription) {
      mappedFilters.push({
        field: 'stepId',
        operator: FILTER_OPERATORS.IN,
        value: JSON.stringify(stepDescription),
      });
    }

    if (eventName) {
      mappedFilters.push({
        field: 'eventName',
        operator: FILTER_OPERATORS.IN,
        value: JSON.stringify(eventName),
      });
    }

    if (leadOwner) {
      mappedFilters.push({
        field: 'leadOwner',
        operator: FILTER_OPERATORS.IN,
        value: JSON.stringify(leadOwner),
      });
    }

    return mappedFilters;
  };

  const fetchLeads = async ({ keepSelectedLead } = {}) => {
    setLoadingGetLeads(true);

    try {
      const leadsDataRes = await getLeads({
        pagination: tableParams?.pagination,
        sorter: tableParams?.sorter,
        filters: getFiltersArray(),
      });

      const { data, pagination } = leadsDataRes?.data ?? {};

      setLeadsData(data);
      if (!keepSelectedLead) {
        setSelectedLeadId(data?.[0]?.id);
      }
      setTableParams({
        ...tableParams,
        pagination: {
          current: pagination?.currentPage,
          pageSize: pagination?.itemsPerPage,
          total: pagination?.totalItems,
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingGetLeads(false);
    }
  };

  const resetEditModeAndFormValues = () => {
    setEditMode(false);
    setFormValues(null);
  };

  useEffect(() => {
    fetchLeads();
    resetEditModeAndFormValues();
  }, [
    JSON.stringify(tableParams.filters),
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams.sorter?.order,
  ]);

  useEffect(() => {
    const fetchLeadsData = async () => {
      setLoadingGetLeads(true);

      try {
        const [leadsOwnerRes, leadsStepsRes, leadsEventsRes] =
          await Promise.all([
            getLeadsOwner(),
            getLeadsSteps(),
            getLeadsEvents(),
          ]);

        const { data: leadOwnersData } = leadsOwnerRes.data;
        const { data: leadStepsData } = leadsStepsRes.data;
        const { data: leadEventsData } = leadsEventsRes.data;

        setLeadOwners(leadOwnersData);
        setLeadSteps(leadStepsData);
        setLeadEvents(leadEventsData);
      } catch (e) {
        console.error('Failed to fetch leads data:', e);
      } finally {
        setLoadingGetLeads(false);
      }
    };

    fetchLeadsData();
  }, []);

  const filterSteps = (leadStepsData) => {
    return leadStepsData.filter((step) => step.isEditable);
  };

  const handleOnSave = async () => {
    setLoadingEditLead(true);

    const { company, stepDescription, ...otherFormValues } = formValues;

    const normalizedFormValues = {
      ...otherFormValues,
      id: selectedLeadId,
      numberEmployees: company?.numberEmployees,
      stepId: stepDescription,
    };

    try {
      await editLead(normalizedFormValues);
      await fetchLeads({ keepSelectedLead: true });
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingEditLead(false);
      resetEditModeAndFormValues();
    }
  };

  const onClickRow = (leadId) => {
    setSelectedLeadId(leadId);
    resetEditModeAndFormValues();
  };

  const updateFilter = (field, value) => {
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      filters: value
        ? {
            [field]: value,
          }
        : null,
      pagination: {
        ...prevTableParams.pagination,
        current: 1,
      },
    }));
  };

  const getCalendarSearchProps = () => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, close }) => (
        <div
          style={{
            padding: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}>
          <DatePickerApp
            isRangePicker
            disabledDatesAfter={moment(new Date())}
            onChange={(dates) => {
              const formattedDates = getFormattedDates(dates);
              setSelectedKeys(formattedDates);
            }}
            defaultValue={selectedKeys}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <AntButton
              type="link"
              size="small"
              onClick={() => {
                setSelectedKeys(null);
              }}>
              Reiniciar
            </AntButton>
            <AntButton
              type="primary"
              onClick={() => {
                updateFilter('createdAt', selectedKeys);
                close();
              }}
              size="small">
              Aceptar
            </AntButton>
          </div>
        </div>
      ),
      filterIcon: () => {
        return (
          <FontAwesomeIcon
            icon={faCalendar}
            style={{
              color: tableParams.filters?.createdAt ? '#1677ff' : undefined,
            }}
          />
        );
      },
    };
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, close }) => (
      <div
        style={{
          padding: '8px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          placeholder={`Buscar ${INPUTS_PLACEHOLDERS[dataIndex]}`}
          value={selectedKeys}
          onChange={(e) => setSelectedKeys(e.target.value)}
          onPressEnter={() => {
            updateFilter(dataIndex, selectedKeys);
            close();
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <AntButton
            type="link"
            size="small"
            onClick={() => {
              setSelectedKeys(null);
            }}>
            Reiniciar
          </AntButton>
          <AntButton
            type="primary"
            onClick={() => {
              updateFilter(dataIndex, selectedKeys);
              close();
            }}
            size="small">
            Aceptar
          </AntButton>
        </div>
      </div>
    ),
    filterIcon: () => (
      <FontAwesomeIcon
        icon={faSearch}
        style={{
          color: tableParams.filters?.[dataIndex] ? '#1677ff' : undefined,
        }}
      />
    ),
  });

  /**
   * Esta función se ejecuta siempre que cambien los campos de búsqueda controlados por la tabla, la paginación y el filtrado.
   * Los campos de búsqueda personalizados (Fecha creación, CIF y nombre empresa) no vienen controlados por la tabla en el campo filters.
   */
  const handleTableChange = (pagination, filters, sorter) => {
    const currentFilters = tableParams.filters ?? {};
    const tableFilters = removeEmptyValues(filters);
    const filterKey = Object.keys(tableParams.filters ?? {})[0];
    const isTableFilters =
      Object.values(COLUMN_NAMES).includes(filterKey) ||
      !isEmptyObject(tableFilters);
    let finalTableFilters = {};

    if (isTableFilters) {
      const isCombinedFilter = Object.keys(tableFilters).every(
        (key) =>
          key === COLUMN_NAMES.EVENT_NAME || key === COLUMN_NAMES.LEAD_OWNER
      );

      // Obtenemos la nueva propiedad a filtrar
      finalTableFilters = getNewEntries(currentFilters, tableFilters);

      // Si filtramos por el mismo campo, entonces, nos quedamos con lo que venga en el filtro gestionado por la tabla
      if (isEmptyObject(finalTableFilters) || isCombinedFilter) {
        finalTableFilters = tableFilters;
      }
    } else {
      finalTableFilters = currentFilters;
    }

    setTableParams({
      pagination,
      filters: finalTableFilters,
      sorter,
    });
  };

  const leadStepsObject = leadSteps.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  const leadsDataSource = leadsData.map((lead) => ({
    ...lead,
    stepDescription: leadStepsObject[lead.stepId]?.description,
  }));

  const selectedLead = leadsDataSource.find(
    (lead) => lead.id === selectedLeadId
  );

  const columns = [
    {
      title: 'Fecha creación',
      dataIndex: 'createdAt',
      render: (datetime) => (
        <span>{new Date(datetime).toLocaleDateString()}</span>
      ),
      ...getCalendarSearchProps(),
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'CIF',
      dataIndex: ['company', 'cif'],
      ...getColumnSearchProps('companyCif'),
    },
    {
      title: 'Nombre empresa',
      dataIndex: ['company', 'name'],
      ...getColumnSearchProps('companyName'),
    },
    {
      title: 'Estado',
      dataIndex: 'stepDescription',
      filters: leadSteps.map(({ id, description, count }) => ({
        value: id,
        text: `${description} (${count})`,
      })),
      filteredValue: tableParams.filters?.stepDescription || null,
      filterSearch: true,
    },
    {
      title: 'Partner',
      dataIndex: 'eventName',
      filters: leadEvents
        .map(({ name, count }) => ({
          value: name,
          text: `${name} (${count})`,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
      filteredValue: tableParams.filters?.eventName || null,
      filterSearch: true,
    },
    {
      title: 'Propietario',
      dataIndex: 'leadOwner',
      filters: leadOwners
        .map(({ firstName, lastName }) => ({
          value: `${firstName} ${lastName}`,
          text: `${firstName} ${lastName}`,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
      filteredValue: tableParams.filters?.leadOwner || null,
      filterSearch: true,
    },
  ];

  const { total } = tableParams.pagination || {};

  return (
    <SCLeadsAdminPage>
      <h2>Leads {typeof total === 'number' ? `(${total})` : ''}</h2>
      <Content>
        <TableContainer>
          <CustomTable
            columns={columns}
            dataSource={leadsDataSource}
            setSelectedRow={onClickRow}
            pagination={{
              ...tableParams.pagination,
              position: ['bottomCenter'],
            }}
            selectedRowId={selectedLeadId}
            loading={loadingGetLeads}
            onChange={handleTableChange}
          />
        </TableContainer>

        {selectedLead && (
          <DetailPanel>
            <DetailHeader>
              <h3 className="company-name">{selectedLead?.company?.name}</h3>
              {!editMode && (
                <Button
                  text="Editar"
                  size="small"
                  color="bluishGrey"
                  icon={faPen}
                  onClick={() => setEditMode(true)}
                />
              )}
            </DetailHeader>

            <DetailContainer>
              {!editMode ? (
                <DetailView data={selectedLead} />
              ) : (
                <>
                  <DetailForm
                    data={selectedLead}
                    onChange={setFormValues}
                    leadOwners={leadOwners}
                    leadSteps={filterSteps(leadSteps)}
                  />
                  <ButtonContainer>
                    <Button
                      text="Cancelar"
                      size="small"
                      color="white"
                      onClick={resetEditModeAndFormValues}
                    />
                    <Button
                      text={
                        loadingEditLead ? (
                          <SpinnerText text="Editando" />
                        ) : (
                          'Guardar'
                        )
                      }
                      size="small"
                      color="bluishGrey"
                      onClick={handleOnSave}
                      disabled={loadingEditLead || !formValues}
                    />
                  </ButtonContainer>
                </>
              )}
            </DetailContainer>
          </DetailPanel>
        )}
      </Content>
    </SCLeadsAdminPage>
  );
};

export default LeadsAdminPage;
