import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SCIcon from './Icon.style';

const Icon = ({
  iconName,
  size,
  color,
  border,
  borderRadius,
  padding,
  iconClassName,
  height,
  onClick,
  ...props
}) => {
  return (
    <SCIcon
      color={color}
      border={border}
      size={size}
      borderRadius={borderRadius}
      padding={padding}
      height={height}
      onClick={onClick}
      {...props}>
      <FontAwesomeIcon icon={iconName} className={iconClassName} />
    </SCIcon>
  );
};

export default Icon;
