import styled from '@emotion/styled';

const SCEmailSecurityTableCard = styled.div`
  .table-domain-info-container {
    display: flex;
    flex-direction: column;

    .domain-info-container {
      margin-top: 30px;
      color: var(--bluish-grey);
      font-weight: 500;
    }
    .emailSecurityRow {
      display: flex;
      flex-direction: row;
      place-content: baseline;
      align-items: center;
      transform: translate(-30px, 0px);
      width: calc(100% + 60px);
      padding: 0 30px;
      height: 50px;
      cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
      &:hover {
        background: var(--light-grey);
      }

      .emailSecurrityRowName {
        width: 20%;
        p {
          font-weight: 500;
        }
      }

      .emailSecurrityRowStatus {
        margin-left: 70px;
        width: 100%;
      }
    }
  }
`;

export default SCEmailSecurityTableCard;
