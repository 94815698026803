import React from 'react';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import useBreachNotificationType from '../../../hooks/breachNotificationType/useBreachNotificationType';
import SCVisionByEmployeeBreachesCard from './VisionByEmployeeBreachesCard.style';
import Label from '../../atoms/Label/Label';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import TagList from '../../molecules/TagList/TagList';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import { getDateFormat, getDateInMs } from '../../../utils/format/dataFormat';

const VisionByEmployeeBreachesCard = () => {
  const i18n = useTranslation();
  const { savedEmails } = useBreachNotificationType();
  const navigate = useNavigate();

  const mapperFn = (item) => {
    return {
      email: {
        type: 'component',
        value: (
          <Tooltip tooltip={<p>{item.email}</p>}>
            <p>{item.email}</p>
          </Tooltip>
        ),
        sortValue: item.email,
      },
      // type: 'string', value: item.email },
      breachesPerEmail: {
        type: 'component',
        value: (
          <Label
            value={item?.breachesPerEmail || '0'}
            color={item?.breachesPerEmail && 'red'}
          />
        ),
        sortValue: item?.breachesPerEmail || '0',
      },
      lastBreach: {
        type: 'string',
        value: item.lastBreachAt
          ? getDateFormat(item.lastBreachAt.toDate())
          : i18n.t('breachNotification.breachTable.never'),
        sortValue: item.lastBreachAt
          ? getDateInMs(item.lastBreachAt.toDate())
          : 0,
      },
      role: {
        type: 'component',
        value: <TagList teamMember={item} cell />,
      },
      onClick: () => navigate(`/client/data-alert?email=${item.email}`),
    };
  };

  const PAGE_SIZE = 10;
  const defaultSortDirection = 'asc';

  const sortOptions = {
    activeSortedColumnName: i18n.t(
      'breachNotification.breachTable.headers.members.name'
    ),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 3,
    onSort: (data) => {
      setSort(data);
    },
  };
  const {
    currentPage,
    setSort,
    setSearch,
    setSearchTags,
    setPage,
    rows,
    length,
  } = useFilterTable(PAGE_SIZE, mapperFn, savedEmails, ['email'], sortOptions);

  // Constante con información para pintar los headers de la tabla
  const columns = [
    {
      name: i18n.t('breachNotification.breachTable.headers.members.name'),
      options: {
        stickyOnScroll: true,
        width: 200,
        stickyOffset: 0,
        ellipsisContent: true,
        ofuscateFS: true,
      },
    },
    {
      name: i18n.t('breachNotification.breachTable.headers.members.breaches'),
    },
    {
      name: i18n.t(
        'breachNotification.breachTable.headers.members.lastBreachAt'
      ),
    },
    {
      name: i18n.t(`manageEmailList.tags`),
      options: {
        avoidSort: true,
      },
    },
  ];

  return (
    <SCVisionByEmployeeBreachesCard>
      <SectionCard
        headerIcon={faEye}
        headerTitle={`${i18n.t(
          'breachNotification.sectionByTeamMembers'
        )} (${length})`}>
        <TableContainer
          onChangeValue={setSearch}
          columns={columns}
          rows={rows}
          emptyMessage={i18n.t(
            'breachNotification.breachTable.emptyMessage.noEmployeesBreaches'
          )}
          hasRowIcon
          onChangeFilterTag={setSearchTags}
          hasHorizontalScroll
          onPageChange={(page) => setPage(page)}
          totalCount={length}
          currentPage={currentPage}
          pageSize={PAGE_SIZE}
          sortOptions={sortOptions}
        />
      </SectionCard>
    </SCVisionByEmployeeBreachesCard>
  );
};

export default VisionByEmployeeBreachesCard;
