import styled from '@emotion/styled';

const SCScoreExplanation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  border: 1px solid var(--light-grey-3);
  border-radius: 5px;
  padding: 30px;
  background-color: var(--light-grey-4);
`;

export const Observation = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;

  .scoreExplanation_score,
  .scoreExplanation_name {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .scoreExplanation_score {
    margin-right: 10px;
  }

  .scoreExplanation_name {
    color: var(--black);
  }
`;

export default SCScoreExplanation;
