import styled from '@emotion/styled';
import { css } from '@emotion/core';

const defaultStyle = () => css`
  background-color: var(--white);
  color: var(--bluish-grey);
  border: 1px solid var(--light-grey-3);

  & p {
    color: var(--bluish-grey);
  }
`;

const info = () => css`
  background-color: var(--light-green);
  color: var(--green);

  & p {
    color: var(--green);
  }
`;

const warn = () => css`
  background-color: var(--light-orange);
  color: var(--orange);

  & p {
    color: var(--orange);
  }
`;

const error = () => css`
  background-color: var(--light-red);
  color: var(--red);

  & p {
    color: var(--red);
  }
`;

const bannerStyles = {
  defaultStyle,
  info,
  check: info,
  warn,
  error,
};

const SCInfoBanner = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  width: 100%;

  ${({ type }) => (type ? bannerStyles[type] : bannerStyles.defaultStyle)};

  .icon-content-container {
    display: flex;
    align-items: center;
    gap: 20px;

    .custom-icon-container {
      display: flex;
      justify-content: center;
      width: 30px;
      aspect-ratio: 1/1;
    }

    .content-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  & h3 {
    color: inherit;
    font-size: 24px;
  }

  & p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    :last-of-type {
      margin-bottom: 0px;
    }
  }

  & button {
    height: fit-content;
    min-height: 35px;
    min-width: ${({ width }) => width};
  }

  @media (max-width: 576px) {
    text-align: center;
    flex-direction: column;
    > div {
      flex-direction: column;
    }
    & button {
      min-width: 100%;
    }
  }
`;

export default SCInfoBanner;
