import { faFishingRod } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCPhishingTrainingCard from './PhishingTrainingCard.style';
import { getDateFormat, parseDate } from '../../../utils/format/dataFormat';
import LabelDetailGroup from '../LabelDetailGroup/LabelDetailGroup';

const PhishingTrainingCard = ({ data = {}, isHeaderClickable, loading }) => {
  const navigate = useNavigate();
  const i18n = useTranslation();

  const {
    currentCampaigns,
    completedTrainings,
    completedCampaigns,
    lastCampaignAt,
    updatedAt,
  } = data;

  const parsedLastCampaignAt = parseDate(lastCampaignAt);
  const parsedUpdatedAt = parseDate(updatedAt);

  const LABEL_DETAILS = [
    {
      name: i18n.t('controlPanel.phisingTraining.currentCampaigns'),
      value: currentCampaigns || '0',
    },
    {
      name: i18n.t('controlPanel.phisingTraining.completedTrainings'),
      value: completedTrainings || '0',
    },
    {
      name: i18n.t('controlPanel.phisingTraining.completedCampaigns'),
      value: completedCampaigns || '0',
    },
    {
      name: i18n.t('controlPanel.phisingTraining.lastDateCampaign'),
      value: lastCampaignAt ? getDateFormat(parsedLastCampaignAt) : '-',
    },
  ];

  const handleHeaderButtonOnClick = isHeaderClickable
    ? () => {
        navigate('/client/phishingsimulator');
      }
    : undefined;

  return (
    <SCPhishingTrainingCard>
      <SectionCard
        headerIcon={faFishingRod}
        headerTitle={i18n.t('controlPanel.phisingTraining.title')}
        headerButtonOnClick={handleHeaderButtonOnClick}>
        <LabelDetailGroup
          labelDetails={LABEL_DETAILS}
          lastUpdateDate={parsedUpdatedAt}
          loading={loading}
        />
      </SectionCard>
    </SCPhishingTrainingCard>
  );
};

export default PhishingTrainingCard;
