import React from 'react';
import { useTranslation } from '../../i18n';
import SCEmbeddedTawkTo from './supportGerencia.style';

import Navbar from '../../components/molecules/Navbar/Navbar';
import ContactLegalInformation from '../../components/molecules/ContactLegalInformation/ContactLegalInformation';

const SupportGerenciaView = () => {
  const i18n = useTranslation();

  return (
    <SCEmbeddedTawkTo>
      <Navbar hideAuthButtons showSantanderLogo />
      <div className="chat-container">
        <div className="title">{i18n.t('supportGerencia.title')}</div>
        <div className="frame">
          <iframe src="https://tawk.to/cggerencia" title="TawkTo" />
        </div>
      </div>
      <ContactLegalInformation />
    </SCEmbeddedTawkTo>
  );
};

export default SupportGerenciaView;
