import styled from '@emotion/styled';

const SCIndeterminateCheckbox = styled.label`
  margin: 0;
  width: 20px;
  height: 20px;
  background-color: ${({ empty }) =>
    empty ? 'transparent' : 'var(--bluish-grey-4)'};
  border: 1px solid var(--bluish-grey-2);
  border-radius: 2px;
  cursor: pointer;
  display: flex;

  :hover {
    background-color: var(--light-grey);
    transition: 0.2s;
  }

  .checkbox-indicator {
    display: flex;
    margin: auto;
    svg {
      height: 14px;
      width: 14px;
      transition: transform 200ms ease;
    }
  }

  input {
    appearance: none;
  }
`;

export default SCIndeterminateCheckbox;
