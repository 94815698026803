import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
  faGlobe,
  faMask,
} from '@fortawesome/pro-regular-svg-icons';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { useTranslation } from '../../../i18n';
import SCSupplierSecurityExplanationCard from './SupplierSecurityExplanationCard.style';
import useCollapsableCardStatus from '../../../hooks/collapsableCardStatus/useCollapsableCardStatus';
import useSection from '../../../hooks/useSection/useSection';

const SupplierSecurityExplanationCard = () => {
  const i18n = useTranslation();
  const { section } = useSection();

  const { collapsableCardOpened, switchCollapsableCardStatus } =
    useCollapsableCardStatus(
      `${section}.how-do-we-evaluate-suppliers-card.hide`
    );

  return (
    <SCSupplierSecurityExplanationCard>
      <SectionCard
        headerIcon={faCircleInfo}
        headerTitle={i18n.t('suppliersSecurity.explains')}
        isCollapsed={collapsableCardOpened}
        headerCollapsableButtonOnClick={() => switchCollapsableCardStatus()}>
        <div className="ss-evaluation">
          <div>
            <FontAwesomeIcon icon={faMask} style={{ fontSize: '17px' }} />
            <div>
              <h3>{i18n.t('suppliersSecurity.impersonationRisk')}</h3>
              <p>{i18n.t('suppliersSecurity.impersonationExplanation')}</p>
            </div>
          </div>
          <div>
            <FontAwesomeIcon icon={faGlobe} style={{ fontSize: '17px' }} />
            <div>
              <h3>{i18n.t('suppliersSecurity.websiteSecurityRisk')}</h3>
              <p>{i18n.t('suppliersSecurity.websiteSecurityExplanation')}</p>
            </div>
          </div>
        </div>
      </SectionCard>
    </SCSupplierSecurityExplanationCard>
  );
};

export default SupplierSecurityExplanationCard;
