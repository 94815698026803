import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from '@emotion/styled';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { useTranslation } from '../../../i18n';
import { ErrorKey } from '../../../track';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import monitoredDomainsService from '../../../services/monitoredDomains/monitoredDomains.service';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import { ERRORS } from '../../../utils/constants/errors';
import { REGULAR_EXPRESSIONS } from '../../../utils/constants/regularExpressions';

const SCAddWebsite = styled.div`
  h1 {
    text-align: center;
    font-family: var(--font1);
    font-size: 25px;
    font-weight: 600;
  }
`;

const AddWebsite = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const [website, setWebsite] = useState();
  const [loading, setLoading] = useState(false);

  const validateAndSaveDomain = () => {
    if (!REGULAR_EXPRESSIONS.WEBSITE.test(website)) {
      ErrorKey('websiteSecurity.add.errorDomainFormat');
      return;
    }

    setLoading(true);

    monitoredDomainsService
      .addMonitoredDomain(website)
      .then(() => {
        dispatch(showPopUp(null));
      })
      .catch((error) => {
        const { data: errorCode } = error.response;

        const i18nKey =
          errorCode === ERRORS.INVALID_DOMAIN
            ? 'websiteSecurity.add.errorDomainFormat'
            : 'errors.not_found';
        ErrorKey(i18nKey);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeHandlerText = (val) => {
    const replacedVal = `${val
      .replace(/https:\/\//g, '')
      .replace(/http:\/\//g, '')}`
      .trim()
      .toLowerCase();

    setWebsite(replacedVal);
  };

  return (
    <ModalLayout>
      <SCAddWebsite>
        <h1>{i18n.t('websiteSecurity.add.title')}</h1>
        <div className="modal-card-content">
          <Input
            name="website"
            id="website"
            inputType="text"
            size="large"
            inputPlaceholder={i18n.t('websiteSecurity.add.placeholderWebsite')}
            defaultValue={website || ''}
            onChangeValue={(val) => changeHandlerText(val)}
            center
          />
        </div>
        <div className="modal-card-buttons">
          <Button
            text={
              loading ? (
                <SpinnerText text={i18n.t('common.loading')} />
              ) : (
                i18n.t('websiteSecurity.add.validateButton')
              )
            }
            onClick={validateAndSaveDomain}
            size="full"
            color="bluishGrey"
            disabled={loading}
          />
          <Button
            text={i18n.t('websiteSecurity.add.cancel')}
            onClick={() => dispatch(showPopUp(null))}
            size="full"
            color="white"
          />
        </div>
      </SCAddWebsite>
    </ModalLayout>
  );
};

export default AddWebsite;
