import { useDispatch, useSelector } from 'react-redux';
import { showPopUp } from '../../redux/actions/popUp.actions';

function useDomains() {
  const dispatch = useDispatch();
  const client = useSelector((redux) => redux.client);

  const removeDomain = (domain) => {
    dispatch(showPopUp('removeWebsite', domain));
  };

  const addDomain = () => {
    if (
      client.monitoredDomains.length <
      client?.status?.serviceLevel.limits.maxDomains
    ) {
      dispatch(showPopUp('addWebsite'));
    } else {
      dispatch(showPopUp('maxDomainsPopUp'));
    }
  };

  return {
    addDomain,
    removeDomain,
  };
}

export default useDomains;
