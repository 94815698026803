import React from 'react';
import {
  faCheckCircle,
  faClipboardListCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCActionsProgress from './ActionsProgress.style';
import ProgressBar from '../../atoms/ProgressBar/ProgressBar';
import Icon from '../../atoms/Icon/Icon';
import ActionsEmptyMessage from '../../molecules/ActionsEmptyMessage/ActionsEmptyMessage';
import { useTranslation } from '../../../i18n';
import {
  getCompletedActions,
  getDisplayableActions,
} from '../../../utils/functions/actions';

const ActionsProgress = ({ title }) => {
  const i18n = useTranslation();
  const client = useSelector((redux) => redux.client);
  const completedActions = getCompletedActions(client.status.actions);
  const nCompletedActions = completedActions.length;
  const nTotalActions = getDisplayableActions(client.status.actions).length;

  return (
    <SCActionsProgress>
      <SectionCard headerIcon={faClipboardListCheck} headerTitle={title}>
        {nTotalActions > 0 ? (
          <div className="completed-actions-container">
            <div className="left-container">
              <div className="completed-total-text-container">
                <h1>{nCompletedActions}</h1>
                <span>/{nTotalActions}</span>
              </div>

              <div className="completed-actions-text-container">
                <p>{i18n.t('welcome.actions')}</p>
                <p>{i18n.t('welcome.completed')}</p>
              </div>

              <ProgressBar
                backgroundColor="#F8F9FA"
                completed={nCompletedActions}
                total={nTotalActions}
                hideXAxis
                height="9px"
              />
            </div>

            <div className="right-container">
              <p>{i18n.t('action-center.lastActionsCompleted')}</p>

              {completedActions && (
                <div className="actions-fade-container">
                  <div className="actions-scroll-container">
                    <div className="actions-container">
                      {completedActions
                        .sort((a, b) => b.doneAt - a.doneAt)
                        .slice(0, 3)
                        .map((action) => (
                          <div className="action-container">
                            <Icon
                              iconName={faCheckCircle}
                              color="var(--green)"
                            />
                            <p>
                              {i18n.t(
                                `action-center.actions.${action.type}.title`
                              )}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <ActionsEmptyMessage
            title={i18n.t('action-center.titleEmptyMessageCompletedActions')}
          />
        )}
      </SectionCard>
    </SCActionsProgress>
  );
};

export default ActionsProgress;
