import styled from '@emotion/styled';

const SCSingleActionProgress = styled.div`
  position: relative;

  .progressData {
    font-size: 17px;

    margin-top: 40px;
    font-family: var(--font2);

    & b {
      color: #2e2e2e;
    }
    & h4 {
      margin-left: 0;
    }
  }
`;

export default SCSingleActionProgress;
