import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../i18n';
import SCContactLegalInformation from './ContactLegalInformation.style';
import { ReactComponent as CyberGuardianLogo } from '../../../img/cyberguardian/cyberguardian-logo-bluish-grey.svg';
import Separator from '../../atoms/Separator/Separator';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { isCallMeAvailable } from '../../../utils/locale';
import getLegalDocumentsUrls from '../../../utils/internationalizationModules/legalDocuments';
import { auth } from '../../../firebase';
import { PUBLIC_HOME } from '../../../utils/constants/urls';

const ContactLegalInformation = ({
  color = 'black',
  size = 'full',
  separator = false,
}) => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { privacyPolicy, generalConditions, legalNoticeAndTermsOfUse } =
    getLegalDocumentsUrls(i18n.getLanguage());

  const handleLogo = () => {
    if (auth.currentUser) {
      navigate('/client');
    } else {
      window.location.href = PUBLIC_HOME;
    }
  };

  return (
    <SCContactLegalInformation color={color} size={size} separator={separator}>
      <div className="footer-container">
        {/* TODO - Se añadirá cuando añadamos este footer en toda la app */}

        {separator && (
          <div className="separator-container">
            <Separator />
          </div>
        )}

        <div className="footer-content-container">
          <div className="copyright-container">
            <CyberGuardianLogo onClick={handleLogo} />

            <p>© Cyber Guardian {new Date().getFullYear()}</p>

            <LanguageSelector displayLanguageIcon={false} color={color} />
          </div>

          <div className="contact-container">
            <h3>{i18n.t('contactUs.contact')}</h3>

            <a
              href="https://blog.cyberguardian.tech/"
              target="_blank"
              rel="noreferrer">
              {i18n.t('contactUs.blog')}
            </a>

            <a
              href="https://www.linkedin.com/company/cyber-guardian-security"
              target="_blank"
              rel="noreferrer">
              LinkedIn
            </a>

            <a href="mailto:contact@cyberguardian.tech">
              {i18n.t('contactUs.email')}
            </a>
          </div>

          <div className="support-container">
            <h3>{i18n.t('contactUs.support')}</h3>

            {isCallMeAvailable(pathname) && (
              <a onClick={() => dispatch(showPopUp('callUs'))}>
                {i18n.t('callMe.weCallYou')}
              </a>
            )}

            <a onClick={() => Tawk_API.toggle()}>
              {i18n.t('contactUs.contactSupport')}
            </a>

            <a href="mailto:support@cyberguardian.tech">
              {i18n.t('contactUs.email')}
            </a>
          </div>

          <div className="legal-container">
            <h3>{i18n.t('contactUs.legal')}</h3>

            <a href={legalNoticeAndTermsOfUse} target="_blank" rel="noreferrer">
              {i18n.t('sidebar.legal')}
            </a>

            <a href={generalConditions} target="_blank" rel="noreferrer">
              {i18n.t('sidebar.contractConditions')}
            </a>

            <a href={privacyPolicy} target="_blank" rel="noreferrer">
              {i18n.t('sidebar.privacy')}
            </a>

            <a onClick={() => Optanon.ToggleInfoDisplay()}>
              {i18n.t('sidebar.cookies')}
            </a>
          </div>
        </div>
      </div>
    </SCContactLegalInformation>
  );
};

export default ContactLegalInformation;
