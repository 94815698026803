import styled from '@emotion/styled';

const SCYourPaymentMethodInformationCard = styled.div`
  .info-content {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .buttons-form-container {
      display: flex;
      gap: 20px;
    }
  }
`;

export default SCYourPaymentMethodInformationCard;
