import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { TrackButton } from '../../../track';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import { deleteTeamMember } from '../../../services/teamMembers/teamMembers.service';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const SCRemoveEmails = styled.div`
  h1 {
    text-align: center;
    font-family: var(--font1);
    font-size: 25px;
    font-weight: 500;
  }

  p {
    text-align: center;
    font-family: var(--font2);
    color: var(--black);
    :first-of-type {
      font-weight: 500;
    }
  }
`;

const RemoveEmails = (props) => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const user = useSelector((redux) => redux.user);

  const { selectedTeamMembers } = props;

  const noOwnerTeamMembers = selectedTeamMembers.filter((teamMember) => {
    return (
      !teamMember.role.owner && teamMember.hashedEmail !== user.hashedEmail
    );
  });

  const isOwnerOnly = () => {
    return noOwnerTeamMembers.length === 0 && selectedTeamMembers.length === 1;
  };

  const removeTeamMemberById = async () => {
    noOwnerTeamMembers.forEach((noOwnerTeamMember) => {
      deleteTeamMember(noOwnerTeamMember.hashedEmail);
    });
    closeModel();
  };

  const closeModel = () => {
    dispatch(showPopUp(null));
  };

  return (
    <ModalLayout>
      <SCRemoveEmails>
        {!isOwnerOnly() && (
          <>
            <h1>{i18n.t('manageEmailList.remove.title')}</h1>
            <div className="modal-card-content">
              {noOwnerTeamMembers.length === 1 && (
                <p>{i18n.t('manageEmailList.remove.oneUser')}</p>
              )}
              {noOwnerTeamMembers.length > 1 && (
                <p>
                  {i18n.t('manageEmailList.remove.numberOfUsers', {
                    numberOfUsers: `${noOwnerTeamMembers.length}`,
                  })}
                </p>
              )}

              {noOwnerTeamMembers.length !== selectedTeamMembers.length && (
                <p>{i18n.t('manageEmailList.remove.warningOwnerIncluded')}</p>
              )}
            </div>
            <div className="modal-card-buttons">
              <Button
                text={i18n.t('manageEmailList.remove.confirm')}
                onClick={() => {
                  removeTeamMemberById();
                  TrackButton('manageEmailList.remove.confirm');
                }}
                size="full"
                color="bluishGrey"
              />
              <Button
                text={i18n.t('manageEmailList.remove.decline')}
                onClick={() => {
                  closeModel();
                  TrackButton('manageEmailList.remove.decline');
                }}
                size="full"
                color="white"
              />
            </div>
          </>
        )}
        {isOwnerOnly() && (
          <>
            <h1>{i18n.t('manageEmailList.remove.ownserOnlyTitle')}</h1>
            <div className="modal-card-content">
              <p>{i18n.t('manageEmailList.remove.ownerOnly')}</p>
            </div>
            <div className="modal-card-buttons">
              <Button
                text={i18n.t('manageEmailList.remove.understood')}
                onClick={() => {
                  closeModel();
                }}
                size="full"
                color="bluishGrey"
              />
            </div>
          </>
        )}
      </SCRemoveEmails>
    </ModalLayout>
  );
};

export default RemoveEmails;
