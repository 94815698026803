import { useSelector } from 'react-redux';

function useLicenses() {
  const client = useSelector((redux) => redux.client);

  const totalLicenses = client?.status?.serviceLevel?.limits?.nbLicences || 0;
  const sentLicenses = client.status?.nbLicensesSent || 0;
  const installedLicenses = client.status?.nbLicensesUsed || 0;

  const sentNotInstalledLicenses = sentLicenses - installedLicenses;
  const availableLicensesToSend = totalLicenses - sentLicenses;

  return {
    sentLicenses,
    availableLicensesToSend,
    sentNotInstalledLicenses,
    totalLicenses,
    installedLicenses,
  };
}

export default useLicenses;
