// Subnavbar el que lo lea

import {
  faBadgeCheck,
  faDesktop,
  faDolly,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEnvelopesBulk,
  faEye,
  faGear,
  faGlobe,
  faListUl,
  faMask,
  faRadar,
  faUser,
  faUserSecret,
} from '@fortawesome/pro-regular-svg-icons';
import { faShieldAlt } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import Dropdown from '../../atoms/Dropdown/Dropdown';
import Icon from '../../atoms/Icon/Icon';
import SCSubNavBar from './SubNavBar.style';
import ApplicationSections from '../../../utils/sections/sections';
import useSection from '../../../hooks/useSection/useSection';
import { isUserInTeamMembers } from '../../../utils/functions/teamMembers';

const SubNavBar = () => {
  const { section, subSection } = useSection();

  const navigate = useNavigate();
  const i18n = useTranslation();

  const settingsSectionDropdownItems = {
    ...(isUserInTeamMembers() && {
      'my-account': {
        text: i18n.t('profile.menu.myAccount'),
        icon: faUser,
        onClick: () => navigate('/client/settings/my-account'),
      },
    }),
    employees: {
      text: i18n.t('profile.menu.employeesLong'),
      icon: faListUl,
      onClick: () => navigate('/client/settings/employees'),
    },
    ...(isUserInTeamMembers() && {
      'email-preferences': {
        text: i18n.t('profile.menu.emailPreferences'),
        icon: faEnvelopeOpenText,
        onClick: () => navigate('/client/settings/email-preferences'),
      },
    }),
    billing: {
      text: i18n.t('profile.menu.mySubscription'),
      icon: faBadgeCheck,
      onClick: () => navigate('/client/settings/billing'),
    },
  };

  const internalSecurityDropdownItems = {
    'device-security': {
      text: i18n.t('sections.protection.deviceSecurity.name'),
      icon: faDesktop,
      onClick: () => navigate('/client/device-security'),
    },
    'managed-threats': {
      text: i18n.t('sections.protection.managedThreats.name'),
      icon: faRadar,
      onClick: () => navigate('/client/managed-threats'),
    },
    'mailbox-security': {
      text: i18n.t('sections.protection.mailboxSecurity.name'),
      icon: faEnvelopesBulk,
      onClick: () => navigate('/client/mailbox-security'),
    },
  };

  const internetExposureDropdownItems = {
    'configure-email-security': {
      text: i18n.t('sections.exposure.emailSecurity.name'),
      icon: faEnvelopeOpen,
      onClick: () => navigate('/client/configure-email-security'),
    },
    'data-alert': {
      text: i18n.t('sections.exposure.dataFiltrations.name'),
      icon: faUserSecret,
      onClick: () => navigate('/client/data-alert'),
    },
    websitesecurity: {
      text: i18n.t('sections.exposure.websiteSecurity.name'),
      icon: faGlobe,
      onClick: () => navigate('/client/websitesecurity'),
    },
    domainimpersonation: {
      text: i18n.t('sections.exposure.domainImpersonation.name'),
      icon: faMask,
      onClick: () => navigate('/client/domainimpersonation'),
    },
    'supplier-security': {
      text: i18n.t('sections.exposure.supplierSecurity.name'),
      icon: faDolly,
      onClick: () => navigate('/client/supplier-security'),
    },
  };

  const sectionData = {
    settings: {
      icon: faGear,
      name: i18n.t('profile.menu.settings'),
      dropDownItems: settingsSectionDropdownItems,
    },
    internalSecurity: {
      icon: faShieldAlt,
      name: i18n.t('sections.protection.name'),
      dropDownItems: internalSecurityDropdownItems,
    },
    internetExposure: {
      icon: faEye,
      name: i18n.t('sections.exposure.name'),
      dropDownItems: internetExposureDropdownItems,
    },
  };

  const generalSection =
    Object.keys(ApplicationSections).find((key) =>
      ApplicationSections[key].includes(section)
    ) || section;

  return (
    <>
      {sectionData[generalSection] && (
        <SCSubNavBar>
          <div className="section-info">
            <Icon
              iconName={sectionData[generalSection].icon}
              color="var(--bluish-grey)"
              size="medium"
            />
            <h3>{sectionData[generalSection].name}</h3>
          </div>
          <Dropdown
            selectedItem={
              sectionData[generalSection].dropDownItems[
                section === 'settings' ? subSection : section
              ]
            }
            items={Object.values(sectionData[generalSection].dropDownItems)}
          />
        </SCSubNavBar>
      )}
    </>
  );
};

export default SubNavBar;
