import React from 'react';
import { Form, Input, DatePicker } from 'antd';
import styled from '@emotion/styled/macro';
import SCCustomCard from './CustomCard.style';

const ContentGrid = styled.div`
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    padding-left: 15px;
    gap: 50px;
  }

  .half-width {
    flex: 1;
  }
`;

const SubscriptionCard = () => {
  return (
    <SCCustomCard title="Suscripción" size="small" bordered>
      <ContentGrid>
        <Form.Item
          label={
            <span>
              <strong>Email cliente</strong>
            </span>
          }
          name={['subscription', 'clientEmail']}>
          <Input disabled size="small" placeholder="N/A" />
        </Form.Item>

        <Form.Item
          label={
            <span>
              <strong>Nº licencias</strong>
            </span>
          }
          name={['subscription', 'nbLicences']}>
          <Input disabled size="small" placeholder="N/A" />
        </Form.Item>

        {/* Creamos una fila para Fecha Inicio y Fecha Fin */}
        <div className="row">
          <Form.Item
            label={
              <span>
                <strong>Fecha inicio</strong>
              </span>
            }
            name={['subscription', 'startTime']}>
            <DatePicker
              format="DD/MM/YYYY"
              disabled
              size="small"
              placeholder="N/A"
            />
          </Form.Item>

          <Form.Item
            label={
              <span>
                <strong>Fecha fin</strong>
              </span>
            }
            name={['subscription', 'endTime']}>
            <DatePicker
              format="DD/MM/YYYY"
              disabled
              size="small"
              placeholder="N/A"
            />
          </Form.Item>
        </div>
      </ContentGrid>
    </SCCustomCard>
  );
};

export default SubscriptionCard;
