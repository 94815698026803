import { AUTH_USER, SIGN_OUT, SIGN_IN } from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case AUTH_USER:
      return action.payload || null;
    case SIGN_OUT:
      return action.payload || null;
    case SIGN_IN:
      return action.payload || null;
    default:
      return state;
  }
};
