import React from 'react';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import SCScanResultBox from './ScanResultBox.style';
import {
  getSecurityColor,
  getSecurityText,
} from '../../../utils/functions/securitySeverity';
import Icon from '../../atoms/Icon/Icon';
import { useTranslation } from '../../../i18n';

const ScanResultBox = ({ title, scanScore, learnMoreOnClick, lineHeight }) => {
  const i18n = useTranslation();
  return (
    <SCScanResultBox
      color={scanScore ? getSecurityColor(scanScore) : 'default'}
      lineHeight={lineHeight}>
      <div className="scan-result-box">
        <p
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <div className="risk-tag">
          {i18n.t(
            `websiteSecurity.${
              scanScore ? getSecurityText(scanScore) : 'unknown'
            }`
          )}
        </div>
        <div className="learn-more" onClick={learnMoreOnClick}>
          <Icon iconName={faCircleInfo} size="small" />
          <p>{i18n.t('websiteSecurity.learnMore')}</p>
        </div>
      </div>
      <div className="scan-result-line" />
      <div className="scan-result-circle" />
    </SCScanResultBox>
  );
};

export default ScanResultBox;
