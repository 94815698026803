import styled from '@emotion/styled';

const SCLicensesOverviewCard = styled.div`
  & > div {
    height: 100%;
    min-height: 300px;
    flex-direction: column;
    display: flex;
  }
`;

export default SCLicensesOverviewCard;
