import React, { useState } from 'react';

import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../i18n';
import TutorialContent from './TutorialContent';

import OrientationBox from '../../molecules/OrientationBox/OrientationBox';
import Button from '../../atoms/Button/Button';
import { finishTutorial } from '../../../redux/actions/client.actions';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const TutorialContentBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 15px;

  p {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--font1);
    text-align: left;
    font-weight: 400;
  }
`;

const TutorialBox = styled.div`
  padding: 10px 0px;
  width: 100%;
  border-radius: 9px;
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  color: black;
  align-items: center;
  gap: 1.25rem;

  & p {
    margin-bottom: 0 !important;
    text-align: start;
  }

  & svg {
    margin: auto;
    color: var(--bluish-grey);
  }
`;

const TutorialContainer = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    text-align: center;
    font-size: 25px;
  }
`;

const renderTutorialContent = (content) => {
  if (React.isValidElement(content)) {
    return content;
  }

  if (content.icon && content.p) {
    return (
      <TutorialBox>
        {content.icon && <FontAwesomeIcon icon={content.icon} size="3x" />}
        <p>{content.p}</p>
      </TutorialBox>
    );
  }
};

const Tutorial = ({ tutorial }) => {
  const i18n = useTranslation();
  const [step, setStep] = useState(1);

  const dispatch = useDispatch();

  const tutorialContent = TutorialContent();
  const totalSteps = tutorialContent[tutorial].steps.length;

  const orientation = () => (
    <OrientationBox
      activeStep={step}
      onClick={setStep}
      totalSteps={totalSteps}
    />
  );

  const closeModal = () => {
    dispatch(finishTutorial(tutorial));
  };

  const footer = () => (
    <div className="modal-card-buttons">
      {step < totalSteps && (
        <>
          <Button
            size="full"
            color="bluishGrey"
            text={i18n.t('tutorials.next')}
            onClick={() => setStep(step + 1)}
          />
          <Button
            size="full"
            color="white"
            text={i18n.t('tutorials.skipTutorial')}
            onClick={() => closeModal()}
          />
        </>
      )}
      {step === totalSteps && (
        <Button
          size="full"
          color="bluishGrey"
          text={i18n.t('tutorials.finish')}
          onClick={() => closeModal()}
        />
      )}
    </div>
  );

  return (
    <ModalLayout>
      <TutorialContainer>
        {totalSteps > 1 && orientation()}
        <h1>{tutorialContent[tutorial].steps[step - 1].title}</h1>
        <div className="modal-card-content">
          <TutorialContentBox>
            {tutorialContent[tutorial].steps[step - 1].content.map((p) =>
              renderTutorialContent(p)
            )}
          </TutorialContentBox>
        </div>

        {footer()}
      </TutorialContainer>
    </ModalLayout>
  );
};

export default Tutorial;
