import { faBell, faClockFive } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import { useTranslation } from '../../../i18n';
import { getDateFormat } from '../../../utils/format/dataFormat';
import { getSecurityIcon } from '../../../utils/functions/securitySeverity';
import Icon from '../../atoms/Icon/Icon';
import SectionLayout from '../../layouts/SectionLayout/SectionLayout';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import SCProfileNotifications from './ProfileNotifications.style';
import useNotifications from '../../../hooks/notifications/useNotifications';

const ProfileNotifications = () => {
  const i18n = useTranslation();
  const { notifications, isLoading } = useNotifications();

  const filterEmptyChanges = (changes) => {
    if (!changes) return {};
    const filtered = Object.keys(changes)
      .filter((k) => changes[k])
      .reduce((acc, k) => ({ [k]: changes[k], ...acc }), {});
    return filtered;
  };

  const getNotificationInfo = (notification) => {
    if (!notification?.type || !notification?.changes) return '';
    // changes is a map (key-value) similar to actions.Progress
    // where the keys are the affected resource (domain, user_uuid, email hash for breaches)
    // and the values are structs/objects that include any relevant information
    const changes = filterEmptyChanges(notification.changes);
    const numChanges = Object.keys(changes).length;
    if (numChanges < 1) {
      return '';
    }

    // TODO: use more info of what's in changes for each notification type
    //       https://github.com/Bewica/gofire/blob/staging/notifications/data.go#L103
    const typeKey = notification.type;
    if (notification.type === 'phishing') {
      // TODO: different kinds of phishing notification
    }

    let tKey = `notifications.messages.${typeKey}`;
    if (notification.level === 'info') {
      tKey = `notifications.messages.info.${typeKey}`;
    }

    return (
      i18n.t(tKey, { count: numChanges }) || `notification of type ${typeKey}`
    );
  };

  const mapperFn = (notification) => {
    return {
      icon: {
        type: 'icon',
        value: getSecurityIcon(notification?.level),
        color:
          notification.level === 'info' ? 'var(--bluish-grey-2)' : 'var(--red)',
        fontSize: '20px',
      },
      date: {
        type: 'component',
        value: (
          <div className="notification-container">
            <p>{getNotificationInfo(notification)}</p>
            <div className="icon-date-container">
              <Icon iconName={faClockFive} size="small" />
              {getDateFormat(notification?.sent?.toDate(), {
                month: 'short',
                year: undefined,
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
          </div>
        ),
      },
      futureArrow: {
        type: 'string',
        value: '',
      },
    };
  };

  const PAGE_SIZE = 6;
  const ROW_HEIGHT = 92;

  const { currentPage, setPage, rows, length } = useFilterTable(
    PAGE_SIZE,
    mapperFn,
    notifications
  );

  return (
    <SectionLayout>
      <SCProfileNotifications rowHeight={ROW_HEIGHT}>
        <SectionCard
          headerTitle={i18n.t('profile.notifications.title')}
          headerIcon={faBell}>
          <TableContainer
            rows={rows}
            emptyMessage={i18n.t('profile.notifications.empty')}
            rowHeight={ROW_HEIGHT}
            isLoading={isLoading}
            onPageChange={(page) => setPage(page)}
            totalCount={length}
            currentPage={currentPage}
            pageSize={PAGE_SIZE}
          />
        </SectionCard>
      </SCProfileNotifications>
    </SectionLayout>
  );
};

export default ProfileNotifications;
