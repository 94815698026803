import styled from '@emotion/styled';

const SCTooltip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;
  .show-tooltip {
    background-color: var(--white);
    border-radius: 5px;
    height: auto;
    box-shadow: rgb(121 136 151 / 15%) 2px 2px 20px 0px;
    white-space: nowrap;

    z-index: 2000000003;
    position: fixed;
    background-color: white;

    // top: calc(${(props) => props.mouseY}px - 30px);
    // left: calc(${(props) => props.mouseX}px + 10px);

    display: ${(props) => (props.forceDisplay ? 'inherit' : 'none')};

    p {
      color: var(--bluish-grey);
      font-family: var(--font1);
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      padding: 5px 10px;
    }
  }
`;

export default SCTooltip;
