import styled from '@emotion/styled';

const SCDeviceSecurityCard = styled.div`
  .chart-date-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;

    .chart-alert {
      display: flex;
      gap: 10px;

      > p {
        color: var(--red);
      }
    }

    .apex-chart-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @media (width < 500px) {
    .apexcharts-legend {
      justify-content: flex-start !important;
      top: auto !important;
    }
  }
`;

export default SCDeviceSecurityCard;
