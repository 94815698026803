import styled from '@emotion/styled';

const SCProfileInfo = styled.div`
  .two-factor-autentication-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & p {
      margin-bottom: 0px;
      font-family: var(--font2);
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

export default SCProfileInfo;
