import axios from 'axios';
import { showPopUp } from './popUp.actions';

export const finishTutorial = (tutorial) => async (dispatch, getState) => {
  if (!tutorial) return;
  const { client, popUp } = getState();
  axios
    .post(`/clients/${client.id}/tutorial/`, {
      tutorial,
    })
    .then((res) => {
      if (popUp) dispatch(showPopUp(null));
      return true;
    })
    .catch((error) => {
      if (popUp) dispatch(showPopUp(null));
      return false;
    });
};

export const switchHDI = (clientId) => {
  axios
    .post(`/clients/${clientId}/hdi/`, {})
    .then((res) => true)
    .catch((error) => false);
};
