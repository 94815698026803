import styled from '@emotion/styled';

const SCImpersonationInfoCard = styled.div`
  h1 {
    margin-bottom: 20px;
  }
  .ImpersonationInfoCard_MainContainer {
    display: flex;
    flex-direction: row;
    gap: 60px;
  }
  .ImpersonationInfoCard_ColumnContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .ImpersonationInfoCard_Info {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    span,
    p {
      font-size: 14px;
      margin: 0;
    }
    p {
      font-weight: 700;
    }
  }

  .ImpersonationInfoCard_ImageContainer {
    margin-bottom: 60px;
  }

  .ImpersonationInfoCard_ActionContainer {
    margin-bottom: 30px;
    p {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }

  .ImpersonationInfoCard_Actions {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-content: center;
    align-items: center;

    button {
      min-width: 100px;
    }
  }

  .ImpersonationInfoCard_BannerContainer {
    margin-bottom: 60px;

    a {
      color: var(--orange);
      font-weight: 500;
    }

    .icann-icon-text-container {
      display: flex;
      gap: 10px;
    }
  }

  .ImpersonationInfoCard_ScoreResume {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
`;

export default SCImpersonationInfoCard;
