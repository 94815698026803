import axios from 'axios';
import {
  filtersToString,
  sortToString,
} from '../../utils/functions/queryParameters';

/* eslint-disable import/prefer-default-export */
export const requestContact = async (contactPhone, language, name, token) => {
  return axios.post(`/send_data_lead/contact-request`, {
    contactPhone,
    language,
    name,
    token,
  });
};

export const getLeads = async ({ pagination, sorter, filters }) => {
  return axios.get(`/leads`, {
    params: {
      filter: filters ? `${filtersToString(filters)}` : null,
      page: pagination.current,
      order: sortToString(sorter),
      limit: pagination.pageSize,
    },
  });
};

export const getLeadsOwner = () => {
  return axios.get('/leads/lead-owners');
};

export const getLeadsSteps = () => {
  return axios.get('/leads/steps');
};

export const getLeadsEvents = () => {
  return axios.get('/leads/events');
};

export const editLead = async ({
  id,
  emailManager,
  leadOwner,
  notes,
  numberEmployees,
  position,
  stepId,
} = {}) => {
  return axios.put(`/leads/${id}`, {
    emailManager,
    leadOwner,
    notes,
    numberEmployees,
    position,
    stepId,
  });
};
