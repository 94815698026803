import styled from '@emotion/styled';

const SCMailboxSecuritySection = styled.div`
  .tutorial-main-container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    p {
      font-size: 14px;
    }

    .step-1-container,
    .step-2-container {
      display: flex;
      flex-direction: column;
      gap: 30px;

      b {
        color: var(--black);
      }
    }

    .step-1-container {
      .questions {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .question-container {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .question {
            display: flex;
            gap: 8px;

            > p {
              font-weight: 700;
            }
          }

          .question-actions {
            display: flex;
            gap: 15px;
          }
        }
      }
    }

    .step-2-container {
      .email-protection-tutorial {
        padding: 20px;
        align-items: center;
        background-color: #f4f4f4; 
        border-radius: 8px;
        line-height: 1.6;
        max-width: 800px;
        font-family: var(--font2);
        margin: auto; 

        p{
          margin-bottom: 20px;
          color: var(--bluish-grey);
          font-size: 15px;

          span{
            font-weight: bold;
          }
        }

        a{
          font-weight: 500;
          margin-top: 10px; 
          border-radius: var(--btn-radius);
          border-style: solid;
          border-color: var(--gray);
          color: var(--bluish-grey);
          border-width: 1px;
          padding: 10px;
          font-family: var(--font2);
        }

        .important-note {
          margin-top: 10px; 

          span {
            font-weight: bold;
          }
        }
      
        .token {
          margin-top: 15px;
          display: flex;
          align-items: center;
          cursor: pointer;
          color: var(--bluish-grey);

          span{
            margin-right: 10px;
          }

          svg:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
  }
`;

export default SCMailboxSecuritySection;
