import styled from '@emotion/styled/macro';

const SCApplicationTopMenu = styled.div`
  z-index: 1999999998;
  position: sticky;
  top: 0;
  width: 100%;
  background: var(--white);
  box-shadow: 2px 2px 20px #79889714;
  display: flex;
  flex-direction: column;

  .top-menu,
  .partner-navbar {
    padding: 8px 15px;
  }

  .top-menu {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-container {
      width: 202px;
      > svg {
        width: 125px;
        cursor: pointer;
      }
    }

    .menu-buttons {
      width: var(--section-width);
      display: flex;
      justify-content: space-evenly;
    }
  }

  .sub-navbar {
    display: none;
  }

  .partner-navbar {
    background-color: var(--red);
    > div {
      margin: auto;
      display: flex;
      align-items: center;
      gap: 15px;
      > p,
      a {
        font-family: var(--font2);
        font-size: 14px;
        color: var(--white);
        text-align: center;
      }

      .partner-client-description {
        text-align: left;
        flex: 1;
      }

      .partner-dashboard-link {
        display: flex;
        justify-content: flex-end;
        flex: 1;

        > a {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }

  @media (max-width: 1320px) {
    .top-menu .menu-buttons {
      display: none;
    }
    .sub-navbar {
      display: block;
    }
  }

  @media (width < 1000px) {
    .partner-client-description {
      display: none;
    }
  }
`;

export default SCApplicationTopMenu;
