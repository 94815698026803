import { useSelector } from 'react-redux';

const useDnsRecord = () => {
  const dnsRecord = useSelector((redux) => redux.dnsRecord);

  return {
    dnsRecord,
  };
};

export default useDnsRecord;
