import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from '../../redux/actions/auth.actions';

const SignOutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(signOut());
  }, []);

  return <></>;
};

export default SignOutPage;
