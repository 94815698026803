import axios from 'axios';

const runSecurityScan = ({ domain, atEmail, emails }) => {
  return axios.post('/security-scan/run', {
    domain,
    atEmail,
    emails,
  });
};

export default { runSecurityScan };
