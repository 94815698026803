import React, { useState } from 'react';

import {
  faDollyBox,
  faEnvelope,
  faFileInvoice,
  faGlobe,
  faMagnifyingGlassChart,
  faPaperPlaneTop,
  faPen,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { useTranslation } from '../../../i18n';
import SCSupplierNameCard from './SupplierNameCard.style';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import { getDateFormat } from '../../../utils/format/dataFormat';
import RadialChart from '../../molecules/RadialChart/RadialChart';
import Icon from '../../atoms/Icon/Icon';
import { toBase64 } from '../../../utils/functions/base64';
import { ErrorKey } from '../../../track';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';

const SupplierNameCard = ({ supplier }) => {
  const i18n = useTranslation();
  const { name, domain, atEmail, timestamp } = supplier || {};
  const dispatch = useDispatch();
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  const downloadReport = () => {
    setIsDownloadingReport(true);

    axios
      .get(
        `/get_report_pdf/${toBase64(domain)}?companyName=${
          name || ''
        }&atEmail=${toBase64(atEmail || '')}`,
        {
          responseType: 'blob',
          headers: { 'Accept-Language': i18n.getLanguage() },
        }
      )
      .then((response) => {
        if (window.navigator && window.navigator.msSaveBlob) {
          // IE11 and the legacy version Edge support
          const blob = new Blob([response.data], { type: 'application/pdf' });
          window.navigator.msSaveBlob(blob, `${domain}.pdf`);
        } else {
          // other browsers
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${domain}.pdf`); // or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {
        axios
          .get(
            `/get_report_pdf/${toBase64(domain)}?companyName=${name || ''}`,
            {
              responseType: 'blob',
              headers: { 'Accept-Language': i18n.getLanguage() },
            }
          )
          .then((response) => {
            if (window.navigator && window.navigator.msSaveBlob) {
              // IE11 and the legacy version Edge support
              const blob = new Blob([response.data], {
                type: 'application/pdf',
              });
              window.navigator.msSaveBlob(blob, `${domain}.pdf`);
            } else {
              // other browsers
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' })
              );
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${domain}.pdf`); // or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(() => ErrorKey('errors.thereWasAnError', 'getReportPDF'))
          .finally(() => {
            setIsDownloadingReport(false);
          });
      })
      .finally(() => {
        setIsDownloadingReport(false);
      });
  };

  return (
    <SCSupplierNameCard>
      <SectionCard
        headerIcon={faDollyBox}
        headerTitle={i18n.t(
          'suppliersSecurity.supplierDetailPage.supplierName'
        )}>
        {supplier && (
          <div className="ss-supplier-summary">
            <h2>{name}</h2>
            <div className="ss-supplier-summary-content">
              <RadialChart score={supplier.overall.score} />
              <div className="ss-supplier-summary-info">
                <div className="ss-supplier-summary-info-content">
                  <div>
                    <Icon
                      iconName={faGlobe}
                      color="var(--light-grey-6)"
                      size="navIcon"
                    />
                    <div>
                      <h3>
                        {i18n.t(
                          'suppliersSecurity.supplierDetailPage.websiteDomain'
                        )}
                      </h3>
                      <span>{domain}</span>
                    </div>
                  </div>
                </div>
                <div className="ss-supplier-summary-info-content">
                  <div>
                    <Icon
                      iconName={faMagnifyingGlassChart}
                      color="var(--light-grey-6)"
                      size="navIcon"
                    />
                    <div>
                      <h3>
                        {i18n.t(
                          'suppliersSecurity.supplierDetailPage.lastScan'
                        )}
                      </h3>
                      <span>{getDateFormat(timestamp.toDate())}</span>
                    </div>
                  </div>
                </div>
                <div className="ss-supplier-summary-info-content">
                  <div>
                    <Icon
                      iconName={faEnvelope}
                      color="var(--light-grey-6)"
                      size="navIcon"
                    />
                    <div>
                      <h3>
                        {i18n.t(
                          'suppliersSecurity.supplierDetailPage.emailDomain'
                        )}
                      </h3>
                      <span>{atEmail}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Button
                icon={!isDownloadingReport && faFileInvoice}
                onClick={() => downloadReport()}
                width="180px"
                text={
                  isDownloadingReport ? (
                    <SpinnerText text={i18n.t('misc.preparing')} />
                  ) : (
                    i18n.t('score.downloadReport')
                  )
                }
                size="mid"
                color="white"
                disabled={isDownloadingReport}
              />
              <Button
                icon={faPaperPlaneTop}
                width="180px"
                text={i18n.t('suppliersSecurity.notifySupplier.notify')}
                size="mid"
                color="white"
                onClick={() => {
                  dispatch(
                    showPopUp('SentEmailTo', {
                      title: i18n.t('suppliersSecurity.notifySupplier.notify'),
                      type: 'supplierSecurity',
                    })
                  );
                }}
              />
              <Button
                icon={faPen}
                onClick={() =>
                  dispatch(
                    showPopUp('editSupplier', {
                      domain: supplier.domain,
                      name: supplier.name,
                      atEmail: supplier.atEmail,
                    })
                  )
                }
                width="180px"
                text={i18n.t('suppliersSecurity.update.title')}
                size="mid"
                color="white"
              />
              <Button
                icon={faTrashCan}
                onClick={() =>
                  dispatch(showPopUp('removeSupplier', supplier.domain))
                }
                width="180px"
                text={i18n.t('suppliersSecurity.delete.title')}
                size="mid"
                color="white"
              />
            </div>
          </div>
        )}
      </SectionCard>
    </SCSupplierNameCard>
  );
};

export default SupplierNameCard;
