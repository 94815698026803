const TELEPHONE_DATA = {
  es: {
    phonePrefix: '+34',
    countryCode: 'ES',
    phoneLength: [9],
    phoneExample: '612345678',
    startWith: /^[6789]\d*/,
  },
  mx: {
    phonePrefix: '+52',
    countryCode: 'MX',
    phoneLength: [10],
    phoneExample: '6123456789',
    startWith: /^\d*/,
  },
  br: {
    phonePrefix: '+55',
    countryCode: 'BR',
    phoneLength: [10, 11],
    phoneExample: '61234567890',
    startWith: /^\d*/,
  },
};

export default TELEPHONE_DATA;
