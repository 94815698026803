import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Spinner from '../../../components/Misc/Spinner';
import { hasPaymentAccess } from '../../../utils/functions/subscriptions';

function ContractSubscriptionGuard({ children }) {
  const client = useSelector((redux) => redux.client);

  const [isBillingAddressValid, setIsBillingAddressValid] = useState();

  useEffect(() => {
    axios.get(`/clients/${client.id}/billing-address/valid`).then((resp) => {
      setIsBillingAddressValid(resp.data);
    });
  }, []);

  const canAccess = hasPaymentAccess() && isBillingAddressValid;

  if (isBillingAddressValid === undefined) {
    return <Spinner loading />;
  }

  if (!canAccess) {
    return <Navigate to="/client" />;
  }

  return children;
}

export default ContractSubscriptionGuard;
