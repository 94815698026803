import React from 'react';

const Spinner = ({ fetching, loading }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        zIndex: '1000000',
        minWidth: '100vw',
        minHeight: '100vh',
        display: `${fetching || loading ? 'flex' : 'none'}`,
        backgroundColor: 'var(--skull-white)',
      }}>
      <svg
        id="a"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        className="logo-loader">
        <g>
          <path
            d="M65.79,118.24c17.51,20.42,40.33,21.17,63.75,16.87,26.93-4.95,51.61-15.79,73.64-33.24,1.32,11.02,.13,21.29-4.28,31.09-10.46,23.23-29.78,32.71-53.96,34.49-25.85,1.91-47.79-6.39-65.5-25.39-6.33-6.81-10.98-14.69-13.65-23.82Z"
            style={{ fill: '#ba2027' }}
          />
          <path
            d="M62.25,123.25c35.83,59.19,83.95,56.61,137.23,29.84-3.21,17.62-16.58,40.45-42.82,46.16-34.04,7.41-75.28-16.25-86.53-50.09-2.81-8.47-5.21-17.08-7.89-25.91Z"
            style={{ fill: '#ba2027' }}
          />
          <path
            d="M54.69,20.68c15.38,24.83,42.24,28.78,65.94,37.95,22.38,8.65,35.05,34.18,25.52,53.53-9.84-30.28-38.44-31.36-62.19-39.89-14.24-5.12-27.26-11.47-30.88-28-1.68-7.69-.34-15.4,1.62-23.58Z"
            style={{ fill: '#ba2027' }}
          />
          <path
            d="M132.1,239.6c-31.14-27.53-60.25-56.26-69.43-99.12,17.33,50.36,55.2,67.19,105.46,64.68-11.06,12.62-23.09,23.59-36.04,34.43Z"
            style={{ fill: '#ba2027' }}
          />
          <path
            d="M72.31,71.32c3.57,1.41,7.13,2.84,10.85,4.33-1.58,11.81,2.15,20.87,14.11,24.81,11.55,3.8,17.93-4.04,24.36-11.63,9.36,4.61,16.6,11.15,19.91,21.95-13.77-11.45-28.62-2.13-42.88-2.84-16.76-.83-31.59-20.63-26.35-36.61Z"
            style={{ fill: '#ba2027' }}
          />
          <path
            d="M88.88,16.4c4.17,15.76,14.28,26.73,27.36,36.8-12.83-4.51-24.95-7.69-36.15-13.78,.62-8.46,4.5-15.62,8.79-23.02Z"
            style={{ fill: '#ba2027' }}
          />
          <path
            d="M106.47,82.87c2.83,1.09,5.62,2.16,8.41,3.25-.36,2.53-.98,4.9-3.9,5.38-1.93,.32-3.93,.18-5.18-1.7-1.57-2.36-.97-4.59,.67-6.93Z"
            style={{ fill: '#ba2027' }}
          />
        </g>
        <rect width="256" height="256" style={{ fill: 'none' }} />
      </svg>
      {/* <img src={logoLoader} className="logo-loader" /> */}
    </div>
  );
};

export default Spinner;
