import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons';
import MiniCard from '../../atoms/MiniCard/MiniCard';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import useConfigureEmailLinks from '../../../hooks/configureEmailLinks/useConfigureEmailLinks';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import useBreachNotificationType from '../../../hooks/breachNotificationType/useBreachNotificationType';

const PopUpWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;

const SendEmailForm = styled.div`
  width: 100%;
  height: 100%;
  max-width: 917px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 40px;

  h1 {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
  }

  .mailingModal_Cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .mailingModal_Card {
    p {
      font-size: 14px;
      line-height: 16px;
      color: var(--bluish-grey);
    }
  }

  .mailingModal_Actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .other-platforms-step-container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    > p {
      font-size: 14px;
    }

    > textarea {
      resize: none;
      width: 100%;
      max-height: 40vh;
      padding: 5px;
      background-color: var(--light-grey-4);
      border-radius: 5px;
      border-color: var(--light-grey-3);
      font-family: var(--font2);
      font-size: 14px;
      font-weight: var(--ap-weight);
      color: var(--black);
      line-height: var(--ap-lineheight);
    }
  }
`;

const SentEmailToModal = ({ modalTitle, emailType, domainName }) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const { links } = useConfigureEmailLinks(domainName);
  const emailProtectionTutorial = useSelector((redux) => redux.emailProtectionTutorial);
  const [step, setStep] = useState();
  // Solo para breaches
  const { activeBreach } = useBreachNotificationType();
  const user = useSelector((redux) => redux.user);
  // Solo para emailSecurity y mailboxSecurity
  const client = useSelector((redux) => redux.client);

  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    const textArea = document.getElementById('email-text-to-copy');

    if (textArea) {
      textArea.style.height = `${textArea.scrollHeight + 5}px`;
    }
  }, [step]);

  const getGmailLink = () => {
    switch (emailType) {
      case 'emailSecurity':
        return links.gmailEmailSecurityLink;
      case 'breaches':
        return links.gmailBreachesLink;
      case 'impersonation':
        return links.gmailImpersonationLink;
      case 'mailboxSecurity':
        return links.gmailMailboxSecurityLink;
      case 'supplierSecurity':
        return links.gmailProvidersLink;
      default:
        return links.gmailEmailSecurityLink;
    }
  };

  const getNativeLink = () => {
    switch (emailType) {
      case 'emailSecurity':
        return links.nativeEmailSecurityLink;
      case 'breaches':
        return links.nativeBreachesLink;
      case 'impersonation':
        return links.nativeImpersonationLink;
      case 'mailboxSecurity':
        return links.nativeMailboxSecurityLink;
      case 'supplierSecurity':
        return links.nativeProvidersLink;
      default:
        return links.nativeEmailSecurityLink;
    }
  };

  const getOtherEmailPlatformsStepData = () => {
    switch (emailType) {
      case 'emailSecurity':
        return {
          textToCopy: i18n.t('configureEmailSecurity.sendITEmailBodyToCopy', {
            domain: client.atEmail
          }),
        };
      case 'breaches':
        return {
          textToCopy: i18n.t(
            'breachNotification.notifyAffectedBox.emailBodyToCopy',
            {
              breachedServices: activeBreach,
              adminName: user.firstName,
            }
          ),
        };
      case 'impersonation':
        return {
          textToCopy: i18n.t(
            'domainImpersonation.requestManualReview.emailBodyToCopy',
            { domain: domainName }
          ),
        };
      case 'mailboxSecurity':
        return {
          textToCopy: i18n.t(
            'mailbox-security.configureEmailMxRecords.sendITEmailBodyToCopy',
            {
              domain: client.atEmail,
              domainVerificationToken: client.domainVerificationToken,
              url: emailProtectionTutorial?.[i18n.lang],
            }
          ),
        };
      case 'supplierSecurity':
        return {
          textToCopy: i18n.t('suppliersSecurity.notifySupplier.mailTextToCopy'),
        };
      default:
        return {};
    }
  };

  const handleCopy = () => {
    setCopied(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [isCopied]);

  return (
    <PopUpWrapper>
      <SendEmailForm>
        <h1>{modalTitle ?? i18n.t('sendEmailTo.sendITEmailTitle')}</h1>
        {step !== 'otherEmailPlatforms' ? (
          <div className="mailingModal_Cards">
            <div className="mailingModal_Card">
              <MiniCard
                onClick={() => {
                  window.open(getGmailLink(), '_blank').focus();
                }}>
                <Icon iconName={faGoogle} />
                <div>
                  <p>{i18n.t('sendEmailTo.use')}</p>
                  <p>Gmail</p>
                </div>
              </MiniCard>
            </div>
            <div className="mailingModal_Card">
              <MiniCard
                onClick={() => {
                  window.open(getNativeLink(), '_blank').focus();
                }}>
                <Icon iconName={faMicrosoft} />
                <div>
                  <p>{i18n.t('sendEmailTo.use')}</p>
                  <p>Outlook</p>
                </div>
              </MiniCard>
            </div>
            {getOtherEmailPlatformsStepData().textToCopy && (
              <div className="mailingModal_Card">
                <MiniCard
                  onClick={() => {
                    setStep('otherEmailPlatforms');
                  }}>
                  <div>
                    <p>{i18n.t('common.others')}</p>
                  </div>
                </MiniCard>
              </div>
            )}
          </div>
        ) : (
          <div className="other-platforms-step-container">
            <p>{i18n.t('sendEmailTo.copyMailText')}</p>
            <textarea readOnly id="email-text-to-copy">
              {getOtherEmailPlatformsStepData().textToCopy}
            </textarea>
          </div>
        )}
        <div className="mailingModal_Actions">
          {step === 'otherEmailPlatforms' && (
            <Button
              color="bluishGrey"
              size="large"
              width="100%"
              icon={isCopied ? faCheck : faCopy}
              text={
                isCopied ? i18n.t('common.copied') : i18n.t('common.copyText')
              }
              onClick={() => {
                handleCopy();
                navigator.clipboard.writeText(
                  getOtherEmailPlatformsStepData().textToCopy
                );
              }}
              disabled={isCopied}
            />
          )}
          <Button
            color="white"
            size="large"
            width="100%"
            text={i18n.t('common.cancel')}
            onClick={() => {
              dispatch(showPopUp(null));
            }}
          />
        </div>
      </SendEmailForm>
    </PopUpWrapper>
  );
};

export default SentEmailToModal;
