import styled from '@emotion/styled';

const SCTagFilterContainer = styled.div`
  flex-direction: column;
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  .TagFilterContainer_MainContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: ${({ hasCustomButtonElements }) =>
      hasCustomButtonElements ? '0px' : '30px'};
  }
`;

export default SCTagFilterContainer;
