import styled from '@emotion/styled';
import {
  faArrowTurnDownLeft,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useTag from '../../../hooks/tags/useTag';
import useTeamMembersTag from '../../../hooks/tags/useTeamMembersTag';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import { CHECKBOX_STATES } from '../../atoms/IndeterminateCheckbox/IndeterminateCheckbox';
import Input from '../../atoms/Input/Input';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import Tag from '../../atoms/Tag/Tag';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const SCTagPicker = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  > h1 {
    text-align: center;
  }

  .input-tags-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .tagpicker-tag-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 435px;
      overflow-y: scroll;

      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 69px;
        background: var(--bluish-grey-2);
      }
    }

    .TagPicker_TagsContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-right: 8px;
    }
  }

  .add-tag-container {
    margin-top: auto;
    align-self: flex-start;
    width: 100%;
  }

  .create-tag {
    background: transparent;
    padding: 5.5px 15px;
    display: flex;
    align-items: center;
    text-align: left;
    width: fit-content;

    :hover {
      background-color: var(--bluish-grey-4);
    }
    :focus {
      outline: none;
    }

    svg {
      margin-right: 7px;
    }

    span {
      font-family: var(--font2);
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const TagPicker = ({ teamMembersHashedEmails }) => {
  const { sortedAvailableTags, editTagsToTeamMembers } = useTeamMembersTag(
    teamMembersHashedEmails
  );
  const { createTag } = useTag();
  const i18n = useTranslation();
  const dispatch = useDispatch();

  const [availableTagsFiltered, setAvailableTagsFiltered] = useState([]);
  const [teamMembersTagModifications, setTeamMembersTagModifications] =
    useState({ toTag: [], toUntag: [] });
  const [searchValue, setSearchValue] = useState('');
  const [createdTags, setCreatedTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditingTags, setIsEditingTags] = useState(false);

  useEffect(() => {
    const availableTagFilteredAux = sortedAvailableTags
      ?.filter((sortedAvailableTag) =>
        sortedAvailableTag.name
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      )
      .map((sortedAvailableTag) => {
        if (createdTags.includes(sortedAvailableTag.name)) {
          onChangeTagCheckbox(sortedAvailableTag.id, CHECKBOX_STATES.checked);
          return {
            ...sortedAvailableTag,
            tagAppearance: CHECKBOX_STATES.checked,
          };
        }
        return sortedAvailableTag;
      });
    setAvailableTagsFiltered(availableTagFilteredAux ?? []);
  }, [searchValue, teamMembersHashedEmails, sortedAvailableTags]);

  const onChangeTagCheckbox = (tagId, newTagState) => {
    const { tagAppearance } = sortedAvailableTags.find(
      (tag) => tag.id === tagId
    );
    let toTag = [...teamMembersTagModifications.toTag];
    let toUntag = [...teamMembersTagModifications.toUntag];

    // Eliminamos su ID del array en el que esté.
    const indexToTag = toTag.indexOf(tagId);
    if (indexToTag !== -1) {
      toTag.splice(indexToTag, 1);
    }
    const indexToUntag = toUntag.indexOf(tagId);
    if (indexToUntag !== -1) {
      toUntag.splice(indexToUntag, 1);
    }

    if (tagAppearance !== newTagState) {
      if (newTagState === 'checked') {
        // Introducir en toTag y, si estaba en toUntag, eliminarlo.
        toTag = [...toTag.filter((id) => id !== tagId), tagId];
      } else {
        // Introducir en toUntag y, si estaba en toTag, eliminarlo.
        toUntag = [...toUntag.filter((id) => id !== tagId), tagId];
      }
    }

    setTeamMembersTagModifications({ toTag, toUntag });
  };

  const handleCreateTag = async () => {
    setLoading(true);
    try {
      await createTag(searchValue, '#000016', []);
      setCreatedTags([...createdTags, searchValue]);
    } catch (e) {
      console.error('TagPicker - handleCreateTag():', e);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleCreateTag();
    }
  };

  const handleEditTagsToTeamMembers = async () => {
    const MAX_LIMIT = 100;
    const deferred = teamMembersHashedEmails.length > MAX_LIMIT;

    try {
      setIsEditingTags(true);

      await editTagsToTeamMembers(teamMembersTagModifications, deferred);

      if (deferred) {
        dispatch(
          showPopUp('notification', {
            notificationType: 'success',
            title: i18n.t('common.processingRequest'),
            text: i18n.t('tagFiltering.processingRequestText'),
          })
        );
      } else {
        dispatch(
          showPopUp('notification', {
            notificationType: 'success',
            title: i18n.t('common.processedRequest'),
            text: i18n.t('tagFiltering.processedRequestText'),
          })
        );
      }
    } catch (e) {
      console.error('TagPicker - handleEditTagsToTeamMembers():', e);
    } finally {
      setIsEditingTags(false);
    }
  };

  const isSearchValueNotInsideAvailableTags = (availableTag) =>
    availableTag.name.toLowerCase() !== searchValue.toLowerCase();

  const getTagAppearance = (tag) => {
    if (teamMembersTagModifications.toTag.includes(tag.id)) {
      return CHECKBOX_STATES.checked;
    }
    if (teamMembersTagModifications.toUntag.includes(tag.id)) {
      return CHECKBOX_STATES.empty;
    }
    return tag.tagAppearance;
  };

  return (
    <ModalLayout>
      <SCTagPicker>
        <h1>{i18n.t('tagFiltering.title')}</h1>

        <div className="input-tags-container modal-card-content">
          <Input
            name="tagSelection"
            id="tagSelection"
            inputType="erasableText"
            size="large"
            design="round"
            icon={faMagnifyingGlass}
            inputPlaceholder={i18n.t('tagFiltering.searchTag')}
            defaultValue={searchValue}
            onChangeValue={(val) => setSearchValue(val)}
            onKeyPress={(e) => handleKeyPress(e)}
          />
          <div className="tagpicker-tag-wrapper">
            {searchValue.length > 0 &&
              availableTagsFiltered.every(
                isSearchValueNotInsideAvailableTags
              ) && (
                <button
                  type="button"
                  className="create-tag"
                  onClick={() => {
                    handleCreateTag();
                  }}>
                  {loading ? (
                    <SpinnerText text={i18n.t('misc.saving')} />
                  ) : (
                    <>
                      <Icon iconName={faArrowTurnDownLeft} />
                      <div>
                        <span>
                          <strong>{i18n.t('tagFiltering.createTag')}</strong>
                        </span>
                        <span style={{ color: 'var(--bluish-grey)' }}>
                          {`'${searchValue}'`}
                        </span>
                      </div>
                    </>
                  )}
                </button>
              )}

            <div className="TagPicker_TagsContainer">
              {availableTagsFiltered.map((availableTag) => (
                <Tag
                  key={availableTag.id}
                  tag={availableTag}
                  onChangeTagCheckbox={onChangeTagCheckbox}
                  tagAppearance={getTagAppearance(availableTag)}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="modal-card-buttons">
          <Button
            color="bluishGrey"
            size="full"
            text={
              !isEditingTags ? (
                i18n.t('common.save')
              ) : (
                <SpinnerText text={i18n.t('misc.saving')} />
              )
            }
            onClick={handleEditTagsToTeamMembers}
            disabled={
              (teamMembersTagModifications.toTag.length === 0 &&
                teamMembersTagModifications.toUntag.length === 0) ||
              isEditingTags
            }
          />
          <Button
            color="white"
            size="full"
            text={i18n.t('common.cancel')}
            onClick={() => dispatch(showPopUp(null))}
          />
        </div>
      </SCTagPicker>
    </ModalLayout>
  );
};

export default TagPicker;
