import React from 'react';
import {
  // faEnvelope,
  faPaperclipVertical,
  faTrashCan,
  faInbox,
} from '@fortawesome/pro-regular-svg-icons';
import { faRadiation } from '@fortawesome/pro-solid-svg-icons';

import { useNavigate } from 'react-router-dom';
import ThreatsNeutralized from '../../molecules/ThreatsNeutralized/ThreatsNeutralized';
import Button from '../../atoms/Button/Button';
import { COUNTER_TYPES } from '../../../hooks/useManagedThreatCounters/useManagedThreatCounters';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import EmptyCardContent from '../../molecules/EmptyCardContent/EmptyCardContent';
import usePaymentAccess from '../../../hooks/paymentAccess/usePaymentAccess';
import useSubscriptionType, {
  SUBSCRIPTIONS_PRODUCTS,
} from '../../../hooks/subscriptionType/useSubscriptionType';
import useLicenses from '../../../hooks/licenses/useLicenses';
import { hasPaymentAccess } from '../../../utils/functions/subscriptions';
import { hasEmailProtection } from '../../../utils/functions/emailProtection';
import useFetchQuarantineEmails from '../../../hooks/emailProtection/useFetchQuarantineEmails';

const ThreatsNeutralizedMail = ({ counters }) => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const { handlePaymentAccess } = usePaymentAccess();
  const { currentSubscription } = useSubscriptionType();
  const { totalLicenses } = useLicenses();
  const { tableParams } = useFetchQuarantineEmails();

  const { total } = tableParams?.pagination ?? {};
  const hasQuarantineEmails = Boolean(total);

  const quarantineEmailsAvailable = hasEmailProtection();

  const THREATS_NEUTRALIZED_MAIL = [
    // {
    //   iconName: faEnvelope,
    //   amount: counters[COUNTER_TYPES.EMAIL_PROTECTION_EMAILS_ANALYZED],
    //   description: i18n.t(
    //     'managed-threats.widgets.email-threats-neutralized.labels.emailsAnalyzed'
    //   ),
    // },
    {
      iconName: faPaperclipVertical,
      amount: counters[COUNTER_TYPES.EMAIL_PROTECTION_FILES_ANALYZED],
      description: i18n.t(
        'managed-threats.widgets.email-threats-neutralized.labels.attachedAnalyzed'
      ),
    },
    {
      iconName: faRadiation,
      amount: counters[COUNTER_TYPES.EMAIL_PROTECTION_EMAILS_IN_QUARANTINE],
      description: i18n.t(
        'managed-threats.widgets.email-threats-neutralized.labels.quarantineEmails'
      ),
      isHighlighted:
        !!counters[COUNTER_TYPES.EMAIL_PROTECTION_EMAILS_IN_QUARANTINE],
    },
    {
      iconName: faTrashCan,
      amount: counters[COUNTER_TYPES.EMAIL_PROTECTION_MALICIOUS_EMAILS_DELETED],
      description: i18n.t(
        'managed-threats.widgets.email-threats-neutralized.labels.removedEmails'
      ),
    },
  ];

  return (
    <SectionCard
      headerTitle={i18n.t(
        'managed-threats.widgets.email-threats-neutralized.title'
      )}
      headerIcon={faInbox}>
      {currentSubscription?.product === SUBSCRIPTIONS_PRODUCTS.TRIAL && (
        <EmptyCardContent
          text={i18n.t(
            'managed-threats.widgets.email-threats-neutralized.emptyCard.contractSubscriptionText'
          )}
          {...(hasPaymentAccess() && {
            buttonText: i18n.t(
              'managed-threats.widgets.email-threats-neutralized.emptyCard.contractSubscriptionButton'
            ),
            buttonOnClick: handlePaymentAccess,
          })}
        />
      )}

      {currentSubscription?.product === SUBSCRIPTIONS_PRODUCTS.PAYING &&
        totalLicenses > 0 &&
        !quarantineEmailsAvailable && (
          <EmptyCardContent
            text={i18n.t(
              'managed-threats.widgets.email-threats-neutralized.emptyCard.activeMailboxSecurityText'
            )}
            buttonText={i18n.t(
              'managed-threats.widgets.email-threats-neutralized.emptyCard.activeMailboxSecurityButton'
            )}
            buttonOnClick={() => navigate('/client/mailbox-security')}
          />
        )}

      {currentSubscription?.product === SUBSCRIPTIONS_PRODUCTS.PAYING &&
        totalLicenses > 0 &&
        quarantineEmailsAvailable && (
          <ThreatsNeutralized
            displaySubtitle={hasQuarantineEmails}
            subtitle={i18n.t(
              'managed-threats.widgets.email-threats-neutralized.subtitle'
            )}
            isSubtitleHighlighted={hasQuarantineEmails}
            threatsCounters={THREATS_NEUTRALIZED_MAIL}
            button={
              <Button
                text={i18n.t(
                  'managed-threats.widgets.email-threats-neutralized.actions.reviewQuarantineEmails'
                )}
                size="full"
                color="white"
                onClick={() => navigate('/client/mailbox-security')}
              />
            }
          />
        )}
    </SectionCard>
  );
};

export default ThreatsNeutralizedMail;
