import madridHolidays from './2023_madrid_holidays.json';

export function getLaboralDate(date) {
  const auxDate = new Date(date);
  const tomorrow = new Date(auxDate.setDate(auxDate.getDate() + 1));
  const strTomorrow = `${tomorrow.getFullYear()}-${`0${
    tomorrow.getMonth() + 1
  }`.slice(-2)}-${`0${tomorrow.getDate()}`.slice(-2)}`;
  const isFreeDay = madridHolidays.data.some(
    (obj) => obj.fecha_festivo === strTomorrow
  );
  return tomorrow.getDay() % 6 && !isFreeDay
    ? tomorrow
    : getLaboralDate(tomorrow);
}

export const nthLaboralDate = (date, days) => {
  if (!date) {
    return undefined;
  }

  const auxDate = getLaboralDate(date);
  return days > 1 ? nthLaboralDate(auxDate, days - 1) : auxDate;
};
