import axios from 'axios';
import { toBase64 } from '../../utils/functions/base64';

const addMonitoredDomain = (domain) => {
  return axios.post('/clients/monitored-domains', { domain });
};

const deleteMonitoredDomain = (domain) => {
  return axios.delete(`/clients/monitored-domains/${toBase64(domain)}`);
};

export default { addMonitoredDomain, deleteMonitoredDomain };
