import React from 'react';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons';
import SCThreatsNeutralized from './ThreatsNeutralized.style';
import ThreatCounter from '../ThreatCounter/ThreatCounter';
import Icon from '../../atoms/Icon/Icon';

const ThreatsNeutralized = ({
  subtitle,
  isSubtitleHighlighted,
  threatsCounters,
  button,
  displaySubtitle = true,
}) => {
  return (
    <SCThreatsNeutralized isSubtitleHighlighted={isSubtitleHighlighted}>
      {subtitle && displaySubtitle && (
        <div className="ManagedSection_Resume">
          {Boolean(isSubtitleHighlighted) && (
            <Icon
              className="ManagedSection_Resume_Icon"
              iconName={faExclamation}
              size="semiLarge"
              color="var(--red)"
            />
          )}

          <p>{subtitle}</p>
        </div>
      )}

      <div className="ManagedSection_Items">
        {threatsCounters.map(
          ({ iconName, amount, description, isHighlighted }) => (
            <div className="ManagedSection_Item">
              <ThreatCounter
                iconName={iconName}
                amount={amount}
                description={description}
                isHighlighted={isHighlighted}
              />
            </div>
          )
        )}
      </div>

      {button}
    </SCThreatsNeutralized>
  );
};

export default ThreatsNeutralized;
