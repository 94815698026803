import styled from '@emotion/styled';

const SCActionsEmptyMessage = styled.div`
  .actions-empty-message-container {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    .right-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .title,
      .subtitle {
        color: var(--bluish-grey);
      }

      .title {
        font-weight: 500;
      }

      .subtitle {
        font-style: italic;
      }
    }
  }
`;

export default SCActionsEmptyMessage;
