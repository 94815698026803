import React from 'react';
import { faWrench } from '@fortawesome/pro-regular-svg-icons';
import { faCircle1, faCircle2 } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import SCConfigureSpfSteps from './ConfigureSpfSteps.style';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import emailProviders from '../../../utils/emailProviders/emailProvidersTemplate';
import dnsProviders from '../../../utils/dnsProviders/dnsProvidersTemplate';
import ToggleButton from '../../atoms/ToggleButton/ToggleButton';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import { getMappedDnsRecords } from '../../../utils/functions/dnsRecords';
import useDnsRecord from '../../../hooks/dnsRecord/useDnsRecord';

const ConfigureSpfSteps = ({ spf, step, onChangeConfig }) => {
  const client = useSelector((redux) => redux.client);
  const { dnsRecord } = useDnsRecord();
  const i18n = useTranslation();

  const records = getMappedDnsRecords(dnsRecord);

  return (
    <SCConfigureSpfSteps>
      <SectionCard
        headerIcon={faWrench}
        headerTitle={i18n.t('configureEmailSecurity.SPF.title')}>
        <b className="ConfigureSpfStep_Header">
          {i18n.t(`configureEmailSecurity.SPF.step${step}`)}
        </b>
        {step === 1 && (
          <>
            <p className="question">
              <Icon iconName={faCircle1} size="medium" />

              {i18n.t('configureEmailSecurity.SPF.areYouResponsible')}
            </p>
            <div className="questionActions">
              <Button
                text={i18n.t('configureEmailSecurity.SPF.yes')}
                onClick={() => {
                  onChangeConfig({ ...spf, areYouResponsible: true });
                }}
                color={spf.areYouResponsible === true ? 'bluishGrey' : 'white'}
                size="small"
              />
              <Button
                text={i18n.t('configureEmailSecurity.SPF.no')}
                onClick={() => {
                  onChangeConfig({ ...spf, areYouResponsible: false });
                }}
                color={spf.areYouResponsible === false ? 'bluishGrey' : 'white'}
                size="small"
              />
            </div>

            <p className="question">
              <Icon iconName={faCircle2} />
              {i18n.t('configureEmailSecurity.SPF.areYouAbleToSetDNS')}
            </p>
            <div className="questionActions">
              <Button
                text={i18n.t('configureEmailSecurity.SPF.yes')}
                onClick={() => {
                  onChangeConfig({ ...spf, areYouAbleToSetDNS: true });
                }}
                color={spf.areYouAbleToSetDNS === true ? 'bluishGrey' : 'white'}
                size="small"
              />
              <Button
                text={i18n.t('configureEmailSecurity.SPF.no')}
                onClick={() => {
                  onChangeConfig({ ...spf, areYouAbleToSetDNS: false });
                }}
                color={
                  spf.areYouAbleToSetDNS === false ? 'bluishGrey' : 'white'
                }
                size="small"
              />
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className="spfStepContent">
              {records.provider && (
                <div>
                  <span>
                    {i18n.t('configureEmailSecurity.SPF.detectedProvider')}{' '}
                    <strong>{records.provider}</strong>
                  </span>
                </div>
              )}
              <div>
                <span>{i18n.t('configureEmailSecurity.SPF.useDefault')}</span>
                <br />
                <div>
                  <div className="emailProviderImagesContainer">
                    {emailProviders.map((provider) => (
                      <img
                        className="emailProviderImage"
                        onClick={() => onChangeConfig(provider.spf)}
                        src={provider.img}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="questionAsRow">
                <span>
                  {i18n.t('configureEmailSecurity.SPF.thirdPartyTools', {
                    emailDomain: client.atEmail || client.domain,
                  })}{' '}
                </span>
                <div>
                  <ToggleButton
                    checked={!!spf.thirdPartyTools}
                    text={
                      spf.thirdPartyTools
                        ? i18n.t('configureEmailSecurity.on')
                        : i18n.t('configureEmailSecurity.off')
                    }
                    onClick={() => {
                      onChangeConfig({
                        ...spf,
                        thirdPartyTools: !spf.thirdPartyTools,
                      });
                    }}
                  />
                </div>
              </div>
              {spf.thirdPartyTools && (
                <InfoBanner
                  type="warn"
                  text={[
                    i18n.t('configureEmailSecurity.SPF.customizationRequired'),
                  ]}
                />
              )}
            </div>
            <div>
              <h3>{i18n.t('configureEmailSecurity.SPF.customizeSPF')}</h3>
              <div className="questionAsRow">
                <span>
                  {i18n.t('configureEmailSecurity.SPF.allowSubdomains', {
                    domain: client.atEmail || client.domain,
                  })}
                </span>
                <div>
                  <ToggleButton
                    checked={!!spf.allowAnySubdomain}
                    text={
                      spf.allowAnySubdomain
                        ? i18n.t('configureEmailSecurity.on')
                        : i18n.t('configureEmailSecurity.off')
                    }
                    onClick={() => {
                      onChangeConfig({
                        ...spf,
                        allowAnySubdomain: !spf.allowAnySubdomain,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="questionAsRow">
                <span>{i18n.t('configureEmailSecurity.SPF.mxRecords')}</span>
                <div>
                  <ToggleButton
                    checked={!!spf.mxRecords}
                    text={
                      spf.mxRecords
                        ? i18n.t('configureEmailSecurity.on')
                        : i18n.t('configureEmailSecurity.off')
                    }
                    onClick={() => {
                      onChangeConfig({ ...spf, mxRecords: !spf.mxRecords });
                    }}>
                    {/* <Handler enabled={spf.mxRecords} />{' '} */}
                  </ToggleButton>
                </div>
              </div>
              <div className="questionAsRow">
                <span>{i18n.t('configureEmailSecurity.SPF.mainIP')}</span>
                <div>
                  <ToggleButton
                    checked={!!spf.domainMainIP}
                    text={
                      spf.domainMainIP
                        ? i18n.t('configureEmailSecurity.on')
                        : i18n.t('configureEmailSecurity.off')
                    }
                    onClick={() => {
                      onChangeConfig({
                        ...spf,
                        domainMainIP: !spf.domainMainIP,
                      });
                    }}
                  />
                </div>
              </div>
              <div>
                <span>
                  {i18n.t('configureEmailSecurity.SPF.addExtraIPAddresses', {
                    domain: client.atEmail || client.domain,
                  })}
                </span>
                <input
                  className="generatePassword"
                  type="text"
                  value={spf.additionalSenderIPs || ''}
                  onChange={(e) =>
                    onChangeConfig({
                      ...spf,
                      additionalSenderIPs: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <span>
                  {i18n.t('configureEmailSecurity.SPF.addThirdPartyDomains', {
                    domain: client.atEmail || client.domain,
                  })}
                </span>
                <input
                  className="generatePassword form-control"
                  type="text"
                  value={spf.thirtPartySender || ''}
                  onChange={(e) =>
                    onChangeConfig({ ...spf, thirtPartySender: e.target.value })
                  }
                />
              </div>
              <div>
                <span>{i18n.t('configureEmailSecurity.SPF.strictPolicy')}</span>
                <br />
                <select
                  className="form-control"
                  value={spf.policyStrict || 'fail'}
                  onChange={(e) =>
                    onChangeConfig({ ...spf, policyStrict: e.target.value })
                  }>
                  <option value="pass">Pass</option>
                  <option value="softFail">Soft fail</option>
                  <option value="fail">Fail</option>
                </select>
              </div>
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <div className="spfStepContent">
              <p>
                {i18n.t('configureEmailSecurity.dnsProviderDetected')}{' '}
                <strong>{records.dnsProvider}</strong>
              </p>

              {i18n.t('configureEmailSecurity.chooseHostingProvider', {
                mechanism: 'SPF',
              })}
              <div className="dnsProviders_Container">
                {dnsProviders.map((item) => (
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <img src={item.img} alt="DNS provider" />
                  </a>
                ))}
              </div>
            </div>
          </>
        )}
      </SectionCard>
    </SCConfigureSpfSteps>
  );
};

export default ConfigureSpfSteps;
