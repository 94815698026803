import React from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import SCSectionCardHeader from './SectionCardHeader.style';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';

const SectionCardHeader = ({
  iconName,
  title,
  subtitle,
  buttonIcon,
  buttonText,
  buttonOnClick,
  buttonDisabled,
  collapseOnClick,
  isHeaderSelected,
  isCollapsed,
}) => {
  return (
    <SCSectionCardHeader
      onClick={collapseOnClick || (!buttonText && buttonOnClick)}
      buttonOnClick={buttonOnClick}
      buttonText={buttonText}
      isHeaderSelected={isHeaderSelected}>
      <div className="icon-title-subtitle-container">
        <Icon iconName={iconName} color="var(--bluish-grey)" />
        <b> {title}</b> <p> {subtitle} </p>
        {buttonOnClick && !buttonText && (
          <div className="SectionCardHeader_arrow">
            <Icon iconName={faArrowRight} color="var(--bluish-grey)" />
          </div>
        )}
      </div>

      {collapseOnClick && (
        <div>
          {isCollapsed ? (
            <Icon size="semiLarge" iconName={faAngleDown} />
          ) : (
            <Icon size="semiLarge" iconName={faAngleUp} />
          )}
        </div>
      )}

      {buttonOnClick && buttonText && (
        <div>
          <Button
            onClick={buttonOnClick}
            icon={buttonIcon}
            text={buttonText}
            size="mid"
            color="icon"
            disabled={buttonDisabled}
          />
        </div>
      )}
    </SCSectionCardHeader>
  );
};

export default SectionCardHeader;
