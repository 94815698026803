import { faEye } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useTranslation } from '../../../i18n';
import Label from '../../atoms/Label/Label';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import SCSingleResultCampaignTableCard from './SingleResultCampaignTableCard.style';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import TagList from '../../molecules/TagList/TagList';
import Tooltip from '../../atoms/Tooltip/Tooltip';

const SingleResultCampaignTableCard = ({ campaign, trainingResults }) => {
  const i18n = useTranslation();
  const PAGE_SIZE = 10;

  const mapperFn = (member) => {
    const row = {
      name: {
        type: 'component',
        value: (
          <Tooltip
            tooltip={
              <p>
                {member?.first_name} {member?.last_name}
              </p>
            }>
            <p>
              {member?.first_name} {member?.last_name}
            </p>
          </Tooltip>
        ),
        sortValue: `${member?.first_name} ${member?.last_name}`,
      },
      emailsSent: {
        type: 'component',
        value: (
          <Label
            value={
              member.status === 'Email Sent' || member.status === 'Clicked Link'
                ? i18n.t('common.yes')
                : i18n.t('common.no')
            }
            color={
              !(
                member.status === 'Email Sent' ||
                member.status === 'Clicked Link'
              ) && 'red'
            }
            width="70px"
          />
        ),
        sortValue:
          member.status === 'Email Sent' || member.status === 'Clicked Link'
            ? i18n.t('common.yes')
            : i18n.t('common.no'),
      },
      clicks: {
        type: 'component',
        value: (
          <Label
            value={
              member.status === 'Clicked Link'
                ? i18n.t('common.yes')
                : i18n.t('common.no')
            }
            color={member.status === 'Clicked Link' && 'red'}
            width="70px"
          />
        ),
        sortValue:
          member.status === 'Clicked Link'
            ? i18n.t('common.yes')
            : i18n.t('common.no'),
      },
      trainingStarted: {
        type: 'component',
        value: (
          <Label
            value={
              trainingResults.opened.includes(member.email)
                ? i18n.t('common.yes')
                : i18n.t('common.no')
            }
            color={!trainingResults.opened.includes(member.email) && 'red'}
            width="70px"
          />
        ),
        sortValue: trainingResults.opened.includes(member.email)
          ? i18n.t('common.yes')
          : i18n.t('common.no'),
      },
      trainingCompleted: {
        type: 'component',
        value: (
          <Label
            value={
              trainingResults.completed.includes(member.email)
                ? i18n.t('common.yes')
                : i18n.t('common.no')
            }
            color={!trainingResults.completed.includes(member.email) && 'red'}
            width="70px"
          />
        ),
        sortValue: trainingResults.completed.includes(member.email)
          ? i18n.t('common.yes')
          : i18n.t('common.no'),
      },
      role: {
        type: 'component',
        value: <TagList teamMember={member} cell />,
      },
    };
    return row;
  };

  const defaultSortDirection = 'asc';
  const sortOptions = {
    activeSortedColumnName: i18n.t('phishingSimulator.teamMembers.name'),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 0,
    onSort: (data) => {
      setSort(data);
    },
  };

  const {
    currentPage,
    setSearch,
    setSort,
    setSearchTags,
    setPage,
    rows,
    length,
  } = useFilterTable(
    PAGE_SIZE,
    mapperFn,
    campaign?.results,
    ['first_name', 'last_name'],
    sortOptions
  );

  const columns = [
    {
      name: i18n.t('phishingSimulator.teamMembers.name'),
      options: {
        stickyOnScroll: true,
        width: 200,
        stickyOffset: 0,
        ellipsisContent: true,
        ofuscateFS: true,
      },
    },
    {
      name: i18n.t('phishingSimulator.teamMembers.receivedCampaign'),
    },
    {
      name: i18n.t('phishingSimulator.teamMembers.campaignOvercame'),
    },
    {
      name: i18n.t('phishingSimulator.teamMembers.trainingStarted'),
    },
    {
      name: i18n.t('phishingSimulator.teamMembers.trainingCompleted'),
    },
    {
      name: i18n.t(`manageEmailList.tags`),
      options: {
        avoidSort: true,
      },
    },
  ];

  return (
    <SCSingleResultCampaignTableCard>
      <SectionCard
        headerIcon={faEye}
        headerTitle={`${i18n.t(
          'phishingSimulator.resultCampaigns'
        )} (${length})`}>
        <TableContainer
          onChangeValue={setSearch}
          columns={columns}
          rows={rows}
          emptyMessage={i18n.t('phishingSimulator.emptyMessage')}
          onPageChange={(page) => setPage(page)}
          onChangeFilterTag={setSearchTags}
          hasHorizontalScroll
          totalCount={length}
          sortOptions={sortOptions}
          currentPage={currentPage}
          pageSize={PAGE_SIZE}
        />
      </SectionCard>
    </SCSingleResultCampaignTableCard>
  );
};

export default SingleResultCampaignTableCard;
