import styled from '@emotion/styled';

const SCLeadsAdminPage = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-family: var(--font1);
    font-size: 30px;
    color: rgb(17, 25, 39);
    font-weight: 600;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 50px;
`;

const FilterAndTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;

const DetailPanel = styled.div`
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 70%;
  height: 100%;
  position: sticky;
  top: 120px;

  .company-name {
    color: var(--bluish-grey);
    font-weight: 500;
    font-size: 24px;
  }
`;

const DetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin-bottom: 15px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  background-color: white;
  margin-top: 15px;
`;

const DetailContainer = styled.div``;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: white;
  gap: 10px;
`;

export {
  SCLeadsAdminPage,
  DetailPanel,
  Content,
  TableContainer,
  DetailHeader,
  ButtonContainer,
  DetailContainer,
  FilterContainer,
  FilterAndTableContainer,
};
