import styled from '@emotion/styled';

const SCSecurityOverview = styled.div`
  display: flex;

  .security-overview-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .apexcharts-tooltip-marker {
    display: none !important;
  }

  .boxesHolder {
    display: flex;
    flex-direction: row;

    .boxWrapper,
    .boxWrapperRadialChart {
      display: flex;
      flex-direction: column;
      position: relative;

      & h3 {
        font-size: 25px;
        color: #2e2e2e;
        font-weight: 800;
        margin-bottom: 26px;
      }
    }

    .boxWrapper {
      width: 100%;
    }

    .boxWrapperRadialChart {
      max-width: 290px;
      min-width: 250px;
    }

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`;
export default SCSecurityOverview;
