import axios from 'axios';
import { showPopUp } from './popUp.actions';
import { i18n } from '../../i18n';

export const sendPhishingCampaignResults =
  (clientId, campaign_id, campaign_name) => async (dispatch, getState) => {
    if (!campaign_id) return;

    const { client } = getState();
    const company_name = client.company.name;

    await axios
      .post(`/send_phishing_results/${clientId}/${campaign_id}`, {
        campaign_name,
        company_name,
      })
      .then((res) => {
        dispatch(
          showPopUp('notification', {
            notificationType: 'success',
            title: '',
            text: i18n.t('phishingSimulator.campaignSummary.trainingSent'),
          })
        );
      })
      .catch((error) => {
        dispatch(
          showPopUp('notification', {
            notificationType: 'error',
            title: '',
            text: i18n.t('common.errorTryLater'),
          })
        );
      });
  };

export default true;
