import axios from 'axios';
import { firestore } from '../../firebase';
import { setSupplierListener } from './monitoredSuppliers.actions';
import setManagedThreatCountersListener from './managedThreatCounters.actions';
import setTeamMembersListener from './monitoredTeamMembers.action';
import setCampaignListener from './campaigns.actions';
import setSubscriptionListener from './subscriptions.action';
import { GET_CLIENT } from './types';
import setPhishingTrainingsListener from './phishingTrainings.actions';
import { setDNSListener } from './dnsRecord';
import setProtectedDevicesListener from './protectedDevices.actions';
import setPaymentsListener from './payments.actions';
import { setImpersonationListener } from './monitoredImpersonations.actions';
import { setDomainListener } from './monitoredDomains.actions';
import setEmailProtectionTutorial from './emailProtectionTutorial.actions';
import { SESSION_STORAGE_KEYS } from '../../utils/constants/browserStorage';
import setPartnerConfig from './partnerConfig.actions';

const getClientId = async () => {
  const { search, pathname } = window.location;
  const urlParams = new URLSearchParams(search);
  const clientIdFromUrl = urlParams.get('id');
  const clientIdFromSessionStorage = sessionStorage.getItem(
    SESSION_STORAGE_KEYS.CLIENT_ID
  );

  if (clientIdFromUrl && pathname === '/client') {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.CLIENT_ID, clientIdFromUrl);
    return clientIdFromUrl;
  }

  if (clientIdFromSessionStorage && pathname.startsWith('/client')) {
    return clientIdFromSessionStorage;
  }

  sessionStorage.removeItem(SESSION_STORAGE_KEYS.CLIENT_ID);

  try {
    const res = await axios.get('/clients/all');
    return res.data;
  } catch (error) {
    return null;
  }
};

export const watchFirestore = (uuid) => async (dispatch, getState) => {
  if (!uuid) return;

  const clientId = await getClientId();

  if (!clientId) {
    dispatch({
      type: GET_CLIENT,
      payload: null,
    });
    return;
  }

  await firestore
    .collection('clients')
    .doc(clientId)
    .onSnapshot(
      (doc) => {
        if (!doc.exists) {
          dispatch({
            type: GET_CLIENT,
            payload: null,
          });
          return;
        }

        const client = { id: doc.id, ...doc.data() };

        dispatch({
          type: GET_CLIENT,
          payload: client || null,
        });

        if (client) {
          setDomainListener(client, dispatch);
          setImpersonationListener(client, dispatch, getState);
          setTeamMembersListener(client.id, dispatch, getState);
          setSubscriptionListener(client.id, dispatch);
          setCampaignListener(client.id, dispatch);
          setEmailProtectionTutorial(dispatch);
          setSupplierListener(client, dispatch);
          setPhishingTrainingsListener(client.id, dispatch);
          setDNSListener(client.atEmail, dispatch);
          setProtectedDevicesListener(client.id, dispatch);
          setPaymentsListener(client.id, dispatch);
          setManagedThreatCountersListener(client.id, dispatch);

          const licensee = client.licensee ?? client.partner;

          if (licensee) {
            setPartnerConfig(licensee, dispatch);
          }
        }
      },
      (error) => {
        dispatch({
          type: GET_CLIENT,
          payload: null,
        });
        console.log(error);
      }
    );
};

export default watchFirestore;
