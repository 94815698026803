import React, { useEffect } from 'react';
import SCModal from './Modal.style';

const Modal = ({
  closeModal,
  children,
  tawkToAvailable,
  hasCloseOnBackdropClick = true,
  width,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <SCModal
      width={width}
      tawkToAvailable={tawkToAvailable}
      onMouseDown={() => {
        if (hasCloseOnBackdropClick) closeModal();
      }}>
      <div
        className="modal-card"
        onMouseDown={(e) => {
          e.stopPropagation();
        }}>
        {children}
      </div>
    </SCModal>
  );
};
export default Modal;
