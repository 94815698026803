import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import SCImpersonationView from './impersonation.style';
import useImpersonations from '../../hooks/impersonations/useImpersonations';
import ImpersonationInfoCard from '../../components/organisms/ImpersonationInfoCard/ImpersonationInfoCard';
import BackButton from '../../components/molecules/BackButton/BackButton';

const ImpersonationView = () => {
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const domainName = urlParams.get('domain');
  const impersonationName = urlParams.get('website');

  const { impersonations } = useImpersonations(domainName);

  useEffect(() => {
    if (!impersonationName || !domainName) {
      navigate('/client');
    }
  }, []);

  const impersonation = impersonations.find(
    (item) => item.name === impersonationName
  );

  return (
    <SectionLayout>
      <SCImpersonationView>
        <div className="section-back-button">
          <BackButton />
        </div>
        {impersonation && domainName && (
          <>
            <div className="section-organism">
              <ImpersonationInfoCard
                domain={domainName} // TODO: revisar domain.Screenshot
                impersonation={impersonation}
              />
            </div>
          </>
        )}
      </SCImpersonationView>
    </SectionLayout>
  );
};

export default ImpersonationView;
