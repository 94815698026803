import useLicenses from '../../../hooks/licenses/useLicenses';
import useSubscriptionType from '../../../hooks/subscriptionType/useSubscriptionType';
import handleUnauthorizedAccess from '../../../utils/restrictedAccessBehaviour/restrictedAccessBehaviour';

function MailboxSecurityRouteGuard({ children }) {
  const { totalLicenses } = useLicenses();
  const { isTrialWithOneLicence } = useSubscriptionType();

  return totalLicenses > 0 && !isTrialWithOneLicence()
    ? children
    : handleUnauthorizedAccess();
}
export default MailboxSecurityRouteGuard;
