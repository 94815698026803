import { GET_MY_USER_PREFERENCES } from '../actions/types';

export default (state = null, action) => {
  switch (action.type) {
    case GET_MY_USER_PREFERENCES:
      return action.payload || null;
    default:
      return state;
  }
};
