import {
  faBuildingCircleCheck,
  faMapLocationDot,
  faPen,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCYourBillingInformationCardMexico from './YourBillingInformationCardMexico.style';
import { updateBillingAddress } from '../../Dashboard/clientHelper';
import useBillingAddressValidator from '../../../hooks/clientAddressValidator/useClientAddressValidator';
import MEXICAN_STATES from '../../../utils/internationalizationModules/states/mexicanStates';
import Select from '../../atoms/Select/Select';
import { showPopUp } from '../../../redux/actions/popUp.actions';

const YourBillingInformationCardMexico = ({ isShownInPopUp }) => {
  const i18n = useTranslation();
  const client = useSelector((redux) => redux.client);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isBillingAddressComplete } = useBillingAddressValidator();
  const [formEdit, setformEdit] = useState(!!isShownInPopUp);
  const [isSaving, setIsSaving] = useState();

  const billinAddressInitialState = {
    ...(client.billingAddress || {}),
    country: client.billingAddress?.country || 'Mexico',
  };

  const [billingAddress, setBillingAddress] = useState(
    billinAddressInitialState
  );

  useEffect(() => {
    setBillingAddress(billinAddressInitialState);
  }, []);

  const onClickSave = async () => {
    setIsSaving(true);

    await updateBillingAddress(client.id, billingAddress);

    setIsSaving(false);

    if (isShownInPopUp) {
      dispatch(showPopUp(null));
      navigate('/client/contract-subscription');
    }
  };

  const hasChange = () => {
    return !(
      billingAddress.line1 === client.billingAddress?.line1 &&
      billingAddress.city === client.billingAddress?.city &&
      billingAddress.country === client.billingAddress?.country &&
      billingAddress.postalCode === client.billingAddress?.postalCode &&
      billingAddress.corporateName === client.billingAddress?.corporateName &&
      billingAddress.state === client.billingAddress?.state
    );
  };

  return (
    <SCYourBillingInformationCardMexico>
      <SectionCard
        hasPadding={!isShownInPopUp}
        headerIcon={isShownInPopUp ? undefined : faUser}
        headerTitle={
          isShownInPopUp ? undefined : i18n.t('profile.yourBillingDetails')
        }>
        <form onSubmit={(e) => e.preventDefault()} style={{ margin: '0' }}>
          <div className="profile-billing-info-grid">
            <div className="profile-billing-info-grid-column">
              <Input
                className="fs-exclude"
                id="line1"
                name="line1"
                size="large"
                icon={faMapLocationDot}
                inputPlaceholder={
                  isShownInPopUp
                    ? i18n.t(
                        'profile.addBillingInformationPopUp.billingAddress'
                      )
                    : i18n.t('profile.billingLine1')
                }
                defaultValue={billingAddress.line1}
                onChangeValue={(val) =>
                  setBillingAddress({ ...billingAddress, line1: val.trim() })
                }
                disabled={!formEdit}
                inputHidden={!formEdit}
              />

              <Input
                className="fs-exclude"
                id="country"
                name="country"
                size="large"
                icon={faMapLocationDot}
                defaultValue={i18n.t(
                  `profile.country.${billingAddress.country}`
                )}
                disabled
                inputHidden
              />

              <Input
                className="fs-exclude"
                id="city"
                name="city"
                size="large"
                icon={faMapLocationDot}
                inputPlaceholder={i18n.t('profile.billingCity')}
                defaultValue={billingAddress.city}
                onChangeValue={(val) =>
                  setBillingAddress({
                    ...billingAddress,
                    city: val.trim(),
                  })
                }
                disabled={!formEdit}
                inputHidden={!formEdit}
              />
            </div>

            <div className="profile-billing-info-grid-column">
              <Input
                className="fs-exclude"
                id="corporateName"
                name="corporateName"
                size="large"
                icon={faBuildingCircleCheck}
                inputPlaceholder={i18n.t('profile.billingCorporateName')}
                defaultValue={billingAddress.corporateName}
                onChangeValue={(val) =>
                  setBillingAddress({
                    ...billingAddress,
                    corporateName: val.trim(),
                  })
                }
                disabled={!formEdit}
                inputHidden={!formEdit}
              />

              <Input
                className="fs-exclude"
                type="number"
                id="postalCode"
                name="postalCode"
                size="large"
                icon={faMapLocationDot}
                inputPlaceholder={i18n.t('profile.billingPostalCode')}
                defaultValue={billingAddress.postalCode}
                onChangeValue={(val) =>
                  setBillingAddress({
                    ...billingAddress,
                    postalCode: val.trim(),
                  })
                }
                disabled={!formEdit}
                inputHidden={!formEdit}
                maxLenght={5}
                min={0}
              />

              <Select
                className="fs-exclude"
                id="state"
                name="state"
                onChange={(val) =>
                  setBillingAddress({
                    ...billingAddress,
                    state: val.trim(),
                  })
                }
                icon={faMapLocationDot}
                values={MEXICAN_STATES.map((state) => state.name)}
                selectedValue={billingAddress.state}
                disabled={!formEdit}
                selectPlaceholder={i18n.t('profile.billingStateMexico')}
              />
            </div>
          </div>

          <div className="buttons-form-container">
            {formEdit ? (
              <>
                <Button
                  text={
                    !isSaving ? (
                      i18n.t('profile.save')
                    ) : (
                      <SpinnerText text={i18n.t('misc.saving')} />
                    )
                  }
                  size={!isShownInPopUp ? 'mid' : 'full'}
                  color="bluishGrey"
                  onClick={() => {
                    setformEdit(false);
                    onClickSave();
                  }}
                  disabled={
                    !hasChange() || !isBillingAddressComplete(billingAddress)
                  }
                />
                {!isShownInPopUp && (
                  <Button
                    text={i18n.t('common.cancel')}
                    size="mid"
                    color="white"
                    onClick={() => {
                      setBillingAddress(billinAddressInitialState);
                      setformEdit(false);
                    }}
                  />
                )}
              </>
            ) : (
              <Button
                text={
                  !isSaving ? (
                    i18n.t('profile.edit')
                  ) : (
                    <SpinnerText text={i18n.t('misc.saving')} />
                  )
                }
                icon={!isSaving && faPen}
                size={!isShownInPopUp ? 'mid' : 'full'}
                color="white"
                onClick={() => setformEdit(true)}
                disabled={isSaving}
              />
            )}
          </div>
        </form>
      </SectionCard>
    </SCYourBillingInformationCardMexico>
  );
};
export default YourBillingInformationCardMexico;
