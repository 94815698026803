import React from 'react';
import { Outlet } from 'react-router-dom';
import SCAdminLayout from './AdminLayout.style';
import Navbar from '../../molecules/Navbar/Navbar';
import ContactLegalInformation from '../../molecules/ContactLegalInformation/ContactLegalInformation';

const AdminLayout = () => {
  return (
    <SCAdminLayout>
      <Navbar hideAuthButtons showSignOutButton />

      <div className="content-container">
        <Outlet />
      </div>

      <ContactLegalInformation color="transparent" size="section" />
    </SCAdminLayout>
  );
};

export default AdminLayout;
