import { useEffect, useState } from 'react';
import axios from 'axios';

export default function useFetchApiAddress() {
  const [ipAddress, setIpAddress] = useState(undefined);
  const [locationAddress, setLocationAddress] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  // const IP_ADDRESS_URL = 'https://api.ipify.org?format=json';
  // const LOCATION_ADDRESS_URL = 'http://ip-api.com/json/';
  const CLOUDFLARE_URL = 'https://www.cloudflare.com/cdn-cgi/trace';

  useEffect(() => {
    setLoading(true);
    axios
      .get(CLOUDFLARE_URL)
      .then((resp) => {
        const [, ip] = resp.data.match(new RegExp('ip=' + '(.*)' + '\n'));
        const [, loc] = resp.data.match(new RegExp('loc=' + '(.*)' + '\n'));
        setIpAddress(ip);
        setLocationAddress(loc);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => setLoading(false));
  }, []);

  return { ipAddress, locationAddress, error, loading };
}
