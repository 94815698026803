import styled from '@emotion/styled';

// const backgroundColors = {
//   default: 'var(--light-grey)',
//   red: 'var(--red-gradient)',
//   bluishGrey: 'var(--bluish-grey-2)',
//   lightRed: 'var(--light-red-gradient-1)',
//   orange: 'var(--orange-gradient)',
//   green: 'var(--green-gradient)',
// };

const SCProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  place-content: center;
  align-items: center;

  .progress-bar-x-axis-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    place-content: space-between;
    align-items: center;

    div {
      width: fit-content;
    }
  }

  .progress-bar-container {
    display: flex;
    width: 100%;
  }

  .progress-bar-background {
    position: relative;

    display: flex;
    background: ${({ backgroundColor }) =>
      backgroundColor || 'var(--light-grey-4)'};
    border-radius: 50vh;
    height: ${({ height }) => height || '13px'};
    width: 100%;
    overflow: hidden;
  }

  .progress-bar-content,
  .progress-bar-content-2 {
    position: absolute;
    left: 0;
    top: 0;
    background: ${({ barColor }) => barColor || 'var(--green-gradient)'};
    border-radius: 50vh;
    height: ${({ height }) => height || '13px'};
    width: ${({ completed, total }) => `${(completed * 100) / total}%`};
    transition: width 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .progress-bar-content {
    z-index: 3;
  }

  .progress-bar-content-2 {
    background: var(--light-green-gradient);
    width: ${({ toCompleted, completed, total }) =>
      `${((toCompleted + completed) * 100) / total}%`};
  }

  .progress-bar-circle {
    position: absolute;
    right: 0;
    border-radius: 50vh;
    margin: 2px;
    height: calc(100% - 4px);
    background-color: white;
    aspect-ratio: 1/1;
  }
`;

export default SCProgressBar;
