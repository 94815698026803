import firebase from '../../firebase';
import { INITIATE_FIREBASE } from './types';

export const initiateFirebase = () => (dispatch) => {
  dispatch({
    type: INITIATE_FIREBASE,
    payload: firebase,
  });
};

export default initiateFirebase;
