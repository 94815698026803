import React from 'react';

const ScoreExampleEN = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="780"
    height="217.5"
    viewBox="0 0 780 217.5">
    <g
      id="Group_1122"
      data-name="Group 1122"
      transform="translate(11171 -19394)">
      <g
        id="Group_1119"
        data-name="Group 1119"
        transform="translate(-11741 18856.5)">
        <text
          id="_40_"
          data-name="40%"
          transform="translate(570 618)"
          fill="#798897"
          fontSize="30"
          fontFamily="Poppins-SemiBold, Poppins"
          fontWeight="600">
          <tspan x="0" y="0">
            40%
          </tspan>
        </text>
        <g
          id="Component_98_3"
          data-name="Component 98 – 3"
          transform="translate(570 639)">
          <text
            id="Internal_security:_Device_security_Mailbox_security_Managed_threats"
            data-name="Internal security:

Device security
Mailbox security
Managed threats"
            fill="#2e2e2e"
            fontSize="14"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600">
            <tspan x="0" y="15">
              Internal security:
            </tspan>
            <tspan fontFamily="Karla-Regular, Karla" fontWeight="400">
              <tspan x="0" y="34" />
              <tspan x="0" y="51">
                Device security
              </tspan>
              <tspan x="0" y="68">
                Mailbox security
              </tspan>
              <tspan x="0" y="85">
                Managed threats
              </tspan>
            </tspan>
          </text>
        </g>
        <line
          id="Line_33"
          data-name="Line 33"
          x2="184"
          transform="translate(649.5 607.5)"
          fill="none"
          stroke="#798897"
          strokeWidth="2"
        />
        <text
          id="_60_"
          data-name="60%"
          transform="translate(1285 618)"
          fill="#798897"
          fontSize="30"
          fontFamily="Poppins-SemiBold, Poppins"
          fontWeight="600">
          <tspan x="0" y="0">
            60%
          </tspan>
        </text>
        <line
          id="Line_34"
          data-name="Line 34"
          x2="184"
          transform="translate(1086.5 607)"
          fill="none"
          stroke="#798897"
          strokeWidth="2"
        />
        <g
          id="Component_99_3"
          data-name="Component 99 – 3"
          transform="translate(1086 639)">
          <text
            id="Internet_exposure:_Coporate_email_security_Data_leaks_Website_security_Possible_impersonations"
            data-name="Internet exposure:

Coporate email security
Data leaks
Website security
Possible impersonations"
            fill="#2e2e2e"
            fontSize="14"
            fontFamily="Poppins-SemiBold, Poppins"
            fontWeight="600">
            <tspan x="134.99" y="15">
              Internet exposure:
            </tspan>
            <tspan fontFamily="Karla-Regular, Karla" fontWeight="400">
              <tspan x="264" y="34" />
              <tspan x="107.662" y="51">
                Coporate email security
              </tspan>
              <tspan x="194.35" y="68">
                Data leaks
              </tspan>
              <tspan x="154.513" y="85">
                Website security
              </tspan>
              <tspan x="100.816" y="102">
                Possible impersonations
              </tspan>
            </tspan>
          </text>
        </g>
      </g>
      <g
        id="Group_1120"
        data-name="Group 1120"
        transform="translate(-11458.9 18761.1)">
        <path
          id="Subtraction_1"
          data-name="Subtraction 1"
          d="M72,144a71.662,71.662,0,0,1-40.256-12.3A72.216,72.216,0,0,1,5.658,100.025,71.9,71.9,0,0,1,12.3,31.744,72.212,72.212,0,0,1,43.974,5.658,71.9,71.9,0,0,1,112.256,12.3a72.218,72.218,0,0,1,26.086,31.678,71.9,71.9,0,0,1-6.639,68.282,72.208,72.208,0,0,1-31.677,26.086A71.549,71.549,0,0,1,72,144ZM71.28,10.08A61.92,61.92,0,1,0,133.2,72,61.99,61.99,0,0,0,71.28,10.08Z"
          transform="translate(605.9 632.9)"
          fill="#e7e7e7"
        />
        <path
          id="Subtraction_3"
          data-name="Subtraction 3"
          d="M72,144a71.666,71.666,0,0,1-40.257-12.3A72.228,72.228,0,0,1,5.658,100.026,71.9,71.9,0,0,1,12.3,31.744,72.206,72.206,0,0,1,43.975,5.658,71.555,71.555,0,0,1,72,0V10.084c-.225,0-.467,0-.72,0A61.92,61.92,0,1,0,133.2,72H144a71.662,71.662,0,0,1-12.3,40.256,72.208,72.208,0,0,1-31.677,26.087A71.56,71.56,0,0,1,72,144Z"
          transform="translate(605.9 632.9)"
          fill="#78bc12"
        />
        <text
          id="_7.5_High"
          data-name="7.5
High"
          transform="translate(676.9 708.9)"
          fill="#78bc12"
          fontSize="29"
          fontFamily="Poppins-Medium, Poppins"
          fontWeight="500">
          <tspan x="-20.88" y="0">
            7.5
          </tspan>
          <tspan fill="#798897" fontSize="12">
            <tspan x="-13.776" y="15">
              High
            </tspan>
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default ScoreExampleEN;
