import styled from '@emotion/styled/macro';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';

import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import YourBillingInformationCardTemplate from '../../../utils/internationalizationModules/templates/YourBillingInformationCardTemplate';
import Button from '../../atoms/Button/Button';

const SCAddBillingInformation = styled.div`
  text-align: center;
  display: flex;
  overflow: auto;
  flex-direction: column;

  h1 {
    text-align: center;
  }

  .AddBillingInformation_ContentContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const AddBillingInformation = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();

  const closePopUp = () => {
    dispatch(showPopUp(null));
  };

  return (
    <ModalLayout>
      <SCAddBillingInformation>
        <h1>{i18n.t('profile.addBillingInformationPopUp.title')}</h1>
        <div className="AddBillingInformation_ContentContainer">
          <YourBillingInformationCardTemplate isShownInPopUp />
          <Button
            color="white"
            text={i18n.t('profile.addBillingInformationPopUp.actions.cancel')}
            onClick={() => closePopUp()}
          />
        </div>
      </SCAddBillingInformation>
    </ModalLayout>
  );
};

export default AddBillingInformation;
