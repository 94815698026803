import { GET_EMAIL_PROTECTION_TUTORIAL_DATA } from '../actions/types';

export default (state = [], action) => {
  switch (action.type) {
    case GET_EMAIL_PROTECTION_TUTORIAL_DATA:
      return action.payload || [];
    default:
      return state;
  }
};
