import { useSelector } from 'react-redux';

const usePhishingTrainings = () => {
  const phishingTrainings = useSelector((redux) => redux.phishingTrainings);

  return {
    phishingTrainings,
  };
};

export default usePhishingTrainings;
