import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'antd';
import SCKPICard from './KPICard.style';

const KPICard = ({ value, title, icon, loading }) => {
  return (
    <SCKPICard>
      {!loading ? (
        <>
          <FontAwesomeIcon
            icon={icon}
            fontSize="33px"
            color="var(--partner-primary)"
            className="rounded-icon"
          />

          <div className="widget-texts">
            <h3>{value}</h3>
            <p>{title}</p>
          </div>
        </>
      ) : (
        <>
          <Skeleton.Avatar active size={53} />
          <div className="widget-texts">
            <Skeleton active paragraph={false} />
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          </div>
        </>
      )}
    </SCKPICard>
  );
};

export default KPICard;
