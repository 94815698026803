import React from 'react';
import Icon from '../Icon/Icon';
import SCSeparator from './Separator.style';

const Separator = ({ icon, text }) => {
  return (
    <SCSeparator>
      <hr />
      {(icon || text) && (
        <>
          <div className="icon-text-container">
            <Icon iconName={icon} color="var(--bluish-grey)" /> <b>{text}</b>
          </div>
          <hr />
        </>
      )}
    </SCSeparator>
  );
};

export default Separator;
