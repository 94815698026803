import React from 'react';
import {
  faCircleRadiation,
  faSearch,
  faCalendar,
} from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Input, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import styled from '@emotion/styled';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { getDateFormat } from '../../../utils/format/dataFormat';
import AntDTable from '../AntD/AntDTable';
import SCQuarantineEmails from './QuarantineEmails.style';
import InfoBanner from '../../molecules/InfoBanner/InfoBanner';
import { finishTutorial } from '../../../redux/actions/client.actions';
import { nthLaboralDate } from '../../../utils/laboralDate/laboralDate';
import useFetchQuarantineEmails from '../../../hooks/emailProtection/useFetchQuarantineEmails';
import DatePickerApp from '../../atoms/DatePicker/DatePicker';
import AntDButton from '../AntD/AntDButton';

const SCFilterDropdown = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .buttons-container {
    display: flex;
    justify-content: space-between;
  }
`;

const BreakWordCell = styled.div`
  word-wrap: break-word;
  word-break: break-word;
`;

const QuarantineEmails = () => {
  const client = useSelector((redux) => redux.client);
  const i18n = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { quarantineEmails, isLoading, tableParams, setTableParams } =
    useFetchQuarantineEmails({ enableSearchParams: true });

  const secondDayEmailProtected = nthLaboralDate(
    client.protection?.emailProtectedAt?.toDate(),
    2
  );

  const { total } = tableParams?.pagination ?? {};

  /**
   * InfoBanner warn (naranja): si emails en cuarentena está vacío o no han pasado dos días laborables desde inicio protección.
   * InfoBanner check (verde): si emails en cuarentena es mayor que 0 o han pasado dos días laborables desde inicio protección.
   */
  const isQuarantineActivated =
    total > 0 || new Date().getTime() >= secondDayEmailProtected;

  const updateFilter = (field, value) => {
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      filters:
        value?.length > 0
          ? {
              [field]: value,
            }
          : null,
      pagination: {
        ...prevTableParams.pagination,
        current: 1,
      },
    }));
  };

  const renderTextCell = (text) => (
    <Tooltip title={text}>
      <BreakWordCell>
        {text.length > 60 ? `${text.substring(0, 57)}...` : text}
      </BreakWordCell>
    </Tooltip>
  );

  const getColumnSearchProps = (dataIndex, placeholder) => {
    const filterValue = tableParams.filters?.[dataIndex];

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, close }) => {
        const searchValue =
          typeof selectedKeys === 'string' ? selectedKeys : filterValue;

        return (
          <SCFilterDropdown onKeyDown={(e) => e.stopPropagation()}>
            <Input
              placeholder={placeholder}
              value={searchValue}
              onChange={(e) => setSelectedKeys(e.target.value)}
              onPressEnter={() => {
                updateFilter(dataIndex, searchValue);
                close();
              }}
            />
            <div className="buttons-container">
              <AntDButton
                type="text"
                size="small"
                onClick={() => {
                  setSelectedKeys('');
                  updateFilter(dataIndex, null);
                  close();
                }}
                disabled={!filterValue}>
                {i18n.t('common.restart')}
              </AntDButton>
              <AntDButton
                type="primary"
                onClick={() => {
                  updateFilter(dataIndex, searchValue);
                  close();
                }}
                size="small">
                {i18n.t('common.search')}
              </AntDButton>
            </div>
          </SCFilterDropdown>
        );
      },
      filterIcon: () => (
        <FontAwesomeIcon
          icon={faSearch}
          style={{
            color: filterValue ? 'var(--red)' : undefined,
          }}
        />
      ),
    };
  };

  const getFormattedDates = (dates) => {
    if (!dates || dates?.length !== 2) {
      return null;
    }

    return [dates[0].startOf('day').toDate(), dates[1].endOf('day').toDate()];
  };

  const getCalendarSearchProps = (dataIndex) => {
    const filterValue = tableParams.filters?.[dataIndex];

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, close }) => {
        const searchValue =
          selectedKeys?.length === 0 ? filterValue : selectedKeys;
        return (
          <SCFilterDropdown>
            <DatePickerApp
              isRangePicker
              onChange={(dates) => {
                const formattedDates = getFormattedDates(dates);
                setSelectedKeys(formattedDates);
              }}
              defaultValue={searchValue}
              disabledDatesBefore={moment(
                new Date().setDate(new Date().getDate() - 16)
              )}
              disabledDatesAfter={moment(new Date())}
            />
            <div className="buttons-container">
              <AntDButton
                type="text"
                size="small"
                onClick={() => {
                  setSelectedKeys(null);
                  updateFilter(dataIndex, null);
                  close();
                }}
                disabled={!filterValue}>
                {i18n.t('common.restart')}
              </AntDButton>
              <AntDButton
                type="primary"
                onClick={() => {
                  updateFilter(dataIndex, searchValue);
                  close();
                }}
                size="small">
                {i18n.t('common.search')}
              </AntDButton>
            </div>
          </SCFilterDropdown>
        );
      },
      filterIcon: () => {
        return (
          <FontAwesomeIcon
            icon={faCalendar}
            style={{
              color: filterValue ? 'var(--red)' : undefined,
            }}
          />
        );
      },
    };
  };

  const columns = [
    {
      title: i18n.t('mailbox-security.mail.table.headers.subject'),
      dataIndex: 'subject',
      width: '30%',
      ...getColumnSearchProps(
        'subject',
        i18n.t('mailbox-security.mail.table.searchPlaceholders.subject')
      ),
      render: (text) => renderTextCell(text),
    },
    {
      title: i18n.t('mailbox-security.mail.table.headers.from'),
      dataIndex: 'from',
      width: '30%',
      ...getColumnSearchProps(
        'from',
        i18n.t('mailbox-security.mail.table.searchPlaceholders.from')
      ),
      render: (text) => renderTextCell(text),
    },
    {
      title: i18n.t('mailbox-security.mail.table.headers.recipient'),
      dataIndex: 'rcpt',
      width: '30%',
      ...getColumnSearchProps(
        'rcpt',
        i18n.t('mailbox-security.mail.table.searchPlaceholders.recipient')
      ),
      render: (text) => renderTextCell(text),
    },
    {
      title: i18n.t('mailbox-security.mail.table.headers.reception'),
      dataIndex: 'date',
      render: (date) =>
        date
          ? getDateFormat(new Date(date))
          : i18n.t('websiteSecurity.unknown'),
      sorter: true,
      defaultSortOrder: tableParams.sorter.order,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: '10%',
      ...getCalendarSearchProps('date'),
    },
  ];

  const handleTableChange = (pagination, _, sorter) => {
    setTableParams((prevState) => ({
      ...prevState,
      pagination,
      sorter,
    }));
  };

  const totalQuarantineEmailsText =
    typeof total === 'number' ? `(${total})` : '';

  return (
    <SCQuarantineEmails>
      {quarantineEmails !== undefined && !isQuarantineActivated && (
        <div className="section-organism">
          <InfoBanner
            type="warn"
            text={[i18n.t('mailbox-security.quarantineEmailsEmpty')]}
          />
        </div>
      )}

      {quarantineEmails !== undefined &&
        isQuarantineActivated &&
        !client?.finishedTutorials?.mailboxSecurityActivatedQuarantineInfo && (
          <div className="section-organism">
            <InfoBanner
              type="check"
              text={[i18n.t('mailbox-security.quarantineEmailsNotEmpty')]}
              buttonText={i18n.t('common.understood')}
              onClickButton={() =>
                dispatch(
                  finishTutorial('mailboxSecurityActivatedQuarantineInfo')
                )
              }
            />
          </div>
        )}

      <SectionCard
        headerIcon={faCircleRadiation}
        hasOverflow
        headerTitle={`${i18n.t(
          'mailbox-security.mail.table.title'
        )} ${totalQuarantineEmailsText}`}>
        <AntDTable
          columns={columns}
          dataSource={quarantineEmails}
          pagination={{
            ...tableParams.pagination,
            position: ['bottomCenter'],
            showSizeChanger: true,
          }}
          onRow={({ id }) => ({
            onClick: () => navigate(`/client/mailbox-security/${id}`),
          })}
          // selectedRowId={selectedLeadId}
          loading={isLoading}
          onChange={handleTableChange}
        />
      </SectionCard>
    </SCQuarantineEmails>
  );
};

export default QuarantineEmails;
