import { css } from '@emotion/core';
import styled from '@emotion/styled';

const black = () => css`
  color: var(--white);
  background: url('data:image/svg+xml;utf8,<svg style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(236deg) brightness(101%) contrast(107%)" xmlns="http://www.w3.org/2000/svg" width="22.5" height="22.5" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"/></svg>')
    no-repeat;
`;

const transparent = () => css`
  color: var(--bluish-grey);
  background: url('data:image/svg+xml;utf8,<svg style="filter: invert(54%) sepia(8%) saturate(763%) hue-rotate(169deg) brightness(96%) contrast(89%)" xmlns="http://www.w3.org/2000/svg" width="22.5" height="22.5" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"/></svg>')
    no-repeat;
`;

const colors = {
  black,
  transparent,
};

const SCLanguageSelector = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 5px;

  .language-icon-container {
    padding-right: 10px;
  }

  img {
    width: 14px;
    height: 14px;
    border-radius: 50vh;
  }

  .language-selector {
    ${({ color }) => colors[color] || black};

    padding-left: 3px;
    padding-right: 25px;
    border: none;
    border-radius: 0;
    font-family: var(--font1);
    font-weight: 400;
    font-size: 14px;
    min-width: 100%;
    background-size: 18px;
    background-position-x: calc(100% - 5px);
    background-position-y: 8px;

    option {
      color: var(--black);
    }
  }
`;

export default SCLanguageSelector;
