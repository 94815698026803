import useSubscriptionType from '../../hooks/subscriptionType/useSubscriptionType';
import { PARTNER_TYPES } from '../constants/partners';
import { PAYMENT_PERIODICITY } from '../constants/payments';
import { isNonCountryEnvironment } from '../locale';
import { addYearsToDate, getDiffYears } from './date';
import { isPartnerType } from './partners';

// En desuso
export const getRenewalDate = ({
  subscriptionPeriodicity,
  subscriptionExpirationDate,
  subscriptionStartedAt,
}) => {
  if (subscriptionPeriodicity === PAYMENT_PERIODICITY.NONE) {
    return undefined;
  }

  if (subscriptionPeriodicity === PAYMENT_PERIODICITY.YEARLY) {
    return subscriptionExpirationDate;
  }

  /**
   * Próxima renovación = (Fecha próxima factura - fecha inicio suscripción) + 1 año
   */
  const subscriptionDurationYears = getDiffYears(
    subscriptionExpirationDate,
    subscriptionStartedAt
  );

  return addYearsToDate(subscriptionStartedAt, subscriptionDurationYears + 1);
};

export const hasPaymentAccess = () => {
  const { hasPayingSubscription } = useSubscriptionType();

  return (
    !hasPayingSubscription() &&
    !isNonCountryEnvironment &&
    !isPartnerType(PARTNER_TYPES.MSSP)
  );
};
