import styled from '@emotion/styled';

const SCCallMeWidget = styled.div`
  z-index: 2000000000;
  position: fixed;
  top: auto;
  right: 24px;
  bottom: 20px;
  left: auto;

  display: flex;
  flex-direction: column;
  gap: 10px;

  visibility: hidden;

  .text-button-container,
  .tawk-to-text-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;

    > button,
    .tawk-to-space {
      width: 60px;
      height: 60px;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: var(--red);
      border-radius: 50vh;

      cursor: pointer;

      :focus {
        outline: none;
      }

      .call-me-widget-icon-animation {
        animation-duration: 0.25s;
        animation-name: iconAnimation;
      }

      @keyframes iconAnimation {
        0% {
          opacity: 0;
          transform: scale(0.5);
        }

        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    > span {
      font-family: var(--font2);
      color: var(--red);
      font-weight: 600;
    }
  }

  @media (max-width: 576px) {
    .text-button-container,
    .tawk-to-text-button-container {
      > span {
        display: none;
      }
    }
  }
`;

export default SCCallMeWidget;
