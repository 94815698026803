import styled from '@emotion/styled';

const SCProfileNotifications = styled.div`
  .notification-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;

    height: ${({ rowHeight }) => `${rowHeight}px`};

    p {
      font-weight: 500;
    }

    .icon-date-container {
      display: flex;
      align-items: center;
      gap: 5px;
      white-space: nowrap;
      font-weight: 500;
      color: var(--bluish-grey-2);
    }
  }
`;

export default SCProfileNotifications;
