import React, { useEffect, useState } from 'react';
import { faCircle1 } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import SCNewPhisingCampaignType from './NewPhisingCampaignTypeCard.style';
import MiniCard from '../../atoms/MiniCard/MiniCard';
import { TrackButton } from '../../../track';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import useCampaign from '../../../hooks/useCampaign/useCampaign';

const NewPhisingCampaignTypeCard = ({ onChange }) => {
  const [activeCampaignType, setActiveCampaignType] = useState(undefined);
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const { PHISHING_CAMPAINGS } = useCampaign();

  useEffect(() => {
    if (activeCampaignType) {
      onChange(activeCampaignType);
    }
  }, [activeCampaignType]);

  return (
    <SCNewPhisingCampaignType>
      <SectionCard
        headerIcon={faCircle1}
        headerTitle={i18n.t(
          'phishingSimulator.campaigns.create.selectTypeOfPhising'
        )}>
        <div className="NewPhisingCampaignType_TypesContainer">
          {Object.values(PHISHING_CAMPAINGS).map((campaign) => (
            <MiniCard
              key={campaign.type}
              className={`${
                activeCampaignType === campaign.type
                  ? 'NewPhisingCampaignType_TypeSelected'
                  : ''
              }`}
              onClick={() => {
                TrackButton(
                  `phishingSimulator.campaigns.create.${1}.campaign.type`,
                  campaign.type
                );
                setActiveCampaignType(campaign.type);
              }}>
              {campaign.img}
              <div>
                {i18n.t(
                  `phishingSimulator.campaigns.campaingTypes.${campaign.name}`
                )}
              </div>
            </MiniCard>
          ))}
        </div>
        {activeCampaignType && (
          <div className="NewPhisingCampaignType_ActionsContainer">
            <Button
              text={i18n.t(
                'phishingSimulator.campaigns.create.checkEmailReception'
              )}
              onClick={() => {
                dispatch(
                  showPopUp('checkPhisingReceptionEmail', activeCampaignType)
                );
              }}
              color="white"
              size="mid"
            />
          </div>
        )}
      </SectionCard>
    </SCNewPhisingCampaignType>
  );
};

export default NewPhisingCampaignTypeCard;
