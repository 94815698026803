/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';

import { faMobile } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from '../../../i18n';
import { ErrorKey } from '../../../track';
import Modal from '../Modal/Modal';
import Icon from '../../atoms/Icon/Icon';
import Input from '../../atoms/Input/Input';
import Button from '../../atoms/Button/Button';
import SCTwoFactorAuthenticationSetup from './TwoFactorAuthenticationSetup.style';

const TwoFactorAuthenticationSetup = ({
  phoneNumber,
  recaptchaReady,
  clickSendCodeButton,
  isFetching,
  shouldVerifyCode,
  verifyCode,
  clickToClose,
  firebase,
}) => {
  const i18n = useTranslation();

  const [verifyingCode, setVerifyingCode] = useState();
  const [reLogged, setReLogged] = useState(false);
  const [passwordCode, setPasswordCode] = useState();
  const [loginError, setLoginError] = useState();

  const clickVerifyCode = (code) => {
    if (code && code.length === 6) {
      verifyCode(verifyingCode);
    } else {
      ErrorKey('authentication.tfa.emptyCode');
    }
  };

  const login = (pass) => {
    setLoginError(null);
    firebase
      .auth()
      .signInWithEmailAndPassword(firebase.auth().currentUser.email, pass)
      .then(() => {
        // Auto redirect
        setReLogged(true);
      })
      .catch((e) => {
        console.log('Login error ', e);
        setPasswordCode(null);
        setLoginError(e.code);
      });
  };

  const verifyInput = React.createRef();
  const modalRef = React.createRef();

  useEffect(() => {
    if (verifyInput && verifyInput.current) {
      verifyInput.current.focus();
    }
  }, [shouldVerifyCode]);

  if (!phoneNumber) return null;

  return (
    <Modal
      hasCloseOnBackdropClick
      closeModal={() => {
        clickToClose();
      }}>
      <SCTwoFactorAuthenticationSetup ref={modalRef} id="wrapperFor2fa">
        <h1>{i18n.t('authentication.tfa.title')}</h1>

        <div className="phone-container">
          <Icon
            iconName={faMobile}
            size="extraLarge"
            color="var(--bluish-grey)"
          />
        </div>

        {!reLogged && (
          <>
            <div>
              <Input
                inputType="password"
                id="password"
                name="password"
                size="large"
                inputPlaceholder={i18n.t('authentication.tfa.reLogged')}
                defaultValue={passwordCode || ''}
                onChangeValue={(val) => setPasswordCode(val)}
                center
              />

              {loginError && (
                <p
                  style={{
                    color: 'var(--red)',
                    fontSize: '16px',
                    marginTop: '10px',
                    marginBottom: '0px',
                  }}>
                  {i18n.t(`authentication.tfa.${loginError}`)}
                </p>
              )}
            </div>

            <div className="next-button">
              <Button
                text={i18n.t('authentication.tfa.next')}
                onClick={() => login(passwordCode)}
                disabled={isFetching || !passwordCode}
                size="full"
                color="bluishGrey"
              />
            </div>
          </>
        )}

        {!shouldVerifyCode && reLogged && (
          <>
            <p
              style={{
                fontSize: '14px',
                lineHeight: 'normal',
                marginBottom: '0px',
              }}>
              {i18n.t('authentication.tfa.sendSMS')} <b>{phoneNumber}</b>
            </p>

            <div className="next-button">
              <Button
                text={i18n.t('authentication.tfa.continue')}
                onClick={clickSendCodeButton}
                disabled={!recaptchaReady || isFetching}
                size="full"
                color="bluishGrey"
              />
            </div>
          </>
        )}

        {shouldVerifyCode && reLogged && (
          <>
            <Input
              inputType="text"
              id="verificationCode"
              name="password"
              size="large"
              inputPlaceholder={i18n.t('authentication.tfa.insertCode')}
              onChangeValue={(val) => setVerifyingCode(val)}
              disabled={isFetching}
              maxLength={6}
              ref={verifyInput}
              center
            />

            <div className="next-button">
              <Button
                text={i18n.t('authentication.tfa.verifyCode')}
                onClick={() => clickVerifyCode(verifyingCode)}
                disabled={
                  isFetching || !verifyingCode || verifyingCode.length !== 6
                }
                size="full"
                color="bluishGrey"
              />
            </div>
          </>
        )}

        {clickToClose && (
          <div className="cancel-button">
            <Button
              text={i18n.t('websiteSecurity.add.cancel')}
              onClick={clickToClose}
              size="full"
              color="white"
            />
          </div>
        )}
      </SCTwoFactorAuthenticationSetup>
    </Modal>
  );
};

export default TwoFactorAuthenticationSetup;
