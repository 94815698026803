import styled from '@emotion/styled';

const SCNavMenu = styled.div`
  display: none;

  @media (min-width: 769px) {
    display: flex;
    gap: 40px;

    .button-container {
      display: flex;
      gap: 20px;

      .button-login {
        color: var(--red);
      }

      .button-register {
        margin-right: 40px;
      }
    }
  }
`;

export default SCNavMenu;
