const baseDonutChartOptions = {
  labels: [],
  fill: {
    type: 'solid',
    colors: [],
  },
  legend: {
    markers: {
      fillColors: [],
    },
    fontWeight: '500',
    fontSize: '16px',
    fontFamily: 'var(--font2)',
    labels: {
      colors: ['var(--black)'],
    },
    itemMargin: {
      horizontal: 0,
      vertical: 5,
    },
  },
  tooltip: {
    enabled: false,
  },
  dataLabels: {
    enabled: true,
    formatter: (value, obj) => obj.w.config.series[obj.seriesIndex],
    style: {
      fontWeight: '400',
      fontSize: '14px',
      fontFamily: 'var(--font2)',
      colors: [],
    },
    dropShadow: {
      enabled: false,
    },
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      startAngle: -90,
      endAngle: 270,
      donut: {
        size: '45%',
      },
    },
  },
  stroke: {
    width: 0,
  },
  responsive: [
    {
      breakpoint: 500,
      options: {
        chart: {
          width: '100%',
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
};

export default baseDonutChartOptions;
