import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CHECKBOX_STATES } from '../../components/atoms/IndeterminateCheckbox/IndeterminateCheckbox';
import * as tagService from '../../services/tags/tag.service';

const useTeamMembersTag = (teamMembersHashedEmails) => {
  const { tags } = useSelector((redux) => redux.client);
  const teamMembers = useSelector((redux) => redux.teamMembers);
  const { id: clientId } = useSelector((redux) => redux.client);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sortedAvailableTags, setSortedAvailableTags] = useState([]);

  const getTagAppearance = (tag) => {
    let auxTagAppearance = CHECKBOX_STATES.empty;

    if (teamMembersHashedEmails?.length > 0) {
      const teamMembersFiltered = teamMembers.filter((teamMember) =>
        teamMembersHashedEmails.includes(teamMember.hashedEmail)
      );

      const appearsInAll = teamMembersFiltered.every((teamMember) => {
        return teamMember?.tags?.includes(tag.id);
      });
      const appearsInSome = teamMembersFiltered.some((teamMember) => {
        return teamMember?.tags?.includes(tag.id);
      });
      if (appearsInSome && !appearsInAll) {
        auxTagAppearance = CHECKBOX_STATES.indeterminate;
      } else if (appearsInAll) {
        auxTagAppearance = CHECKBOX_STATES.checked;
      }
    }

    return auxTagAppearance;
  };

  const getSortedAvailableTags = () => {
    const tagsAux = tags
      ?.map((tag) => {
        return {
          ...tag,
          tagAppearance: getTagAppearance(tag),
        };
      })
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .sort((a, b) => {
        if (a.tagAppearance === CHECKBOX_STATES.checked) {
          return -1;
        }
        if (
          a.tagAppearance === CHECKBOX_STATES.indeterminate &&
          b.tagAppearance === CHECKBOX_STATES.empty
        ) {
          return -1;
        }

        // a must be equal to b
        return 0;
      });

    return tagsAux;
  };

  useEffect(() => {
    setSortedAvailableTags(getSortedAvailableTags());
  }, [tags, teamMembers]);

  const editTagsToTeamMembers = (teamMembersTagModifications, deferred) => {
    return tagService.editTagsToTeamMembers(
      clientId,
      teamMembersHashedEmails,
      teamMembersTagModifications,
      deferred
    );
  };

  return {
    loading,
    error,

    sortedAvailableTags,
    editTagsToTeamMembers,
  };
};

export default useTeamMembersTag;
