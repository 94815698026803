import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import partnerClientsService from '../../services/partnerClients/partnerClients.service';

const usePartnerClients = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const [partnerClients, setPartnerClients] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPartnerClients();
  }, []);

  const fetchPartnerClients = () => {
    partnerClientsService
      .getPartnerClients()
      .then((partnerClientsResp) => {
        setPartnerClients(partnerClientsResp.data?.data);
      })
      .catch(() => {
        dispatch(
          showPopUp('notification', {
            notificationType: 'error',
            title: i18n.t('common.error'),
            text: i18n.t('errors.not_found'),
            closeButtonText: i18n.t('common.close'),
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    partnerClients,
    fetchPartnerClients,
    loading,
  };
};

export default usePartnerClients;
