const ApplicationSections = {
  internalSecurity: [
    'device-security',
    // 'device-vulnerabilities',
    'managed-threats',
    'mailbox-security',
  ],
  internetExposure: [
    'configure-email-security',
    'data-alert',
    'websitesecurity',
    'domainimpersonation',
    'supplier-security',
  ],
};

export default ApplicationSections;
