import styled from '@emotion/styled';
import React from 'react';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const SCMaxUsersInEmailListPopUp = styled.div`
  .icon-container {
    border-radius: 50vh;
    border: 5px solid ${({ color }) => color || 'inherit'};
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    div {
      font-family: var(--font1);
      color: var(--black);
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const MaxUsersInEmailListPopUp = ({ maxUser }) => {
  const dispatch = useDispatch();
  const i18n = useTranslation();

  return (
    <ModalLayout>
      <SCMaxUsersInEmailListPopUp>
        <h1>{i18n.t('welcome.restrictions.maxUsersInEmailList_title')}</h1>

        <div className="icon-container">
          <Icon iconName={faLock} size="large" color="var(--bluish-grey)" />
        </div>
        <div className="modal-card-content">
          <div className="text-container">
            <div>
              {maxUser
                ? i18n.t('welcome.restrictions.maxUsersInEmailList_first')
                : i18n.t('welcome.restrictions.maxAdminsInEmailList_first')}
            </div>
            <div>
              {i18n.t('welcome.restrictions.maxUsersInEmailList_second')}
            </div>
          </div>
        </div>
        <div className="modal-card-buttons">
          <Button
            size="full"
            color="bluishGrey"
            text={i18n.t('welcome.goBack')}
            onClick={() => {
              dispatch(showPopUp(null));
            }}
          />
          <Button
            size="full"
            color="white"
            text={i18n.t('contactUs.contactSupport')}
            onClick={() => {
              Tawk_API.toggle();
              dispatch(showPopUp(null));
            }}
          />
        </div>
      </SCMaxUsersInEmailListPopUp>
    </ModalLayout>
  );
};

export default MaxUsersInEmailListPopUp;
