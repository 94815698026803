const getInstallerFAQLink = (language) => {
  const DEFAULT_LANGUAGE = 'es';

  const INSTALLER_URLS = {
    generic: {
      es: 'https://cyberguardian.tawk.help/article/como-funciona-el-instalador-de-cyber-guardian-y-que-sistemas-operativos-soporta',
      en: 'https://cyberguardian.tawk.help/en-us/article/how-does-the-cyber-guardian-installer-work-and-what-operating-systems-does-it-support',
      'pt-BR':
        'https://cyberguardian.tawk.help/pt-br/article/como-funciona-o-instalador-do-cyber-guardian-e-que-sistemas-operativos-suporta',
    },
    android: {
      es: 'https://cyberguardian.tawk.help/article/como-instalo-las-protecciones-de-cyber-guardian-en-mi-dispositivo-android',
      en: 'https://cyberguardian.tawk.help/en-us/article/how-do-i-install-cyber-guardian-protections-on-my-android-device',
      'pt-BR':
        'https://cyberguardian.tawk.help/pt-br/article/como-instalo-protecoes-cyber-guardian-no-meu-dispositivo-android',
    },
    ios: {
      es: 'https://cyberguardian.tawk.help/article/como-instalo-las-protecciones-de-cyber-guardian-en-mi-dispositivo-ios',
      en: 'https://cyberguardian.tawk.help/en-us/article/how-do-i-install-cyber-guardian-protections-on-my-ios-device',
      'pt-BR':
        'https://cyberguardian.tawk.help/pt-br/article/como-instalo-as-protecaooes-cyber-guardian-no-meu-dispositivo-ios',
    },
  };

  return {
    genericInstallerFAQLink:
      INSTALLER_URLS.generic[language] ??
      INSTALLER_URLS.generic[DEFAULT_LANGUAGE],
    androidInstallerFAQLink:
      INSTALLER_URLS.android[language] ??
      INSTALLER_URLS.android[DEFAULT_LANGUAGE],
    iosInstallerFAQLink:
      INSTALLER_URLS.ios[language] ?? INSTALLER_URLS.ios[DEFAULT_LANGUAGE],
  };
};

export default getInstallerFAQLink;
