import React from 'react';
import SCQuantityInput from './QuantityInput.style';

const QuantityInput = ({
  value,
  onReduce,
  onAdd,
  onInputChange,
  disabled = false,
}) => {
  return (
    <SCQuantityInput
      disabled={!value}
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <button type="button" onClick={onReduce} disabled={!value}>
        -
      </button>

      <input
        type="number"
        onChange={onInputChange}
        disabled={!onInputChange}
        value={value.toString()}
      />

      <button type="button" onClick={onAdd} disabled={disabled}>
        +
      </button>
    </SCQuantityInput>
  );
};

export default QuantityInput;
