import styled from '@emotion/styled';

const SCConfigureSpfSteps = styled.div`
  select,
  input {
    width: 175px;
  }

  p {
    margin: 0;
    font-size: 16px;
  }

  .ConfigureSpfStep_Header {
    font-size: 20px;
  }

  .spfStepContent {
    margin-top: 40px;
  }
  .question {
    display: flex;
    margin-top: 40px;
    font-weight: 700;
    svg {
      margin-right: 8px;
    }
  }

  .questionActions {
    margin-top: 20px;

    Button {
      margin-right: 15px;
    }
  }

  .emailProviderImagesContainer {
    margin: 50px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 100px;

    .emailProviderImage {
      cursor: pointer;
      max-width: 200px;
    }
  }

  .questionAsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    span {
      margin-right: 6%;
    }
  }

  .dnsProviders_Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 30px;
    width: 50%;
  }

  .dnsProviders_Container a {
    width: 48%;
    margin: 1%;
  }

  .dnsProviders_Container img {
    width: 150px;
    height: auto;
    margin: 10px 0px;
  }
`;

export default SCConfigureSpfSteps;
