import React from 'react';
import SCTagsBox from './TagsBox.style';

const TagsBox = ({ children, isOpenBox }) => {
  return (
    <SCTagsBox isOpenBox={isOpenBox}>
      <div className="tags-box-children-container">{children}</div>
    </SCTagsBox>
  );
};

export default TagsBox;
