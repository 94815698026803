import React from 'react';
import { faCirclePlus, faDolly } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Skeleton } from 'antd';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { useTranslation } from '../../../i18n';
import {
  getSecurityColor,
  getSecurityText,
} from '../../../utils/functions/securitySeverity';
import SCSupplierSecurityCard from './SupplierSecurityCard.style';
import EmptyCardContent from '../../molecules/EmptyCardContent/EmptyCardContent';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import LabelDetailGroup from '../LabelDetailGroup/LabelDetailGroup';
import LastUpdateText from '../../atoms/LastUpdateText/LastUpdateText';
import { parseDate } from '../../../utils/format/dataFormat';

const SupplierSecurityCard = ({
  data = {},
  isHeaderClickable,
  showEmptyCardButton,
  loading = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const i18n = useTranslation();

  const { suppliersScore, updatedAt } = data;

  const suppliers = suppliersScore
    ? Object.keys(suppliersScore).map((supplier) => ({
        name: supplier,
        score: suppliersScore[supplier],
      }))
    : [];

  const sortedSuppliers = suppliers.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const LABEL_DETAILS = sortedSuppliers.map(({ name, score }) => ({
    name,
    value: i18n.t(`controlPanel.supplierSecurity.${getSecurityText(score)}`),
    color: getSecurityColor(score),
  }));

  const handleHeaderButtonOnClick = isHeaderClickable
    ? () => {
        navigate('/client/supplier-security');
      }
    : undefined;

  return (
    <SCSupplierSecurityCard>
      <SectionCard
        headerIcon={faDolly}
        headerTitle={i18n.t('controlPanel.supplierSecurity.title')}
        headerButtonOnClick={handleHeaderButtonOnClick}>
        {!loading ? (
          <div className="label-details-date-container">
            {sortedSuppliers.length > 0 ? (
              <LabelDetailGroup labelDetails={LABEL_DETAILS} />
            ) : (
              <EmptyCardContent
                text={i18n.t('controlPanel.supplierSecurity.stillNoSuppliers')}
                {...(showEmptyCardButton && {
                  buttonIcon: faCirclePlus,
                  buttonText: i18n.t(
                    'controlPanel.supplierSecurity.addYourFirstSupplier'
                  ),
                  buttonOnClick: () => {
                    navigate('/client/supplier-security');
                    dispatch(showPopUp('addSupplier'));
                  },
                })}
              />
            )}

            {updatedAt && <LastUpdateText date={parseDate(updatedAt)} />}
          </div>
        ) : (
          <Skeleton active paragraph={{ rows: 5 }} />
        )}
      </SectionCard>
    </SCSupplierSecurityCard>
  );
};

export default SupplierSecurityCard;
