import styled from '@emotion/styled';

const SCSectionCardHeader = styled.div`
  padding: ${({ buttonOnClick }) =>
    buttonOnClick ? '10px 15px 10px 30px' : '15px 15px 15px 30px'};
  min-height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  background-color: ${({ isHeaderSelected }) =>
    isHeaderSelected ? 'var(--light-grey)' : 'var(--light-grey-4)'};

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

  .SectionCardHeader_arrow {
    transition: 0.2s;
    margin-left: 10px;

    svg {
      width: 10px;
    }
  }

  & div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .icon-title-subtitle-container > div {
    margin-right: 14px;
  }

  p,
  b {
    margin: 0;
    text-align: left;
    font-family: var(--font1);
    font-size: 16px;
    letter-spacing: 0px;
    color: var(--bluish-grey);
    opacity: 1;
  }

  b {
    margin-right: 2px;
    font-weight: 500;
  }

  :hover {
    background-color: ${({ buttonOnClick, buttonText }) =>
      buttonOnClick && !buttonText ? 'var(--light-grey)' : 'none'};

    .SectionCardHeader_arrow {
      margin-left: 14px;
      transition: 0.2s;
    }
  }

  @media (max-width: 576px) {
    padding: ${({ buttonOnClick }) =>
      buttonOnClick ? '10px 5px 10px 20px' : '15px 5px 15px 20px'};
  }
`;

export default SCSectionCardHeader;
