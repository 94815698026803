import styled from '@emotion/styled';

const SCTagLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7.5px;
  width: fit-content;
  height: ${({ height }) => height || '25px'};
  background: ${({ tagStyle }) => tagStyle.backgroundColor};
  border: 1px solid ${({ tagStyle }) => tagStyle.borderColor};

  border-radius: 50vh;
  padding: 5px 10px;

  > p {
    font-family: var(--font2);
    font-size: ${({ fontSize }) => fontSize || '14px'};
    font-weight: 500;
    color: ${({ tagStyle }) => tagStyle.color};
  }

  > button {
    background-color: transparent;
    color: var(--light-grey-4);

    > div > svg {
      width: 13px;
      height: 13px;
    }
  }
`;

export default SCTagLabel;
