import whitelistingGSuite1En from '../../img/whitelisting/whitelisting-gsuite-1_en.png';
import whitelistingGSuite2En from '../../img/whitelisting/whitelisting-gsuite-2_en.png';
import whitelistingGSuite3En from '../../img/whitelisting/whitelisting-gsuite-3_en.png';
import whitelistingGSuite4En from '../../img/whitelisting/whitelisting-gsuite-4_en.png';
import whitelistingGSuite5En from '../../img/whitelisting/whitelisting-gsuite-5_en.png';
import whitelistingGSuite6En from '../../img/whitelisting/whitelisting-gsuite-6_en.png';
import whitelistingGSuite7En from '../../img/whitelisting/whitelisting-gsuite-7_en.png';
import whitelistingGSuite8En from '../../img/whitelisting/whitelisting-gsuite-8_en.png';
import whitelistingGSuite9En from '../../img/whitelisting/whitelisting-gsuite-9_en.png';
import whitelistingGSuite10En from '../../img/whitelisting/whitelisting-gsuite-10_en.png';
import whitelistingExchange1En from '../../img/whitelisting/whitelisting-exchange-1_en.png';
import whitelistingExchange2En from '../../img/whitelisting/whitelisting-exchange-2_en.png';
import whitelistingExchange3En from '../../img/whitelisting/whitelisting-exchange-3_en.png';
import whitelistingExchange4En from '../../img/whitelisting/whitelisting-exchange-4_en.png';
import whitelistingExchange5En from '../../img/whitelisting/whitelisting-exchange-5_en.png';
import whitelistingExchange6En from '../../img/whitelisting/whitelisting-exchange-6_en.png';
import whitelistingExchange7En from '../../img/whitelisting/whitelisting-exchange-7_en.png';
import whitelistingExchange8En from '../../img/whitelisting/whitelisting-exchange-8_en.png';

import whitelistingGSuite1De from '../../img/whitelisting/whitelisting-gsuite-1_de.png';
import whitelistingGSuite2De from '../../img/whitelisting/whitelisting-gsuite-2_de.png';
import whitelistingGSuite3De from '../../img/whitelisting/whitelisting-gsuite-3_de.png';
import whitelistingGSuite4De from '../../img/whitelisting/whitelisting-gsuite-4_de.png';
import whitelistingGSuite5De from '../../img/whitelisting/whitelisting-gsuite-5_de.png';
import whitelistingGSuite6De from '../../img/whitelisting/whitelisting-gsuite-6_de.png';
import whitelistingGSuite7De from '../../img/whitelisting/whitelisting-gsuite-7_de.png';
import whitelistingGSuite8De from '../../img/whitelisting/whitelisting-gsuite-8_de.png';
import whitelistingGSuite9De from '../../img/whitelisting/whitelisting-gsuite-9_de.png';
import whitelistingGSuite10De from '../../img/whitelisting/whitelisting-gsuite-10_de.png';
import whitelistingExchange1De from '../../img/whitelisting/whitelisting-exchange-1_de.png';
import whitelistingExchange2De from '../../img/whitelisting/whitelisting-exchange-2_de.png';
import whitelistingExchange3De from '../../img/whitelisting/whitelisting-exchange-3_de.png';
import whitelistingExchange4De from '../../img/whitelisting/whitelisting-exchange-4_de.png';
import whitelistingExchange5De from '../../img/whitelisting/whitelisting-exchange-5_de.png';
import whitelistingExchange6De from '../../img/whitelisting/whitelisting-exchange-6_de.png';
import whitelistingExchange7De from '../../img/whitelisting/whitelisting-exchange-7_de.png';
import whitelistingExchange8De from '../../img/whitelisting/whitelisting-exchange-8_de.png';

import whitelistingGSuite1Es from '../../img/whitelisting/whitelisting-gsuite-1_es.png';
import whitelistingGSuite2Es from '../../img/whitelisting/whitelisting-gsuite-2_es.png';
import whitelistingGSuite3Es from '../../img/whitelisting/whitelisting-gsuite-3_es.png';
import whitelistingGSuite4Es from '../../img/whitelisting/whitelisting-gsuite-4_es.png';
import whitelistingGSuite5Es from '../../img/whitelisting/whitelisting-gsuite-5_es.png';
import whitelistingGSuite6Es from '../../img/whitelisting/whitelisting-gsuite-6_es.png';
import whitelistingGSuite7Es from '../../img/whitelisting/whitelisting-gsuite-7_es.png';
import whitelistingGSuite8Es from '../../img/whitelisting/whitelisting-gsuite-8_es.png';
import whitelistingGSuite9Es from '../../img/whitelisting/whitelisting-gsuite-9_es.png';
import whitelistingGSuite10Es from '../../img/whitelisting/whitelisting-gsuite-10_es.png';
import whitelistingExchange1Es from '../../img/whitelisting/whitelisting-exchange-1_es.png';
import whitelistingExchange2Es from '../../img/whitelisting/whitelisting-exchange-2_es.png';
import whitelistingExchange3Es from '../../img/whitelisting/whitelisting-exchange-3_es.png';
import whitelistingExchange4Es from '../../img/whitelisting/whitelisting-exchange-4_es.png';
import whitelistingExchange5Es from '../../img/whitelisting/whitelisting-exchange-5_es.png';
import whitelistingExchange6Es from '../../img/whitelisting/whitelisting-exchange-6_es.png';
import whitelistingExchange7Es from '../../img/whitelisting/whitelisting-exchange-7_es.png';
import whitelistingExchange8Es from '../../img/whitelisting/whitelisting-exchange-8_es.png';

const images = {
  en: {
    whitelistingGSuite1: whitelistingGSuite1En,
    whitelistingGSuite2: whitelistingGSuite2En,
    whitelistingGSuite3: whitelistingGSuite3En,
    whitelistingGSuite4: whitelistingGSuite4En,
    whitelistingGSuite5: whitelistingGSuite5En,
    whitelistingGSuite6: whitelistingGSuite6En,
    whitelistingGSuite7: whitelistingGSuite7En,
    whitelistingGSuite8: whitelistingGSuite8En,
    whitelistingGSuite9: whitelistingGSuite9En,
    whitelistingGSuite10: whitelistingGSuite10En,
    whitelistingExchange1: whitelistingExchange1En,
    whitelistingExchange2: whitelistingExchange2En,
    whitelistingExchange3: whitelistingExchange3En,
    whitelistingExchange4: whitelistingExchange4En,
    whitelistingExchange5: whitelistingExchange5En,
    whitelistingExchange6: whitelistingExchange6En,
    whitelistingExchange7: whitelistingExchange7En,
    whitelistingExchange8: whitelistingExchange8En,
  },
  de: {
    whitelistingGSuite1: whitelistingGSuite1De,
    whitelistingGSuite2: whitelistingGSuite2De,
    whitelistingGSuite3: whitelistingGSuite3De,
    whitelistingGSuite4: whitelistingGSuite4De,
    whitelistingGSuite5: whitelistingGSuite5De,
    whitelistingGSuite6: whitelistingGSuite6De,
    whitelistingGSuite7: whitelistingGSuite7De,
    whitelistingGSuite8: whitelistingGSuite8De,
    whitelistingGSuite9: whitelistingGSuite9De,
    whitelistingGSuite10: whitelistingGSuite10De,
    whitelistingExchange1: whitelistingExchange1De,
    whitelistingExchange2: whitelistingExchange2De,
    whitelistingExchange3: whitelistingExchange3De,
    whitelistingExchange4: whitelistingExchange4De,
    whitelistingExchange5: whitelistingExchange5De,
    whitelistingExchange6: whitelistingExchange6De,
    whitelistingExchange7: whitelistingExchange7De,
    whitelistingExchange8: whitelistingExchange8De,
  },
  es: {
    whitelistingGSuite1: whitelistingGSuite1Es,
    whitelistingGSuite2: whitelistingGSuite2Es,
    whitelistingGSuite3: whitelistingGSuite3Es,
    whitelistingGSuite4: whitelistingGSuite4Es,
    whitelistingGSuite5: whitelistingGSuite5Es,
    whitelistingGSuite6: whitelistingGSuite6Es,
    whitelistingGSuite7: whitelistingGSuite7Es,
    whitelistingGSuite8: whitelistingGSuite8Es,
    whitelistingGSuite9: whitelistingGSuite9Es,
    whitelistingGSuite10: whitelistingGSuite10Es,
    whitelistingExchange1: whitelistingExchange1Es,
    whitelistingExchange2: whitelistingExchange2Es,
    whitelistingExchange3: whitelistingExchange3Es,
    whitelistingExchange4: whitelistingExchange4Es,
    whitelistingExchange5: whitelistingExchange5Es,
    whitelistingExchange6: whitelistingExchange6Es,
    whitelistingExchange7: whitelistingExchange7Es,
    whitelistingExchange8: whitelistingExchange8Es,
  },
};

export default images;
