import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from '../../../i18n';
import SCSpinnerText from './SpinnerText.style';

const SpinnerText = ({ text }) => (
  <SCSpinnerText>
    <FontAwesomeIcon icon={faSpinner} spin />
    {text || useTranslation().t('misc.wait')}
  </SCSpinnerText>
);

export default SpinnerText;
