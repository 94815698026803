import styled from '@emotion/styled';

const SCOrientationBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 325px;
  position: relative;

  .tutorialStep {
    height: 5px;
    background-color: var(--light-grey);
    cursor: pointer;
    border-radius: 50vh;
    outline: none;
    width: ${({ totalSteps }) => `calc((100% - 5rem) / ${totalSteps})`};
  }
`;

export default SCOrientationBox;
