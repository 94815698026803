const getLegalDocumentsUrls = (language) => {
  const URL_LANGUAGE_CODES = {
    es: 'es',
    en: 'en',
    'pt-BR': 'pt',
  };

  const URL_LANGUAGE_CODE =
    URL_LANGUAGE_CODES[language] ?? URL_LANGUAGE_CODES.es;

  return {
    privacyPolicy: `https://static.cyberguardian.tech/${URL_LANGUAGE_CODE}/politica-de-privacidad.pdf`,
    generalConditions: `https://static.cyberguardian.tech/${URL_LANGUAGE_CODE}/condiciones-generales-de-contratacion.pdf`,
    legalNoticeAndTermsOfUse: `https://static.cyberguardian.tech/${URL_LANGUAGE_CODE}/aviso-legal-y-condiciones-de-uso.pdf`,
  };
};

export default getLegalDocumentsUrls;
