import { useSelector } from 'react-redux';

export const SUBSCRIPTIONS_PRODUCTS = {
  DEFAULT: 'default',
  PAYING: 'paying',
  TRIAL_PROTECTION: 'trialProtection',
  TRIAL: 'trial',
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  ENDED: 'ENDED',
};

function useSubscriptionType() {
  const subscriptions = useSelector((redux) => redux.subscriptions);
  const client = useSelector((redux) => redux.client);
  const activeSubscriptionId = client?.status?.activeSubscriptionId;
  const { serviceLevelIDs } = client.status;

  const getCurrentSubscriptionDaysLeft = () => {
    const today = new Date();
    const subscriptionEndTime = getCurrentSubscription()?.endTime.toDate();

    const timeDiff = subscriptionEndTime?.getTime() - today.getTime();
    const diffDays = timeDiff / (1000 * 3600 * 24);
    const roundedDays = Math.ceil(diffDays);

    return roundedDays;
  };

  const subscriptionHasBeenUpdatedOneDayAgo = () => {
    const today = new Date();

    const trialSubscription = hadTrialSubscription();

    const subscriptionStartTime = trialSubscription
      ? trialSubscription?.startTime.toDate()
      : getCurrentSubscription()?.startTime.toDate();

    const nextDayAccountCreation = new Date(subscriptionStartTime);
    nextDayAccountCreation.setDate(nextDayAccountCreation.getDate() + 1);
    return nextDayAccountCreation > today;
  };

  const isTrialWithOneLicence = () => {
    return (
      getCurrentSubscription().product === 'trial' &&
      getCurrentSubscription().nbLicences === 1
    );
  };

  const isElegibleForTrialProtection = () => {
    if (subscriptions && subscriptions[0]) {
      return !subscriptions.find(
        (item) =>
          item.product === SUBSCRIPTIONS_PRODUCTS.TRIAL_PROTECTION ||
          item.product === SUBSCRIPTIONS_PRODUCTS.PAYING
      );
    }
    if (serviceLevelIDs.length === 1) {
      return !serviceLevelIDs.find((item) => item === 'default');
    }

    return true;
  };

  const getCurrentSubscription = () => {
    if (!subscriptions || !subscriptions.length) return undefined;
    return subscriptions?.find(
      (subscription) => subscription.id === activeSubscriptionId
    );
  };

  const hasPayingSubscription = () => {
    if (!getCurrentSubscription()) return false;
    return getCurrentSubscription()?.product === SUBSCRIPTIONS_PRODUCTS.PAYING;
  };

  const hadTrialSubscription = () => {
    if (!subscriptions || subscriptions.length === 0) return false;
    return subscriptions.find((subscription) => {
      return subscription.product === SUBSCRIPTIONS_PRODUCTS.TRIAL;
    });
  };

  const getSubscriptionExpired = () => {
    const expired = getCurrentSubscription()?.endTime?.toDate();
    const todayDate = new Date();

    return expired < todayDate;
  };

  return {
    isElegibleForTrialProtection,
    isTrialWithOneLicence,
    hasPayingSubscription,
    currentSubscriptionDaysLeft: getCurrentSubscriptionDaysLeft(),
    subscriptionHasBeenUpdatedOneDayAgo,
    currentSubscription: getCurrentSubscription(),
    subscriptionExpired: getSubscriptionExpired(),
  };
}

export default useSubscriptionType;
