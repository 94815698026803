import React from 'react';
import { Form, Input } from 'antd';
import SCCustomCard from './CustomCard.style';

const CompanyCard = ({ isSantanderEvent }) => {
  const formItems = [
    {
      label: 'Nombre',
      name: ['company', 'name'],
      type: 'input',
      disabled: true,
    },
    {
      label: 'Razón social',
      name: ['company', 'legalName'],
      type: 'input',
      disabled: true,
    },
    {
      label: 'CIF',
      name: ['company', 'cif'],
      type: 'input',
      disabled: true,
    },
    {
      label: 'Nº empleados',
      name: ['company', 'numberEmployees'],
      type: 'input',
    },
    {
      label: 'Dominio web',
      name: ['company', 'website'],
      type: 'input',
      disabled: true,
    },
    {
      label: 'Dominio correo',
      name: ['atEmail'],
      type: 'input',
      disabled: true,
    },
    ...(isSantanderEvent
      ? [
          {
            label: 'Email gestor',
            name: ['emailManager'],
            type: 'input',
          },
        ]
      : []),
  ];

  return (
    <SCCustomCard title="Empresa" size="small" bordered>
      <div className="content-grid">
        {formItems.map((item) => (
          <Form.Item
            label={
              <span>
                <strong>{item.label}</strong>
              </span>
            }
            name={item.name}>
            <Input disabled={item.disabled} size="small" />
          </Form.Item>
        ))}
      </div>
    </SCCustomCard>
  );
};

export default CompanyCard;
