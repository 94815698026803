import axios from 'axios';

const getPartnerClients = () => {
  return axios.get(`/clients/partners/clients`);
};

const getPartnerClient = (clientId) => {
  return axios.get(`/clients/partners/clients/${clientId}`);
};

/**
 * Creates a new client for this partner.
 * @param {companyName, cif, website, atEmail, firstName, lastName, email, nbLicenses} clientData
 */
const addClientFromPartner = (clientData) => {
  return axios.post('/clients/partners/clients', clientData);
};

export default { getPartnerClients, getPartnerClient, addClientFromPartner };
