import styled from '@emotion/styled';

const SCPartnerClientControlPanel = styled.div`
  .control-panel-cards-container {
    display: grid;
    row-gap: 40px;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;

    .subscription-details-card {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    > div {
      min-height: 300px;
    }
  }

  @media (max-width: 900px) {
    .control-panel-cards-container {
      grid-template-columns: none;
      row-gap: 30px;

      .subscription-details-card {
        grid-column: auto;
      }
    }
  }
`;

export default SCPartnerClientControlPanel;
