import styled from '@emotion/styled';

const SCSpinnerText = styled.div`
  display: inline-block;
  display: flex;
  gap: 10px;
  align-items: center;
`;

export default SCSpinnerText;
