import styled from '@emotion/styled';

const SCPartnerClients = styled.div`
  // Gap between clients table and control panel summary
  --layout-gap: 30px;
  --half-layout-gap: var(--layout-gap) / 2;

  display: flex;
  gap: var(--layout-gap);

  .table-container {
    height: 100%;
    width: ${({ showFull }) =>
      showFull ? '100%' : 'calc(60% - var(--half-layout-gap))'};
    position: sticky;
    top: 97.5px;

    .input-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .selected-row {
      background-color: #e6eaeeb5;
    }

    .bold-red {
      color: var(--red);
      font-weight: 600;
    }
  }

  .partner-clients-summary {
    width: calc(40% - var(--half-layout-gap));
  }

  .ant-table-column-sorter-down.active,
  .ant-table-column-sorter-up.active {
    color: var(--partner-primary);
  }

  @media (max-width: 1400px) {
    flex-direction: column;
    height: '100%';

    h3 {
      position: static;
      top: auto;
    }

    .table-container {
      width: 100%;
      position: static;
      top: auto;
    }

    .partner-clients-summary {
      width: 100%;
    }
  }
`;

export default SCPartnerClients;
