import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { faCircle3 } from '@fortawesome/pro-solid-svg-icons';
import SCNewPhisingCampaignDate from './NewPhisingCampaignDateCard.style';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import DatePickerApp from '../../atoms/DatePicker/DatePicker';

const NewPhisingCampaignDateCard = ({ onChange }) => {
  const [launchDate, setLaunchDate] = useState(moment(new Date()));
  const i18n = useTranslation();

  useEffect(() => {
    if (launchDate) {
      onChange(launchDate.format('DD/MM/YYYY'));
    }
  }, [launchDate]);

  return (
    <SCNewPhisingCampaignDate>
      <SectionCard
        headerIcon={faCircle3}
        hasOverflow
        headerTitle={i18n.t('phishingSimulator.campaigns.create.selectDate')}>
        <DatePickerApp
          defaultValue={launchDate}
          onChange={(date) => {
            setLaunchDate(date);
          }}
        />
      </SectionCard>
    </SCNewPhisingCampaignDate>
  );
};

export default NewPhisingCampaignDateCard;
