import styled from '@emotion/styled';

const SCDevicesSummary = styled.div`
  > table {
    width: 100%;
  }

  .infoBanner-container {
    margin-bottom: 30px;
  }

  .selected-row-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 65px;
    padding: 15px;

    background-color: var(--light-grey);
    border-radius: 5px;

    > p {
      color: var(--bluish-grey);
      text-align: end;
    }

    .selected-row-actions-info {
      display: flex;
      gap: 8px;
      align-items: center;

      p {
        color: var(--bluish-grey);
        font-weight: 500;
      }
    }

    .buttons-container {
      display: flex;
      gap: 20px;
    }
  }
`;

export const SCTypeIcons = styled.div`
  display: flex;
  gap: 20px;
`;

export default SCDevicesSummary;
