import styled from '@emotion/styled';

const SCOnboardLayout = styled.div`
  min-height: 100vh;
  height: 100%;
  background-color: var(--application-background-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 110px;

  .onboard-elements {
    margin-top: calc(var(--navbar-height) * 1.5);
    display: flex;
    height: calc(90% - 100px);
    align-items: center;
    justify-content: center;
    align-content: center;

    @media (max-width: 769px) {
      width: 100vw;
    }
  }
`;

export default SCOnboardLayout;
