import React from 'react';
import MobileMenuItem from '../../atoms/MobileMenuItem/MobileMenuItem';
import SCMobileMenuDropDownItem from './MobileMenuDropDownItem.style';

const MobileMenuDropDownItem = ({
  onClick,
  icon,
  text,
  opened,
  children,
  ...props
}) => {
  return (
    <SCMobileMenuDropDownItem opened={opened}>
      <MobileMenuItem
        onClick={onClick}
        icon={icon}
        text={text}
        droppedDown={opened}
        {...props}
      />

      <div className="dropdown-sidebar">{children}</div>
    </SCMobileMenuDropDownItem>
  );
};

export default MobileMenuDropDownItem;
