import React from 'react';
import PropTypes from 'prop-types';
import SCOrientationBox from './OrientationBox.style';

const OrientationBox = ({ activeStep, onClick, totalSteps }) => {
  const orientationSteps = [];

  for (let i = 0; i < totalSteps; i += 1) {
    orientationSteps.push(
      <button
        className="tutorialStep"
        type="button"
        aria-label="change-tutorial-step"
        style={{
          backgroundColor:
            activeStep === i + 1 ? 'var(--bluish-grey)' : 'var(--light-grey)',
        }}
        key={i}
        onClick={() => onClick(i + 1)}
      />
    );
  }

  return (
    <SCOrientationBox totalSteps={totalSteps}>
      {orientationSteps}
    </SCOrientationBox>
  );
};

OrientationBox.defaultProps = {
  activeStep: 0,
};

OrientationBox.propTypes = {
  activeStep: PropTypes.number,
};

export default OrientationBox;
