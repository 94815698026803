import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Button from '../../atoms/Button/Button';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import emailProtectionService from '../../../services/emailProtection/emailProtection.service';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .ReleaseQuarantineEmail_Text {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 10px;
    p {
      font-size: 14px;
      color: var(--black);
      font-weight: 400;
    }
  }
`;

const ReleaseQuarantineEmail = ({ emailId }) => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const release = () => {
    setLoading(true);
    emailProtectionService
      .release(emailId)
      .then(() => {
        navigate(-1);
        dispatch(
          showPopUp('notification', {
            notificationType: 'success',
            title: i18n.t('common.success'),
          })
        );
      })
      .catch(() => {
        dispatch(
          showPopUp('notification', {
            notificationType: 'error',
            title: i18n.t('common.error'),
            text: i18n.t('errors.not_found'),
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cancel = () => {
    dispatch(showPopUp(null));
  };

  return (
    <ModalLayout>
      <Wrapper>
        <h1>{i18n.t('mailbox-security.mail.releasePopUp.header')}</h1>
        <div className="modal-card-content ReleaseQuarantineEmail_Text">
          <p> {i18n.t('mailbox-security.mail.releasePopUp.text_one')}</p>
          <p> {i18n.t('mailbox-security.mail.releasePopUp.text_two')}</p>
        </div>
        <div className="modal-card-buttons">
          <Button
            color="bluishGrey"
            size="full"
            type="button"
            text={
              loading ? (
                <SpinnerText text={i18n.t('common.loading')} />
              ) : (
                i18n.t('mailbox-security.mail.releasePopUp.actions.release')
              )
            }
            onClick={release}
          />

          <Button
            color="white"
            size="full"
            type="button"
            text={i18n.t('mailbox-security.mail.releasePopUp.actions.cancel')}
            onClick={cancel}
          />
        </div>
      </Wrapper>
    </ModalLayout>
  );
};

export default ReleaseQuarantineEmail;
