import styled from '@emotion/styled';
import React from 'react';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const SCMaxPhishingCampaignsPopUp = styled.div`
  .icon-container {
    border-radius: 50vh;
    border: 5px solid ${({ color }) => color || 'inherit'};
  }

  .paragraph {
    font-family: var(--font1);
    font-size: 14px;
  }
`;

const MaxPhishingCampaignsPopUp = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();

  return (
    <ModalLayout>
      <SCMaxPhishingCampaignsPopUp>
        <h1>{i18n.t('welcome.restrictions.maxUsersInEmailList_title')}</h1>

        <div className="icon-container">
          <Icon iconName={faLock} size="large" color="var(--bluish-grey)" />
        </div>

        <p className="paragraph">
          {i18n.t('welcome.restrictions.maxCampaigns')}
        </p>

        <div className="modal-card-buttons">
          <Button
            size="full"
            color="bluishGrey"
            text={i18n.t('common.contactSupport')}
            onClick={() => {
              dispatch(showPopUp(null));
              Tawk_API.toggle();
            }}
          />

          <Button
            size="full"
            color="white"
            text={i18n.t('common.close')}
            onClick={() => {
              dispatch(showPopUp(null));
            }}
          />
        </div>
      </SCMaxPhishingCampaignsPopUp>
    </ModalLayout>
  );
};

export default MaxPhishingCampaignsPopUp;
