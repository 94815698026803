import { css } from '@emotion/core';
import styled from '@emotion/styled';

const defaultValue = () => css`
  .Select_Icon {
    left: 0px;
  }

  select {
    border: none;
    border-bottom: 1px solid var(--light-grey-3);
    opacity: 1;
    color: var(--black);

    :disabled {
      border-bottom-color: transparent;
      background: none;
    }
  }
`;

const round = () => css`
  .Select_Icon {
    left: 7.5px;
  }

  select {
    border: 1px solid var(--light-grey-3);
    border-radius: 50vh;
    background-color: var(--light-grey-4);
    color: var(--bluish-grey);
    font-weight: 500;
  }
`;

const designStyles = {
  defaultValue,
  round,
};

const SCSelect = styled.div`
  position: ${({ hasIcon }) => (hasIcon ? 'relative' : 'inherit')};

  .Select_Icon {
    width: 30px;
    position: absolute;
    height: 100%;
    color: var(--bluish-grey);
    > img {
      width: 15px;
      height: 35px;
      margin: auto;
      display: flex;
    }
  }

  select {
    height: 35px;
    cursor: pointer;
    padding-left: ${({ hasIcon }) => (hasIcon ? '35px' : '15px')};
    padding-right: 35px;
    width: 100%;
    min-width: 210px;
    font-family: var(--font2);

    /* for Firefox */
    -moz-appearance: none;

    /* for Chrome */
    -webkit-appearance: none;

    background: url('data:image/svg+xml;utf8,<svg style="filter: invert(63%) sepia(8%) saturate(749%) hue-rotate(169deg) brightness(83%) contrast(85%)" xmlns="http://www.w3.org/2000/svg" width="22.5" height="22.5" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"/></svg>')
      no-repeat;
    background-size: 18px;
    background-position-x: calc(100% - 5px);
    background-position-y: 8px;

    outline: 0px;
  }

  ${({ design }) =>
    design ? designStyles[design] : designStyles.defaultValue};

  /* For IE10 */
  select::-ms-expand {
    display: none;
  }
`;

export default SCSelect;
