import { useSelector } from 'react-redux';

const usePayments = () => {
  const payments = useSelector((redux) => redux.payments);

  return {
    payments,
  };
};

export default usePayments;
