import styled from '@emotion/styled';

const SCSpfAndDmarcCard = styled.div`
  .SPfAndDmarc_Container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .SPfAndDmarc_ImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
  }

  .SPfAndDmarc_TextContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 30px;
  }

  .SPfAndDmarc_Text {
    display: flex;
    flex-direction: column;

    b {
      font-size: 16px;
      font-family: var(--font2);
    }
  }

  img {
    max-width: 200px;
  }
`;

export default SCSpfAndDmarcCard;
