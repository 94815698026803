import styled from '@emotion/styled';

const SCWhitelisting = styled.div`
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  gap: 45px;
  margin: 0 auto;
  margin-top: calc(var(--navbar-height) * 1.5);
`;

export default SCWhitelisting;
