import { firestore } from '../../firebase';
import { GET_PHISHING_TRAININGS } from './types';

const setPhishingTrainingsListener = (clientId, dispatch) => {
  firestore
    .collection('clients')
    .doc(clientId)
    .collection('trainings')
    .onSnapshot(async (surveysSnapshot) => {
      const phishingTrainings = {};
      await surveysSnapshot.forEach(async (doc) => {
        if (doc.exists) {
          const email = await firestore
            .collection('clients')
            .doc(clientId)
            .collection('team_members')
            .doc(doc.data().hashedEmail)
            .get()
            .then((userObj) => {
              if (userObj?.data()) return userObj.data().email;
              return null;
            });

          if (doc.data().training === 'phishing') {
            if (email) phishingTrainings[email] = { ...doc.data(), email };
            dispatch({
              type: GET_PHISHING_TRAININGS,
              payload: { ...phishingTrainings },
            });
          }
        }
      });
    });
};

export default setPhishingTrainingsListener;
