import styled from '@emotion/styled';

const SCSeparator = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 10px;

  hr {
    width: 100%;
    border: 0;
    border-top: 1px solid var(--hr-color);
    margin: 0px;
  }

  & svg {
    margin-right: 5px;
  }

  b {
    white-space: nowrap;
    font-family: var(--font1);
    font-size: 16px;
    color: var(--bluish-grey);
    font-weight: 500;
  }

  .icon-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default SCSeparator;
