const SPANISH_STATES = [
  {
    name: 'Álava',
    intlCode: 'ALAVA',
    code: '01',
  },
  {
    name: 'Albacete',
    intlCode: 'ALBACETE',
    code: '02',
  },
  {
    name: 'Alicante',
    intlCode: 'ALICANTE',
    code: '03',
  },
  {
    name: 'Almería',
    intlCode: 'ALMERIA',
    code: '04',
  },
  {
    name: 'Ávila',
    intlCode: 'AVILA',
    code: '05',
  },
  {
    name: 'Badajoz',
    intlCode: 'BADAJOZ',
    code: '06',
  },
  {
    name: 'Islas Baleares',
    intlCode: 'ISLAS_BALEARES',
    code: '07',
  },
  {
    name: 'Barcelona',
    intlCode: 'BARCELONA',
    code: '08',
  },
  {
    name: 'Burgos',
    intlCode: 'BURGOS',
    code: '09',
  },
  {
    name: 'Cáceres',
    intlCode: 'CACERES',
    code: '10',
  },
  {
    name: 'Cádiz',
    intlCode: 'CADIZ',
    code: '11',
  },
  {
    name: 'Castellón',
    intlCode: 'CASTELLON',
    code: '12',
  },
  {
    name: 'Ciudad Real',
    intlCode: 'CIUDAD_REAL',
    code: '13',
  },
  {
    name: 'Córdoba',
    intlCode: 'CORDOBA',
    code: '14',
  },
  {
    name: 'Coruña',
    intlCode: 'CORUNA',
    code: '15',
  },
  {
    name: 'Cuenca',
    intlCode: 'CUENCA',
    code: '16',
  },
  {
    name: 'Gerona',
    intlCode: 'GERONA',
    code: '17',
  },
  {
    name: 'Granada',
    intlCode: 'GRANADA',
    code: '18',
  },
  {
    name: 'Guadalajara',
    intlCode: 'GUADALAJARA',
    code: '19',
  },
  {
    name: 'Gipuzkoa',
    intlCode: 'GIPUZKOA',
    code: '20',
  },
  {
    name: 'Huelva',
    intlCode: 'HUELVA',
    code: '21',
  },
  {
    name: 'Huesca',
    intlCode: 'HUESCA',
    code: '22',
  },
  {
    name: 'Jaén',
    intlCode: 'JAEN',
    code: '23',
  },
  {
    name: 'León',
    intlCode: 'LEON',
    code: '24',
  },
  {
    name: 'Lérida',
    intlCode: 'LERIDA',
    code: '25',
  },
  {
    name: 'La Rioja',
    intlCode: 'LA_RIOJA',
    code: '26',
  },
  {
    name: 'Lugo',
    intlCode: 'LUGO',
    code: '27',
  },
  {
    name: 'Madrid',
    intlCode: 'MADRID',
    code: '28',
  },
  {
    name: 'Málaga',
    intlCode: 'MALAGA',
    code: '29',
  },
  {
    name: 'Murcia',
    intlCode: 'MURCIA',
    code: '30',
  },
  {
    name: 'Navarra',
    intlCode: 'NAVARRA',
    code: '31',
  },
  {
    name: 'Ourense',
    intlCode: 'OURENSE',
    code: '32',
  },
  {
    name: 'Asturias',
    intlCode: 'ASTURIAS',
    code: '33',
  },
  {
    name: 'Palencia',
    intlCode: 'PALENCIA',
    code: '34',
  },
  {
    name: 'Las Palmas',
    intlCode: 'LAS_PALMAS',
    code: '35',
  },
  {
    name: 'Pontevedra',
    intlCode: 'PONTEVEDRA',
    code: '36',
  },
  {
    name: 'Salamanca',
    intlCode: 'SALAMANCA',
    code: '37',
  },
  {
    name: 'S. C. Tenerife',
    intlCode: 'TENERIFE',
    code: '38',
  },
  {
    name: 'Cantabria',
    intlCode: 'CANTABRIA',
    code: '39',
  },
  {
    name: 'Segovia',
    intlCode: 'SEGOVIA',
    code: '40',
  },
  {
    name: 'Sevilla',
    intlCode: 'SEVILLA',
    code: '41',
  },
  {
    name: 'Soria',
    intlCode: 'SORIA',
    code: '42',
  },
  {
    name: 'Tarragona',
    intlCode: 'TARRAGONA',
    code: '43',
  },
  {
    name: 'Teruel',
    intlCode: 'TERUEL',
    code: '44',
  },
  {
    name: 'Toledo',
    intlCode: 'TOLEDO',
    code: '45',
  },
  {
    name: 'Valencia',
    intlCode: 'VALENCIA',
    code: '46',
  },
  {
    name: 'Valladolid',
    intlCode: 'VALLADOLID',
    code: '47',
  },
  {
    name: 'Bizkaia',
    intlCode: 'BIZKAIA',
    code: '48',
  },
  {
    name: 'Zamora',
    intlCode: 'ZAMORA',
    code: '49',
  },
  {
    name: 'Zaragoza',
    intlCode: 'ZARAGOZA',
    code: '50',
  },
  {
    name: 'Ceuta',
    intlCode: 'CEUTA',
    code: '51',
  },
  {
    name: 'Melilla',
    intlCode: 'MELILLA',
    code: '52',
  },
];

export default SPANISH_STATES;
