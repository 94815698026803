import styled from '@emotion/styled';

const SCKPICard = styled.div`
  padding: 30px;
  background-color: var(--white);
  border-radius: var(--section-radius);
  display: flex;
  gap: 20px;
  align-items: center;

  .rounded-icon {
    padding: 10px;
    background-color: var(--partner-primary-light);
    border-radius: 20px;
  }

  .widget-texts {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      font-family: var(--font1);
      font-size: 16px;
      font-weight: 400;
      color: var(--bluish-grey);
    }

    .ant-skeleton-title {
      height: 30px;
    }

    .ant-skeleton-paragraph > li {
      height: 20px;
    }
  }
`;

export default SCKPICard;
