import styled from '@emotion/styled/macro';

const SCDropdown = styled.div`
  position: relative;
  cursor: pointer;

  .clickable-element {
    display: flex;
    align-items: center;
    gap: 10px;

    h3 {
      font-family: var(--font1);
      font-weight: 500;
      user-select: none;
    }

    .dropdown-arrow {
      transition: transform 225ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
      &.down {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-container {
    position: absolute;
    width: fit-content;
    top: 30px;
    right: 0;
    display: ${({ dropdownOpened }) => (dropdownOpened ? 'block' : 'none')};
    padding: 15px;
    background-color: var(--white);
    box-shadow: 2px 2px 20px 0px rgba(121, 136, 151, 0.15);
    border-radius: 5px;

    .dropdown-element {
      height: 50px;
      display: flex;
      gap: 6px;
      align-items: center;
      color: var(--bluish-grey-2);

      > button {
        color: inherit;
        font-family: var(--font1);
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        text-align: left;
        background: transparent;
        padding: 5px;
        white-space: nowrap;
      }

      &.active {
        color: var(--red);
      }
    }
  }

  @media (max-width: 576px) {
    .clickable-element {
      h3 {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 17ch;
      }
    }
  }
`;

export default SCDropdown;
