import styled from '@emotion/styled';

const SCFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  .filter-select-container {
    width: 210px;
  }
`;

export default SCFilter;
