import styled from '@emotion/styled';

const SCProgressMeasuresTakenCard = styled.div`
  .progress-actions-container {
    .progress-actions-first-step {
      display: flex;
      flex-direction: row;
      padding-bottom: 30px;
    }

    .progress-actions-second-step {
      display: flex;
      flex-direction: row;
    }

    .steps-icon {
      display: flex;
      flex-direction: column;
    }

    .steps-information-title {
      padding-left: 20px;
    }

    h3 {
      margin: 7.5px 0px;
      font-weight: 500;
      font-size: 20px;
    }

    h3.dark-grey {
      color: var(--bluish-grey);
    }

    h3.light-grey {
      color: #d9d9d9;
    }

    h3.light-green {
      color: var(--green);
    }

    p {
      color: var(--black);
      font-size: 16px;
      font-weight: 200;
      margin-bottom: 25px;
    }
  }
`;

export default SCProgressMeasuresTakenCard;
