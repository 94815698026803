import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDollyBox,
  faGlobe,
  faMask,
  faSyncAlt,
} from '@fortawesome/pro-regular-svg-icons';

import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import useMonitoredSuppliers from '../../../hooks/monitoredSuppliers/useMonitoredSuppliers';
import SCSupplierAnalysisCard from './SupplierAnalysisCard.style';
import Button from '../../atoms/Button/Button';
import { getDateFormat } from '../../../utils/format/dataFormat';
import useRunScan from '../../../hooks/useRunScan/useRunScan';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';

const SupplierAnalysisCard = ({ supplier }) => {
  const i18n = useTranslation();
  const { getRiskLabel } = useMonitoredSuppliers();
  const { loading, isAbleToRescan, isLoadingNewScan, handleRunScan } =
    useRunScan({ domain: supplier.domain, atEmail: supplier.atEmail });

  return (
    <SCSupplierAnalysisCard>
      <SectionCard
        headerIcon={faDollyBox}
        headerTitle={i18n.t(
          'suppliersSecurity.supplierDetailPage.supplierAnalysis'
        )}>
        <div className="ss-analysis">
          <div className="analysis-content">
            <div>
              <FontAwesomeIcon icon={faMask} />
              <div>
                <h4>{i18n.t('suppliersSecurity.results.impersonation')}</h4>
                <div className="column-content">
                  <div>
                    <span>
                      {i18n.t('suppliersSecurity.results.impersonationDetail')}
                    </span>
                    {supplier.impersonation &&
                      getRiskLabel(supplier.impersonation.overall.score)}
                  </div>
                  <div>
                    <span>
                      {i18n.t('suppliersSecurity.results.breachedCredentials')}
                    </span>
                    {supplier.accounts &&
                      getRiskLabel(supplier.accounts.overall.score)}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <FontAwesomeIcon icon={faGlobe} />
              <div>
                <h4>{i18n.t('suppliersSecurity.results.websiteSecurity')}</h4>
                <div className="column-content">
                  <div>
                    <span>{i18n.t('suppliersSecurity.results.xss')}</span>
                    {supplier &&
                      supplier.website &&
                      supplier.website.xss &&
                      getRiskLabel(supplier.website.xss.score)}
                  </div>
                  <div>
                    <span>
                      {i18n.t('suppliersSecurity.results.clickjacking')}
                    </span>
                    {supplier.website &&
                      getRiskLabel(supplier.website.clickjacking.score)}
                  </div>
                  <div>
                    <span>
                      {i18n.t('suppliersSecurity.results.manInTheMiddle')}
                    </span>
                    {supplier.website &&
                      getRiskLabel(supplier.website.mitm.score)}
                  </div>
                  <div>
                    <span>{i18n.t('suppliersSecurity.results.ddos')}</span>
                    {supplier.website &&
                      getRiskLabel(supplier.website.ddos.score)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="analysis-footer">
          <Button
            icon={!isLoadingNewScan && faSyncAlt}
            onClick={handleRunScan}
            width="180px"
            text={
              isLoadingNewScan ? (
                <SpinnerText text={i18n.t('common.analyzing')} />
              ) : (
                i18n.t('websiteSecurity.runScan')
              )
            }
            disabled={loading || isLoadingNewScan || !isAbleToRescan}
            size="mid"
            color="white"
          />
          <h3>
            {`${i18n.t(
              'suppliersSecurity.supplierDetailPage.lastScan'
            )} ${getDateFormat(supplier.timestamp.toDate())}`}
          </h3>
        </div>
      </SectionCard>
    </SCSupplierAnalysisCard>
  );
};

export default SupplierAnalysisCard;
