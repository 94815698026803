import { faEye } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { Skeleton } from 'antd';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCMailContentCard from './MailContentCard.style';

const MailContentCard = ({ mailInfo, isLoading }) => {
  const i18n = useTranslation();

  const formatted = `<html>${mailInfo?.body}</html>`;

  return (
    <SCMailContentCard>
      <SectionCard
        headerIcon={faEye}
        headerTitle={i18n.t('mailbox-security.mail.content.title')}>
        <div className="MailContentCard_MainContainer">
          {!isLoading ? (
            mailInfo?.body && (
              <textarea rows="16" readOnly>
                {formatted}
              </textarea>
            )
          ) : (
            <Skeleton active paragraph={{ rows: 4 }} />
          )}
        </div>
      </SectionCard>
    </SCMailContentCard>
  );
};

export default MailContentCard;
