import { faEnvelopesBulk } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCEmailSecurityCard from './EmailSecurityCard.style';
import { useTranslation } from '../../../i18n';
import { getEmailSecurityColor } from '../../../utils/functions/securitySeverity';
import LabelDetailGroup from '../LabelDetailGroup/LabelDetailGroup';
import { parseDate } from '../../../utils/format/dataFormat';
import {
  getDMARCStatus,
  getSPFStatus,
} from '../../../utils/emailSecurity/emailSecurity';

const EmailSecurityCard = ({
  data = {},
  atEmail,
  isHeaderClickable,
  loading,
}) => {
  const navigate = useNavigate();
  const i18n = useTranslation();

  const { spf, dmarc, updatedAt } = data;

  const SPFStatus = getSPFStatus(spf);
  const DMARCStatus = getDMARCStatus(dmarc);

  const LABEL_DETAILS = [
    {
      name: i18n.t('controlPanel.emailSecurity.SPF'),
      value: i18n.t(`configureEmailSecurity.SPF.${SPFStatus}`),
      color: getEmailSecurityColor(SPFStatus),
    },

    {
      name: i18n.t('controlPanel.emailSecurity.DMARC'),
      value: i18n.t(`configureEmailSecurity.DMARC.${DMARCStatus}`),
      color: getEmailSecurityColor(DMARCStatus),
    },
  ];

  const handleHeaderButtonOnClick = isHeaderClickable
    ? () => {
        navigate('/client/configure-email-security');
      }
    : undefined;

  return (
    <SCEmailSecurityCard>
      <SectionCard
        headerIcon={faEnvelopesBulk}
        headerTitle={`${i18n.t(
          'controlPanel.emailSecurity.title'
        )} @${atEmail}`}
        headerButtonOnClick={handleHeaderButtonOnClick}>
        <LabelDetailGroup
          labelDetails={LABEL_DETAILS}
          lastUpdateDate={parseDate(updatedAt)}
          loading={loading}
        />
      </SectionCard>
    </SCEmailSecurityCard>
  );
};

export default EmailSecurityCard;
