import {
  ADD_EMAIL_RESULTS,
  REMOVE_EXPLICIT_EMAIL_RESULTS,
} from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_EMAIL_RESULTS:
      return { ...state, ...action.payload } || {};
    case REMOVE_EXPLICIT_EMAIL_RESULTS: {
      // remove email from state
      const newState = state;
      delete newState[action.payload];
      return newState;
    }
    default:
      return state;
  }
};
