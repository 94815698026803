import { faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons';
import {
  faClockRotateLeft,
  faFile,
  faUserCowboy,
  faUserTie,
  faEnvelope,
  faTag,
} from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import React from 'react';
import { Skeleton } from 'antd';
import { useTranslation } from '../../../i18n';
import { getDateFormat } from '../../../utils/format/dataFormat';
import Icon from '../../atoms/Icon/Icon';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCMailDetailsCard from './mailDetailsCard.style';

const MailDetailsCard = ({ mailInfo, isLoading }) => {
  const i18n = useTranslation();

  const mailInfoData = [
    {
      icon: faEnvelope,
      title: i18n.t('mailbox-security.mail.details.keys.subject'),
      value: mailInfo?.subject,
      excludeFromFS: false,
    },
    {
      icon: faTag,
      title: i18n.t('mailbox-security.mail.details.keys.reason'),
      value: i18n.t(
        `mailbox-security.mail.table.content.reasons.${mailInfo?.type}`
      ),
      excludeFromFS: false,
    },
    {
      icon: faUserCowboy,
      title: i18n.t('mailbox-security.mail.details.keys.from'),
      value: mailInfo?.from,
      excludeFromFS: true,
    },
    {
      icon: faUserTie,
      title: i18n.t('mailbox-security.mail.details.keys.to'),
      value: mailInfo?.rcpt,
      excludeFromFS: true,
    },
    {
      icon: faClockRotateLeft,
      title: i18n.t('mailbox-security.mail.details.keys.date'),
      value: i18n.t('mailbox-security.mail.details.keys.hourLabel', {
        date: getDateFormat(new Date(mailInfo?.date)),
        hour: moment(new Date(mailInfo?.date)).format('HH:mm'),
      }),
      excludeFromFS: false,
    },
    {
      icon: faFile,
      title: i18n.t('mailbox-security.mail.details.keys.size'),
      value: `${mailInfo?.size} kB`,
      excludeFromFS: false,
    },
  ];

  return (
    <SCMailDetailsCard>
      <SectionCard
        headerIcon={faEnvelopeOpen}
        headerTitle={i18n.t('mailbox-security.mail.details.title')}>
        <div className="MailDetailsCard_Details">
          {mailInfoData.map(({ icon, title, value, excludeFromFS }) => (
            <div className="MailDetailsCard_InfoContainer">
              <Icon
                iconName={icon}
                size="semiLarge"
                color="var(--bluish-grey-3)"
              />
              <div className="MailDetailsCard_Info">
                <span>{title}</span>
                <p className={excludeFromFS ? 'fs-exclude' : ''}>
                  {!isLoading ? (
                    value ?? i18n.t('websiteSecurity.unknown')
                  ) : (
                    <Skeleton
                      active
                      title={false}
                      paragraph={{ rows: 1 }}
                      style={{ width: '200px' }}
                    />
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
      </SectionCard>
    </SCMailDetailsCard>
  );
};

export default MailDetailsCard;
