import { useSelector } from 'react-redux';
import handleUnauthorizedAccess from '../../../utils/restrictedAccessBehaviour/restrictedAccessBehaviour';

function BreachesRouteGuard({ children }) {
  const client = useSelector((redux) => redux.client);

  return client?.status?.serviceLevel?.periodicitiesInDays
    ?.scanEmailsForBreaches
    ? children
    : handleUnauthorizedAccess();
}
export default BreachesRouteGuard;
