import styled from '@emotion/styled';

const SCTag = styled.div`
  display: flex;
  flex-direction: column;

  .Tag_Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .Tag_Info,
    .Tag_Actions {
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      scroll-margin-top: 10px;
      margin-right: 10px;
    }

    .Tag_Info {
      gap: 10px;
    }
  }

  .Tag_Palette_Container {
    margin-left: auto;
  }
`;

export default SCTag;
