import styled from '@emotion/styled';

const SCInitialTutorialPage = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: var(--application-background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .it-step-wrapper {
    max-width: 100%;
    width: 900px;
    padding: 30px;
    background-color: var(--white);
  }

  @media (width < 769px) {
    .it-step-wrapper {
      height: 100%;
      display: flex;

      flex-direction: column;
      justify-content: space-between;

      padding: 60px 0px 30px 0px;

      div {
        ::-webkit-scrollbar {
          display: none;
        }
      }
    }

    p {
      font-size: 18px !important;
      line-height: 1.15 !important;
    }

    h1 {
      font-size: 32px !important;
    }
    h3 {
      font-size: 20px !important;
    }
  }
`;

export default SCInitialTutorialPage;
