import styled from '@emotion/styled';

const SCSectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  .icon-container {
    border-radius: 5px;
    padding: 12.5px;
    background: var(--bluish-grey);
  }

  .main-container {
    display: flex;
    flex-direction: column;

    p {
      font-family: var(--font1);
      font-weight: 500;
      color: var(--bluish-grey);
    }

    .title-container {
      font-size: 11px;
    }

    .subtitle-container {
      font-size: 16px;
    }
  }

  .skeleton-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

export default SCSectionHeader;
