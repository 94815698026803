import React from 'react';
import { faLockAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import usePaymentAccess from '../../../hooks/paymentAccess/usePaymentAccess';
import { hasPaymentAccess } from '../../../utils/functions/subscriptions';

const RequirePaymentWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  margin: 0 auto;

  h3 {
    text-align: left;
  }

  .require-payment-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`;

const RequirePayment = () => {
  const i18n = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handlePaymentAccess } = usePaymentAccess();

  return (
    <ModalLayout>
      <RequirePaymentWrapper>
        <h1>{i18n.t('welcome.restrictions.restrictedPageTitle')}</h1>

        <div className="modal-card-content">
          <div className="require-payment-content">
            <FontAwesomeIcon
              icon={faLockAlt}
              style={{ color: 'var(--bluish-grey)', fontSize: '60px' }}
            />

            <h3>{i18n.t('welcome.restrictions.restrictedPageParagraph1')}</h3>
            <h3>{i18n.t('welcome.restrictions.restrictedPageParagraph2')}</h3>
          </div>
        </div>
        <div className="modal-card-buttons">
          {hasPaymentAccess() && (
            <Button
              size="full"
              color="bluishGrey"
              text={i18n.t('welcome.restrictions.upgradeToday')}
              onClick={() => {
                dispatch(showPopUp(null));
                handlePaymentAccess();
              }}
            />
          )}

          <Button
            size="full"
            color="white"
            text={i18n.t('contactUs.contactSupport')}
            onClick={() => {
              dispatch(showPopUp(null));
              navigate('/client');
              Tawk_API.toggle();
            }}
          />

          <Button
            size="full"
            color="white"
            text={i18n.t('common.cancel')}
            onClick={() => {
              dispatch(showPopUp(null));
              navigate('/client');
            }}
          />
        </div>
      </RequirePaymentWrapper>
    </ModalLayout>
  );
};
export default RequirePayment;
