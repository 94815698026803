import styled from '@emotion/styled/macro';
import { Card } from 'antd';

const SCCustomCard = styled(Card)`
  .content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;

    .full-width {
      grid-column: span 2;

      .ant-form-item-label {
        min-width: auto !important;
      }
    }

    .full-text {
      white-space: normal;
    }

    .ant-form-item {
      margin-bottom: 0 !important;

      .ant-picker {
        width: 100%;
      }
    }

    label {
      min-width: 90px;
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  @media (width < 1300px) {
    .content-grid {
      grid-template-columns: auto;

      .full-width {
        grid-column: auto;
      }
    }
  }
`;

export default SCCustomCard;
