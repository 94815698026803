import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faEnvelope, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

import Button from '../../atoms/Button/Button';
import SCInstallProtections from './InstallProtections.style';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { useTranslation } from '../../../i18n';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import TagList from '../../molecules/TagList/TagList';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import Icon from '../../atoms/Icon/Icon';

const InstallProtections = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();

  const teamMembers = useSelector((redux) => redux.teamMembers);

  const [selectedTeamMembersEmails, setSelectedTeamMembersEmails] = useState(
    []
  );

  const mapperFn = (item) => {
    return {
      name: {
        type: 'component',
        value: (
          <Tooltip
            tooltip={
              <p>
                {item.firstName} {item.lastName}
              </p>
            }>
            <p>
              {item.firstName} {item.lastName}
            </p>
          </Tooltip>
        ),
        sortValue: `${item.firstName} ${item.lastName}`,
      },
      email: {
        type: 'string',
        value: item.email,
        sortValue: item.email,
      },
      role: {
        type: 'component',
        value: <TagList teamMember={item} cell />,
      },
    };
  };

  const PAGE_SIZE = 10;

  const defaultSortDirection = 'asc';
  const sortOptions = {
    activeSortedColumnName: i18n.t('deviceSecurity.selectEmailsModal.name'),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 0,
    onSort: (data) => {
      setSort(data);
    },
  };

  const { setSort, setSearch, setSearchTags, rows, length } = useFilterTable(
    PAGE_SIZE,
    mapperFn,
    teamMembers,
    ['firstName', 'lastName', 'email'],
    sortOptions
  );

  const columns = [
    {
      name: i18n.t('deviceSecurity.selectEmailsModal.name'),
      options: {
        stickyOnScroll: true,
        width: 200,
        stickyOffset: 40,
        ellipsisContent: true,
        ofuscateFS: true,
      },
    },

    {
      name: i18n.t('deviceSecurity.selectEmailsModal.email'),
      options: {
        ofuscateFS: true,
      },
    },
    {
      name: i18n.t(`manageEmailList.tags`),
      options: {
        avoidSort: true,
      },
    },
  ];

  return (
    <SCInstallProtections>
      <div className="install-protections-wrapper">
        <SectionCard
          headerIcon={faUserPlus}
          headerTitle={i18n.t('deviceSecurity.selectEmailsModal.chooseUsers')}>
          <p className="install-protections-text">
            {i18n.t('deviceSecurity.selectEmailsModal.chooseUsersParagraph')}
          </p>

          <TableContainer
            onChangeValue={setSearch}
            columns={columns}
            rows={rows}
            emptyMessage={i18n.t('manageEmailList.emptyMessage')}
            onChangeFilterTag={setSearchTags}
            hasHorizontalScroll
            totalCount={length}
            pageSize={PAGE_SIZE}
            sortOptions={sortOptions}
            hasRowSelect
            rowIdentifier="email"
            rowHasBeenSelected={(selectedRows) => {
              setSelectedTeamMembersEmails(
                selectedRows.map((row) => row.email.value)
              );
            }}
            customBottomElements={
              <div className="selected-row-actions">
                <div className="selected-row-actions-info">
                  <Icon iconName={faCircleInfo} color="var(--bluish-grey)" />

                  <p>
                    {selectedTeamMembersEmails.length === 0
                      ? i18n.t('deviceSecurity.selectEmployees')
                      : i18n.t('deviceSecurity.selectedEmployees', {
                          numberOfUsers: `${selectedTeamMembersEmails.length}`,
                        })}
                  </p>
                </div>

                {selectedTeamMembersEmails.length > 0 && (
                  <Button
                    onClick={() =>
                      dispatch(
                        showPopUp(
                          'installProtections',
                          selectedTeamMembersEmails
                        )
                      )
                    }
                    icon={faEnvelope}
                    text={i18n.t(
                      'deviceSecurity.selectEmailsModal.sendLicenses'
                    )}
                    color="white"
                    size="customMid"
                  />
                )}
              </div>
            }
          />
        </SectionCard>
      </div>
    </SCInstallProtections>
  );
};
export default InstallProtections;
