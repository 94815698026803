import { useSelector } from 'react-redux';
import handleUnauthorizedAccess from '../../../utils/restrictedAccessBehaviour/restrictedAccessBehaviour';

function PhisingRouteGuard({ children }) {
  const client = useSelector((redux) => redux.client);

  return client?.status?.serviceLevel?.limits?.maxPhishingCampaigns
    ? children
    : handleUnauthorizedAccess();
}
export default PhisingRouteGuard;
