import styled from '@emotion/styled';

const SCThreatsNeutralized = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: 100%;
  gap: 30px;

  .ManagedSection_Resume {
    min-height: 40px;

    display: flex;
    gap: 4px;

    > p {
      color: ${({ isSubtitleHighlighted }) =>
        isSubtitleHighlighted ? 'var(--red)' : 'var(--bluish-grey)'};
    }

    .ManagedSection_Resume_Icon {
      justify-content: flex-start;
      margin-top: 3px;
    }
  }

  .ManagedSection_Items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .ManagedSection_Item {
      width: calc(50% - 5px);
    }
  }
`;

export default SCThreatsNeutralized;
