import React, { useEffect, useState } from 'react';
import SCLeadsPage from './LeadsPage.style';
import reports from '../../img/leadsPage/report.webp';
import { ReactComponent as CyberGuardianImg } from '../../img/cyberguardian/cyberguardian-logo.svg';
import { firestore } from '../../firebase';
import { getDateFormat } from '../../utils/format/dataFormat';
import ContactLegalInformation from '../../components/molecules/ContactLegalInformation/ContactLegalInformation';
import Navbar from '../../components/molecules/Navbar/Navbar';
import LandingForm from '../../components/organisms/LandingForm/LandingForm';
import { useTranslation } from '../../i18n';

const LeadsPage = () => {
  const [leadsPageConfig, setLeadsPageConfig] = useState({});
  const [showEventInfo, setShowEventInfo] = useState(false);
  const i18n = useTranslation();

  useEffect(() => {
    firestore
      .collection('config')
      .doc('leads_page')
      .get()
      .then((leadsPageDocument) => {
        const data = leadsPageDocument.data();

        const today = new Date();
        setShowEventInfo(
          data.startDate.toDate() <= today && data.endDate.toDate() >= today
        );

        const dateOptions = {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        };
        setLeadsPageConfig({
          eventName: data.eventName,
          eventLogo: data.eventLogo,
          isSantanderEvent: data.isSantanderEvent,
          startDate: getDateFormat(data.startDate.toDate(), dateOptions),
          endDate: getDateFormat(data.endDate.toDate(), dateOptions),
        });
      });
  }, []);

  const WHAT_DOES_YOUR_REPORT_CONTAIN_CONTENT = [
    i18n.t('landing.onlineSecurityScore'),
    i18n.t('landing.webMainRisks'),
    i18n.t('landing.dataBreachesRisk'),
  ];

  return (
    <SCLeadsPage>
      <Navbar hideAuthButtons />

      <header className="header-container">
        <div className="background-image">
          <div className="header-content-container">
            <div className="image-container">
              {showEventInfo ? (
                <img
                  src={leadsPageConfig.eventLogo}
                  alt={`${leadsPageConfig.eventName} logo`}
                />
              ) : (
                <CyberGuardianImg style={{ width: '231px', height: '80px' }} />
              )}
            </div>

            <div className="dates-container">
              {showEventInfo ? (
                <>
                  <p>
                    <strong>{i18n.t('landing.YourReportIntervalDate')}</strong>
                  </p>
                  <p>{leadsPageConfig.startDate}</p>
                  <p className="dates-separator">-</p>
                  <p>{leadsPageConfig.endDate}</p>
                </>
              ) : (
                <>
                  <p>
                    <strong>
                      {i18n.t('landing.YourReportIntervalDateDefault', {
                        event: 'CYBER GUARDIAN',
                      })}
                    </strong>
                  </p>
                  <p className="dates-separator">-</p>
                  <p>{i18n.t('landing.getYourOnlineReport')}</p>
                </>
              )}
            </div>
          </div>
        </div>
      </header>

      <section className="top-info-container">
        <div className="left-container">
          <div className="left-container-content">
            <h1>{i18n.t('landing.receiveYourReportTitle')}</h1>
            <p>{i18n.t('landing.receiveYourReportSubtitle')}</p>

            <img src={reports} alt="Cyber Guardian reporte" />

            <div className="report-contain-container">
              <h2>{i18n.t('landing.whatDoesYourReportContain')}</h2>

              <div className="feature-checks-container">
                {WHAT_DOES_YOUR_REPORT_CONTAIN_CONTENT.map((reportData) => {
                  return (
                    <div className="assessment-check">
                      <img
                        src="https://storage.googleapis.com/cyberguardian-assets/favicon.png"
                        width="22"
                        alt="Cyber Guardian check"
                      />
                      <p>{reportData}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="right-container">
          <div className="right-container-content">
            <LandingForm landingPageConfig={leadsPageConfig} />
          </div>
        </div>
      </section>

      <ContactLegalInformation />
    </SCLeadsPage>
  );
};

export default LeadsPage;
