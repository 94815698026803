import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import * as teamMembersService from '../../services/teamMembers/teamMembers.service';
import { TEAM_MEMBERS_ROLES } from '../../utils/constants/teamMembers';
import { ERRORS } from '../../utils/constants/errors';
import securityScanService from '../../services/securityScan/securityScan.service';

const useTeamMembers = () => {
  const teamMembers = useSelector((redux) => redux.teamMembers);
  const client = useSelector((redux) => redux.client);
  const dispatch = useDispatch();
  const i18n = useTranslation();

  const [loading, setLoading] = useState(false);

  const numAdmins = useMemo(
    () => teamMembers.filter((item) => item?.role?.admin).length,
    [teamMembers]
  );

  const addTeamMember = (teamMember, shouldGoToTag) => {
    const numTeamMembers = teamMembers.filter(
      (item) => item?.role?.admin === false
    ).length;

    if (
      teamMember?.role === TEAM_MEMBERS_ROLES.ADMIN &&
      numAdmins >= client?.status?.serviceLevel.limits.maxAdmins
    ) {
      dispatch(showPopUp('maxAdminsInEmailList'));
      return;
    }

    if (
      teamMember?.role !== TEAM_MEMBERS_ROLES.ADMIN &&
      numTeamMembers >= client?.status?.serviceLevel.limits.maxTeamMembers
    ) {
      dispatch(showPopUp('maxUsersInEmailList'));
      return;
    }

    setLoading(true);

    teamMembersService
      .addTeamMember(teamMember)
      .then(() => {
        securityScanService.runSecurityScan({
          atEmail: client.atEmail,
          emails: [teamMember.email],
        });

        dispatch(showPopUp(null));

        if (shouldGoToTag) {
          const hashedEmail = CryptoJS.SHA224(teamMember.email).toString(
            CryptoJS.enc.Hex
          );

          dispatch(showPopUp('tagPicker', [hashedEmail]));
        }
      })
      .catch((error) => {
        dispatch(
          showPopUp('notification', {
            notificationType: 'error',
            title: i18n.t('common.error'),
            closeButtonText: i18n.t('common.close'),
            text:
              error.response.data === ERRORS.MEMBER_ALREADY_EXIST
                ? i18n.t('manageEmailList.error.memberAlreadyExists')
                : i18n.t('common.errorTryLater'),
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addMultipleTeamMembers = (teamMembersArray) => {
    const MAX_LIMIT = 100;

    if (!teamMembersArray) return;
    setLoading(true);
    const deferred = teamMembersArray.length > MAX_LIMIT;
    teamMembersService
      .addMultipleTeamMembers(teamMembersArray, deferred)
      .then((response) => {
        if (response.status === 200) {
          if (deferred) {
            dispatch(
              showPopUp('notification', {
                notificationType: 'success',
                title: i18n.t('common.processingRequest'),
                text: i18n.t('manageEmailList.processingRequestText'),
              })
            );
          } else {
            dispatch(
              showPopUp('notification', {
                notificationType: 'success',
                title: i18n.t('common.processedRequest'),
                text: i18n.t('manageEmailList.processedRequestText'),
              })
            );
          }
        }
      })
      .catch((e) => {
        const hasInvalidEmail = (errorObject) => {
          return Object.values(errorObject).some(
            (value) => value === 'invalid_email'
          );
        };

        const hasMaxTeamMembers = (errorObject) => {
          return Object.values(errorObject).some(
            (value) => value === 'max_team_members'
          );
        };

        if (hasInvalidEmail(e.response.data)) {
          dispatch(
            showPopUp('notification', {
              notificationType: 'error',
              title: i18n.t('common.error'),
              text: i18n.t('teamMembers.invalidEmail'),
            })
          );
        }

        if (hasMaxTeamMembers(e.response.data)) {
          dispatch(showPopUp('maxUsersInEmailList'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editTeamMember = (teamMember) => {
    if (
      teamMember?.role === TEAM_MEMBERS_ROLES.ADMIN &&
      numAdmins >= client?.status?.serviceLevel.limits.maxAdmins
    ) {
      dispatch(showPopUp('maxAdminsInEmailList'));
      return null;
    }

    setLoading(true);

    teamMembersService
      .editTeamMember(teamMember)
      .then(() => {
        dispatch(showPopUp(null));
      })
      .catch((e) => {
        console.log('There was an error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { loading, addTeamMember, addMultipleTeamMembers, editTeamMember };
};

export default useTeamMembers;
