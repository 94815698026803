import { SET_PARTNER_CONFIG } from '../actions/types';

const partnerConfigReducer = (state = null, action) => {
  switch (action.type) {
    case SET_PARTNER_CONFIG:
      return action.payload || null;
    default:
      return state;
  }
};

export default partnerConfigReducer;
