import styled from '@emotion/styled';

const SCRadioButton = styled.span`
  display: inline-block;
  height: 20px;
  width: 20px;
  background-color: ${({ isChecked }) =>
    isChecked ? 'var(--red)' : 'var(--light-grey-2)'};
  border-radius: 50%;
  vertical-align: text-bottom;
  cursor: pointer;
  transition: var(--transition-delay);
`;

export default SCRadioButton;
