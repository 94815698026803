import styled from '@emotion/styled';
import { css } from '@emotion/core';

const sided = () => css`
  display: flex;
  justify-content: space-between;
`;

const defaultLayout = () => css`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const layouts = {
  sided,
  defaultLayout,
};

const SCTextImage = styled.div`
  ${({ imgSided }) => (imgSided ? layouts.sided : defaultLayout)};

  .texts-container {
    width: ${({ imgSided }) => (imgSided ? '40%' : '')};
    text-align: ${({ imgSided }) => (imgSided ? 'left' : 'center')};
  }

  & h2 {
    font-size: 30px;
    margin-bottom: 8px;
  }

  & h3 {
    font-family: var(--font1);
    font-size: 14px;
  }

  & img {
    max-width: ${({ imgWidth }) => imgWidth || '100%'};
  }
  & p {
    margin-bottom: 25px;
  }

  .img-container {
    height: ${({ imgHeight }) => imgHeight ?? '100%'};
    min-height: ${({ imgHeight }) => imgHeight ?? '100%'};
    width: ${({ imgWidth }) => imgWidth ?? '100%'};
    background-origin: content-box;
    background-repeat: no-repeat;
    padding: ${({ imgPadding }) => imgPadding ?? ''};
    border-radius: 5px;
    background-image: url(${({ img }) => `${img}`});
    background-size: 100%;
    background-position: ${({ backgroundPosition }) =>
      backgroundPosition ?? ''};
  }

  @media (width < 769px) {
    padding: 0 30px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 60px;

    .img-container {
      width: calc(100% + 60px);
      transform: translateX(-30px);
      height: 340px;
      background-size: cover;
      background-position: 100%;
      border-radius: 0;
    }
  }
`;

export default SCTextImage;
