import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../i18n';
import TagLabel from '../../atoms/TagLabel/TagLabel';
import SCTagList from './TagList.style';

const TagList = ({ teamMember, cell, isErasable }) => {
  const i18n = useTranslation();
  const { tags = [] } = useSelector((redux) => redux.client);

  return (
    <SCTagList cell={cell}>
      {(teamMember.role.admin || teamMember.role.member) && (
        <TagLabel
          value={
            teamMember.role.admin
              ? i18n.t(`manageEmailList.administrator`)
              : i18n.t(`manageEmailList.teamMember`)
          }
          color="#000016"
        />
      )}

      {teamMember?.tags?.length > 0 &&
        teamMember.tags.map((tag) => {
          const currentTag = tags.find(
            (availableTag) => availableTag.id === tag
          );
          return (
            <TagLabel
              value={currentTag?.name}
              color={currentTag?.color}
              isErasable={isErasable}
            />
          );
        })}
    </SCTagList>
  );
};

export default TagList;
