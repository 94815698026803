import styled from '@emotion/styled';
import { css } from '@emotion/core';

const openedStyles = () => css`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  opacity: 1;
  pointer-events: all;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: all 225ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
`;

const SCMobileMenuDropDownItem = styled.div`
  width: 100%;

  .dropdown-sidebar {
    height: 0;
    opacity: 0;
    cursor: default;
    pointer-events: none;
    transition: all 225ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transform: translate3d(0px, -20px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;

    ${({ opened }) => (opened ? openedStyles : '')};
  }
`;

export default SCMobileMenuDropDownItem;
