export const getRisk = (risk) => {
  const number = risk;
  if (number === -1 || (number !== 0 && !number) || number > 10)
    return 'pending';
  if (number >= 0 && number < 2.5) {
    return 'highRisk';
  }
  if (number >= 2.5 && number < 5) {
    return 'mediumRisk';
  }

  if (number >= 5 && number < 7.5) {
    return 'lowRisk';
  }
  return 'noRisk';
};

export const getRiskColor = (score) => {
  if (score === -1 || (score !== 0 && !score) || score > 10)
    return 'var(--black)';
  if (score >= 0 && score < 2.5) {
    return 'var(--red)';
  }
  if (score >= 2.5 && score < 5) {
    return 'var(--light-red3)';
  }

  if (score >= 5 && score < 7.5) {
    return 'var(--orange)';
  }
  return 'var(--green)';
};

export const getWebsiteScore = (domainResults) => {
  if (!domainResults || !domainResults.date) return -1;
  let scoreA = 0;
  let count = 0;
  if (domainResults.clickjacking && domainResults.clickjacking.score) {
    scoreA += domainResults.clickjacking.score;
    count += 1;
  }
  if (
    domainResults.man_in_the_middle &&
    domainResults.man_in_the_middle.score
  ) {
    scoreA += domainResults.man_in_the_middle.score;
    count += 1;
  }
  if (domainResults.xss && domainResults.xss.score) {
    scoreA += domainResults.xss.score;
    count += 1;
  }
  if (count === 0) return -1;
  return scoreA / count;
};

export const getEmailSecurityScore = (domainResults) => {
  if (!domainResults || !domainResults.date) return -1;
  let scoreA = 0;
  let count = 0;
  if (
    domainResults.credentials_stuffing &&
    domainResults.credentials_stuffing.score
  ) {
    scoreA += domainResults.credentials_stuffing.score;
    count += 1;
  }
  if (domainResults.phishing && domainResults.phishing.score) {
    scoreA += domainResults.phishing.score;
    count += 1;
  }
  if (count === 0) return -1;
  return scoreA / count;
};

export const generatePassword = (length, symbols) => {
  let result = '';
  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowCase = 'abcdefghijklmnopqrstuvwxyz';
  const digit = '0123456789';
  const set = [upperCase, lowCase, digit];

  for (let i = 0; i < length; i += 1) {
    result += set[i % 3].charAt(Math.floor(Math.random() * set[i % 3].length));
  }
  result = result.split('');

  for (let i = length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    const tmp = result[i];
    result[i] = result[j];
    result[j] = tmp;
  }
  return result.join('');
};

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2]);
};

export const simpleIndustries = [
  {
    id: 'setup.company.industries.Agriculture, Forestry and Fishing',
    label: 'Agriculture, Forestry and Fishing',
  },
  {
    id: 'setup.company.industries.Mining and Quarrying',
    label: 'Mining and Quarrying',
  },
  {
    id: 'setup.company.industries.Manufacturing (excluding pharma & chemicals) and mechanical engineering',
    label:
      'Manufacturing (excluding pharma & chemicals) and mechanical engineering',
  },
  {
    id: 'setup.company.industries.Manufacturing – pharma & chemicals & biotech',
    label: 'Manufacturing – pharma & chemicals & biotech',
  },
  {
    id: 'setup.company.industries.Utilities',
    label: 'Utilities',
  },
  {
    id: 'setup.company.industries.Construction',
    label: 'Construction',
  },
  {
    id: 'setup.company.industries.Retail & wholesale',
    label: 'Retail & wholesale',
  },
  {
    id: 'setup.company.industries.Aviation',
    label: 'Aviation',
  },
  {
    id: 'setup.company.industries.Transport (excl aviation)',
    label: 'Transport (excl aviation)',
  },
  {
    id: 'setup.company.industries.Hotel / accommodation, restaurants and food service activities',
    label: 'Hotel / accommodation, restaurants and food service activities',
  },
  {
    id: 'setup.company.industries.Telecommunications',
    label: 'Telecommunications',
  },
  {
    id: 'setup.company.industries.Information technology (excl telco)',
    label: 'Information technology (excl telco)',
  },
  {
    id: 'setup.company.industries.Financial services (excl accounting)',
    label: 'Financial services (excl accounting)',
  },
  {
    id: 'setup.company.industries.Real estate services',
    label: 'Real estate services',
  },
  {
    id: 'setup.company.industries.Professional, scientific and technical activities',
    label: 'Professional, scientific and technical activities',
  },
  {
    id: 'setup.company.industries.Consulting',
    label: 'Consulting',
  },
  {
    id: 'setup.company.industries.Law firms, notaries',
    label: 'Law firms, notaries',
  },
  {
    id: 'setup.company.industries.Accountants',
    label: 'Accountants',
  },
  {
    id: 'setup.company.industries.Government',
    label: 'Government',
  },
  {
    id: 'setup.company.industries.Education',
    label: 'Education',
  },
  {
    id: 'setup.company.industries.Health and care service providers',
    label: 'Health and care service providers',
  },
  {
    id: 'setup.company.industries.Charity',
    label: 'Charity',
  },
  {
    id: 'setup.company.industries.Arts, entertainment and recreation',
    label: 'Arts, entertainment and recreation',
  },
  {
    id: 'setup.company.industries.Other',
    label: 'Other',
  },
  {
    id: 'setup.company.industries.Other services',
    label: 'Other services',
  },
];

export function datediff(first, second) {
  return Math.ceil(
    (second.getTime() - first.getTime()) / (1000 * 60 * 60 * 24)
  );
}

export default false;
