import { useDispatch, useSelector } from 'react-redux';
import * as subscriptionService from '../../services/subscriptions/subscription.service';
import { setPaymentMethodsAction } from '../../redux/actions/paymentMethods.action';

function usePaymentMethods() {
  const dispatch = useDispatch();

  const paymentMethods = useSelector((redux) => redux.paymentMethods);

  const getClientCards = async () => {
    const { data } = await subscriptionService.getClientCards();
    dispatch(setPaymentMethodsAction(data?.data?.cards));
  };
  return {
    getClientCards,
    paymentMethods,
  };
}

export default usePaymentMethods;
