import { faList } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useBreachNotificationType from '../../../hooks/breachNotificationType/useBreachNotificationType';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import SCBreachesByEmployeeCard from './BreachesByEmployeeCard.style';
import { getParameterByName } from '../../../utils/helper';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import TableContainer from '../../molecules/TableContainer/TableContainer';
import { getDateFormat, getDateInMs } from '../../../utils/format/dataFormat';

const BreachesByEmployeeCard = () => {
  const i18n = useTranslation();
  const { breachesByEmployee } = useBreachNotificationType();
  const activeEmail = getParameterByName('email', window.location.href);
  const navigate = useNavigate();
  // Constante con información para pintar los headers de la tabla
  const columns = [
    {
      name: i18n.t('breachNotification.breachTable.headers.member.name'),
    },
    {
      name: i18n.t('breachNotification.breachTable.headers.member.date'),
    },
    {
      name: i18n.t('breachNotification.breachTable.headers.member.classes'),
    },
  ];

  const mapperFn = (item) => {
    return {
      name: { type: 'string', value: item.name, sortValue: item.name },
      date: {
        type: 'string',
        value: getDateFormat(item.date.toDate()),
        sortValue: getDateInMs(item.date.toDate()),
      },
      classes: { type: 'string', value: item.classes, sortValue: item.classes },
      onClick: () => navigate(`/client/data-alert?breach=${item.name}`),
    };
  };
  const PAGE_SIZE = 10;
  const defaultSortDirection = 'desc';
  const sortOptions = {
    activeSortedColumnName: i18n.t(
      'breachNotification.breachTable.headers.member.date'
    ),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 1,
    onSort: (data) => {
      setSort(data);
    },
  };
  const {
    currentPage,
    setSort,
    setSearch,
    setSearchTags,
    setPage,
    rows,
    length,
  } = useFilterTable(
    PAGE_SIZE,
    mapperFn,
    breachesByEmployee,
    ['name'],
    sortOptions
  );

  return (
    <SCBreachesByEmployeeCard>
      <SectionCard
        headerIcon={faList}
        headerTitle={`${i18n.t(
          'breachNotification.breachTable.categories.breaches'
        )} '${activeEmail}'`}>
        <TableContainer
          emptyMessage={i18n.t(
            'breachNotification.breachTable.emptyMessage.noBreaches'
          )}
          onChangeValue={setSearch}
          columns={columns}
          rows={rows}
          onPageChange={(page) => setPage(page)}
          hasHorizontalScroll
          sortOptions={sortOptions}
          totalCount={length}
          currentPage={currentPage}
          pageSize={PAGE_SIZE}
        />
      </SectionCard>
    </SCBreachesByEmployeeCard>
  );
};

export default BreachesByEmployeeCard;
