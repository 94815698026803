import styled from '@emotion/styled';

const SCRegionSelector = styled.div`
  .region-select-container {
    display: flex;
    text-align: left;
    gap: 5px;
    align-items: center;
    > p {
      color: var(--bluish-grey);
      font-weight: 500;
      margin-right: 5px;
    }
    img {
      width: 14px;
      height: 14px;
      border-radius: 50vh;
    }
    .region-select {
      height: 25px;
      padding-left: 12px;
      padding-right: 30px;
      border: none;
      border-radius: 50vh;
      font-family: var(--font1);
      font-size: 14px;
      font-weight: 500;
      color: var(--black);
      min-width: 100%;
      background: url('data:image/svg+xml;utf8,<svg style="filter: invert(63%) black(8%) saturate(749%) hue-rotate(169deg) brightness(83%) contrast(85%)" xmlns="http://www.w3.org/2000/svg" width="22.5" height="22.5" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"/></svg>')
          no-repeat,
        var(--light-grey);
      background-size: 18px;
      background-position-x: calc(100% - 8px);
      background-position-y: 4px;
    }
  }
`;

export default SCRegionSelector;
