import styled from '@emotion/styled';

const SCLabelDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  b {
    display: inline-block;
    align-items: center;
    font-family: var(--font2);
    font-size: 14px;
    color: var(--black);
    margin-right: 15px;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export default SCLabelDetail;
