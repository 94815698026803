import styled from '@emotion/styled';

const SCListOfPaymentMethods = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  .PaymentMethod_Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    border-radius: 10px;

    .PaymentMethod_Info {
      display: flex;
      flex-direction: column;
      width: 100%;

      > p:last-of-type {
        color: var(--grey-2);
      }
    }
  }
`;

export default SCListOfPaymentMethods;
