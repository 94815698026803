import styled from '@emotion/styled';

const SCDeviceSecuritySection = styled.div`
  .control-panel-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 60px;

    > div {
      flex: 1 0 calc(50% - 10px);
      min-width: 300px;
      max-width: 470px;
      position: relative;
    }

    &.full > div {
      flex: 1 0 100%;
      min-width: 300px;
      max-width: inherit;
    }
  }
`;

export default SCDeviceSecuritySection;
