import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import { useSelector } from 'react-redux';
import en from './locale/en.json';
import es from './locale/es.json';
import pt from './locale/pt-BR.json';
import { auth } from './firebase';

class I18n {
  constructor(lang) {
    if (lang) {
      this.getTranslation(lang);
    }
    this.fallbackTranslation = en;
  }

  getLanguage() {
    return this.lang;
  }

  changeLanguage(lang) {
    this.lang = lang;
    moment.locale(lang);
    this.getTranslation(lang);
  }

  getTranslation(lang) {
    if (lang === 'es') {
      this.translationObject = es;
      this.lang = 'es';
      return;
    }
    if (lang === 'en') {
      this.translationObject = en;
      this.lang = 'en';
      return;
    }
    if (lang === 'pt-BR') {
      this.translationObject = pt;
      this.lang = 'pt-BR';
      return;
    }
    this.translationObject = en;
    this.lang = 'en';
  }

  t(key, options = null) {
    const keys = key.split('.');
    let text = '';
    let tmpObj = this.translationObject;
    for (let i = 0; i < keys.length; i += 1) {
      if (tmpObj && tmpObj[keys[i]]) {
        tmpObj = tmpObj[keys[i]];
      } else {
        tmpObj = undefined;
      }
    }
    if (tmpObj) {
      text = tmpObj;
    }
    if (!text) {
      tmpObj = this.fallbackTranslation;
      for (let i = 0; i < keys.length; i += 1) {
        if (tmpObj && tmpObj[keys[i]]) {
          tmpObj = tmpObj[keys[i]];
        } else {
          tmpObj = undefined;
        }
      }
    }
    if (tmpObj) {
      text = tmpObj;
    }
    if (text && options && typeof options === 'object') {
      Object.keys(options).forEach((attr) => {
        const reg = new RegExp(`{{${attr}}}`, 'g');
        text = text.replace(reg, options[attr]);
      });
    }
    return text;
  }
}

const setLanguageByStorage = (newLanguage) => {
  return localStorage.setItem('language', newLanguage);
};

const getLanguageByStorage = () => {
  return localStorage.getItem('language');
};

const getInitialLanguage = (navigatorLanguage) => {
  if (navigatorLanguage === 'pt-BR') {
    return navigatorLanguage;
  }

  return navigator.language.split('-')[0];
};

export const i18n = new I18n(
  getLanguageByStorage() ?? getInitialLanguage(navigator.language)
);
const initialState = { i18n };

export const I18nContext = React.createContext(initialState);

export const I18nContextProvider = ({ children }) => {
  const [lang, setLang] = useState();

  const myUserPreferences = useSelector((redux) => redux.myUserPreferences);

  const setLanguageCustom = (language) => {
    if (window.OneTrust) {
      window.OneTrust.changeLanguage(language);
    }
    setLanguageByStorage(language);
    i18n.changeLanguage(language);
    auth.languageCode = language;
    setLang(language);
  };

  // El idioma de BD predomina sobre el local
  useEffect(() => {
    if (myUserPreferences && myUserPreferences?.language !== lang) {
      setLanguageCustom(myUserPreferences?.language);
    }
  }, [myUserPreferences?.language]);

  useEffect(() => {
    setLanguageCustom(i18n.getLanguage());
  }, []);

  return (
    <I18nContext.Provider
      value={{
        i18n,
        lang,
        setLanguageCustom,
      }}>
      {children}
    </I18nContext.Provider>
  );
};

export const useTranslation = () => {
  const translation = useContext(I18nContext).i18n;
  return translation;
};
