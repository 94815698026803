import styled from '@emotion/styled';

const SCAnyDoubts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  > h3 {
    font-family: var(--font1);
    color: var(--bluish-grey);
  }

  .buttons-container {
    display: flex;
    gap: 20px;
  }

  @media (max-width: 768px) {
    .buttons-container {
      flex-direction: column;
    }
  }
`;

export default SCAnyDoubts;
