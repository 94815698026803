import styled from '@emotion/styled';
import React from 'react';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';
import { useTranslation } from '../../../i18n';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import usePaymentAccess from '../../../hooks/paymentAccess/usePaymentAccess';
import { hasPaymentAccess } from '../../../utils/functions/subscriptions';

const SCMaxDomainsPopUp = styled.div`
  .icon-container {
    border-radius: 50vh;
    border: 5px solid ${({ color }) => color || 'inherit'};
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    div {
      font-family: var(--font1);
      color: var(--black);
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const MaxDomainsPopUp = () => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const client = useSelector((redux) => redux.client);
  const { handlePaymentAccess } = usePaymentAccess();

  return (
    <ModalLayout>
      <SCMaxDomainsPopUp>
        <h1>{i18n.t('welcome.restrictions.maxUsersInEmailList_title')}</h1>

        <div className="icon-container">
          <Icon iconName={faLock} size="large" color="var(--bluish-grey)" />
        </div>
        <div className="modal-card-content">
          <div className="text-container">
            <div>
              {i18n.t('welcome.restrictions.maxWebsites', {
                max: client?.status?.serviceLevel.limits.maxDomains,
              })}
            </div>

            <div>{i18n.t('welcome.restrictions.restrictedPageParagraph')}</div>
          </div>
        </div>
        <div className="modal-card-buttons">
          {hasPaymentAccess() && (
            <Button
              size="full"
              color="bluishGrey"
              text={i18n.t('welcome.restrictions.upgradeToday')}
              onClick={() => {
                dispatch(showPopUp(null));
                handlePaymentAccess();
              }}
            />
          )}
          <Button
            size="full"
            color="white"
            text={i18n.t('welcome.goBack')}
            onClick={() => {
              dispatch(showPopUp(null));
            }}
          />
        </div>
      </SCMaxDomainsPopUp>
    </ModalLayout>
  );
};

export default MaxDomainsPopUp;
