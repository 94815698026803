import React from 'react';
import useInternationalization from '../../../hooks/Internationalization/useInternationalization';
import { useTranslation } from '../../../i18n';
import WrapperImage from '../../organisms/PopUp/WrapperImage';
import whitelistingImages from '../../../utils/internationalizationModules/whitelistingImages';
import SCExchangeGuide from './ExchangeGuide.style';

const ExchangeGuide = () => {
  const i18n = useTranslation();
  const { internationalizedImgLang } = useInternationalization();
  const whitelistingImagesI18n = whitelistingImages[internationalizedImgLang];

  return (
    <SCExchangeGuide>
      <h3>
        {i18n.t(
          'whitelisting.guideMicrosoftExchange.stepAllowEmails.stepTitle'
        )}
      </h3>
      <p>
        <div>
          {i18n.t('whitelisting.guideMicrosoftExchange.stepAllowEmails.logon')}{' '}
          <a
            href="https://portal.office.com/adminportal"
            target="_blank"
            rel="noreferrer">
            https://portal.office.com/adminportal
          </a>
        </div>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                'whitelisting.guideMicrosoftExchange.stepAllowEmails.navigate'
              ),
            }}
          />
          <WrapperImage src={whitelistingImagesI18n.whitelistingExchange1} />
          <WrapperImage src={whitelistingImagesI18n.whitelistingExchange2} />
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: i18n.t(
              'whitelisting.guideMicrosoftExchange.stepAllowEmails.edit'
            ),
          }}
        />
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                'whitelisting.guideMicrosoftExchange.stepAllowEmails.addIP'
              ),
            }}
          />
          <WrapperImage src={whitelistingImagesI18n.whitelistingExchange3} />
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: i18n.t(
              'whitelisting.guideMicrosoftExchange.stepAllowEmails.save'
            ),
          }}
        />
      </p>
      <h3>
        {i18n.t(
          'whitelisting.guideMicrosoftExchange.stepPreventWarnings.stepTitle'
        )}
      </h3>
      <p>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                'whitelisting.guideMicrosoftExchange.stepPreventWarnings.createRule'
              ),
            }}
          />
          <WrapperImage src={whitelistingImagesI18n.whitelistingExchange4} />
        </div>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                'whitelisting.guideMicrosoftExchange.stepPreventWarnings.options'
              ),
            }}
          />
          <WrapperImage src={whitelistingImagesI18n.whitelistingExchange5} />
        </div>

        <div>
          {i18n.t(
            'whitelisting.guideMicrosoftExchange.stepPreventWarnings.setOption'
          )}
          <ul>
            <li
              dangerouslySetInnerHTML={{
                __html: i18n.t(
                  'whitelisting.guideMicrosoftExchange.stepPreventWarnings.setOptionName'
                ),
              }}
            />
            <li>
              <span
                dangerouslySetInnerHTML={{
                  __html: i18n.t(
                    'whitelisting.guideMicrosoftExchange.stepPreventWarnings.setOptionCondition'
                  ),
                }}
              />
              <WrapperImage
                src={whitelistingImagesI18n.whitelistingExchange6}
              />
            </li>
            <li
              dangerouslySetInnerHTML={{
                __html: i18n.t(
                  'whitelisting.guideMicrosoftExchange.stepPreventWarnings.setOptionProperties'
                ),
              }}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: i18n.t(
                  'whitelisting.guideMicrosoftExchange.stepPreventWarnings.setOptionHeader'
                ),
              }}
            />
            <li>
              <span
                dangerouslySetInnerHTML={{
                  __html: i18n.t(
                    'whitelisting.guideMicrosoftExchange.stepPreventWarnings.setOptionAction'
                  ),
                }}
              />
              <WrapperImage
                src={whitelistingImagesI18n.whitelistingExchange7}
              />
            </li>
            <li>
              <span
                dangerouslySetInnerHTML={{
                  __html: i18n.t(
                    'whitelisting.guideMicrosoftExchange.stepPreventWarnings.setOptionSave'
                  ),
                }}
              />
              <WrapperImage
                src={whitelistingImagesI18n.whitelistingExchange8}
              />
            </li>
          </ul>
        </div>
      </p>
    </SCExchangeGuide>
  );
};

export default ExchangeGuide;
