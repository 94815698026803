import React from 'react';
import {
  faArrowUpRightFromSquare,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { faGear } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'antd';
import SectionHeader from '../SectionHeader/SectionHeader';
import { isNonCountryEnvironment } from '../../../utils/locale';
import getCompanyIDExample from '../../../utils/internationalizationModules/companyIdLabels/companyIdLabels';
import { useTranslation } from '../../../i18n';
import SCPartnerClientControlPanelHeader from './PartnerClientControlPanelHeader.style';
import Button from '../../atoms/Button/Button';
import { isPartnerType } from '../../../utils/functions/partners';
import { PARTNER_TYPES } from '../../../utils/constants/partners';
import { showPopUp } from '../../../redux/actions/popUp.actions';

const PartnerClientControlPanelHeader = ({
  partnerClient,
  fetchPartnerClientsData,
  loading,
  hasPadding,
}) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const client = useSelector((redux) => redux.client);

  const getHref = () => {
    const partnerClientId = partnerClient?.id;
    const { id: clientId } = client;
    const baseHref = '/client';

    return partnerClientId === clientId
      ? baseHref
      : `${baseHref}?id=${partnerClientId}`;
  };

  const isPartnerMSSP = isPartnerType(PARTNER_TYPES.MSSP);

  return (
    <SCPartnerClientControlPanelHeader hasPadding={hasPadding}>
      <SectionHeader
        icon={faUser}
        // Si es un entorno de país, se añade el companyID
        {...(!isNonCountryEnvironment && {
          title: `${getCompanyIDExample().name}: ${
            partnerClient?.company?.cif
          }`,
        })}
        subtitle={`${i18n.t('common.client')}: ${partnerClient?.company?.name}`}
        loading={loading}
      />
      {isPartnerMSSP && !loading && (
        <div className="actions-buttons-container">
          <Tooltip
            title={i18n.t(
              'partner.manageSubscriptionPopUp.manageSubscription'
            )}>
            <Button
              size="fit"
              color="bluishGrey"
              icon={faGear}
              onClick={() =>
                dispatch(
                  showPopUp('manageSubscription', {
                    client: partnerClient,
                    fetchPartnerClientsData,
                  })
                )
              }
            />
          </Tooltip>

          <Tooltip title={i18n.t('partner.consoleAccess')}>
            <a
              href={getHref()}
              target="_blank"
              aria-label="Link to client console"
              rel="noreferrer">
              <Button
                size="fit"
                color="bluishGrey"
                icon={faArrowUpRightFromSquare}
              />
            </a>
          </Tooltip>
        </div>
      )}
    </SCPartnerClientControlPanelHeader>
  );
};

export default PartnerClientControlPanelHeader;
