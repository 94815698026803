import React from 'react';
import SCProgressBar from './ProgressBar.style';

const ProgressBar = ({
  height,
  backgroundColor,
  barColor,
  hideXAxis,
  completed,
  toCompleted,
  total,
  displayCircle = false,
}) => (
  <SCProgressBar
    height={height}
    backgroundColor={backgroundColor}
    barColor={barColor}
    completed={completed}
    toCompleted={toCompleted}
    total={total}>
    {!hideXAxis && (
      <div className="progress-bar-x-axis-container">
        <div>0%</div>
        <div>25%</div>
        <div>50%</div>
        <div>75%</div>
        <div>100%</div>
      </div>
    )}
    <div className="progress-bar-container">
      <div className="progress-bar-background">
        <div className="progress-bar-content">
          {displayCircle && <div className="progress-bar-circle" />}
        </div>
        <div className="progress-bar-content-2">
          {displayCircle && <div className="progress-bar-circle" />}
        </div>
      </div>
    </div>
  </SCProgressBar>
);

export default ProgressBar;
