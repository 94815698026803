import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { showPopUp } from '../../../redux/actions/popUp.actions';

import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';
import useMonitoredDomains from '../../../hooks/monitoredDomains/useMonitoredDomains';
import SpinnerText from '../../atoms/SpinnerText/SpinnerText';

const SCRemoveWebsite = styled.div`
  h1 {
    text-align: center;
    font-family: var(--font1);
    font-size: 25px;
    font-weight: 500;
  }
`;

const RemoveWebsite = ({ domain }) => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const { loading, deleteMonitoredDomain } = useMonitoredDomains();

  return (
    <ModalLayout>
      <SCRemoveWebsite>
        <h1>{i18n.t('websiteSecurity.delete.question')}</h1>
        <div className="modal-card-buttons">
          <Button
            text={
              loading ? (
                <SpinnerText text={i18n.t('common.loading')} />
              ) : (
                i18n.t('websiteSecurity.delete.confirm')
              )
            }
            onClick={() => deleteMonitoredDomain(domain)}
            disabled={loading}
            size="full"
            color="bluishGrey"
          />
          <Button
            text={i18n.t('websiteSecurity.delete.cancel')}
            onClick={() => dispatch(showPopUp(null))}
            size="full"
            color="white"
          />
        </div>
      </SCRemoveWebsite>
    </ModalLayout>
  );
};

export default RemoveWebsite;
