import styled from '@emotion/styled';

const SCButtonBubble = styled.button`
  border-radius: 50vh;
  width: 25px;
  height: 25px;
  background: ${({ tagStyle }) => tagStyle.backgroundColor};
  color: ${({ tagStyle }) => tagStyle.color};
  border: 1px solid ${({ tagStyle }) => tagStyle.borderColor};
  display: flex;
  justify-content: center;
  align-items: center;

  :focus {
    outline: none;
  }
  svg {
    font-size: 15px;
    font-weight: 600;
  }
`;

export default SCButtonBubble;
