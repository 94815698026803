/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch } from 'react-redux';
import SCQualificationFormQuestion from './QualificationFormQuestion.style';
import { showPopUp } from '../../../redux/actions/popUp.actions';

const QualificationFormQuestion = ({
  question,
  currentQAnswers,
  setCurrentQAnnswers,
}) => {
  const dispatch = useDispatch();

  const checkboxChanged = (value) => {
    if (currentQAnswers.includes(value.target.value)) {
      const newResponses = currentQAnswers.filter((response) => {
        return response !== value.target.value;
      });
      setCurrentQAnnswers(newResponses);
    } else {
      setCurrentQAnnswers([...currentQAnswers, value.target.value]);
    }
  };

  const matricialCheckboxChanged = (value, possibleResponses) => {
    const indextoReplace = currentQAnswers.findIndex((response) => {
      return possibleResponses.includes(response);
    });
    if (indextoReplace === -1) {
      setCurrentQAnnswers([...currentQAnswers, value.target.value]);
    } else {
      const auxResponses = [...currentQAnswers];
      auxResponses[indextoReplace] = value.target.value;
      setCurrentQAnnswers(auxResponses);
    }
  };

  const handleMoreInfo = (text) => () => {
    dispatch(
      showPopUp('notification', {
        notificationType: 'info',
        title: 'Más información',
        text,
      })
    );
  };

  const getSingleQuestion = () => {
    return question.options.map((response) => (
      <div className="StepQuestion_Answer">
        <label htmlFor={response.id}>
          <input
            type="radio"
            id={response.id}
            checked={currentQAnswers.includes(response.id)}
            name={response.id}
            value={response.id}
            onChange={() => {
              setCurrentQAnnswers([response.id]);
            }}
          />
          <p>{response.text}</p>
        </label>
        {response.moreInfo && (
          <a onClick={handleMoreInfo(response.moreInfo)}>(+info)</a>
        )}
      </div>
    ));
  };

  const getMultipleQuestion = () => {
    return question.options.map((response) => (
      <div className="StepQuestion_Answer">
        <label htmlFor={response.id}>
          <input
            type="checkbox"
            id={response.id}
            value={response.id}
            checked={currentQAnswers.includes(response.id)}
            defaultChecked={currentQAnswers.includes(response.id)}
            onChange={checkboxChanged}
          />
          <p>{response.text}</p>
        </label>
        {response.moreInfo && (
          <a onClick={handleMoreInfo(response.moreInfo)}>(+info)</a>
        )}
      </div>
    ));
  };

  const getMatricialSubQuestion = (q, shouldShowLabel) => {
    return (
      <div className="StepQuestion_SubAnswers">
        <div className="StepQuestion_Answer">
          <p>{q.text}</p>
          {q.moreInfo && <a onClick={handleMoreInfo(q.moreInfo)}>(+info)</a>}
        </div>

        <div className="StepQuestion_SubAnswersContainer">
          {q.options.map((response) => (
            <div className="StepQuestion_MatricialRow">
              <p style={{ marginBottom: '15px' }}>{response.text}</p>

              <div className="StepQuestion_Answer">
                <input
                  type="radio"
                  id={response.id}
                  checked={currentQAnswers.includes(response.id)}
                  name={response.id}
                  value={response.id}
                  onChange={(value) => {
                    matricialCheckboxChanged(
                      value,
                      q.options.map((res) => {
                        return res.id;
                      })
                    );
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getMatricialQuestion = () => {
    return (
      <div className="StepQuestion_SubQuestion">
        {question.matrixOptions.map((subQuestion, index) => (
          <>{getMatricialSubQuestion(subQuestion, index === 0)}</>
        ))}
      </div>
    );
  };

  const showPossibleResponses = () => {
    let possibleResponses;
    switch (question.type) {
      case 'single':
        possibleResponses = getSingleQuestion();
        break;
      case 'multiple':
        possibleResponses = getMultipleQuestion();
        break;
      case 'matrix':
        possibleResponses = getMatricialQuestion();
        break;
      default:
        possibleResponses = getSingleQuestion();
        break;
    }
    return possibleResponses;
  };

  return (
    <SCQualificationFormQuestion>
      <div className="StepQuestion_Wrapper">
        <div className="StepQuestion_Questions">
          <h2>{question.title}</h2>
        </div>
        <div className="StepQuestion_Answers">
          {showPossibleResponses(question)}
        </div>
      </div>

      <div className="StepQuestion_AnswersDesc">
        {currentQAnswers.length > 0 && question.type !== 'matrix' && (
          <p>
            {
              question.options.find((response) => {
                return (
                  response.id === currentQAnswers[currentQAnswers.length - 1]
                );
              }).description
            }
          </p>
        )}
        {currentQAnswers.length > 0 &&
          question.type === 'matrix' &&
          question.matrixOptions.map((matrixOption) => {
            return (
              <p>
                {
                  matrixOption.options.find((option) => {
                    return currentQAnswers.includes(option.id);
                  })?.description
                }
              </p>
            );
          })}
      </div>
    </SCQualificationFormQuestion>
  );
};

export default QualificationFormQuestion;
