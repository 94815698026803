import baseLineChartOptions from './baseCharts/baseLineChartOptions';
import baseLineChartSeries from './baseCharts/baseLineChartSeries';
import baseDonutChartOptions from './baseCharts/baseDonutChartOptions';
import baseColumnChartOptions from './baseCharts/baseColumnChartOptions';

export function getColumnChartSeries({
  name,
  data,
}: {
  name: string;
  data: any[];
}) {
  return [
    {
      name: name ?? '',
      data: data ?? [],
    },
  ];
}

export function getColumnChartOptions({
  xAxisCategory,
  xAxisLabelFormatter,
  yAxisLabelFormatter,
  fillColors,
  onClickCallback,
}: {
  xAxisCategory: any[];
  xAxisLabelFormatter: any;
  yAxisLabelFormatter: any;
  fillColors: any[];
  onClickCallback: any;
}) {
  return {
    ...baseColumnChartOptions,
    chart: {
      ...baseColumnChartOptions.chart,
      events: {
        click: onClickCallback,
      },
    },
    fill: {
      ...baseColumnChartOptions.fill,
      colors: fillColors,
    },
    xaxis: {
      ...baseColumnChartOptions.xaxis,
      categories: xAxisCategory ?? [],
      labels: {
        ...baseColumnChartOptions.xaxis.labels,
        formatter: xAxisLabelFormatter ?? (() => {}),
      },
    },
    yaxis: {
      ...baseColumnChartOptions.xaxis,
      labels: {
        ...baseColumnChartOptions.yaxis.labels,
        formatter: yAxisLabelFormatter ?? (() => {}),
      },
    },
  };
}

export function getLineChartSeries({
  name,
  data,
}: {
  name: string;
  data: any[];
}) {
  return [
    {
      ...baseLineChartSeries,
      name: name ?? '',
      data: data ?? [],
    },
  ];
}

export function getLineChartOptions({
  xAxisCategory,
  xAxisLabelFormatter,
  yAxisTooltipFormatter,
  yAxisMin,
  yAxisMax,
  fillColors,
  discreteMarkerColors,
  onClickCallback,
}: {
  xAxisCategory: any[];
  xAxisLabelFormatter: any;
  yAxisTooltipFormatter: any;
  yAxisMin: number;
  yAxisMax: number;
  fillColors: any[];
  discreteMarkerColors: any[];
  onClickCallback: any;
}) {
  return {
    ...baseLineChartOptions,
    chart: {
      ...baseLineChartOptions.chart,
      events: {
        click: onClickCallback,
      },
    },
    xaxis: {
      ...baseLineChartOptions.xaxis,
      categories: xAxisCategory ?? [],
      tickAmount: xAxisCategory.length ?? 0,
      labels: {
        ...baseLineChartOptions,
        formatter: xAxisLabelFormatter ?? (() => {}),
        style: {
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      ...baseLineChartOptions.yaxis,
      min: yAxisMin ?? 0,
      max: yAxisMax ?? 10,
    },
    fill: {
      ...baseLineChartOptions.fill,
      gradient: {
        ...baseLineChartOptions.fill.gradient,
        colorStops: fillColors ?? [],
      },
    },
    tooltip: {
      ...baseLineChartOptions.tooltip,
      y: {
        formatter: yAxisTooltipFormatter ?? undefined,
        title: {
          formatter: '',
        },
      },
    },
    markers: {
      ...baseLineChartOptions.markers,
      discrete: discreteMarkerColors ?? [],
    },
  };
}

// Actualmente no se está utilizando
export function getDonutChartSeries(seriesData) {
  return [...seriesData];
}

export function getDonutChartOptions({
  labels,
  colors,
  dataLabelsEnable = true,
  dataLabelsColors,
  legendWidth = 230,
  responsive,
  dataPointSelectionEvent = undefined,
}) {
  return {
    ...baseDonutChartOptions,
    labels: labels ?? [],
    fill: {
      ...baseDonutChartOptions.fill,
      colors: colors ?? [],
    },
    chart: {
      events: {
        dataPointSelection: dataPointSelectionEvent,
        dataPointMouseEnter: (event: any) => {
          event.target.style.cursor = dataPointSelectionEvent
            ? 'pointer'
            : 'auto';
        },
      },
    },
    legend: {
      ...baseDonutChartOptions.legend,
      width: legendWidth,
      markers: {
        ...baseDonutChartOptions.legend.markers,
        fillColors: colors ?? [],
      },
    },
    dataLabels: {
      ...baseDonutChartOptions.dataLabels,
      enabled: dataLabelsEnable,
      style: {
        ...baseDonutChartOptions.dataLabels.style,
        colors: dataLabelsColors ?? [],
      },
    },
    responsive: responsive ?? baseDonutChartOptions.responsive,
  };
}

export function getBarChartOptions({
  categories = [],
  colors = [],
  tooltipFormatter = (seriesName) => `${seriesName}:`,
  valuesFormatter = undefined,
  dataPointSelectionEvent = undefined,
}) {
  return {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: dataPointSelectionEvent,
        dataPointMouseEnter: (event: any) => {
          event.target.style.cursor = dataPointSelectionEvent
            ? 'pointer'
            : 'auto';
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    colors,
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories,
      labels: {
        formatter: valuesFormatter,
      },
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        formatter: valuesFormatter,
        title: {
          formatter: tooltipFormatter,
        },
      },
    },
  };
}
