import { firestore } from '../../firebase';

export const isMaintainMode = async () => {
  const doc = await firestore.collection('config').doc('maintain').get();

  return {
    isMaintainMode: doc.data()?.maintainGlobal,
    code: 200,
  }
}
