import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useBillingAddressValidator from '../clientAddressValidator/useClientAddressValidator';
import { showPopUp } from '../../redux/actions/popUp.actions';

function usePaymentAccess() {
  const client = useSelector((redux) => redux.client);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isBillingAddressComplete } = useBillingAddressValidator();

  const handlePaymentAccess = () => {
    if (isBillingAddressComplete(client.billingAddress)) {
      navigate('/client/contract-subscription');
    } else {
      dispatch(showPopUp('addBillingAddress'));
    }
  };
  return {
    handlePaymentAccess,
  };
}

export default usePaymentAccess;
