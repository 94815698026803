import {
  faBadgeCheck,
  faDisplay,
  faDollyBox,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEnvelopesBulk,
  faGlobe,
  faListUl,
  faMask,
  faRadar,
  faUser,
  faUserSecret,
} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useTranslation } from '../../../i18n';
import Icon from '../../atoms/Icon/Icon';
import SCSectionHeader from './SectionHeader.style';

const SectionHeader = ({ icon, title, subtitle, loading = false }) => {
  const i18n = useTranslation();
  const { pathname } = useLocation();

  const SECTION_HEADER_DATA = {
    '/client/device-security': {
      icon: faDisplay,
      title: i18n.t('sections.protection.name'),
      subtitle: i18n.t('sections.protection.deviceSecurity.name'),
    },
    '/client/managed-threats': {
      icon: faRadar,
      title: i18n.t('sections.protection.name'),
      subtitle: i18n.t('sections.protection.managedThreats.name'),
    },
    '/client/mailbox-security': {
      icon: faEnvelopesBulk,
      title: i18n.t('sections.protection.name'),
      subtitle: i18n.t('sections.protection.mailboxSecurity.name'),
    },
    '/client/configure-email-security': {
      icon: faEnvelopeOpen,
      title: i18n.t('sections.exposure.name'),
      subtitle: i18n.t('sections.exposure.emailSecurity.name'),
    },
    '/client/data-alert': {
      icon: faUserSecret,
      title: i18n.t('sections.exposure.name'),
      subtitle: i18n.t('sections.exposure.dataFiltrations.name'),
    },
    '/client/websitesecurity': {
      icon: faGlobe,
      title: i18n.t('sections.exposure.name'),
      subtitle: i18n.t('sections.exposure.websiteSecurity.name'),
    },
    '/client/domainimpersonation': {
      icon: faMask,
      title: i18n.t('sections.exposure.name'),
      subtitle: i18n.t('sections.exposure.domainImpersonation.name'),
    },
    '/client/supplier-security': {
      icon: faDollyBox,
      title: i18n.t('sections.exposure.name'),
      subtitle: i18n.t('sections.exposure.supplierSecurity.name'),
    },
    '/client/settings': {
      icon: faUser,
      title: i18n.t('profile.menu.settings'),
      subtitle: i18n.t('profile.menu.myAccount'),
    },
    '/client/settings/my-account': {
      icon: faUser,
      title: i18n.t('profile.menu.settings'),
      subtitle: i18n.t('profile.menu.myAccount'),
    },
    '/client/settings/employees': {
      icon: faListUl,
      title: i18n.t('profile.menu.settings'),
      subtitle: i18n.t('profile.menu.employeesLong'),
    },
    '/client/settings/email-preferences': {
      icon: faEnvelopeOpenText,
      title: i18n.t('profile.menu.settings'),
      subtitle: i18n.t('profile.menu.emailPreferences'),
    },
    '/client/settings/billing': {
      icon: faBadgeCheck,
      title: i18n.t('profile.menu.settings'),
      subtitle: i18n.t('profile.menu.mySubscription'),
    },
  };

  const finalTitle = title ?? SECTION_HEADER_DATA[pathname]?.title;
  const finalSubtitle = subtitle ?? SECTION_HEADER_DATA[pathname]?.subtitle;

  return (
    <SCSectionHeader>
      <div className="icon-container">
        <Icon
          size="sectionHeaderIcon"
          iconName={icon ?? SECTION_HEADER_DATA[pathname].icon}
          color="var(--light-grey-4)"
        />
      </div>
      {!loading ? (
        <div className="main-container">
          {finalTitle && (
            <p className="title-container">{`${finalTitle.toUpperCase()} >`}</p>
          )}

          {finalSubtitle && (
            <p className="subtitle-container">{finalSubtitle}</p>
          )}
        </div>
      ) : (
        <div className="skeleton-container">
          <Skeleton
            active
            paragraph={{ rows: 1, width: '15%' }}
            title={false}
          />
          <Skeleton
            active
            paragraph={{ rows: 1, width: '30%' }}
            title={false}
          />
        </div>
      )}
    </SCSectionHeader>
  );
};

export default SectionHeader;
