import React from 'react';
import Icon from '../../atoms/Icon/Icon';
import { Card, Horizontal, SCInfoSummary, Steps } from './InfoSummary.style';

const InfoSummary = ({ icons, titles, subtitles }) => {
  return (
    <SCInfoSummary>
      <Steps>
        {icons.map((item, index) => (
          <>
            <Card key={`infSum${index}`}>
              <div className="tutorial-icon-wrapper">
                <Icon iconName={item} />
              </div>
              <div className="tuturial-step-title">{titles[index]}</div>
              <div className="tuturial-step-subtitle">{subtitles[index]}</div>
            </Card>
            {index < icons.length - 1 && <Horizontal />}
          </>
        ))}
      </Steps>
    </SCInfoSummary>
  );
};

export default InfoSummary;
