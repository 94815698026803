import React from 'react';
import { useNavigate } from 'react-router-dom';
import SCPossibleImpersionationTableCard from './PossibleImpersionationTableCard.style';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { useTranslation } from '../../../i18n';
import Label from '../../atoms/Label/Label';

import TableContainer from '../../molecules/TableContainer/TableContainer';
import {
  getSuspiciousColor,
  getSuspiciousText,
} from '../../../utils/functions/suspiciousSeverity';
import useFilterTable from '../../../hooks/useFilterTable/useFilterTable';
import { getDateFormat, getDateInMs } from '../../../utils/format/dataFormat';

const PossibleImpersonationTableCard = ({
  title,
  icon,
  impersonations,
  domain,
}) => {
  const i18n = useTranslation();
  const navigate = useNavigate();

  const PAGE_SIZE = 10;

  const columns = [
    {
      name: i18n.t('suppliersSecurity.table.headers.domain'),
    },
    {
      name: i18n.t('suppliersSecurity.table.headers.level'),
    },
    {
      name: i18n.t('suppliersSecurity.table.headers.date'),
    },
  ];

  const mapperFn = (item) => {
    return {
      domain: {
        type: 'string',
        value: item.name,
        color: 'var(--black)',
        sortValue: item.name,
      },
      level: {
        type: 'component',
        value: (
          <Label
            value={i18n.t(
              `domainImpersonation.suspiciusLevels.${
                item.score !== 'N/A' ? getSuspiciousText(item.score) : 'unknown'
              }`
            )}
            color={
              item.score !== 'N/A' ? getSuspiciousColor(item.score) : 'default'
            }
          />
        ),
        sortValue: item.score === 'N/A' ? -1 : item.score,
      },
      date: {
        type: 'string',
        value:
          (item.date && getDateFormat(item.date.toDate())) ??
          i18n.t('websiteSecurity.unknown'),
        sortValue: getDateInMs(item.date?.toDate()),
      },
      onClick: () =>
        navigate(
          `/client/impersonation?domain=${item.domainCustomAttr}&website=${item.name}`
        ),
    };
  };
  const defaultSortDirection = 'desc';
  const sortOptions = {
    activeSortedColumnName: i18n.t('suppliersSecurity.table.headers.level'),
    activeSortedColumnDirection: defaultSortDirection,
    activeSortedColumnIndex: 1,
    onSort: (data) => {
      setSort(data);
    },
  };

  const { currentPage, setPage, setSearch, setSort, rows, length } =
    useFilterTable(
      PAGE_SIZE,
      mapperFn,
      impersonations.map((impersonation) => {
        return { ...impersonation, domainCustomAttr: domain };
      }),
      ['name'],
      sortOptions
    );

  return (
    <SCPossibleImpersionationTableCard>
      <SectionCard headerIcon={icon} headerTitle={`${title} (${length})`}>
        <TableContainer
          columns={columns}
          sortOptions={sortOptions}
          rows={rows}
          onChangeValue={setSearch}
          emptyMessage={`${i18n.t(
            'domainImpersonation.table.noData'
          )} ${title}`}
          hasRowIcon
          onPageChange={(page) => setPage(page)}
          totalCount={length}
          currentPage={currentPage}
          pageSize={PAGE_SIZE}
        />
      </SectionCard>
    </SCPossibleImpersionationTableCard>
  );
};

export default PossibleImpersonationTableCard;
