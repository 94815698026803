import axios from 'axios';
import { toBase64 } from '../../utils/functions/base64';

export const isBlacklistedDomain = async (domain) => {
  const encodedDomain = toBase64(domain);
  const response = await axios.get(`/blacklisted-domain/${encodedDomain}`);

  return response;
};

export const isWarnDomain = async (domain) => {
  const encodedAtEmail = toBase64(domain);
  const response = await axios.get(`/warn-email-providers/${encodedAtEmail}`);

  return response;
};
