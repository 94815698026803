import gmailLogo from '../../img/emailProviders/gmail.svg';
import outlookLogo from '../../img/emailProviders/outlook.svg';

const emailProviders = [
  {
    img: gmailLogo,
    spf: { thirtPartySender: '_spf.google.com', policyStrict: 'fail' },
  },
  {
    img: outlookLogo,
    spf: {
      thirtPartySender: 'spf.protection.outlook.com',
      policyStrict: 'fail',
    },
  },
];

export default emailProviders;
