import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import SCConfigureSpf from './configureSpf.style';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import Button from '../../components/atoms/Button/Button';
import ConfigureSpfSteps from '../../components/organisms/ConfigureSpfSteps/ConfigureSpfSteps';
import SectionCard from '../../components/molecules/SectionCard/SectionCard';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import BackButton from '../../components/molecules/BackButton/BackButton';
import { getMappedDnsRecords } from '../../utils/functions/dnsRecords';
import useDnsRecord from '../../hooks/dnsRecord/useDnsRecord';

const ConfigureSpf = () => {
  const { dnsRecord } = useDnsRecord();
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const [stepSPF, setStepSPF] = useState(1);
  const navigate = useNavigate();
  const records = getMappedDnsRecords(dnsRecord);

  const copyToClipboard = () => {
    const textField = document.createElement('textarea');
    textField.innerText = generatedSPF;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  const [SPF, setSPF] = useState({
    areYouResponsible: undefined,
    areYouAbleToSetDNS: undefined,
    thirdPartyTools: undefined,
    allowAnySubdomain: undefined,
    mxRecords: undefined,
    domainMainIP: undefined,
    additionalSenderIPs: undefined,
    thirtPartySender: undefined,
    policyStrict: 'fail',
  });

  const isSpfValid =
    SPF.thirdPartyTools ||
    SPF.allowAnySubdomain ||
    SPF.mxRecords ||
    SPF.domainMainIP ||
    SPF.thirtPartySender ||
    SPF.policyStrict === 'pass';

  const handleButtonNext = (e) => {
    if (stepSPF > 0) {
      if (SPF && (!SPF.areYouResponsible || !SPF.areYouAbleToSetDNS)) {
        dispatch(
          showPopUp('SentEmailTo', {
            type: 'emailSecurity',
          })
        );
      } else {
        setStepSPF(stepSPF + 1);
      }
    }
  };

  const handleButtonPrevious = (e) => {
    e.preventDefault();
    if (stepSPF > 1) {
      setStepSPF(stepSPF - 1);
    }
  };

  const generateSpfRecord = (p) => {
    let genSPF = '';

    if (p.mxRecords) genSPF += ' mx';
    if (!p.thirdPartyTools) {
      if (p.allowAnySubdomain) genSPF += ' a';
      if (p.additionalSenderIPs) {
        p.additionalSenderIPs
          .replace(/\s+/g, '')
          .split(',')
          .forEach((ip) => {
            genSPF += ` ip4:${ip}`;
          });
      }
    }
    if (p.thirtPartySender) {
      p.thirtPartySender
        .replace(/\s+/g, '')
        .split(',')
        .forEach((domain) => {
          genSPF += ` include:${domain}`;
        });
    }

    let qualifier;
    switch (p.policyStrict) {
      case 'fail':
        qualifier = '-';
        break;
      case 'softFail':
        qualifier = '~';
        break;
      case 'neutral':
        qualifier = '?';
        break;
      case 'pass':
      default:
        // qualifier = '+';
        qualifier = ''; // + not needed: pass is default qualifier
    }

    return `v=spf1${genSPF} ${qualifier}all`;
  };

  const generatedSPF = generateSpfRecord(SPF);

  return (
    <SectionLayout>
      <SCConfigureSpf>
        <div className="section-back-button">
          <BackButton />
        </div>

        <div className="section-organism">
          <ConfigureSpfSteps spf={SPF} step={stepSPF} onChangeConfig={setSPF} />
        </div>

        <div className="section-organism configureSpf_Actions">
          {stepSPF > 1 && (
            <Button
              width="340px"
              onClick={handleButtonPrevious}
              color="white"
              text={i18n.t('configureEmailSecurity.back')}
            />
          )}
          {stepSPF > 0 && stepSPF < 3 && (
            <Button
              width="340px"
              onClick={handleButtonNext}
              color="bluishGrey"
              text={i18n.t('configureEmailSecurity.next')}
            />
          )}
          {stepSPF === 3 && (
            <Button
              width="340px"
              onClick={() => navigate('/client/configure-email-security')}
              color="bluishGrey"
              text={i18n.t('configureEmailSecurity.done')}
            />
          )}
        </div>

        {stepSPF > 1 && (
          <SectionCard>
            <div>
              {records.spf ? (
                <div className="configureSpfRecord">
                  <span>
                    {i18n.t('configureEmailSecurity.SPF.yourCurrentRecord')}
                  </span>
                  <p>{records.spf && <span>{records.spf}</span>}</p>
                </div>
              ) : (
                <div>
                  <span>
                    {i18n.t('configureEmailSecurity.SPF.noCurrentRecord')}
                  </span>
                </div>
              )}

              {!isSpfValid ? (
                <div style={{ margin: '5px 0' }}>
                  <span>
                    {i18n.t('configureEmailSecurity.SPF.chooseCriteria')}
                  </span>
                </div>
              ) : (
                <>
                  <div>
                    <span>
                      {i18n.t('configureEmailSecurity.SPF.generatedRecord')}
                    </span>{' '}
                    {generatedSPF && (
                      <div className="configureSpf_Actions">
                        <span>{generatedSPF}</span>
                        <Button
                          width="140px"
                          color="white"
                          onClick={() => copyToClipboard()}
                          text={i18n.t('configureEmailSecurity.copy')}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <h3>{i18n.t('configureEmailSecurity.status')}:</h3>{' '}
                    {(!records.spf || records.spf !== generatedSPF) && (
                      <div>
                        <FontAwesomeIcon icon={faTimes} color="red" />
                        {i18n.t('configureEmailSecurity.SPF.noMatchRecord')}
                      </div>
                    )}
                    {records.spf && records.spf === generatedSPF && (
                      <div>
                        <FontAwesomeIcon icon={faCheck} color="green" />
                        {i18n.t('configureEmailSecurity.SPF.recordSet')}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              <a
                href="http://www.open-spf.org/SPF_Record_Syntax/"
                target="_blank"
                rel="noreferrer">
                {i18n.t('configureEmailSecurity.SPF.detailedSyntax')}
              </a>
            </div>
          </SectionCard>
        )}
      </SCConfigureSpf>
    </SectionLayout>
  );
};

export default ConfigureSpf;
