import { useSelector } from 'react-redux';
import handleUnauthorizedAccess from '../../../utils/restrictedAccessBehaviour/restrictedAccessBehaviour';

function WebsiteRouteGuard({ children }) {
  const client = useSelector((redux) => redux.client);

  return client?.status?.serviceLevel.periodicitiesInDays.scanWebsite
    ? children
    : handleUnauthorizedAccess();
}
export default WebsiteRouteGuard;
