import ActionTemplates from '../../components/Dashboard/Sections/Actions/ActionTemplates.json';

export const getDisplayableActions = (actions) =>
  Object.values(actions || {}).filter((action) => ActionTemplates[action.type]);

export const getIncompletedActions = (actions) =>
  getDisplayableActions(actions)
    .filter((action) => !action.pass)
    .sort((a, b) => b.priority - a.priority);

export const getCompletedActions = (actions) =>
  getDisplayableActions(actions)
    .filter((action) => action.pass)
    .sort((a, b) => b.priority - a.priority);

export const getAction = (actions, actionType) => actions[actionType];
