import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
// import './index.css';
import 'antd/dist/reset.css';
import './css/cyberguardian.css';
import './css/antd-override.css';
import './css/clientdashboard.css';
// import 'bootstrap/dist/css/bootstrap.css';
import makeMainRoutes from './routes';
import './loadthirdpartyscripts';

const routes = makeMainRoutes();

ReactDOM.render(routes, document.getElementById('root'));
