import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import useBreachNotificationType from '../../../hooks/breachNotificationType/useBreachNotificationType';
import { useTranslation } from '../../../i18n';
import Button from '../../atoms/Button/Button';
import { showPopUp } from '../../../redux/actions/popUp.actions';
import ModalLayout from '../../layouts/ModalLayout/ModalLayout';

const SCBreachesNotifyAffected = styled.div`
  /* display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: center; */
  > div:first-of-type {
    text-align: left;
  }

  h1 {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    display: flex;
    text-align: center;
  }

  div {
    font-size: 14px;
    font-family: var(--font1);
  }
`;

const BreachesNotifyAffected = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const { breaches, activeBreach, emailBreachNotifications } =
    useBreachNotificationType();

  return (
    <ModalLayout>
      <SCBreachesNotifyAffected>
        <h1>
          {i18n.t('breachNotification.resolvingBreachCard.notifyAffected')}
        </h1>
        <div className="modal-card-content">
          <div
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                'breachNotification.resolvingBreachCard.notifyAffectedParagrah',
                {
                  count: Object.keys(breaches[activeBreach].emails).length,
                }
              ),
            }}
          />
        </div>
        <div className="modal-card-buttons">
          <Button
            text={i18n.t('breachNotification.resolvingBreachCard.sendEmail')}
            onClick={() => emailBreachNotifications()}
            color="bluishGrey"
            size="full"
          />
          <Button
            text={i18n.t('common.cancel')}
            onClick={() => dispatch(showPopUp(null))}
            color="white"
            size="full"
          />
        </div>
      </SCBreachesNotifyAffected>
    </ModalLayout>
  );
};

export default BreachesNotifyAffected;
